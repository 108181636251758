import { API } from 'aws-amplify';
import { cloneDeep, get, omit, set } from 'lodash';
import { brandFields } from './brandFields';
import { putAppEvent } from '../putAppEvent';

const updateBrandQuery = /* GraphQL */ `
  mutation UpdateBrand($input: UpdateBrandInput!) {
    updateBrand(input: $input) {
      ${brandFields}
    }
  }
`;

const removeBrandProps = [
  'teamBrandsId',
  'markups',
  'availableCredits',
  'summaryMetrics',
  'segmentPackages',
  'segmentPackages',
  'bMar',
  'config',
];

const cleanBrandInput = brand => {
  const cleanedBrand = cloneDeep(omit(brand, removeBrandProps));

  if (get(brand, 'politicalSettings.candidate')) {
    switch (get(cleanedBrand, 'politicalSettings.politicalType')) {
      case 'stateOrLocal':
        set(cleanedBrand, 'politicalSettings.candidate.FederalVerificationId', null);
        break;
      case 'federal':
        set(cleanedBrand, 'politicalSettings.candidate.StateVerificationId', null);
        set(cleanedBrand, 'politicalSettings.candidate.ElectionState', null);
        break;
      default:
        break;
    }
  }

  console.log(cleanedBrand);

  return cleanedBrand;
};

export const updateBrand = async brand => {
  const clean = cleanBrandInput(brand);

  clean.underReview = brand.industryCategoryId === 109;

  if (clean.id) {
    return API.graphql({
      query: updateBrandQuery,
      variables: {
        input: clean,
      },
    })
      .then(({ data: { updateBrand: resp } = {} }) => resp || false)
      .then(resp => {
        putAppEvent('BrandMutation', clean);

        return resp;
      });
  }

  return Promise.reject();
};
