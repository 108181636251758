import { useEffect, useState } from 'react';
import { useGAS } from 'src/GlobalAppState/context';
import { useAppParams } from 'src/hooks/useAppParams';
import { updateBrand } from 'src/helpers/brand';
import { PixelToggle } from 'src/components/MainSettings/BrandSettings/BrandSettingsPixel/PixelToggle';
import { TrackingTagPanel } from 'src/components/MainSettings/BrandSettings/BrandSettingsPixel/TrackingTagPanel';
import { UniversalPixelPanel } from 'src/components/MainSettings/BrandSettings/BrandSettingsPixel/UniversalPixelPanel';
import { CustomTagPanel } from 'src/components/MainSettings/BrandSettings/BrandSettingsPixel/CustomTagPanel';

export default function BrandSettingsTrackingPage() {
  const appParams = useAppParams();
  const { brand } = useGAS();
  const { pixelTracking } = brand;
  const [tracking, setTracking] = useState(pixelTracking);
  const [selection, setSelection] = useState('universal');
  const { authClaim } = appParams;
  const adminAuthRole = (authClaim || []).filter(a => a.staticAuthRole === 'Admin');

  useEffect(() => setTracking(pixelTracking || false), [pixelTracking]);

  const onClick = () => {
    updateBrand({ id: brand.id, pixelTracking: !tracking }).then(() => setTracking(!tracking));
  };

  return (
    <div className="flex flex-col space-y-4 overflow-y-auto relative flex-grow">
      <div className="mb-4">
        <PixelToggle selection={selection} setSelection={setSelection} authRole={adminAuthRole[0] || null} />
      </div>
      {selection === 'universal' && <UniversalPixelPanel brand={brand} />}
      {selection === 'tracking' && <TrackingTagPanel brand={brand} appParams={appParams} />}
      {selection === 'custom' && <CustomTagPanel brand={brand} appParams={appParams} />}
      <div className="absolute -top-2 left-96 ml-32">
        <TrackingSwitch onClick={onClick} tracking={tracking} />
      </div>
    </div>
  );
}

const TrackingSwitch = ({ onClick, tracking }) => {
  const toggleBtnClass = 'w-14 h-7 flex items-center border-2 border-gray-400 bg-gray-200 rounded-full p-1 mr-1';

  return (
    <div className="flex flex-row tracking-switch-container relative">
      <div className="flex cursor-pointer w-16" onClick={onClick}>
        {!tracking ? (
          <div className={`${toggleBtnClass} opacity-50`}>
            <span className="ml-7 opacity-50">&#x58;</span>
          </div>
        ) : (
          <div className={`${toggleBtnClass}`}>
            <span className="ml-1">&#10003;</span>
          </div>
        )}
        <div
          className={`z-10 -ml-14 sm:ml-1 mt-1 sm:absolute h-5 w-5 sm:px-1 rounded-full bg-primary shadow-md transform ${
            !tracking ? '' : 'translate-x-7'
          }`}
        />
      </div>
      {!tracking ? (
        <div className="flex opacity-50 pl-2">
          Audience Tracking <span className="font-bold pl-2">Disabled</span>
        </div>
      ) : (
        <div className="flex pl-2">Audience Tracking Enabled</div>
      )}
    </div>
  );
};
