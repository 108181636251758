import React, { useState, useEffect, useRef } from 'react';
import { CloseIcon, CheckIcon, EditIcon } from 'src/svgs';
import { UrlPrefixDropdown } from 'src/components/UrlPrefixDropdown';

export const SettingsWebsiteInput = ({ label, value, schema, onSave }) => {
  const [showEdit, setShowEdit] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [draft, setDraft] = useState('');
  const [prefix, setPrefix] = useState('');

  let urlNoPrefix = draft;

  if (draft) {
    if (draft.startsWith('http://') || draft.startsWith('https://')) {
      urlNoPrefix = draft.replace(/(^http:\/\/|https:\/\/)/, '');
    }
  }

  useEffect(() => {
    let tempDraft = draft;
    let tempPrefix = prefix;

    if (draft) {
      if (tempDraft.startsWith('https://')) {
        tempDraft = tempDraft.replace(/(http:\/\/|https:\/\/)/g, '');
        tempPrefix = 'https://';
      } else if (tempDraft.startsWith('http://')) {
        tempDraft = tempDraft.replace(/(http:\/\/|https:\/\/)/g, '');
        tempPrefix = 'http://';
      }
    }
    setDraft(tempPrefix + tempDraft);
    setPrefix(tempPrefix);
  }, [draft, prefix]);

  const inputRef = useRef();

  useEffect(() => {
    if (showEdit && inputRef.current) inputRef.current.focus();
  }, [showEdit]);

  useEffect(() => {
    setDraft(value || '');
  }, [value]);

  useEffect(() => {
    const setError = msg => invalid !== msg && setInvalid(msg);

    if (schema) {
      schema
        .validate(draft)
        .then(() => setError(false))
        .catch(({ message }) => setError(message));
    }
  }, [schema, draft, setInvalid, invalid, prefix]);

  const onSaveClick = () => {
    const cleanWebsite = (draft || '').replace('https://https://', 'https://').replace('http://http://', 'http://');

    if (!invalid) {
      onSave(cleanWebsite);

      setShowEdit(false);
    }
  };

  const onEditClick = () => {
    if (showEdit) {
      setDraft(value);
      setShowEdit(false);
      if (value.startsWith('https://') && prefix === 'http://') {
        setPrefix('https://');
      } else if (!value.startsWith('https://') && value.startsWith('http://') && prefix === 'https://') {
        setPrefix('http://');
      }
    } else {
      setShowEdit(true);
    }
  };

  const updateDraft = ({ target: { value: val } = {} }) => {
    setDraft(`${prefix}${val}`);
  };

  return (
    <div>
      <div className="flex items-center space-x-4 h-14">
        <button type="button" onClick={onEditClick} className="bz-btn-icon w-8 h-8 p-1">
          {!showEdit && <EditIcon className="w-full h-full text-primary stroke-current" />}
          {showEdit && <CloseIcon className="w-5 h-5 text-gray-400 stroke-current" />}
        </button>
        {!showEdit && (
          <div className="flex flex-col">
            <h2 className="font-medium">{label}</h2>
            <h2 className="text-gray-500 text-lg">{draft}</h2>
          </div>
        )}
        {showEdit && (
          <div className="flex items-center space-x-3 pb-5 sm:pb-0">
            <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-3">
              <UrlPrefixDropdown update={setDraft} prefix={prefix} setPrefix={setPrefix} urlNoPrefix={urlNoPrefix} />
              <input
                ref={inputRef}
                type="text"
                placeholder={label}
                value={urlNoPrefix}
                onChange={updateDraft}
                className="bz-input h-12"
              />
            </div>
            <button type="button" onClick={onSaveClick} className="bz-btn-icon p-1">
              <div className="flex items-center justify-center w-8 h-8 border-2 border-green-400 rounded-full">
                <CheckIcon className="w-4 h-4 text-green-400 stroke-current" />
              </div>
            </button>
          </div>
        )}
      </div>
      {invalid && (
        <div className="my-1 h-5 flex justify-self-center">
          <p className="text-primary h-full">{invalid}</p>
        </div>
      )}
    </div>
  );
};
