import { API } from 'aws-amplify';
import { trackingTagFields } from './trackingTagFields';
import { putAppEvent } from '../putAppEvent';

const createTrackingTagQuery = /* GraphQL */ `
  mutation CreateTrackingTag($input: CreateTrackingTagInput!) {
    createTrackingTag(input: $input) {
      ${trackingTagFields}
    }
  }
`;

const cleanTrackingTagInput = TrackingTag => {
  const { archived: _archived, dspIds: _dspIds, brand: _brand, ...rest } = TrackingTag;

  return rest;
};

export const createTrackingTag = async input =>
  API.graphql({
    query: createTrackingTagQuery,
    variables: { input: cleanTrackingTagInput(input) },
  })
    .then(({ data: { createTrackingTag: resp } = {} }) => resp || false)
    .then(resp => {
      putAppEvent('TrackingTagMutation', input);

      return resp;
    });
