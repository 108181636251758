import React, { useState, useEffect } from 'react';
import { ChevronLeft } from 'src/svgs';

export const AudienceTypeEveryone = ({ campaignDraft, setCampaignDraft }) => {
  const setAudienceType = aType => setCampaignDraft({ ...campaignDraft, audienceType: aType });

  const [isDisabled, setDisabled] = useState(false);

  useEffect(() => {
    if (campaignDraft.status === 'completed') {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [campaignDraft.status]);

  return (
    <div className="w-full flex flex-col justify-center items-center mb-16">
      <div className="w-full pt-2 pb-4 px-3">
        <div className="flex items-center space-x-4">
          <button
            disabled={isDisabled}
            type="button"
            onClick={() => setAudienceType(false)}
            className={`flex space-x-4 p-1 ${isDisabled ? 'opacity-25 cursor-not-allowed' : ''}`}
          >
            <div className="w-6 h-6 p-1 rounded bg-primary flex justify-center items-center">
              <ChevronLeft className="stroke-current text-white h-full -ml-px" />
            </div>
            <h3 className="text-primary">Targeting section</h3>
          </button>
          <h3 className="text-primary">/</h3>
          <h3 className="text-primary">Target everyone</h3>
        </div>
      </div>
      <div className="w-full max-w-lg flex-1 flex flex-col justify-center items-center space-y-4">
        <h3 className="text-gray-900 text-lg font-semibold">You're targeting everyone</h3>
        <h3 className="text-gray-900 text-center text-md">
          This means that you do not have any specific target audiences; your campaign will run on the open exchange.
        </h3>
      </div>
    </div>
  );
};
