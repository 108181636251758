import React, { useState, useRef } from 'react';
import { useSpring, animated, config } from 'react-spring';
import { industryCategories } from 'src/constants';
import { useClickAway, useKey } from 'react-use';
import { CloseIcon, EditIcon } from 'src/svgs';

export const IndustryCategoryDropdown = ({ value, onSelect }) => {
  const [showEdit, setShowEdit] = useState(false);
  const style = useSpring({ height: showEdit ? 208 : 0, opacity: showEdit ? 1 : 0, config: config.stiff });
  const menuRef = useRef(null);

  useKey('Escape', () => setShowEdit(false));
  useClickAway(menuRef, () => setShowEdit(false));

  const onOptionSelect = cat => {
    onSelect(cat.value);
    setShowEdit(false);
  };

  const options = industryCategories.map(cat => (
    <button
      type="button"
      key={`cat-${cat.value}`}
      onClick={() => onOptionSelect(cat)}
      className="drop-option text-left truncate flex w-full items-center px-4 h-12 hover:bg-blue-100 focus:outline-none focus:bg-blue-100"
    >
      {cat.text}
    </button>
  ));

  const selected = industryCategories.find(cat => cat.value === value);
  const selectedText = selected ? selected.text : 'select an option';

  const politicalBrand = value === 4385;

  const onEditClick = () => {
    if (politicalBrand) return false;
    if (showEdit) {
      setShowEdit(false);
    } else {
      setShowEdit(true);
    }
  };

  return (
    <div>
      <div className="flex items-center space-x-4 h-14">
        <button type="button" disabled={politicalBrand} onClick={onEditClick} className="bz-btn-icon w-8 h-8 p-1">
          {!showEdit && <EditIcon className="w-full h-full text-primary stroke-current" />}
          {showEdit && <CloseIcon className="w-5 h-5 text-gray-400 stroke-current" />}
        </button>

        {!showEdit && (
          <div className="flex flex-col">
            <h2 className="font-medium">Industry Category *</h2>
            <h2 className="text-gray-500 text-lg">{selected?.text || ''}</h2>
          </div>
        )}
        {showEdit && (
          <div ref={menuRef} className="relative w-full">
            <button type="button" onClick={() => setShowEdit(!showEdit)} className="bz-input py-2 text-left truncate">
              {selectedText}
            </button>
            <animated.div
              style={style}
              className={`z-0 absolute h-52 mt-1 overflow-scroll w-full rounded bg-white shadow-lg ${
                showEdit ? 'block' : 'hidden'
              }`}
            >
              {showEdit && options}
            </animated.div>
          </div>
        )}
      </div>
      {politicalBrand && (
        <div className="bg-gray-50 border-2 border-gray-200 text-gray-800 text-xs px-2 py-1 mr-1 ml-2 flex items-center w-80">
          <p> Please contact support if you want to change from a political brand</p>
        </div>
      )}
      {!selected?.text && (
        <div className="flex ml-12 space-x-2 text-sm bg-orange-100 p-1 w-1/2 mt-2 ">
          <span className="mx-auto text-gray-500">Category is required</span>
        </div>
      )}
    </div>
  );
};
