import React, { useState, useEffect, useContext } from 'react';

const ModalContext = React.createContext({});

export const ModalProvider = ({ children }) => {
  const [modal, setModal] = useState({});
  const [modalData, setModalData] = useState(false);

  useEffect(() => {
    if (modal.key) setModalData(false);
  }, [modal.key]);

  useEffect(() => {
    if (modalData) setModal({});
  }, [modalData]);

  const openModal = (key, options = {}) => setModal({ ...options, key });

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ModalContext.Provider value={{ modal, setModal, openModal, modalData, setModalData }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
