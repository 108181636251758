import DatePicker from 'react-datepicker';
import { SELECT_RANGE_ALL, SELECT_RANGE_END, SELECT_START } from './campaignDatePickerVariants';

export const CampaignDatePickerDropdown = ({ settingEndDate, onChange, startDate, endDate, variant, now }) => {
  switch (variant) {
    case SELECT_START:
      return <DatePicker selected={startDate} onChange={onChange} inline minDate={now} />;
    case SELECT_RANGE_END:
      return (
        <DatePicker
          onChange={onChange}
          selected={startDate}
          startDate={startDate}
          endDate={null}
          minDate={startDate > now ? startDate : now}
          selectsRange
          inline
        />
      );
    case SELECT_RANGE_ALL:
      return (
        <DatePicker
          onChange={onChange}
          selected={settingEndDate ? startDate : endDate}
          startDate={startDate}
          endDate={settingEndDate ? null : endDate}
          minDate={settingEndDate ? startDate : now}
          selectsRange
          inline
        />
      );
    default:
      return null;
  }
};
