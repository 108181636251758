import React, { useState, useRef } from 'react';
import { useClickAway } from 'react-use';
import { ChevronDown } from 'src/svgs';

export const GraphDropdown = ({ selected, setSelected }) => {
  const [open, setOpen] = useState(false);

  const menuRef = useRef();

  useClickAway(menuRef, () => setOpen(false));

  const onSelect = val => {
    setSelected(val);
    setOpen(false);
  };

  return (
    <div className="relative inline-block text-left">
      <button
        type="button"
        onClick={() => setOpen(!open)}
        className="flex w-48 items-center justify-between space-x-4 pl-4 pr-3 py-2 rounded border hover:border-gray-400"
      >
        <h1 className="text-lg font-semibold capitalize">{selected}</h1>
        <ChevronDown className="w-5 h-5 stroke-current text-primary" />
      </button>
      <div
        ref={menuRef}
        className={`origin-top-right ${
          !open && 'hidden'
        } absolute z-30 left-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
      >
        <div className="py-1">
          <DropdownButton active={selected === 'impressions'} onClick={() => onSelect('impressions')}>
            Impressions
          </DropdownButton>
          <DropdownButton active={selected === 'clicks'} onClick={() => onSelect('clicks')}>
            Clicks
          </DropdownButton>
          <DropdownButton active={selected === 'views'} onClick={() => onSelect('views')}>
            Views
          </DropdownButton>
          <DropdownButton active={selected === 'spend'} onClick={() => onSelect('spend')}>
            Spend
          </DropdownButton>
          <DropdownButton active={selected === 'conversions'} onClick={() => onSelect('conversions')}>
            Conversions
          </DropdownButton>
        </div>
      </div>
    </div>
  );
};

const DropdownButton = ({ active, children, onClick }) => {
  const activeStyle = active ? 'text-primary' : 'text-gray-700';

  return (
    <button
      type="button"
      onClick={onClick}
      className={`block w-full text-left px-4 py-4 font-semibold hover:bg-gray-100 focus:bg-gray-100 ${activeStyle}`}
      role="menuitem"
    >
      {children}
    </button>
  );
};
