import { Component } from 'react';
import errorEmitter from './errorEmitter';

class ErrorBoundary extends Component {
  componentDidCatch(error, errorInfo) {
    errorEmitter.emitError(error.message, errorInfo.componentStack);
  }

  render() {
    const { children } = this.props;

    return children;
  }
}

export default ErrorBoundary;
