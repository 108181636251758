import { API } from 'aws-amplify';

const fields = /* GraphQL */ `
  {
    id
    createdAt
    updatedAt
    adminGroups
    viewerGroups
    userId
    organizationId
    teamId
    brandId
    campaignId
    chargeType
    chargeId
    amountIntended
    amount
    status
    metadata
  }
`;

const getOrganizationCredits = /* GraphQL */ `
  query creditsByOrganizationTeamBrandCampaign($organizationId: ID!, $nextToken: String) {
    creditsByOrganizationTeamBrandCampaign(organizationId: $organizationId, nextToken: $nextToken) {
      nextToken
      items ${fields}
    }
  }
`;

export const getCredits = ({ organizationId, teamId, brandId, campaignId }) => {
  const input = { organizationId };

  if (teamId) {
    input.teamIdBrandIdCampaignIdCreatedAt = { beginsWith: { teamId } };
    if (brandId) input.teamIdBrandIdCampaignIdCreatedAt.beginsWith.brandId = brandId;
    if (brandId && campaignId) input.teamIdBrandIdCampaignIdCreatedAt.beginsWith.campaignId = campaignId;
  }
  const getPage = next =>
    API.graphql({
      query: getOrganizationCredits,
      variables: { ...input, nextToken: next },
    }).then(
      ({
        data: {
          creditsByOrganizationTeamBrandCampaign: { items, nextToken },
        },
      }) => {
        if (nextToken) return getPage(nextToken).then(resp => [...items, ...resp]);

        return items;
      }
    );

  return getPage().then(resp => resp.filter(itm => !itm.archived).sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1)));
};
