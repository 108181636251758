import { Switch, Route } from 'react-router-dom';
import TeamSettingsLayout from './layout';
import TeamSettingsEditPage from './edit/page';
import TeamSettingsUsersPage from './users/page';
import TeamSettingsWhiteLabelPage from './white-label/page';
import TeamSettingsIntegrationsPage from './integrations/page';

const teamSettingsEdit = '/team/:id/edit';
const teamSettingsUsers = '/team/:id/users';
const teamSettingsWhiteLabel = '/team/:id/white-label';
const teamSettingsIntegrations = '/team/:id/integrations';

export const teamSettingsPaths = [
  teamSettingsEdit,
  teamSettingsUsers,
  teamSettingsWhiteLabel,
  teamSettingsIntegrations,
];

export default function TeamSettings() {
  return (
    <TeamSettingsLayout>
      <Switch>
        <Route exact path={teamSettingsEdit}>
          <TeamSettingsEditPage />
        </Route>
        <Route exact path={teamSettingsUsers}>
          <TeamSettingsUsersPage />
        </Route>
        <Route exact path={teamSettingsWhiteLabel}>
          <TeamSettingsWhiteLabelPage />
        </Route>
        <Route exact path={teamSettingsIntegrations}>
          <TeamSettingsIntegrationsPage />
        </Route>
      </Switch>
    </TeamSettingsLayout>
  );
}
