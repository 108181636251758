import React, { useState, useRef, useEffect } from 'react';
import { useClickAway } from 'react-use';
import { API } from 'aws-amplify';
import { randomBytes } from 'crypto';
import { UserAuthRole } from 'src/models';
import { sendInviteLink } from 'src/graphql/mutations';
import * as yup from 'yup';

const genCode = () => randomBytes(5).toString('hex');

const createUserAuthRole = /* GraphQL */ `
  mutation CreateUserAuthRole($input: CreateUserAuthRoleInput!) {
    createUserAuthRole(input: $input) {
      id
      brandId
      teamId
      organizationId
      userId
      email
    }
  }
`;

export const TeamUserNewRow = ({ team, setOpen, inputRef, setSending, setShowNew }) => {
  const [email, setEmail] = useState('');

  /**
   * Invite Service
   * Create a new UserAuthRole - status pending
   */

  const [invalid, setInvalid] = useState(false);
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (email) {
      setTouched(true);
    }
  }, [email]);

  useEffect(() => {
    let mounted = true;

    yup
      .string()
      .required('email is required')
      .email('must be a valid email address')
      .validate(email)
      .then(() => mounted && setInvalid(false))
      .catch(({ message }) => mounted && setInvalid(message));

    return () => (mounted = false);
  }, [email]);

  const submit = async () => {
    setSending(email);
    const input = new UserAuthRole({
      email,
      organizationId: team.organizationId,
      teamId: team.id,
      inviteCode: genCode(),
      authRole: 'admin',
    });

    API.graphql({
      query: createUserAuthRole,
      variables: { input },
    })
      .then(({ data: { createUserAuthRole: resp } = {} }) => resp)
      .then(({ id: userAuthRoleId }) => API.graphql({ query: sendInviteLink, variables: { userAuthRoleId } }))
      .then(() => setEmail(''))
      .then(setShowNew(false));
  };

  const rowRef = useRef();

  useClickAway(rowRef, () => {
    setOpen(false);
  });

  return (
    <>
      <tr className="text-lg">
        <td label="team-user-row" className="w-24 px-6 py-4" />
        <td data-mf-replace="" className="px-4 pt-2 ">
          <input
            ref={inputRef}
            type="text"
            placeholder="email"
            onChange={({ target: { value } }) => setEmail(value)}
            value={email}
            className="w-full rounded border-blueGray-300 focus:border-transparent focus:outline-none focus:ring-2 focus:ring-primary"
          />
        </td>
        <td label="team-user-spacer" />
        <td>
          <button
            type="button"
            disabled={invalid}
            onClick={submit}
            className={`text-primary w-48 text-center ${
              invalid ? 'opacity-25 cursor-not-allowed' : 'text-primary w-48 text-center'
            }`}
          >
            send
          </button>
        </td>
      </tr>

      <tr className="mt-1 h-5 w-64">
        <td />
        <td className="text-primary h-full p-4">{touched && invalid ? invalid : ''}</td>
        <td />
        <td />
      </tr>
    </>
  );
};
