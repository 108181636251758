import { API } from 'aws-amplify';
import { creativeFields } from './creativeFields';

const getCreativeQuery = /* GraphQL */ `
  query GetCreative($id: ID!) {
    getCreative(id: $id) {
      ${creativeFields}
    }
  }
`;

export const getCreative = async creativeId =>
  API.graphql({
    query: getCreativeQuery,
    variables: {
      id: creativeId,
    },
  })
    .then(({ data: { getCreative: resp } = {} }) => resp || false)
    .catch(({ data: { getCreative: resp } = {} }) => resp || false);
