import { useCallback } from 'react';
import { useModal } from 'src/contexts';
import { ColumnCell, ColumnHeader } from './Components';

const shouldShowArchive = campaign => campaign.status === 'draft' || campaign.status === 'completed';

const MainListInlineActions = ({ campaign }) => {
  const { openModal } = useModal();

  const showArchive = shouldShowArchive(campaign);

  const onArchiveClick = useCallback(() => {
    if (!showArchive) return;

    openModal('archive-campaign', {
      name: campaign.name,
      id: campaign.id,
      brandId: campaign.brandId,
    });
  }, [campaign, openModal, showArchive]);

  const onCloneClick = useCallback(() => {
    openModal('clone-campaign', {
      campaign,
    });
  }, [campaign, openModal]);

  return (
    <ColumnCell className="text-sm">
      <button type="button" onClick={onCloneClick} className="text-blue-400 hover:opacity-75 hover:cursor-pointer">
        Clone
      </button>
      <span className="text-gray-200 px-2">|</span>
      <button
        type="button"
        disabled={!showArchive}
        onClick={onArchiveClick}
        className={showArchive ? 'text-blue-400 hover:opacity-75 cursor-pointer' : 'text-gray-300 cursor-default'}
      >
        Archive
      </button>
    </ColumnCell>
  );
};

export const ActionsColumn = {
  key: 'actions',
  header: () => <ColumnHeader className="w-40" />,
  cell: MainListInlineActions,
};
