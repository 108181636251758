import { differenceInDays } from 'date-fns';
import { createSelector } from 'reselect';

export const getSummaryMetrics = campaign => campaign.summaryMetrics?.items ?? [];

export const getStartDate = campaignOrDraft => campaignOrDraft.startDate;

export const getBudget = campaignOrDraft => campaignOrDraft.budget ?? 0;
export const getRecurrenceBudget = campaignOrDraft => campaignOrDraft.recurrenceBudget ?? 0;

export const getIsRecurring = campaignOrDraft => campaignOrDraft.isRecurring;

export const getCampaignMinSpend = createSelector(getSummaryMetrics, getStartDate, (summaryMetrics, startDate) => {
  const summaryAllTime = summaryMetrics.find(m => m.summaryType === 'ALL_TIME') || {};
  const spendAllTime = summaryAllTime.spend || 0;

  if (!spendAllTime) {
    return 0;
  }

  const start = new Date(startDate);
  const today = new Date();

  const dayCount = (differenceInDays(today, start) || 0) + 1;
  const dailySpend = spendAllTime / dayCount;

  return Math.ceil(spendAllTime + dailySpend);
});
