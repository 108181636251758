import CampaignEditPage from '../../../campaign/[id]/edit/page';
import CampaignLayout from '../../../campaign/[id]/layout';

export default function NewCampaignPage() {
  return (
    <CampaignLayout>
      <CampaignEditPage />
    </CampaignLayout>
  );
}
