import React, { useEffect, useState } from 'react';
import { ChevronLeft } from 'src/svgs';
import { useGAS } from 'src/GlobalAppState/context';

export const AudienceTypeExisting = ({ campaignDraft, setCampaignDraft }) => {
  const setAudienceType = aType => setCampaignDraft({ ...campaignDraft, audienceType: aType });
  const { brandCampaigns: campaigns } = useGAS();
  const [isDisabled, setDisabled] = useState(false);

  useEffect(() => {
    if (campaignDraft.status === 'completed') {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [campaignDraft.status]);
  const onSelect = campaign => {
    setCampaignDraft({
      ...campaignDraft,
      audienceType: campaign.audienceType,
      audienceFrom: campaign.Id,
      dmpSegments: campaign.dmpSegments,
    });
  };

  const campaignButtons = campaigns ? (
    campaigns
      .filter(camp => camp.id !== campaignDraft.id && !!camp.audienceType)
      .map(camp => (
        <button
          key={camp.id}
          type="button"
          onClick={() => onSelect(camp)}
          className={`w-full py-3 px-4 text-left hover:bg-blueGray-100 rounded ${
            isDisabled ? 'opacity-25 cursor-not-allowed' : ''
          }`}
          disabled={isDisabled}
        >
          <div className="truncate w-64">{camp.name}</div>
        </button>
      ))
  ) : (
    <div />
  );

  const goBack = () => setAudienceType(false);

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="w-full pb-4 px-3 -ml-1">
        <div className="flex items-center space-x-4">
          <button
            disabled={isDisabled}
            type="button"
            onClick={goBack}
            className={`flex space-x-4 p-1 ${isDisabled ? 'opacity-25 cursor-not-allowed' : ''}`}
          >
            <div className="w-6 h-6 p-1 rounded bg-primary flex justify-center items-center">
              <ChevronLeft className="stroke-current text-white h-full -ml-px" />
            </div>
            <h3 className="text-primary w-24 sm:w-44">Targeting section</h3>
          </button>
          <h3 className="text-primary">/</h3>
          <h3 className="text-primary w-30 pr-2 sm:pr-0 sm:w-1/2">Use an audience from an existing campaign</h3>
        </div>
      </div>
      <div className="px-12 pt-4">
        <h3 className="text-gray-900 text-center text-md">
          Select from the list below the previously created audience you want to use for this campaign.
        </h3>
        <div className="w-full py-2 h-80 overscroll-auto overflow-scroll pb-20">{campaignButtons}</div>
      </div>
    </div>
  );
};
