import { isEqual, isEqualWith } from 'lodash';
import { useRef } from 'react';

const shallow = (obj1, obj2) => isEqualWith(obj1, obj2, (prop1, prop2) => prop1 === prop2);

export const useStableObjectIdentity = (obj, deep = true) => {
  const objRef = useRef(obj);

  const comparator = deep ? isEqual : shallow;

  if (!comparator(objRef.current, obj)) {
    objRef.current = obj;
  }

  return objRef.current;
};
