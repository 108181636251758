import { createAPIQueryHook } from '../../hooks/useAPIQuery';

const listQuery = /* GraphQL */ `
  query ListManagedAudiences($brandId: ID!) {
    audiences: searchAudiences(filter: { brandId: { eq: $brandId }, audienceType: { eq: "managed" } }, limit: 100) {
      items {
        id
        name
        description
        brandId
        teamId
        organizationId
        audienceType
      }
      nextToken
    }
  }
`;

const useAudienceQuery = createAPIQueryHook({
  query: listQuery,
  paged: true,
  nextTokenPath: 'audiences.nextToken',
  resultPath: 'audiences.items',
});

export const useManagedAudiences = ({ brandId }) => {
  const disable = !brandId;
  const { data: audiences } = useAudienceQuery({ brandId }, { disable });

  return audiences ?? [];
};
