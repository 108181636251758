import { API } from 'aws-amplify';
import { teamFields } from './teamFields';

const getTeamQuery = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      ${teamFields}
    }
  }
`;

export const getTeam = async teamId =>
  API.graphql({
    query: getTeamQuery,
    variables: {
      id: teamId,
    },
  })
    .then(({ data: { getTeam: resp } = {} }) => resp || false)
    .catch(({ data: { getTeam: resp } = {} }) => resp || false);
