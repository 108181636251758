/**
 *
 * @param {(
 * 'credits-added' |
 * 'campaign-published' |
 * 'campaign-forecast-viewed' |
 * 'user-invited-to-brand' |
 * 'add-credits-viewed' |
 * 'onboarding-completed' |
 * 'credits-added' |
 * 'onboarding-started' |
 * )} eventName
 * @param {*} metaData
 */

export const trackEvent = (eventName, metaData) => {
  window.Intercom?.('trackEvent', eventName, metaData);
};
