import { API } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { BsTag } from 'react-icons/bs';
import { useModal } from 'src/contexts';
import { AddIcon } from 'src/svgs';
import { addCustomTrackingTag } from 'src/graphql/mutations';
import { createTrackingTag } from 'src/helpers/trackingTag';
import { CustomTagRow } from './CustomTagRow';
import { NewCustomTagRow } from './NewCustomTagRow';

export const CustomTagPanel = ({ brand, appParams }) => {
  const { openModal } = useModal();
  const [tagDraft, setTagDraft] = useState({});
  const [showNewRow, setShowNewRow] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [activeRow, setActiveRow] = useState(false);
  const [trackingTags, setTrackingTags] = useState([]);
  const [error, setError] = useState(false);
  const { brandTrackingTags } = appParams;

  const brandId = brand?.id;
  const { teamId, organizationId } = brand;

  useEffect(() => {
    setActiveRow(showNewRow ? 'new' : false);
  }, [showNewRow]);

  useEffect(() => {
    if (brandTrackingTags) {
      const tags = brandTrackingTags.filter(t => t.trackingTagType === 'custom');

      setTrackingTags(tags);
    }
  }, [brandTrackingTags]);

  const onCreate = async () => {
    setError(false);
    setSubmitting(true);

    const input = {
      ...tagDraft,
      trackingTagType: 'custom',
      brandId,
      teamId,
      organizationId,
      status: 'Verifying...',
    };

    const existing = trackingTags.find(t => t.ttdId === input.ttdId);

    if (existing) {
      setError('Custom tracking tag already exists.');
    } else {
      const dbTag = await createTrackingTag(input);

      const addCustomInput = {
        id: dbTag.id,
        ttdId: dbTag.ttdId,
      };

      if (dbTag) {
        await API.graphql({ query: addCustomTrackingTag, variables: { input: addCustomInput } });
      }

      setTagDraft({});
      setShowNewRow(false);
      setSubmitting(false);
    }
  };

  const onDelete = trackingTag => {
    openModal('confirm-delete-tracking-tag', { trackingTag, brand, setActiveRow });
  };

  const rows = (trackingTags || []).map(tag => (
    <CustomTagRow
      key={tag.id}
      tag={tag}
      submitting={submitting}
      onDelete={onDelete}
      activeRow={activeRow}
      setActiveRow={setActiveRow}
    />
  ));

  return (
    <div className="w-full flex flex-col space-y-4 overflow-y-auto">
      <div className="flex items-center space-x-4 pt-2">
        <h1 className="text-lg font-medium">Custom Tracking Tags</h1>
      </div>

      <p className="text-gray-500">Customize your tracking tags exactly where you need them in your code.</p>
      <span className="text-gray-500 max-w-4xl text-sm sm:text-base">
        While our Universal Pixel allows you to track multiple pages with just one pixel, Custom Tracking Tags can be
        placed inside of elements that render conditionally on your page. Once your Custom Tracking Tag is placed, it
        may be used for <span className="font-semibold">Retargeting or Conversions</span>. Enable the{' '}
        <span className="font-semibold">Audience Tracking</span> toggle to monitor visits to your site.
      </span>
      <div className="overflow-x-auto" style={{ maxHeight: 'calc(100vh - 100px)', minWidth: '1000px' }}>
        <table className="table-fixed w-full text-sm sm:text-base">
          <thead>
            <tr className="h-16 sm:h-20 text-lg font-medium">
              <td className="w-24 sm:w-28 px-2 sm:px-4">
                <div className="w-full h-full flex items-center justify-center">
                  <button
                    type="button"
                    onClick={() => setShowNewRow(!showNewRow)}
                    className="flex items-center space-x-1 p-1 rounded hover:bg-gray-100"
                  >
                    <AddIcon className="stroke-current text-primary w-4" />
                    <BsTag className="stroke-current text-primary w-4 sm:w-6" />
                  </button>
                </div>
              </td>
              <td className="w-44 border-b">Custom ID</td>
              <td className="border-b w-44 sm:w-1/2 pl-5">Name</td>
              <td className="w-44 sm:w-1/6 border-b">Status</td>
              <td className="border-b w-36">
                <div className="flex flex-col items-center">
                  <span className="text-lg font-semibold">Hit Count</span>
                  <span className="text-xs sm:text-sm font-normal">(7 days)</span>
                </div>
              </td>
            </tr>
          </thead>
          <tbody>
            {rows}
            <NewCustomTagRow
              tagDraft={tagDraft}
              setTagDraft={setTagDraft}
              submitting={submitting}
              showNewRow={showNewRow}
              rowCount={rows.length}
              setShowNewRow={setShowNewRow}
              onCreate={onCreate}
              error={error}
              setError={setError}
              setSubmitting={setSubmitting}
            />
          </tbody>
        </table>
      </div>
    </div>
  );
};
