import { useCallback } from 'react';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import { useGAS } from 'src/GlobalAppState/context';
import { useModal } from 'src/contexts';
import { AddIcon } from 'src/svgs';
import { FaArrowRight } from 'react-icons/fa';

export const HeaderButton = ({
  children,
  onClick,
  icon: Icon,
  primaryAction,
  disabled,
  type = 'button',
  className = '',
}) => (
  <button
    // eslint-disable-next-line react/button-has-type
    type={type}
    onClick={onClick}
    disabled={disabled}
    className={`flex items-center justify-between space-x-4 px-4 py-2 border rounded font-medium disabled:opacity-25 disabled:pointer-events-none ${className} ${primaryAction ? 'bg-blue-600 hover:bg-blue-800 text-white border-blue-600' : 'bg-gray-100 hover:bg-gray-200 border-gray-200 text-black'}`}
  >
    {Icon && <Icon className="stroke-current h-5 w-5" />}
    <span>{children}</span>
  </button>
);

export const HeaderLink = ({ children, href, icon: Icon, primaryAction, className = '' }) => (
  <Link
    to={href}
    className={`flex items-center justify-between space-x-4 px-4 py-2 border rounded font-medium disabled:opacity-25 disabled:pointer-events-none ${className} ${primaryAction ? 'bg-blue-600 hover:bg-blue-800 text-white border-blue-600' : 'bg-gray-100 hover:bg-gray-200 border-gray-200 text-black'}`}
  >
    {Icon && <Icon className="stroke-current h-5 w-5" />}
    <span>{children}</span>
  </Link>
);

export const Headline = ({ children }) => <h1 className="text-gray-800 text-3xl whitespace-nowrap">{children}</h1>;

export const Header = ({ children, title }) => (
  <div className="flex flex-row space-x-4 items-center">
    <Headline>{title}</Headline>
    <span className="flex-1" />
    {children}
  </div>
);

const CreditHeader = ({ children, title, credits }) => (
  <div className="flex flex-row space-x-4 items-center">
    <Headline>{title}</Headline>
    <span>-</span>
    <NumberFormat
      className="text-3xl text-gray-800"
      value={credits}
      displayType="text"
      prefix="$"
      decimalScale={2}
      fixedDecimalScale
      thousandSeparator
      data-testid="available-credits"
    />
    <span className="flex-1" />
    {children}
  </div>
);

export const CreditHistoryHeader = ({ modelName = 'team' }) => {
  const {
    team,
    brand,
    credits: { teamCredits, brandCredits },
  } = useGAS();

  const isBrand = modelName === 'brand';

  const model = isBrand ? brand : team;
  const credits = isBrand ? brandCredits : teamCredits;

  const { openModal } = useModal();

  const onAddCredits = useCallback(() => {
    openModal('add-credits', isBrand ? { brand } : { team });
  }, [brand, isBrand, openModal, team]);

  const onTransfer = useCallback(() => {
    openModal(isBrand ? 'transfer-brand-credits' : 'transfer-agency-credits', isBrand ? { brand } : { team });
  }, [brand, isBrand, openModal, team]);

  return (
    <CreditHeader title={`${model.name} Credits`} credits={credits}>
      <HeaderButton onClick={onTransfer} icon={FaArrowRight}>
        {isBrand ? 'Transfer Credits' : 'Distribute Credits'}
      </HeaderButton>
      <HeaderButton onClick={onAddCredits} icon={AddIcon} primaryAction>
        Add Credits
      </HeaderButton>
    </CreditHeader>
  );
};

export const CreditMarginHeader = ({ children }) => {
  const {
    team,
    credits: { teamPayoutCredits },
  } = useGAS();

  return (
    <CreditHeader title={`${team.name} Margin Earnings`} credits={teamPayoutCredits}>
      {children}
    </CreditHeader>
  );
};
