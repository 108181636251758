import { Switch, Route } from 'react-router-dom';
import BrandCreditsLayout from './layout';
import BrandCreditsPage from './credits/page';
import BrandPaymentMethodsPage from './payment-methods/page';

const brandCreditsPath = '/brand/:id/credits';
const brandPaymentMethodsPath = '/brand/:id/payment-methods';

export const brandCreditsPaths = [brandCreditsPath, brandPaymentMethodsPath];

export default function BrandCredits() {
  return (
    <BrandCreditsLayout>
      <Switch>
        <Route exact path={brandCreditsPath}>
          <BrandCreditsPage />
        </Route>
        <Route exact path={brandPaymentMethodsPath}>
          <BrandPaymentMethodsPage />
        </Route>
      </Switch>
    </BrandCreditsLayout>
  );
}
