const onClick = event => {
  event.preventDefault();
  const href = event.target.getAttribute('href');

  const element = document.querySelector(href);

  if (element?.scrollIntoView) {
    element.scrollIntoView();
  }
};

export const SummaryHeader = ({ children, highlight = false, panelKey }) => (
  <div className={`flex items-center space-x-2 mt-4 mb-1 ${highlight ? 'text-green-500' : 'text-secondary'}`}>
    <h3 className="text-md w-full font-medium">
      <a
        href={panelKey ? `#bp-${panelKey.replaceAll(' ', '')}` : null}
        onClick={onClick}
        className="border-b-2 border-gray-100 text-gray-900 hover:text-primary block w-full"
      >
        {children}
      </a>
    </h3>
  </div>
);
