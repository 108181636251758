import { useMemo, useState, useEffect } from 'react';
import { Campaign } from 'src/models';
import { AdNetworkExchangeIcon } from 'src/svgs';
import { bzColorPallets } from 'src/helpers';
import { getDataAppMetricsWithConversionBreakdown } from 'src/helpers/getDataAppMetrics';
import Color from 'color';
import { useGAS } from 'src/GlobalAppState/context';
import { AdNetworkGraph } from './AdNetworkGraph';
import { DevicesGraph } from './DevicesGraph';
import { GraphDropdown } from '../PerformanceSpend/GraphDropdown';
import { DownloadCsvButton } from '../shared/DownloadCsvButton';

const requestExchangeMetrics = campaignId =>
  getDataAppMetricsWithConversionBreakdown(Campaign, campaignId, 'CAMPAIGN_AD_NETWORK').then(resp =>
    (resp.items || []).sort((a, b) => (a?.impressions > b?.impressions ? 1 : -1))
  );

const requestDeviceMetrics = campaignId =>
  getDataAppMetricsWithConversionBreakdown(Campaign, campaignId, 'CAMPAIGN_DEVICES').then(resp => resp.items || []);

const defaultColors = ['#1F2937', '#D1D5DB', '#374151', '#9CA3AF', '#4B5563', '#6B7280'];

const useChartColors = () => {
  const { theme } = useGAS();

  return useMemo(() => {
    const palletKey = theme?.pallet || 'The Blues';
    const { [palletKey]: selectedColor } = bzColorPallets || {};
    const { colors = defaultColors.slice() } = selectedColor || {};

    return {
      colors,
      lightColors: colors.map(c => new Color(c).alpha(0.8).string()),
    };
  }, [theme]);
};

export const AdNetworkExchange = ({ campaign = {} }) => {
  const [selected, setSelected] = useState('impressions');
  const [deviceMetrics, setDeviceMetrics] = useState([]);
  const [exchangeMetrics, setExchangeMetrics] = useState([]);
  const [loading, setLoading] = useState(true);
  const { colors, lightColors } = useChartColors();

  useEffect(() => {
    let mounted = true;

    if (campaign.id) {
      setLoading(true);
      Promise.all([requestExchangeMetrics(campaign.id), requestDeviceMetrics(campaign.id)]).then(
        ([exchange, device]) => {
          if (!mounted) return;

          setExchangeMetrics(exchange);
          setDeviceMetrics(device);
          setLoading(false);
        }
      );
    }

    return () => (mounted = false);
  }, [campaign.id]);

  const empty = !loading && deviceMetrics.length < 1;

  if (loading || empty) return <div />;

  return (
    <div className="w-full flex flex-col space-y-4 rounded shadow bg-white p-4">
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-6">
          <AdNetworkExchangeIcon className="w-11 h-11 stroke-current text-primary" />
          <h1 className="text-gray-900 text-xl font-semibold">Ad Networks and Device Types</h1>
        </div>
        <div className="flex flex-row space-x-4">
          <DownloadCsvButton
            metricType="CAMPAIGN_AD_NETWORK"
            metrics={exchangeMetrics}
            loading={loading}
            campaign={campaign}
          >
            Download Ad Exchange CSV
          </DownloadCsvButton>
          <DownloadCsvButton
            metricType="CAMPAIGN_DEVICES"
            metrics={deviceMetrics}
            loading={loading}
            campaign={campaign}
          >
            Download Devices CSV
          </DownloadCsvButton>
        </div>
      </div>
      <GraphDropdown selected={selected} setSelected={setSelected} />
      <div className="w-full flex justify-evenly items-center py-12">
        <div className="flex-1 flex max-w-xl items-center space-x-8">
          <AdNetworkGraph colors={colors} lightColors={lightColors} selected={selected} metrics={exchangeMetrics} />
        </div>
        <div className="flex-1 flex max-w-xl items-center space-x-8">
          <DevicesGraph
            campaign={campaign}
            campaignId={campaign.id}
            metrics={deviceMetrics}
            colors={colors}
            lightColors={lightColors}
            selected={selected}
          />
        </div>
      </div>
    </div>
  );
};
