import { addHours } from 'date-fns';
import { PerformanceSpend } from './PerformanceSpend';
import { Geography } from './Geography';
import { TopSites } from './TopSites';
import { TopCities } from './TopCities';
import { AdNetworkExchange } from './AdNetworkExchange';
import { TotalMetrics } from './TotalMetrics';
import { Creatives } from './Creatives';
import { NoDataYetEmptyState, NotStartedEmptyState } from './EmptyStates';
import { CampaignReportsCard } from '../CampaignReports';

export const CampaignAnalytics = ({ appParams, campaign = {} }) => {
  const { name, startDate, status, summaryMetrics } = campaign;

  const beforeStartDate = new Date(startDate) > new Date();
  const atLeastDayAfterStartDate = addHours(new Date(startDate), 36) < new Date();

  const completed = status === 'completed' || status === 'stopping' || status === 'stopped';
  const draft = status === 'draft';
  const notYetStarted = !completed && (beforeStartDate || draft);

  const noData = !summaryMetrics;

  if (notYetStarted) {
    return <NotStartedEmptyState />;
  }

  if (noData && !atLeastDayAfterStartDate && !completed) {
    return <NoDataYetEmptyState />;
  }
  const hasSpent = (summaryMetrics?.items || []).reduce((acc, itm) => acc + itm.spend || 0, 0);
  const isActive = status && status !== 'draft';
  const reportingEnabled = isActive && hasSpent;

  return (
    <div className="flex-1 flex flex-col h-full overflow-y-scroll py-3 space-y-3">
      <div className="w-full h-20 min-h-20 rounded bg-white shadow border-gray-200 flex items-center justify-between p-4 relative">
        <p className="text-3xl text-primary capitalize ml-2 truncate w-full" title={name}>
          {`${name} - Analytics`}
        </p>
      </div>
      <TotalMetrics campaign={campaign} appParams={appParams} />
      {reportingEnabled && <CampaignReportsCard campaign={campaign} />}
      <PerformanceSpend campaign={campaign} appParams={appParams} />
      <Creatives campaign={campaign} appParams={appParams} />
      <TopSites campaign={campaign} appParams={appParams} />
      <AdNetworkExchange campaign={campaign} />
      <TopCities campaign={campaign} appParams={appParams} />
      <Geography campaign={campaign} appParams={appParams} />
    </div>
  );
};
