import { useMemo } from 'react';
import { Doughnut } from 'react-chartjs-2';

export const AdNetworkGraph = ({ colors, lightColors, selected, metrics: inputMetrics }) => {
  const metrics = useMemo(() => (inputMetrics || []).slice(0, 5), [inputMetrics]);

  const options = {
    plugins: {
      legend: {
        display: false,
        maintainAspectRatio: false,
      },
    },
  };

  const data = {
    labels: metrics.map(m => m.metricKey),
    datasets: [
      {
        data: getFilteredMetrics(metrics, selected),
        backgroundColor: lightColors.slice(0, (metrics || []).length),
        borderColor: colors.slice(0, (metrics || []).length),
        borderWidth: 2,
        cutout: '75%',
      },
    ],
  };

  const legendItems = metrics.map((m, idx) => (
    <div key={`adnetwork-${idx}`} className="flex space-x-2 items-center">
      <div style={{ backgroundColor: colors[idx] }} className="w-5 h-5 rounded-full mx-2" />
      <h1 className="tracking-tight">{m.metricKey}</h1>
    </div>
  ));

  return (
    <>
      <div className="w-52 h-52 relative">
        <div className="w-full h-full absolute">
          <Doughnut height={220} width={220} options={options} data={data} />
        </div>
        <div className="w-full h-full flex  justify-center items-center">
          <h1 className="text-xl font-medium">Ad Exchanges</h1>
        </div>
      </div>
      <div className="flex flex-col w-40 space-y-4 ml-2">{legendItems}</div>
    </>
  );
};

const getFilteredMetrics = (metrics, selected) => {
  if (selected === 'impressions') {
    return (metrics || []).map(m => m.impressions);
  }
  if (selected === 'clicks') {
    return (metrics || []).map(m => m.clicks);
  }
  if (selected === 'views') {
    return (metrics || []).map(m => m.views);
  }
  if (selected === 'spend') {
    return (metrics || []).map(m => m.spend);
  }
  if (selected === 'conversions') {
    return (metrics || []).map(m => m.conversions);
  }

  return [];
};
