import React, { useState, useEffect, useRef, useMemo } from 'react';
import { AddUserIcon } from 'src/svgs';
import { useGAS } from 'src/GlobalAppState/context';
import { TeamUserRow } from 'src/components/MainSettings/TeamSettings/TeamUserRow';
import { TeamUserNewRow } from 'src/components/MainSettings/TeamSettings/TeamUserNewRow';
import { Spinner } from 'src/components/shared/Spinner';
import { Headline } from 'src/components/MainSettings/shared/Headers';

export default function TeamSettingsUsersPage() {
  const { orgAuthRoles, team, user, userAuth } = useGAS();

  const userAuthRoles = useMemo(() => orgAuthRoles.teams[team.id] ?? [], [orgAuthRoles.teams, team.id]);

  const { loading } = orgAuthRoles;

  const [showNew, setShowNew] = useState(false);
  const [sending, setSending] = useState('');
  const inputRef = useRef();
  const { userId } = userAuth;
  const { email: userEmail } = user;

  useEffect(() => {
    if (showNew && inputRef.current) inputRef.current.focus();
  }, [showNew]);

  useEffect(() => {
    if (sending) {
      const [existing] = (userAuthRoles || []).filter(a => a.email === sending && !a.brandId);

      if (existing?.email) setSending('');
    }
  }, [sending, userAuthRoles]);

  const shouldShowUser = role => {
    const isBZUser = userEmail?.includes('brandzooka');
    const roleHasBZEmail = role?.email?.includes('brandzooka');
    const isNotOwnerOrBrand = role.teamId === team.id && role.status !== 'owner' && !role.brandId;

    // If logged in user is not BZ, only show non-BZ email roles
    if (!isBZUser) {
      return !roleHasBZEmail && isNotOwnerOrBrand;
    }

    return isNotOwnerOrBrand;
  };

  const users = team ? (
    (userAuthRoles || [])
      .filter(shouldShowUser)
      .map((userAuthRole, idx) => (
        <TeamUserRow key={userAuthRole.id} userAuthRole={userAuthRole} userId={userId} odd={!(idx % 2)} />
      ))
  ) : (
    <tr />
  );

  const onAddUser = () => setShowNew(!showNew);

  const showNewRow = showNew || !users.length;

  return (
    <div>
      <Headline>{team.name} Users</Headline>
      <div>
        {loading ? (
          <div className="w-full h-full flex justify-center items-center top-0 left-0">
            <Spinner />
          </div>
        ) : (
          <table className="h-auto min-w-full divide-y divide-gray-200">
            <thead className="bg-white">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-sm font-semibold text-gray-500 uppercase tracking-wider"
                >
                  <button type="button" onClick={onAddUser} className="bz-btn-icon">
                    <AddUserIcon className="w-8 h-8 text-primary" />
                  </button>
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-sm font-semibold text-gray-500 uppercase tracking-wider"
                >
                  User
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-sm font-semibold text-gray-500 uppercase tracking-wider"
                >
                  Status
                </th>
                <th scope="col" className="w-24 relative px-6 py-3">
                  <span className="sr-only">Remove</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {users}
              {sending && (
                <tr className="text-md text-gray-500">
                  <td label="brand-user-row" className="w-24 px-6 py-4" />
                  <td data-mf-replace="" className="px-6 py-4 w-full">
                    {sending}
                  </td>
                  <td className="w-40 px-6 py-4">sending invite...</td>
                  <td label="brand-user-spacer" />
                </tr>
              )}
              {showNewRow && (
                <TeamUserNewRow
                  setOpen={setShowNew}
                  inputRef={inputRef}
                  team={team}
                  setSending={setSending}
                  setShowNew={setShowNew}
                />
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}
