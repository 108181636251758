import { adgroupDisplayNames } from 'src/constants/adgroups';
import { BsExclamationTriangle } from 'react-icons/bs';
import NumberFormat from 'react-number-format';

export const AdgroupsBudgetList = ({ adgroups, isRecurring, recurrenceWindow }) =>
  adgroups.map(item => (
    <div
      key={item.adgroupType}
      className={`p-1 pl-2 border-top-left-right-gray-300 ${item.warning && 'bg-orange-100'}`}
    >
      {item.warning && (
        <span className="float-left pr-2">
          <BsExclamationTriangle />
        </span>
      )}
      <span>{adgroupDisplayNames[item.adgroupType]}</span>
      <span className="float-right">
        <NumberFormat
          value={Number(item.budget)}
          displayType="text"
          className="text-gray-800"
          prefix="$"
          decimalScale={2}
          fixedDecimalScale
          thousandSeparator
        />
        {isRecurring && recurrenceWindow === 7 && <span>/weekly</span>}
        {isRecurring && recurrenceWindow === 30 && <span>/30 days</span>}
      </span>
    </div>
  ));
