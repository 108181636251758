/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const daSearchSegmentPathChildren = /* GraphQL */ `
  query DaSearchSegmentPathChildren($filter: SearchableSegmentFilterInput) {
    daSearchSegmentPathChildren(filter: $filter) {
      items {
        key
        doc_count
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const daSearchSegments = /* GraphQL */ `
  query DaSearchSegments(
    $filter: SearchableSegmentFilterInput
    $sort: [SearchableSegmentSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableSegmentAggregationInput]
  ) {
    daSearchSegments(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        createdAt
        updatedAt
        segmentType
        segmentGroup
        segmentPackageName
        segmentPackageId
        pathKey
        name
        dspSegments {
          items {
            id
            dspSegmentType
            dspSegmentGroup
            dspSegmentId
            pathKey
            __typename
          }
          nextToken
          __typename
        }
        geoJson {
          type
          coordinates
          __typename
        }
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          buckets {
            key
            doc_count
            __typename
          }
          value
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const daSearchMetrics = /* GraphQL */ `
  query DaSearchMetrics(
    $filter: DaSearchableMetricsFilterInput
    $metricKeyRange: [String]
    $sort: [DaSearchableMetricsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [DaSearchableMetricsAggregationInput]
  ) {
    daSearchMetrics(
      filter: $filter
      metricKeyRange: $metricKeyRange
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        adminGroups
        viewerGroups
        createdAt
        updatedAt
        organizationId
        teamId
        brandId
        campaignId
        oMar
        tMar
        bMar
        metricType
        metricKey
        clicks
        views
        impressions
        conversions
        conversionBreakdown
        spend
        geoJson {
          type
          coordinates
          __typename
        }
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          buckets {
            key
            doc_count
            __typename
          }
          value
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const daSearchSegmentGeoIntersect = /* GraphQL */ `
  query DaSearchSegmentGeoIntersect(
    $id: ID!
    $segmentGroup: String
    $limit: Int
    $nextToken: String
  ) {
    daSearchSegmentGeoIntersect(
      id: $id
      segmentGroup: $segmentGroup
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        segmentType
        segmentGroup
        segmentPackageName
        segmentPackageId
        pathKey
        name
        dspSegments {
          items {
            id
            dspSegmentType
            dspSegmentGroup
            dspSegmentId
            pathKey
            __typename
          }
          nextToken
          __typename
        }
        geoJson {
          type
          coordinates
          __typename
        }
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          buckets {
            key
            doc_count
            __typename
          }
          value
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const daSearchMetricsGeoIntersect = /* GraphQL */ `
  query DaSearchMetricsGeoIntersect($id: ID!, $limit: Int, $nextToken: String) {
    daSearchMetricsGeoIntersect(id: $id, limit: $limit, nextToken: $nextToken) {
      items {
        id
        adminGroups
        viewerGroups
        createdAt
        updatedAt
        organizationId
        teamId
        brandId
        campaignId
        oMar
        tMar
        bMar
        metricType
        metricKey
        clicks
        views
        impressions
        conversions
        conversionBreakdown
        spend
        geoJson {
          type
          coordinates
          __typename
        }
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          buckets {
            key
            doc_count
            __typename
          }
          value
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const daSearchMetricsGeoAgg = /* GraphQL */ `
  query DaSearchMetricsGeoAgg(
    $campaignId: ID
    $segments: [DaSearchSegment]
    $metricKeyRange: [String]
    $limit: Int
    $nextToken: String
  ) {
    daSearchMetricsGeoAgg(
      campaignId: $campaignId
      segments: $segments
      metricKeyRange: $metricKeyRange
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        name
        impressions
        clicks
        views
        spend
        conversions
        __typename
      }
      total
      __typename
    }
  }
`;
export const getAccountTheme = /* GraphQL */ `
  query GetAccountTheme($subdomain: String) {
    getAccountTheme(subdomain: $subdomain)
  }
`;
export const getInviteLinkByCode = /* GraphQL */ `
  query GetInviteLinkByCode($inviteCode: String) {
    getInviteLinkByCode(inviteCode: $inviteCode)
  }
`;
export const checkInviteLinkEmail = /* GraphQL */ `
  query CheckInviteLinkEmail($inviteCode: String, $email: String) {
    checkInviteLinkEmail(inviteCode: $inviteCode, email: $email)
  }
`;
export const getBrandProtected = /* GraphQL */ `
  query GetBrandProtected($id: ID!) {
    getBrandProtected(id: $id) {
      id
      config
      accountConfig
      accountSettings
      paymentMethods {
        items {
          stripeId
          nickname
          lastFour
          cardType
          default
          __typename
        }
        __typename
      }
      oMar
      tMar
      bMar
      __typename
    }
  }
`;
export const verifyPixel = /* GraphQL */ `
  query VerifyPixel($brandId: String) {
    verifyPixel(brandId: $brandId)
  }
`;
export const checkFeatureFlag = /* GraphQL */ `
  query CheckFeatureFlag($id: ID!) {
    checkFeatureFlag(id: $id)
  }
`;
export const getMargins = /* GraphQL */ `
  query GetMargins($input: GetMarginsInput) {
    getMargins(input: $input) {
      id
      defaultValue
      recordType
      records {
        id
        recordType
        value
        __typename
      }
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      cognitoId
      userId
      authGroup
      createdAt
      updatedAt
      selectedAuth
      firstName
      lastName
      profileImageUrl
      hasBeenWelcomed
      email
      authRoles {
        items {
          id
          createdAt
          updatedAt
          adminGroups
          viewerGroups
          status
          email
          userId
          authRole
          staticAuthRole
          organizationId
          organization {
            id
            archived
            adminGroups
            viewerGroups
            memberGroups
            createdAt
            updatedAt
            name
            logoUrl
            accountDomain
            accountSubdomain
            accountConfig
            config
            availableCredits
            oMar
            defaultTeamMarkup
            segmentPackages
            __typename
          }
          teamId
          team {
            id
            archived
            adminGroups
            viewerGroups
            memberGroups
            createdAt
            updatedAt
            organizationId
            name
            websiteUrl
            logoUrl
            industryCategoryId
            config
            availableCredits
            tMar
            defaultBrandMarkup
            segmentPackages
            defaultStripeId
            organizationTeamsId
            __typename
          }
          brandId
          brand {
            id
            archived
            adminGroups
            viewerGroups
            createdAt
            updatedAt
            organizationId
            teamId
            advertiserId
            name
            websiteUrl
            logoUrl
            industryCategoryId
            campaignPostProcessors
            config
            availableCredits
            bMar
            pixelCode
            pixelTracking
            pixelAudienceSize
            pixelMappingSizes
            segmentPackages
            isValid
            error
            defaultStripeId
            underReview
            teamBrandsId
            __typename
          }
          inviteCode
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoId
        userId
        authGroup
        createdAt
        updatedAt
        selectedAuth
        firstName
        lastName
        profileImageUrl
        hasBeenWelcomed
        email
        authRoles {
          items {
            id
            createdAt
            updatedAt
            adminGroups
            viewerGroups
            status
            email
            userId
            authRole
            staticAuthRole
            organizationId
            teamId
            brandId
            inviteCode
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listUserByEmail = /* GraphQL */ `
  query ListUserByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoId
        userId
        authGroup
        createdAt
        updatedAt
        selectedAuth
        firstName
        lastName
        profileImageUrl
        hasBeenWelcomed
        email
        authRoles {
          items {
            id
            createdAt
            updatedAt
            adminGroups
            viewerGroups
            status
            email
            userId
            authRole
            staticAuthRole
            organizationId
            teamId
            brandId
            inviteCode
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserAuthRole = /* GraphQL */ `
  query GetUserAuthRole($id: ID!) {
    getUserAuthRole(id: $id) {
      id
      createdAt
      updatedAt
      adminGroups
      viewerGroups
      status
      email
      userId
      authRole
      staticAuthRole
      organizationId
      organization {
        id
        archived
        adminGroups
        viewerGroups
        memberGroups
        createdAt
        updatedAt
        name
        logoUrl
        accountDomain
        accountSubdomain
        accountInfo {
          owner
          ownerEmail
          website
          companyRole
          companyLocation
          companySize
          referencedBy
          industryCategoryId
          sfAccountId
          sfContactId
          sfOwnerId
          ownerPhoneNumber
          __typename
        }
        theme {
          primary
          secondary
          pallet
          __typename
        }
        accountConfig
        config
        accountSettings {
          accountType
          accountTier
          onboardingStatus
          teamId
          brandId
          role
          companySize
          address {
            street
            unitNumber
            city
            state
            postalCode
            country
            __typename
          }
          __typename
        }
        availableCredits
        paymentMethods {
          items {
            stripeId
            nickname
            lastFour
            cardType
            default
            __typename
          }
          __typename
        }
        oMar
        markups {
          oMar
          tMar
          bMar
          __typename
        }
        defaultTeamMarkup
        summaryMetrics {
          items {
            summaryType
            impressions
            clicks
            views
            spend
            conversions
            __typename
          }
          total
          __typename
        }
        segmentPackages
        teams {
          items {
            id
            archived
            adminGroups
            viewerGroups
            memberGroups
            createdAt
            updatedAt
            organizationId
            name
            websiteUrl
            logoUrl
            industryCategoryId
            config
            availableCredits
            tMar
            defaultBrandMarkup
            segmentPackages
            defaultStripeId
            organizationTeamsId
            __typename
          }
          nextToken
          __typename
        }
        whitelabelConfig {
          disableBrandCreditSelfService
          __typename
        }
        overrides {
          minimumCampaignBudget
          __typename
        }
        __typename
      }
      teamId
      team {
        id
        archived
        adminGroups
        viewerGroups
        memberGroups
        createdAt
        updatedAt
        organizationId
        organization {
          id
          archived
          adminGroups
          viewerGroups
          memberGroups
          createdAt
          updatedAt
          name
          logoUrl
          accountDomain
          accountSubdomain
          accountInfo {
            owner
            ownerEmail
            website
            companyRole
            companyLocation
            companySize
            referencedBy
            industryCategoryId
            sfAccountId
            sfContactId
            sfOwnerId
            ownerPhoneNumber
            __typename
          }
          theme {
            primary
            secondary
            pallet
            __typename
          }
          accountConfig
          config
          accountSettings {
            accountType
            accountTier
            onboardingStatus
            teamId
            brandId
            role
            companySize
            __typename
          }
          availableCredits
          paymentMethods {
            __typename
          }
          oMar
          markups {
            oMar
            tMar
            bMar
            __typename
          }
          defaultTeamMarkup
          summaryMetrics {
            total
            __typename
          }
          segmentPackages
          teams {
            nextToken
            __typename
          }
          whitelabelConfig {
            disableBrandCreditSelfService
            __typename
          }
          overrides {
            minimumCampaignBudget
            __typename
          }
          __typename
        }
        name
        websiteUrl
        logoUrl
        industryCategoryId
        config
        availableCredits
        paymentMethods {
          items {
            stripeId
            nickname
            lastFour
            cardType
            default
            __typename
          }
          __typename
        }
        tMar
        markups {
          oMar
          tMar
          bMar
          __typename
        }
        defaultBrandMarkup
        theme {
          primary
          secondary
          pallet
          __typename
        }
        summaryMetrics {
          items {
            summaryType
            impressions
            clicks
            views
            spend
            conversions
            __typename
          }
          total
          __typename
        }
        segmentPackages
        brands {
          items {
            id
            archived
            adminGroups
            viewerGroups
            createdAt
            updatedAt
            organizationId
            teamId
            advertiserId
            name
            websiteUrl
            logoUrl
            industryCategoryId
            campaignPostProcessors
            config
            availableCredits
            bMar
            pixelCode
            pixelTracking
            pixelAudienceSize
            pixelMappingSizes
            segmentPackages
            isValid
            error
            defaultStripeId
            underReview
            teamBrandsId
            __typename
          }
          nextToken
          __typename
        }
        defaultStripeId
        organizationTeamsId
        __typename
      }
      brandId
      brand {
        id
        archived
        adminGroups
        viewerGroups
        createdAt
        updatedAt
        organizationId
        teamId
        team {
          id
          archived
          adminGroups
          viewerGroups
          memberGroups
          createdAt
          updatedAt
          organizationId
          organization {
            id
            archived
            adminGroups
            viewerGroups
            memberGroups
            createdAt
            updatedAt
            name
            logoUrl
            accountDomain
            accountSubdomain
            accountConfig
            config
            availableCredits
            oMar
            defaultTeamMarkup
            segmentPackages
            __typename
          }
          name
          websiteUrl
          logoUrl
          industryCategoryId
          config
          availableCredits
          paymentMethods {
            __typename
          }
          tMar
          markups {
            oMar
            tMar
            bMar
            __typename
          }
          defaultBrandMarkup
          theme {
            primary
            secondary
            pallet
            __typename
          }
          summaryMetrics {
            total
            __typename
          }
          segmentPackages
          brands {
            nextToken
            __typename
          }
          defaultStripeId
          organizationTeamsId
          __typename
        }
        advertiserId
        name
        websiteUrl
        logoUrl
        industryCategoryId
        politicalSettings {
          politicalType
          electionType
          candidate {
            PoliticalDataId
            CandidateName
            ElectionState
            PayingEntityName
            PayingEntityAddress
            PayingEntityEmailAddress
            PayingEntityExecutiveName
            PayingEntityExecutiveTitle
            GeoDescription
            AudienceDescription
            DisclaimerNotice
            FileLocationInS3
            FederalVerificationId
            StateVerificationId
            IsAdvertiserIEC
            __typename
          }
          status
          ballotMeasureProfile {
            PayingEntityName
            PayingEntityAddress
            PayingEntityExecutiveName
            PayingEntityExecutiveTitle
            ElectionState
            __typename
          }
          __typename
        }
        campaignPostProcessors
        config
        availableCredits
        paymentMethods {
          items {
            stripeId
            nickname
            lastFour
            cardType
            default
            __typename
          }
          __typename
        }
        bMar
        markups {
          oMar
          tMar
          bMar
          __typename
        }
        universalPixel {
          UniversalPixelMappings {
            __typename
          }
          UniversalPixelId
          UniversalPixelName
          AdvertiserId
          __typename
        }
        pixelCode
        pixelTracking
        pixelAudienceSize
        pixelMappingSizes
        theme {
          primary
          secondary
          pallet
          __typename
        }
        campaignSummary {
          running
          stopped
          completed
          __typename
        }
        creativeSummary {
          audio
          display
          video
          __typename
        }
        reportSummary {
          forecast
          summary
          __typename
        }
        summaryMetrics {
          items {
            summaryType
            impressions
            clicks
            views
            spend
            conversions
            __typename
          }
          total
          __typename
        }
        segmentPackages
        campaigns {
          items {
            id
            archived
            adminGroups
            viewerGroups
            createdAt
            updatedAt
            stoppedAt
            approvedAt
            lastRecurredAt
            organizationId
            teamId
            brandId
            status
            recurrenceWindow
            recurrenceBudget
            name
            goal
            budget
            originalBudget
            dailyBudget
            startDate
            endDate
            originalEndDate
            audienceType
            audienceFrom
            timesOfDay
            daysOfWeek
            forecastRequestedAt
            forecastAdgroups
            publishedAt
            ttdId
            conversionsEnabled
            trackingTagId
            pixelTrackingUrl
            ttdStatus
            dspIds
            audience
            bidlists
            ttdUpdatedAt
            activeUpdatedAt
            sfOpportunityId
            ctvSegments
            isRecurring
            isValid
            error
            isMultiDataGroups
            additionalBidLists
            postProcessors
            brandCampaignsId
            __typename
          }
          nextToken
          __typename
        }
        trackingTags {
          items {
            id
            archived
            trackingTagType
            adminGroups
            viewerGroups
            createdAt
            updatedAt
            errorNotifiedAt
            organizationId
            teamId
            brandId
            dspIds
            status
            ttdId
            name
            pixelCode
            pixelAudienceSize
            pixelUrl
            remainingDays
            piiType
            region
            retainPii
            brandTrackingTagsId
            __typename
          }
          nextToken
          __typename
        }
        isValid
        error
        defaultStripeId
        underReview
        teamBrandsId
        __typename
      }
      inviteCode
      __typename
    }
  }
`;
export const listUserAuthRoles = /* GraphQL */ `
  query ListUserAuthRoles(
    $filter: ModelUserAuthRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserAuthRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        adminGroups
        viewerGroups
        status
        email
        userId
        authRole
        staticAuthRole
        organizationId
        organization {
          id
          archived
          adminGroups
          viewerGroups
          memberGroups
          createdAt
          updatedAt
          name
          logoUrl
          accountDomain
          accountSubdomain
          accountInfo {
            owner
            ownerEmail
            website
            companyRole
            companyLocation
            companySize
            referencedBy
            industryCategoryId
            sfAccountId
            sfContactId
            sfOwnerId
            ownerPhoneNumber
            __typename
          }
          theme {
            primary
            secondary
            pallet
            __typename
          }
          accountConfig
          config
          accountSettings {
            accountType
            accountTier
            onboardingStatus
            teamId
            brandId
            role
            companySize
            __typename
          }
          availableCredits
          paymentMethods {
            __typename
          }
          oMar
          markups {
            oMar
            tMar
            bMar
            __typename
          }
          defaultTeamMarkup
          summaryMetrics {
            total
            __typename
          }
          segmentPackages
          teams {
            nextToken
            __typename
          }
          whitelabelConfig {
            disableBrandCreditSelfService
            __typename
          }
          overrides {
            minimumCampaignBudget
            __typename
          }
          __typename
        }
        teamId
        team {
          id
          archived
          adminGroups
          viewerGroups
          memberGroups
          createdAt
          updatedAt
          organizationId
          organization {
            id
            archived
            adminGroups
            viewerGroups
            memberGroups
            createdAt
            updatedAt
            name
            logoUrl
            accountDomain
            accountSubdomain
            accountConfig
            config
            availableCredits
            oMar
            defaultTeamMarkup
            segmentPackages
            __typename
          }
          name
          websiteUrl
          logoUrl
          industryCategoryId
          config
          availableCredits
          paymentMethods {
            __typename
          }
          tMar
          markups {
            oMar
            tMar
            bMar
            __typename
          }
          defaultBrandMarkup
          theme {
            primary
            secondary
            pallet
            __typename
          }
          summaryMetrics {
            total
            __typename
          }
          segmentPackages
          brands {
            nextToken
            __typename
          }
          defaultStripeId
          organizationTeamsId
          __typename
        }
        brandId
        brand {
          id
          archived
          adminGroups
          viewerGroups
          createdAt
          updatedAt
          organizationId
          teamId
          team {
            id
            archived
            adminGroups
            viewerGroups
            memberGroups
            createdAt
            updatedAt
            organizationId
            name
            websiteUrl
            logoUrl
            industryCategoryId
            config
            availableCredits
            tMar
            defaultBrandMarkup
            segmentPackages
            defaultStripeId
            organizationTeamsId
            __typename
          }
          advertiserId
          name
          websiteUrl
          logoUrl
          industryCategoryId
          politicalSettings {
            politicalType
            electionType
            status
            __typename
          }
          campaignPostProcessors
          config
          availableCredits
          paymentMethods {
            __typename
          }
          bMar
          markups {
            oMar
            tMar
            bMar
            __typename
          }
          universalPixel {
            UniversalPixelId
            UniversalPixelName
            AdvertiserId
            __typename
          }
          pixelCode
          pixelTracking
          pixelAudienceSize
          pixelMappingSizes
          theme {
            primary
            secondary
            pallet
            __typename
          }
          campaignSummary {
            running
            stopped
            completed
            __typename
          }
          creativeSummary {
            audio
            display
            video
            __typename
          }
          reportSummary {
            forecast
            summary
            __typename
          }
          summaryMetrics {
            total
            __typename
          }
          segmentPackages
          campaigns {
            nextToken
            __typename
          }
          trackingTags {
            nextToken
            __typename
          }
          isValid
          error
          defaultStripeId
          underReview
          teamBrandsId
          __typename
        }
        inviteCode
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userAuthRoleByInviteCode = /* GraphQL */ `
  query UserAuthRoleByInviteCode(
    $inviteCode: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserAuthRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userAuthRoleByInviteCode(
      inviteCode: $inviteCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        adminGroups
        viewerGroups
        status
        email
        userId
        authRole
        staticAuthRole
        organizationId
        organization {
          id
          archived
          adminGroups
          viewerGroups
          memberGroups
          createdAt
          updatedAt
          name
          logoUrl
          accountDomain
          accountSubdomain
          accountInfo {
            owner
            ownerEmail
            website
            companyRole
            companyLocation
            companySize
            referencedBy
            industryCategoryId
            sfAccountId
            sfContactId
            sfOwnerId
            ownerPhoneNumber
            __typename
          }
          theme {
            primary
            secondary
            pallet
            __typename
          }
          accountConfig
          config
          accountSettings {
            accountType
            accountTier
            onboardingStatus
            teamId
            brandId
            role
            companySize
            __typename
          }
          availableCredits
          paymentMethods {
            __typename
          }
          oMar
          markups {
            oMar
            tMar
            bMar
            __typename
          }
          defaultTeamMarkup
          summaryMetrics {
            total
            __typename
          }
          segmentPackages
          teams {
            nextToken
            __typename
          }
          whitelabelConfig {
            disableBrandCreditSelfService
            __typename
          }
          overrides {
            minimumCampaignBudget
            __typename
          }
          __typename
        }
        teamId
        team {
          id
          archived
          adminGroups
          viewerGroups
          memberGroups
          createdAt
          updatedAt
          organizationId
          organization {
            id
            archived
            adminGroups
            viewerGroups
            memberGroups
            createdAt
            updatedAt
            name
            logoUrl
            accountDomain
            accountSubdomain
            accountConfig
            config
            availableCredits
            oMar
            defaultTeamMarkup
            segmentPackages
            __typename
          }
          name
          websiteUrl
          logoUrl
          industryCategoryId
          config
          availableCredits
          paymentMethods {
            __typename
          }
          tMar
          markups {
            oMar
            tMar
            bMar
            __typename
          }
          defaultBrandMarkup
          theme {
            primary
            secondary
            pallet
            __typename
          }
          summaryMetrics {
            total
            __typename
          }
          segmentPackages
          brands {
            nextToken
            __typename
          }
          defaultStripeId
          organizationTeamsId
          __typename
        }
        brandId
        brand {
          id
          archived
          adminGroups
          viewerGroups
          createdAt
          updatedAt
          organizationId
          teamId
          team {
            id
            archived
            adminGroups
            viewerGroups
            memberGroups
            createdAt
            updatedAt
            organizationId
            name
            websiteUrl
            logoUrl
            industryCategoryId
            config
            availableCredits
            tMar
            defaultBrandMarkup
            segmentPackages
            defaultStripeId
            organizationTeamsId
            __typename
          }
          advertiserId
          name
          websiteUrl
          logoUrl
          industryCategoryId
          politicalSettings {
            politicalType
            electionType
            status
            __typename
          }
          campaignPostProcessors
          config
          availableCredits
          paymentMethods {
            __typename
          }
          bMar
          markups {
            oMar
            tMar
            bMar
            __typename
          }
          universalPixel {
            UniversalPixelId
            UniversalPixelName
            AdvertiserId
            __typename
          }
          pixelCode
          pixelTracking
          pixelAudienceSize
          pixelMappingSizes
          theme {
            primary
            secondary
            pallet
            __typename
          }
          campaignSummary {
            running
            stopped
            completed
            __typename
          }
          creativeSummary {
            audio
            display
            video
            __typename
          }
          reportSummary {
            forecast
            summary
            __typename
          }
          summaryMetrics {
            total
            __typename
          }
          segmentPackages
          campaigns {
            nextToken
            __typename
          }
          trackingTags {
            nextToken
            __typename
          }
          isValid
          error
          defaultStripeId
          underReview
          teamBrandsId
          __typename
        }
        inviteCode
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      archived
      adminGroups
      viewerGroups
      memberGroups
      createdAt
      updatedAt
      name
      logoUrl
      accountDomain
      accountSubdomain
      accountInfo {
        owner
        ownerEmail
        website
        companyRole
        companyLocation
        companySize
        referencedBy
        industryCategoryId
        sfAccountId
        sfContactId
        sfOwnerId
        ownerPhoneNumber
        __typename
      }
      theme {
        primary
        secondary
        pallet
        __typename
      }
      accountConfig
      config
      accountSettings {
        accountType
        accountTier
        onboardingStatus
        teamId
        brandId
        role
        companySize
        address {
          street
          unitNumber
          city
          state
          postalCode
          country
          __typename
        }
        __typename
      }
      availableCredits
      paymentMethods {
        items {
          stripeId
          nickname
          lastFour
          cardType
          default
          __typename
        }
        __typename
      }
      oMar
      markups {
        oMar
        tMar
        bMar
        __typename
      }
      defaultTeamMarkup
      summaryMetrics {
        items {
          summaryType
          impressions
          clicks
          views
          spend
          conversions
          graphs {
            impressions
            clicks
            views
            spend
            conversions
            __typename
          }
          __typename
        }
        total
        __typename
      }
      segmentPackages
      teams {
        items {
          id
          archived
          adminGroups
          viewerGroups
          memberGroups
          createdAt
          updatedAt
          organizationId
          organization {
            id
            archived
            adminGroups
            viewerGroups
            memberGroups
            createdAt
            updatedAt
            name
            logoUrl
            accountDomain
            accountSubdomain
            accountConfig
            config
            availableCredits
            oMar
            defaultTeamMarkup
            segmentPackages
            __typename
          }
          name
          websiteUrl
          logoUrl
          industryCategoryId
          config
          availableCredits
          paymentMethods {
            __typename
          }
          tMar
          markups {
            oMar
            tMar
            bMar
            __typename
          }
          defaultBrandMarkup
          theme {
            primary
            secondary
            pallet
            __typename
          }
          summaryMetrics {
            total
            __typename
          }
          segmentPackages
          brands {
            nextToken
            __typename
          }
          defaultStripeId
          organizationTeamsId
          __typename
        }
        nextToken
        __typename
      }
      whitelabelConfig {
        disableBrandCreditSelfService
        __typename
      }
      overrides {
        minimumCampaignBudget
        __typename
      }
      __typename
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        archived
        adminGroups
        viewerGroups
        memberGroups
        createdAt
        updatedAt
        name
        logoUrl
        accountDomain
        accountSubdomain
        accountInfo {
          owner
          ownerEmail
          website
          companyRole
          companyLocation
          companySize
          referencedBy
          industryCategoryId
          sfAccountId
          sfContactId
          sfOwnerId
          ownerPhoneNumber
          __typename
        }
        theme {
          primary
          secondary
          pallet
          __typename
        }
        accountConfig
        config
        accountSettings {
          accountType
          accountTier
          onboardingStatus
          teamId
          brandId
          role
          companySize
          address {
            street
            unitNumber
            city
            state
            postalCode
            country
            __typename
          }
          __typename
        }
        availableCredits
        paymentMethods {
          items {
            stripeId
            nickname
            lastFour
            cardType
            default
            __typename
          }
          __typename
        }
        oMar
        markups {
          oMar
          tMar
          bMar
          __typename
        }
        defaultTeamMarkup
        summaryMetrics {
          items {
            summaryType
            impressions
            clicks
            views
            spend
            conversions
            __typename
          }
          total
          __typename
        }
        segmentPackages
        teams {
          items {
            id
            archived
            adminGroups
            viewerGroups
            memberGroups
            createdAt
            updatedAt
            organizationId
            name
            websiteUrl
            logoUrl
            industryCategoryId
            config
            availableCredits
            tMar
            defaultBrandMarkup
            segmentPackages
            defaultStripeId
            organizationTeamsId
            __typename
          }
          nextToken
          __typename
        }
        whitelabelConfig {
          disableBrandCreditSelfService
          __typename
        }
        overrides {
          minimumCampaignBudget
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const organizationsByAccountDomain = /* GraphQL */ `
  query OrganizationsByAccountDomain(
    $accountDomain: String!
    $accountSubdomain: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    organizationsByAccountDomain(
      accountDomain: $accountDomain
      accountSubdomain: $accountSubdomain
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        archived
        adminGroups
        viewerGroups
        memberGroups
        createdAt
        updatedAt
        name
        logoUrl
        accountDomain
        accountSubdomain
        accountInfo {
          owner
          ownerEmail
          website
          companyRole
          companyLocation
          companySize
          referencedBy
          industryCategoryId
          sfAccountId
          sfContactId
          sfOwnerId
          ownerPhoneNumber
          __typename
        }
        theme {
          primary
          secondary
          pallet
          __typename
        }
        accountConfig
        config
        accountSettings {
          accountType
          accountTier
          onboardingStatus
          teamId
          brandId
          role
          companySize
          address {
            street
            unitNumber
            city
            state
            postalCode
            country
            __typename
          }
          __typename
        }
        availableCredits
        paymentMethods {
          items {
            stripeId
            nickname
            lastFour
            cardType
            default
            __typename
          }
          __typename
        }
        oMar
        markups {
          oMar
          tMar
          bMar
          __typename
        }
        defaultTeamMarkup
        summaryMetrics {
          items {
            summaryType
            impressions
            clicks
            views
            spend
            conversions
            __typename
          }
          total
          __typename
        }
        segmentPackages
        teams {
          items {
            id
            archived
            adminGroups
            viewerGroups
            memberGroups
            createdAt
            updatedAt
            organizationId
            name
            websiteUrl
            logoUrl
            industryCategoryId
            config
            availableCredits
            tMar
            defaultBrandMarkup
            segmentPackages
            defaultStripeId
            organizationTeamsId
            __typename
          }
          nextToken
          __typename
        }
        whitelabelConfig {
          disableBrandCreditSelfService
          __typename
        }
        overrides {
          minimumCampaignBudget
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchOrganizations = /* GraphQL */ `
  query SearchOrganizations(
    $filter: SearchableOrganizationFilterInput
    $sort: [SearchableOrganizationSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableOrganizationAggregationInput]
  ) {
    searchOrganizations(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        archived
        adminGroups
        viewerGroups
        memberGroups
        createdAt
        updatedAt
        name
        logoUrl
        accountDomain
        accountSubdomain
        accountInfo {
          owner
          ownerEmail
          website
          companyRole
          companyLocation
          companySize
          referencedBy
          industryCategoryId
          sfAccountId
          sfContactId
          sfOwnerId
          ownerPhoneNumber
          __typename
        }
        theme {
          primary
          secondary
          pallet
          __typename
        }
        accountConfig
        config
        accountSettings {
          accountType
          accountTier
          onboardingStatus
          teamId
          brandId
          role
          companySize
          address {
            street
            unitNumber
            city
            state
            postalCode
            country
            __typename
          }
          __typename
        }
        availableCredits
        paymentMethods {
          items {
            stripeId
            nickname
            lastFour
            cardType
            default
            __typename
          }
          __typename
        }
        oMar
        markups {
          oMar
          tMar
          bMar
          __typename
        }
        defaultTeamMarkup
        summaryMetrics {
          items {
            summaryType
            impressions
            clicks
            views
            spend
            conversions
            __typename
          }
          total
          __typename
        }
        segmentPackages
        teams {
          items {
            id
            archived
            adminGroups
            viewerGroups
            memberGroups
            createdAt
            updatedAt
            organizationId
            name
            websiteUrl
            logoUrl
            industryCategoryId
            config
            availableCredits
            tMar
            defaultBrandMarkup
            segmentPackages
            defaultStripeId
            organizationTeamsId
            __typename
          }
          nextToken
          __typename
        }
        whitelabelConfig {
          disableBrandCreditSelfService
          __typename
        }
        overrides {
          minimumCampaignBudget
          __typename
        }
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          buckets {
            key
            doc_count
            __typename
          }
          value
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      archived
      adminGroups
      viewerGroups
      memberGroups
      createdAt
      updatedAt
      organizationId
      organization {
        id
        archived
        adminGroups
        viewerGroups
        memberGroups
        createdAt
        updatedAt
        name
        logoUrl
        accountDomain
        accountSubdomain
        accountInfo {
          owner
          ownerEmail
          website
          companyRole
          companyLocation
          companySize
          referencedBy
          industryCategoryId
          sfAccountId
          sfContactId
          sfOwnerId
          ownerPhoneNumber
          __typename
        }
        theme {
          primary
          secondary
          pallet
          __typename
        }
        accountConfig
        config
        accountSettings {
          accountType
          accountTier
          onboardingStatus
          teamId
          brandId
          role
          companySize
          address {
            street
            unitNumber
            city
            state
            postalCode
            country
            __typename
          }
          __typename
        }
        availableCredits
        paymentMethods {
          items {
            stripeId
            nickname
            lastFour
            cardType
            default
            __typename
          }
          __typename
        }
        oMar
        markups {
          oMar
          tMar
          bMar
          __typename
        }
        defaultTeamMarkup
        summaryMetrics {
          items {
            summaryType
            impressions
            clicks
            views
            spend
            conversions
            __typename
          }
          total
          __typename
        }
        segmentPackages
        teams {
          items {
            id
            archived
            adminGroups
            viewerGroups
            memberGroups
            createdAt
            updatedAt
            organizationId
            name
            websiteUrl
            logoUrl
            industryCategoryId
            config
            availableCredits
            tMar
            defaultBrandMarkup
            segmentPackages
            defaultStripeId
            organizationTeamsId
            __typename
          }
          nextToken
          __typename
        }
        whitelabelConfig {
          disableBrandCreditSelfService
          __typename
        }
        overrides {
          minimumCampaignBudget
          __typename
        }
        __typename
      }
      name
      websiteUrl
      logoUrl
      industryCategoryId
      config
      availableCredits
      paymentMethods {
        items {
          stripeId
          nickname
          lastFour
          cardType
          default
          __typename
        }
        __typename
      }
      tMar
      markups {
        oMar
        tMar
        bMar
        __typename
      }
      defaultBrandMarkup
      theme {
        primary
        secondary
        pallet
        __typename
      }
      summaryMetrics {
        items {
          summaryType
          impressions
          clicks
          views
          spend
          conversions
          graphs {
            impressions
            clicks
            views
            spend
            conversions
            __typename
          }
          __typename
        }
        total
        __typename
      }
      segmentPackages
      brands {
        items {
          id
          archived
          adminGroups
          viewerGroups
          createdAt
          updatedAt
          organizationId
          teamId
          team {
            id
            archived
            adminGroups
            viewerGroups
            memberGroups
            createdAt
            updatedAt
            organizationId
            name
            websiteUrl
            logoUrl
            industryCategoryId
            config
            availableCredits
            tMar
            defaultBrandMarkup
            segmentPackages
            defaultStripeId
            organizationTeamsId
            __typename
          }
          advertiserId
          name
          websiteUrl
          logoUrl
          industryCategoryId
          politicalSettings {
            politicalType
            electionType
            status
            __typename
          }
          campaignPostProcessors
          config
          availableCredits
          paymentMethods {
            __typename
          }
          bMar
          markups {
            oMar
            tMar
            bMar
            __typename
          }
          universalPixel {
            UniversalPixelId
            UniversalPixelName
            AdvertiserId
            __typename
          }
          pixelCode
          pixelTracking
          pixelAudienceSize
          pixelMappingSizes
          theme {
            primary
            secondary
            pallet
            __typename
          }
          campaignSummary {
            running
            stopped
            completed
            __typename
          }
          creativeSummary {
            audio
            display
            video
            __typename
          }
          reportSummary {
            forecast
            summary
            __typename
          }
          summaryMetrics {
            total
            __typename
          }
          segmentPackages
          campaigns {
            nextToken
            __typename
          }
          trackingTags {
            nextToken
            __typename
          }
          isValid
          error
          defaultStripeId
          underReview
          teamBrandsId
          __typename
        }
        nextToken
        __typename
      }
      defaultStripeId
      organizationTeamsId
      __typename
    }
  }
`;
export const listTeams = /* GraphQL */ `
  query ListTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        archived
        adminGroups
        viewerGroups
        memberGroups
        createdAt
        updatedAt
        organizationId
        organization {
          id
          archived
          adminGroups
          viewerGroups
          memberGroups
          createdAt
          updatedAt
          name
          logoUrl
          accountDomain
          accountSubdomain
          accountInfo {
            owner
            ownerEmail
            website
            companyRole
            companyLocation
            companySize
            referencedBy
            industryCategoryId
            sfAccountId
            sfContactId
            sfOwnerId
            ownerPhoneNumber
            __typename
          }
          theme {
            primary
            secondary
            pallet
            __typename
          }
          accountConfig
          config
          accountSettings {
            accountType
            accountTier
            onboardingStatus
            teamId
            brandId
            role
            companySize
            __typename
          }
          availableCredits
          paymentMethods {
            __typename
          }
          oMar
          markups {
            oMar
            tMar
            bMar
            __typename
          }
          defaultTeamMarkup
          summaryMetrics {
            total
            __typename
          }
          segmentPackages
          teams {
            nextToken
            __typename
          }
          whitelabelConfig {
            disableBrandCreditSelfService
            __typename
          }
          overrides {
            minimumCampaignBudget
            __typename
          }
          __typename
        }
        name
        websiteUrl
        logoUrl
        industryCategoryId
        config
        availableCredits
        paymentMethods {
          items {
            stripeId
            nickname
            lastFour
            cardType
            default
            __typename
          }
          __typename
        }
        tMar
        markups {
          oMar
          tMar
          bMar
          __typename
        }
        defaultBrandMarkup
        theme {
          primary
          secondary
          pallet
          __typename
        }
        summaryMetrics {
          items {
            summaryType
            impressions
            clicks
            views
            spend
            conversions
            __typename
          }
          total
          __typename
        }
        segmentPackages
        brands {
          items {
            id
            archived
            adminGroups
            viewerGroups
            createdAt
            updatedAt
            organizationId
            teamId
            advertiserId
            name
            websiteUrl
            logoUrl
            industryCategoryId
            campaignPostProcessors
            config
            availableCredits
            bMar
            pixelCode
            pixelTracking
            pixelAudienceSize
            pixelMappingSizes
            segmentPackages
            isValid
            error
            defaultStripeId
            underReview
            teamBrandsId
            __typename
          }
          nextToken
          __typename
        }
        defaultStripeId
        organizationTeamsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchTeams = /* GraphQL */ `
  query SearchTeams(
    $filter: SearchableTeamFilterInput
    $sort: [SearchableTeamSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableTeamAggregationInput]
  ) {
    searchTeams(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        archived
        adminGroups
        viewerGroups
        memberGroups
        createdAt
        updatedAt
        organizationId
        organization {
          id
          archived
          adminGroups
          viewerGroups
          memberGroups
          createdAt
          updatedAt
          name
          logoUrl
          accountDomain
          accountSubdomain
          accountInfo {
            owner
            ownerEmail
            website
            companyRole
            companyLocation
            companySize
            referencedBy
            industryCategoryId
            sfAccountId
            sfContactId
            sfOwnerId
            ownerPhoneNumber
            __typename
          }
          theme {
            primary
            secondary
            pallet
            __typename
          }
          accountConfig
          config
          accountSettings {
            accountType
            accountTier
            onboardingStatus
            teamId
            brandId
            role
            companySize
            __typename
          }
          availableCredits
          paymentMethods {
            __typename
          }
          oMar
          markups {
            oMar
            tMar
            bMar
            __typename
          }
          defaultTeamMarkup
          summaryMetrics {
            total
            __typename
          }
          segmentPackages
          teams {
            nextToken
            __typename
          }
          whitelabelConfig {
            disableBrandCreditSelfService
            __typename
          }
          overrides {
            minimumCampaignBudget
            __typename
          }
          __typename
        }
        name
        websiteUrl
        logoUrl
        industryCategoryId
        config
        availableCredits
        paymentMethods {
          items {
            stripeId
            nickname
            lastFour
            cardType
            default
            __typename
          }
          __typename
        }
        tMar
        markups {
          oMar
          tMar
          bMar
          __typename
        }
        defaultBrandMarkup
        theme {
          primary
          secondary
          pallet
          __typename
        }
        summaryMetrics {
          items {
            summaryType
            impressions
            clicks
            views
            spend
            conversions
            __typename
          }
          total
          __typename
        }
        segmentPackages
        brands {
          items {
            id
            archived
            adminGroups
            viewerGroups
            createdAt
            updatedAt
            organizationId
            teamId
            advertiserId
            name
            websiteUrl
            logoUrl
            industryCategoryId
            campaignPostProcessors
            config
            availableCredits
            bMar
            pixelCode
            pixelTracking
            pixelAudienceSize
            pixelMappingSizes
            segmentPackages
            isValid
            error
            defaultStripeId
            underReview
            teamBrandsId
            __typename
          }
          nextToken
          __typename
        }
        defaultStripeId
        organizationTeamsId
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          buckets {
            key
            doc_count
            __typename
          }
          value
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const getBrand = /* GraphQL */ `
  query GetBrand($id: ID!) {
    getBrand(id: $id) {
      id
      archived
      adminGroups
      viewerGroups
      createdAt
      updatedAt
      organizationId
      teamId
      team {
        id
        archived
        adminGroups
        viewerGroups
        memberGroups
        createdAt
        updatedAt
        organizationId
        organization {
          id
          archived
          adminGroups
          viewerGroups
          memberGroups
          createdAt
          updatedAt
          name
          logoUrl
          accountDomain
          accountSubdomain
          accountInfo {
            owner
            ownerEmail
            website
            companyRole
            companyLocation
            companySize
            referencedBy
            industryCategoryId
            sfAccountId
            sfContactId
            sfOwnerId
            ownerPhoneNumber
            __typename
          }
          theme {
            primary
            secondary
            pallet
            __typename
          }
          accountConfig
          config
          accountSettings {
            accountType
            accountTier
            onboardingStatus
            teamId
            brandId
            role
            companySize
            __typename
          }
          availableCredits
          paymentMethods {
            __typename
          }
          oMar
          markups {
            oMar
            tMar
            bMar
            __typename
          }
          defaultTeamMarkup
          summaryMetrics {
            total
            __typename
          }
          segmentPackages
          teams {
            nextToken
            __typename
          }
          whitelabelConfig {
            disableBrandCreditSelfService
            __typename
          }
          overrides {
            minimumCampaignBudget
            __typename
          }
          __typename
        }
        name
        websiteUrl
        logoUrl
        industryCategoryId
        config
        availableCredits
        paymentMethods {
          items {
            stripeId
            nickname
            lastFour
            cardType
            default
            __typename
          }
          __typename
        }
        tMar
        markups {
          oMar
          tMar
          bMar
          __typename
        }
        defaultBrandMarkup
        theme {
          primary
          secondary
          pallet
          __typename
        }
        summaryMetrics {
          items {
            summaryType
            impressions
            clicks
            views
            spend
            conversions
            __typename
          }
          total
          __typename
        }
        segmentPackages
        brands {
          items {
            id
            archived
            adminGroups
            viewerGroups
            createdAt
            updatedAt
            organizationId
            teamId
            advertiserId
            name
            websiteUrl
            logoUrl
            industryCategoryId
            campaignPostProcessors
            config
            availableCredits
            bMar
            pixelCode
            pixelTracking
            pixelAudienceSize
            pixelMappingSizes
            segmentPackages
            isValid
            error
            defaultStripeId
            underReview
            teamBrandsId
            __typename
          }
          nextToken
          __typename
        }
        defaultStripeId
        organizationTeamsId
        __typename
      }
      advertiserId
      name
      websiteUrl
      logoUrl
      industryCategoryId
      politicalSettings {
        politicalType
        electionType
        candidate {
          PoliticalDataId
          CandidateName
          ElectionState
          PayingEntityName
          PayingEntityAddress
          PayingEntityEmailAddress
          PayingEntityExecutiveName
          PayingEntityExecutiveTitle
          GeoDescription
          AudienceDescription
          DisclaimerNotice
          FileLocationInS3
          FederalVerificationId
          StateVerificationId
          IsAdvertiserIEC
          __typename
        }
        status
        ballotMeasureProfile {
          PayingEntityName
          PayingEntityAddress
          PayingEntityExecutiveName
          PayingEntityExecutiveTitle
          ElectionState
          __typename
        }
        __typename
      }
      campaignPostProcessors
      config
      availableCredits
      paymentMethods {
        items {
          stripeId
          nickname
          lastFour
          cardType
          default
          __typename
        }
        __typename
      }
      bMar
      markups {
        oMar
        tMar
        bMar
        __typename
      }
      universalPixel {
        UniversalPixelMappings {
          ExactMatchMappings {
            Url
            HouseholdExtensionEnabled
            Revenue
            Currency
            TrackingTagId
            UniversalPixelMappingName
            UniversalPixelMappingType
            Description
            HitCount30Day
            FirstPartyDataId
            __typename
          }
          WildcardMatchMappings {
            UrlPattern
            HouseholdExtensionEnabled
            Revenue
            Currency
            TrackingTagId
            UniversalPixelMappingName
            UniversalPixelMappingType
            Description
            HitCount30Day
            FirstPartyDataId
            __typename
          }
          __typename
        }
        UniversalPixelId
        UniversalPixelName
        AdvertiserId
        __typename
      }
      pixelCode
      pixelTracking
      pixelAudienceSize
      pixelMappingSizes
      theme {
        primary
        secondary
        pallet
        __typename
      }
      campaignSummary {
        running
        stopped
        completed
        __typename
      }
      creativeSummary {
        audio
        display
        video
        __typename
      }
      reportSummary {
        forecast
        summary
        __typename
      }
      summaryMetrics {
        items {
          summaryType
          impressions
          clicks
          views
          spend
          conversions
          graphs {
            impressions
            clicks
            views
            spend
            conversions
            __typename
          }
          __typename
        }
        total
        __typename
      }
      segmentPackages
      campaigns {
        items {
          id
          archived
          adminGroups
          viewerGroups
          createdAt
          updatedAt
          stoppedAt
          approvedAt
          lastRecurredAt
          organizationId
          teamId
          brandId
          brand {
            id
            archived
            adminGroups
            viewerGroups
            createdAt
            updatedAt
            organizationId
            teamId
            advertiserId
            name
            websiteUrl
            logoUrl
            industryCategoryId
            campaignPostProcessors
            config
            availableCredits
            bMar
            pixelCode
            pixelTracking
            pixelAudienceSize
            pixelMappingSizes
            segmentPackages
            isValid
            error
            defaultStripeId
            underReview
            teamBrandsId
            __typename
          }
          status
          recurrenceWindow
          recurrenceBudget
          name
          goal
          budget
          originalBudget
          dailyBudget
          startDate
          endDate
          originalEndDate
          audienceType
          audienceFrom
          retargetingTags {
            dspId
            firstPartyDataId
            trackingTagId
            __typename
          }
          geoSegments {
            id
            segmentPackageId
            dspId
            name
            pathKey
            segmentType
            segmentGroup
            __typename
          }
          dmpSegments {
            id
            segmentPackageId
            dspId
            name
            pathKey
            segmentType
            segmentGroup
            __typename
          }
          inventorySegments {
            id
            segmentPackageId
            dspId
            name
            pathKey
            segmentType
            segmentGroup
            __typename
          }
          timesOfDay
          daysOfWeek
          creatives {
            nextToken
            __typename
          }
          forecastRequestedAt
          forecastAdgroups
          publishedAt
          forecast {
            requestedAt
            createdAt
            __typename
          }
          ttdId
          conversionsEnabled
          trackingTagId
          pixelTrackingUrl
          conversionEvents {
            type
            id
            __typename
          }
          ttdStatus
          dspIds
          audience
          bidlists
          markups {
            oMar
            tMar
            bMar
            __typename
          }
          summaryMetrics {
            total
            __typename
          }
          ttdUpdatedAt
          activeUpdatedAt
          sfOpportunityId
          ctvSegments
          isRecurring
          isValid
          error
          isMultiDataGroups
          campaignPoliticalSettings {
            type
            __typename
          }
          additionalBidLists
          postProcessors
          brandCampaignsId
          __typename
        }
        nextToken
        __typename
      }
      trackingTags {
        items {
          id
          archived
          trackingTagType
          adminGroups
          viewerGroups
          createdAt
          updatedAt
          errorNotifiedAt
          organizationId
          teamId
          brandId
          dspIds
          status
          ttdId
          name
          pixelCode
          pixelAudienceSize
          pixelUrl
          remainingDays
          brand {
            id
            archived
            adminGroups
            viewerGroups
            createdAt
            updatedAt
            organizationId
            teamId
            advertiserId
            name
            websiteUrl
            logoUrl
            industryCategoryId
            campaignPostProcessors
            config
            availableCredits
            bMar
            pixelCode
            pixelTracking
            pixelAudienceSize
            pixelMappingSizes
            segmentPackages
            isValid
            error
            defaultStripeId
            underReview
            teamBrandsId
            __typename
          }
          piiType
          region
          retainPii
          brandTrackingTagsId
          __typename
        }
        nextToken
        __typename
      }
      isValid
      error
      defaultStripeId
      underReview
      teamBrandsId
      __typename
    }
  }
`;
export const listBrands = /* GraphQL */ `
  query ListBrands(
    $filter: ModelBrandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBrands(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        archived
        adminGroups
        viewerGroups
        createdAt
        updatedAt
        organizationId
        teamId
        team {
          id
          archived
          adminGroups
          viewerGroups
          memberGroups
          createdAt
          updatedAt
          organizationId
          organization {
            id
            archived
            adminGroups
            viewerGroups
            memberGroups
            createdAt
            updatedAt
            name
            logoUrl
            accountDomain
            accountSubdomain
            accountConfig
            config
            availableCredits
            oMar
            defaultTeamMarkup
            segmentPackages
            __typename
          }
          name
          websiteUrl
          logoUrl
          industryCategoryId
          config
          availableCredits
          paymentMethods {
            __typename
          }
          tMar
          markups {
            oMar
            tMar
            bMar
            __typename
          }
          defaultBrandMarkup
          theme {
            primary
            secondary
            pallet
            __typename
          }
          summaryMetrics {
            total
            __typename
          }
          segmentPackages
          brands {
            nextToken
            __typename
          }
          defaultStripeId
          organizationTeamsId
          __typename
        }
        advertiserId
        name
        websiteUrl
        logoUrl
        industryCategoryId
        politicalSettings {
          politicalType
          electionType
          candidate {
            PoliticalDataId
            CandidateName
            ElectionState
            PayingEntityName
            PayingEntityAddress
            PayingEntityEmailAddress
            PayingEntityExecutiveName
            PayingEntityExecutiveTitle
            GeoDescription
            AudienceDescription
            DisclaimerNotice
            FileLocationInS3
            FederalVerificationId
            StateVerificationId
            IsAdvertiserIEC
            __typename
          }
          status
          ballotMeasureProfile {
            PayingEntityName
            PayingEntityAddress
            PayingEntityExecutiveName
            PayingEntityExecutiveTitle
            ElectionState
            __typename
          }
          __typename
        }
        campaignPostProcessors
        config
        availableCredits
        paymentMethods {
          items {
            stripeId
            nickname
            lastFour
            cardType
            default
            __typename
          }
          __typename
        }
        bMar
        markups {
          oMar
          tMar
          bMar
          __typename
        }
        universalPixel {
          UniversalPixelMappings {
            __typename
          }
          UniversalPixelId
          UniversalPixelName
          AdvertiserId
          __typename
        }
        pixelCode
        pixelTracking
        pixelAudienceSize
        pixelMappingSizes
        theme {
          primary
          secondary
          pallet
          __typename
        }
        campaignSummary {
          running
          stopped
          completed
          __typename
        }
        creativeSummary {
          audio
          display
          video
          __typename
        }
        reportSummary {
          forecast
          summary
          __typename
        }
        summaryMetrics {
          items {
            summaryType
            impressions
            clicks
            views
            spend
            conversions
            __typename
          }
          total
          __typename
        }
        segmentPackages
        campaigns {
          items {
            id
            archived
            adminGroups
            viewerGroups
            createdAt
            updatedAt
            stoppedAt
            approvedAt
            lastRecurredAt
            organizationId
            teamId
            brandId
            status
            recurrenceWindow
            recurrenceBudget
            name
            goal
            budget
            originalBudget
            dailyBudget
            startDate
            endDate
            originalEndDate
            audienceType
            audienceFrom
            timesOfDay
            daysOfWeek
            forecastRequestedAt
            forecastAdgroups
            publishedAt
            ttdId
            conversionsEnabled
            trackingTagId
            pixelTrackingUrl
            ttdStatus
            dspIds
            audience
            bidlists
            ttdUpdatedAt
            activeUpdatedAt
            sfOpportunityId
            ctvSegments
            isRecurring
            isValid
            error
            isMultiDataGroups
            additionalBidLists
            postProcessors
            brandCampaignsId
            __typename
          }
          nextToken
          __typename
        }
        trackingTags {
          items {
            id
            archived
            trackingTagType
            adminGroups
            viewerGroups
            createdAt
            updatedAt
            errorNotifiedAt
            organizationId
            teamId
            brandId
            dspIds
            status
            ttdId
            name
            pixelCode
            pixelAudienceSize
            pixelUrl
            remainingDays
            piiType
            region
            retainPii
            brandTrackingTagsId
            __typename
          }
          nextToken
          __typename
        }
        isValid
        error
        defaultStripeId
        underReview
        teamBrandsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchBrands = /* GraphQL */ `
  query SearchBrands(
    $filter: SearchableBrandFilterInput
    $sort: [SearchableBrandSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableBrandAggregationInput]
  ) {
    searchBrands(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        archived
        adminGroups
        viewerGroups
        createdAt
        updatedAt
        organizationId
        teamId
        team {
          id
          archived
          adminGroups
          viewerGroups
          memberGroups
          createdAt
          updatedAt
          organizationId
          organization {
            id
            archived
            adminGroups
            viewerGroups
            memberGroups
            createdAt
            updatedAt
            name
            logoUrl
            accountDomain
            accountSubdomain
            accountConfig
            config
            availableCredits
            oMar
            defaultTeamMarkup
            segmentPackages
            __typename
          }
          name
          websiteUrl
          logoUrl
          industryCategoryId
          config
          availableCredits
          paymentMethods {
            __typename
          }
          tMar
          markups {
            oMar
            tMar
            bMar
            __typename
          }
          defaultBrandMarkup
          theme {
            primary
            secondary
            pallet
            __typename
          }
          summaryMetrics {
            total
            __typename
          }
          segmentPackages
          brands {
            nextToken
            __typename
          }
          defaultStripeId
          organizationTeamsId
          __typename
        }
        advertiserId
        name
        websiteUrl
        logoUrl
        industryCategoryId
        politicalSettings {
          politicalType
          electionType
          candidate {
            PoliticalDataId
            CandidateName
            ElectionState
            PayingEntityName
            PayingEntityAddress
            PayingEntityEmailAddress
            PayingEntityExecutiveName
            PayingEntityExecutiveTitle
            GeoDescription
            AudienceDescription
            DisclaimerNotice
            FileLocationInS3
            FederalVerificationId
            StateVerificationId
            IsAdvertiserIEC
            __typename
          }
          status
          ballotMeasureProfile {
            PayingEntityName
            PayingEntityAddress
            PayingEntityExecutiveName
            PayingEntityExecutiveTitle
            ElectionState
            __typename
          }
          __typename
        }
        campaignPostProcessors
        config
        availableCredits
        paymentMethods {
          items {
            stripeId
            nickname
            lastFour
            cardType
            default
            __typename
          }
          __typename
        }
        bMar
        markups {
          oMar
          tMar
          bMar
          __typename
        }
        universalPixel {
          UniversalPixelMappings {
            __typename
          }
          UniversalPixelId
          UniversalPixelName
          AdvertiserId
          __typename
        }
        pixelCode
        pixelTracking
        pixelAudienceSize
        pixelMappingSizes
        theme {
          primary
          secondary
          pallet
          __typename
        }
        campaignSummary {
          running
          stopped
          completed
          __typename
        }
        creativeSummary {
          audio
          display
          video
          __typename
        }
        reportSummary {
          forecast
          summary
          __typename
        }
        summaryMetrics {
          items {
            summaryType
            impressions
            clicks
            views
            spend
            conversions
            __typename
          }
          total
          __typename
        }
        segmentPackages
        campaigns {
          items {
            id
            archived
            adminGroups
            viewerGroups
            createdAt
            updatedAt
            stoppedAt
            approvedAt
            lastRecurredAt
            organizationId
            teamId
            brandId
            status
            recurrenceWindow
            recurrenceBudget
            name
            goal
            budget
            originalBudget
            dailyBudget
            startDate
            endDate
            originalEndDate
            audienceType
            audienceFrom
            timesOfDay
            daysOfWeek
            forecastRequestedAt
            forecastAdgroups
            publishedAt
            ttdId
            conversionsEnabled
            trackingTagId
            pixelTrackingUrl
            ttdStatus
            dspIds
            audience
            bidlists
            ttdUpdatedAt
            activeUpdatedAt
            sfOpportunityId
            ctvSegments
            isRecurring
            isValid
            error
            isMultiDataGroups
            additionalBidLists
            postProcessors
            brandCampaignsId
            __typename
          }
          nextToken
          __typename
        }
        trackingTags {
          items {
            id
            archived
            trackingTagType
            adminGroups
            viewerGroups
            createdAt
            updatedAt
            errorNotifiedAt
            organizationId
            teamId
            brandId
            dspIds
            status
            ttdId
            name
            pixelCode
            pixelAudienceSize
            pixelUrl
            remainingDays
            piiType
            region
            retainPii
            brandTrackingTagsId
            __typename
          }
          nextToken
          __typename
        }
        isValid
        error
        defaultStripeId
        underReview
        teamBrandsId
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          buckets {
            key
            doc_count
            __typename
          }
          value
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const getCampaign = /* GraphQL */ `
  query GetCampaign($id: ID!) {
    getCampaign(id: $id) {
      id
      archived
      adminGroups
      viewerGroups
      createdAt
      updatedAt
      stoppedAt
      approvedAt
      lastRecurredAt
      organizationId
      teamId
      brandId
      brand {
        id
        archived
        adminGroups
        viewerGroups
        createdAt
        updatedAt
        organizationId
        teamId
        team {
          id
          archived
          adminGroups
          viewerGroups
          memberGroups
          createdAt
          updatedAt
          organizationId
          organization {
            id
            archived
            adminGroups
            viewerGroups
            memberGroups
            createdAt
            updatedAt
            name
            logoUrl
            accountDomain
            accountSubdomain
            accountConfig
            config
            availableCredits
            oMar
            defaultTeamMarkup
            segmentPackages
            __typename
          }
          name
          websiteUrl
          logoUrl
          industryCategoryId
          config
          availableCredits
          paymentMethods {
            __typename
          }
          tMar
          markups {
            oMar
            tMar
            bMar
            __typename
          }
          defaultBrandMarkup
          theme {
            primary
            secondary
            pallet
            __typename
          }
          summaryMetrics {
            total
            __typename
          }
          segmentPackages
          brands {
            nextToken
            __typename
          }
          defaultStripeId
          organizationTeamsId
          __typename
        }
        advertiserId
        name
        websiteUrl
        logoUrl
        industryCategoryId
        politicalSettings {
          politicalType
          electionType
          candidate {
            PoliticalDataId
            CandidateName
            ElectionState
            PayingEntityName
            PayingEntityAddress
            PayingEntityEmailAddress
            PayingEntityExecutiveName
            PayingEntityExecutiveTitle
            GeoDescription
            AudienceDescription
            DisclaimerNotice
            FileLocationInS3
            FederalVerificationId
            StateVerificationId
            IsAdvertiserIEC
            __typename
          }
          status
          ballotMeasureProfile {
            PayingEntityName
            PayingEntityAddress
            PayingEntityExecutiveName
            PayingEntityExecutiveTitle
            ElectionState
            __typename
          }
          __typename
        }
        campaignPostProcessors
        config
        availableCredits
        paymentMethods {
          items {
            stripeId
            nickname
            lastFour
            cardType
            default
            __typename
          }
          __typename
        }
        bMar
        markups {
          oMar
          tMar
          bMar
          __typename
        }
        universalPixel {
          UniversalPixelMappings {
            __typename
          }
          UniversalPixelId
          UniversalPixelName
          AdvertiserId
          __typename
        }
        pixelCode
        pixelTracking
        pixelAudienceSize
        pixelMappingSizes
        theme {
          primary
          secondary
          pallet
          __typename
        }
        campaignSummary {
          running
          stopped
          completed
          __typename
        }
        creativeSummary {
          audio
          display
          video
          __typename
        }
        reportSummary {
          forecast
          summary
          __typename
        }
        summaryMetrics {
          items {
            summaryType
            impressions
            clicks
            views
            spend
            conversions
            __typename
          }
          total
          __typename
        }
        segmentPackages
        campaigns {
          items {
            id
            archived
            adminGroups
            viewerGroups
            createdAt
            updatedAt
            stoppedAt
            approvedAt
            lastRecurredAt
            organizationId
            teamId
            brandId
            status
            recurrenceWindow
            recurrenceBudget
            name
            goal
            budget
            originalBudget
            dailyBudget
            startDate
            endDate
            originalEndDate
            audienceType
            audienceFrom
            timesOfDay
            daysOfWeek
            forecastRequestedAt
            forecastAdgroups
            publishedAt
            ttdId
            conversionsEnabled
            trackingTagId
            pixelTrackingUrl
            ttdStatus
            dspIds
            audience
            bidlists
            ttdUpdatedAt
            activeUpdatedAt
            sfOpportunityId
            ctvSegments
            isRecurring
            isValid
            error
            isMultiDataGroups
            additionalBidLists
            postProcessors
            brandCampaignsId
            __typename
          }
          nextToken
          __typename
        }
        trackingTags {
          items {
            id
            archived
            trackingTagType
            adminGroups
            viewerGroups
            createdAt
            updatedAt
            errorNotifiedAt
            organizationId
            teamId
            brandId
            dspIds
            status
            ttdId
            name
            pixelCode
            pixelAudienceSize
            pixelUrl
            remainingDays
            piiType
            region
            retainPii
            brandTrackingTagsId
            __typename
          }
          nextToken
          __typename
        }
        isValid
        error
        defaultStripeId
        underReview
        teamBrandsId
        __typename
      }
      status
      recurrenceWindow
      recurrenceBudget
      name
      goal
      budget
      originalBudget
      dailyBudget
      startDate
      endDate
      originalEndDate
      audienceType
      audienceFrom
      retargetingTags {
        dspId
        firstPartyDataId
        trackingTagId
        __typename
      }
      geoSegments {
        id
        segmentPackageId
        dspId
        name
        pathKey
        segmentType
        segmentGroup
        __typename
      }
      dmpSegments {
        id
        segmentPackageId
        dspId
        name
        pathKey
        segmentType
        segmentGroup
        __typename
      }
      inventorySegments {
        id
        segmentPackageId
        dspId
        name
        pathKey
        segmentType
        segmentGroup
        __typename
      }
      timesOfDay
      daysOfWeek
      creatives {
        items {
          id
          archived
          createdAt
          updatedAt
          uploadedAt
          publishedAt
          adminGroups
          viewerGroups
          organizationId
          teamBrandCampaign
          teamId
          brandId
          campaignId
          campaign {
            id
            archived
            adminGroups
            viewerGroups
            createdAt
            updatedAt
            stoppedAt
            approvedAt
            lastRecurredAt
            organizationId
            teamId
            brandId
            status
            recurrenceWindow
            recurrenceBudget
            name
            goal
            budget
            originalBudget
            dailyBudget
            startDate
            endDate
            originalEndDate
            audienceType
            audienceFrom
            timesOfDay
            daysOfWeek
            forecastRequestedAt
            forecastAdgroups
            publishedAt
            ttdId
            conversionsEnabled
            trackingTagId
            pixelTrackingUrl
            ttdStatus
            dspIds
            audience
            bidlists
            ttdUpdatedAt
            activeUpdatedAt
            sfOpportunityId
            ctvSegments
            isRecurring
            isValid
            error
            isMultiDataGroups
            additionalBidLists
            postProcessors
            brandCampaignsId
            __typename
          }
          teamBrandCampaignId
          name
          fileName
          creativeType
          s3Key
          s3ThumbnailKey
          clickthroughUrl
          landingPageUrl
          inApp
          web
          ctv
          displayTargeting
          devices
          dspIds
          ttdId
          ttdStatus
          isValid
          error
          campaignCreativesId
          __typename
        }
        nextToken
        __typename
      }
      forecastRequestedAt
      forecastAdgroups
      publishedAt
      forecast {
        requestedAt
        createdAt
        impressions {
          max
          min
          __typename
        }
        reach {
          max
          min
          __typename
        }
        frequency {
          max
          min
          __typename
        }
        spend {
          max
          min
          __typename
        }
        results {
          adgroupType
          impressions {
            max
            min
            __typename
          }
          reach {
            max
            min
            __typename
          }
          frequency {
            max
            min
            __typename
          }
          spend {
            max
            min
            __typename
          }
          __typename
        }
        __typename
      }
      ttdId
      conversionsEnabled
      trackingTagId
      pixelTrackingUrl
      conversionEvents {
        type
        id
        __typename
      }
      ttdStatus
      dspIds
      audience
      bidlists
      markups {
        oMar
        tMar
        bMar
        __typename
      }
      summaryMetrics {
        items {
          summaryType
          impressions
          clicks
          views
          spend
          conversions
          graphs {
            impressions
            clicks
            views
            spend
            conversions
            __typename
          }
          __typename
        }
        total
        __typename
      }
      ttdUpdatedAt
      activeUpdatedAt
      sfOpportunityId
      ctvSegments
      isRecurring
      isValid
      error
      isMultiDataGroups
      campaignPoliticalSettings {
        type
        __typename
      }
      additionalBidLists
      postProcessors
      brandCampaignsId
      __typename
    }
  }
`;
export const listCampaigns = /* GraphQL */ `
  query ListCampaigns(
    $filter: ModelCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCampaigns(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        archived
        adminGroups
        viewerGroups
        createdAt
        updatedAt
        stoppedAt
        approvedAt
        lastRecurredAt
        organizationId
        teamId
        brandId
        brand {
          id
          archived
          adminGroups
          viewerGroups
          createdAt
          updatedAt
          organizationId
          teamId
          team {
            id
            archived
            adminGroups
            viewerGroups
            memberGroups
            createdAt
            updatedAt
            organizationId
            name
            websiteUrl
            logoUrl
            industryCategoryId
            config
            availableCredits
            tMar
            defaultBrandMarkup
            segmentPackages
            defaultStripeId
            organizationTeamsId
            __typename
          }
          advertiserId
          name
          websiteUrl
          logoUrl
          industryCategoryId
          politicalSettings {
            politicalType
            electionType
            status
            __typename
          }
          campaignPostProcessors
          config
          availableCredits
          paymentMethods {
            __typename
          }
          bMar
          markups {
            oMar
            tMar
            bMar
            __typename
          }
          universalPixel {
            UniversalPixelId
            UniversalPixelName
            AdvertiserId
            __typename
          }
          pixelCode
          pixelTracking
          pixelAudienceSize
          pixelMappingSizes
          theme {
            primary
            secondary
            pallet
            __typename
          }
          campaignSummary {
            running
            stopped
            completed
            __typename
          }
          creativeSummary {
            audio
            display
            video
            __typename
          }
          reportSummary {
            forecast
            summary
            __typename
          }
          summaryMetrics {
            total
            __typename
          }
          segmentPackages
          campaigns {
            nextToken
            __typename
          }
          trackingTags {
            nextToken
            __typename
          }
          isValid
          error
          defaultStripeId
          underReview
          teamBrandsId
          __typename
        }
        status
        recurrenceWindow
        recurrenceBudget
        name
        goal
        budget
        originalBudget
        dailyBudget
        startDate
        endDate
        originalEndDate
        audienceType
        audienceFrom
        retargetingTags {
          dspId
          firstPartyDataId
          trackingTagId
          __typename
        }
        geoSegments {
          id
          segmentPackageId
          dspId
          name
          pathKey
          segmentType
          segmentGroup
          __typename
        }
        dmpSegments {
          id
          segmentPackageId
          dspId
          name
          pathKey
          segmentType
          segmentGroup
          __typename
        }
        inventorySegments {
          id
          segmentPackageId
          dspId
          name
          pathKey
          segmentType
          segmentGroup
          __typename
        }
        timesOfDay
        daysOfWeek
        creatives {
          items {
            id
            archived
            createdAt
            updatedAt
            uploadedAt
            publishedAt
            adminGroups
            viewerGroups
            organizationId
            teamBrandCampaign
            teamId
            brandId
            campaignId
            teamBrandCampaignId
            name
            fileName
            creativeType
            s3Key
            s3ThumbnailKey
            clickthroughUrl
            landingPageUrl
            inApp
            web
            ctv
            displayTargeting
            devices
            dspIds
            ttdId
            ttdStatus
            isValid
            error
            campaignCreativesId
            __typename
          }
          nextToken
          __typename
        }
        forecastRequestedAt
        forecastAdgroups
        publishedAt
        forecast {
          requestedAt
          createdAt
          impressions {
            max
            min
            __typename
          }
          reach {
            max
            min
            __typename
          }
          frequency {
            max
            min
            __typename
          }
          spend {
            max
            min
            __typename
          }
          results {
            adgroupType
            __typename
          }
          __typename
        }
        ttdId
        conversionsEnabled
        trackingTagId
        pixelTrackingUrl
        conversionEvents {
          type
          id
          __typename
        }
        ttdStatus
        dspIds
        audience
        bidlists
        markups {
          oMar
          tMar
          bMar
          __typename
        }
        summaryMetrics {
          items {
            summaryType
            impressions
            clicks
            views
            spend
            conversions
            __typename
          }
          total
          __typename
        }
        ttdUpdatedAt
        activeUpdatedAt
        sfOpportunityId
        ctvSegments
        isRecurring
        isValid
        error
        isMultiDataGroups
        campaignPoliticalSettings {
          type
          __typename
        }
        additionalBidLists
        postProcessors
        brandCampaignsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchCampaigns = /* GraphQL */ `
  query SearchCampaigns(
    $filter: SearchableCampaignFilterInput
    $sort: [SearchableCampaignSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableCampaignAggregationInput]
  ) {
    searchCampaigns(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        archived
        adminGroups
        viewerGroups
        createdAt
        updatedAt
        stoppedAt
        approvedAt
        lastRecurredAt
        organizationId
        teamId
        brandId
        brand {
          id
          archived
          adminGroups
          viewerGroups
          createdAt
          updatedAt
          organizationId
          teamId
          team {
            id
            archived
            adminGroups
            viewerGroups
            memberGroups
            createdAt
            updatedAt
            organizationId
            name
            websiteUrl
            logoUrl
            industryCategoryId
            config
            availableCredits
            tMar
            defaultBrandMarkup
            segmentPackages
            defaultStripeId
            organizationTeamsId
            __typename
          }
          advertiserId
          name
          websiteUrl
          logoUrl
          industryCategoryId
          politicalSettings {
            politicalType
            electionType
            status
            __typename
          }
          campaignPostProcessors
          config
          availableCredits
          paymentMethods {
            __typename
          }
          bMar
          markups {
            oMar
            tMar
            bMar
            __typename
          }
          universalPixel {
            UniversalPixelId
            UniversalPixelName
            AdvertiserId
            __typename
          }
          pixelCode
          pixelTracking
          pixelAudienceSize
          pixelMappingSizes
          theme {
            primary
            secondary
            pallet
            __typename
          }
          campaignSummary {
            running
            stopped
            completed
            __typename
          }
          creativeSummary {
            audio
            display
            video
            __typename
          }
          reportSummary {
            forecast
            summary
            __typename
          }
          summaryMetrics {
            total
            __typename
          }
          segmentPackages
          campaigns {
            nextToken
            __typename
          }
          trackingTags {
            nextToken
            __typename
          }
          isValid
          error
          defaultStripeId
          underReview
          teamBrandsId
          __typename
        }
        status
        recurrenceWindow
        recurrenceBudget
        name
        goal
        budget
        originalBudget
        dailyBudget
        startDate
        endDate
        originalEndDate
        audienceType
        audienceFrom
        retargetingTags {
          dspId
          firstPartyDataId
          trackingTagId
          __typename
        }
        geoSegments {
          id
          segmentPackageId
          dspId
          name
          pathKey
          segmentType
          segmentGroup
          __typename
        }
        dmpSegments {
          id
          segmentPackageId
          dspId
          name
          pathKey
          segmentType
          segmentGroup
          __typename
        }
        inventorySegments {
          id
          segmentPackageId
          dspId
          name
          pathKey
          segmentType
          segmentGroup
          __typename
        }
        timesOfDay
        daysOfWeek
        creatives {
          items {
            id
            archived
            createdAt
            updatedAt
            uploadedAt
            publishedAt
            adminGroups
            viewerGroups
            organizationId
            teamBrandCampaign
            teamId
            brandId
            campaignId
            teamBrandCampaignId
            name
            fileName
            creativeType
            s3Key
            s3ThumbnailKey
            clickthroughUrl
            landingPageUrl
            inApp
            web
            ctv
            displayTargeting
            devices
            dspIds
            ttdId
            ttdStatus
            isValid
            error
            campaignCreativesId
            __typename
          }
          nextToken
          __typename
        }
        forecastRequestedAt
        forecastAdgroups
        publishedAt
        forecast {
          requestedAt
          createdAt
          impressions {
            max
            min
            __typename
          }
          reach {
            max
            min
            __typename
          }
          frequency {
            max
            min
            __typename
          }
          spend {
            max
            min
            __typename
          }
          results {
            adgroupType
            __typename
          }
          __typename
        }
        ttdId
        conversionsEnabled
        trackingTagId
        pixelTrackingUrl
        conversionEvents {
          type
          id
          __typename
        }
        ttdStatus
        dspIds
        audience
        bidlists
        markups {
          oMar
          tMar
          bMar
          __typename
        }
        summaryMetrics {
          items {
            summaryType
            impressions
            clicks
            views
            spend
            conversions
            __typename
          }
          total
          __typename
        }
        ttdUpdatedAt
        activeUpdatedAt
        sfOpportunityId
        ctvSegments
        isRecurring
        isValid
        error
        isMultiDataGroups
        campaignPoliticalSettings {
          type
          __typename
        }
        additionalBidLists
        postProcessors
        brandCampaignsId
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          buckets {
            key
            doc_count
            __typename
          }
          value
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const getCreative = /* GraphQL */ `
  query GetCreative($id: ID!) {
    getCreative(id: $id) {
      id
      archived
      createdAt
      updatedAt
      uploadedAt
      publishedAt
      adminGroups
      viewerGroups
      organizationId
      teamBrandCampaign
      teamId
      brandId
      campaignId
      campaign {
        id
        archived
        adminGroups
        viewerGroups
        createdAt
        updatedAt
        stoppedAt
        approvedAt
        lastRecurredAt
        organizationId
        teamId
        brandId
        brand {
          id
          archived
          adminGroups
          viewerGroups
          createdAt
          updatedAt
          organizationId
          teamId
          team {
            id
            archived
            adminGroups
            viewerGroups
            memberGroups
            createdAt
            updatedAt
            organizationId
            name
            websiteUrl
            logoUrl
            industryCategoryId
            config
            availableCredits
            tMar
            defaultBrandMarkup
            segmentPackages
            defaultStripeId
            organizationTeamsId
            __typename
          }
          advertiserId
          name
          websiteUrl
          logoUrl
          industryCategoryId
          politicalSettings {
            politicalType
            electionType
            status
            __typename
          }
          campaignPostProcessors
          config
          availableCredits
          paymentMethods {
            __typename
          }
          bMar
          markups {
            oMar
            tMar
            bMar
            __typename
          }
          universalPixel {
            UniversalPixelId
            UniversalPixelName
            AdvertiserId
            __typename
          }
          pixelCode
          pixelTracking
          pixelAudienceSize
          pixelMappingSizes
          theme {
            primary
            secondary
            pallet
            __typename
          }
          campaignSummary {
            running
            stopped
            completed
            __typename
          }
          creativeSummary {
            audio
            display
            video
            __typename
          }
          reportSummary {
            forecast
            summary
            __typename
          }
          summaryMetrics {
            total
            __typename
          }
          segmentPackages
          campaigns {
            nextToken
            __typename
          }
          trackingTags {
            nextToken
            __typename
          }
          isValid
          error
          defaultStripeId
          underReview
          teamBrandsId
          __typename
        }
        status
        recurrenceWindow
        recurrenceBudget
        name
        goal
        budget
        originalBudget
        dailyBudget
        startDate
        endDate
        originalEndDate
        audienceType
        audienceFrom
        retargetingTags {
          dspId
          firstPartyDataId
          trackingTagId
          __typename
        }
        geoSegments {
          id
          segmentPackageId
          dspId
          name
          pathKey
          segmentType
          segmentGroup
          __typename
        }
        dmpSegments {
          id
          segmentPackageId
          dspId
          name
          pathKey
          segmentType
          segmentGroup
          __typename
        }
        inventorySegments {
          id
          segmentPackageId
          dspId
          name
          pathKey
          segmentType
          segmentGroup
          __typename
        }
        timesOfDay
        daysOfWeek
        creatives {
          items {
            id
            archived
            createdAt
            updatedAt
            uploadedAt
            publishedAt
            adminGroups
            viewerGroups
            organizationId
            teamBrandCampaign
            teamId
            brandId
            campaignId
            teamBrandCampaignId
            name
            fileName
            creativeType
            s3Key
            s3ThumbnailKey
            clickthroughUrl
            landingPageUrl
            inApp
            web
            ctv
            displayTargeting
            devices
            dspIds
            ttdId
            ttdStatus
            isValid
            error
            campaignCreativesId
            __typename
          }
          nextToken
          __typename
        }
        forecastRequestedAt
        forecastAdgroups
        publishedAt
        forecast {
          requestedAt
          createdAt
          impressions {
            max
            min
            __typename
          }
          reach {
            max
            min
            __typename
          }
          frequency {
            max
            min
            __typename
          }
          spend {
            max
            min
            __typename
          }
          results {
            adgroupType
            __typename
          }
          __typename
        }
        ttdId
        conversionsEnabled
        trackingTagId
        pixelTrackingUrl
        conversionEvents {
          type
          id
          __typename
        }
        ttdStatus
        dspIds
        audience
        bidlists
        markups {
          oMar
          tMar
          bMar
          __typename
        }
        summaryMetrics {
          items {
            summaryType
            impressions
            clicks
            views
            spend
            conversions
            __typename
          }
          total
          __typename
        }
        ttdUpdatedAt
        activeUpdatedAt
        sfOpportunityId
        ctvSegments
        isRecurring
        isValid
        error
        isMultiDataGroups
        campaignPoliticalSettings {
          type
          __typename
        }
        additionalBidLists
        postProcessors
        brandCampaignsId
        __typename
      }
      teamBrandCampaignId
      name
      fileName
      creativeType
      s3Key
      s3ThumbnailKey
      clickthroughUrl
      landingPageUrl
      inApp
      web
      ctv
      displayTargeting
      devices
      dspIds
      ttdId
      ttdStatus
      isValid
      error
      campaignCreativesId
      __typename
    }
  }
`;
export const listCreatives = /* GraphQL */ `
  query ListCreatives(
    $filter: ModelCreativeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCreatives(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        archived
        createdAt
        updatedAt
        uploadedAt
        publishedAt
        adminGroups
        viewerGroups
        organizationId
        teamBrandCampaign
        teamId
        brandId
        campaignId
        campaign {
          id
          archived
          adminGroups
          viewerGroups
          createdAt
          updatedAt
          stoppedAt
          approvedAt
          lastRecurredAt
          organizationId
          teamId
          brandId
          brand {
            id
            archived
            adminGroups
            viewerGroups
            createdAt
            updatedAt
            organizationId
            teamId
            advertiserId
            name
            websiteUrl
            logoUrl
            industryCategoryId
            campaignPostProcessors
            config
            availableCredits
            bMar
            pixelCode
            pixelTracking
            pixelAudienceSize
            pixelMappingSizes
            segmentPackages
            isValid
            error
            defaultStripeId
            underReview
            teamBrandsId
            __typename
          }
          status
          recurrenceWindow
          recurrenceBudget
          name
          goal
          budget
          originalBudget
          dailyBudget
          startDate
          endDate
          originalEndDate
          audienceType
          audienceFrom
          retargetingTags {
            dspId
            firstPartyDataId
            trackingTagId
            __typename
          }
          geoSegments {
            id
            segmentPackageId
            dspId
            name
            pathKey
            segmentType
            segmentGroup
            __typename
          }
          dmpSegments {
            id
            segmentPackageId
            dspId
            name
            pathKey
            segmentType
            segmentGroup
            __typename
          }
          inventorySegments {
            id
            segmentPackageId
            dspId
            name
            pathKey
            segmentType
            segmentGroup
            __typename
          }
          timesOfDay
          daysOfWeek
          creatives {
            nextToken
            __typename
          }
          forecastRequestedAt
          forecastAdgroups
          publishedAt
          forecast {
            requestedAt
            createdAt
            __typename
          }
          ttdId
          conversionsEnabled
          trackingTagId
          pixelTrackingUrl
          conversionEvents {
            type
            id
            __typename
          }
          ttdStatus
          dspIds
          audience
          bidlists
          markups {
            oMar
            tMar
            bMar
            __typename
          }
          summaryMetrics {
            total
            __typename
          }
          ttdUpdatedAt
          activeUpdatedAt
          sfOpportunityId
          ctvSegments
          isRecurring
          isValid
          error
          isMultiDataGroups
          campaignPoliticalSettings {
            type
            __typename
          }
          additionalBidLists
          postProcessors
          brandCampaignsId
          __typename
        }
        teamBrandCampaignId
        name
        fileName
        creativeType
        s3Key
        s3ThumbnailKey
        clickthroughUrl
        landingPageUrl
        inApp
        web
        ctv
        displayTargeting
        devices
        dspIds
        ttdId
        ttdStatus
        isValid
        error
        campaignCreativesId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCredit = /* GraphQL */ `
  query GetCredit($id: ID!) {
    getCredit(id: $id) {
      id
      createdAt
      updatedAt
      adminGroups
      viewerGroups
      userId
      organizationId
      teamId
      brandId
      campaignId
      chargeType
      chargeId
      amountIntended
      amount
      status
      metadata
      __typename
    }
  }
`;
export const listCredits = /* GraphQL */ `
  query ListCredits(
    $filter: ModelCreditFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCredits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        adminGroups
        viewerGroups
        userId
        organizationId
        teamId
        brandId
        campaignId
        chargeType
        chargeId
        amountIntended
        amount
        status
        metadata
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const creditsByOrganizationTeamBrandCampaign = /* GraphQL */ `
  query CreditsByOrganizationTeamBrandCampaign(
    $organizationId: ID!
    $teamIdBrandIdCampaignIdCreatedAt: ModelCreditByOrganizationTeamBrandCampaignCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCreditFilterInput
    $limit: Int
    $nextToken: String
  ) {
    creditsByOrganizationTeamBrandCampaign(
      organizationId: $organizationId
      teamIdBrandIdCampaignIdCreatedAt: $teamIdBrandIdCampaignIdCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        adminGroups
        viewerGroups
        userId
        organizationId
        teamId
        brandId
        campaignId
        chargeType
        chargeId
        amountIntended
        amount
        status
        metadata
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const creditsByChargeId = /* GraphQL */ `
  query CreditsByChargeId(
    $chargeId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCreditFilterInput
    $limit: Int
    $nextToken: String
  ) {
    creditsByChargeId(
      chargeId: $chargeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        updatedAt
        adminGroups
        viewerGroups
        userId
        organizationId
        teamId
        brandId
        campaignId
        chargeType
        chargeId
        amountIntended
        amount
        status
        metadata
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTrackingTag = /* GraphQL */ `
  query GetTrackingTag($id: ID!) {
    getTrackingTag(id: $id) {
      id
      archived
      trackingTagType
      adminGroups
      viewerGroups
      createdAt
      updatedAt
      errorNotifiedAt
      organizationId
      teamId
      brandId
      dspIds
      status
      ttdId
      name
      pixelCode
      pixelAudienceSize
      pixelUrl
      remainingDays
      brand {
        id
        archived
        adminGroups
        viewerGroups
        createdAt
        updatedAt
        organizationId
        teamId
        team {
          id
          archived
          adminGroups
          viewerGroups
          memberGroups
          createdAt
          updatedAt
          organizationId
          organization {
            id
            archived
            adminGroups
            viewerGroups
            memberGroups
            createdAt
            updatedAt
            name
            logoUrl
            accountDomain
            accountSubdomain
            accountConfig
            config
            availableCredits
            oMar
            defaultTeamMarkup
            segmentPackages
            __typename
          }
          name
          websiteUrl
          logoUrl
          industryCategoryId
          config
          availableCredits
          paymentMethods {
            __typename
          }
          tMar
          markups {
            oMar
            tMar
            bMar
            __typename
          }
          defaultBrandMarkup
          theme {
            primary
            secondary
            pallet
            __typename
          }
          summaryMetrics {
            total
            __typename
          }
          segmentPackages
          brands {
            nextToken
            __typename
          }
          defaultStripeId
          organizationTeamsId
          __typename
        }
        advertiserId
        name
        websiteUrl
        logoUrl
        industryCategoryId
        politicalSettings {
          politicalType
          electionType
          candidate {
            PoliticalDataId
            CandidateName
            ElectionState
            PayingEntityName
            PayingEntityAddress
            PayingEntityEmailAddress
            PayingEntityExecutiveName
            PayingEntityExecutiveTitle
            GeoDescription
            AudienceDescription
            DisclaimerNotice
            FileLocationInS3
            FederalVerificationId
            StateVerificationId
            IsAdvertiserIEC
            __typename
          }
          status
          ballotMeasureProfile {
            PayingEntityName
            PayingEntityAddress
            PayingEntityExecutiveName
            PayingEntityExecutiveTitle
            ElectionState
            __typename
          }
          __typename
        }
        campaignPostProcessors
        config
        availableCredits
        paymentMethods {
          items {
            stripeId
            nickname
            lastFour
            cardType
            default
            __typename
          }
          __typename
        }
        bMar
        markups {
          oMar
          tMar
          bMar
          __typename
        }
        universalPixel {
          UniversalPixelMappings {
            __typename
          }
          UniversalPixelId
          UniversalPixelName
          AdvertiserId
          __typename
        }
        pixelCode
        pixelTracking
        pixelAudienceSize
        pixelMappingSizes
        theme {
          primary
          secondary
          pallet
          __typename
        }
        campaignSummary {
          running
          stopped
          completed
          __typename
        }
        creativeSummary {
          audio
          display
          video
          __typename
        }
        reportSummary {
          forecast
          summary
          __typename
        }
        summaryMetrics {
          items {
            summaryType
            impressions
            clicks
            views
            spend
            conversions
            __typename
          }
          total
          __typename
        }
        segmentPackages
        campaigns {
          items {
            id
            archived
            adminGroups
            viewerGroups
            createdAt
            updatedAt
            stoppedAt
            approvedAt
            lastRecurredAt
            organizationId
            teamId
            brandId
            status
            recurrenceWindow
            recurrenceBudget
            name
            goal
            budget
            originalBudget
            dailyBudget
            startDate
            endDate
            originalEndDate
            audienceType
            audienceFrom
            timesOfDay
            daysOfWeek
            forecastRequestedAt
            forecastAdgroups
            publishedAt
            ttdId
            conversionsEnabled
            trackingTagId
            pixelTrackingUrl
            ttdStatus
            dspIds
            audience
            bidlists
            ttdUpdatedAt
            activeUpdatedAt
            sfOpportunityId
            ctvSegments
            isRecurring
            isValid
            error
            isMultiDataGroups
            additionalBidLists
            postProcessors
            brandCampaignsId
            __typename
          }
          nextToken
          __typename
        }
        trackingTags {
          items {
            id
            archived
            trackingTagType
            adminGroups
            viewerGroups
            createdAt
            updatedAt
            errorNotifiedAt
            organizationId
            teamId
            brandId
            dspIds
            status
            ttdId
            name
            pixelCode
            pixelAudienceSize
            pixelUrl
            remainingDays
            piiType
            region
            retainPii
            brandTrackingTagsId
            __typename
          }
          nextToken
          __typename
        }
        isValid
        error
        defaultStripeId
        underReview
        teamBrandsId
        __typename
      }
      piiType
      region
      retainPii
      brandTrackingTagsId
      __typename
    }
  }
`;
export const listTrackingTags = /* GraphQL */ `
  query ListTrackingTags(
    $filter: ModelTrackingTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTrackingTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        archived
        trackingTagType
        adminGroups
        viewerGroups
        createdAt
        updatedAt
        errorNotifiedAt
        organizationId
        teamId
        brandId
        dspIds
        status
        ttdId
        name
        pixelCode
        pixelAudienceSize
        pixelUrl
        remainingDays
        brand {
          id
          archived
          adminGroups
          viewerGroups
          createdAt
          updatedAt
          organizationId
          teamId
          team {
            id
            archived
            adminGroups
            viewerGroups
            memberGroups
            createdAt
            updatedAt
            organizationId
            name
            websiteUrl
            logoUrl
            industryCategoryId
            config
            availableCredits
            tMar
            defaultBrandMarkup
            segmentPackages
            defaultStripeId
            organizationTeamsId
            __typename
          }
          advertiserId
          name
          websiteUrl
          logoUrl
          industryCategoryId
          politicalSettings {
            politicalType
            electionType
            status
            __typename
          }
          campaignPostProcessors
          config
          availableCredits
          paymentMethods {
            __typename
          }
          bMar
          markups {
            oMar
            tMar
            bMar
            __typename
          }
          universalPixel {
            UniversalPixelId
            UniversalPixelName
            AdvertiserId
            __typename
          }
          pixelCode
          pixelTracking
          pixelAudienceSize
          pixelMappingSizes
          theme {
            primary
            secondary
            pallet
            __typename
          }
          campaignSummary {
            running
            stopped
            completed
            __typename
          }
          creativeSummary {
            audio
            display
            video
            __typename
          }
          reportSummary {
            forecast
            summary
            __typename
          }
          summaryMetrics {
            total
            __typename
          }
          segmentPackages
          campaigns {
            nextToken
            __typename
          }
          trackingTags {
            nextToken
            __typename
          }
          isValid
          error
          defaultStripeId
          underReview
          teamBrandsId
          __typename
        }
        piiType
        region
        retainPii
        brandTrackingTagsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFeatureFlag = /* GraphQL */ `
  query GetFeatureFlag($id: ID!) {
    getFeatureFlag(id: $id) {
      id
      value
      internal {
        brandzookaEmails
        __typename
      }
      staticAuthRoles {
        staticAuthRole
        value
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFeatureFlags = /* GraphQL */ `
  query ListFeatureFlags(
    $filter: ModelFeatureFlagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeatureFlags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        value
        internal {
          brandzookaEmails
          __typename
        }
        staticAuthRoles {
          staticAuthRole
          value
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getApiKey = /* GraphQL */ `
  query GetApiKey($id: ID!) {
    getApiKey(id: $id) {
      id
      apiKeyId
      apiKey
      api
      name
      description
      brandId
      teamId
      organizationId
      adminGroups
      viewerGroups
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listApiKeys = /* GraphQL */ `
  query ListApiKeys(
    $filter: ModelApiKeyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApiKeys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        apiKeyId
        apiKey
        api
        name
        description
        brandId
        teamId
        organizationId
        adminGroups
        viewerGroups
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const apiKeyByApiKey = /* GraphQL */ `
  query ApiKeyByApiKey(
    $apiKey: String!
    $sortDirection: ModelSortDirection
    $filter: ModelApiKeyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    apiKeyByApiKey(
      apiKey: $apiKey
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        apiKeyId
        apiKey
        api
        name
        description
        brandId
        teamId
        organizationId
        adminGroups
        viewerGroups
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const apiKeysByOrganizationTeamBrand = /* GraphQL */ `
  query ApiKeysByOrganizationTeamBrand(
    $organizationId: ID!
    $teamIdBrandIdCreatedAt: ModelApiKeyByOrganizationTeamBrandCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelApiKeyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    apiKeysByOrganizationTeamBrand(
      organizationId: $organizationId
      teamIdBrandIdCreatedAt: $teamIdBrandIdCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        apiKeyId
        apiKey
        api
        name
        description
        brandId
        teamId
        organizationId
        adminGroups
        viewerGroups
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getExecutionSummary = /* GraphQL */ `
  query GetExecutionSummary($id: ID!) {
    getExecutionSummary(id: $id) {
      id
      type
      date
      successCount
      failedExecutions {
        executionId
        executionDate
        campaignId
        brandId
        teamId
        organizationId
        campaignName
        brandName
        teamName
        organizationName
        error
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listExecutionSummaries = /* GraphQL */ `
  query ListExecutionSummaries(
    $filter: ModelExecutionSummaryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExecutionSummaries(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        date
        successCount
        failedExecutions {
          executionId
          executionDate
          campaignId
          brandId
          teamId
          organizationId
          campaignName
          brandName
          teamName
          organizationName
          error
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listExecutionSummaryByDate = /* GraphQL */ `
  query ListExecutionSummaryByDate(
    $date: AWSDate!
    $sortDirection: ModelSortDirection
    $filter: ModelExecutionSummaryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExecutionSummaryByDate(
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        date
        successCount
        failedExecutions {
          executionId
          executionDate
          campaignId
          brandId
          teamId
          organizationId
          campaignName
          brandName
          teamName
          organizationName
          error
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCampaignForecast = /* GraphQL */ `
  query GetCampaignForecast($id: ID!) {
    getCampaignForecast(id: $id) {
      id
      campaignId
      campaignBudget
      brandId
      teamId
      organizationId
      adminGroups
      viewerGroups
      adgroupType
      avgBidCpm
      timeWindowInDays
      metrics {
        impressions {
          max
          min
          __typename
        }
        reach {
          max
          min
          __typename
        }
        frequency {
          max
          min
          __typename
        }
        spend {
          max
          min
          __typename
        }
        cpm {
          max
          min
          __typename
        }
        __typename
      }
      budgetMetrics {
        impressions {
          max
          min
          __typename
        }
        reach {
          max
          min
          __typename
        }
        frequency {
          max
          min
          __typename
        }
        spend {
          max
          min
          __typename
        }
        cpm {
          max
          min
          __typename
        }
        __typename
      }
      deviceMaxReach
      underSpend
      startDate
      endDate
      geoSegments {
        id
        segmentPackageId
        dspId
        name
        pathKey
        segmentType
        segmentGroup
        __typename
      }
      dmpSegments {
        id
        segmentPackageId
        dspId
        name
        pathKey
        segmentType
        segmentGroup
        __typename
      }
      inventorySegments {
        id
        segmentPackageId
        dspId
        name
        pathKey
        segmentType
        segmentGroup
        __typename
      }
      ctvSegments
      creatives {
        id
        updatedAt
        creativeType
        web
        ctv
        displayTargeting
        archived
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCampaignForecasts = /* GraphQL */ `
  query ListCampaignForecasts(
    $filter: ModelCampaignForecastFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCampaignForecasts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        campaignId
        campaignBudget
        brandId
        teamId
        organizationId
        adminGroups
        viewerGroups
        adgroupType
        avgBidCpm
        timeWindowInDays
        metrics {
          impressions {
            max
            min
            __typename
          }
          reach {
            max
            min
            __typename
          }
          frequency {
            max
            min
            __typename
          }
          spend {
            max
            min
            __typename
          }
          cpm {
            max
            min
            __typename
          }
          __typename
        }
        budgetMetrics {
          impressions {
            max
            min
            __typename
          }
          reach {
            max
            min
            __typename
          }
          frequency {
            max
            min
            __typename
          }
          spend {
            max
            min
            __typename
          }
          cpm {
            max
            min
            __typename
          }
          __typename
        }
        deviceMaxReach
        underSpend
        startDate
        endDate
        geoSegments {
          id
          segmentPackageId
          dspId
          name
          pathKey
          segmentType
          segmentGroup
          __typename
        }
        dmpSegments {
          id
          segmentPackageId
          dspId
          name
          pathKey
          segmentType
          segmentGroup
          __typename
        }
        inventorySegments {
          id
          segmentPackageId
          dspId
          name
          pathKey
          segmentType
          segmentGroup
          __typename
        }
        ctvSegments
        creatives {
          id
          updatedAt
          creativeType
          web
          ctv
          displayTargeting
          archived
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listCampaignForecastByCampaign = /* GraphQL */ `
  query ListCampaignForecastByCampaign(
    $campaignId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCampaignForecastFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCampaignForecastByCampaign(
      campaignId: $campaignId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        campaignId
        campaignBudget
        brandId
        teamId
        organizationId
        adminGroups
        viewerGroups
        adgroupType
        avgBidCpm
        timeWindowInDays
        metrics {
          impressions {
            max
            min
            __typename
          }
          reach {
            max
            min
            __typename
          }
          frequency {
            max
            min
            __typename
          }
          spend {
            max
            min
            __typename
          }
          cpm {
            max
            min
            __typename
          }
          __typename
        }
        budgetMetrics {
          impressions {
            max
            min
            __typename
          }
          reach {
            max
            min
            __typename
          }
          frequency {
            max
            min
            __typename
          }
          spend {
            max
            min
            __typename
          }
          cpm {
            max
            min
            __typename
          }
          __typename
        }
        deviceMaxReach
        underSpend
        startDate
        endDate
        geoSegments {
          id
          segmentPackageId
          dspId
          name
          pathKey
          segmentType
          segmentGroup
          __typename
        }
        dmpSegments {
          id
          segmentPackageId
          dspId
          name
          pathKey
          segmentType
          segmentGroup
          __typename
        }
        inventorySegments {
          id
          segmentPackageId
          dspId
          name
          pathKey
          segmentType
          segmentGroup
          __typename
        }
        ctvSegments
        creatives {
          id
          updatedAt
          creativeType
          web
          ctv
          displayTargeting
          archived
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAudience = /* GraphQL */ `
  query GetAudience($id: ID!) {
    getAudience(id: $id) {
      id
      dspId
      audienceType
      name
      description
      brandId
      teamId
      organizationId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAudiences = /* GraphQL */ `
  query ListAudiences(
    $filter: ModelAudienceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAudiences(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dspId
        audienceType
        name
        description
        brandId
        teamId
        organizationId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchAudiences = /* GraphQL */ `
  query SearchAudiences(
    $filter: SearchableAudienceFilterInput
    $sort: [SearchableAudienceSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableAudienceAggregationInput]
  ) {
    searchAudiences(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        dspId
        audienceType
        name
        description
        brandId
        teamId
        organizationId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          buckets {
            key
            doc_count
            __typename
          }
          value
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const getBidlist = /* GraphQL */ `
  query GetBidlist($id: ID!) {
    getBidlist(id: $id) {
      id
      dspId
      type
      category
      adjustmentType
      name
      description
      brandId
      teamId
      organizationId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listBidlists = /* GraphQL */ `
  query ListBidlists(
    $filter: ModelBidlistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBidlists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dspId
        type
        category
        adjustmentType
        name
        description
        brandId
        teamId
        organizationId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchBidlists = /* GraphQL */ `
  query SearchBidlists(
    $filter: SearchableBidlistFilterInput
    $sort: [SearchableBidlistSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableBidlistAggregationInput]
  ) {
    searchBidlists(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        dspId
        type
        category
        adjustmentType
        name
        description
        brandId
        teamId
        organizationId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          buckets {
            key
            doc_count
            __typename
          }
          value
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
