const spinnerStyles = /* CSS */ `
  @keyframes bz_spinner_outer {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes bz_spinner_inner {
    0% {
      stroke-dasharray: 0 150;
      stroke-dashoffset: 0;
    }
    47.5% {
      stroke-dasharray: 42 150;
      stroke-dashoffset: -16;
    }
    95%,100% {
      stroke-dasharray: 42 150;
      stroke-dashoffset: -59;
    }
  }

  .bz_spinner {
    transform-origin: center;
    animation: bz_spinner_outer 1.5s linear infinite
  }

  .bz_spinner_fast {
    animation-duration: 0.75s;
  }

  .bz_spinner circle {
    stroke-linecap: round;
    animation: bz_spinner_inner 1.5s ease-in-out infinite;
  }
`;

const variants = {
  appLoading: {
    className: 'h-24 stroke-current',
    strokeWidth: 1,
    speed: 'fast',
  },
  inline: {
    className: 'h-8 stroke-current',
    strokeWidth: 1,
    speed: 'fast',
  },
};

const speedClasses = {
  fast: 'bz_spinner_fast',
};

const getSpinnerClasses = speed => {
  const speedClassName = speedClasses[speed];

  if (!speedClassName) {
    return 'bz_spinner';
  }

  return `bz_spinner ${speedClassName}`;
};

export const Spinner = ({ variant = 'apploading' }) => {
  const { className, strokeWidth, speed } = variants[variant] || variants.appLoading;

  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className}>
      <style>{spinnerStyles}</style>
      <g className={getSpinnerClasses(speed)}>
        <circle cx="12" cy="12" r="9.5" fill="none" strokeWidth={strokeWidth} />
      </g>
    </svg>
  );
};
