import React, { useEffect, useState } from 'react';
import * as yup from 'yup';

export const EmailField = ({ value = '', update }) => {
  const [invalid, setInvalid] = useState(false);
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (value) setTouched(true);
  }, [value]);

  useEffect(() => {
    let mounted = true;

    yup
      .string()
      .required('email is required')
      .email('must be a valid email address')
      .validate(value)
      .then(() => mounted && setInvalid(false))
      .catch(({ message }) => mounted && setInvalid(message));

    return () => (mounted = false);
  }, [value]);

  return (
    <div data-mf-replace="">
      <input
        className="bz-input py-0 h-12 focus:ring-secondary"
        value={value}
        placeholder="Email"
        data-testid="email"
        onChange={({ target: { value: v } = {} }) => update(v)}
        onBlur={() => setTouched(true)}
      />
      <div className="my-1 h-5 flex items-center">
        {touched && invalid && (
          <p className="text-primary h-full" data-testid="emailValidation">
            {invalid}
          </p>
        )}
      </div>
    </div>
  );
};
