const containerClasses = 'relative flex flex-row';
const inputClasses = 'rounded w-full pl-6 text-gray-600 disabled:bg-gray-100 disabled:text-gray-500 focus:ring-white';

const getBorderColor = (disabled, error) => {
  if (disabled) {
    return 'border-gray-200';
  }

  if (error) {
    return 'border-red-600 focus:border-red-600';
  }

  return 'border-gray-500 focus:border-black';
};

export const CurrencyInput = ({ className = '', onChange, value, error, disabled }) => (
  <div className={`${containerClasses} ${className}`}>
    <input
      type="number"
      className={`${inputClasses} ${getBorderColor(disabled, error)}`}
      step="1"
      onChange={onChange}
      value={`${value}`}
      disabled={disabled}
    />
    <span className="pointer-events-none absolute top-1/2 transform -translate-y-1/2 left-2 text-gray-400">$</span>
  </div>
);
