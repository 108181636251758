import { Switch, Route } from 'react-router-dom';
import BrandSettingsLayout from './layout';
import BrandSettingsEditPage from './edit/page';
import BrandSettingsUsersPage from './users/page';
import BrandSettingsTrackingPage from './tracking/page';
import BrandSettingsFirstPartyPage from './first-party/page';

const brandSettingsEdit = '/brand/:id/edit';
const brandSettingsUsers = '/brand/:id/users';
const brandSettingsTracking = '/brand/:id/tracking';
const brandSettingsFirstParty = '/brand/:id/first-party';

export const brandSettingsPaths = [
  brandSettingsEdit,
  brandSettingsUsers,
  brandSettingsTracking,
  brandSettingsFirstParty,
];

export default function BrandSettings() {
  return (
    <BrandSettingsLayout>
      <Switch>
        <Route exact path={brandSettingsEdit}>
          <BrandSettingsEditPage />
        </Route>
        <Route exact path={brandSettingsUsers}>
          <BrandSettingsUsersPage />
        </Route>
        <Route exact path={brandSettingsTracking}>
          <BrandSettingsTrackingPage />
        </Route>
        <Route exact path={brandSettingsFirstParty}>
          <BrandSettingsFirstPartyPage />
        </Route>
      </Switch>
    </BrandSettingsLayout>
  );
}
