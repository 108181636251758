import { useState } from 'react';
import { PiFileImageLight } from 'react-icons/pi';
import { AddIcon, BulbIcon } from 'src/svgs';
import { useModal } from 'src/contexts';
import { useFeatureFlag } from 'src/helpers/featureFlags/useFeatureFlag';
import { CreativeCard } from './CreativeCard';
import { BuilderPanel } from '../BuilderPanel';

export const CampaignCreatives = ({ creatives, setCreativesChanged, campaignStatus, appParams }) => {
  const [show, setShow] = useState(false);
  const isCompleted = campaignStatus === 'completed';
  const { campaign } = appParams;
  const { openModal, modalData } = useModal();

  // TODO: this feature flag gets passed in through 'options' to the modal. When removing this flag, also remove code from UploadCreative.jsx
  const hasCompanionBannerFlag = useFeatureFlag('COMPANION_BANNER', false);
  const onNewClick = () => {
    openModal('upload-creative', { setCreativesChanged, hasCompanionBannerFlag });
    if (isCompleted) {
      openModal(false);
      setShow(!show);
    }
  };
  const onEditClick = creative =>
    openModal('upload-creative', { creative, setCreativesChanged, hasCompanionBannerFlag });

  const onInfoClick = () => openModal('creatives-info-modal', { modalKey: 'webVideo' });

  const creativeCards = (creatives || []).map(creative => (
    <CreativeCard
      key={creative.id}
      creative={creative}
      modalData={modalData}
      onClick={() => onEditClick(creative)}
      appParams={appParams}
      setCreativesChanged={setCreativesChanged}
    />
  ));

  const onCloneClick = () => {
    openModal('clone-campaign', {
      campaign,
    });
  };

  return (
    <BuilderPanel icon={PiFileImageLight} title="Creatives">
      <div className="w-full md:px-4 flex flex-col justify-center pt-4">
        <div className="md:w-192 lg:w-full flex justify-center pt-2">
          <button type="button" onClick={onNewClick} className="bz-btn-solid w-64" data-testid="addNewCreative">
            <AddIcon className="stroke-current w-4 h-4" />
            <h2 className="font-medium">Add New Creatives</h2>
          </button>
        </div>
        {isCompleted && show ? (
          <div className="flex justify-center">
            <div>
              <div className="flex flex-col">
                <span className="text-orange-400 pr-3 py-1">You cannot edit a completed campaign</span>
              </div>
              <div className="flex justify-center">
                <button type="button" onClick={onCloneClick} className="bz-btn-solid w-20 h-8 hover:opacity-80 rounded">
                  Clone
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div />
        )}
        <div className="flex w-full h-12 justify-center items-center text-primary pt-6">
          <h1>Your budget will be distributed evenly across your creatives</h1>
        </div>
        {!creatives?.length && (
          <div className="flex w-full justify-center pt-12">
            <button
              type="button"
              onClick={onInfoClick}
              className=" flex flex-col space-y-8 rounded-lg p-2 hover:bg-blueGray-50"
            >
              <div className="flex items-center space-x-4 text-gray-400 -mt-5 sm:mb-2">
                <BulbIcon className="w-8 h-8 text-primary stroke-current" />
                <h1 className="text-base font-medium">What types of ads can I run?</h1>
              </div>
            </button>
          </div>
        )}
        <div className="w-full grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-2 py-4 mt-3">{creativeCards}</div>
      </div>
    </BuilderPanel>
  );
};
