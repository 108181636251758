import { format } from 'date-fns';
import React from 'react';
import NumberFormat from 'react-number-format';
import { RENAMED_GOALS } from '../../../constants/goals';

const toDateStr = (date, fmt = 'LLL do') => format(date ? new Date(date) : new Date(), fmt);

const getFieldValue = (campaignObject, fieldKey) => {
  const value = campaignObject[fieldKey];

  if (fieldKey === 'goal') {
    return RENAMED_GOALS[value];
  }

  return value;
};

export const DiffValue = ({ className, campaign, campaignDraft, appParams, fieldKey, defaultValue = '-' }) => {
  let changed = false;
  let hasEnoughCredits = true;
  const { status, budget } = campaign;
  const isActive = status && !['draft', 'approved', 'completed', 'archived'].includes(status);
  const brandCredits = appParams?.brandCredits || 0;

  if (isActive && campaignDraft.budget > budget) {
    const adjBudget = campaignDraft.budget - budget;

    hasEnoughCredits = brandCredits >= adjBudget;
  }

  const campaignValue = campaign[fieldKey];
  const campaignDraftValue = campaignDraft[fieldKey];
  const isArr = Array.isArray(campaignValue) || Array.isArray(campaignDraftValue);

  if (isArr) {
    const aArr = (campaignValue || [])
      .map(v => v.pathKey || v.name)
      .sort()
      .join(',');
    const bArr = (campaignDraftValue || [])
      .map(v => v.pathKey || v.name)
      .sort()
      .join(',');

    if (aArr !== bArr) {
      changed = true;
    }
  } else if (campaignValue !== campaignDraftValue) {
    changed = true;
  }

  if (['startDate', 'endDate'].includes(fieldKey)) {
    let color = '';

    if (changed) {
      color = 'text-green-500';
    }

    if (new Date(campaignDraft.startDate) > new Date(campaignDraft.endDate)) {
      color = 'text-red-500 font-bold';
    }

    return (
      <div className="flex space-x-2">
        {changed && (
          <h1 className="capitalize line-through text-gray-400">
            {campaign[fieldKey] ? toDateStr(campaign[fieldKey]) : defaultValue}
          </h1>
        )}
        <h1 className={`capitalize font-medium ${color}`}>
          {campaignDraft[fieldKey] ? toDateStr(campaignDraft[fieldKey]) : defaultValue}
        </h1>
      </div>
    );
  }

  if (fieldKey === 'budget' || fieldKey === 'recurrenceBudget') {
    return (
      <div className="flex space-x-2">
        {changed && (
          <NumberFormat
            className="capitalize line-through text-gray-400"
            value={campaign[fieldKey] || defaultValue}
            displayType="text"
            prefix="$"
            decimalScale={2}
            fixedDecimalScale
            thousandSeparator
          />
        )}
        <NumberFormat
          className={`${changed && hasEnoughCredits && 'text-green-500 font-medium'} ${
            !hasEnoughCredits && 'text-red-500 font-bold'
          } ${className}`}
          value={campaignDraft[fieldKey] || defaultValue}
          displayType="text"
          prefix="$"
          decimalScale={2}
          fixedDecimalScale
          thousandSeparator
        />
      </div>
    );
  }

  return (
    <div className="flex space-x-2">
      {changed && (
        <h1 className="capitalize line-through text-gray-400">{getFieldValue(campaign, fieldKey) || defaultValue}</h1>
      )}
      <h1 className={`capitalize font-medium ${changed && 'text-green-500'}`}>
        {getFieldValue(campaignDraft, fieldKey) || defaultValue}
      </h1>
    </div>
  );
};
