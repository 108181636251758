import { shortNumber } from 'src/helpers';
import { ColumnCell, ColumnHeader } from './Components';

export const ClicksColumn = {
  key: 'clicks',
  header: () => <ColumnHeader className="w-32">Clicks</ColumnHeader>,
  cell: ({ metrics }) => {
    const { clicks } = metrics;

    return (
      <ColumnCell>
        {Number.isFinite(clicks) ? shortNumber(clicks, 1) : <span className="text-lg text-gray-600">--</span>}
      </ColumnCell>
    );
  },
};
