import { useGAS } from 'src/GlobalAppState/context';
import { CreditsTable } from '../../shared/CreditsTable';

export const CreditHistory = ({ marginHistory }) => {
  const {
    credits: { teamCreditRecords, teamPayoutRecords },
  } = useGAS();

  return <CreditsTable credits={marginHistory ? teamPayoutRecords : teamCreditRecords} type="team" />;
};
