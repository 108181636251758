import { selectCampaignDraftValidForSave } from 'src/selectors/campaign/summarySelectors';
import { useGAS } from 'src/GlobalAppState/context';

export const SaveDraftButton = ({ campaignDraft, onSave, draftChanged }) => {
  const { appRouteParams, brandTrackingTags } = useGAS();
  const draftValidForSave = selectCampaignDraftValidForSave(campaignDraft, { brandTrackingTags });
  const isNew = appRouteParams.page === 'new-campaign';

  const saveIsDisabled = !draftValidForSave || !draftChanged || isNew;

  return (
    <button
      type="button"
      onClick={onSave}
      disabled={saveIsDisabled}
      className="bz-btn-solid w-full h-12 2xl:h-16 disabled:cursor-default hover:opacity-80 disabled:opacity-40 rounded"
      data-testid="campaignSave"
    >
      Save Draft
    </button>
  );
};
