import { useEffect, useState, useRef } from 'react';
import { differenceInDays, differenceInMinutes } from 'date-fns';
import { useSpring, useSpringRef, animated } from 'react-spring';
import { Line } from 'react-chartjs-2';
import { useClickAway } from 'react-use';
import { useGAS } from 'src/GlobalAppState/context';
import { useAppParams } from 'src/hooks/useAppParams';
import { ChevronDown, ForecastCloud } from 'src/svgs';
import { hexToRgb, shortNumber } from 'src/helpers';
import { WarningIcon } from 'src/svgs/icons';
import { useFeatureFlag } from 'src/helpers/featureFlags/useFeatureFlag';
import InfoPopup from 'src/components/shared/Tooltips/InfoPopup';
import NumberFormat from 'react-number-format';
import { trackEvent } from 'src/helpers/trackEvent';

const FORECAST_INTERCOM_LINK =
  'http://help.brandzooka.com/en/articles/8377485-campaign-forecasting-boosting-success-in-2024-and-beyond';

const ToolTip = ({ tooltipRef, tooltipStyle }) => (
  <animated.div style={{ ...tooltipStyle, pointerEvents: 'none' }} className="relative -mt-56">
    <div
      ref={tooltipRef}
      style={{ height: 125, width: 300, pointerEvents: 'none' }}
      className="bg-white border-2 rounded-lg"
    />
  </animated.div>
);

const DropdownButton = ({ active, children, onClick }) => {
  const activeStyle = active ? 'text-primary' : 'text-gray-700';

  return (
    <button
      type="button"
      onClick={onClick}
      className={`block w-full text-left px-4 py-4 font-semibold hover:bg-gray-100 focus:bg-gray-100 ${activeStyle}`}
      role="menuitem"
    >
      {children}
    </button>
  );
};

const buildChartData = (metrics, activeMetric, theme) => {
  const labels = metrics ? metrics.map(({ spend }) => `$${shortNumber(spend.max.toFixed(0))}`) : [];
  const yAxis = activeMetric.toLowerCase();

  return {
    labels,
    datasets: [
      {
        label: activeMetric,
        data: (metrics || []).map(itm => parseFloat(itm[yAxis].max).toFixed(0)),
        fill: 'origin',
        borderColor: `rgba(${hexToRgb(theme.primary)},0.6`,
        backgroundColor: `rgba(${hexToRgb(theme.primary)},0.2`,
        pointBackgroundColor: `rgba(${hexToRgb(theme.primary)},0.6`,
        pointBorderColor: '#FFF',
      },
    ],
  };
};

const buildChartOptions = tooltipCallback => ({
  stacked: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      ticks: {
        callback: value => value,
        color: 'rgba(156, 163, 175, 1)',
      },
    },
    x: {
      ticks: {
        color: 'rgba(156, 163, 175, 1)',
      },
      grid: {
        display: false,
      },
    },
  },
  elements: {
    point: {
      radius: 3,
      borderWidth: 1,
      hoverRadius: 10,
      hoverBorderWidth: 0,
      hoverBorderColor: '#FFF',
      borderColor: '#FFF',
      backgroundColor: 'rgba(92, 89, 167, 1)',
    },
  },
  animations: {
    numbers: {
      properties: ['x', 'y', 'borderWidth', 'radius', 'tension'],
    },
  },
  animation: true,
  interaction: {
    mode: 'nearest',
  },
  layout: {
    border: 0,
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      usePointStyle: true,
      position: 'nearest',
      enabled: false,
      external: tooltipCallback,
    },
  },
});

export default function CampaignForecastPage() {
  const appParams = useAppParams();
  const { campaignForecasts: forecasts, campaign } = useGAS();
  const [activeForecast, setActiveForecast] = useState(false);
  const [activeMetric, setActiveMetric] = useState('Impressions');
  const [open, setOpen] = useState(false);
  const [openMetric, setOpenMetric] = useState(false);
  const menuRef = useRef();
  const menuRefMetric = useRef();
  const tooltipRef = useRef();
  const tooltipSpringRef = useSpringRef();
  const tooltipStyle = useSpring({ x: 0, y: 0, opacity: 0, borderColor: '#000', ref: tooltipSpringRef });
  const { budget: campaignBudget, startDate, endDate, name, forecastRequestedAt, forecastAdgroups = [] } = campaign;
  const hasV2ForecastingChartFlag = useFeatureFlag('V2_FORECASTING_CHART', false);

  const start = new Date(startDate);
  const end = new Date(endDate);
  const dayCount = (differenceInDays(end, start) || 0) + 1;
  const processing = forecastRequestedAt && !forecasts.length;
  const loadingAdgroups = processing || forecastAdgroups?.length !== forecasts?.length;
  const requestedMinAgo = (forecastRequestedAt && differenceInMinutes(new Date(), new Date(forecastRequestedAt))) || 0;
  const expiredRequest = !forecasts.length && requestedMinAgo >= 10;

  useEffect(() => {
    if (forecasts.length) {
      setActiveForecast(forecasts[0] || []);
    }
  }, [forecasts]);

  useClickAway(menuRef, () => setOpen(false));
  useClickAway(menuRefMetric, () => setOpenMetric(false));

  const onAdgroupClick = adgroup => {
    const [active] = forecasts.filter(f => f.adgroupType === adgroup);

    setActiveForecast(active);
    setOpen(false);
  };

  const onMetricClick = metric => {
    setActiveMetric(metric);
    setOpenMetric(false);
  };

  useEffect(() => {
    trackEvent('campaign-forecast-viewed', {});
  }, []);

  const tooltipCallback = context => {
    const { tooltip: { dataPoints: [{ raw: value, element: { x, y } = {} }] = [{}], opacity } = {} } = context;
    const [fore] = activeForecast.budgetMetrics.filter(f => parseFloat(f.impressions.max).toFixed(0) === value);

    if (fore) {
      const spendMin = shortNumber(fore.spend.min.toFixed(2));
      const spendMax = shortNumber(fore.spend.max.toFixed(2));
      const impMin = shortNumber(fore.impressions.min.toFixed(0));
      const impMax = shortNumber(fore.impressions.max.toFixed(0));
      const reachMin = shortNumber(fore.reach.min.toFixed(0));
      const reachMax = shortNumber(fore.reach.max.toFixed(0));

      tooltipRef.current.innerHTML = `\
  <div class="flex flex-col space-y-1 items-center justify-center w-full h-full pb-2 z-40 text-gray-600 text-sm">
  <h1>Spend: $${spendMin} - $${spendMax}</h1>
  <div>Reach: ${reachMin} - ${reachMax}</div>
  <div>Impressions: ${impMin} - ${impMax}</div>
  </div>`;
      if (x < 215) tooltipSpringRef.start({ x: x - 20, y: y + 80, opacity });
      else tooltipSpringRef.start({ x: x - 260, y: y + 80, opacity });
    }
  };

  const lineChartOptions = buildChartOptions(tooltipCallback);
  const lineChartData = buildChartData(activeForecast?.budgetMetrics || [], activeMetric, appParams.theme);

  const campaignForecasts = {};
  const underSpendAdgroups = [];

  for (const forecast of forecasts) {
    const { metrics, adgroupType, deviceMaxReach, underSpend, updatedAt } = forecast;

    metrics.updatedAt = updatedAt;
    metrics.maxReach = deviceMaxReach;
    metrics.underSpend = underSpend;

    campaignForecasts[adgroupType] = metrics;

    if (underSpend) {
      const adgType = adgroupType.replace(/([a-z])([A-Z])/g, '$1 $2');
      const adgroupStr = adgType.charAt(0).toUpperCase() + adgType.slice(1);

      underSpendAdgroups.push(adgroupStr);
    }
  }

  if (forecasts.length > 1) {
    const allMetrics = forecasts.map(item => item.metrics);

    const totals = allMetrics.reduce(
      (obj, itm) => {
        obj.impressions.min += itm.impressions.min;
        obj.impressions.max += itm.impressions.max;
        obj.frequency.min += itm.frequency.min;
        obj.frequency.max += itm.frequency.max;
        obj.reach.min += itm.reach.min;
        obj.reach.max += itm.reach.max;
        obj.spend.min += itm.spend.min;
        obj.spend.max += itm.spend.max;
        obj.maxReach += itm.maxReach;

        return obj;
      },
      {
        impressions: { min: 0, max: 0 },
        frequency: { min: 0, max: 0 },
        reach: { min: 0, max: 0 },
        spend: { min: 0, max: 0 },
        cpm: { min: 0, max: 0 },
        maxReach: 0,
      }
    );

    const cpmMin = (campaign.budget / Number(totals.impressions.max.toFixed(0))) * 1000;
    const cpmMax = (campaign.budget / Number(totals.impressions.min.toFixed(0))) * 1000;

    totals.cpm.min = cpmMin;
    totals.cpm.max = cpmMax;

    campaignForecasts.All = totals;
  }

  const summaryItems = Object.entries(campaignForecasts).map(([k, v]) => {
    const k1 = k.replace(/([a-z])([A-Z])/g, '$1 $2');
    let adgroupStr = k1.charAt(0).toUpperCase() + k1.slice(1);

    if (adgroupStr === 'Ctv') adgroupStr = 'CTV';
    const cpmMin = v.cpm.min ? `$${v.cpm.min.toFixed(2)} ` : '';
    const cpmMax = v.cpm.max ? ` - $${v.cpm.max.toFixed(2)}` : '';

    return (
      <div key={`summary-${k}`} className="h-auto relative w-full bg-white p-3">
        <div className="text-lg font-bold w-1/3 pl-2">{adgroupStr}</div>
        {k !== 'All' && (
          <div className="text-sm text-gray-500 pl-2">Generated At: {new Date(v.updatedAt).toLocaleString()}</div>
        )}
        <div className="p-5">
          {v.underSpend && (
            <div className="flex absolute top-3 right-3 text-xs text-center bg-yellow-100 p-2 rounded-full">
              <WarningIcon className="h-5 w-5 flex-shrink-0 fill-gray-600" />
              <span className="flex ml-2 p-1 font-bold">Deliverability Risk</span>
            </div>
          )}
          <table className={`table-auto w-full border-2 border-gray-100 ${k === 'All' ? 'mt-5' : ''}`}>
            <tbody>
              <tr className="border-b-2 border-gray-100">
                <td className="p-3">
                  CPM
                  {k === forecasts[0].adgroupType && (
                    <div className="absolute -mt-8 ml-11">
                      <InfoPopup
                        position="right"
                        content="CPM, or cost per thousand impressions, calculates the average cost per one thousand impressions delivered. Your CPM contains both the cost of the media, as well as the cost of your audience data, and differs between Display, Web Video, CTV, and Audio file formats."
                      />
                    </div>
                  )}
                </td>
                <td className="p-3 float-right">{`${cpmMin}${cpmMax}`}</td>
              </tr>
              <tr className="border-b-2 border-gray-100">
                <td className="p-3">
                  Reach
                  {k === forecasts[0].adgroupType && (
                    <div className="absolute -mt-8 ml-14">
                      <InfoPopup
                        position="right"
                        content="Reach measures the size of the audience to which your ads will be seen. It factors in the number of targetable devices and the frequency of which your ads will be shown to each unique device ID."
                      />
                    </div>
                  )}
                </td>
                <td className="p-3 float-right">
                  <NumberFormat value={v.reach.min} displayType="text" decimalScale={0} thousandSeparator />
                  <span> - </span>
                  <NumberFormat value={v.reach.max} displayType="text" decimalScale={0} thousandSeparator />
                </td>
              </tr>
              <tr className="border-b-2 border-gray-100">
                <td className="p-3">
                  Impressions
                  {k === forecasts[0].adgroupType && (
                    <div className="absolute -mt-8 ml-28">
                      <InfoPopup
                        position="right"
                        content="An impression occurs when an ad loads on an active device, application, or web browser."
                      />
                    </div>
                  )}
                </td>
                <td className="p-3 float-right">
                  <NumberFormat value={v.impressions.min} displayType="text" decimalScale={0} thousandSeparator />
                  <span> - </span>
                  <NumberFormat value={v.impressions.max} displayType="text" decimalScale={0} thousandSeparator />
                </td>
              </tr>
              <tr>
                <td className="p-3">
                  Max. Device Reach
                  {k === forecasts[0].adgroupType && (
                    <div className="absolute -mt-8 ml-40">
                      <InfoPopup
                        position="right"
                        content="Your max device reach is how many devices are available based on the audience and geographical targeting you’ve selected."
                      />
                    </div>
                  )}
                </td>
                <td className="p-3 float-right">{`${shortNumber(v.maxReach.toFixed(0))}`}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  });

  const getLoadingMsg = () => {
    if (expiredRequest) {
      return (
        <>
          <h1 className="text-primary font-semibold text-2xl">Forecast Generation Timeout</h1>
          <h2 className="text-primary text-lg">
            Please contact your account representative or reach out to our support team via the chat.
          </h2>
        </>
      );
    }
    if (loadingAdgroups) {
      return (
        <>
          <h1 className="text-primary font-semibold text-2xl">Generating Campaign Forecast</h1>
          <h2 className="text-primary text-lg">check back in a few minutes</h2>
        </>
      );
    }

    return <h1 className="text-primary font-semibold text-2xl">Loading Campaign Forecast</h1>;
  };

  return (
    <div className="flex-1 flex flex-col h-full overflow-y-scroll py-3">
      <div className="w-full flex items-center">
        <div className="flex flex-col w-full">
          <div className="bg-secondary text-white capitalize truncate w-full p-5 rounded" title="forecast">
            <div className="text-4xl pb-2 pt-5">{name}</div>
            <div className="font-bold text-4xl">Forecasted Metrics</div>
          </div>
          {(loadingAdgroups || expiredRequest) && (
            <div className="w-full flex justify-center h-96">
              <div className="w-full flex flex-col space-y-4 rounded-lg bg-white shadow-lg pb-2 pt-24">
                <div className="w-full flex justify-center items-center p-4">
                  <div className="flex flex-col items-center space-y-4">
                    <ForecastCloud className="stroke-current text-primary w-10" />
                    {getLoadingMsg()}
                  </div>
                </div>
              </div>
            </div>
          )}
          {hasV2ForecastingChartFlag && !loadingAdgroups && forecasts?.length > 0 && (
            <>
              <div className="flex w-full bg-white mt-5 pt-8 -mb-5 pl-5">
                <div className="relative w-80">
                  <span className="float-left p-3">Metric:</span>
                  <button
                    type="button"
                    onClick={() => setOpenMetric(!openMetric)}
                    className="flex w-48 items-center justify-between space-x-4 pl-4 pr-3 py-2 bg-white border-2 border-gray-300"
                  >
                    <h1 className="text-lg font-semibold capitalize">{activeMetric}</h1>
                    <ChevronDown className="w-5 h-5 stroke-current text-primary" />
                  </button>
                  <div
                    ref={menuRefMetric}
                    className={`origin-top-right ${
                      !openMetric && 'hidden'
                    } absolute z-30 left-20 mt-0 w-48 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
                  >
                    <div className="py-1">
                      <DropdownButton
                        active={activeMetric === 'Impressions'}
                        onClick={() => onMetricClick('Impressions')}
                      >
                        <span>Impressions</span>
                      </DropdownButton>
                      <DropdownButton active={activeMetric === 'Reach'} onClick={() => onMetricClick('Reach')}>
                        <span>Reach</span>
                      </DropdownButton>
                    </div>
                  </div>
                </div>
                <div className="relative w-96">
                  <span className="float-left p-3">Ad Group:</span>
                  {forecasts.length > 1 ? (
                    <>
                      <button
                        type="button"
                        onClick={() => setOpen(!open)}
                        className="flex w-48 items-center justify-between space-x-4 pl-4 pr-3 py-2 bg-white border-2 border-gray-300"
                      >
                        <h1 className="text-lg font-semibold capitalize">
                          {activeForecast?.adgroupType?.replace(/([a-z])([A-Z])/g, '$1 $2')}
                        </h1>
                        <ChevronDown className="w-5 h-5 stroke-current text-primary" />
                      </button>
                      <div
                        ref={menuRef}
                        className={`origin-top-right ${
                          !open && 'hidden'
                        } absolute z-30 left-24 w-48 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
                      >
                        <div className="py-1">
                          {forecasts.map(data => (
                            <DropdownButton
                              active={activeForecast.adgroupType === data.adgroupType}
                              onClick={() => onAdgroupClick(data.adgroupType)}
                              key={data.adgroupType}
                            >
                              <span className="capitalize">{data.adgroupType.replace(/([a-z])([A-Z])/g, '$1 $2')}</span>
                            </DropdownButton>
                          ))}
                        </div>
                      </div>
                    </>
                  ) : (
                    <h1 className="flex capitalize text-lg font-semibold p-1 mt-1">
                      {(activeForecast && activeForecast.adgroupType.replace(/([a-z])([A-Z])/g, '$1 $2')) || ''}
                    </h1>
                  )}
                </div>
              </div>
              <div className="w-full p-8 bg-white mt-5">
                <ToolTip tooltipRef={tooltipRef} tooltipStyle={tooltipStyle} />
                <div className="h-96 mt-24">
                  <Line options={lineChartOptions} data={lineChartData} />
                </div>
              </div>
            </>
          )}
          {!processing && !loadingAdgroups && underSpendAdgroups.length > 0 && (
            <div className="bg-yellow-100 p-5">
              <div className="pb-3 font-bold">Deliverability Warning</div>
              <div>The following ad groups may not spend their full budget:</div>
              <ul className="list-disc ml-10">
                {underSpendAdgroups.map((data, idx) => (
                  <li key={`${data}_${idx}`}>{data}</li>
                ))}
              </ul>
              <div className="pt-3">
                Try expanding your audience or campaign duration.{' '}
                <a href={FORECAST_INTERCOM_LINK} className="underline" target="_blank" rel="noreferrer">
                  Learn more about improving deliverability.
                </a>
              </div>
            </div>
          )}
          {!processing && !loadingAdgroups && (
            <div className="w-full">
              <div className="py-5 px-3 bg-white my-3">
                Based on your budget of ${campaignBudget}, and a campaign length of {dayCount} days, your estimated
                results are:
              </div>
              <div
                className={`grid grid-cols-1 ${
                  forecasts.length > 1 ? '2xl:grid-cols-2' : '2xl:grid-cols-1 w-3/4 m-auto'
                } gap-3`}
              >
                {summaryItems}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
