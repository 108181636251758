import { useEffect, useRef, useState } from 'react';
import { FaTrash, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { shortNumber } from 'src/helpers';
import { updateTrackingTag } from 'src/helpers/trackingTag';
import { CheckIcon, CloseIcon, EditIcon } from 'src/svgs';

export const CustomTagRow = ({ tag, onDelete, submitting, activeRow, setActiveRow }) => {
  const [showEdit, setShowEdit] = useState(false);
  const [nameDraft, setNameDraft] = useState(tag.name || '');
  const [ttdIdDraft, setTtdIdDraft] = useState(tag.ttdId || '');
  const inputRef = useRef();

  useEffect(() => {
    setTtdIdDraft(tag.ttdId || '');
  }, [tag.ttdId]);

  useEffect(() => {
    setNameDraft(tag.name || '');
  }, [tag.name]);

  useEffect(() => {
    if (showEdit && tag.id) {
      if (inputRef.current) inputRef.current.select();
      setActiveRow(tag.id);
    }
  }, [showEdit, setActiveRow, tag.id]);

  const onNameChange = ({ target }) => setNameDraft(target.value.slice(0, 30));

  const onCancel = () => {
    setNameDraft(tag.name || '');
    setTtdIdDraft(tag.ttdId || '');
    setShowEdit(false);

    setActiveRow(false);
  };

  const onSubmit = () => {
    updateTrackingTag({ id: tag.id, name: nameDraft, ttdId: ttdIdDraft }).then(() => {
      setShowEdit(false);
      setActiveRow(false);
    });
  };

  const hideActions = activeRow && activeRow !== tag.id;
  const enableEdit = !submitting && tag.pixelCode;

  const buttonGroup = showEdit ? (
    <div className="flex items-center space-x-2">
      <button
        type="button"
        onClick={onCancel}
        className="bz-btn-icon rounded-full w-6 h-6 border-2 border-gray-300 p-1"
      >
        <CloseIcon className="stroke-current text-gray-300 w-4 h-4" />
      </button>
      <button type="button" onClick={onSubmit} className="bz-btn-icon rounded-full w-6 h-6 border-2 border-primary p-1">
        <CheckIcon className="stroke-current text-primary w-4 h-4" />
      </button>
      {!hideActions && showEdit && (
        <button
          type="button"
          title="delete"
          onClick={() => showEdit && onDelete(tag)}
          disabled={!showEdit}
          className={`bz-btn-icon rounded-full w-8 h-8 ${showEdit ? 'text-red-600' : 'text-gray-300 cursor-default'}`}
        >
          <FaTrash className="stroke-current  h-6" />
        </button>
      )}
    </div>
  ) : (
    <button type="button" onClick={() => setShowEdit(true)} className="bz-btn-icon">
      <EditIcon className="stroke-current text-primary w-6 h-6" />
    </button>
  );

  let tagStatus = <span>{tag.status}</span>;
  let errorText = '';

  if (tag.status === 'succeeded') {
    tagStatus = <FaCheckCircle className="stroke-current text-green-800 h-6 w-14" />;
  }
  if (tag.status.includes('Error')) {
    tagStatus = <FaTimesCircle className="stroke-current text-red-800 h-6 w-14" />;
    errorText = tag.status;
  }

  return (
    <tr key={tag.id} className="w-full">
      <td className="">
        {!hideActions && <div className="w-full h-full flex items-center justify-center">{buttonGroup}</div>}
      </td>
      <td className="py-2 border-b h-16 w-32">
        {!showEdit && <h3 className="truncate text-xl font-medium">{tag.ttdId}</h3>}
        {showEdit && (
          <input
            type="text"
            className="bz-input bg-blueGray-50 shadow-inner text-xl focus:ring px-2 -ml-2"
            value={ttdIdDraft}
            disabled
          />
        )}
      </td>
      <td className="w-64 border-b">
        {!showEdit && tag.name && <h3 className="truncate text-xl font-medium">{tag.name}</h3>}
        {!showEdit && errorText && <h3 className="text-red-800 truncate text-xl font-medium">{errorText}</h3>}
        {showEdit && enableEdit && (
          <input
            ref={inputRef}
            type="text"
            className="bz-input bg-blueGray-50 shadow-inner text-xl focus:ring px-2 -ml-2"
            value={nameDraft}
            onChange={onNameChange}
          />
        )}
      </td>
      <td className="py-2 border-b h-16">{tagStatus}</td>
      <td className="w-40 border-b">
        <h3 className="text-xl text-center">{shortNumber(tag.pixelAudienceSize)}</h3>
      </td>
    </tr>
  );
};
