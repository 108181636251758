import { useCallback, useState } from 'react';
import NumberFormat from 'react-number-format';
import { PiDownloadSimpleLight } from 'react-icons/pi';
import { ReportButton } from 'src/components/shared';
import { useModal } from 'src/contexts';
import { useAppParams } from 'src/hooks/useAppParams';
import { useCSVDownloader } from '../CsvDownloader/CsvDownloader';

const CampaignPDFReportDownloadButton = ({ marginPct, children }) => {
  const { openModal } = useModal();

  const onReportClick = useCallback(() => openModal('report-campaign-summary', { marginPct }), [openModal, marginPct]);

  return <ReportButton btnTxt={children} onClick={onReportClick} />;
};

const CampaignCSVReportDownloadButton = ({ appParams, startDate, endDate, marginPct, children }) => {
  const { loading, onClick } = useCSVDownloader(appParams, { startDate, endDate, marginPct: marginPct * 100 });

  const onReportClick = useCallback(() => onClick('campaign-daily'), [onClick]);

  return <ReportButton btnTxt={loading ? 'Loading…' : children} onClick={onReportClick} disabled={loading} />;
};

const MarginToggle = ({ onClick, showMargin }) => {
  const toggleBtnClass = 'w-14 h-7 flex items-center border-2 border-gray-400 bg-gray-200 rounded-full p-1';

  return (
    <div className="flex flex-row flex-nowrap items-center space-x-2 relative">
      <div className="flex cursor-pointer w-16" onClick={onClick}>
        {!showMargin ? (
          <div className={`${toggleBtnClass} opacity-50`}>
            <span className="ml-7 opacity-50">&#x58;</span>
          </div>
        ) : (
          <div className={`${toggleBtnClass}`}>
            <span className="ml-1">&#10003;</span>
          </div>
        )}
        <div
          className={`ml-1 mt-1 absolute h-5 w-5 px-1 rounded-full bg-primary shadow-md transform ${
            !showMargin ? '' : 'translate-x-7'
          }`}
        />
      </div>
      <div className={`flex text-sm opacity-50 ${showMargin ? 'opacity-100' : 'opacity-0'} whitespace-nowrap`}>
        Enable Report Markup
      </div>
    </div>
  );
};

const ReportMargin = ({ onChange, value }) => (
  <NumberFormat
    className="border rounded px-3 py-2 w-24 border-gray-400 hover:border-gray-600 focus:border-blue-400"
    suffix="%"
    value={value}
    displayType="input"
    decimalScale={0}
    allowLeadingZeros={false}
    onValueChange={({ floatValue: v }) => onChange(v)}
  />
);

export const CampaignReportsCard = ({ campaign }) => {
  const appParams = useAppParams();
  const { startDate, endDate } = campaign;
  const [showMargin, setShowMargin] = useState(false);
  const [marginPct, setMarginPct] = useState(0);

  const onMarginClick = useCallback(() => setShowMargin(!showMargin), [showMargin]);
  const onMarginChange = useCallback(v => setMarginPct(v ? v / 100 : 0), [setMarginPct]);

  return (
    <div className="w-full flex flex-row space-x-4 justify-between rounded shadow bg-white p-4">
      <div className="flex flex-wrap justify-between items-start">
        <div className="flex items-center space-x-6 h-16">
          <PiDownloadSimpleLight className="w-11 h-11 mt-px stroke-current text-primary" />
          <h1 className="text-gray-900 text-xl font-semibold">Reports</h1>
          <MarginToggle onClick={onMarginClick} showMargin={showMargin} />
          {showMargin && <ReportMargin onChange={onMarginChange} value={marginPct * 100} />}
        </div>
      </div>
      <div className="flex flex-row justify-start items-center space-x-6">
        <CampaignPDFReportDownloadButton marginPct={showMargin ? marginPct : 0}>
          Summary PDF
        </CampaignPDFReportDownloadButton>
        <CampaignCSVReportDownloadButton
          appParams={appParams}
          startDate={startDate}
          endDate={endDate}
          marginPct={showMargin ? marginPct : 0}
        >
          Daily Totals CSV
        </CampaignCSVReportDownloadButton>
      </div>
    </div>
  );
};
