import { format } from 'date-fns';
import { get, has, partition, round, set, sortBy } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import NumberFormat from 'react-number-format';
import { getCampaign as rawGetCampaign } from 'src/helpers/campaign';
import { getBrand as rawGetBrand } from 'src/helpers/brand';
import { getTeam as rawGetTeam } from 'src/helpers/team';
import { getCredits as rawGetCredits } from 'src/helpers/getCredits';
import { AddIcon, SubtractIcon } from 'src/svgs/icons';
import { TransactionDescriptor } from './CreditsTable/TransactionDescriptors';

const queryCacher = (obj, path, value) => {
  if (!has(obj, path)) set(obj, path, value());

  return get(obj, path);
};

// This helper hook exists to reduce query volume until we can get centralized state.
// We use the returned get{Record} functions here in place of the imported versions
// to ensure we're only issuing one request per ID
const useLocalQueryCache = () => {
  const cacheData = useRef({
    teamRequests: {},
    brandRequests: {},
    campaignRequests: {},
    creditRequests: {},
  });

  return useMemo(
    () => ({
      getCampaign: id => queryCacher(cacheData.current.campaignRequests, id, () => rawGetCampaign(id)),
      getBrand: id => queryCacher(cacheData.current.brandRequests, id, () => rawGetBrand(id)),
      getTeam: id => queryCacher(cacheData.current.teamRequests, id, () => rawGetTeam(id)),
      getCredits: ids =>
        queryCacher(
          cacheData.current.teamRequests,
          [ids.organizationId, (ids.teamId = ''), (ids.brandId = ''), (ids.campaignId = '')],
          () => rawGetCredits(ids)
        ),
    }),
    []
  );
};

const sortAndSumCredits = credits => {
  const sorted = sortBy(credits, 'createdAt');
  const [pendingCredits, nonPendingCredits] = partition(sorted, isProcessingACH);

  return nonPendingCredits.concat(pendingCredits).reduce(
    (acc, credit) => {
      acc.sum = round(acc.sum + credit.amount, 2);
      acc.rows.unshift({
        credit,
        sum: acc.sum,
      });

      return acc;
    },
    { sum: 0, rows: [] }
  );
};

const useCachedOrgdata = ({ getCampaign, getBrand, getTeam, getCredits }, type, credit) => {
  const [campaign, setCampaign] = useState({});
  const [brand, setBrand] = useState({});
  const [team, setTeam] = useState({});

  useEffect(() => {
    let mounted = true;
    const unMount = () => (mounted = false);

    const { organizationId, teamId, brandId, campaignId, chargeType, chargeId, id } = credit || {};
    const metadata = credit?.metadata || {};

    if (type === 'team') {
      if (!metadata.brand) {
        if (brandId) {
          getBrand(brandId).then(resp => mounted && setBrand(resp));
        } else if (chargeType === 'transfer') {
          getCredits({ organizationId, teamId })
            .then(resp => resp.filter(itm => itm.chargeId === chargeId && itm.id !== id))
            .then(([resp]) => resp && getBrand(resp.brandId))
            .then(resp => mounted && setBrand(resp));
        }
      }

      if (!metadata.campaign) {
        if (campaignId) {
          getCampaign(campaignId).then(resp => mounted && setCampaign(resp));
        }
      }

      if (chargeType === 'payout') {
        getCampaign(chargeId).then(camp => {
          if (mounted) setCampaign(camp);
          getBrand(camp.brandId).then(brnd => mounted && setBrand(brnd));
        });
      }
    }

    if (type === 'brand') {
      if (!metadata.campaign && campaignId) {
        getCampaign(campaignId).then(resp => mounted && setCampaign(resp));
      } else if (!metadata.team && teamId) {
        getTeam(teamId).then(resp => mounted && setTeam(resp));
      }
    }

    return unMount;
  }, [getCampaign, getBrand, getTeam, getCredits, type, credit]);

  return {
    campaign,
    brand,
    team,
  };
};

const getDate = timestamp => {
  const date = timestamp ? new Date(timestamp) : new Date();

  return format(date, 'PPP');
};

const isProcessingACH = credit =>
  ['processing', 'pending'].includes(credit.status) && credit.amount === 0 && credit.amountIntended > 0;

const CreditAmount = ({ credit }) => {
  const isPending = isProcessingACH(credit);

  return (
    <div className={`h-full flex items-center justify-end space-x-2 ${isPending ? 'text-gray-500' : ''}`}>
      {credit.amount < 0 ? <SubtractIcon className="w-3 h-3" /> : <AddIcon className="w-3 h-3" />}
      <NumberFormat
        className="font-medium ml-4"
        value={Math.abs(isPending ? credit.amountIntended : credit.amount || 0)}
        displayType="text"
        prefix="$"
        decimalScale={2}
        fixedDecimalScale
        thousandSeparator
      />
    </div>
  );
};

const CreditRow = ({ credit, isOdd, sum, queryCache, type }) => {
  const { campaign, brand, team } = useCachedOrgdata(queryCache, type, credit);

  return (
    <tr data-mf-replace="" key={credit.id} className={`w-full h-20 ${isOdd ? 'bg-blueGray-100' : 'bg-white'}`}>
      <td className="pl-12">{getDate(credit.createdAt)}</td>
      <td className="pl-4 text-sm">
        <div className="flex flex-col space-y-1">
          <span className="text-base text-gray-800">
            <TransactionDescriptor credit={credit} type={type} team={team} brand={brand} campaign={campaign} />
          </span>
        </div>
      </td>
      <td className="text-right pr-12 border-l">{credit.amount >= 0 && <CreditAmount credit={credit} />}</td>
      <td className="text-right pr-12">{credit.amount < 0 && <CreditAmount credit={credit} />}</td>
      <td className="text-right pr-12">
        {!isProcessingACH(credit) && (
          <NumberFormat
            className="w-20"
            value={sum}
            displayType="text"
            prefix="$"
            decimalScale={2}
            fixedDecimalScale
            thousandSeparator
          />
        )}
      </td>
    </tr>
  );
};

export const CreditsTable = ({ credits, type }) => {
  const queryCache = useLocalQueryCache();
  const { rows } = useMemo(() => sortAndSumCredits(credits), [credits]);

  return (
    <div className="w-full flex-1 bg-white rounded border border-gray-300 shadow-lg pb-4 min-h-0 overflow-scroll">
      <table className="w-full" data-mf-replace="">
        <thead>
          <tr className="h-20 border-b text-lg font-medium top-0 bg-white">
            <td className="w-64 pl-12">Date</td>
            <td className="pl-4">Transactions</td>
            <td colSpan="2" className="w-96" />
            <td className="text-right pr-12 w-48">Balance</td>
          </tr>
        </thead>
        <tbody data-mf-replace="" className="border-b">
          {rows.map(({ credit, sum }, index) => (
            <CreditRow
              key={credit.id}
              isOdd={index % 2}
              credit={credit}
              sum={sum}
              queryCache={queryCache}
              type={type}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};
