export const userFields = /* GraphQL */ `
id
cognitoId
userId
authGroup
createdAt
updatedAt
selectedAuth
firstName
lastName
profileImageUrl
hasBeenWelcomed
email
authRoles {
  items {
    id
    createdAt
    updatedAt
    adminGroups
    viewerGroups
    status
    email
    userId
    authRole
    staticAuthRole
    organizationId
    teamId
    brandId
    inviteCode
  }
  nextToken
}
`;
