import { API } from 'aws-amplify';
import { campaignFields } from './campaignFields';
import { putAppEvent } from '../putAppEvent';

const createCampaignQuery = /* GraphQL */ `
  mutation CreateCampaign($input: CreateCampaignInput!) {
    createCampaign(input: $input) {
      ${campaignFields}
    }
  }
`;

const cleanCampaignInput = campaign => {
  const {
    brandCampaignsId: _brandCampaignsId,
    creatives: _creatives,
    summaryMetrics: _summaryMetrics,
    ...rest
  } = campaign;

  return rest;
};

export const createCampaign = async input =>
  API.graphql({
    query: createCampaignQuery,
    variables: { input: cleanCampaignInput(input) },
  })
    .then(({ data: { createCampaign: resp } = {} }) => resp || false)
    .then(resp => {
      putAppEvent('BrandMutation', input);

      return resp;
    })
    .catch(({ data: { createCampaign: resp } = {} }) => resp || false);
