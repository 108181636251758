import { useCallback } from 'react';
import { METRICS, READABLE_KEYS } from './constants';

const readableRegion = k => READABLE_KEYS[k] || k;

export const MetricsSelector = ({ metricSelection, setMetricSelection }) => (
  <>
    {METRICS.map(metricName => (
      <button
        key={metricName}
        className={`first:rounded-l last:rounded-r px-2 py-1 border-l flex-1 first:border-0 ${
          metricName === metricSelection ? 'bg-primary text-white' : 'text-gray-500'
        }`}
        type="button"
        onClick={() => setMetricSelection(metricName)}
      >
        {metricName}
      </button>
    ))}
  </>
);

export const RegionMapSelector = ({
  mapParams,
  selectedGroup,
  selectedSegmentGroup,
  mapSegmentGroup,
  setSelectedSegmentGroup,
}) => {
  const updateSelectedSegmentGroup = useCallback(
    item => {
      setSelectedSegmentGroup({ ...selectedSegmentGroup, [mapSegmentGroup]: item });
    },
    [setSelectedSegmentGroup, selectedSegmentGroup, mapSegmentGroup]
  );

  let mapOptions = mapParams?.maps || ['County'];

  mapOptions.length = 2;

  if (mapParams.regionSegmentGroup === 'Zip') mapOptions = ['Zip'];

  return (
    <>
      {mapOptions.map(item => (
        <button
          key={item}
          className={`first:rounded-l last:rounded-r px-2 py-1 border-l first:border-0 ${
            item === selectedGroup ? 'bg-primary text-white' : 'text-gray-500'
          }`}
          type="button"
          onClick={() => updateSelectedSegmentGroup(item)}
        >
          {readableRegion(item)}
        </button>
      ))}
    </>
  );
};
