import './init';
import { BrowserRouter } from 'react-router-dom';
import { MobileWarning } from 'src/components/App/MobileWarning';
import { UserAuthProvider } from 'src/GlobalAppState/UserAuth';
import { GlobalAppState } from '../GlobalAppState/GlobalAppState';

export default function SiteLayout({ children }) {
  return (
    <>
      <MobileWarning />
      <UserAuthProvider>
        <BrowserRouter>
          <GlobalAppState>{children}</GlobalAppState>
        </BrowserRouter>
      </UserAuthProvider>
    </>
  );
}
