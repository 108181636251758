import React, { useState, useMemo, useRef, useCallback } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import * as SafeAuth from 'src/helpers/SafeAuth';
import { AuthContainer } from 'src/components/Auth/Layout';
import { useAuthProvider } from 'src/providers/AuthProvider';
import { useAlreadyAuthedRootRedirect } from 'src/hooks/authHooks';

export default function ResetPasswordPage() {
  const { logo, isWhiteLabel } = useAuthProvider();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [processing, setProcessing] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const emailRef = useRef();
  const codeRef = useRef();
  const passwordRef = useRef();

  useAlreadyAuthedRootRedirect();

  const inputParams = useMemo(() => {
    if (location?.search) {
      const params = new URLSearchParams(location.search);

      return {
        code: params.get('code'),
        email: params.get('email'),
      };
    }
  }, [location]);

  const [verificationToken, setVerificationToken] = useState(inputParams?.code ?? '');
  const [email, setEmail] = useState(inputParams?.email ?? '');

  const onResetPassword = useCallback(
    e => {
      e?.preventDefault();
      setErrorMessage('');
      if (password && password !== confirmPassword) {
        return setErrorMessage('passwords do not match');
      }

      setProcessing(true);
      SafeAuth.forgotPasswordSubmit(email, verificationToken, password)
        .then(() =>
          SafeAuth.signIn({ username: email, password }).then(() => {
            setProcessing(false);
            history.push('/');
          })
        )
        .catch(err => {
          console.log(err.code, err.message);
          if (err.code === 'UserNotConfirmedException') {
            setProcessing(false);

            history.push(`/auth/confirm-signup?email=${encodeURIComponent(email)}`);

            return;
          }
          setProcessing(false);
          setErrorMessage(err.message);
        });
    },
    [confirmPassword, email, history, password, verificationToken]
  );

  const disabled = !password || !confirmPassword || processing;
  const labelStyle = 'text-gray-500 text-left pt-3';

  return (
    <AuthContainer logo={logo} isWhiteLabel={isWhiteLabel}>
      <form data-mf-replace="" onSubmit={onResetPassword} className="flex flex-col w-full space-y-4">
        {!inputParams?.email && (
          <label htmlFor="email" className={labelStyle}>
            Email
            <input
              type="text"
              ref={emailRef}
              className="bz-input"
              name="email"
              label="Email"
              value={email}
              onChange={({ target: { value } }) => setEmail(value)}
            />
          </label>
        )}
        {!inputParams?.code && (
          <label htmlFor="verification-code" className={labelStyle}>
            Verification Code
            <input
              type="text"
              ref={codeRef}
              name="verification-code"
              autoComplete="off"
              className="bz-input"
              label="Verification Token"
              value={verificationToken}
              onChange={({ target: { value } }) => setVerificationToken(value)}
            />
          </label>
        )}
        <div className="text-3xl text-center pb-5 mt-4">Welcome Back</div>
        <div className="mx-auto">{email}</div>
        <button
          type="button"
          onClick={() => history.push('/')}
          className="mx-auto text-sm text-blue-600 text-left underline cursor-pointer"
        >
          Not you?
        </button>
        <label htmlFor="new-password text-primary" className={labelStyle}>
          New Password
          <input
            type="password"
            ref={passwordRef}
            name="new-password"
            autoComplete="new-password"
            className={`${isWhiteLabel ? 'bz-input' : 'bz-input bg-pink-100 opacity-50 focus:ring-pink-500'}`}
            label="Password"
            value={password}
            onChange={({ target: { value } }) => setPassword(value)}
          />
        </label>
        <label htmlFor="new-password-confirm" className={`${labelStyle} pb-4`}>
          Confirm Password
          <input
            type="password"
            name="new-password-confirm"
            autoComplete="new-password"
            className={`${isWhiteLabel ? 'bz-input' : 'bz-input bg-pink-100 opacity-50 focus:ring-pink-500'}`}
            label="Confirm Password"
            value={confirmPassword}
            onChange={({ target: { value } }) => setConfirmPassword(value)}
          />
        </label>
        <button
          type="submit"
          disabled={disabled}
          className={`bz-btn-solid border-0 bg-pink-500 w-full px-4 ${isWhiteLabel && 'bg-secondary'} ${
            disabled && 'bg-gray-300 opacity-25 cursor-not-allowed'
          }`}
        >
          <div className="self-center flex-1 w-full px-0 mx-0">{processing ? 'Processing...' : 'Reset Password'}</div>
        </button>
        <div className="w-full -mt-4 h-auto max-h-20">
          {errorMessage && <h1 className="text-red-500 text-lg">{errorMessage}</h1>}
        </div>
      </form>

      <div className="w-full px-4 my-4 border-b-2" />
      <div className="flex items-center justify-center space-around w-full px-4 mb-4 space-x-1">
        <span className="mx-auto mt-6 text-sm cursor-pointer text-gray-600">
          Already have an account?
          <Link to="/" href="/" className="ml-2 mx-auto mt-6 text-sm underline cursor-pointer text-blue-600">
            Sign In
          </Link>
        </span>
      </div>
    </AuthContainer>
  );
}
