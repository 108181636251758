import Spotify from 'src/svgs/InventoryIcons/Spotify.svg';
import IheartRadio from 'src/svgs/InventoryIcons/IheartRadio.svg';
import SoundCloud from 'src/svgs/InventoryIcons/SoundCloud.svg';
import Pandora from 'src/svgs/InventoryIcons/Pandora.svg';
import Sirius from 'src/svgs/InventoryIcons/Sirius.svg';
import Podcasts from 'src/svgs/InventoryIcons/Podcasts.svg';

export const audioSegments = {
  Spotify: {
    id: '59937',
    icons: [Spotify],
  },
  'iHeart Radio': {
    id: '59938',
    icons: [IheartRadio],
  },

  Soundcloud: {
    id: '59930',
    icons: [SoundCloud],
  },
  Pandora: {
    id: '59931',
    icons: [Pandora],
  },
  'Sirius XM Streaming': {
    id: '59932',
    icons: [Sirius],
  },
  Podcasts: {
    id: '59933',
    icons: [Podcasts],
  },
};
