import { API } from 'aws-amplify';
import { trackingTagFields } from './trackingTagFields';

const getTrackingTagQuery = /* GraphQL */ `
  query GetTrackingTag($id: ID!) {
    getTrackingTag(id: $id) {
      ${trackingTagFields}
    }
  }
`;

export const getTrackingTag = async TrackingTagId =>
  API.graphql({
    query: getTrackingTagQuery,
    variables: {
      id: TrackingTagId,
    },
  })
    .then(({ data: { getTrackingTag: resp } = {} }) => resp || false)
    .catch(({ data: { getTrackingTag: resp } = {} }) => resp || false);
