const defaultColors = ['#1F2937', '#D1D5DB', '#374151', '#9CA3AF', '#4B5563', '#6B7280'];

export const GraphExamples = ({ className, colors }) => {
  const colorsArr = colors || defaultColors;
  const [done, dtwo, dthree, dfour, dfive, dsix] = defaultColors;
  const [one = done, two = dtwo, three = dthree, four = dfour, five = dfive, six = dsix] = colorsArr;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 294.1 252.1">
      <g data-name="Group 8627" transform="translate(-1768 -660.3)">
        <rect
          width="14.1"
          height="132.7"
          fill={one}
          data-name="Rectangle 1799"
          rx="1"
          transform="translate(1936 660.3)"
        />
        <rect
          width="14.1"
          height="109.3"
          fill={two}
          data-name="Rectangle 1802"
          rx="1"
          transform="translate(1958.3 683.7)"
        />
        <rect
          width="14.1"
          height="89.3"
          fill={three}
          data-name="Rectangle 1805"
          rx="1"
          transform="translate(1980.6 703.7)"
        />
        <rect
          width="14.1"
          height="69.2"
          fill={four}
          data-name="Rectangle 1808"
          rx="1"
          transform="translate(2002.8 723.8)"
        />
        <rect
          width="14.1"
          height="54.3"
          fill={five}
          data-name="Rectangle 1811"
          rx="1"
          transform="translate(2025.1 738.7)"
        />
        <rect
          width="14.1"
          height="38.7"
          fill={six}
          data-name="Rectangle 1814"
          rx="1"
          transform="translate(2047.4 754.3)"
        />
        <path
          fill={one}
          d="M2060.4 831.3s-76.3 63.5-119.6 66.9-62.3-60.2-99.1-56.9 6.5 70-48.3 70h267Z"
          data-name="Path 16567"
          opacity=".1"
        />
        <path
          fill={six}
          d="M2060.8 849s-13.3 36.9-47 43.4-47.5-43.3-87.2-38.5 4.3 57.5-71.3 57.5h205.5Z"
          data-name="Path 16569"
          opacity=".1"
        />
        <path
          fill="none"
          stroke={six}
          strokeWidth="2"
          d="M1769.6 911.4h85.7c75.6 0 32-53 71.6-57.8s53.3 45.3 87 38.8 46.9-43.4 46.9-43.4"
          data-name="Path 16571"
        />
        <path
          fill="none"
          stroke={one}
          strokeWidth="2"
          d="M1793.4 911.4c54.8 0 11.4-67.2 48.2-70.5s55.9 60.7 99.2 57.3 120.7-67 120.7-67"
          data-name="Path 16573"
        />
        <g data-name="Group 7162">
          <path fill={four} d="M1863 764.2a46.4 46.4 0 0 1-27.7 9V793a66 66 0 0 0 39.4-13Z" data-name="Path 16650" />
          <path
            fill={three}
            d="M1882 726.6a46.6 46.6 0 0 1-19 37.6l11.7 15.8a66.3 66.3 0 0 0 27-53.4c0-1.5 0-3-.2-4.6l-19.7 1.4.2 3.3Z"
            data-name="Path 16651"
          />
          <path
            fill={two}
            d="m1881.8 723.4 19.7-1.4a66.3 66.3 0 0 0-1.9-12q-.8-3.1-2-6.2t-2.4-6a66.3 66.3 0 0 0-4.8-8.2l-1.9-2.6a66.6 66.6 0 0 0-27.3-21.5q-3-1.2-6.1-2.2a66.3 66.3 0 0 0-19.8-3V680a46.7 46.7 0 0 1 46.5 43.4Z"
            data-name="Path 16652"
          />
          <path
            fill={five}
            d="M1809.2 787.7a66.7 66.7 0 0 0 4.1 1.5 66.2 66.2 0 0 0 22 3.8v-19.7a46.4 46.4 0 0 1-18.3-3.8Z"
            data-name="Path 16653"
          />
          <path
            fill={six}
            d="M1817 769.5a46.7 46.7 0 0 1-11-6.7l-12.5 15.3 1.6 1.3a66.3 66.3 0 0 0 14.1 8.3Z"
            data-name="Path 16654"
          />
          <path
            fill={one}
            d="M1769 726.6a66.1 66.1 0 0 0 19.4 47l2.7 2.4q1.1 1.1 2.4 2.1l12.4-15.3a46.6 46.6 0 0 1 29.4-82.8v-19.7a66.3 66.3 0 0 0-66.3 66.4Z"
            data-name="Path 16655"
          />
        </g>
      </g>
    </svg>
  );
};
