import { useCallback } from 'react';
import { ChevronLeft } from 'src/svgs';
import { useGAS } from 'src/GlobalAppState/context';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from 'src/components/shared/TableComponent/TableV2';
import { Radio } from 'src/components/shared/Radio';

export const AudienceTypeManaged = ({ campaignDraft, setCampaignDraft }) => {
  const { managedAudiences } = useGAS();

  const goBack = useCallback(
    () => setCampaignDraft({ ...campaignDraft, audienceType: false }),
    [campaignDraft, setCampaignDraft]
  );

  const setAudience = useCallback(
    audienceId => setCampaignDraft({ ...campaignDraft, audience: audienceId }),
    [campaignDraft, setCampaignDraft]
  );

  const isDisabled = campaignDraft.status === 'completed';

  const onClick = useCallback(
    e => {
      if (isDisabled) return;

      const audienceId = e.target.value;

      if (audienceId) {
        setAudience(audienceId);
      }
    },
    [isDisabled, setAudience]
  );

  return (
    <div className="w-full flex flex-col justify-center items-center mb-4">
      <div className="w-full pt-2 pb-4 px-3">
        <div className="flex items-center space-x-4">
          <button
            disabled={isDisabled}
            type="button"
            onClick={goBack}
            className={`flex space-x-4 p-1 ${isDisabled ? 'opacity-25 cursor-not-allowed' : ''}`}
          >
            <div className="w-6 h-6 p-1 rounded bg-primary flex justify-center items-center">
              <ChevronLeft className="stroke-current text-white h-full -ml-px" />
            </div>
            <h3 className="text-primary">Targeting section</h3>
          </button>
          <h3 className="text-primary">/</h3>
          <h3 className="text-primary">Target a managed audience</h3>
        </div>
      </div>
      <div className="w-full flex-1 flex flex-col justify-center items-center space-y-4">
        <Table className="w-full text-left">
          <TableHead>
            <TableRow>
              <TableHeadCell>Audience Name</TableHeadCell>
              <TableHeadCell>Description</TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {managedAudiences?.map(audience => (
              <TableRow key={audience.id}>
                <TableCell>
                  <label className="flex flex-row space-x-4 items-center cursor-pointer">
                    <Radio
                      name="managedAudience"
                      value={audience.id}
                      checked={campaignDraft.audience === audience.id}
                      onChange={onClick}
                    />
                    <span>{audience.name}</span>
                  </label>
                </TableCell>
                <TableCell>{audience.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <p className="text-left w-full text-sm">
          These audiences have been created for you and are managed by our team. If you have questions, please reach out
          to your representative.
        </p>
      </div>
    </div>
  );
};
