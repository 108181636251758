import React from 'react';

export const DropdownButton = ({ className, children, onClick, dataTestId, disabled }) => (
  <button
    type="button"
    onClick={onClick}
    disabled={disabled}
    className={`block w-full text-right px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:bg-gray-100 disabled:opacity-25 disabled:cursor-pointer ${className}`}
    role="menuitem"
    data-testid={dataTestId}
  >
    {children}
  </button>
);
