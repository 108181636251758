import { FaInfoCircle, FaQuestionCircle } from 'react-icons/fa';

const positions = {
  left: { top: '-0rem', left: '-17rem' },
  top: { bottom: '100%', left: 0 },
  bottom: { top: '2rem', left: '-7rem' },
  right: { top: '-0rem', left: '2rem' },
};

const variantIcons = {
  question: FaQuestionCircle,
  info: FaInfoCircle,
};

const getVariantIcon = variant => variantIcons[variant] ?? variantIcons.info;

export const InfoPopupV2 = ({ content, link, position = 'top', variant, Icon = getVariantIcon(variant) }) => (
  <div className="group relative inline-block">
    <button
      type="button"
      className="transform hover:scale-110 m-2 font-semibold text-xs inline-block align-middle text-gray-600"
      id="btn"
    >
      <Icon className="h-4 w-4 text-current" />
    </button>
    <div
      className="absolute z-10 rounded p-2 hidden group-hover:block flex-col text-sm bg-gray-700 rounded w-48 text-white"
      style={positions[position] || positions.top}
    >
      {content && <div>{content}</div>}
      {link && (
        <a className="text-primary hover:text-blue-800 visited:text-purple-600" href={link}>
          {link}
        </a>
      )}
    </div>
  </div>
);
