import { useState, useRef } from 'react';
import { ChevronDown } from 'src/svgs';
import { DropdownButton } from 'src/components/shared';
import { useClickAway } from 'react-use';

export const MainListBrandSort = ({ sort, setSort }) => {
  const [sortOpen, setSortOpen] = useState(false);
  const menuRef = useRef();

  useClickAway(menuRef, () => setSortOpen(false));

  const onSort = key => {
    setSort(key);
    setSortOpen(false);
  };

  return (
    <div ref={menuRef} className="flex flex-col items-end">
      <div className="flex space-x-2 items-center">
        <span className="font-medium text-sm text-gray-500">Sort By:</span>
        <button
          type="button"
          className="w-32 border hover:bg-white rounded flex items-center justify-end space-x-2 px-3 h-10"
          onClick={() => setSortOpen(!sortOpen)}
        >
          <span className="text-sm text-gray-500 whitespace-nowrap">{sort}</span>
          <ChevronDown className="text-sm text-gray-500 w-4 stroke-current" />
        </button>
      </div>
      <div className="z-20 h-0 overflow-visible">
        <div
          className={`origin-bottom-right ${
            !sortOpen && 'hidden'
          } mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
        >
          <div className="py-1">
            <DropdownButton onClick={() => onSort('Sort A-Z')}>Sort A-Z</DropdownButton>
            <DropdownButton onClick={() => onSort('Sort Z-A')}>Sort Z-A</DropdownButton>
            <DropdownButton onClick={() => onSort('Created ASC')}>Created ASC</DropdownButton>
            <DropdownButton onClick={() => onSort('Created DESC')}>Created DESC</DropdownButton>
            <DropdownButton onClick={() => onSort('Credits')}>Available Credits</DropdownButton>
            <DropdownButton onClick={() => onSort('Spend')}>Total Spend</DropdownButton>
          </div>
        </div>
      </div>
    </div>
  );
};
