import React from 'react';
import { CheckIcon } from 'src/svgs';
import { InventoryIcons } from './InventoryIcons';

export const InventoryRow = ({ name, icons }) => (
  <div className="h-16">
    <div className="w-full flex justify-between h-16 items-center space-x-2 text-left border-b border-gray-300">
      <div className="flex">
        <div className="pr-5">
          <div className="w-6 h-6 p-1">
            <CheckIcon className="stroke-current text-primary w-full" />
          </div>
        </div>
        <h3 className="text-lg">{name}, including:</h3>
      </div>
      <InventoryIcons name={name} icons={icons} />
    </div>
  </div>
);

export const CtvAudioRows = ({ name, icons }) => <InventoryIcons name={name} icons={icons} large />;
