import { useCallback, useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { FaPause, FaPlay, FaStop } from 'react-icons/fa';
import { useGAS } from 'src/GlobalAppState/context';
import { useIfMounted } from 'src/hooks/useIfMounted';
import { useModal } from 'src/contexts';
import { campaignService } from 'src/graphql/mutations';
import { CampaignAction } from 'src/models';
import { Spinner } from '../../shared/Spinner';

const MAX_LOADING_TIMEOUT = 30_000;

const getCampaignLoadingKey = campaign => campaign?.id && campaign?.status && `${campaign.id}-${campaign.status}`;

const useLoadingCache = campaign => {
  const [campaignLoadingKey, setCampaignLoadingKey] = useState(null);
  const campaignKey = getCampaignLoadingKey(campaign);

  const setLoading = useCallback(() => setCampaignLoadingKey(campaignKey), [campaignKey]);

  useEffect(() => {
    if (!campaignLoadingKey) return;

    // Campaign record has changed. Clear loading
    if (campaignKey && campaignLoadingKey !== campaignKey) {
      setCampaignLoadingKey(null);

      return;
    }

    // In case it hangs
    const timeout = setTimeout(() => setCampaignLoadingKey(null), MAX_LOADING_TIMEOUT);

    return () => clearTimeout(timeout);
  }, [campaignLoadingKey, campaignKey]);

  return [campaignKey && campaignLoadingKey === campaignKey, setLoading];
};

const useAction = (action, setLoading) => {
  const { campaign } = useGAS();

  return useCallback(() => {
    setLoading?.();
    API.graphql({
      query: campaignService,
      variables: {
        campaignId: campaign.id,
        action,
      },
    });
  }, [action, campaign.id, setLoading]);
};

const hoverColors = {
  green: 'hover:from-green-200 hover:to-green-300',
  yellow: 'hover:from-yellow-200 hover:to-yellow-300',
  red: 'hover:from-red-200 hover:to-red-300',
};

const MediaButton = ({ icon: Icon, hoverColor, disabled, onClick, label }) => {
  const buttonColorClasses = disabled
    ? 'bg-gray-100 cursor-default text-gray-400'
    : `bg-gradient-to-br from-gray-100 to-gray-200 ${hoverColor} text-black`;

  return (
    <button
      type="button"
      className={`rounded p-3 ${buttonColorClasses}`}
      disabled={disabled}
      aria-label={label}
      onClick={disabled ? null : onClick}
    >
      <Icon className="h-5 w-5 text-current opacity-90" />
    </button>
  );
};

const PlayButton = ({ disabled, setLoading }) => {
  const onClick = useAction(CampaignAction.RESUME, setLoading);

  return (
    <MediaButton
      icon={FaPlay}
      hoverColor={hoverColors.green}
      onClick={onClick}
      disabled={disabled}
      label="Resume campaign"
    />
  );
};

const PauseButton = ({ disabled, setLoading }) => {
  const onClick = useAction(CampaignAction.PAUSE, setLoading);

  return (
    <MediaButton
      icon={FaPause}
      hoverColor={hoverColors.yellow}
      onClick={onClick}
      disabled={disabled}
      label="Pause campaign"
    />
  );
};

const StopButton = ({ disabled, setLoading }) => {
  const { openModal } = useModal();

  const safeSetLoading = useIfMounted(setLoading);

  const onClick = useCallback(() => {
    openModal('campaign-action', { action: 'stop', callback: safeSetLoading });
  }, [openModal, safeSetLoading]);

  return (
    <MediaButton
      icon={FaStop}
      hoverColor={hoverColors.red}
      onClick={onClick}
      disabled={disabled}
      label="Stop campaign"
    />
  );
};

const getStatusColor = status => {
  switch (status) {
    case 'approved':
    case 'updating':
    case 'running':
      return 'bg-green-400';
    case 'stopped':
    case 'stopping':
    case 'error':
      return 'bg-red-400';
    case 'paused':
    case 'pausing':
    case 'archiving':
      return 'bg-yellow-400';
    default:
      return 'bg-gray-300';
  }
};

const disabledStates = [
  'draft',
  'completed',
  'stopped',
  'stopping',
  'archived',
  'archiving',
  'pausing',
  'updating',
  'draft',
];
const showPlayStates = ['paused', 'pausing', 'draft'];
const showPauseStates = ['running', 'updating', 'error', 'approved'];
const showStopStates = ['paused', 'pausing', 'running', 'updating', 'error', 'approved', 'draft'];

export const StatusCard = ({ campaign }) => {
  const [loading, setLoading] = useLoadingCache(campaign);
  const { status = 'draft' } = campaign;

  const statusColor = getStatusColor(status);

  const disabled = disabledStates.includes(status);
  const showPlay = showPlayStates.includes(status);
  const showPause = showPauseStates.includes(status);
  const showStop = showStopStates.includes(status);

  return (
    <div className="w-full h-20 rounded bg-white shadow border-gray-200 flex items-center justify-between p-4 relative">
      <div className="px-2 py-2 flex items-center space-x-4 flex-grow">
        {loading ? <Spinner variant="inline" /> : <div className={`w-3 h-3 rounded-full ${statusColor}`} />}
        <span className="text-gray-800 text-right font-medium capitalize">{status}</span>
      </div>
      <div className="flex-grow-0 flex flex-row space-x-4">
        {showPlay && <PlayButton setLoading={setLoading} disabled={disabled || loading} />}
        {showPause && <PauseButton setLoading={setLoading} disabled={disabled || loading} />}
        {showStop && <StopButton setLoading={setLoading} disabled={disabled || status === 'approved' || loading} />}
      </div>
    </div>
  );
};
