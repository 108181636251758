const selectAudienceType = campaignOrDraft => campaignOrDraft.audienceType;

const selectManagedAudience = campaignOrDraft => campaignOrDraft.audience;

const selectDMPSegments = campaignOrDraft => campaignOrDraft.dmpSegments || [];

const selectCampaignTags = campaignOrDraft => campaignOrDraft.retargetingTags || [];

const selectFirstPartyTags = (campaignOrDraft, brandParams) => {
  const brandTrackingTags = brandParams?.brandTrackingTags || [];
  const campaignTags = selectCampaignTags(campaignOrDraft);

  return campaignTags.filter(({ trackingTagId }) =>
    brandTrackingTags.find(({ id, trackingTagType }) => id === trackingTagId && trackingTagType === 'firstParty')
  );
};

export const selectAudienceValidForPublish = (campaignOrDraft, brandParams) => {
  const audienceType = selectAudienceType(campaignOrDraft);
  const audience = selectManagedAudience(campaignOrDraft);
  const dmpSegments = selectDMPSegments(campaignOrDraft);
  const firstPartyTags = selectFirstPartyTags(campaignOrDraft, brandParams);

  switch (audienceType) {
    case 'everyone':
    case 'retargeting':
      return true;
    case 'thirdParty':
      return dmpSegments.length > 0;
    case 'firstParty':
      return firstPartyTags.length > 0;
    case 'managed':
      return !!audience;
    default:
      return false;
  }
};

export const selectAudienceValidForSave = (campaignOrDraft, brandParams) => {
  const audienceType = selectAudienceType(campaignOrDraft);

  return !audienceType || selectAudienceValidForPublish(campaignOrDraft, brandParams);
};
