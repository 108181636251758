import { useInteractionLock } from './InteractionLock';

export const BuilderPanel = ({ children, title, 'data-testid': testid, icon: Icon }) => {
  const isInteractionLocked = useInteractionLock();

  const interactionLockClass = isInteractionLocked ? 'opacity-30 pointer-events-none' : '';

  return (
    <div
      className="md:w-192 lg:w-full flex flex-col rounded shadow bg-white p-4"
      data-testid={testid}
      id={`bp-${title.replaceAll(' ', '')}`}
    >
      <div className={`flex-1 flex justify-between space-x-2 pt-2 pb-4 items-center ${interactionLockClass}`}>
        <h1 className="text-xl font-semibold text-gray-800">{title}</h1>
        {Icon && <Icon className="w-8 h-8 stroke-current text-gray-400" />}
      </div>
      <div className={`relative w-full ${interactionLockClass}`}>
        <div className="w-full">{children}</div>
      </div>
    </div>
  );
};
