import { useModal } from 'src/contexts';
import { useState } from 'react';
import { API } from 'aws-amplify';
import { useGAS } from 'src/GlobalAppState/context';
import { updateUniversalPixel, addUniversalTrackingTag } from 'src/graphql/mutations';
import { updateBrand } from 'src/helpers/brand';
import { updateCampaign } from 'src/helpers/campaign/updateCampaign';
import { getTrackingTag, updateTrackingTag } from 'src/helpers/trackingTag';
import { PixelCodeBlock } from '../PixelCodeBlock';

const numOfAsterisks = url => url.replace(/[^*]/g, '').length;

const getMappingType = url => {
  if (numOfAsterisks(url) === 1 && url.length === 1) return 'All Traffic';
  if (numOfAsterisks(url) === 2 && url.startsWith('*') && url.endsWith('*')) return 'Contains';
  if (numOfAsterisks(url) === 1 && url.startsWith('*')) return 'Ends With';
  if (numOfAsterisks(url) === 1 && url.endsWith('*')) return 'Starts With';
  if (numOfAsterisks(url) >= 1) return 'Custom';

  return 'Exact';
};

const getDisplayUrl = url => {
  const type = getMappingType(url);

  if (type === 'Contains' || type === 'Ends With' || type === 'Starts With') {
    return url.replaceAll('*', '');
  }

  return url;
};

const getUniversalPixelPayload = (brand, trackingTagId, urlPattern, name) => {
  const { universalPixel } = brand;
  const trimmedName = name.substring(0, 60);

  const pixelUrl = urlPattern.trim();
  const pixelMapping = {
    UniversalPixelMappingName: trimmedName,
    UniversalPixelMappingType: 'Conversion',
    HouseholdExtensionEnabled: false,
  };

  const wildcardMappings = universalPixel?.UniversalPixelMappings?.WildcardMatchMappings || [];
  const exactMappings = universalPixel?.UniversalPixelMappings?.ExactMatchMappings || [];
  let updateRequired = false;

  if (!trackingTagId) {
    if (pixelUrl.includes('*')) {
      const wildcardMapping = wildcardMappings.find(({ UrlPattern }) => UrlPattern === pixelUrl);

      if (wildcardMapping) return false;

      pixelMapping.UrlPattern = pixelUrl;
      pixelMapping.UniversalPixelMappingName = trimmedName;
      universalPixel.UniversalPixelMappings.WildcardMatchMappings.push(pixelMapping);
    } else {
      const exactMapping = exactMappings.find(({ Url }) => Url === pixelUrl);

      if (exactMapping) return false;

      pixelMapping.Url = pixelUrl;
      pixelMapping.UniversalPixelMappingName = trimmedName;
      universalPixel.UniversalPixelMappings.ExactMatchMappings.push(pixelMapping);
    }

    return universalPixel;
  }

  if (pixelUrl.includes('*')) {
    const wildcardMapping = wildcardMappings.find(({ TrackingTagId }) => TrackingTagId === trackingTagId);

    if (wildcardMapping?.UniversalPixelMappingName !== trimmedName) {
      wildcardMapping.UniversalPixelMappingName = trimmedName;
      updateRequired = true;
    }

    if (wildcardMapping?.UrlPattern !== urlPattern) {
      wildcardMapping.UrlPattern = pixelUrl;
      updateRequired = true;
    }
  } else {
    const exactMapping = exactMappings.find(({ TrackingTagId }) => TrackingTagId === trackingTagId);

    if (exactMapping?.UniversalPixelMappingName !== trimmedName) {
      exactMapping.UniversalPixelMappingName = trimmedName;
      updateRequired = true;
    }

    if (exactMapping?.Url !== urlPattern) {
      exactMapping.Url = pixelUrl;
      updateRequired = true;
    }
  }

  if (!updateRequired) return false;

  return universalPixel;
};

const getDeleteUniversalPixelPayload = (brand, pixelUrl, trackingTagId) => {
  const { universalPixel } = brand;
  const wildcardMappings = universalPixel?.UniversalPixelMappings?.WildcardMatchMappings || [];
  const exactMappings = universalPixel?.UniversalPixelMappings?.ExactMatchMappings || [];

  if (pixelUrl.includes('*')) {
    const newWildcardMappings = wildcardMappings.filter(w => w.TrackingTagId !== trackingTagId);

    universalPixel.UniversalPixelMappings.WildcardMatchMappings = newWildcardMappings;
  } else {
    const newExactMappings = exactMappings.filter(e => e.TrackingTagId !== trackingTagId);

    universalPixel.UniversalPixelMappings.ExactMatchMappings = newExactMappings;
  }

  return universalPixel;
};

const removeCampaignConversionEvent = async (campaigns, trackingTagId) => {
  const conversionCampaigns = campaigns.filter(c => c?.conversionEvents);

  if (conversionCampaigns.length) {
    for (const campaign of conversionCampaigns) {
      const hasTrackingTag = campaign.conversionEvents.find(e => e.id === trackingTagId);

      if (hasTrackingTag) {
        const updatedConversionEvents = campaign.conversionEvents.filter(c => c.id !== trackingTagId);

        await updateCampaign({
          id: campaign.id,
          conversionEvents: updatedConversionEvents,
        });
      }
    }
  }
};

const getUrlPattern = (type, trackingUrl) => {
  if (type === 'Contains') return `*${trackingUrl}*`;
  if (type === 'Ends With') return `*${trackingUrl}`;
  if (type === 'Starts With') return `${trackingUrl}*`;

  return trackingUrl;
};

const updateUniversalPixelFn = async (brandId, universalPixel) => {
  const input = { id: brandId, universalPixel };

  await updateBrand(input).then(() => {
    API.graphql({
      query: updateUniversalPixel,
      variables: { input: { brandId } },
    });
  });
};

export const UniversalPixelPanel = ({ brand }) => {
  const { openModal } = useModal();
  const { id: brandId, teamId, organizationId, universalPixel = {} } = brand;
  const [deleted, setDeleted] = useState([]);
  const { ExactMatchMappings = [], WildcardMatchMappings = [] } = universalPixel?.UniversalPixelMappings || {};
  const rawMappings = [...ExactMatchMappings, ...WildcardMatchMappings];
  const { brandCampaigns: campaigns } = useGAS();

  const onSaveClick = async (trackingTagId, url, name, type, onClose) => {
    const urlPattern = getUrlPattern(type, url);
    const pixelMappings = getUniversalPixelPayload(brand, trackingTagId, urlPattern, name);

    if (pixelMappings) {
      await updateUniversalPixelFn(brandId, pixelMappings).then(onClose);
    } else {
      onClose();
    }
  };

  const onDeleteClick = async pixelMap => {
    const { Type, Url, TrackingTagId, UniversalPixelMappingName } = pixelMap;

    if (TrackingTagId) {
      const urlPattern = getUrlPattern(Type, Url);
      const pixelMappings = getDeleteUniversalPixelPayload(brand, urlPattern, TrackingTagId);

      await updateUniversalPixelFn(brandId, pixelMappings);
      await removeCampaignConversionEvent(campaigns, TrackingTagId);
      setDeleted([...deleted, TrackingTagId]);

      const trackingTag = await getTrackingTag(TrackingTagId);

      if (trackingTag) {
        trackingTag.archived = true;
        trackingTag.trackingTagType = 'universal';
        await updateTrackingTag(trackingTag);
      } else {
        const input = {
          organizationId,
          teamId,
          brandId,
          name: UniversalPixelMappingName,
          trackingTagType: 'universal',
          pixelUrl: Url,
          ttdId: TrackingTagId,
          archived: true,
        };

        await API.graphql({
          query: addUniversalTrackingTag,
          variables: { input },
        });
      }
    }
  };

  const exactMapping = ExactMatchMappings.map(({ TrackingTagId, Url, HitCount30Day, UniversalPixelMappingName }) => ({
    TrackingTagId,
    UniversalPixelMappingName,
    Url: getDisplayUrl(Url),
    HitCount30Day,
    Type: getMappingType(Url),
  }));

  const wildcardMapping = WildcardMatchMappings.map(
    ({ TrackingTagId, UrlPattern, HitCount30Day, UniversalPixelMappingName }) => ({
      TrackingTagId,
      UniversalPixelMappingName,
      Url: getDisplayUrl(UrlPattern),
      HitCount30Day,
      Type: getMappingType(UrlPattern),
    })
  );

  // hack for weird state behavior
  const mapppingsSet = new Set([...exactMapping, ...wildcardMapping]);
  const mappings = Array.from(mapppingsSet).filter(m => !deleted.includes(m.TrackingTagId));

  mappings.sort((a, b) => ((a.HitCount30Day || 0) < (b.HitCount30Day || 0) ? 1 : -1));

  const rows = (mappings || []).map(map => (
    <MappingRow
      key={map.TrackingTagId || map.Url}
      map={map}
      mappings={rawMappings}
      openModal={openModal}
      brand={brand}
      onSaveClick={onSaveClick}
      onDeleteClick={onDeleteClick}
    />
  ));

  return (
    <div
      className="w-full h-full flex flex-col space-y-4 overflow-auto"
      style={{ maxHeight: 'calc(100vh - 100px)', minWidth: '1200px' }}
    >
      <div className="grid grid-flow-col gap-3">
        <div>
          <h1 className="text-xl font-semibold">Universal Pixel</h1>
          <div className="pr-10 max-w-3xl">
            Once placed, any page on your site may be used for Retargeting or Conversions. Ensure the Audience Tracking
            toggle is enabled in order to monitor visits to your site.
          </div>
        </div>
        <PixelCodeBlock brand={brand} />
      </div>

      <h1 className="font-semibold text-lg mb-2">Mappings</h1>
      <div className="grid grid-flow-col gap-3 pb-3">
        <p className="w-3/4">
          Enhance your campaign tracking by configuring your universal pixel mappings below for more detailed insights..
          Learn how to use universal pixel mappings
        </p>
        <button
          type="button"
          className="w-56 rounded-r h-10 border-2 border-primary mr-2 bg-primary text-white hover:opacity-80"
          onClick={() => openModal('add-pixel-mapping', { isNew: true, onSaveClick, brand, mappings: rawMappings })}
        >
          + Create a Mapping
        </button>
      </div>
      <table className="table-fixed w-full">
        <thead>
          <tr className="text-lg font-medium border-b w-full">
            <td className="border-b w-96">Name</td>
            <td className="border-b w-28">Type</td>
            <td className="border-b w-1/3">Url</td>
            <td className="border-b w-28">
              <div className="flex flex-col items-center">
                <span>Hit Count</span>
                <span className="text-xs">(30 days)</span>
              </div>
            </td>
            <td className="border-b float-right mr-8">Actions</td>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );
};

const MappingRow = ({ map, mappings, openModal, onSaveClick, onDeleteClick }) => (
  <tr key={map.TrackingTagId || map.Url} className="w-full border-b">
    <td className="p-3">{map.UniversalPixelMappingName}</td>
    <td>{map.Type || 'Custom'}</td>
    <td>{map.Url}</td>
    <td>
      <div className="flex items-center justify-center">
        <div>{map.HitCount30Day || 0}</div>
      </div>
    </td>
    {map.TrackingTagId ? (
      <td>
        {map.Type === 'All Traffic' ? (
          <div />
        ) : (
          <div className="grid grid-flow-col w-36 float-right">
            <button
              className="underline"
              type="button"
              onClick={() => openModal('add-pixel-mapping', { isNew: false, mapping: map, onSaveClick, mappings })}
            >
              Edit
            </button>
            <button
              className="text-red-500 underline"
              type="button"
              onClick={() => openModal('delete-pixel-mapping', { mapping: map, onDeleteClick })}
            >
              Delete
            </button>
          </div>
        )}
      </td>
    ) : (
      <td>
        <div className="float-right pr-5">Processing</div>
      </td>
    )}
  </tr>
);
