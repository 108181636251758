import { useMemo } from 'react';

const getDefaultSegmentPackages = () => ['default-dmp'];

export const useSegmentPackages = (org, team, brand) =>
  useMemo(() => {
    const orgSegmentPackages = org?.segmentPackages || [];
    const teamSegmentPackages = team?.segmentPackages || [];
    const brandSegmentPackages = brand?.segmentPackages || [];

    return [...getDefaultSegmentPackages(), ...orgSegmentPackages, ...teamSegmentPackages, ...brandSegmentPackages];
  }, [brand?.segmentPackages, org?.segmentPackages, team?.segmentPackages]);
