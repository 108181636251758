import ErrorBoundary from 'src/ErrorBoundary';
import { SidebarV1 } from 'src/components/Layout/SidebarV1';
import { NavbarV1 } from 'src/components/Layout/NavbarV1/NavbarV1';

export default function AppLayout({ children }) {
  return (
    <ErrorBoundary>
      <div className="flex flex-1">
        <SidebarV1 />
        <div className="flex flex-col shrink flex-auto min-h-0 min-w-0 max-h-screen" data-testid="main">
          <NavbarV1 />
          <main className="min-w-0 flex flex-col flex-1 overflow-scroll">{children}</main>
        </div>
      </div>
    </ErrorBoundary>
  );
}
