import { PiWrenchLight } from 'react-icons/pi';
import { BuilderPanel } from '../BuilderPanel';
import { BasicsEdit } from './BasicsEdit';

export const CampaignBasics = ({ campaignDraft, creatives, appParams, setCampaignDraft }) => (
  <BuilderPanel icon={PiWrenchLight} title="Basics">
    <BasicsEdit
      campaignDraft={campaignDraft}
      creatives={creatives}
      setCampaignDraft={setCampaignDraft}
      appParams={appParams}
    />
  </BuilderPanel>
);
