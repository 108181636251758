import { noop } from 'lodash';
import { TileList } from 'src/svgs/TileList';
import { RENAMED_GOALS } from 'src/constants/goals';

export const GoalSelector = ({ goal, onSelect, status }) => (
  <div className="flex w-full space-x-4">
    <GoalSelectorButton
      index={0}
      active={goal === 'impressions'}
      onSelect={() => onSelect('impressions')}
      title={RENAMED_GOALS.impressions}
      description="Find leads and boost brand visibility"
      data-testid="goalImpresions"
      status={status}
    />
    <GoalSelectorButton
      index={1}
      active={goal === 'views'}
      onSelect={() => onSelect('views')}
      title={RENAMED_GOALS.views}
      description="Influence new and potential customers"
      data-testid="goalViews"
      status={status}
    />
    <GoalSelectorButton
      index={2}
      active={goal === 'clicks'}
      onSelect={() => onSelect('clicks')}
      title={RENAMED_GOALS.clicks}
      description="Drive customers to a desired action"
      data-testid="goalClicks"
      status={status}
    />
  </div>
);

const baseStyles = 'group flex-1 min-h-40 flex flex-col space-y-1 text-center rounded-lg p-4';

const GoalSelectorButton = ({ active, index, title, onSelect, status, description, 'data-testid': testid }) => {
  const isDisabled = status === 'completed';

  const disabledStyles = isDisabled ? 'opacity-25 cursor-not-allowed' : '';
  const activeStyles = active
    ? 'text-white bg-gray-800'
    : 'text-gray-500 bg-white border-2 border-gray-200 hover:bg-gray-100';

  return (
    <button
      type="button"
      onClick={isDisabled ? noop : onSelect}
      className={`${baseStyles} ${disabledStyles} ${activeStyles}`}
      data-testid={testid}
    >
      <TileList step={index} active={active} className="w-12 h-12 mx-auto" />
      <h1 className="text-lg font-medium text-current text-center mx-auto">{title}</h1>
      <p className="text-current max-w-xs block mx-auto">{description}</p>
    </button>
  );
};
