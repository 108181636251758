import { useEffect } from 'react';

export const emit = (eventName, detail) => {
  if (typeof window?.dispatchEvent === 'undefined') return;

  const event = new CustomEvent(eventName, { detail });

  window.dispatchEvent(event);
};

export const useEventCallback = (eventName, callback) =>
  useEffect(() => {
    if (typeof window?.addEventListener === 'undefined') return;

    window.addEventListener(eventName, callback);

    return () => window.removeEventListener(eventName, callback);
  }, [eventName, callback]);
