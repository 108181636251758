import React, { useState, useRef } from 'react';
import { useSpring, animated, config } from 'react-spring';
import { industryCategories } from 'src/constants';
import { useClickAway, useKey } from 'react-use';

export const IndustryCategoryDropdownNew = ({ value, onSelect }) => {
  const [showEdit, setShowEdit] = useState(false);
  const style = useSpring({ height: showEdit ? 208 : 0, opacity: showEdit ? 1 : 0, config: config.stiff });
  const menuRef = useRef(null);

  useKey('Escape', () => setShowEdit(false));
  useClickAway(menuRef, () => setShowEdit(false));

  const onOptionSelect = cat => {
    const int = parseInt(cat.value);

    onSelect(int);
    setShowEdit(false);
  };

  const options = industryCategories.map(cat => (
    <button
      type="button"
      key={`cat-${cat.value}`}
      onClick={() => onOptionSelect(cat)}
      className="drop-option text-left truncate flex w-full items-center px-4 h-12 hover:bg-blue-100 focus:outline-none focus:bg-blue-100"
    >
      {cat.text}
    </button>
  ));

  const selected = industryCategories.find(cat => cat.value === value);
  const selectedText = selected ? selected.text : 'select an industry';

  return (
    <div className="w-full flex items-center space-x-4 h-14">
      <label htmlFor="industry" className="w-full">
        <p className="pb-1 font-medium text-gray-600">Industry Category</p>
        <div ref={menuRef} className="relative w-full">
          <button type="button" onClick={() => setShowEdit(!showEdit)} className="bz-input py-2 text-left truncate">
            {selectedText}
          </button>
          <animated.div style={style} className="absolute h-52 mt-1 overflow-scroll w-full rounded bg-white shadow-lg">
            {showEdit && options}
          </animated.div>
        </div>
      </label>
    </div>
  );
};
