import BigNumber from 'bignumber.js';
import { nFormatter } from './numberHelpers';

const cpms = {
  ctv: [39.38, 57.88],
  webVideo: [19.95, 29.33],
  display: [4.78, 7.02],
  displayWeb: [4.78, 7.02],
  displayApp: [4.78, 7.02],
  audio: [13.14, 19.31],
};

export const getForecastCalculations = (results, marginPct, budget) => {
  let adgroups = {
    ctv: { min: 0, max: 0 },
    display: { min: 0, max: 0 },
    audio: { min: 0, max: 0 },
    webVideo: { min: 0, max: 0 },
  };

  if (results && results.length > 0) {
    adgroups = results.reduce((obj, itm) => {
      if (itm.adgroupType) {
        const type = itm.adgroupType;
        const cpm = getCpm(type, marginPct);

        return { ...obj, [type]: { ...itm, cpm } };
      }

      return null;
    }, null);

    if (adgroups) {
      const cpmMin = Math.min(...Object.values(adgroups).map(item => item.cpm.min));
      const cpmMax = Math.max(...Object.values(adgroups).map(item => item.cpm.max));
      let reachMin = Math.min(...Object.values(adgroups).map(item => item.reach.min));
      let reachMax = Math.max(...Object.values(adgroups).map(item => item.reach.max));
      let impMin = Math.min(...Object.values(adgroups).map(item => item.impressions.min));
      let impMax = Math.max(...Object.values(adgroups).map(item => item.impressions.max));

      // More impressions if cpm is less
      const cpmImpMax = Math.round((budget / cpmMin) * 1000);
      // Less impressions if cpm is more
      const cpmImpMin = Math.round((budget / cpmMax) * 1000);

      let satMax = Math.round((impMax / reachMin) * 1000) / 100;
      let satMin = Math.round((impMax / reachMax) * 1000) / 100;

      if (satMax > 100) satMax = 100;
      if (satMin > 95) satMin = 95;

      // Handle scientific notatons for large numbers.
      impMin = cpmImpMin.toLocaleString('fullwide', { useGrouping: false });
      impMax = cpmImpMax.toLocaleString('fullwide', { useGrouping: false });
      reachMin = reachMin.toLocaleString('fullwide', { useGrouping: false });
      reachMax = reachMax.toLocaleString('fullwide', { useGrouping: false });

      const formattedReach = nFormatter(new BigNumber(reachMin).toFixed(0)) || 'n/a';

      return {
        formattedReach,
        impMin,
        impMax,
        satMax,
        satMin,
        adgroups,
        reachMin,
        reachMax,
      };
    }
  }

  return {
    formattedReach: 0,
    impMin: 0,
    impMax: 0,
    satMax: 0,
    satMin: 0,
    adgroups: 0,
    reachMin: 0,
    reachMax: 0,
  };
};

export const getCpm = (type, marginPct = 0) => {
  const [min, max] = cpms[type];

  return getCpmRange(min, max, marginPct);
};

const getCpmRange = (min, max, margin) => {
  const cpmMin = Number((margin * min + min).toFixed(2));
  const cpmMax = Number((margin * max + max).toFixed(2));

  return { min: cpmMin, max: cpmMax };
};
