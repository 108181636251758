const baseClassName =
  "before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded border border-inherit text-primary transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-primary checked:before:bg-primary hover:before:opacity-10";

export const Check = ({ name, checked, defaultChecked, onChange, value, className, disabled }) => (
  <input
    type="checkbox"
    name={name}
    checked={checked}
    disabled={disabled}
    defaultChecked={defaultChecked}
    onChange={onChange}
    value={value}
    className={`${baseClassName} ${className}`}
  />
);
