import { round } from 'lodash';
import { useMemo } from 'react';

export const useAvailableBrandBudget = (appParams = null, campaigns = null) =>
  useMemo(() => {
    const noCreditRecords = !appParams?.brandCreditRecords?.length;
    const noCredits = !appParams.brandCredits;

    if (noCreditRecords || noCredits) return 0;

    const approvedCampaigns = (campaigns || []).filter(c => c.status === 'approved');

    if (approvedCampaigns.length < 1) return round(appParams.brandCredits, 2);

    const unaccountedApprovedCampaignsBudget = approvedCampaigns.reduce((sum, campaign) => {
      const matchingCreditRecords = appParams.brandCreditRecords.filter(record => record.campaignId === campaign.id);

      const creditsToClaim =
        campaign.budget +
        matchingCreditRecords.reduce((existingSpend, creditRecord) => existingSpend + creditRecord.amount, 0);

      return sum + creditsToClaim;
    }, 0);

    return round(appParams.brandCredits - unaccountedApprovedCampaignsBudget, 2);
  }, [appParams.brandCredits, appParams.brandCreditRecords, campaigns]);
