import { shortNumber } from 'src/helpers';
import { ColumnCell, ColumnHeader } from './Components';

export const ImpressionsColumn = {
  key: 'impressions',
  header: () => <ColumnHeader className="w-44">Impressions</ColumnHeader>,
  cell: ({ metrics }) => {
    const { impressions } = metrics;

    return (
      <ColumnCell>
        {Number.isFinite(impressions) ? shortNumber(impressions, 1) : <span className="text-lg text-gray-600">--</span>}
      </ColumnCell>
    );
  },
};
