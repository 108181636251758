import React, { useEffect, useState } from 'react';
import { shortNumber } from 'src/helpers';
import { ChevronLeft } from 'src/svgs';

export const AudienceTypeRetargeting = ({ campaignDraft, appParams, setCampaignDraft, setAppRoute }) => {
  const { brand = {}, brandTrackingTags: trackingTags } = appParams || {};

  const retargetingTags = campaignDraft?.retargetingTags || [];
  const setRetargetingTags = tags => setCampaignDraft({ ...campaignDraft, retargetingTags: tags || [] });

  const tagTotal = (trackingTags || []).reduce((acc, itm) => acc + itm.pixelAudienceSize || 0, 0);
  const uniTotal = Object.values(brand?.pixelMappingSizes || {}).reduce((acc, itm) => acc + itm, 0);
  const totalAudienceSize = tagTotal + uniTotal + (brand?.pixelAudienceSize || 0);
  const [isDisabled, setDisabled] = useState(false);

  useEffect(() => {
    if (campaignDraft.status === 'completed') {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [campaignDraft.status]);

  const setAudienceType = aType => setCampaignDraft({ ...campaignDraft, audienceType: aType });
  const onSettingsClick = () => setAppRoute({ model: 'brand', id: brand.id, page: 'settings' });
  const goBack = () => setAudienceType(false);

  return (
    <div className="flex flex-col justify-center items-center w-full mb-16">
      <div className="w-full pt-5 sm:pt-2 px-3 -ml-1">
        <div className="flex items-center space-x-4">
          <button
            disabled={isDisabled}
            type="button"
            onClick={goBack}
            className={`flex space-x-4 ${isDisabled ? 'opacity-25 cursor-not-allowed' : ''}`}
          >
            <div className="w-6 h-6 p-1 rounded bg-primary flex justify-center items-center">
              <ChevronLeft className="stroke-current text-white h-full -ml-px" />
            </div>
            <h3 className="text-primary">Targeting section</h3>
          </button>
          <h3 className="text-primary">/</h3>
          <h3 className="text-primary">Use a retargeting audience</h3>
        </div>
      </div>
      <div className="w-full h-full flex-1 flex flex-col items-center">
        {!totalAudienceSize ? (
          <NoPixel onSettingsClick={onSettingsClick} goBack={goBack} />
        ) : (
          <HasPixel
            goBack={goBack}
            brand={brand}
            trackingTags={trackingTags}
            retargetingTags={retargetingTags}
            setRetargetingTags={setRetargetingTags}
          />
        )}
      </div>
    </div>
  );
};

const NoPixel = ({ onSettingsClick, goBack }) => (
  <div className="w-full max-w-xl flex flex-col justify-center items-center space-y-4">
    <h3 className="text-gray-900 text-lg font-semibold text-center">You don't have a pixel set up</h3>
    <h3 className="text-gray-900 text-center text-sm pb-5">
      You need to set up a pixel on your website. You can do this through the brand settings, or you can go back and
      pick a different selection.
    </h3>
    <div className="w-full flex space-x-4">
      <button
        type="button"
        onClick={onSettingsClick}
        className="w-full max-w-md bg-primary rounded py-3 text-white font-semibold"
      >
        View brand settings
      </button>
      <button
        type="button"
        onClick={goBack}
        className="w-full max-w-md border-2 border-primary rounded py-3 text-primary font-semibold"
      >
        Go back
      </button>
    </div>
  </div>
);

const HasPixel = ({ brand, trackingTags, retargetingTags, setRetargetingTags }) => {
  const [selection, setSelection] = useState('universal');
  const activeTags = (retargetingTags || []).filter(t => t.trackingTagId);
  const uniTags = (retargetingTags || []).filter(t => t.dspId);
  let hitCountDays = '30 days';
  let tagRows = [];

  if (selection === 'tracking') {
    hitCountDays = '7 days';
    tagRows = (trackingTags || [])
      .filter(tag => !['custom', 'firstParty'].includes(tag.trackingTagType))
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .map(tag => (
        <TrackingTagRow
          key={tag.id}
          tagType="tracking"
          tag={tag}
          retargetingTags={retargetingTags}
          setRetargetingTags={setRetargetingTags}
        />
      ));
  } else {
    const exact = brand?.universalPixel?.UniversalPixelMappings?.ExactMatchMappings || [];
    const wild = brand?.universalPixel?.UniversalPixelMappings?.WildcardMatchMappings || [];

    const universalMappings = [...exact, ...wild];

    tagRows = (universalMappings || [])
      .map(ttdTag => {
        const ttdId = ttdTag?.TrackingTagId;
        const ttdPixelAudienceSize = ttdTag.HitCount30Day || 0;
        const firstPartyDataId = ttdTag?.FirstPartyDataId || null;
        let pixelName = ttdTag?.Url ? ttdTag.Url : ttdTag?.UrlPattern || null;

        if (pixelName === '*') {
          pixelName = `${brand.websiteUrl}/${pixelName}`;
        }

        return { id: ttdId, name: pixelName, pixelAudienceSize: ttdPixelAudienceSize, firstPartyDataId };
      })
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .map(tag => (
        <TrackingTagRow
          key={tag.id}
          tagType="universal"
          tag={tag}
          retargetingTags={retargetingTags}
          setRetargetingTags={setRetargetingTags}
        />
      ));
  }

  return (
    <div className="w-full flex-1 flex flex-col items-center p-4 pt-8">
      <div className="w-full flex justify-center items-center flex-row h-10 rounded space-x-4">
        <button
          type="button"
          onClick={() => setSelection('universal')}
          className={`w-5/12 rounded-l h-full border-2 border-primary mr-2 ${
            selection === 'universal'
              ? 'bg-primary text-white hover:opacity-80'
              : 'bg-white text-primary hover:bg-blueGray-100'
          }`}
        >
          <span>Universal Pixel ({uniTags.length || 0})</span>
        </button>
        <button
          type="button"
          onClick={() => setSelection('tracking')}
          className={`w-5/12 rounded-r h-full border-2 border-primary mr-2 ${
            selection === 'tracking'
              ? 'bg-primary text-white hover:opacity-80'
              : 'bg-white text-primary hover:bg-blueGray-100'
          }`}
        >
          <span>Tracking Tags ({activeTags.length || 0})</span>
        </button>
      </div>
      <div className="flex w-full justify-between mt-4 pl-2">
        <div className="text-gray-900 font-semibold">We're retargeting users that have been to your site</div>
        <div>Hit Count ({hitCountDays})</div>
      </div>
      <div className="w-full max-h-60 overflow-scroll pt-4 pb-8">
        <div className="flex-1 w-full flex items-center">
          <div className="w-full flex flex-col rounded-lg shadow-inner border">
            <div>{tagRows}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TrackingTagRow = ({ tag, tagType, retargetingTags, setRetargetingTags }) => {
  const active = (retargetingTags || []).map(t => t.trackingTagId || t.dspId).includes(tag.id);
  const onSelect = () =>
    active
      ? setRetargetingTags((retargetingTags || []).filter(t => (t.trackingTagId || t.dspId) !== tag.id))
      : setRetargetingTags([
          ...retargetingTags,
          tagType === 'universal'
            ? { dspId: tag.id, firstPartyDataId: tag.firstPartyDataId }
            : { trackingTagId: tag.id },
        ]);

  return (
    <button
      type="button"
      key={tag.id}
      onClick={onSelect}
      className={`w-full flex items-center h-16 border-b p-4 first:rounded-t-lg last:rounded-b-lg ${
        active ? 'bg-blue-100 bg-opacity-25' : ' hover:bg-blueGray-50'
      }`}
    >
      <div className="w-12">
        <div
          className={`w-6 h-6 rounded-full border ${
            active ? 'bg-primary border-primary' : 'bg-gray-100 border-gray-300'
          }`}
        />
      </div>
      <div className="flex-1 text-left">
        <h3 className="truncate text-xl font-medium">{tag.name}</h3>
      </div>
      <div className="w-12">
        <h3 className="text-xl text-center">{shortNumber(tag.pixelAudienceSize, 0, true)}</h3>
      </div>
    </button>
  );
};
