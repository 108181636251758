import { createAPIQueryHook, createAPIQuerySubscriptionHook } from '../../hooks/useAPIQuery';
import { brandFields } from './brandFields';

const getBrandQuery = /* GraphQL */ `
  query GetBrand($id: ID!) {
    result: getBrand(id: $id) {
      ${brandFields}
    }
  }
`;

const useBrandQuery = createAPIQueryHook({
  query: getBrandQuery,
});

const onCreateBrand = /* GraphQL */ `
  subscription OnCreateBrand($id: String, $teamId: String, $organizationId: String) {
    create: onCreateBrand(teamId: $teamId, organizationId: $organizationId) {
      id
      teamId
      organizationId
    }
  }
`;

const onUpdateBrand = /* GraphQL */ `
  subscription OnUpdateBrand($id: String, $teamId: String, $organizationId: String) {
    update: onUpdateBrand(id: $id, teamId: $teamId, organizationId: $organizationId) {
      id
      teamId
      organizationId
    }
  }
`;

const useBrandUpdateSubscription = createAPIQuerySubscriptionHook({
  subscription: onUpdateBrand,
  query: getBrandQuery,
});

const useBrandCreateSubscription = createAPIQuerySubscriptionHook({
  subscription: onCreateBrand,
  query: getBrandQuery,
});

const useBrandSubscription = (...args) => {
  useBrandUpdateSubscription(...args);
  useBrandCreateSubscription(...args);
};

export const useBrand = ({ organizationId, teamId, brandId }) => {
  const disable = !organizationId || !teamId || !brandId;
  const { data: brand, subscriptionHandler } = useBrandQuery({ id: brandId }, { disable });

  useBrandSubscription({ id: brandId, teamId, organizationId }, { subscriptionHandler, disable });

  return brand ?? {};
};
