import Bloomberg from 'src/svgs/InventoryIcons/Bloomberg.svg';
import CBS from 'src/svgs/InventoryIcons/Cbs.svg';
import CNN from 'src/svgs/InventoryIcons/CNN.svg';
import ESPN from 'src/svgs/InventoryIcons/ESPN.svg';
import FoodNetwork from 'src/svgs/InventoryIcons/FoodNetwork.svg';
import FoxNews from 'src/svgs/InventoryIcons/FoxNews.svg';
import HGTV from 'src/svgs/InventoryIcons/HGTV.svg';
import MotorTrend from 'src/svgs/InventoryIcons/MotorTrend.svg';
import MSNBC from 'src/svgs/InventoryIcons/MSNBC.svg';
import MTV from 'src/svgs/InventoryIcons/MTV.svg';
import E from 'src/svgs/InventoryIcons/E.svg';
import Bravo from 'src/svgs/InventoryIcons/Bravo.svg';
import CNBC from 'src/svgs/InventoryIcons/CNBC.svg';
import FoxBusiness from 'src/svgs/InventoryIcons/FoxBusiness.svg';
import CookingChannel from 'src/svgs/InventoryIcons/CookingChannel.svg';
import ScienceChannel from 'src/svgs/InventoryIcons/ScienceChannel.svg';
import History from 'src/svgs/InventoryIcons/History.svg';
import FoxSports from 'src/svgs/InventoryIcons/FoxSports.svg';
import DiscoveryChannel from 'src/svgs/InventoryIcons/DiscoveryChannel.svg';
import OWN from 'src/svgs/InventoryIcons/OWN.svg';
import TLC from 'src/svgs/InventoryIcons/TLC.svg';
import AE from 'src/svgs/InventoryIcons/AE.svg';
import Magnolia from 'src/svgs/InventoryIcons/Magnolia.svg';
import DIY from 'src/svgs/InventoryIcons/DIY.svg';
import PowerNation from 'src/svgs/InventoryIcons/PowerNation.svg';
import TasteMade from 'src/svgs/InventoryIcons/TasteMade.svg';
import Turbo from 'src/svgs/InventoryIcons/Turbo.svg';

export const ctvSegments = [
  {
    name: 'Arts & Entertainment',
    id: 'entertainment',
    icons: [E, Bravo, MTV],
  },
  {
    name: 'Autos & Vehicles',
    id: 'automotive',
    icons: [Turbo, MotorTrend, PowerNation],
  },
  {
    name: 'Business & Finance',
    id: 'business',
    icons: [CNBC, FoxBusiness, Bloomberg],
  },
  {
    name: 'Food & Drink',
    id: 'food',
    icons: [FoodNetwork, TasteMade, CookingChannel],
  },
  {
    name: 'News',
    id: 'news',
    icons: [CNN, FoxNews, MSNBC],
  },
  {
    name: 'Science',
    id: 'science',
    icons: [DiscoveryChannel, ScienceChannel, History],
  },
  {
    name: 'Sports',
    id: 'sports',
    icons: [ESPN, FoxSports, CBS],
  },
  {
    name: 'Lifestyle',
    id: 'lifestyle',
    icons: [OWN, TLC, AE],
  },
  {
    name: 'Home & Garden',
    id: 'home',
    icons: [HGTV, Magnolia, DIY],
  },
];

export const ctvSegmentsMap = ctvSegments.reduce((acc, segment) => {
  acc[segment.id] = segment;

  return acc;
}, {});

export const ctvSegmentIds = ctvSegments.map(type => type.id);
