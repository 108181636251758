import React from 'react';
import { useHistory } from 'react-router-dom';
import { API } from 'aws-amplify';
import { acceptInviteLink } from 'src/graphql/mutations';
import { refreshToken } from 'src/helpers';

export const AcceptInviteCurrentUser = ({ invite }) => {
  const { inviteCode } = invite;
  const history = useHistory();

  const acceptInvite = async () => {
    await API.graphql({
      query: acceptInviteLink,
      variables: { inviteCode, status: 'accepted' },
    })
      .then(refreshToken)
      .then(() => history.push('/'));
  };

  return (
    <>
      <div className="w-full text-center">
        <h1 className="w-full text-3xl text-gray-800">You're invited to join the</h1>
        <h1 className="w-full text-3xl text-gray-800">{`${invite.name} ${invite.inviteType}`}</h1>
      </div>
      <button
        type="button"
        onClick={acceptInvite}
        className="w-full border-0 bz-btn-solid text-white mt-8 h-12 font-medium rounded-lg bg-secondary"
      >
        Accept
      </button>
    </>
  );
};
