import { Doughnut } from 'react-chartjs-2';
import { useCreativesContext } from '../../CreativesContext';

export const DevicesGraph = ({ colors, lightColors, selected, metrics }) => {
  const creatives = useCreativesContext();

  const hasCtv = (creatives || []).filter(c => c?.ctv).length;
  const hasOther = (creatives || []).filter(c => c?.web).length;

  const options = {
    plugins: {
      legend: {
        display: false,
        maintainAspectRatio: false,
      },
    },
  };

  let adjustedMetrics = metrics;

  if (hasCtv && !hasOther) {
    const total = metrics.reduce(
      (obj, itm) => ({
        impressions: obj.impressions + itm.impressions,
        clicks: obj.clicks + itm.clicks,
        views: obj.views + itm.views,
        spend: obj.spend + itm.spend,
        conversions: obj.conversions + itm.conversions,
      }),
      { impressions: 0, clicks: 0, views: 0, spend: 0, conversions: 0 }
    );

    adjustedMetrics = [{ ...total, metricKey: 'Connected TV' }];
  }

  const metricData = getFilteredMetrics(adjustedMetrics, selected);

  const data = {
    labels: (adjustedMetrics || []).map(m => m.metricKey),
    datasets: [
      {
        data: metricData,
        backgroundColor: lightColors.slice(0, (adjustedMetrics || []).length),
        borderColor: colors.slice(0, (adjustedMetrics || []).length),
        borderWidth: 2,
        cutout: '75%',
      },
    ],
  };

  const legendItems = (adjustedMetrics || []).map((m, idx) => (
    <div key={`device-${idx}`} className="flex space-x-2 items-center">
      <div style={{ backgroundColor: colors[idx] }} className="w-5 h-5 rounded-full mx-2" />
      <h1 className="tracking-tight">{m.metricKey}</h1>
    </div>
  ));

  return (
    <>
      <div className="w-52 h-52 relative">
        <div className="w-full h-full absolute">
          <Doughnut height={220} width={220} options={options} data={data} />
        </div>
        <div className="w-full h-full flex  justify-center items-center">
          <h1 className="text-xl font-medium">Devices</h1>
        </div>
      </div>
      <div className="flex flex-col w-40 space-y-4 ml-2">{legendItems}</div>
    </>
  );
};

const getFilteredMetrics = (metrics, selected) => {
  if (selected === 'impressions') {
    return (metrics || []).map(m => m.impressions);
  }
  if (selected === 'clicks') {
    return (metrics || []).map(m => m.clicks);
  }
  if (selected === 'views') {
    return (metrics || []).map(m => m.views);
  }
  if (selected === 'spend') {
    return (metrics || []).map(m => m.spend);
  }
  if (selected === 'conversions') {
    return (metrics || []).map(m => m.conversions);
  }

  return [];
};
