import { useCallback, useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import { DropdownButton } from './DropdownButton';

const getDirectionalClasses = direction => {
  switch (direction) {
    case 'up':
      return 'bottom-0';
    default:
      return 'top-0';
  }
};

/**
 * Component for rendering a dropdown menu.
 * onClick is required for either the element or the items
 *
 * @component
 * @example
 * const items = [
 *   {
 *     key: 'a',
 *     onClick: () => {} // optional
 *     children: <p>Some element</p> || 'Some text',
 *   },
 * ];
 * return (
 *   <Dropdown items={items}>
 *     Text
 *   </Dropdown>
 * )
 */
export const Dropdown = ({ children, items, className, buttonClassName, onClick, direction = 'down' }) => {
  const ref = useRef(null);
  const [open, setOpen] = useState(false);

  useClickAway(ref, () => setOpen(false));
  const toggle = useCallback(() => setOpen(!open), [open, setOpen]);

  return (
    <div ref={ref} className={`${className} relative`}>
      <button
        type="button"
        className={buttonClassName ?? 'flex items-center space-x-4 hover:bg-blueGray-200 py-1 px-2 rounded'}
        onClick={toggle}
      >
        {children}
      </button>
      <div className={`z-20 overflow-visible absolute min-w-full ${getDirectionalClasses(direction)}`}>
        <div
          className={`origin-bottom-right mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none ${
            !open && 'hidden'
          }`}
        >
          <div className="py-1 p-3 flex flex-col max-h-72 overflow-y-scroll force-scrollbar">
            {items.map(item => {
              const clickHandler = item.onClick ?? (() => onClick?.(item.key));

              return (
                <DropdownButton
                  key={item.key}
                  onClick={(...args) => {
                    setOpen(false);
                    clickHandler(...args);
                  }}
                >
                  {item.children}
                </DropdownButton>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
