import { API } from 'aws-amplify';

export const updateUserMutation = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!, $condition: ModelUserConditionInput) {
    updateUser(input: $input, condition: $condition) {
      id
      cognitoId
      userId
      authGroup
      createdAt
      updatedAt
      selectedAuth
      firstName
      lastName
      profileImageUrl
      hasBeenWelcomed
      email
    }
  }
`;

const getUserQuery = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      selectedAuth
      email
    }
  }
`;

export const getUser = userId =>
  API.graphql({
    query: getUserQuery,
    variables: {
      id: userId,
    },
  }).then(({ data: { getUser: resp } = {} }) => resp || {});
