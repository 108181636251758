import { useGAS } from 'src/GlobalAppState/context';
import { selectCampaignValidForPublish } from 'src/selectors/campaign/summarySelectors';
import { useOrganizationMinBudget } from 'src/helpers/organization/useOrganizationMinBudget';
import { useCreativesContext } from '../../CreativesContext';
import { usePublishHandler } from './usePublishHandler';

export const PublishButton = ({ campaignDraft, draftChanged, brandHasError }) => {
  const { campaign, appRouteParams, brandTrackingTags } = useGAS();
  const orgMinimum = useOrganizationMinBudget();
  const campaignValidForPublish = selectCampaignValidForPublish(campaignDraft, campaign, {
    brandTrackingTags,
    orgMinimum,
  });
  const creatives = useCreativesContext();
  const isNew = appRouteParams?.page === 'new-campaign';

  const onApprove = usePublishHandler(campaignDraft);

  const disabled = brandHasError || !campaignValidForPublish || draftChanged || !creatives.length || isNew;

  return (
    <button
      type="button"
      onClick={onApprove}
      disabled={disabled}
      className="bz-btn-solid w-full h-12 2xl:h-16 disabled:cursor-default hover:opacity-80 disabled:opacity-40 rounded"
      data-testid="campaignSubmit"
    >
      Approve for Launch
    </button>
  );
};
