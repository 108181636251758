import { Suspense, lazy } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import WithAuthLayout from './layout';
import AppComponent, { appPaths } from './(app)/component';

const Admin = lazy(() => import('./admin/component'));
const Finance = lazy(() => import('./finance/component'));
const Onboarding = lazy(() => import('./onboarding/component'));

const validAdminPaths = [
  '/admin',
  '/admin/:view(accounts|errors|campaigns|spend)',
  '/admin/accounts/:record(organization|team|brand|campaign|creative|audience|bidlist)/:id',
];

const validFinancePaths = ['/finance', '/finance/:model(team|brand)/:id'];

const onboardingPath = '/onboarding';

export const withAuthPaths = [...validAdminPaths, ...validFinancePaths, onboardingPath, ...appPaths];

export default function WithAuth() {
  return (
    <WithAuthLayout>
      <Switch>
        <Route exact path={validAdminPaths}>
          <Suspense>
            <Admin />
          </Suspense>
        </Route>
        <Route exact path={validFinancePaths}>
          <Suspense>
            <Finance />
          </Suspense>
        </Route>
        <Route exact path={appPaths}>
          <AppComponent />
        </Route>
        <Route exact path={onboardingPath}>
          <Suspense>
            <Onboarding />
          </Suspense>
        </Route>
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
    </WithAuthLayout>
  );
}
