import { useCallback } from 'react';

export const OPTIONS = [
  {
    value: 7,
    label: 'Weekly',
  },
  {
    value: 30,
    label: 'Every 30 Days',
  },
];

const baseInputClasses = 'flex flex-row space-x-2 py-2 px-3 items-center border-2 rounded select-none';

const getLabelColor = (checked, disabled) => {
  if (checked && disabled) {
    return 'border-gray-500 bg-gray-500 text-gray-50';
  }

  if (checked) {
    return 'border-black bg-black text-gray-50';
  }

  if (disabled) {
    return 'border-gray-100 bg-gray-100 text-gray-500';
  }

  return 'border-black text-black';
};

const RecurrenceInput = ({ value, disabled, onChange, checked, children }) => (
  <label className={`${baseInputClasses} ${getLabelColor(checked, disabled)}`}>
    <input
      className={`text-gray-500 focus:ring-current ${disabled && 'bg-gray-400 opacity-25 cursor-default'}`}
      name="recurrence"
      type="radio"
      value={value}
      disabled={disabled}
      onChange={onChange}
      checked={checked}
    />
    <span>{children}</span>
  </label>
);

export const RecurrenceSelector = ({ value, onChange, disabled }) => {
  const handler = useCallback(
    e => {
      if (disabled) {
        return;
      }

      onChange(parseInt(e.target.value, 10));
    },
    [disabled, onChange]
  );

  return (
    <div className="flex flex-row space-x-4">
      {OPTIONS.map(({ value: optionValue, label }) => (
        <RecurrenceInput
          disabled={disabled}
          key={optionValue}
          value={optionValue}
          onChange={handler}
          checked={value === optionValue}
        >
          {label}
        </RecurrenceInput>
      ))}
    </div>
  );
};
