export const AuthLevels = {
  STATIC: 'static',
  ORGANIZATION: 'organization',
  TEAM: 'team',
  BRAND: 'brand',
};

export const OrderedAuthLevels = [AuthLevels.STATIC, AuthLevels.ORGANIZATION, AuthLevels.TEAM, AuthLevels.BRAND];

export const getAuthLevel = ({ selectedAuthS3Prefix, selectedAuth }, { accountType }) => {
  const authLevelIdx = (selectedAuthS3Prefix || '').split('/').length;

  if (selectedAuth?.staticAuthRole) return AuthLevels.STATIC;

  return authLevelIdx ? OrderedAuthLevels[authLevelIdx] : accountType;
};
