import { useContext, useEffect } from 'react';
import { requestFeatureFlag } from './featureFlagReducer';
import { FeatureFlagContext } from './FeatureFlagContext';

export const useFeatureFlag = (featureFlag, defaultValue = false) => {
  const [state, dispatch] = useContext(FeatureFlagContext);
  const currentValue = state[featureFlag]?.value;
  const value = typeof currentValue !== 'undefined' ? currentValue : defaultValue;

  useEffect(() => {
    dispatch(requestFeatureFlag(featureFlag));
  }, [dispatch, featureFlag]);

  return value ? value === 'TRUE' : value;
};
