import { Auth } from 'aws-amplify';

export const refreshToken = async ({ bypassCache } = {}) => {
  const cognitoUser = await Auth.currentAuthenticatedUser({
    bypassCache,
  });
  const currentSession = await Auth.currentSession();

  return cognitoUser.refreshSession(currentSession.refreshToken, err => {
    if (err) console.error(err);

    return Auth.currentUserCredentials();
  });
};
