import React, { useState, useRef } from 'react';
import { useSpring, animated, config } from 'react-spring';
import { useClickAway, useKey } from 'react-use';

export const UrlPrefixDropdown = ({
  update,
  prefix,
  setPrefix,
  urlNoPrefix,
  ringColor = 'ring-primary',
  bgColor = 'bg-primary',
}) => {
  const [open, setOpen] = useState(false);
  const style = useSpring({ height: open ? 112 : 0, opacity: open ? 1 : 0, config: config.stiff });
  const menuRef = useRef(null);

  useKey('Escape', () => setOpen(false));
  useClickAway(menuRef, () => setOpen(false));

  const onSelect = cat => {
    update(`${cat}${urlNoPrefix}`);
    setPrefix(cat);
    setOpen(false);
  };

  const optionsArrStr = 'http://, https://';
  const options = optionsArrStr.split(', ').map(opt => (
    <button
      type="button"
      key={`cat-${opt}`}
      onClick={() => onSelect(opt)}
      className="drop-option flex w-full items-center px-4 h-12 hover:bg-blue-100 focus:outline-none focus:bg-blue-100"
    >
      {opt}
    </button>
  ));

  const selectedText = prefix || 'https://';

  return (
    <div ref={menuRef}>
      <div className="flex flex-col">
        <div className="relative w-full h-full">
          <button
            type="button"
            onClick={() => setOpen(!open)}
            className={`flex justify-between items-center bg-blueGray-200 focus:bg-blueGray-100 focus:ring ${ringColor} h-12 pr-0 pl-4 py-4 text-lg leading-tight text-secondary rounded appearance-none focus:outline-none ${
              ringColor === 'ring-secondary' ? 'ring-opacity-0' : ''
            }`}
          >
            {selectedText}
            <div className={`w-12 h-12 ml-4 flex justify-center items-center rounded-r ${bgColor}`}>
              <ChevronDown className="w-4" />
            </div>
          </button>
          <animated.div
            style={style}
            className="absolute z-40 h-52 mt-1 overflow-scroll w-full rounded bg-white shadow-lg"
          >
            {open && options}
          </animated.div>
        </div>
      </div>
    </div>
  );
};

const ChevronDown = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 17.1 7.7">
    <path
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M1.4 1.4l7.2 5.3 7.1-5.3"
      data-name="Path 13084"
    />
  </svg>
);
