import { useCallback, useEffect, useRef } from 'react';

export const useIfMounted = fn => {
  const ref = useRef(false);

  useEffect(() => {
    ref.current = true;

    return () => {
      ref.current = false;
    };
  }, []);

  return useCallback(
    (...args) => {
      if (ref.current) {
        fn(...args);
      }
    },
    [fn]
  );
};
