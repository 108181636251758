import { getBrandCampaigns } from 'src/helpers/brand/getBrandCampaigns';
import { identity } from 'lodash';
import { getCsvMetrics } from './getCsvMetrics';
import {
  aggregateMetricsBy,
  collectUniqueCampaignConversionEventsDspIds,
  createBrandDspIdsToTrackingTagNameMap,
  createBrandTrackingTagIdTtdIdMap,
  createUniversalPixelDspIdsToTrackingTagNameMap,
  makeSorter,
} from './helpers';

const campaignEligibleForReporting = campaign => !['draft', 'archived'].includes(campaign.status) && !campaign.archived;

const buildMetricsQueryProcessor =
  (aggregateFn, sorter = identity) =>
  async (ids, options) => {
    const { brandTrackingTags, brand } = options;

    const brandTrackingTagIdTtdIdMap = createBrandTrackingTagIdTtdIdMap(brandTrackingTags);
    const brandDspIdsToTrackingTagName = createBrandDspIdsToTrackingTagNameMap(brandTrackingTags);
    const universalPixelDspIdsToTrackingTagName = createUniversalPixelDspIdsToTrackingTagNameMap(brand);

    const dspIdsToTrackingTagName = {
      ...brandDspIdsToTrackingTagName,
      ...universalPixelDspIdsToTrackingTagName,
    };

    const campaigns = await getBrandCampaigns({ brandId: ids.brandId });

    const filteredCampaignsById = campaigns.reduce((acc, campaign) => {
      if (campaignEligibleForReporting(campaign)) {
        acc[campaign.id] = campaign;
      }

      return acc;
    }, {});

    const uniqueCampaignConversionEventDspIds = collectUniqueCampaignConversionEventsDspIds(
      Object.values(filteredCampaignsById),
      brandTrackingTagIdTtdIdMap
    );

    uniqueCampaignConversionEventDspIds.forEach(dspId => {
      dspIdsToTrackingTagName[dspId] = dspIdsToTrackingTagName[dspId] || dspId;
    });

    const metrics = await getCsvMetrics(ids, 'CAMPAIGN_DAILY').then(resp => aggregateFn(resp.items, options));

    const ttdIdsInMetrics = [];

    const reportMetrics = metrics.reduce((acc, metric) => {
      const eligibleCampaign = filteredCampaignsById[metric.campaignId];

      if (eligibleCampaign) {
        acc.push(Object.assign(metric, { campaignName: eligibleCampaign.name }));
      }

      if (metric.conversionBreakdown) {
        ttdIdsInMetrics.push(...Object.keys(metric.conversionBreakdown));

        const campaign = filteredCampaignsById[metric.campaignId];

        const campaignConversionEventTtdIds = campaign?.conversionEvents?.map(
          conversionEvent => brandTrackingTagIdTtdIdMap[conversionEvent.id]
        );

        campaignConversionEventTtdIds?.forEach(ttdId => {
          if (!metric.conversionBreakdown[ttdId]) {
            metric.conversionBreakdown[ttdId] = 0;
          }
        });

        Object.keys(metric.conversionBreakdown).forEach(ttdId => {
          const name = dspIdsToTrackingTagName[ttdId] || ttdId;

          metric[name] = metric.conversionBreakdown[ttdId];
        });

        delete metric.conversionBreakdown;
      }

      return acc;
    }, []);

    const additionalHeadersId = [...new Set([...uniqueCampaignConversionEventDspIds, ...ttdIdsInMetrics])];
    const additionalHeaders = additionalHeadersId.map(id => dspIdsToTrackingTagName[id] || id);

    const sortedMetrics = sorter(reportMetrics);

    sortedMetrics.additionalHeaders = additionalHeaders;

    return sortedMetrics;
  };

const dateSort = ['date', 'desc'];
const campaignNameSort = [metric => metric.campaignName.toLowerCase(), 'asc'];

export const getCampaignsSummaryMetrics = buildMetricsQueryProcessor(
  aggregateMetricsBy('campaignId'),
  makeSorter(campaignNameSort)
);

export const getCampaignsDailyMetrics = buildMetricsQueryProcessor(
  aggregateMetricsBy('metricKey', 'campaignId'),
  makeSorter(dateSort, campaignNameSort)
);
