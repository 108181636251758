import React, { useState, useRef } from 'react';
import { useSpring, animated, config } from 'react-spring';
import { useClickAway, useKey } from 'react-use';
import { ChevronDown } from 'src/svgs';
import { bzColorPallets } from 'src/helpers';

export const GraphThemeDropdown = ({ value, onSelect }) => {
  const [showEdit, setShowEdit] = useState(false);
  const style = useSpring({ height: showEdit ? 208 : 0, opacity: showEdit ? 1 : 0, config: config.stiff });
  const menuRef = useRef(null);

  useKey('Escape', () => setShowEdit(false));
  useClickAway(menuRef, () => setShowEdit(false));

  const onOptionSelect = cat => {
    onSelect(cat.name);
    setShowEdit(false);
  };

  const options = Object.values(bzColorPallets).map(cat => {
    const Icon = cat.icon;

    return (
      <button
        type="button"
        key={`cat-${cat.name}`}
        onClick={() => onOptionSelect(cat)}
        className="drop-option text-left truncate flex w-full items-center space-x-4 px-6 h-12 hover:bg-blueGray-100 focus:outline-none focus:bg-blue-100"
      >
        <Icon className="w-8 h-8" />
        <h2 className="text-lg">{cat.name}</h2>
      </button>
    );
  });

  const selected = bzColorPallets[value];
  const SelectedIcon = selected?.icon;
  const { colors: [firstColor = '#DDD', secondColor = '#DDD'] = [] } = selected || {};

  const onEditClick = () => {
    if (showEdit) {
      setShowEdit(false);
    } else {
      setShowEdit(true);
    }
  };

  return (
    <div className="w-72 flex flex-col justify-center space-x-4 h-14">
      <button
        type="button"
        onClick={onEditClick}
        style={{ borderColor: firstColor }}
        className="bz-btn-icon flex justify-between px-8 py-2 rounded-l-full rounded-r-full border-2"
      >
        <div className="flex items-center space-x-4">
          {selected?.icon ? <SelectedIcon className="w-8 h-8" /> : <div className="w-8 h-8" />}
          <h2 className="text-lg text-left text-gray-800 font-medium">{selected?.name}</h2>
        </div>
        <ChevronDown style={{ color: secondColor }} className="w-5 stroke-current text-primary" />
      </button>
      {showEdit && (
        <div className="px-1">
          <div ref={menuRef} className="relative w-full">
            <animated.div
              style={style}
              className="absolute -left-2 h-52 mt-1 overflow-scroll w-full rounded bg-white shadow-lg"
            >
              {showEdit && options}
            </animated.div>
          </div>
        </div>
      )}
    </div>
  );
};
