export const buildChartOptions = (selected, tooltipCallback) => {
  const primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primary');

  return {
    maintainAspectRatio: false,
    scales: {
      y: {
        ticks: {
          callback: value => {
            switch (selected) {
              case 'spend':
                return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
              default:
                return shortNumber(value);
            }
          },
          color: 'rgba(156, 163, 175, 1)',
        },
      },
      x: {
        ticks: {
          color: 'rgba(156, 163, 175, 1)',
        },
      },
    },
    elements: {
      point: {
        radius: 7,
        borderWidth: 5,
        hoverRadius: 10,
        hoverBorderWidth: 5,
        hoverBackgroundColor: primaryColor,
        hoverBorderColor: '#FFF',
        borderColor: '#FFF',
        backgroundColor: 'rgba(92, 89, 167, 1)',
      },
    },
    animations: {
      numbers: {
        properties: ['x', 'y', 'borderWidth', 'radius', 'tension'],
      },
    },
    animation: true,
    interaction: {
      mode: 'nearest',
    },
    layout: {
      border: 0,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        usePointStyle: true,
        position: 'nearest',
        enabled: false,
        external: tooltipCallback,
      },
    },
  };
};

const shortNumber = numStr => {
  const num = Number(numStr);

  if (num > 999 && num < 1000000) return num.toLocaleString();
  if (num > 1000000) return `${(num / 1000000).toFixed(1)}M`;
  if (num > 1000000000) return `${(num / 1000000).toFixed(1)}B`;
  if (num < 1000) return num;
};
