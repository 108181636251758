import React, { useState, useEffect } from 'react';
import { animated, useTransition } from 'react-spring';
import { shortNumber } from 'src/helpers';

export const TopRegions = ({ leafletRefs, mapParams, metricSelection, regionSegmentGroup }) => {
  const [segments, setSegments] = useState(false);

  useEffect(() => {
    const { geoJson: { features, properties } = {} } = mapParams || {};
    const { mapType, name, segmentGroup } = properties || {};
    const parentName = `${name} >`;

    if (features) {
      let featureSet = features;

      if (mapType === 'Region') {
        const regionSet = features.find(f => f.properties.segmentGroup === regionSegmentGroup) || features[0];

        featureSet = regionSet.features || [];
      }

      if (segmentGroup === 'Heatmap') {
        setSegments([]);

        return;
      }

      const topFeatures = featureSet
        .filter(({ properties: { segmentGroup: sg, name: fName, metrics: fMetrics } = {} }) => {
          const { [metricSelection]: metric = 0 } = fMetrics || {};
          const notMask = sg !== 'Mask';
          const hasParent = fName.startsWith(parentName);
          const hasMetrics = metric > 0;
          const validZip = sg === 'Zip' && hasMetrics;
          const validRegion = notMask && hasParent && hasMetrics;
          const validCountry = sg === 'Country' && hasMetrics;

          return validCountry || validRegion || validZip;
        })
        .sort((a, b) => (a?.properties?.metrics[metricSelection] > b?.properties?.metrics[metricSelection] ? -1 : 1))
        .slice(0, 9);

      setSegments(topFeatures);
    }
  }, [mapParams, metricSelection, regionSegmentGroup]);

  return (
    <div className="h-full pointer-events-auto">
      <RegionList
        segments={segments}
        mapParams={mapParams}
        metricSelection={metricSelection}
        leafletRefs={leafletRefs}
      />
    </div>
  );
};

const RegionList = ({ segments, mapParams, metricSelection, leafletRefs }) => {
  const parentKey = mapParams?.geoJson?.properties?.name ? `${mapParams?.geoJson?.properties?.name} > ` : '';

  const { map } = leafletRefs.current || {};

  const calcY = (arr, i) => {
    const max = arr.length;
    const idx = i >= max ? i - max : i;

    return idx * 70;
  };

  const transitions = useTransition(segments, {
    keys: item => item?.id,
    from: { opacity: 0, x: -300 },
    leave: { opacity: 0, x: -300 },
    sort: (a, b) => {
      const selA = a?.properties?.metrics[metricSelection];
      const selB = b?.properties?.metrics[metricSelection];

      return selA > selB ? -1 : 1;
    },
    update: (_itm, idx) => ({ y: calcY(segments, idx) }),
    enter: (_itm, idx) => ({ y: calcY(segments, idx), opacity: 1, x: 0, delay: 300 }),
  });

  // eslint-disable-next-line react/no-unstable-nested-components
  const RegionMetric = ({ region, metricKey }) => {
    const active = metricKey === metricSelection;
    const fullNum = Math.ceil(region?.properties?.metrics[metricKey] || 0);
    const value = shortNumber(fullNum, 0, true);
    const valueStr = metricKey === 'spend' && value ? `$${value}` : value;

    return (
      <div className={`flex-1 flex items-center space-x-2 ${active ? 'text-primary' : 'text-gray-500'}`}>
        <div
          className={`flex items-center justify-center rounded-full w-5 h-5 ${
            active ? 'bg-primary text-white' : 'border border-primary text-primary'
          }`}
        >
          <span className="text-xs -mt-px">{metricKey[0]}</span>
        </div>
        <span className={`inline-block text-sm ${active ? 'font-bold' : 'font-normal'}`}>{valueStr}</span>
      </div>
    );
  };

  const mouseEnter = itm => {
    map.eachLayer(lyr => {
      if (lyr?.feature?.properties?.pathKey === itm?.properties?.pathKey) lyr.fireEvent('mouseover');
    });
  };

  const mouseLeave = itm => {
    map.eachLayer(lyr => {
      if (lyr?.feature?.properties?.pathKey === itm?.properties?.pathKey) lyr.fireEvent('mouseout');
    });
  };

  const onClick = itm => {
    map.eachLayer(lyr => {
      if (lyr?.feature?.properties?.pathKey === itm?.properties?.pathKey) lyr.fireEvent('click');
    });
  };

  const items = transitions((style, item, _t, idx) => {
    const adjIdx = idx >= segments.length ? idx - segments.length + 1 : idx + 1;

    return (
      <animated.button
        type="button"
        onMouseEnter={() => mouseEnter(item)}
        onMouseLeave={() => mouseLeave(item)}
        onClick={() => onClick(item)}
        key={item?.properties?.name}
        style={style}
        className="flex absolute w-80 items-center text-left rounded bg-white pt-1 pl-0 pb-2 pointer-events-auto"
      >
        <span className="w-8 inline-block text-center">{`${adjIdx}.`}</span>
        <div className="flex flex-col w-full space-y-1">
          <div className="whitespace-nowrap font-semibold">
            <span>{(item?.properties?.name || '').replace(parentKey, '')}</span>
          </div>
          <div className="flex w-full justify-between">
            <RegionMetric region={item} metricKey="impressions" />
            <RegionMetric region={item} metricKey="views" />
            <RegionMetric region={item} metricKey="clicks" />
            <RegionMetric region={item} metricKey="spend" />
          </div>
        </div>
      </animated.button>
    );
  });

  return (
    <div className="w-0 overflow-visible relative z-10 h-full pointer-events-none">
      <div className="relative p-2 pt-36 left-1 h-full pointer-events-none">
        <div className={`${segments.length ? 'w-80 h-full' : 'w-0'} overflow-hidden relative pb-8`}>{items}</div>
      </div>
    </div>
  );
};
