const forceStringValue = value => (typeof value === 'string' ? value : '');

export const campaignSorter = (items, appParams, options) => {
  const { search, filter, sort, loaded, isListView } = options || {};
  const { model, appState, brand = {}, accountSettings: { accountType } = {} } = appParams || {};

  if (!accountType || !loaded) return [];
  const statuses = Object.entries(filter).reduce((arr, [k, v]) => (v ? [...arr, k] : arr), []);

  if (statuses.includes('running')) statuses.push('approved');
  if (statuses.includes('pausing')) statuses.push('approved');

  let filteredSearch = (items || []).filter(
    ({ name, brandId }) =>
      forceStringValue(name).toLocaleLowerCase().includes(forceStringValue(search).toLocaleLowerCase()) &&
      brandId === brand?.id
  );

  if (filter) {
    filteredSearch = filteredSearch.filter(({ status }) => {
      let statusStr = status || 'draft';

      if (
        ['paused', 'pausing', 'approved', 'running', 'stopped', 'stopping', 'updating'].includes(status) &&
        appParams.appState !== 'build'
      ) {
        statusStr = 'approved';
      }

      if (['forecast', 'draft', 'updating'].includes(status)) statusStr = 'draft';

      return statuses.includes(statusStr);
    });
  }

  if (sort === 'Sort A-Z') {
    filteredSearch = filteredSearch.sort((a, b) => a?.name?.localeCompare(b?.name));
  }

  if (sort === 'Sort Z-A') {
    filteredSearch = filteredSearch.sort((a, b) => b?.name?.localeCompare(a?.name));
  }

  if (sort === 'Date Asc') {
    filteredSearch = filteredSearch.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
  }
  if (sort === 'Date Desc') {
    filteredSearch = filteredSearch.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
  }

  const cardItems =
    model === 'brand' && appState === 'build' && !search && !isListView ? [{ id: 'new', newCard: true }] : [];

  if (filteredSearch) cardItems.push(...filteredSearch);
  if (loaded && filteredSearch && filteredSearch.length === 0 && !isListView) {
    if (model !== 'brand') {
      cardItems.push({ id: 'new', newCard: true });
    }
    cardItems.push({ id: 'help', helpCard: true });
  }

  return cardItems;
};
