import {
  SettingsBody,
  SettingsPage,
  SettingsPageHeader,
  SettingsPageHeaderLink,
} from 'src/components/MainSettings/shared/Layout';
import { useUserAuth } from 'src/GlobalAppState/UserAuth';
import { useGAS } from 'src/GlobalAppState/context';

export default function TeamCreditsLayout({ children }) {
  const { team } = useGAS();
  const { userAuth } = useUserAuth();

  const hasTeamAdmin = team?.id && userAuth.authGroups?.includes(`${team.id}:admin`);
  const hasAdmin = userAuth.authGroups?.includes('Admin');

  const canSeeMargin = hasTeamAdmin || hasAdmin;

  return (
    <SettingsPage>
      <SettingsPageHeader>
        <SettingsPageHeaderLink pageKey="credits">Credits</SettingsPageHeaderLink>
        {canSeeMargin && (
          <>
            <SettingsPageHeaderLink pageKey="earnings">Margin Earnings</SettingsPageHeaderLink>
            <SettingsPageHeaderLink pageKey="margin">Margin Settings</SettingsPageHeaderLink>
          </>
        )}
        <SettingsPageHeaderLink pageKey="payment-methods">Saved Payment Methods</SettingsPageHeaderLink>
      </SettingsPageHeader>
      <SettingsBody>{children}</SettingsBody>
    </SettingsPage>
  );
}
