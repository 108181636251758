import { Switch, Route } from 'react-router-dom';
import CampaignLayout from './layout';
import CampaignPage from './page';
import CampaignEditPage from './edit/page';
import CampaignForecastPage from './forecast/page';

const campaignViewer = '/campaign/:id';
const campaignEditor = '/campaign/:id/edit';
const campaignForecast = '/campaign/:id/forecast';

export const campaignPaths = [campaignEditor, campaignViewer, campaignForecast];

export default function Campaign() {
  return (
    <CampaignLayout>
      <Switch>
        <Route exact path={campaignViewer}>
          <CampaignPage />
        </Route>
        <Route exact path={campaignEditor}>
          <CampaignEditPage />
        </Route>
        <Route exact path={campaignForecast}>
          <CampaignForecastPage />
        </Route>
      </Switch>
    </CampaignLayout>
  );
}
