import { ColumnCell, ColumnHeader } from './Components';

export const SpendPercentColumn = {
  key: 'spendPercent',
  header: () => <ColumnHeader className="w-48">% Spent</ColumnHeader>,
  cell: ({ campaign, metrics }) => {
    let budgetSpent = Math.round(((metrics.spend || 0) / (campaign.budget || 1)) * 100) || 0;

    if (budgetSpent > 100) budgetSpent = 100;

    return (
      <ColumnCell className="pr-4">
        <div className="w-full bg-gray-100 rounded h-5">
          <div className="bg-blueGray-300 h-5 rounded flex items-center" style={{ width: `${budgetSpent}%` }}>
            <span className="text-black text-sm ml-1">{`${budgetSpent}%`}</span>
          </div>
        </div>
      </ColumnCell>
    );
  },
};
