import { difference, groupBy, matches, pick, sortBy, upperFirst } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { FaCaretDown, FaCaretUp, FaChevronDown, FaPlus } from 'react-icons/fa';
import { useGAS } from 'src/GlobalAppState/context';
import { Dropdown } from 'src/components/shared/Dropdown';

const CONVERSION_EVENT_MAX_COUNT = 5;

const titleizeTagType = tagType => {
  switch (tagType) {
    case 'trackingTag':
      return 'Tracking Tag';
    default:
      return upperFirst(tagType);
  }
};

const renderTagOption = setSelected => tag => ({
  key: tag.id,
  onClick: () => setSelected(tag),
  children: (
    <p className="text-black whitespace-nowrap flex flex-row space-x-8 items-center">
      <span className="flex-1 text-left">{tag.name ?? tag.id}</span>
      <span className="flex-0">{titleizeTagType(tag.type)}</span>
    </p>
  ),
});

const NewEventSelector = ({ tags, onPersist }) => {
  const [selected, setSelected] = useState();

  const dropdownOptions = useMemo(() => (tags || []).map(renderTagOption(setSelected)), [setSelected, tags]);

  return (
    <>
      <div className="flex-1 flex items-center">
        <Dropdown
          items={dropdownOptions}
          onClick={setSelected}
          direction="up"
          buttonClassName="w-full border border-gray-200 flex flex-row px-4 py-2 rounded items-center"
          className="w-full"
        >
          <span className="flex-1 text-left">{selected ? selected.name || selected.id : 'Select an event…'}</span>
          <FaChevronDown />
        </Dropdown>
      </div>
      <button
        type="button"
        className={`px-4 py-2 rounded ${
          selected ? 'bg-black text-white' : 'bg-gray-200 text-gray-400 pointer-events-none'
        }`}
        disabled={!selected}
        onClick={() => onPersist(selected)}
      >
        Add Event
      </button>
    </>
  );
};

const MoveButton = ({ disabled, onMove, type, hidden, index }) => {
  const Icon = type === 'up' ? FaCaretUp : FaCaretDown;

  const onClick = useCallback(() => {
    const byNumber = type === 'up' ? -1 : 1;

    onMove(index, byNumber);
  }, [index, onMove, type]);

  const baseClasses =
    hidden || disabled
      ? 'text-gray-200 pointer-events-none'
      : 'text-gray-500 transform hover:scale-125 hover:text-gray-800 transition';
  const hiddenClasses = hidden ? 'opacity-0 pointer-events-none' : '';

  return (
    <button
      type="button"
      disabled={disabled || hidden}
      onClick={onClick}
      className={`px-1 ${baseClasses} ${hiddenClasses}`}
    >
      <Icon className="text-current h-5 w-5" />
    </button>
  );
};

const ConversionRow = ({ index, persisted = false, onPersist, onRemove, selectedTag, tags, onMove }) => (
  <li className="border-b last:border-b-0 border-gray-300 flex flex-row px-4 py-2 space-x-4 items-center">
    <div className="flex flex-col items-center justify-center">
      <MoveButton type="up" disabled={!index} hidden={!persisted} onMove={onMove} index={index} />
      <MoveButton type="down" disabled={index + 1 >= tags.length} hidden={!persisted} onMove={onMove} index={index} />
    </div>
    <div className="bg-gray-200 text-gray-800 font-semibold flex-0 rounded-full px-4 py-2">Event {index + 1}</div>
    {persisted ? (
      <>
        <span className="flex-1 px-4 py-2">{selectedTag.name}</span>
        <button type="button" className="px-4 py-2 text-red-500 underline" onClick={() => onRemove(selectedTag)}>
          Remove
        </button>
      </>
    ) : (
      <>
        <NewEventSelector tags={tags} onPersist={onPersist} />
        <button type="button" className="text-black underline px-4 py-2" onClick={() => onRemove(selectedTag)}>
          Cancel
        </button>
      </>
    )}
  </li>
);

const getTrackingTagGroup = tag => {
  switch (tag.trackingTagType) {
    case 'custom':
    case null:
      return 'eligibleTags';
    default:
      return tag.trackingTagType;
  }
};

const universalTagToSelectable = universalPixelMapping => ({
  id: universalPixelMapping.TrackingTagId,
  name: universalPixelMapping.UniversalPixelMappingName,
  type: 'universal',
});

const trackingTagToSelectable = trackingTag => ({
  id: trackingTag.id,
  name: trackingTag.name,
  type: 'trackingTag',
});

const useAllAvailableConversionTags = () => {
  const { brand, brandTrackingTags } = useGAS();

  const universalPixels = useMemo(() => {
    if (!brand.universalPixel?.UniversalPixelMappings) {
      return [];
    }

    const { ExactMatchMappings = [], WildcardMatchMappings = [] } = brand.universalPixel.UniversalPixelMappings;

    return [...ExactMatchMappings, ...WildcardMatchMappings].map(universalTagToSelectable);
  }, [brand.universalPixel]);

  const trackingTags = useMemo(() => {
    const { eligibleTags = [] } = groupBy(brandTrackingTags, getTrackingTagGroup);

    return eligibleTags.map(trackingTagToSelectable);
  }, [brandTrackingTags]);

  const availbleTrackingTags = [...universalPixels, ...trackingTags].filter(tag => tag.archived !== true);

  return useMemo(() => sortBy(availbleTrackingTags, 'name'), [availbleTrackingTags]);
};

export const MultiConversionSelector = ({ campaignDraft, setCampaignDraft }) => {
  const [showAddNew, setShowAddNew] = useState(false);
  const availableConversionTags = useAllAvailableConversionTags();

  const selectedTags = useMemo(
    () =>
      (campaignDraft.conversionEvents || []).map(event => availableConversionTags.find(matches(event))).filter(Boolean),
    [availableConversionTags, campaignDraft.conversionEvents]
  );

  const unselectedTags = useMemo(
    () => difference(availableConversionTags, selectedTags),
    [availableConversionTags, selectedTags]
  );

  const onPersist = useCallback(
    tag => {
      setCampaignDraft({
        ...campaignDraft,
        conversionEvents: (campaignDraft.conversionEvents || []).concat(pick(tag, ['id', 'type'])),
      });
      setShowAddNew(false);
    },
    [campaignDraft, setCampaignDraft]
  );

  const onRemove = useCallback(
    tag => {
      const conversionEvents = (campaignDraft.conversionEvents || []).filter(event => event.id !== tag.id);

      setCampaignDraft({ ...campaignDraft, conversionEvents });
    },
    [campaignDraft, setCampaignDraft]
  );

  const onMove = useCallback(
    (index, byNumber) => {
      const conversionEvents = (campaignDraft.conversionEvents || []).slice();
      const [event] = conversionEvents.splice(index, 1);

      conversionEvents.splice(index + byNumber, 0, event);

      setCampaignDraft({
        ...campaignDraft,
        conversionEvents,
      });
    },
    [campaignDraft, setCampaignDraft]
  );

  const canAddMoreEvents = selectedTags.length < CONVERSION_EVENT_MAX_COUNT;

  return (
    <div className="flex flex-col space-y-4">
      <ul className={`${selectedTags.length || showAddNew ? 'border border-gray-300 rounded' : ''}`}>
        {selectedTags.map((tag, index) => (
          <ConversionRow
            persisted
            index={index}
            selectedTag={tag}
            key={tag.id}
            onRemove={onRemove}
            onMove={onMove}
            tags={selectedTags}
          />
        ))}
        {showAddNew && canAddMoreEvents && (
          <ConversionRow
            index={selectedTags.length}
            onPersist={onPersist}
            onRemove={() => setShowAddNew(false)}
            tags={unselectedTags}
          />
        )}
      </ul>
      {!showAddNew && canAddMoreEvents && (
        <button
          type="button"
          onClick={() => setShowAddNew(true)}
          className="bg-black text-white font-semibold p-4 rounded flex flex-row space-x-4 items-center w-full justify-center"
        >
          <FaPlus className="flex-0 h-3 w-3" />
          <span>Add An Event</span>
        </button>
      )}
    </div>
  );
};
