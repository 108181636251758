import { useMemo } from 'react';
import { groupBy } from 'lodash';
import { useGAS } from 'src/GlobalAppState/context';
import { useCampaignDraftProvider } from 'src/providers/CampaignDraftProvider';
import { SummaryHeader } from './SummaryHeader';
import { SummaryItem } from './SummaryItem';

export const SiteListsSummary = () => {
  const { managedSitelists } = useGAS();
  const { campaignDraft } = useCampaignDraftProvider();

  const groupedBidlists = useMemo(() => {
    const bidlists = campaignDraft.bidlists?.map(id => managedSitelists.find(sitelist => sitelist.id === id)) ?? [];

    return groupBy(bidlists, 'adjustmentType');
  }, [campaignDraft.bidlists, managedSitelists]);

  if (!managedSitelists?.length) {
    return null;
  }

  if (!campaignDraft.bidlists?.length) {
    return null;
  }

  return (
    <>
      <SummaryHeader panelKey="Site Lists">Site Lists</SummaryHeader>
      {Object.keys(groupedBidlists).map(adjustmentType => {
        const lists = groupedBidlists[adjustmentType];
        const count = lists.length;

        return (
          <div className="ml-4" key={adjustmentType}>
            <SummaryItem title={adjustmentType}>
              {count === 1 ? (
                <h3 className="text-primary">{lists[0].name}</h3>
              ) : (
                <h3 className="text-primary">{count} lists</h3>
              )}
            </SummaryItem>
          </div>
        );
      })}
    </>
  );
};
