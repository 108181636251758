import { useCallback, useState } from 'react';
import { DownloadIcon } from 'src/svgs';
import { ImSpinner } from 'react-icons/im';
import { format } from 'date-fns';
import { endsWith } from 'lodash';
import { getCampaignsSummaryMetrics, getCampaignsDailyMetrics } from './getCampaignMetrics';
import { getBrandDailyMetrics, getBrandSummaryMetrics } from './getBrandMetrics';

const BZ_EMAIL_DOMAIN = '@brandzooka.com';

const getCSVFileName = (recordName, reportKey) => {
  const filePrefix = recordName.replace(/ /g, '_').toLocaleLowerCase();
  const dateStr = format(new Date(), 'MM.dd.yy');
  const reportType = reportKey.split('-')[1] || 'summary';

  return `${filePrefix}-${reportType}-${dateStr}.csv`;
};

const baseColumns = ['spend', 'impressions', 'cpm', 'views', 'clicks', 'ctr'];

const reportColumns = {
  'campaign-daily': ['date', ...baseColumns],
  'brand-daily': ['date', 'campaignName', ...baseColumns, 'conversions'],
  'brand-campaigns': ['campaignName', ...baseColumns],
  'team-daily': ['date', 'brandName', ...baseColumns, 'conversions'],
  'team-brands': ['brandName', ...baseColumns],
};

const internalColumns = {
  'campaign-daily': ['date', ...baseColumns],
  'brand-daily': ['date', 'campaignId', 'campaignName', ...baseColumns, 'conversions'],
  'brand-campaigns': ['campaignId', 'campaignName', ...baseColumns],
  'team-daily': ['date', 'brandId', 'brandName', ...baseColumns, 'conversions'],
  'team-brands': ['brandId', 'brandName', ...baseColumns],
};

const issueQuery = ({ campaignId, brandId, teamId, organizationId }, reportKey, options) => {
  switch (reportKey) {
    case 'campaign-daily':
      return getCampaignsDailyMetrics({ campaignId, brandId, teamId, organizationId }, options);
    case 'brand-daily':
      return getCampaignsDailyMetrics({ brandId, teamId, organizationId }, options);
    case 'brand-campaigns':
      return getCampaignsSummaryMetrics({ brandId, teamId, organizationId }, options);
    case 'team-daily':
      return getBrandDailyMetrics({ teamId, organizationId }, options);
    case 'team-brands':
      return getBrandSummaryMetrics({ teamId, organizationId }, options);
    default:
      return Promise.reject(new Error(`Invalid report key: ${reportKey}`));
  }
};

export const useCSVDownloader = (appParams, options = {}) => {
  const [loading, setLoading] = useState(false);
  const { startDate, endDate, marginPct = 0 } = options;

  const onClick = useCallback(
    (reportKey, campaign = false) => {
      let {
        campaignId,
        brandId,
        teamId,
        organizationId,
        brandTrackingTags,
        campaign: { name: campaignName = '' } = {},
        brand: { name: brandName = '' } = {},
        brand,
        team: { name: teamName = '' } = {},
        user: { email } = {},
      } = appParams;

      if (campaign) {
        campaignId = campaign.id;
        campaignName = campaign.name;
      }

      const fileName = getCSVFileName(campaignName || brandName || teamName, reportKey);
      const showUserInternalColumns = endsWith(email, BZ_EMAIL_DOMAIN);
      const columns = (showUserInternalColumns ? internalColumns : reportColumns)[reportKey];

      setLoading(true);
      issueQuery({ campaignId, brandId, teamId, organizationId }, reportKey, {
        startDate,
        endDate,
        marginPct,
        brandTrackingTags,
        brand,
      })
        .then(metrics => {
          download(metrics, columns, fileName);
        })
        .catch(e => {
          console.error(e);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [setLoading, appParams, startDate, endDate, marginPct]
  );

  return {
    loading,
    onClick,
  };
};

export const CsvDownloader = ({ appParams, reportKey }) => {
  const { loading, onClick } = useCSVDownloader(appParams);
  const _onClick = useCallback(() => onClick(reportKey), [onClick, reportKey]);

  return (
    <div className="w-10 h-full flex items-center justify-center">
      {loading && <ImSpinner className="w-6 h-6 mt-1 text-gray-300 animate-spin" />}
      {!loading && (
        <button
          type="button"
          title="csv export"
          onClick={_onClick}
          className="flex flex-col px-2 h-full items-center justify-center hover:opacity-60 "
        >
          <DownloadIcon className="w-6 h-6 stroke-current text-primary" />
        </button>
      )}
    </div>
  );
};

const genCsv = (metrics, columns) => {
  const data = metrics.map(item => columns.map(column => item[column]).join(',')).join('\n');
  const csvData = `${columns.join(',')}\n${data}`;

  return new Blob([csvData], { type: 'text/csv' });
};

const saveWithAnchor = (csv, fileName) => {
  const url = window.URL.createObjectURL(csv);
  const a = document.createElement('a');

  a.setAttribute('hidden', '');
  a.setAttribute('href', url);
  a.setAttribute('download', fileName);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  setTimeout(() => {
    window.URL.revokeObjectURL(url);
  }, 10_000);
};

export const download = async (metrics, columns, fileName = 'data.csv') => {
  const additionalHeaders = metrics.additionalHeaders || [];
  const headers = [...columns, ...additionalHeaders];

  const csv = genCsv(metrics, headers);

  saveWithAnchor(csv, fileName);
};
