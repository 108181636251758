import React, { useState, useEffect, useRef, useMemo } from 'react';
import { AddUserIcon } from 'src/svgs';
import { useGAS } from 'src/GlobalAppState/context';
import { Spinner } from 'src/components/shared/Spinner';
import { BrandUserRow } from 'src/components/MainSettings/BrandSettings/BrandUserRow';
import { BrandUserNewRow } from 'src/components/MainSettings/BrandSettings/BrandUserNewRow';
import { Headline } from 'src/components/MainSettings/shared/Headers';

export default function BrandSettingsUsersPage() {
  const { orgAuthRoles, brand, userAuth } = useGAS();

  const userAuthRoles = useMemo(() => orgAuthRoles.brands[brand.id] ?? [], [orgAuthRoles.brands, brand.id]);

  const { loading } = orgAuthRoles;

  const [showNew, setShowNew] = useState(false);
  const [sending, setSending] = useState('');
  const inputRef = useRef();
  const { userId } = userAuth;

  useEffect(() => {
    if (showNew && inputRef.current) inputRef.current.focus();
  }, [showNew]);
  const users = brand ? (
    (userAuthRoles || [])
      .filter(role => role.brandId === brand.id)
      .map(userAuthRole => <BrandUserRow key={userAuthRole.id} userAuthRole={userAuthRole} userId={userId} />)
  ) : (
    <tr />
  );

  useEffect(() => {
    if (sending) {
      const [existing] = (userAuthRoles || []).filter(a => a.email === sending && a.brandId);

      if (existing?.email) setSending('');
    }
  }, [sending, userAuthRoles]);

  const onAddUser = () => setShowNew(!showNew);

  const showNewRow = showNew || !users.length;

  return (
    <div className="w-full">
      <Headline>{brand.name} Users</Headline>
      {loading ? (
        <div className="w-full h-full flex justify-center items-center top-0 left-0">
          <Spinner />
        </div>
      ) : (
        <table className="h-auto min-w-full divide-y divide-gray-200">
          <thead className="bg-white">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-sm font-semibold text-gray-500 uppercase tracking-wider"
              >
                <button type="button" data-testid="addUser" onClick={onAddUser} className="bz-btn-icon">
                  <AddUserIcon className="w-8 h-8 text-primary" />
                </button>
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-sm font-semibold text-gray-500 uppercase tracking-wider"
              >
                User
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-sm font-semibold text-gray-500 uppercase tracking-wider"
              >
                Status
              </th>
              <th scope="col" className="w-24 relative px-6 py-3">
                <span className="sr-only">Remove</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {users}
            {sending && (
              <tr className="text-md text-gray-500">
                <td label="brand-user-row" className="w-24 px-6 py-4" />
                <td data-mf-replace="" className="px-6 py-4 w-full">
                  {sending}
                </td>
                <td className="w-40 px-6 py-4">sending invite...</td>
                <td label="brand-user-spacer" />
              </tr>
            )}
            {showNewRow && (
              <BrandUserNewRow inputRef={inputRef} brand={brand} setSending={setSending} setShowNew={setShowNew} />
            )}
          </tbody>
        </table>
      )}
    </div>
  );
}
