import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight } from 'src/svgs';
import { useGAS } from 'src/GlobalAppState/context';
import { capitalize, get } from 'lodash';
import { UserMenu } from './UserMenu';

const ACCOUNT_ACCESS_ROLES = ['Admin', 'Media'];

const AdminNav = () => {
  const {
    appRouteParams: { model, id },
  } = useGAS();
  const [hide, setHide] = useState(false);

  const hasRouteInfo = !!(model && id);

  if (hide) {
    return null;
  }

  return (
    <div className="p-2 bg-gray-800 flex space-x-4 items-center shadow-md text-white text-xs">
      <Link to="/admin" className="hover:underline">
        Admin
      </Link>
      {hasRouteInfo && (
        <Link className="hover:underline" to={`/admin/accounts/${model}/${id}`}>
          {capitalize(model)} Record
        </Link>
      )}
      <span className="flex-1" />
      <button type="button" className="hover:underline" onClick={() => setHide(true)}>
        Hide
      </button>
    </div>
  );
};

export const NavbarV1 = ({ className }) => {
  const { userAuth } = useGAS();
  const isAdmin = ACCOUNT_ACCESS_ROLES.includes(userAuth?.selectedAuth?.staticAuthRole);

  return (
    <header className={`flex flex-col z-10 relative ${className}`}>
      {isAdmin && <AdminNav />}
      <div className="py-4 bg-white flex items-center shadow-md">
        <Breadcrumbs />
        <NavLinks />
      </div>
    </header>
  );
};

const Breadcrumbs = () => {
  const { campaign, brand, team, organization, authLevel } = useGAS();
  const accountType = get(organization, 'accountSettings.accountType');
  const containerRef = useRef();
  const rowRef = useRef();

  useEffect(() => {
    const elm = containerRef.current;

    if (elm) {
      const observer = new ResizeObserver(entries => {
        const [{ contentRect: { width = 0 } = {} }] = entries || [];

        try {
          rowRef.current.style.width = `${width}px`;
        } catch (error) {
          console.log('error', error);
        }
      });

      observer.observe(elm);

      return () => observer.unobserve(elm);
    }
  }, []);

  const showAdminOrg = authLevel === 'static' && ['organization'].includes(accountType);
  const showAdminTeam = authLevel === 'static' && ['organization', 'team'].includes(accountType);
  const showAdminBrand = authLevel === 'static' && ['organization', 'team', 'brand'].includes(accountType);

  const showOrg = organization?.name && showAdminOrg;
  const showTeam = team?.name && (['static', 'organization', 'team'].includes(authLevel) || showAdminTeam);
  const showBrand = brand?.name && (['static', 'organization', 'team', 'brand'].includes(authLevel) || showAdminBrand);

  return (
    <div className="flex-1 overflow-hidden flex space-x-3 items-center pl-8">
      {showOrg && <BreadRow organization={organization} chevron={false} />}
      {showTeam && <BreadRow team={team} chevron={showOrg} />}
      {showBrand && <BreadRow brand={brand} chevron={showTeam} />}
      {campaign?.name && <BreadRow campaign={campaign} />}
    </div>
  );
};

const BreadRow = ({ campaign, brand, team, organization, chevron = true }) => {
  let model = 'brand';

  if (team) model = 'team';
  if (organization) model = 'organization';

  const { name, id } = campaign || brand || team || organization || {};

  return (
    <div className="flex truncate items-center space-x-3">
      {chevron && <ChevronRight className="stroke-current text-gray-600 h-3" />}
      <Link to={`/${model}/${id}`} className="flex-1 text-ellipsis truncate whitespace-nowrap hover:text-primary">
        {name}
      </Link>
    </div>
  );
};

const NavLinks = () => (
  <div className="ml-10 mr-6 flex-shrink-0 flex items-center space-x-10">
    <div className="flex items-center space-x-8">
      <UserMenu />
    </div>
  </div>
);

export default NavbarV1;
