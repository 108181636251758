import Amplify, { Analytics, AWSKinesisFirehoseProvider, Storage } from 'aws-amplify';
import 'react-datepicker/dist/react-datepicker.css';
import awsconfig from 'src/aws-exports';
import 'src/app.css';
import 'leaflet/dist/leaflet.css';
import errorEmitter from 'src/errorEmitter';

window.AudioContext = window.AudioContext || window.webkitAudioContext;

Amplify.configure(awsconfig);
Storage.configure({
  AWSS3: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: 'us-east-1',
  },
  customPrefix: {
    public: '',
  },
});

window.onerror = (message, source, lineno, colno, error) => {
  errorEmitter.emitError(error, {
    message,
    source,
    lineno,
    colno,
  });
};

Analytics.addPluggable(new AWSKinesisFirehoseProvider());
Analytics.configure({
  AWSKinesisFirehose: {
    region: 'us-east-1',
    bufferSize: 1000, // OPTIONAL - The buffer size for events in number of items.
    flushSize: 100, // OPTIONAL - The number of events to be deleted from the buffer when flushed.
    flushInterval: 5000, // 5s // OPTIONAL - The interval in milliseconds to perform a buffer check and flush if necessary.
    resendLimit: 5, // OPTIONAL - The limit for failed recording retries.
  },
});
