export const forecastFields = /* GraphQL */ `
  id
  campaignId,
  brandId,
  teamId,
  organizationId,
  adminGroups,
  viewerGroups,
  adgroupType,
  avgBidCpm,
  timeWindowInDays,
  metrics {
    impressions {
      max
      min
    }
    reach {
      max
      min
    }
    frequency {
      max
      min
    }
    spend {
      max
      min
    }
    cpm {
      max
      min
    }
  },
  budgetMetrics {
    impressions {
      max
      min
    }
    reach {
      max
      min
    }
    frequency {
      max
      min
    }
    spend {
      max
      min
    }
  },
  deviceMaxReach,
  underSpend,
  startDate,
  endDate,
  geoSegments {
    id
    dspId
    name
    pathKey
    segmentGroup
    segmentPackageId
    segmentType
  },
  dmpSegments {
    id
    dspId
    name
    pathKey
    segmentGroup
    segmentPackageId
    segmentType
  },
  inventorySegments {
    id
    dspId
    name
    pathKey
    segmentGroup
    segmentPackageId
    segmentType
  },
  ctvSegments,
  creatives {
    id
    updatedAt
    creativeType
    web
    ctv
    displayTargeting
    archived
  },
  createdAt,
  updatedAt,
  campaignBudget,
`;
