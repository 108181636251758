import { polygon } from '@turf/helpers';
import intersect from '@turf/intersect';
import area from '@turf/area';
import booleanWithin from '@turf/boolean-within';
import booleanIntersects from '@turf/boolean-intersects';
import bboxPolygon from '@turf/bbox-polygon';
import Leaflet from 'leaflet';
import { IMPRESSIONS } from './constants';

export const multiBooleanIntersect = (parent, child) => {
  const initIntersects = booleanIntersects(bboxPolygon(parent.bbox), bboxPolygon(child.bbox));

  if (!initIntersects) return false;

  const [[maskShape]] = parent.geometry.coordinates || [];
  const childArea = area(child) * 0.01;

  const maskPoly = polygon([maskShape]);
  const [innerPoly] = child.geometry?.coordinates || [];
  const shapePolys = innerPoly.filter((_itm, idx) => !(idx % 2));

  let resp = false;

  for (const coords of shapePolys) {
    try {
      if (booleanWithin(polygon([coords]), maskPoly)) {
        resp = true;
      } else {
        const inter = intersect(polygon([coords]), maskPoly);

        if (inter && area(inter) > childArea) resp = true;
      }
    } catch {
      console.log('errOn', child?.properties?.name);
    }
  }

  return resp;
};

export const bboxToBounds = bb => {
  const sw = Leaflet.latLng(bb[1], bb[0]);
  const ne = Leaflet.latLng(bb[3], bb[2]);

  return Leaflet.latLngBounds(sw, ne);
};

export const calcMax = (geoJson, segmentType, key = IMPRESSIONS) => {
  const arr = geoJson.features || [geoJson];
  const max = Math.max(
    ...arr.map(f => {
      const { segmentGroup, metrics = {} } = f?.properties || {};

      if (segmentGroup !== segmentType && !['HeatmapRegion', 'Zip'].includes(segmentGroup)) return 0;

      return metrics[key] || 0;
    })
  );

  return max;
};

export const setHeatmapColor = value => {
  const root = document.documentElement;
  const color = value?.color || '#374151';

  root.style.setProperty(`--heatmap-color`, color);
};

export const fitLayer = (map, layerBounds, sidebarAdjust) => {
  if (!layerBounds) return;

  const bounds = layerBounds.pad(0.04);
  const left = sidebarAdjust ? 330 : 0;

  // map.flyToBounds(bounds, { paddingTopLeft: [left, 100] });
  map.fitBounds(bounds, { paddingTopLeft: [left, 100] });
};

export const getMapS3Key = campaign => {
  const { id, brandId, teamId, organizationId } = campaign || {};

  return id ? `account-private/${organizationId}/${teamId}/${brandId}/campaigns/${id}/maps2` : false;
};
