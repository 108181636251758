import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppParams } from 'src/hooks/useAppParams';
import { buildAppPath } from 'src/helpers/app/buildAppPath';
import { CampaignAnalytics } from 'src/components/CampaignDetails/CampaignAnalytics';

export default function CampaignPage() {
  const history = useHistory();
  const appParams = useAppParams();

  const setAppRoute = useCallback(
    paramsInput => {
      const route = buildAppPath(appParams, paramsInput);

      if (paramsInput.replace) {
        history.replace(route);
      } else {
        history.push(route);
      }
    },
    [appParams, history]
  );

  return <CampaignAnalytics appParams={appParams} setAppRoute={setAppRoute} campaign={appParams.campaign} />;
}
