import React from 'react';
import { SummaryItem } from './SummaryItem';
import { DiffValue } from './DiffValue';
import { SummaryHeader } from './SummaryHeader';
import { RENAMED_GOALS } from '../../../constants/goals';

export const CampaignBasics = ({ campaign, campaignDraft, appParams }) => {
  const { isRecurring, recurrenceWindow } = campaignDraft;
  const budget = campaignDraft.budget || 0;
  const brandCredits = appParams?.brandCredits || 0;
  const campaignCredits = appParams?.campaignCredits || 0;
  const remainingBudget = budget + campaignCredits;
  const remainingCredits = brandCredits - remainingBudget;
  const overBudget = remainingCredits < 0 && !isRecurring;
  const remainingStyle = overBudget ? 'text-red-500' : 'text-green-500';
  let recurBudgetTitle = 'Weekly Budget';
  // let budgetFieldKey = 'budget';

  if (recurrenceWindow === 30) recurBudgetTitle = '30 Day Budget';
  // if (isRecurring) budgetFieldKey = 'recurrenceBudget';

  return (
    <>
      <SummaryHeader panelKey="Basics">Basics</SummaryHeader>
      <div className="flex flex-col space-y-1 pl-4">
        <SummaryItem title="Goal">
          <DiffValue
            campaign={campaign}
            campaignDraft={campaignDraft}
            fieldKey="goal"
            defaultValue={RENAMED_GOALS.impressions}
          />
        </SummaryItem>
        <SummaryItem title={isRecurring ? `${recurBudgetTitle}` : 'Budget'}>
          <DiffValue
            className={remainingStyle}
            campaign={campaign}
            campaignDraft={campaignDraft}
            appParams={appParams}
            fieldKey={!isRecurring ? 'budget' : 'recurrenceBudget'}
            defaultValue={0}
          />
        </SummaryItem>
        <SummaryItem title="Start">
          <DiffValue campaign={campaign} campaignDraft={campaignDraft} fieldKey="startDate" />
        </SummaryItem>
        {!isRecurring && (
          <SummaryItem title="End">
            <DiffValue campaign={campaign} campaignDraft={campaignDraft} fieldKey="endDate" />
          </SummaryItem>
        )}
      </div>
    </>
  );
};
