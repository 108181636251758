import { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';

export default function SignOutPage() {
  const history = useHistory();

  useEffect(() => {
    Auth.signOut().then(history.push('/'));
  }, [history]);

  return <div />;
}
