import React, { useState, useEffect } from 'react';
import { UrlPrefixDropdown } from 'src/components/UrlPrefixDropdown';

export const WebsiteField = ({ value, update }) => {
  const [prefix, setPrefix] = useState('https://');
  const [urlNoPrefix, setUrlNoPrefix] = useState(value);

  useEffect(() => {
    // Replaces all occurences of any protocol ending in :// preceeding the value
    const val = urlNoPrefix.replace(/(\w+:|^)\/\/+/, '');

    setUrlNoPrefix(val);
    update(`${prefix}${urlNoPrefix}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlNoPrefix]);

  return (
    <div>
      <label htmlFor="Brand Website" className="w-full">
        <p className="pb-1 font-medium text-gray-600">Brand Website</p>

        <div className="flex space-x-2">
          <UrlPrefixDropdown update={update} prefix={prefix} setPrefix={setPrefix} urlNoPrefix={urlNoPrefix} />
          <input
            className="bz-input h-12 placeholder-gray-400"
            value={urlNoPrefix}
            placeholder="example.com"
            onChange={({ target: { value: v } = {} }) => setUrlNoPrefix(v)}
          />
        </div>
      </label>
    </div>
  );
};
