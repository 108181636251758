import { Logo } from 'src/svgs';
import { Spinner } from 'src/components/shared/Spinner';

export const AuthContainer = ({ children, loading, logo, isWhiteLabel, 'data-testid': testId }) => (
  <div className="flex w-192 h-full items-center justify-center" data-testid={testId} data-mf-replace="">
    <div className="flex flex-col items-center w-full space-y-12 max-w-lg p-4 py-12 bg-white rounded-lg shadow-lg">
      {logo && (
        <div className="rounded-full bg-white w-32 h-32 flex items-center justify-center">
          <img alt="logo" src={logo} className="w-auto h-auto" />
        </div>
      )}
      {!logo && !isWhiteLabel && (
        <div className="flex justify-center items-center space-x-4 h-16">
          <Logo className="h-12" fill="var(--primary)" />
        </div>
      )}
      {loading ? <Spinner /> : children}
    </div>
  </div>
);
