import React from 'react';
import { industryCategories } from 'src/constants';
import { ElectionSettings } from './ElectionSettings';

export const AdditionalSettings = ({ brandDraft, updateField, indCatId }) => {
  const catId = brandDraft?.industryCategoryId || indCatId;
  const categoryIdMap = industryCategories.reduce((obj, itm) => ({ ...obj, [itm.value]: itm.text }), {});
  const cat = categoryIdMap[catId];

  const settingsObj = {
    'Elections (Candidates and Issues)': <ElectionSettings brandDraft={brandDraft} updateField={updateField} />,
  };

  if (!cat || !Object.keys(settingsObj).includes(cat)) return <div />;

  const additionalSettings = settingsObj[cat] || <div />;

  return (
    <div className="flex-1 flex flex-col space-y-3 bg-gray-100 mt-10 p-5">
      <div className="flex items-center space-x-2">
        <h1 className="text-lg font-semibold">Additional Settings</h1>
        <h1 className="text-lg">|</h1>
        <span className="text-lg text-gray-600 pr-4">{cat}</span>
      </div>
      {additionalSettings}
    </div>
  );
};
