const toDate = dateStr => (dateStr ? new Date(dateStr) : null);

export const selectStartDate = campaignOrDraft => toDate(campaignOrDraft.startDate);

export const selectEndDate = campaignOrDraft => toDate(campaignOrDraft.endDate);

const selectIsRecurring = campaignOrDraft => campaignOrDraft?.isRecurring || false;

export const selectDatesValidForPublish = campaignOrDraft => {
  const isRecurring = selectIsRecurring(campaignOrDraft);
  const startDate = selectStartDate(campaignOrDraft);
  const endDate = selectEndDate(campaignOrDraft);

  if (isRecurring) {
    return !!startDate;
  }

  return startDate && endDate && startDate < endDate;
};

export const selectDatesValidForSave = selectDatesValidForPublish;
