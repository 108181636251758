import { API } from 'aws-amplify';
import { creativeFields } from './creativeFields';
import { putAppEvent } from '../putAppEvent';

const createCreativeQuery = /* GraphQL */ `
  mutation CreateCreative($input: CreateCreativeInput!) {
    createCreative(input: $input) {
      ${creativeFields}
    }
  }
`;

const cleanCreativeInput = creative => {
  const { archived: _archived, dspIds: _dspIds, campaign: _campaign, ...rest } = creative;

  return rest;
};

export const createCreative = async input =>
  API.graphql({
    query: createCreativeQuery,
    variables: { input: cleanCreativeInput(input) },
  })
    .then(({ data: { createCreative: resp } = {} }) => resp || false)
    .then(resp => {
      putAppEvent('CreativeMutation', input);

      return resp;
    });
