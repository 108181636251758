import { useMemo } from 'react';
import { maxBy } from 'lodash';
import { ActionsColumn } from './CampaignTable/ActionsColumn';
import { AudienceColumn } from './CampaignTable/AudienceColumn';
import { BudgetColumn } from './CampaignTable/BudgetColumn';
import { ClicksColumn } from './CampaignTable/ClicksColumn';
import { ConversionsColumn } from './CampaignTable/ConversionsColumn';
import { CPMColumn } from './CampaignTable/CPMColumn';
import { CreativesColumn } from './CampaignTable/CreativesColumn';
import { CTRColumn } from './CampaignTable/CTRColumn';
import { FlightDatesColumn } from './CampaignTable/FlightDatesColumn';
import { GeoColumn } from './CampaignTable/GeoColumn';
import { GoalColumn } from './CampaignTable/GoalColumn';
import { ImpressionsColumn } from './CampaignTable/ImpressionsColumn';
import { InventoryColumn } from './CampaignTable/InventoryColumn';
import { NameColumn } from './CampaignTable/NameColumn';
import { ReportsColumn } from './CampaignTable/ReportsColumn';
import { SpendColumn } from './CampaignTable/SpendColumn';
import { SpendPercentColumn } from './CampaignTable/SpendPercentColumn';
import { StatusColumn } from './CampaignTable/StatusColumn';
import { COLUMNS } from './ColumnSelector';

const SETUP_COLUMNS = [
  NameColumn,
  ActionsColumn,
  StatusColumn,
  FlightDatesColumn,
  BudgetColumn,
  GoalColumn,
  AudienceColumn,
  GeoColumn,
  InventoryColumn,
  CreativesColumn,
];

const DELIVERY_COLUMNS = [
  NameColumn,
  StatusColumn,
  SpendColumn,
  SpendPercentColumn,
  ImpressionsColumn,
  CPMColumn,
  ClicksColumn,
  CTRColumn,
];

const PERF_COLUMNS = [
  NameColumn,
  StatusColumn,
  FlightDatesColumn,
  CPMColumn,
  CTRColumn,
  ConversionsColumn,
  ReportsColumn,
];

const CONFIG = {
  [COLUMNS.SETUP]: SETUP_COLUMNS,
  [COLUMNS.DELIVERY]: DELIVERY_COLUMNS,
  [COLUMNS.PERFORMANCE]: PERF_COLUMNS,
};

export const CampaignTable = ({ campaigns, columns = COLUMNS.SETUP }) => {
  const columnsToRender = CONFIG[columns];

  const rows = useMemo(
    () =>
      campaigns.map(campaign => {
        const metrics = (campaign?.summaryMetrics?.items || []).find(obj => obj.summaryType === 'ALL_TIME') || {};

        return {
          campaign,
          metrics,
        };
      }),
    [campaigns]
  );

  const metadata = useMemo(() => {
    const maxCampaignName = maxBy(campaigns, 'name.length');

    return {
      maxCampaignName,
    };
  }, [campaigns]);

  return (
    <div className="w-full rounded overflow-scroll force-scrollbar h-full max-h-screen-calc">
      <table className="table-fixed w-full rounded-md">
        <thead>
          <tr className="h-12 border-b text-lg font-medium sticky z-10 top-0 bg-white">
            {columnsToRender.map(({ key, header: Header }) => (
              <Header key={key} metadata={metadata} />
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map(({ campaign, metrics }) => (
            <tr
              key={campaign.id}
              className="group w-full h-16 bg-white border-b last-of-type:border-b-0 hover:bg-blueGray-50"
            >
              {columnsToRender.map(({ key, cell: Cell }) => (
                <Cell key={key} campaign={campaign} metrics={metrics} />
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
