import { useCallback, useState } from 'react';
import { useModal } from 'src/contexts';
import { useGAS } from 'src/GlobalAppState/context';
import { useIfMounted } from 'src/hooks/useIfMounted';
import { selectCampaignValidForPublish } from 'src/selectors/campaign/summarySelectors';
import { useOrganizationMinBudget } from 'src/helpers/organization/useOrganizationMinBudget';
import { useCreativesContext } from '../../CreativesContext';

const HOUR = 1_000 * 60 * 60;
const DAY = 24 * HOUR;

const useClickHandler = () => {
  const { openModal } = useModal();
  const { campaign } = useGAS();
  const [forceDisableForecast, setForceDisableForecast] = useState(false);

  const disableForecastCallback = useCallback(() => {
    setForceDisableForecast(true);
  }, [setForceDisableForecast]);

  // Casting this in case it gets invoked after the user navigates
  const safeDisableForecastCallback = useIfMounted(disableForecastCallback);

  const onClick = useCallback(() => {
    openModal('request-forecast', {
      campaignId: campaign.id,
      requestedAt: campaign.forecast?.requestedAt,
      callback: safeDisableForecastCallback,
    });
  }, [campaign.forecast?.requestedAt, campaign.id, openModal, safeDisableForecastCallback]);

  return {
    onClick,
    forceDisableForecast,
  };
};

export const RequestForecastButton = ({ campaignDraft, draftChanged }) => {
  const creatives = useCreativesContext();
  const { campaign, appRouteParams } = useGAS();
  const orgMinimum = useOrganizationMinBudget();
  const campaignValidForPublish = selectCampaignValidForPublish(campaignDraft, campaign, { orgMinimum });
  const isNew = appRouteParams.page === 'new-campaign';
  const forecastRequestedAt = campaignDraft?.forecastRequestedAt || campaignDraft?.forecast?.requestedAt || 0;
  const forecastExpired = new Date() - new Date(forecastRequestedAt) > DAY;

  const { onClick, forceDisableForecast } = useClickHandler();

  return (
    <button
      type="button"
      disabled={
        !campaignValidForPublish ||
        !creatives.length ||
        draftChanged ||
        forceDisableForecast ||
        isNew ||
        !forecastExpired
      }
      onClick={onClick}
      className="bz-btn-solid w-full h-12 2xl:h-16 disabled:cursor-default hover:opacity-80 disabled:opacity-40 rounded"
    >
      Request a Forecast
    </button>
  );
};
