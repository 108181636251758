import { useState, useCallback } from 'react';
import { Radio } from 'src/components/shared/Radio';

const SETUP = {
  key: 'setup',
  text: 'Setup',
};

const DELIVERY = {
  key: 'delivery',
  text: 'Delivery',
};

const PERFORMANCE = {
  key: 'performance',
  text: 'Performance',
};

export const COLUMNS = {
  SETUP: SETUP.key,
  DELIVERY: DELIVERY.key,
  PERFORMANCE: PERFORMANCE.key,
};

const ORDERED_COLUMNS_SETS = [SETUP, DELIVERY, PERFORMANCE];

export const useColumnSets = () => {
  const [activeColumn, setColumn] = useState(SETUP);

  const setter = useCallback(columnKey => {
    const column = ORDERED_COLUMNS_SETS.find(col => col.key === columnKey) ?? SETUP;

    setColumn(column);
  }, []);

  return [activeColumn.key, setter];
};

export const ColumnSelector = ({ columns, setActiveColumns }) => {
  const onSelect = useCallback(
    key => {
      setActiveColumns(key);
    },
    [setActiveColumns]
  );

  return (
    <div className="flex space-x-4 items-center">
      <span className="font-medium text-sm text-gray-500">Columns:</span>
      {ORDERED_COLUMNS_SETS.map(col => (
        <label key={col.key} className="flex items-center flex-row space-x-2">
          <Radio name="column" checked={col.key === columns} value={col.key} onChange={e => onSelect(e.target.value)} />
          <span className="text-sm text-gray-500">{col.text}</span>
        </label>
      ))}
    </div>
  );
};
