import { getTeamBrands } from 'src/helpers/team/getTeamBrands';
import { identity } from 'lodash';
import { getCsvMetrics } from './getCsvMetrics';
import { getTeamCampaigns } from '../../helpers/team/getTeamCampaigns';
import { getTrackingTagsByTeamId } from '../../helpers/team/getTeamTrackingTags';
import {
  aggregateMetricsBy,
  collectUniqueCampaignConversionEventsDspIds,
  createBrandDspIdsToTrackingTagNameMap,
  createBrandTrackingTagIdTtdIdMap,
  createUniversalPixelDspIdsToTrackingTagNameMap,
  makeSorter,
} from './helpers';

const buildMetricsQueryProcessor =
  (aggregateFn, sorter = identity) =>
  async (ids, options) => {
    const [brands, trackingTags, campaigns, metrics] = await Promise.all([
      getTeamBrands({ teamId: ids.teamId }),
      getTrackingTagsByTeamId({ teamId: ids.teamId }),
      getTeamCampaigns({ teamId: ids.teamId }),
      getCsvMetrics(ids, 'CAMPAIGN_DAILY').then(resp => aggregateFn(resp.items, options)),
    ]);

    const brandTrackingTagIdTtdIdMap = createBrandTrackingTagIdTtdIdMap(trackingTags);
    const brandDspIdsToTrackingTagName = createBrandDspIdsToTrackingTagNameMap(trackingTags);

    const universalPixelDspIdsToTrackingTagName = brands.reduce((acc, brand) => {
      const brandMap = createUniversalPixelDspIdsToTrackingTagNameMap(brand);

      return { ...acc, ...brandMap };
    }, {});

    const dspIdsToTrackingTagName = {
      ...brandDspIdsToTrackingTagName,
      ...universalPixelDspIdsToTrackingTagName,
    };

    const uniqueCampaignConversionEventDspIds = collectUniqueCampaignConversionEventsDspIds(
      campaigns,
      brandTrackingTagIdTtdIdMap
    );

    uniqueCampaignConversionEventDspIds.forEach(dspId => {
      dspIdsToTrackingTagName[dspId] = dspIdsToTrackingTagName[dspId] || dspId;
    });

    const campaignMap = campaigns.reduce((acc, campaign) => Object.assign(acc, { [campaign.id]: campaign }), {});
    const brandsById = brands.reduce((acc, brand) => Object.assign(acc, { [brand.id]: brand }), {});

    metrics.map(metric => Object.assign(metric, { brandName: brandsById[metric.brandId]?.name }));

    const ttdIdsInMetrics = [];

    const reportMetrics = metrics.reduce((acc, metric) => {
      acc.push(Object.assign(metric, { brandName: brandsById[metric.brandId]?.name }));

      if (metric.conversionBreakdown) {
        ttdIdsInMetrics.push(...Object.keys(metric.conversionBreakdown));

        const campaign = campaignMap[metric.campaignId];

        const campaignConversionEventTtdIds = campaign?.conversionEvents?.map(
          conversionEvent => brandTrackingTagIdTtdIdMap[conversionEvent.id]
        );

        campaignConversionEventTtdIds?.forEach(ttdId => {
          if (!metric.conversionBreakdown[ttdId]) {
            metric.conversionBreakdown[ttdId] = 0;
          }
        });

        Object.keys(metric.conversionBreakdown).forEach(ttdId => {
          const name = dspIdsToTrackingTagName[ttdId] || ttdId;

          metric[name] = metric.conversionBreakdown[ttdId];
        });

        delete metric.conversionBreakdown;
      }

      return acc;
    }, []);

    const additionalHeadersId = [...new Set([...uniqueCampaignConversionEventDspIds, ...ttdIdsInMetrics])];
    const additionalHeaders = additionalHeadersId.map(id => dspIdsToTrackingTagName[id] || id);

    const sortedMetrics = sorter(reportMetrics);

    sortedMetrics.additionalHeaders = additionalHeaders;

    return sortedMetrics;
  };

const dateSort = ['date', 'desc'];
const brandNameSort = [metric => metric.brandName.toLowerCase(), 'asc'];

export const getBrandSummaryMetrics = buildMetricsQueryProcessor(
  aggregateMetricsBy('brandId'),
  makeSorter(brandNameSort)
);

export const getBrandDailyMetrics = buildMetricsQueryProcessor(
  aggregateMetricsBy('brandId', 'metricKey'),
  makeSorter(dateSort, brandNameSort)
);
