export const brandFields = /* GraphQL */ `
  id
  archived
  adminGroups
  viewerGroups
  createdAt
  updatedAt
  organizationId
  teamId
  advertiserId
  name
  websiteUrl
  logoUrl
  industryCategoryId
  underReview
  politicalSettings {
    politicalType
    electionType
    candidate {
      PoliticalDataId
      CandidateName
      ElectionState
      PayingEntityName
      PayingEntityAddress
      PayingEntityEmailAddress
      PayingEntityExecutiveName
      PayingEntityExecutiveTitle
      GeoDescription
      AudienceDescription
      DisclaimerNotice
      FileLocationInS3
      FederalVerificationId
      StateVerificationId
    }
    ballotMeasureProfile {
      PayingEntityName
      PayingEntityAddress
      PayingEntityExecutiveName
      PayingEntityExecutiveTitle
      ElectionState
    }
    status
  }
  availableCredits
  paymentMethods {
    items {
      stripeId
      nickname
      lastFour
      cardType
      default
    }
  }
  universalPixel {
    UniversalPixelMappings {
      ExactMatchMappings {
        Url
        HouseholdExtensionEnabled
        Revenue
        Currency
        TrackingTagId
        UniversalPixelMappingName
        UniversalPixelMappingType
        Description
        HitCount30Day
        FirstPartyDataId
      }
      WildcardMatchMappings {
        UrlPattern
        HouseholdExtensionEnabled
        Revenue
        Currency
        TrackingTagId
        UniversalPixelMappingName
        UniversalPixelMappingType
        Description
        HitCount30Day
        FirstPartyDataId
      }
    }
    UniversalPixelId
    UniversalPixelName
    AdvertiserId
  }
  pixelCode
  pixelTracking
  pixelAudienceSize
  pixelMappingSizes
  theme {
    primary
    secondary
    pallet
  }
  summaryMetrics {
    items {
      summaryType
      impressions
      clicks
      views
      spend
      conversions
      graphs {
        impressions
        clicks
        views
        spend
        conversions
      }
    }
    total
  }
  segmentPackages
  isValid
  error
  teamBrandsId
  defaultStripeId
`;
