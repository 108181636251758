import { generatePath } from 'react-router-dom';

export const buildAppPath = (appParams = {}, params = {}) => {
  const newParams = { ...appParams, ...params };

  let path = '/:model/:id';

  if (newParams.page) path += '/:page';

  return generatePath(path, newParams);
};
