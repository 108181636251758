import { useMemo } from 'react';
import { useGAS } from 'src/GlobalAppState/context';
import { ColumnCell, ColumnHeader } from './Components';

const AudienceHeader = () => {
  const { managedAudiences } = useGAS();

  return <ColumnHeader className={managedAudiences?.length ? 'w-48' : 'w-32'}>Audience</ColumnHeader>;
};

const AudienceCellContents = ({ campaign }) => {
  const { managedAudiences } = useGAS();

  const matchedAudience = useMemo(
    () => managedAudiences.find(audience => audience.id === campaign.audience),
    [campaign.audience, managedAudiences]
  );

  if (matchedAudience) {
    return (
      <div className="truncate" title={matchedAudience.name}>
        {matchedAudience.name}
      </div>
    );
  }

  if (campaign?.dmpSegments?.length) {
    return 'Custom';
  }

  return 'Everyone';
};

export const AudienceColumn = {
  key: 'audience',
  header: AudienceHeader,
  cell: ({ campaign }) => (
    <ColumnCell className="capitalize">
      <AudienceCellContents campaign={campaign} />
    </ColumnCell>
  ),
};
