import { useState, useEffect, useMemo, useCallback } from 'react';
import { PiFoldersLight } from 'react-icons/pi';
import { inventorySegments as inventorySegmentTypes } from 'src/constants';
import { ctvSegments as ctvSegmentTypes, ctvSegmentIds } from 'src/constants/ctvSegments';
import { audioSegments } from 'src/constants/audioSegments';
import { without } from 'lodash';
import { SelectAllButton } from './SelectAllButton';
import { CtvAudioRows } from './InventoryRow';
import { InventoryButton } from './InventoryButton';
import { BuilderPanel } from '../BuilderPanel';

export const InventoryTargeting = ({ appParams, campaignDraft, setCampaignDraft }) => {
  const [selectAll, setSelectAll] = useState(true);
  const draftIds = (campaignDraft.inventorySegments || []).map(d => d.id);
  const [targetingTab, setTargetingTab] = useState('display');
  const { campaign } = appParams;
  const allInventory = Object.entries(inventorySegmentTypes).map(([k, v]) => ({ id: v.id, name: k }));
  const isDisabled = campaign.status === 'completed';

  const [selectAllCtv, setSelectAllCtv] = useState(true);

  useEffect(() => {
    if (campaignDraft) {
      const segLength = campaignDraft.inventorySegments?.length || 0;

      if (segLength < allInventory.length) setSelectAll(false);
      if (segLength === allInventory.length) setSelectAll(true);
    }
  }, [allInventory.length, campaignDraft, draftIds]);

  useEffect(() => {
    if (campaignDraft) {
      const segLength = campaignDraft.ctvSegments?.length || 0;

      if (segLength < ctvSegmentTypes.length) setSelectAllCtv(false);
      if (segLength === ctvSegmentTypes.length) setSelectAllCtv(true);
    }
  }, [campaignDraft]);

  const selectedCtvIds = useMemo(
    () => (selectAllCtv ? ctvSegmentIds.slice() : campaignDraft.ctvSegments || []),
    [selectAllCtv, campaignDraft.ctvSegments]
  );

  const toggleSegment = segment => {
    const isSelected = draftIds.includes(segment.id);
    const updated = isSelected
      ? (campaignDraft.inventorySegments || []).filter(({ id }) => id && id !== segment.id)
      : [...(campaignDraft.inventorySegments || []), segment];

    setCampaignDraft({ ...campaignDraft, inventorySegments: updated });
  };

  const toggleCtvSegment = useCallback(
    ({ id }) => {
      const isSelected = selectedCtvIds.includes(id);
      const updated = isSelected ? without(selectedCtvIds, id) : selectedCtvIds.concat(id);

      setCampaignDraft({ ...campaignDraft, ctvSegments: updated });
    },
    [campaignDraft, selectedCtvIds, setCampaignDraft]
  );

  const inventoryButtons = Object.entries(inventorySegmentTypes).map(([k, v]) => (
    <InventoryButton
      key={v.id}
      name={k}
      icons={v.icons}
      onClick={() => toggleSegment({ id: v.id, name: k })}
      isSelected={draftIds.includes(v.id)}
      disabled={isDisabled}
    />
  ));
  const ctvButtons = ctvSegmentTypes.map(segment => (
    <InventoryButton
      key={segment.id}
      name={segment.name}
      icons={segment.icons}
      onClick={() => toggleCtvSegment(segment)}
      isSelected={selectedCtvIds.includes(segment.id)}
      disabled={isDisabled}
    />
  ));

  const audioRows = Object.entries(audioSegments).map(([k, v]) => <CtvAudioRows key={v.id} name={k} icons={v.icons} />);

  return (
    <BuilderPanel icon={PiFoldersLight} title="Categories">
      <div className="md:w-192 lg:w-full flex flex-col space-y-8">
        <div className="flex items-center justify-center">
          <h1 className="text-gray-400 text-lg">What sites and channels do you want to target?</h1>
        </div>
        <div className="flex justify-center">
          <TargetingSelector setTargetingTab={setTargetingTab} targetingTab={targetingTab} />
        </div>
        {targetingTab === 'display' && (
          <div>
            <SelectAllButton
              setCampaignDraft={setCampaignDraft}
              campaignDraft={campaignDraft}
              selectAll={selectAll}
              setSelectAll={setSelectAll}
              appParams={appParams}
              allInventory={allInventory}
              inventoryCampaignKey="inventorySegments"
            />
            <div className="text-sm sm:text-base text-right text-gray-400 -mt-8 mr-5 pb-3">
              * Icons are an example of available inventory.
            </div>
            <div className="flex w-full sm:px-4">
              <div className="flex flex-col w-full px-8 h-96 overflow-scroll rounded border border-gray-400">
                {inventoryButtons}
              </div>
            </div>
          </div>
        )}
        {targetingTab === 'ctv' && (
          <div>
            <SelectAllButton
              setCampaignDraft={setCampaignDraft}
              campaignDraft={campaignDraft}
              selectAll={selectAllCtv}
              setSelectAll={setSelectAllCtv}
              appParams={appParams}
              allInventory={ctvSegmentIds}
              inventoryCampaignKey="ctvSegments"
            />
            <div className="text-right text-gray-400 -mt-8 mr-5 pb-3 text-sm sm:text-base">
              * Icons are an example of available inventory.
            </div>
            <div className="flex w-full px-4">
              <div className="flex flex-col w-full px-8 h-56 overflow-scroll rounded border border-gray-400">
                {ctvButtons}
              </div>
            </div>
          </div>
        )}
        {targetingTab === 'audio' && <AudioList campaign={campaignDraft} audioRows={audioRows} />}
      </div>
    </BuilderPanel>
  );
};

export const TargetingSelector = ({ targetingTab, setTargetingTab }) => (
  <div className="w-full max-w-lg flex space-x-2">
    <TargetingButton active={targetingTab === 'display'} onClick={() => setTargetingTab('display')}>
      Display + Web Video
    </TargetingButton>
    <TargetingButton active={targetingTab === 'ctv'} onClick={() => setTargetingTab('ctv')}>
      CTV
    </TargetingButton>
    <TargetingButton active={targetingTab === 'audio'} onClick={() => setTargetingTab('audio')}>
      Audio
    </TargetingButton>
  </div>
);

const TargetingButton = ({ active, onClick, children }) => (
  <button
    type="button"
    onClick={onClick}
    className={`flex-1 text-primary h-10 ${
      active ? 'border-b-2 border-primary' : ' text-primary'
    } text-sm sm:text-base font-normal`}
  >
    {children}
  </button>
);

const AudioList = ({ campaign, audioRows }) => {
  const campName = campaign.name;

  return (
    <div className="pt-12">
      <div className="flex flex-col items-center justify-center text-gray-400 text-sm sm:text-lg -mt-12 pb-5 px-4">
        <div className="text-primary pl-1 flex items-center ">
          <span className="text-gray-400 mr-2">For </span>
          <span className={campName.length > 20 ? 'truncate w-40 sm:w-44' : ''}>{campName}</span>
          <span className="text-gray-400 ml-2">campaign,</span>
        </div>
        <span className="mt-2 sm:mt-0 text-center"> your inventory will run on platforms like these:</span>
      </div>
      <div className="flex justify-center ml-24">
        <div className="grid grid-cols-3 gap-4">
          {audioRows.map((data, idx) => (
            <div className="w-48" key={`${idx}`}>
              {data}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
