import { useCallback, useMemo, useRef } from 'react';
import { FiClipboard } from 'react-icons/fi';
import { useCopyToClipboard } from 'react-use';
import { animated, useSpring, useSpringRef } from 'react-spring';

const CodeBlock = ({ code }) => {
  const [_copyState, copyToClipboard] = useCopyToClipboard();
  const rotate = useRef(0);
  const springRef = useSpringRef();
  const style = useSpring({ rotate: rotate.current, ref: springRef });

  const copyCode = useCallback(() => {
    rotate.current = rotate.current > 0 ? 0 : 360;
    springRef.start({ rotate: rotate.current });

    copyToClipboard(code);
  }, [code, copyToClipboard, springRef]);

  return (
    <>
      <h1 className="py-4 font-medium text-gray-600">Place the following code in the header of your website</h1>
      <div className="relative max-w-full rounded bg-white border p-4">
        <pre className="h-48 text-sm text-black whitespace-pre-wrap overflow-x-auto max-w-full">{code}</pre>
        <button
          type="button"
          onClick={copyCode}
          className="rounded w-8 h-8 flex justify-center items-center bg-blue-600 absolute top-2 right-2 hover:bg-blue-400"
        >
          <animated.div style={style}>
            <FiClipboard className="w-5 h-5 stroke-current text-white" />
          </animated.div>
        </button>
      </div>
    </>
  );
};

// https://partner.thetradedesk.com/v3/portal/data/doc/TrackingTagsUniversalPixel
const genPixelCode = (AdvertiserId, UniversalPixelId) =>
  `
<script src="https://js.adsrvr.org/up_loader.1.1.0.js" type="text/javascript"></script>
<script type="text/javascript">
    ttd_dom_ready( function() {
        if (typeof TTDUniversalPixelApi === 'function') {
            var universalPixelApi = new TTDUniversalPixelApi();
            universalPixelApi.init("${AdvertiserId}", ["${UniversalPixelId}"], "https://insight.adsrvr.org/track/up");
        }
    });
</script>
`.trim();

const usePixelCode = brand =>
  useMemo(() => {
    const { AdvertiserId, UniversalPixelId } = brand?.universalPixel ?? {};

    if (AdvertiserId && UniversalPixelId) {
      return genPixelCode(AdvertiserId, UniversalPixelId);
    }

    return brand?.pixelCode ?? '';
  }, [brand]);

export const PixelCodeBlock = ({ brand }) => {
  const code = usePixelCode(brand);

  return (
    <div className="w-full space-y-3 items-center justify-center">
      <CodeBlock code={code} />
    </div>
  );
};
