import { useMemo } from 'react';

const getDefaultTheme = () => ({
  primary: '#f06290',
  secondary: '#03012B',
  pallet: 'The Blues',
});

const getCurrentTheme = ({ orgTheme, teamTheme }, appRouteParams) => {
  switch (appRouteParams.model) {
    case 'campaign':
    case 'brand':
    case 'team':
      return teamTheme;
    default:
      return orgTheme;
  }
};

const getActiveThemes = (organization, team) => {
  const { accountType } = organization?.accountSettings ?? {};
  const orgTheme = organization?.theme || getDefaultTheme();
  const teamTheme = team?.theme || orgTheme;

  switch (accountType) {
    case 'brand':
    case 'team':
      return {
        orgTheme: teamTheme,
        teamTheme,
      };
    default:
      return {
        orgTheme,
        teamTheme,
      };
  }
};

export const useThemes = (organization, team, appRouteParams) =>
  useMemo(() => {
    const activeThemes = getActiveThemes(organization, team);

    const currentTheme = getCurrentTheme(activeThemes, appRouteParams);

    return {
      ...activeThemes,
      theme: currentTheme,
    };
  }, [appRouteParams, organization, team]);
