import React, { useState } from 'react';
import { API } from 'aws-amplify';
import { sendInviteLink } from 'src/graphql/mutations';

const fields = /* GraphQL */ `
  {
    id
    brandId
    teamId
    organizationId
    userId
    email
  }
`;

const deleteUserAuthRole = /* GraphQL */ `
  mutation DeleteUserAuthRole($input: DeleteUserAuthRoleInput!) {
    deleteUserAuthRole(input: $input) ${fields}
  }
`;

export const TeamUserRow = ({ userAuthRole, userId }) => {
  const { email, status, userId: uid } = userAuthRole;
  const [edit, setEdit] = useState(false);
  const [resent, setResent] = useState(false);
  const [action, setAction] = useState('');

  const onDelete = () => {
    setAction('deleting');
    API.graphql({
      query: deleteUserAuthRole,
      variables: { input: { id: userAuthRole.id } },
    });
  };

  const onResend = () => {
    const userAuthRoleId = userAuthRole.id;

    setResent(true);
    API.graphql({ query: sendInviteLink, variables: { userAuthRoleId } }).then(console.log);
  };

  const showRemove = status !== 'owner' && uid !== userId;
  const showResend = status === 'pending' && !resent;

  const form = edit ? (
    <tr className="text-lg">
      <td label="team-user-row" className="w-24 px-6 py-4" />
      <td data-mf-replace="">edit {email}</td>
      <td className="w-40 px-6 py-4">
        <div className="w-full flex items-center space-x-3">
          <span>{status}</span>
          {showResend && (
            <button type="button" onClick={onResend} className="text-sky-700 text-sm">
              (resend)
            </button>
          )}
          {resent && <span className="text-green-400">sent!</span>}
        </div>
      </td>
      <td>
        <button type="button" onClick={() => setEdit(!edit)} className="text-primary">
          edit
        </button>
      </td>
    </tr>
  ) : (
    <tr className="whitespace-nowrap text-md text-gray-500 ">
      <td label="team-user-spacer" className="w-24 px-6 py-4" />
      <td data-mf-replace="" className="px-6 py-4 w-full">
        {email}
      </td>
      <td className="w-40 px-6 py-4">
        <div className="w-full flex items-center space-x-3">
          <span>{action === 'deleting' ? 'removing...' : `${status}`}</span>
          {showResend && action !== 'deleting' && (
            <button type="button" onClick={onResend} className="text-sky-700 text-sm">
              (resend)
            </button>
          )}
          {resent && <span className="text-green-400">sent!</span>}
        </div>
      </td>
      <td className="px-16 py-4 text-right text-md font-medium">
        {showRemove && action !== 'deleting' && (
          <button type="button" onClick={onDelete} className="text-red-400">
            remove
          </button>
        )}
      </td>
    </tr>
  );

  return form;
};
