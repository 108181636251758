import { createAPIQueryHook, createAPIQuerySubscriptionHook } from 'src/hooks/useAPIQuery';
import { organizationFields } from './organizationFields';

const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    result: getOrganization(id: $id) {
      ${organizationFields}
      overrides {
        minimumCampaignBudget
      }
    }
  }
`;

const onUpdateOrganization = /* GraphQL */ `
  subscription OnUpdateOrganization($id: String!) {
    update: onUpdateOrganization(id: $id) {
      id
      overrides {
        minimumCampaignBudget
      }
    }
  }
`;

const queryHookArgs = {
  query: getOrganization,
};

const useOrganizationQuery = createAPIQueryHook(queryHookArgs);

const useOrganizationSubscription = createAPIQuerySubscriptionHook({
  ...queryHookArgs,
  subscription: onUpdateOrganization,
});

export const useOrganization = id => {
  const disable = !id;
  const { data: organization, subscriptionHandler } = useOrganizationQuery({ id }, { disable });

  useOrganizationSubscription({ id }, { subscriptionHandler, disable });

  return organization ?? {};
};
