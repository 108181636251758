const Container = ({ children }) => (
  <div className="w-full py-3 flex flex-col">
    <div className="w-full rounded shadow bg-white px-8 py-16 text-center flex flex-col items-center space-y-8 flex-1 justify-center">
      {children}
    </div>
  </div>
);

const Headline = ({ children }) => <h1 className="text-xl font-semibold">{children}</h1>;

const Message = ({ children }) => <p className="text-lg max-w-prose">{children}</p>;

export const NotStartedEmptyState = () => (
  <Container>
    <Headline>Campaign Not Live</Headline>
    <Message>
      After publishing your campaign, return here to track its performance. Note: Analytics may take up to 24 hours to
      appear on this dashboard.
    </Message>
  </Container>
);

export const NoDataYetEmptyState = () => (
  <Container>
    <Headline>No Campaign Data Yet</Headline>
    <Message>
      Rest assured your campaign is running, but it may take up to 24 hours after launch for analytics to appear on this
      dashboard.
    </Message>
  </Container>
);
