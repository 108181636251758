import { dailyAdgroupMinimums, adgroupDisplayNames } from 'src/constants/adgroups';
import NumberFormat from 'react-number-format';
import { BsExclamationTriangle } from 'react-icons/bs';

export const AdgroupsWarningPanel = ({ adgroups }) => (
  <div className="bg-orange-100 p-3 text-xs mt-2">
    <span className="flex">
      <BsExclamationTriangle /> <span className="pl-5 pt-1 -mt-1 font-semibold">Minimum Budget Warning</span>
    </span>
    <div className="px-8">
      <div className="pb-3">
        {adgroups.length > 1 ? `One or more of your channels'` : `Your channels'`} budget is below our recommended
        minimum.
      </div>
      {(adgroups || [])
        .filter(a => a.warning)
        .map(itm => (
          <div key={`${itm.adgroupType}-warn`}>
            <span>
              <span className="font-semibold">{adgroupDisplayNames[itm.adgroupType]}</span>:{' '}
              <span>
                <NumberFormat
                  value={Number(dailyAdgroupMinimums[itm.adgroupType])}
                  displayType="text"
                  className="text-gray-800"
                  prefix="$"
                  decimalScale={2}
                  fixedDecimalScale
                  thousandSeparator
                />
                /day
              </span>
            </span>
          </div>
        ))}
    </div>
  </div>
);
