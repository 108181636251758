import { Auth, Analytics } from 'aws-amplify';

const getAuthClaim = () =>
  Auth.currentAuthenticatedUser().then(
    ({ attributes: { email: userEmail } = {}, signInUserSession: { idToken: { payload } = {} } = {} }) => ({
      ...payload,
      userEmail,
    })
  );

export const putAppEvent = (type, event) => {
  if (process.env.REACT_APP_STAGE === 'production' || process.env.REACT_APP_STAGE === 'prodb') {
    getAuthClaim().then(({ userId, userEmail }) =>
      Analytics.record(
        {
          data: JSON.stringify({
            type,
            timestamp: new Date().toISOString(),
            userId,
            userEmail,
            event,
          }),
          streamName: process?.env?.REACT_APP_EVENT_STREAM_NAME,
        },
        'AWSKinesisFirehose'
      )
    );
  }
};
