import React from 'react';
import { CheckIcon } from 'src/svgs';

export const CheckBox = ({ isSelected, className = '' }) => (
  <div className={`pr-5 ${className}`}>
    {isSelected && (
      <div className="w-6 h-6 rounded border-2 border-primary bg-gray-100 flex justify-center items-center p-1">
        <CheckIcon className="stroke-current text-primary w-full" />
      </div>
    )}
    {!isSelected && <div className="w-6 h-6 rounded border border-primary flex justify-center items-center p-1" />}
  </div>
);
