import NumberFormat from 'react-number-format';
import { ColumnCell, ColumnHeader } from './Components';

export const SpendColumn = {
  key: 'spend',
  header: () => <ColumnHeader className="w-44">Spend</ColumnHeader>,
  cell: ({ metrics }) => (
    <ColumnCell>
      <NumberFormat
        className="text-lg text-gray-600"
        displayType="text"
        value={metrics.spend ?? 0}
        thousandSeparator
        fixedDecimalScale
        prefix="$"
        decimalScale={2}
      />
    </ColumnCell>
  ),
};
