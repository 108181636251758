import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { getSelectedAuthS3Ids } from '../auth/getSelectedAuthS3Ids';
import { getAuthModel } from '../auth/getAuthModel';

const validModels = ['team', 'brand', 'campaign'];

const getParams = pathname => {
  const components = pathname.split('/').filter(Boolean);

  if (components[0] === 'finance') {
    components.shift();
  }

  const [model, id, page] = components;

  return { model, id, page };
};

export const useAppRouteParams = userAuth => {
  const location = useLocation();

  const { selectedAuth, selectedAuthS3Prefix } = userAuth || {};

  return useMemo(() => {
    const { model, id, page } = getParams(location.pathname);

    const params = {
      appState: 'build',
    };

    const modelValid = validModels.includes(model);

    if (modelValid) {
      params.model = model;
    } else {
      params.model = getAuthModel(selectedAuth, selectedAuthS3Prefix);
    }

    if (modelValid && id) {
      params.id = id;
    } else {
      const { organizationId, teamId, brandId } = getSelectedAuthS3Ids(selectedAuthS3Prefix);

      params.id = brandId || teamId || organizationId;
    }

    if (modelValid && page) {
      params.page = page;
    }

    return params;
  }, [location, selectedAuth, selectedAuthS3Prefix]);
};
