import { format } from 'date-fns';
import {
  SELECT_RANGE_ALL,
  SELECT_RANGE_DISABLED,
  SELECT_RANGE_END,
  SELECT_START_DISABLED,
} from './campaignDatePickerVariants';

const formatDate = date => format(date, 'MM/dd/yyyy') || '';

const baseButtonClasses =
  'w-full flex items-center px-2 sm:px-4 py-2 border-2 border-gray-200 rounded text-gray-800 disabled:cursor-default';

const getPickerButtonClasses = disabled => {
  const variableClasses = disabled ? 'bg-gray-100' : 'hover:text-blue-500 hover:border-blueGray-300';

  return `${baseButtonClasses} ${variableClasses}`;
};

const getPickerDateTextClasses = (disabled, highlight) => {
  if (disabled) {
    return 'text-gray-500 font-medium';
  }

  return `${highlight ? 'text-blue-500' : 'text-current'} font-semibold`;
};

export const CampaignDatePickerButton = ({ startDate, endDate, toggle, variant, open, settingEndDate }) => {
  const allDisabled = [SELECT_RANGE_DISABLED, SELECT_START_DISABLED].includes(variant);
  const partiallyDisabled = allDisabled || variant === SELECT_RANGE_END;
  const highlightStart = open && !settingEndDate;
  const highlightEnd = open && (settingEndDate || variant === SELECT_RANGE_END);
  const showEndDate = [SELECT_RANGE_ALL, SELECT_RANGE_DISABLED, SELECT_RANGE_END].includes(variant);

  const buttonClasses = getPickerButtonClasses(allDisabled);
  const startDateClasses = getPickerDateTextClasses(partiallyDisabled, highlightStart);
  const dashClasses = getPickerDateTextClasses(allDisabled);
  const endDateClasses = getPickerDateTextClasses(allDisabled, highlightEnd);

  return (
    <button
      type="button"
      onClick={() => {
        // eslint-disable-next-line no-unused-expressions
        !allDisabled && toggle();
      }}
      disabled={allDisabled}
      className={buttonClasses}
      data-testid="dateButtonV2"
    >
      <h1 className="flex-1 text-left text-sm sm:text-base block flex flex-row space-x-2 justify-between">
        <span className={startDateClasses}>{formatDate(startDate)}</span>
        {showEndDate && (
          <>
            <span className={dashClasses}>-</span>
            <span className={endDateClasses}>{formatDate(endDate)}</span>
          </>
        )}
      </h1>
    </button>
  );
};
