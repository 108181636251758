import {
  Blues,
  Autumn,
  Pride,
  Valentines,
  Snowfall,
  Sunrise,
  Aqua,
  Hallows,
  Neon,
  Sunset,
  LifesLemons,
  VintageFlame,
  Earth,
  FinalFrontier,
  Summertime,
} from 'src/svgs/colorPalletIcons';

export const colorPallets = {
  blue: ['#295AEF', '#00347E', '#85A4FF', '#00C7E9', '#295AEF'],
  pink: ['#FF5290', '#5D57AC', '#04002D', '#E7E6FF', '#FF5290'],
  autumn: ['#DE7917', '#A75200', '#FF7200', '#FFD000', '#FF3F1E'],
  green: ['#606C38', '#283618', '#A4AC86', '#DDA15E', '#BC6C25'],
};

export const bzColorPallets = {
  'The Blues': {
    name: 'The Blues',
    icon: Blues,
    colors: ['#5072dd', '#e3eeff', '#bdd3f9', '#9fbaf7', '#2948cc', '#7798ed'],
  },
  Autumn: {
    name: 'Autumn',
    icon: Autumn,
    colors: ['#a3a901', '#feecd4', '#f89f05', '#e36005', '#f2d670', '#d04711'],
  },
  Pride: {
    name: 'Pride',
    icon: Pride,
    colors: ['#0000f9', '#ffa52c', '#ffff41', '#ff0018', '#86007d', '#ff0018'],
  },
  "Valentine's": {
    name: "Valentine's",
    icon: Valentines,
    colors: ['#660202', '#fc6c85', '#38040e', '#c21d5a', '#922a46', '#b32638'],
  },
  Snowfall: {
    name: 'Snowfall',
    icon: Snowfall,
    colors: ['#d9d8df', '#e4c2c7', '#b2a7bb', '#f7d7d4', '#b2a7bb', '#b0c2d7'],
  },
  Sunrise: {
    name: 'Sunrise',
    icon: Sunrise,
    colors: ['#b5728e', '#fff2bd', '#da7f7d', '#ebb58a', '#f4d797', '#776e99'],
  },
  Aqua: {
    name: 'Aqua',
    icon: Aqua,
    colors: ['#3ce5cb', '#0f8ec0', '#1eb3c4', '#2dccde', '#0771b8', '#13d8ec'],
  },
  "Hallow's Eve": {
    name: "Hallow's Eve",
    icon: Hallows,
    colors: ['#e5552b', '#539417', '#83ae3d', '#61278d', '#3e1852', '#eb7721'],
  },
  Neon: {
    name: 'Neon',
    icon: Neon,
    colors: ['#52fb5b', '#faff19', '#48fffe', '#220efa', '#9929fb', '#e92b99'],
  },
  Sunset: {
    name: 'Sunset',
    icon: Sunset,
    colors: ['#ffe58a', '#50366f', '#bf3475', '#ffce61', '#1f214d', '#ee6c45'],
  },
  "Life's Lemon's": {
    name: "Life's Lemon's",
    icon: LifesLemons,
    colors: ['#f2d249', '#f0e367', '#fff7ad', '#ffdb00', '#f5ff83', '#ffea01'],
  },
  'Vintage Flame': {
    name: 'Vintage Flame',
    icon: VintageFlame,
    colors: ['#edcf12', '#dc3b18', '#f28f0a', '#edb605', '#bf1e33', '#f26417'],
  },
  Earth: {
    name: 'Earth',
    icon: Earth,
    colors: ['#dab05e', '#899165', '#c1b78e', '#ad8e69', '#384324', '#805b55'],
  },
  'Final Frontier': {
    name: 'Final Frontier',
    icon: FinalFrontier,
    colors: ['#1b264d', '#73ccf9', '#263b61', '#489bcf', '#000f2b', '#4a61a4'],
  },
  Summertime: {
    name: 'Summertime',
    icon: Summertime,
    colors: ['#3ab49d', '#fc77b1', '#d92b8e', '#90dfda', '#215a8e', '#f1e978'],
  },
};
