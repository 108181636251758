import { noop } from 'lodash';

export const ToggleButton = ({ onClick, enabled, disableToggle, textColor = 'text-primary', customBgClass }) => (
  <button
    type="button"
    className={`z-10 relative ${disableToggle ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}
    onClick={onClick}
  >
    {enabled ? (
      <div className="w-14 h-7 flex items-center border-2 border-gray-400 bg-gray-100 rounded-full p-1 shadow">
        <span className={`${textColor} ml-1`}>&#10003;</span>
      </div>
    ) : (
      <div className="w-14 h-7 flex items-center border-2 border-gray-400 bg-gray-100 rounded-full p-1 shadow">
        <span className={`${textColor} ml-7`}>&#x58;</span>
      </div>
    )}
    <div
      className={`ml-1 mt-1 absolute top-0 h-5 w-5 px-1 rounded-full shadow transform ${
        enabled ? customBgClass || 'bg-primary' : customBgClass
      } ${enabled && 'translate-x-7'}`}
    />
  </button>
);

const toggleBoxBaseClasses = 'w-8 h-3 relative flex items-center rounded-full shadow';

const toggleSliderBaseClasses =
  'absolute duration-300 left-0 h-4 w-4 rounded-full bg-current shadow transform scale-110 shadow';

const ToggleBox = ({ value }) => (
  <div className={`${toggleBoxBaseClasses} ${value ? 'bg-green-200' : 'bg-gray-300'}`}>
    <div className={`${toggleSliderBaseClasses} ${value ? 'bg-green-600 translate-x-4' : 'bg-gray-50'}`} />
  </div>
);

const ToggleLabel = ({ children }) => <p className="select-none">{children}</p>;

const baseToggleButtonClasses =
  'p-2 z-10 my-4 relative flex flex-row items-center space-x-4 disabled:opacity-50 hover:text-black';

/**
 * @param {{
 *  onChange?: (boolean) => void
 *  disabled?: boolean
 *  value?: boolean
 *  label?: string
 * }} props
 * @returns {React.Component}
 */
export const ToggleButtonV2 = ({ onChange = noop, disabled = false, value = false, label }) => (
  <button
    type="button"
    disabled={disabled}
    className={`${baseToggleButtonClasses} ${value ? 'text-blueGray-700' : 'text-gray-500'} ${
      disabled && 'pointer-events-none'
    }`}
    onClick={() => onChange(!value)}
  >
    <ToggleBox value={value} />
    {label && <ToggleLabel>{label}</ToggleLabel>}
  </button>
);
