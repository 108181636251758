import { useBrandTrackingTags } from 'src/helpers/brand/useBrandTrackingTags';
import { useUser } from 'src/helpers/user/useUser';
import { useRouteIds } from 'src/helpers/routes/useRouteIds';
import { useAccountSettings } from 'src/helpers/organization/useAccountSettings';
import { useOrganization } from 'src/helpers/organization/useOrganization';
import { useAppRouteParams } from 'src/helpers/routes/useAppRouteParams';
import { getAuthLevel } from 'src/helpers/auth/getAuthLevel';
import { useCredits } from 'src/helpers/organization/useCredits';
import { useTeam } from 'src/helpers/team/useTeam';
import { useBrand } from 'src/helpers/brand/useBrand';
import { useCampaign } from 'src/helpers/campaign/useCampaign';
import { useSegmentPackages } from 'src/helpers/organization/useSegmentPackages';
import { useThemes } from 'src/helpers/organization/useThemes';
import { getDomainInfo } from 'src/helpers/whitelabel';
import { useBrandCampaigns } from 'src/helpers/brand/useBrandCampaigns';
import { useCampaignForecasts } from 'src/helpers/forecast/useCampaignForecasts';
import { useBrandCreativesSubscriptionAPIHook } from 'src/helpers/brand/useBrandCreativesSubscription';
import {
  useBrandPaymentMethods,
  useOrganizationPaymentMethods,
  useTeamPaymentMethods,
} from 'src/helpers/listPaymentMethodsHooks';
import { GASContext } from './context';
import { useUserAuth } from './UserAuth';
import { useTeamBrands } from '../helpers/team/useTeamBrands';
import { useManagedAudiences } from '../helpers/brand/useManagedAudiences';
import { useManagedSitelists } from '../helpers/brand/useManagedSitelists';
import { useStableObjectIdentity } from '../hooks/useStableObjectIdentity';
import { useOrganizationAuthRoles } from '../helpers/organization/useOrganizationAuthRoles';
import { useUserAuthRoles } from '../helpers/user/useUserAuthRoles';

const useGASHooks = () => {
  const { userAuth, loaded: userAuthLoaded } = useUserAuth();
  const appRouteParams = useAppRouteParams(userAuth);
  const user = useUser(userAuth);
  const { routeIds, loaded: routeIdsLoaded } = useRouteIds(userAuth, appRouteParams);
  const organization = useOrganization(routeIds.organizationId);
  const accountSettings = useAccountSettings(userAuth, organization);
  const credits = useCredits(routeIds);
  const brandTrackingTags = useBrandTrackingTags(routeIds);
  const team = useTeam(routeIds);
  const { teamBrands, loading: teamBrandsLoading, reload: reloadTeamBrands } = useTeamBrands(routeIds);
  const brand = useBrand(routeIds);
  const managedAudiences = useManagedAudiences(routeIds);
  const managedSitelists = useManagedSitelists(routeIds);
  const { brandCampaigns, loading: brandCampaignsLoading } = useBrandCampaigns(routeIds);
  const campaign = useCampaign(routeIds);
  const campaignForecasts = useCampaignForecasts(routeIds);
  const orgAuthRoles = useOrganizationAuthRoles(routeIds);
  const userAuthRoles = useUserAuthRoles(user);

  const organizationPaymentMethods = useOrganizationPaymentMethods(routeIds);
  const { paymentMethods: teamPaymentMethods, reload: reloadTeamPaymentMethods } = useTeamPaymentMethods(routeIds);
  const { paymentMethods: brandPaymentMethods, reload: reloadBrandPaymentMethods } = useBrandPaymentMethods(routeIds);

  const segmentPackages = useSegmentPackages(organization, team, brand);
  const themes = useThemes(organization, team, appRouteParams);

  useBrandCreativesSubscriptionAPIHook(routeIds);

  const gasValues = {
    appRouteParams,
    routeIds,
    routeIdsLoaded,
    authLevel: getAuthLevel(userAuth ?? {}, accountSettings ?? {}),
    isWhiteLabel: getDomainInfo().isWhiteLabel,
    accountSettings,
    userAuth,
    userAuthLoaded,
    user,
    organization,
    credits,
    brandTrackingTags,
    team,
    teamBrands,
    teamBrandsLoading,
    reloadTeamBrands,
    brand,
    brandCampaigns,
    managedAudiences,
    managedSitelists,
    brandCampaignsLoading,
    campaign,
    segmentPackages,
    ...themes,
    organizationPaymentMethods,
    teamPaymentMethods,
    reloadTeamPaymentMethods,
    brandPaymentMethods,
    reloadBrandPaymentMethods,
    campaignForecasts,
    orgAuthRoles,
    userAuthRoles,
  };

  return useStableObjectIdentity(gasValues, false);
};

export const GlobalAppState = ({ children }) => {
  const gas = useGASHooks();

  return <GASContext.Provider value={gas}>{children}</GASContext.Provider>;
};
