import { useCallback, useEffect, useMemo, useReducer, useDebugValue, useState, useRef } from 'react';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { get, isArray, mapValues, merge, pick, uniqueId, noop } from 'lodash';
import { useStableObjectIdentity } from './useStableObjectIdentity';

const logError = (message, ...args) => console.error(new Error(message), ...args);
const logErrorInfo = (...args) => console.info(...args);

const SUBSCRIPTION_ACTIONS = {
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
};

const SUBSCRIPTION_ACTIONS_LIST = Object.values(SUBSCRIPTION_ACTIONS);

const ActionTypes = {
  INIT: 'APIQUERY_INIT',
  LOAD_PAGE: 'APIQUERY_LOAD_PAGE',
  SUBSCRIPTION_UPDATE: 'APIQUERY_SUBSCRIPTION_UPDATE',
  COMPLETE: 'APIQUERY_COMPLETE',
};

const Actions = mapValues(ActionTypes, type => payload => ({
  type,
  payload,
}));

const getDefaultState = paged => ({
  loading: false,
  paged,
  data: null,
  errors: null,
  completed: false,
  pagesLoaded: 0,
  querySymbol: null,
});

const buildReducerResult = ({ paged, data }, { payload }) => {
  if (!paged) return payload;
  const currentData = new Map(data);

  if (!isArray(payload.data)) {
    logError('Paged query must return arrays to work correctly', { paged, data, payload });

    return payload;
  }

  payload.data.forEach(item => {
    if (!item.id) return logError('Paged query items must have IDs for indexing', { paged, data, payload, item });
    currentData.set(item.id, item);
  });

  return { data: currentData, errors: payload.errors };
};

const mergeReducerSubscriptionResult = ({ paged, data }, { payload: { method, value } }) => {
  if (!paged) {
    switch (method) {
      case SUBSCRIPTION_ACTIONS.CREATE:
        return value;
      case SUBSCRIPTION_ACTIONS.UPDATE:
        return {
          ...data,
          ...value,
        };
      case SUBSCRIPTION_ACTIONS.DELETE:
        return { data: null };
      default:
        return { data };
    }
  }

  const payloadData = value?.data || {};

  if (!payloadData.id) {
    logError('Without a subscription ID, cannot merge subscription data into state.');

    return { data };
  }

  if (!data) {
    console.warn('Subscription fired before initial query responded. Ignoring subscription update');

    return { data };
  }

  const currentData = new Map(data);
  const existingRecord = data.get(payloadData.id) ?? {};

  switch (method) {
    case SUBSCRIPTION_ACTIONS.CREATE:
      currentData.set(payloadData.id, payloadData);
      break;
    case SUBSCRIPTION_ACTIONS.UPDATE:
      currentData.set(payloadData.id, merge({}, existingRecord, payloadData));
      break;
    case SUBSCRIPTION_ACTIONS.DELETE:
      currentData.delete(payloadData.id);
      break;
    default:
      break;
  }

  return { data: currentData, errors: value.errors };
};

const rawReducer = (state, action) => {
  switch (action?.type) {
    case ActionTypes.INIT:
      return {
        ...state,
        data: null,
        loading: true,
        querySymbol: action.payload,
      };
    case ActionTypes.LOAD_PAGE:
    case ActionTypes.COMPLETE:
      return {
        ...state,
        loading: false,
        completed: action.type === ActionTypes.COMPLETE,
        pagesLoaded: state.pagesLoaded + 1,
        ...buildReducerResult(state, action),
      };
    case ActionTypes.SUBSCRIPTION_UPDATE:
      return {
        ...state,
        ...mergeReducerSubscriptionResult(state, action),
      };
    default:
      return state;
  }
};

const getReducer = ({ debug = false }) => {
  if (debug) {
    return (state, action) => {
      const result = rawReducer(state, action);

      console.debug('debug', { action, prevState: state, state: result });

      return result;
    };
  }

  return rawReducer;
};

const issueQuery = async (args, resultsGetter, nextGetter, totalGetter) => {
  let data;
  let nextToken;
  let total;
  let errors;

  try {
    const current = await Auth.currentAuthenticatedUser();
    const response = await API.graphql(args, {
      Authorization: current.signInUserSession.idToken.jwtToken,
    });

    data = resultsGetter(response.data);
    nextToken = nextGetter?.(response.data);
    total = totalGetter?.(response.data);
  } catch (errResponse) {
    errors = errResponse.errors;
    data = resultsGetter(errResponse.data);
    nextToken = nextGetter?.(errResponse.data);
    total = totalGetter?.(errResponse.data);
  }

  if (errors) {
    const logFn = data ? logErrorInfo : logError;

    logFn('useAPIQuery error', args, errors, data);
  }

  return { errors, data, nextToken, total };
};

const emptyQueryFn = () => noop;

const singleQueryFn = async (dispatch, getState, args, resultsGetter) => {
  const querySymbol = uniqueId('query_');

  const queryArguments = {
    variables: args.variables,
    query: args.mutation || args.query,
  };

  dispatch(Actions.INIT(querySymbol));

  const queryResponse = await issueQuery(queryArguments, resultsGetter);

  if (getState().querySymbol === querySymbol) {
    dispatch(Actions.COMPLETE(queryResponse));
  } // else, someone else has issued a newer query, ignore the network response

  return queryResponse;
};

const multiQueryFn = async (
  dispatch,
  getState,
  { query, variables: inputVariables, nextTokenPath, totalPath },
  resultsGetter
) => {
  const querySymbol = uniqueId('query_');

  let nextToken;
  let queriedTotal = 0;
  const queriedTokens = new Set();

  dispatch(Actions.INIT(querySymbol));

  const getNextToken = data => get(data, nextTokenPath);
  const getTotal = data => (totalPath ? get(data, totalPath) : null);

  do {
    const variables = {
      ...inputVariables,
      nextToken,
    };

    const queryResponse = await issueQuery({ query, variables }, resultsGetter, getNextToken, getTotal);

    if (getState().querySymbol !== querySymbol) {
      break; // someone else has issued a newer query, ignore the network response
    }

    queriedTotal += queryResponse.data?.length ?? 0;

    /**
     * ES Queries can sometimes return nextTokens even when the query has
     * returned everything. In that case, we can pass a total path to hooks.
     * That total path will check to make sure we're not asking for pages
     * indefinitely.
     * Given that this behavior may be missed in development, we also have
     * a constraint to check next tokens for uniqueness.
     */
    const hasTotalResponse = !!queryResponse.total;
    const hasQueriedTotal = hasTotalResponse && queryResponse.total <= queriedTotal;
    const hasQueriedNextToken = queriedTokens.has(queryResponse.nextToken);

    if (hasQueriedTotal || hasQueriedNextToken) {
      nextToken = null;
    } else {
      queriedTokens.add(queryResponse.nextToken);
      nextToken = queryResponse.nextToken;
    }

    const action = nextToken ? Actions.LOAD_PAGE : Actions.COMPLETE;

    dispatch(action(queryResponse));
  } while (nextToken);
};

const getQueryFn = (paged, disable) => {
  if (disable) return emptyQueryFn;

  return paged ? multiQueryFn : singleQueryFn;
};

const makeResultsGetter = resultPath => arg => get(arg, resultPath);

// eslint-disable-next-line react-hooks/exhaustive-deps
const useResultsGetter = resultPath => useCallback(makeResultsGetter(resultPath), [resultPath]);

const subscriptionEventDetector = arg => {
  let value;

  const method = SUBSCRIPTION_ACTIONS_LIST.find(action => {
    const found = get(arg, action);

    if (found) value = found;

    return !!found;
  });

  return {
    method,
    value,
  };
};

const useDerivedState = (
  dispatch,
  { paged, data, loading, errors, completed, pagesLoaded },
  { handler, disable, queryIssuer }
) => {
  const derivedData = useMemo(() => {
    if (!paged) return data;

    return Array.from(data?.values() ?? []);
  }, [paged, data]);

  const subscriptionHandler = useCallback(
    subscriptionResponseData => dispatch(Actions.SUBSCRIPTION_UPDATE(subscriptionResponseData)),
    [dispatch]
  );

  const derivedState = {
    data: disable ? null : derivedData,
    loading,
    errors,
    completed,
    pagesLoaded,
    subscriptionHandler,
    handler,
    reload: queryIssuer,
  };

  return derivedState;
};

/**
 * @name useAPIQuerySubscription
 * @description Helper function to create a subscription and consume subscriptionHandler from useAPIQuery.
 *
 * As variables are an object, the most performant option is to ensure your variables are memoized before passing them
 * to this function, as this function will check for variable equality before reissuing a query.
 *
 * This disable parameter exists for when you want to avoid creating a subscription, such as when you do not yet have
 * the model ID. This allows the hook to be properly mounted, but avoids the subscription invocation.
 * @param {{
 *  subscription: string,
 *  variables: object,
 *  subscriptionHandler: (obj) => void,
 *  resultPath?: string,
 *  disable?: boolean,
 * }} useAPIQuerySubscriptionArgs
 * @returns {{
 *  error: any,
 * }}
 */
export const useAPIQuerySubscription = ({
  subscription,
  query,
  variables: inputVariables,
  subscriptionHandler,
  resultPath = 'result',
  disable = false,
}) => {
  useDebugValue('useAPIQuerySubscription');
  const variables = useStableObjectIdentity(inputVariables);
  const [error, setError] = useState(null);
  const resultsGetter = useResultsGetter(resultPath);

  useEffect(() => {
    setError(null);
    if (disable) return;

    const sub = API.graphql(graphqlOperation(subscription, variables)).subscribe({
      next: async ({ value: subscriptionResponse }) => {
        const { method, value: subscriptionValue } = subscriptionEventDetector(subscriptionResponse.data);

        const subscriptionQueryVariables = pick(subscriptionValue, 'id');

        let value;

        if ([SUBSCRIPTION_ACTIONS.UPDATE, SUBSCRIPTION_ACTIONS.CREATE].includes(method)) {
          value = await issueQuery({ query, variables: subscriptionQueryVariables }, resultsGetter);
        } else if (method === SUBSCRIPTION_ACTIONS.DELETE) {
          value = { data: subscriptionValue };
        }

        subscriptionHandler({
          method,
          value,
        });
      },
      error: setError,
    });

    return () => {
      sub.unsubscribe();
    };
  }, [subscriptionHandler, resultsGetter, subscription, variables, disable, query]);

  return {
    error,
  };
};

/**
 * @name createAPIQuerySubscriptionHook
 * @description Used to preset useAPIQuerySubscriptionArgs for simplicity. Essentially, for any variables in the hook
 * that will not change, use this helper to abstract them away from the react component.
 *
 * As variables are an object, the most performant option is to ensure your variables are memoized before passing them
 * to this function, as this function will check for variable equality before reissuing a query.
 * @param {{
 *  subscription: string,
 *  defaultVariables?: object,
 *  resultPath?: string
 * }} createAPIQueryHookArgs
 * @example
 * const useUser = createAPIQueryHook({
 *   query: getUser,
 *   resultPath: 'getUser',
 * });
 * const useUserSubscription = createAPIQuerySubscriptionHook({
 *   subscription: onUpdateUserQuery,
 * });
 *
 * const Example = ({ userId }) => {
 *   const { data: user, subscriptionHandler } = useUser({ id: userId });
 *   useUserSubscription({ id: userId }, { subscriptionHandler });
 *
 *   return <p>User name: {user?.name ?? 'Loading...'}</p>;
 * };
 */
export const createAPIQuerySubscriptionHook =
  ({ subscription, query, defaultVariables = {}, resultPath }) =>
  // eslint-disable-next-line default-param-last
  (inputVariables = {}, { subscriptionHandler, disable }) => {
    const cachedVariables = useStableObjectIdentity(inputVariables);
    const variables = useMemo(
      () => ({
        ...defaultVariables,
        ...cachedVariables,
      }),
      [cachedVariables]
    );

    return useAPIQuerySubscription({
      subscription,
      variables,
      query,
      resultPath,
      subscriptionHandler,
      disable,
    });
  };

/**
 * @name useAPIQuery
 * @description Helper function to manage loading state, pagination, and other common query requirements in a hook. This
 * exists for controlled access to the function, but you probably want to use createAPIQueryHook instead.
 *
 * As variables are an object, the most performant option is to ensure your variables are memoized before passing them
 * to this function, as this function will check for variable equality before reissuing a query.
 *
 * The disable parameter exists so that you can mount the query, but avoid issuing it. This can be useful in situations
 * where you do not yet have the required information to issue the query.
 * @param {{
 *  query: string,
 *  variables: object,
 *  paged?: boolean,
 *  nextTokenPath?: string,
 *  totalPath?: string,
 *  resultPath?: string,
 *  disable?: boolean,
 *  debug?: boolean
 * }} useAPIQueryArgs
 * @returns {{
 *  loading: boolean,
 *  completed: boolean,
 *  pagesLoaded: number,
 *  data: object | object[],
 *  error: object[]
 * }}
 */
export const useAPIQuery = ({
  query,
  variables: inputVariables,
  paged = false,
  nextTokenPath = 'result.nextToken',
  totalPath = 'result.total',
  resultPath = paged ? 'result.items' : 'result',
  disable = false,
  debug = false,
}) => {
  useDebugValue('useAPIQuery');
  const reducer = useMemo(() => getReducer({ debug }), [debug]);
  const [state, dispatch] = useReducer(reducer, getDefaultState(paged));
  const resultsGetter = useResultsGetter(resultPath);

  const stateRef = useRef(state);

  stateRef.current = state;

  const getState = useCallback(() => stateRef.current, [stateRef]);

  const queryFn = getQueryFn(paged, disable);

  const variables = useStableObjectIdentity(inputVariables);

  const queryIssuer = useCallback(
    () => queryFn(dispatch, getState, { query, variables, nextTokenPath, totalPath }, resultsGetter),
    [queryFn, getState, query, variables, nextTokenPath, totalPath, resultsGetter]
  );

  useEffect(() => {
    queryIssuer();
  }, [queryIssuer]);

  return useDerivedState(dispatch, state, { disable, queryIssuer });
};

/**
 * @name createAPIQueryHook
 * @description Used to preset apiQueryArguments for simplicity. Essentially, for any variables in the hook that will
 * not change, use this helper to abstract them away from the react component.
 *
 * As variables are an object, the most performant option is to ensure your variables are memoized before passing them
 * to this function, as this function will check for variable equality before reissuing a query.
 * @param {{
 *  query: string,
 *  defaultVariables?: object,
 *  nextTokenPath?: string,
 *  totalPath?: string,
 *  resultPath?: string,
 *  debug?: boolean
 * }} createAPIQueryHookArgs
 * @example
 * const useGetCampaignQuery = createAPIQueryHook({ query: getCampaign, resultPath: 'getCampaign' });
 *
 * const Example = ({ campaignId }) => {
 *   const { loading, data: campaign, error } = useGetCampaignQuery({ id: campaignId });
 *
 *   if (loading) return 'Loading...';
 *   if (error) return 'Error...';
 *   return `Loaded campaign ${campaign.name}`;
 * }
 *
 * const useListCampaignQuery = createAPIQueryHook({
 *   query: listCampaigns,
 *   paged: true,
 *   defaultVariables: {
 *     limit: 500,
 *   },
 *   nextTokenPath: 'listCampaigns.nextToken',
 *   resultPath: 'listCampaigns.items'
 * });
 *
 * const ListExample = ({ teamId }) => {
 *   const { pagesLoaded, completed, loading, data: campaigns, error } = useListCampaignQuery({ teamId });
 *
 *   if (loading) return 'Loading...';
 *   if (error) return 'Error';
 *
 *   return `${pagesLoaded} pages of campaigns loaded, yielding ${campaigns.length} records ${completed ? 'in total' : 'so far'}`
 * }
 */
export const createAPIQueryHook =
  ({ query, defaultVariables = {}, nextTokenPath, totalPath, resultPath, paged, debug = false }) =>
  // eslint-disable-next-line default-param-last
  (inputVariables = {}, { disable }) => {
    const cachedVariables = useStableObjectIdentity(inputVariables);
    const variables = useMemo(
      () => ({
        ...defaultVariables,
        ...cachedVariables,
      }),
      [cachedVariables]
    );

    return useAPIQuery({ query, variables, paged, nextTokenPath, totalPath, resultPath, disable, debug });
  };

const isEvent = obj => {
  if (!obj) return false;
  if (obj instanceof Event) return true;
  if (get(obj, 'nativeEvent') instanceof Event) return true;

  return false;
};

/**
 * @name useAPIMutationCallback
 * @description Helper function to manage loading state, pagination, and other common mutation requirements in a hook.
 * This exists for controlled access to the function, but you probably want to use createAPIMutationCallback instead.
 * The callback will ignore events passed directly, so that you can safely pass it without manually escaping the event.
 *
 * As variables are an object, the most performant option is to ensure your variables are memoized before passing them
 * to this function, as this function will check for variable equality before reissuing a query.
 * @param {{
 *  mutation: string,
 *  variables: object,
 *  nextTokenPath?: string,
 *  resultPath?: string
 *  debug?: boolean
 * }} useAPIMutationCallbackArgs
 * @returns {{
 *  loading: boolean,
 *  completed: boolean,
 *  pagesLoaded: number,
 *  data: object | object[],
 *  error: object[],
 *  handler: (variables: {}) => Promise<any>
 * }}
 */
export const useAPIMutationCallback = ({
  mutation,
  variables: inputVariables = {},
  resultPath = 'result',
  debug = false,
}) => {
  useDebugValue('useAPIMutationCallback');
  const reducer = useMemo(() => getReducer({ debug }), [debug]);
  const [state, dispatch] = useReducer(reducer, getDefaultState(false));
  const variables = useStableObjectIdentity(inputVariables);
  const resultsGetter = useResultsGetter(resultPath);
  const stateRef = useRef(state);

  stateRef.current = state;

  const getState = useCallback(() => stateRef.current, [stateRef]);

  const handler = useCallback(
    (callbackVariables = {}) => {
      const passedVariables = isEvent(callbackVariables) ? variables : { ...callbackVariables, ...variables };

      return singleQueryFn(dispatch, getState, { mutation, variables: passedVariables }, resultsGetter);
    },
    [mutation, getState, resultsGetter, variables]
  );

  return useDerivedState(dispatch, state, { handler });
};

/**
 * @name createAPIMutationHook
 * @description Used to preset apiQueryArguments for simplicity. Essentially, for any variables in the hook that will
 * not change, use this helper to abstract them away from the react component.
 *
 * As variables are an object, the most performant option is to ensure your variables are memoized before passing them
 * to this function, as this function will check for variable equality before reissuing a query.
 * @param {{
 *  query: string,
 *  defaultVariables?: object,
 *  resultPath?: string
 *  debug?: boolean
 * }} createAPIMutationHookArgs
 * @example
 * const createCampaignUpdateCallback = createAPIMutationHook({
 *   mutation: createCampaign,
 *   defaultVariables: {
 *     budget: 50 // don't actually default, just an example
 *   },
 * })
 *
 * const ExampleButton = ({ campaignName }) => {
 *   const { handler: onClick } = createCampaignUpdateCallback({ name: campaignName });
 *
 *   return <button onClick={onClick}>Create {campaignName} campaign</button>
 * }
 */
export const createAPIMutationHook =
  ({ mutation, defaultVariables = {}, resultPath, debug = false }) =>
  (inputVariables = {}) => {
    const cachedVariables = useStableObjectIdentity(inputVariables);
    const variables = useMemo(
      () => ({
        ...defaultVariables,
        ...cachedVariables,
      }),
      [cachedVariables]
    );

    return useAPIMutationCallback({ mutation, variables, resultPath, debug });
  };
