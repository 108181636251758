import { createAPIQueryHook } from '../../hooks/useAPIQuery';

const siteListQuery = /* GraphQL */ `
  query GetBrandSiteLists($id: ID!) {
    sitelists: searchBidlists(
      filter: { brandId: { eq: $id }, type: { eq: "managed" }, category: { eq: "SiteList" } }
      limit: 100
    ) {
      nextToken
      items {
        id
        name
        description
        adjustmentType
      }
    }
  }
`;

const useSiteListsQuery = createAPIQueryHook({
  query: siteListQuery,
  resultPath: 'sitelists.items',
  nextTokenPath: 'sitelists.nextToken',
  paged: true,
});

export const useManagedSitelists = ({ brandId }) => {
  const disable = !brandId;
  const { data: sitelists } = useSiteListsQuery({ id: brandId }, { disable });

  return sitelists ?? [];
};
