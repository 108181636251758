import { Auth } from 'aws-amplify';

const argumentModifiers = {
  string: argument => {
    const lowerCase = argument.toLowerCase();
    const lowerCaseIsEqual = lowerCase === argument;

    return [lowerCase, lowerCaseIsEqual];
  },
  object: argument => {
    const { username } = argument;
    const lowerCaseUsername = username.toLowerCase();
    const lowerCase = {
      ...argument,
      username: lowerCaseUsername,
    };
    const lowerCaseIsEqual = lowerCase === username;

    return [lowerCase, lowerCaseIsEqual];
  },
};

const createSafeAuthMethod =
  (authMethod, argumentModifer, forceLowerCase) =>
  async (firstArg, ...remain) => {
    const [lowerCaseArg, lowerCaseIsEqual] = argumentModifer(firstArg);

    if (forceLowerCase) {
      return authMethod.call(Auth, lowerCaseArg, ...remain);
    }

    // Most users have lowercased credentials, so trying that first
    try {
      return await authMethod.call(Auth, lowerCaseArg, ...remain);
    } catch (e) {
      // only handle UserNotFound if there is another login to try
      if (e.code !== 'UserNotFoundException' || lowerCaseIsEqual) {
        throw e;
      }
    }

    return authMethod.call(Auth, firstArg, ...remain);
  };

/* eslint-disable no-restricted-syntax */

/**
 * This has the same arguments as Auth.confirmSignUp but forces lowercase characters for email
 */
export const confirmSignUp = createSafeAuthMethod(Auth.confirmSignUp, argumentModifiers.string, false);

/**
 * This has the same arguments as Auth.forgotPassword but forces lowercase characters for email
 */
export const forgotPassword = createSafeAuthMethod(Auth.forgotPassword, argumentModifiers.string, false);

/**
 * This has the same arguments as Auth.forgotPasswordSubmit but forces lowercase characters for email
 */
export const forgotPasswordSubmit = createSafeAuthMethod(Auth.forgotPasswordSubmit, argumentModifiers.string, false);

/**
 * This has the same arguments as Auth.resendSignUp but forces lowercase characters for email
 */
export const resendSignUp = createSafeAuthMethod(Auth.resendSignUp, argumentModifiers.string, false);

/**
 * This has the same arguments as Auth.signIn but forces lowercase characters for email
 */
export const signIn = createSafeAuthMethod(Auth.signIn, argumentModifiers.object, false);

/**
 * This has the same arguments as Auth.signup but forces lowercase characters for email
 */
export const signUp = createSafeAuthMethod(Auth.signUp, argumentModifiers.object, true);

/* eslint-enable no-restricted-syntax */
