import { useMemo } from 'react';
import { countBy } from 'lodash';
import { ColumnCell, ColumnHeader } from './Components';

const CreativeSummaryBox = ({ label, value }) => (
  <div className="flex items-center justify-center space-x-1">
    <h2 className="text-sm text-left font-medium">{label}</h2>
    <h2 className=" text-gray-400">({value})</h2>
  </div>
);

export const CreativesColumn = {
  key: 'creatives',
  header: () => <ColumnHeader className="w-64">Creatives</ColumnHeader>,
  cell: function CreativesColumnCell({ campaign }) {
    const counts = useMemo(() => countBy(campaign.creatives?.items ?? [], 'creativeType'), [campaign]);

    return (
      <ColumnCell className="flex flex-col justify-center h-16">
        <div className="font-medium flex space-x-3 h-full items-center">
          {counts.display && <CreativeSummaryBox label="Display" value={counts.display} />}
          {counts.video && <CreativeSummaryBox label="Video" value={counts.video} />}
          {counts.audio && <CreativeSummaryBox label="Audio" value={counts.audio} />}
        </div>
      </ColumnCell>
    );
  },
};
