import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as SafeAuth from 'src/helpers/SafeAuth';
import { useAuthProvider } from 'src/providers/AuthProvider';
import { AuthContainer } from 'src/components/Auth/Layout';
import { useAlreadyAuthedRootRedirect } from 'src/hooks/authHooks';

const useEmail = () => {
  const location = useLocation();

  return useMemo(() => {
    const search = new URLSearchParams(location.search);

    return search.get('email');
  }, [location]);
};

const MissingEmail = () => (
  <div className="w-full text-center mb-10">
    <p>You may have reached this page by mistake.</p>
    <p>If you need assistance, reach out to:</p>
    <a href="mailto:support@brandzooka.com" rel="noreferrer" target="_blank" className="underline mt-8 block">
      support@brandzooka.com
    </a>
  </div>
);

const ResendForm = ({ email }) => {
  const [resending, setResending] = useState(false);

  const handleResendVerificationToken = async () => {
    setResending(true);
    await SafeAuth.resendSignUp(email, { domain: window.location.origin });
    setTimeout(() => setResending(false), 3000);
  };

  if (resending) {
    return <div className="h-32 -mb-2">Resending verification email...</div>;
  }

  return (
    <>
      <div className="w-full text-center mb-10">
        <p>A verification link has been sent to your email:</p>
        <p>{email}</p>
      </div>
      <div className="w-full px-4 mb-4 text-center">
        <div className="text-sm">Didn't receive an email?</div>
        <button
          type="button"
          className="text-xs underline cursor-pointer text-primary"
          onClick={handleResendVerificationToken}
        >
          Resend Verification Email
        </button>
      </div>
    </>
  );
};

export default function ConfirmSignUpPage() {
  const email = useEmail();
  const { logo, isWhiteLabel } = useAuthProvider();

  useAlreadyAuthedRootRedirect();

  return (
    <AuthContainer logo={logo} isWhiteLabel={isWhiteLabel}>
      {email ? <ResendForm email={email} /> : <MissingEmail />}
    </AuthContainer>
  );
}
