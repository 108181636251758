export const creativeFields = /* GraphQL */ `
id
archived
createdAt
updatedAt
uploadedAt
publishedAt
organizationId
teamBrandCampaign
teamId
brandId
campaignId
teamBrandCampaignId
name
fileName
creativeType
s3Key
s3ThumbnailKey
clickthroughUrl
landingPageUrl
inApp
web
ctv
displayTargeting
devices
ttdStatus
isValid
error
campaignCreativesId
`;
