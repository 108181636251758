import React from 'react';

export const GraphTypeSelector = ({ selectedType, setSelectedType }) => (
  <div className="w-full max-w-lg flex space-x-2">
    <GraphTypeButton active={selectedType === 'dates'} onClick={() => setSelectedType('dates')}>
      Dates
    </GraphTypeButton>
    <GraphTypeButton active={selectedType === 'daysOfWeek'} onClick={() => setSelectedType('daysOfWeek')}>
      Days of Week
    </GraphTypeButton>
    <GraphTypeButton active={selectedType === 'hoursOfDay'} onClick={() => setSelectedType('hoursOfDay')}>
      Hours of Day
    </GraphTypeButton>
  </div>
);

const GraphTypeButton = ({ active, onClick, children }) => (
  <button
    type="button"
    onClick={onClick}
    className={`flex-1 py-3 rounded  ${
      active ? 'bg-primary text-white' : 'bg-white text-primary'
    } border-2 border-primary font-semibold`}
  >
    {children}
  </button>
);
