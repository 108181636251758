export const invalidPoliticalSettings = ({ candidate, politicalType, ballotMeasureProfile, electionType } = {}) => {
  const hasSelectedElectionType = !!electionType;
  const isBallotMeasure = electionType === 'ballotMeasure';
  const hasValidPoliticalTypeFields =
    politicalType &&
    ((politicalType === 'federal' && candidate?.FederalVerificationId) ||
      (politicalType === 'stateOrLocal' && candidate?.StateVerificationId));
  const hasCandidateName = !!candidate?.CandidateName;
  const hasValidCandidateInformation =
    hasCandidateName &&
    candidate?.PayingEntityName &&
    candidate?.PayingEntityExecutiveName &&
    candidate?.PayingEntityExecutiveTitle &&
    candidate?.PayingEntityAddress;
  const hasValidBallotInformation =
    ballotMeasureProfile?.PayingEntityName &&
    ballotMeasureProfile?.PayingEntityExecutiveName &&
    ballotMeasureProfile?.PayingEntityExecutiveTitle &&
    ballotMeasureProfile?.PayingEntityAddress;

  const valid =
    (isBallotMeasure && hasValidBallotInformation) ||
    (hasSelectedElectionType && hasValidPoliticalTypeFields && hasValidCandidateInformation);

  return !valid;
};
