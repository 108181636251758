import { useCallback, useEffect, useRef, useState } from 'react';
import { Storage } from 'aws-amplify';
import Leaflet from 'leaflet';
import { useSpring, useSpringRef } from 'react-spring';
import { buildColorArray } from '../helpers';
import { multiBooleanIntersect, setHeatmapColor } from './utils';
import { STATE_KEY_TYPES, MAP_ATTRIBUTION, SEGMENT_GROUP_DEFAULTS, TILE_URL, METRICS } from './constants';

export const useHeatmapSpring = (palette, metricSelection) => {
  const heatmapSpringRef = useSpringRef();

  useSpring({ ref: heatmapSpringRef });

  useEffect(() => {
    const root = document.documentElement;

    if (palette && root) {
      const heatmapColors = buildColorArray(palette, 6);
      const heatmapColor = heatmapColors[METRICS.indexOf(metricSelection) || 0];
      const colorSpringParams = { color: heatmapColor, onChange: ({ value }) => setHeatmapColor(value) };
      const currentColor = root.style.getPropertyValue(`--heatmap-color`);

      if (!currentColor) colorSpringParams.from = { color: '#333' };

      heatmapSpringRef.start(colorSpringParams);
    }
  }, [palette, heatmapSpringRef, metricSelection]);
};

export const useMountedLeafletRef = (mapS3Key, setSelectedMap) => {
  const leafletRefs = useRef({});

  useEffect(() => {
    if (!leafletRefs.current.map) {
      const map = Leaflet.map('heatmap', {
        center: [11.0981, 18.6012],
        zoomSnap: 0.1,
        zoom: 1,
        zoomControl: false,
        scrollWheelZoom: false,
        minZoom: 2,
        maxZoom: 13,
        maxBoundsViscosity: 0.0,
        worldCopyJump: true,
      });
      const layer = Leaflet.featureGroup();

      Leaflet.tileLayer(TILE_URL, { attribution: MAP_ATTRIBUTION }).addTo(map);
      layer.addTo(map);
      Leaflet.control.zoom({ position: 'topright' }).addTo(map);
      leafletRefs.current = { map, layer, panelDefault: '' };
    }
  }, []);

  const prevMapKey = useRef();

  useEffect(() => {
    if (mapS3Key && prevMapKey.current && prevMapKey.current !== mapS3Key) {
      if (leafletRefs?.current) {
        const { map, layer, panelDefault } = leafletRefs.current;

        leafletRefs.current = { map, layer, panelDefault };
      }
      setSelectedMap(false);
    }
    prevMapKey.current = mapS3Key;
  }, [mapS3Key, setSelectedMap]);

  return leafletRefs;
};

export const useMap = mapS3Key => {
  const [selectedMap, setSelectedMap] = useState(false);

  useEffect(() => {
    let mounted = true;

    if (selectedMap) return;

    Storage.list(mapS3Key)
      .then(resp => resp.map(r => r.key.replace(`${mapS3Key}/`, '')))
      .then(resp => {
        const world = resp.find(str => {
          const [keyType] = str.split('|');

          return keyType === 'World';
        });

        if (world) return world;

        return resp.find(str => {
          const [keyType] = str.split('|');

          return keyType === 'Country';
        });
      })
      .then(key => mounted && setSelectedMap({ key }))
      .catch(err => console.error(err, mapS3Key));

    return () => (mounted = false);
  }, [mapS3Key, selectedMap]);

  return [selectedMap, setSelectedMap];
};

const getModifiedGeoJSON = ({ geoJson, mask, regionSegmentGroup }) => {
  let newJson = { ...geoJson, features: geoJson.features.slice() };
  const { mapType, segmentGroup: sGroup } = geoJson?.properties || {};

  if (mask && mapType !== 'Country' && geoJson.features && !geoJson.features.includes(mask)) {
    newJson.features.push(mask);
  }

  if (sGroup === 'Collection') {
    const segGroup = regionSegmentGroup || 'County';
    const [layerMask, firstFeature] = geoJson.features;

    newJson = geoJson.features.find(f => f.properties.segmentGroup === segGroup) || firstFeature;

    const filtered = geoJson.features.filter(f => f.properties.segmentGroup !== 'Mask');

    newJson.features = [...filtered, layerMask];
  }

  return newJson;
};

export const useMapParams = ({ leafletRefs, mapS3Key, metricSelection, selectedMap, selectedSegmentGroup }) => {
  const [mapParams, setMapParams] = useState({});

  const getMapParams = useCallback(
    async ({ key, mask }) => {
      const [keyType, fileName] = (key || '').split('|');
      const [mapSegGroup = 'Country'] = selectedMap?.key?.split('|') || [];
      const selectedGroup = selectedSegmentGroup[mapSegGroup] || SEGMENT_GROUP_DEFAULTS[mapSegGroup];

      if (!fileName && keyType !== 'Country') return;

      const params = {
        leafletRefs,
        mapS3Key,
        regionSegmentGroup: selectedGroup,
        metricSelection,
        mask,
        loaded: true,
      };

      if (STATE_KEY_TYPES.includes(keyType)) {
        const stateKey = fileName.split(' > ').slice(0, 2).join(' > ');
        const stateGeoJson = leafletRefs.current[`Zip|${stateKey}`];

        if (stateGeoJson) {
          params.geoJson = JSON.parse(JSON.stringify(stateGeoJson));
          if (mask) {
            if (params.geoJson.default === 'Zip') params.geoJson = params.geoJson.Zip;
            params.geoJson.features = params.geoJson.features
              .filter(f => multiBooleanIntersect(mask, f))
              .map(f => {
                f.properties.mapType = keyType;

                return f;
              });
            params.geoJson.features.push(mask);
          }

          return params;
        }

        return;
      }

      const filePromise = leafletRefs.current[key]
        ? Promise.resolve(leafletRefs.current[key])
        : Storage.get(`${mapS3Key}/${key}`, { download: true, cacheControl: 'no-cache' })
            .then(resp => resp.Body.text())
            .then(resp => JSON.parse(resp));

      return filePromise.then(respObj => {
        leafletRefs.current[key] = respObj;
        const resp = respObj[selectedGroup] || respObj[respObj?.default];

        params.maps = Object.keys(respObj).filter(k => !['default', 'mask', 'mapType'].includes(k));
        params.mapType = respObj?.mapType;

        if (respObj.mask) params.mask = respObj.mask;
        params.geoJson = resp;

        return params;
      });
    },
    [mapS3Key, selectedMap, selectedSegmentGroup, metricSelection, leafletRefs]
  );

  useEffect(() => {
    let mounted = true;

    if (selectedMap) {
      getMapParams(selectedMap).then(params => {
        if (mounted) {
          const newParams = {
            ...params,
            geoJson: getModifiedGeoJSON(params),
          };

          setMapParams(newParams);
        }
      });
    }

    return () => (mounted = false);
  }, [selectedMap, getMapParams]);

  return mapParams;
};
