import React, { useMemo } from 'react';
import { useGAS } from 'src/GlobalAppState/context';
import { SummaryItem } from './SummaryItem';
import { SummaryHeader } from './SummaryHeader';

export const AudienceSummary = ({ campaignDraft, appParams }) => {
  const { audienceType, retargetingTags } = campaignDraft || {};
  const { managedAudiences } = useGAS();
  const { campaign } = appParams;

  const summary = useMemo(
    () =>
      (campaignDraft?.dmpSegments || []).reduce((obj, itm) => {
        if (!itm?.pathKey) return obj;
        // eslint-disable-next-line no-unsafe-optional-chaining
        const [key] = itm?.pathKey.split(' > ');
        const keyCount = obj[key] || 0;

        obj[key] = keyCount + 1;

        return obj;
      }, {}),
    [campaignDraft?.dmpSegments]
  );

  const targetAudienceType = audienceType || campaign.audienceType;
  let showRequired = false;

  const managedAudience =
    targetAudienceType === 'managed' && managedAudiences.find(audience => audience.id === campaignDraft.audience);

  let summaryItems = Object.entries(summary).map(([k, v]) => (
    <div key={`summary-${k}`} className="ml-4">
      <SummaryItem title={k}>
        <h3 className="text-primary">{v}</h3>
      </SummaryItem>
    </div>
  ));

  if (targetAudienceType === 'thirdParty' && !campaignDraft?.dmpSegments?.length) {
    showRequired = true;
  }

  if (!summaryItems.length || targetAudienceType === 'everyone') {
    summaryItems = (
      <div className="ml-4">
        <SummaryItem title="targeting">
          <h3 className="text-primary capitalize">{targetAudienceType}</h3>
        </SummaryItem>
      </div>
    );
  }

  if (targetAudienceType === 'retargeting') {
    summaryItems = (
      <div className="ml-4">
        <SummaryItem title="Retargeting">
          <h3 className="text-primary">{(retargetingTags || []).length}</h3>
        </SummaryItem>
      </div>
    );
  }

  if (targetAudienceType === 'firstParty') {
    summaryItems = (
      <div className="ml-4">
        <SummaryItem title="First Party Data">
          <h3 className="text-primary">{(retargetingTags || []).length}</h3>
        </SummaryItem>
      </div>
    );
  }

  if (targetAudienceType === 'managed') {
    summaryItems = (
      <div className="ml-4">
        <SummaryItem title="Managed">
          <h3 className="text-primary">{managedAudience?.name}</h3>
        </SummaryItem>
      </div>
    );
  }

  return (
    <>
      <SummaryHeader panelKey="Audience">Audience</SummaryHeader>
      {targetAudienceType && !showRequired ? (
        <div className="flex flex-col space-y-1">{summaryItems}</div>
      ) : (
        <div className="flex ml-4 space-x-2 text-xs bg-orange-100 p-1 w-1/3">
          <span className="mx-auto text-gray-500">Required</span>
        </div>
      )}
    </>
  );
};
