import NumberFormat from 'react-number-format';
import { ColumnCell, ColumnHeader } from './Components';

export const ConversionsColumn = {
  key: 'conversions',
  header: () => <ColumnHeader className="w-32">Conversions</ColumnHeader>,
  cell: ({ campaign, metrics }) => {
    const { conversions } = metrics;

    const hasConversions = campaign.conversionEvents?.length && Number.isFinite(conversions);

    return (
      <ColumnCell>
        {hasConversions ? (
          <NumberFormat
            className="text-lg text-gray-600"
            displayType="text"
            value={conversions}
            thousandSeparator
            fixedDecimalScale
            decimalScale={0}
          />
        ) : (
          <span className="text-lg text-gray-600">--</span>
        )}
      </ColumnCell>
    );
  },
};
