import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppParams } from 'src/hooks/useAppParams';
import { buildAppPath } from 'src/helpers/app/buildAppPath';
import { CampaignBuilder } from 'src/components/CampaignDetails/CampaignBuilder';
import { useCampaignDraftProvider } from 'src/providers/CampaignDraftProvider';

export default function CampaignEditPage() {
  const appParams = useAppParams();
  const history = useHistory();
  const { onSave, campaignDraft, setCampaignDraft, creativesChanged, setCreativesChanged } = useCampaignDraftProvider();

  const setAppRoute = useCallback(
    paramsInput => {
      const route = buildAppPath(appParams, paramsInput);

      if (paramsInput.replace) {
        history.replace(route);
      } else {
        history.push(route);
      }
    },
    [appParams, history]
  );

  return (
    <CampaignBuilder
      campaignDraft={campaignDraft}
      appParams={appParams}
      setCampaignDraft={setCampaignDraft}
      onSave={onSave}
      setAppRoute={setAppRoute}
      creativesChanged={creativesChanged}
      setCreativesChanged={setCreativesChanged}
    />
  );
}
