import { useState, useCallback } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import * as SafeAuth from 'src/helpers/SafeAuth';
import { useLocalStorageState } from 'src/helpers/window/localStorageHelper';
import { EmailField } from 'src/components/Auth/EmailField';
import { PasswordField } from 'src/components/Auth/PasswordField';
import { RememberMeCheckbox } from 'src/components/Auth/RememberMeCheckbox';
import { AuthContainer } from 'src/components/Auth/Layout';
import { useAuthProvider } from 'src/providers/AuthProvider';
import { useAlreadyAuthedRootRedirect } from 'src/hooks/authHooks';

const noPassword = 'Custom auth lambda trigger is not configured for the user pool.';
const noPasswordMsg = 'Please fill out the above fields to continue';
const defaultMsg =
  "We couldn't find an account matching the email and password you entered. Please check your email and password and try again.";

const useConfirmationEmail = () => {
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  return params.get('confirmed');
};

export default function SignInPage() {
  const { logo, isWhiteLabel } = useAuthProvider();
  const history = useHistory();
  const confirmationEmail = useConfirmationEmail();
  const [email, setEmail, emailStorage] = useLocalStorageState(
    'appAuth.email',
    confirmationEmail || '',
    !!confirmationEmail
  );
  const [rememberMe, setRememberMe, rememberStorage] = useLocalStorageState('appAuth.rememberMe', false);
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showResetRequest, setShowResetRequest] = useState(false);

  useAlreadyAuthedRootRedirect();

  const updateRememberedEmail = useCallback(() => {
    if (rememberMe) {
      rememberStorage.persist();
      emailStorage.persist();
    } else {
      rememberStorage.clear();
      emailStorage.clear();
    }
  }, [rememberMe, emailStorage, rememberStorage]);

  const onSignIn = useCallback(
    async e => {
      e.preventDefault();
      setErrorMessage('');
      setLoading(true);

      try {
        await SafeAuth.signIn({
          username: email,
          password,
        });
        updateRememberedEmail();
        history.push('/');
      } catch (err) {
        switch (err.code) {
          case 'UserNotConfirmedException':
            history.push(`/auth/confirm-signup?email=${encodeURIComponent(email)}`);
            break;
          case 'PasswordResetRequiredException':
            setShowResetRequest(true);
            break;
          default:
            setErrorMessage(err.message);
        }
      }

      setLoading(false);
    },
    [setErrorMessage, setLoading, setShowResetRequest, email, password, updateRememberedEmail, history]
  );

  return (
    <AuthContainer data-testid="bzsignin" loading={loading} isWhiteLabel={isWhiteLabel} logo={logo}>
      {!showResetRequest && (
        <>
          <h1 className="text-3xl font-medium">Welcome!</h1>
          {!!confirmationEmail && <p>Your email has been successfully confirmed. Please log in.</p>}
          <form data-mf-replace="" className="flex flex-col w-full items-center max-w-md" onSubmit={onSignIn}>
            <div className="w-full">
              <EmailField className="p-0 w-full" label="Email" value={email} update={setEmail} />
            </div>
            <div className="w-full">
              <PasswordField className="p-0" label="Password" value={password} update={setPassword} />
            </div>
            <div className="text-center w-full -mt-4">
              {errorMessage && (
                <p className="text-red-500 text-lg pt-2 -mb-2">
                  {errorMessage === noPassword ? noPasswordMsg : defaultMsg}
                </p>
              )}
            </div>
            <button type="submit" className="w-full bz-btn-solid mt-4 h-12 font-medium">
              Sign In
            </button>
            <div className="text-center w-full my-4">
              <RememberMeCheckbox rememberMe={rememberMe} setRememberMe={setRememberMe} />
            </div>
            <div className="w-full">
              <Link to="/auth/forgot-password" className="text-xs relative left-0 top-2 cursor-pointer text-primary">
                Forgot password?
              </Link>
            </div>
            {!isWhiteLabel && (
              <>
                <h2 className="text-gray-600 w-full text-center py-6">Not a user yet?</h2>
                <Link
                  to="/auth/sign-up"
                  className="cursor-pointer text-primary bz-focus w-20"
                  data-testid="signUpButton"
                >
                  Sign up
                </Link>
              </>
            )}
          </form>
        </>
      )}
      {showResetRequest && (
        <>
          <h1 className="text-2xl font-medium">Password Reset Requested</h1>
          <h1 className="text-lg text-center">Your admin has requested that you reset your password.</h1>
          <Link to="/auth/forgot-password" className="w-full bz-btn-solid mt-4 h-12 font-medium">
            Reset Password
          </Link>
        </>
      )}
    </AuthContainer>
  );
}
