const brandzooka = 'brandzooka';
const localhost = 'localhost';

const getWindowHostname = () => {
  if (typeof window === 'undefined') return '';

  return window.location?.hostname;
};

export const getDomainInfo = (hostname = getWindowHostname()) => {
  const [tld, domain, subdomain] = (hostname?.split('.') || []).reverse();

  const isDev = tld === localhost;
  const isWhiteLabel = !!((isDev || domain !== brandzooka) && subdomain);

  return {
    isWhiteLabel,
    isDev,
    tld,
    domain,
    subdomain,
  };
};
