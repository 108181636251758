import * as yup from 'yup';
import { createSelector, createStructuredSelector } from 'reselect';
import { useGAS } from 'src/GlobalAppState/context';
import { round } from 'lodash';
import { useOrganizationMinBudget } from 'src/helpers/organization/useOrganizationMinBudget';
import { getBudget, getCampaignMinSpend, getIsRecurring, getRecurrenceBudget } from './campaignSelectors';

const getPreviousRecurrencesCampaignTotalBudget = createSelector(
  getBudget,
  getRecurrenceBudget,
  (budget, recurrenceBudget) => budget - recurrenceBudget
);

const getMinRecurrenceBudgetForCampaign = createSelector(
  getPreviousRecurrencesCampaignTotalBudget,
  getCampaignMinSpend,
  (previousBudget, minSpend) => round(minSpend - previousBudget, 2)
);

const draftBudgetSchema = minimumBudget =>
  yup.object({
    budget: yup.number().when(['isRecurring', '$campaign'], (isRecurring, campaign, schema) => {
      if (isRecurring || campaign?.status === 'completed') return schema;

      const baseValidation = schema.required().integer('Campaign budgets must be in whole dollars');

      const minSpend = campaign ? getCampaignMinSpend(campaign) : 0;

      if (minSpend > minimumBudget) {
        return baseValidation.min(
          minSpend,
          `This campaign has already spent some of its budget. Please set a budget greater than $${minSpend}`
        );
      }

      return baseValidation.min(minimumBudget, `Campaigns have a minimum budget of $${minimumBudget}`);
    }),
    recurrenceBudget: yup.number().when(['isRecurring', '$campaign'], (isRecurring, campaign, schema) => {
      if (!isRecurring || campaign?.status === 'completed') return schema;

      const baseValidation = schema.required().integer('Recurring budgets must be in whole dollars');

      const minSpend = campaign ? getMinRecurrenceBudgetForCampaign(campaign) : 0;

      if (minSpend > minimumBudget) {
        return baseValidation.min(minSpend, `Campaign budget cannot be less than the previous spend of $${minSpend}`);
      }

      return baseValidation.min(minimumBudget, `Recurring campaigns have a minimum budget of $${minimumBudget}`);
    }),
  });

const getValidationParameters = createStructuredSelector({
  budget: getBudget,
  isRecurring: getIsRecurring,
  recurrenceBudget: getRecurrenceBudget,
});

export const getCampaignDraftSchemaErrors = createSelector(
  (draft, _, __) => getValidationParameters(draft),
  (_, campaign, __) => campaign,
  (_, __, orgMinimum) => orgMinimum,
  (draft, campaign, orgMinimum) => {
    try {
      draftBudgetSchema(orgMinimum).validateSync(draft, { context: { campaign } });

      return null;
    } catch (e) {
      return e;
    }
  }
);

export const useCampaignDraftSchemaValidator = draft => {
  const { campaign } = useGAS();
  const orgMinimum = useOrganizationMinBudget();

  return getCampaignDraftSchemaErrors(draft, campaign, orgMinimum);
};

export const useSufficientBrandBudgetCheck = draft => {
  const { credits: { brandCredits, campaignCredits } = {} } = useGAS();

  const { budget: draftBudget } = draft;

  const requiredCredits = draftBudget + campaignCredits;
  const availableCredits = brandCredits;

  return {
    requiredCredits,
    hasRequiredCredits: availableCredits >= requiredCredits,
  };
};
