import React, { useState, useEffect } from 'react';
import { animated, useSpring } from 'react-spring';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import { ChevronDown } from 'src/svgs';

export const DateButton = ({
  prefix,
  title,
  value,
  onSelect,
  minDate,
  maxDate,
  disabled = false,
  'data-testid': testid,
}) => {
  const [show, setShow] = useState(false);
  const [selectedDate, setSelectedDate] = useState(minDate || new Date());

  useEffect(() => {
    if (value) {
      const d = new Date(value);

      setSelectedDate(d);
    }
  }, [value]);

  const springProps = show ? { opacity: 1, maxHeight: 320 } : { opacity: 0, maxHeight: 0 };
  const style = useSpring(springProps);

  const onDateSelect = d => {
    const dateStr = d.toISOString();

    onSelect(dateStr);
    setShow(false);
  };

  const date = value ? new Date(value) : new Date();
  const formattedDate = format(date, 'MM/dd/yyyy') || '';

  const buttonTitle = value ? (
    <div className="flex-1 flex space-x-3 items-center">
      <h1 className="font-semibold text-left">{prefix}:</h1>
      <h1 className="font-medium text-left text-primary tracking-wide">{formattedDate}</h1>
    </div>
  ) : (
    <div className="flex-1 flex space-x-3 items-center">
      <h1 className="font-semibold flex-1 text-left">{title}</h1>
    </div>
  );

  return (
    <div className="flex-1 md:w-60 z-10" data-testid={testid}>
      <div className="w-full h-full flex flex-col bg-white rounded border">
        <button
          type="button"
          onClick={() => {
            // eslint-disable-next-line no-unused-expressions
            !disabled && setShow(!show);
          }}
          className="w-full flex items-center px-2 sm:px-4 py-2"
          data-testid="dateButton"
        >
          <h1 className="font-semibold flex-1 text-left text-sm sm:text-base">{buttonTitle}</h1>
          <ChevronDown className="stroke-current w-4 h-4" />
        </button>
        <animated.div style={style} className="z-10 flex-1 flex justify-center overflow-hidden date-picker-container">
          <DatePicker
            wrapperClassName="w-full p-2 sm:p-4"
            calendarClassName="w-full"
            selected={selectedDate}
            onChange={onDateSelect}
            minDate={minDate || null}
            maxDate={maxDate || null}
            inline
          />
        </animated.div>
      </div>
    </div>
  );
};
