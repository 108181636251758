import { useMemo } from 'react';
import { createAPIQueryHook, createAPIQuerySubscriptionHook } from '../../hooks/useAPIQuery';
import { precisionAddition } from '../precisionAddition';

const fields = /* GraphQL */ `
  id
  createdAt
  updatedAt
  adminGroups
  viewerGroups
  userId
  organizationId
  teamId
  brandId
  campaignId
  chargeType
  chargeId
  amountIntended
  amount
  status
  metadata
`;

const getOrganizationCredits = /* GraphQL */ `
  query creditsByOrganizationTeamBrandCampaign($organizationId: ID!, $nextToken: String) {
    result: creditsByOrganizationTeamBrandCampaign(organizationId: $organizationId, nextToken: $nextToken) {
      nextToken
      items {
        ${fields}
      }
    }
  }
`;

const useCreditsQuery = createAPIQueryHook({
  query: getOrganizationCredits,
  paged: true,
});

const getCredit = /* GraphQL */ `
  query getCredit($id: ID!) {
    result: getCredit(id: $id) {
      ${fields}
    }
  }
`;

const onCreateCredit = /* GraphQL */ `
  subscription OnCreateCredit($organizationId: String!) {
    create: onCreateCredit(organizationId: $organizationId) {
      id
    }
  }
`;

const useCreateSubscription = createAPIQuerySubscriptionHook({
  subscription: onCreateCredit,
  query: getCredit,
});

const onUpdateCredit = /* GraphQL */ `
  subscription OnUpdateCredit($organizationId: String!) {
    update: onUpdateCredit(organizationId: $organizationId) {
      id
    }
  }
`;

const useUpdateSubscription = createAPIQuerySubscriptionHook({
  subscription: onUpdateCredit,
  query: getCredit,
});

const onDeleteCredit = /* GraphQL */ `
  subscription OnDeleteCredit($organizationId: String!) {
    delete: onDeleteCredit(organizationId: $organizationId) {
      id
    }
  }
`;

const useDeleteSubscription = createAPIQuerySubscriptionHook({
  subscription: onDeleteCredit,
  query: getCredit,
});

const useCreditsSubscription = (...args) => {
  useCreateSubscription(...args);
  useUpdateSubscription(...args);
  useDeleteSubscription(...args);
};

const getBaseCreditsResponse = () => ({
  organizationCredits: 0,
  organizationCreditRecords: [],
  organizationPayoutCredits: 0,
  organizationPayoutRecords: [],
  teamCredits: 0,
  teamCreditRecords: [],
  teamPayoutCredits: 0,
  teamPayoutRecords: [],
  brandCredits: 0,
  brandCreditRecords: [],
  campaignCredits: 0,
  campaignCreditRecords: [],
});

const getEmptyCreditCalculation = () => ({
  sum: 0,
  records: [],
  payoutSum: 0,
  payoutRecords: [],
});

const getEmptyCreditsObject = () => ({
  byCampaign: {},
  byBrand: {},
  byTeam: {},
  byOrganization: {},
});

const isPayout = ({ chargeType: type }) => type === 'payout' || type === 'cashout';

const reduceInto = (accumulator, key, id, credit) => {
  accumulator[key][id] = accumulator[key][id] || getEmptyCreditCalculation();
  const calcObj = accumulator[key][id];

  if (isPayout(credit)) {
    calcObj.payoutSum = precisionAddition(calcObj.payoutSum, credit.amount);
    calcObj.payoutRecords.push(credit);
  } else {
    calcObj.sum = precisionAddition(calcObj.sum, credit.amount);
    calcObj.records.push(credit);
  }
};

export const useCredits = ({ organizationId, teamId, brandId, campaignId }) => {
  const disable = !organizationId;
  const { data: credits, subscriptionHandler, completed } = useCreditsQuery({ organizationId }, { disable });

  useCreditsSubscription({ organizationId }, { subscriptionHandler, disable });

  const creditsRecords = useMemo(() => {
    if (!completed || !credits?.length) return null;

    return credits.reduce((acc, item) => {
      const { organizationId: oid, teamId: tid, brandId: bid, campaignId: cid } = item || {};

      if (cid) {
        reduceInto(acc, 'byCampaign', cid, item);
      }

      if (bid) {
        reduceInto(acc, 'byBrand', bid, item);
      }

      if (tid && !bid) {
        reduceInto(acc, 'byTeam', tid, item);
      }

      if (oid && !tid && !bid) {
        reduceInto(acc, 'byOrganization', oid, item);
      }

      return acc;
    }, getEmptyCreditsObject());
  }, [credits, completed]);

  return useMemo(() => {
    if (!creditsRecords) return getBaseCreditsResponse();

    const org = creditsRecords.byOrganization[organizationId] ?? getEmptyCreditCalculation();
    const team = creditsRecords.byTeam[teamId] ?? getEmptyCreditCalculation();
    const brand = creditsRecords.byBrand[brandId] ?? getEmptyCreditCalculation();
    const campaign = creditsRecords.byCampaign[campaignId] ?? getEmptyCreditCalculation();

    return {
      creditsRecords,
      organizationCredits: org.sum,
      organizationCreditRecords: org.records,
      organizationPayoutCredits: org.payoutSum,
      organizationPayoutRecords: org.payoutRecords,
      teamCredits: team.sum,
      teamCreditRecords: team.records,
      teamPayoutCredits: team.payoutSum,
      teamPayoutRecords: team.payoutRecords,
      brandCredits: brand.sum,
      brandCreditRecords: brand.records,
      campaignCredits: campaign.sum,
      campaignCreditRecords: campaign.records,
    };
  }, [creditsRecords, organizationId, teamId, brandId, campaignId]);
};
