import { groupBy } from 'lodash';
import { useDebugValue, useMemo } from 'react';
import { createAPIQueryHook } from 'src/hooks/useAPIQuery';

const tryParse = data => {
  try {
    return JSON.parse(data);
  } catch (error) {
    console.error(error);

    return [];
  }
};

const listPaymentMethods = /* GraphQL */ `
  mutation ListPaymentMethods($input: ListPaymentMethodsInput) {
    result: listPaymentMethods(input: $input)
  }
`;

const useListPaymentMethodsQuery = createAPIQueryHook({
  query: listPaymentMethods,
});

const createListPaymentsHook = idKey => {
  const hookName = `${idKey}PaymentMethods`;

  return ({ [idKey]: id }) => {
    useDebugValue(hookName);
    const disable = !id;

    const queryArgs = useMemo(
      () => ({
        input: {
          [idKey]: id,
          paymentMethodType: 'ALL',
        },
      }),
      [id]
    );

    const { data, reload } = useListPaymentMethodsQuery(queryArgs, { disable });

    const parsedMethods = useMemo(() => tryParse(data), [data]);

    const paymentMethods = useMemo(() => groupBy(parsedMethods ?? {}, 'type'), [parsedMethods]);

    return {
      paymentMethods,
      reload,
    };
  };
};

export const useOrganizationPaymentMethods = createListPaymentsHook('organizationId');

export const useTeamPaymentMethods = createListPaymentsHook('teamId');

export const useBrandPaymentMethods = createListPaymentsHook('brandId');
