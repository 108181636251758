import { useAppParams } from 'src/hooks/useAppParams';
import { useCampaignDraftProvider } from 'src/providers/CampaignDraftProvider';
import { AudienceSummary } from './AudienceSummary';
import { CampaignBasics } from './CampaignBasics';
import { ConversionsSummary } from './ConversionsSummary';
import { CreativeSummary } from './CreativeSummary';
import { GeoSummary } from './GeoSummary';
import { InventorySummary } from './InventorySummary';
import { StatusCard } from './StatusCard';
import { SummaryButtons } from './SummaryButtons';
import { SiteListsSummary } from './SiteListsSummary';

export const SummaryColumn = () => {
  const appParams = useAppParams();
  const { onSave, campaignDraft, creativesChanged } = useCampaignDraftProvider();
  const campaign = appParams?.campaign || campaignDraft;

  return (
    <div className="w-64 xl:w-80 flex flex-col space-y-3 py-3">
      <div>
        <StatusCard campaign={campaign} />
      </div>
      <div className="w-full overflow-scroll rounded bg-white shadow border-gray-200 flex-1 flex flex-col px-4 space-y-1">
        <div className="bg-white overflow-auto rounded flex flex-col py-4 pb-10">
          <h1 id="campaign summary" className="font-medium text-left mb-2">
            Campaign Summary
          </h1>
          <CampaignBasics campaign={campaign} campaignDraft={campaignDraft} appParams={appParams} />
          <CreativeSummary campaign={campaign} campaignDraft={campaignDraft} creativesChanged={creativesChanged} />
          <InventorySummary campaign={campaign} campaignDraft={campaignDraft} />
          <SiteListsSummary />
          <AudienceSummary campaign={campaign} campaignDraft={campaignDraft} appParams={appParams} />
          <GeoSummary campaign={campaign} campaignDraft={campaignDraft} />
          <ConversionsSummary campaignDraft={campaignDraft} appParams={appParams} />
        </div>
      </div>
      <div>
        <SummaryButtons campaignDraft={campaignDraft} onSave={onSave} creativesChanged={creativesChanged} />
      </div>
    </div>
  );
};
