import { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';

const fields = /* GraphQL */ `
  {
    id
    archived
    trackingTagType
    adminGroups
    viewerGroups
    createdAt
    updatedAt
    organizationId
    teamId
    brandId
    status
    ttdId
    dspIds
    name
    pixelCode
    pixelAudienceSize
    remainingDays
    brandTrackingTagsId
  }
`;

const getBrandTrackingTags = /* GraphQL */ `
  query getBrand($id: ID!, $nextToken: String) {
    getBrand(id: $id) {
      trackingTags(nextToken: $nextToken) {
        nextToken
        items ${fields}
      }
    }
  }
`;

const onCreateTrackingTag = /* GraphQL */ `
  subscription OnCreateTrackingTag($organizationId: String, $teamId: String, $brandId: String) {
    onCreateTrackingTag(organizationId: $organizationId, teamId: $teamId, brandId: $brandId) {
      id
      brandId
      teamId
      organizationId
    }
  }
`;

const onUpdateTrackingTag = /* GraphQL */ `
  subscription OnUpdateTrackingTag($organizationId: String, $teamId: String, $brandId: String) {
    onUpdateTrackingTag(organizationId: $organizationId, teamId: $teamId, brandId: $brandId) {
      id
      brandId
      teamId
      organizationId
    }
  }
`;

const onDeleteTrackingTag = /* GraphQL */ `
  subscription OnDeleteTrackingTag($organizationId: String, $teamId: String, $brandId: String) {
    onDeleteTrackingTag(organizationId: $organizationId, teamId: $teamId, brandId: $brandId) {
      id
      brandId
      teamId
      organizationId
    }
  }
`;

const getTrackingTags = ({ brandId }) => {
  const getPage = next =>
    API.graphql({
      query: getBrandTrackingTags,
      variables: { id: brandId, nextToken: next },
    }).then(
      ({
        data: {
          getBrand: {
            trackingTags: { items, nextToken },
          },
        },
      }) => {
        if (nextToken) return getPage(nextToken).then(resp => [...items, ...resp]);

        return items;
      }
    );

  return getPage().then(resp => resp.filter(itm => !itm.archived).sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1)));
};

export const useBrandTrackingTags = ({ organizationId, teamId, brandId }) => {
  const [trackingTags, setTrackingTags] = useState(false);

  useEffect(() => {
    if (brandId && teamId && organizationId) {
      let subscribed = true;
      const input = { brandId, teamId, organizationId };
      const dbQuery = () => getTrackingTags(input).then(resp => subscribed && setTrackingTags(resp));

      const onError = err => {
        const { error: { errors: [{ message = 'subscription error' } = {}] = [] } = {} } = err || {};

        console.warn(message);
      };

      const createSubscription = API.graphql(graphqlOperation(onCreateTrackingTag, input)).subscribe({
        next: dbQuery,
        error: onError,
      });
      const updateSubscription = API.graphql(graphqlOperation(onUpdateTrackingTag, input)).subscribe({
        next: dbQuery,
        error: onError,
      });
      const deleteSubscription = API.graphql(graphqlOperation(onDeleteTrackingTag, input)).subscribe({
        next: dbQuery,
        error: onError,
      });

      dbQuery();

      return () => {
        subscribed = false;
        createSubscription.unsubscribe();
        updateSubscription.unsubscribe();
        deleteSubscription.unsubscribe();
      };
    }

    setTrackingTags(false);
  }, [brandId, teamId, organizationId]);

  return trackingTags;
};
