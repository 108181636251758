import { API } from 'aws-amplify';

const fields = /* GraphQL */ `
  {
    id
    archived
    createdAt
    updatedAt
    uploadedAt
    publishedAt
    organizationId
    teamId
    brandId
    campaignId
    name
    fileName
    creativeType
    s3Key
    s3ThumbnailKey
    clickthroughUrl
    landingPageUrl
    inApp
    web
    ctv
    devices
    ttdStatus
    isValid
    error
  }
`;

const getCampaignCreativesQuery = /* GraphQL */ `
  query ListCampaignCreatives($id: ID!, $nextToken: String) {
    getCampaign(id: $id) {
      creatives(nextToken: $nextToken) {
        nextToken
        items ${fields}
      }
    }
  }
`;

export const getCampaignCreatives = (campaignId, filterArchived = true) => {
  if (!campaignId) return Promise.resolve([]);

  const getPage = next =>
    API.graphql({
      query: getCampaignCreativesQuery,
      variables: { id: campaignId, nextToken: next },
    }).then(
      ({
        data: {
          getCampaign: {
            creatives: { items, nextToken },
          },
        },
      }) => {
        if (nextToken) return getPage(nextToken).then(resp => [...items, ...resp]);

        return items;
      }
    );

  return getPage().then(resp => resp.filter(itm => (filterArchived ? !itm.archived : true)));
};
