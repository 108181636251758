import { useEffect, useCallback, Suspense, lazy } from 'react';
import { useSpring, useSpringRef } from 'react-spring';
import { ModalProvider } from 'src/contexts';
import { useGAS } from 'src/GlobalAppState/context';
import { setThemeColor } from 'src/helpers';
import { FeatureFlagContextProvider } from 'src/helpers/featureFlags/FeatureFlagContext';
import { useNoAuthSignInRedirect } from 'src/hooks/authHooks';

const Modal = lazy(() => import('src/components/Modal/Modal'));
const SlideOutPanel = lazy(() => import('src/components/SlideOutPanel/SlideOutPanel'));

const blankTheme = { primary: '#374151', secondary: '#03012B' };
const setCssVar = (key, value) => {
  setThemeColor(`primary-${key}`, value?.primary);
  setThemeColor(`secondary-${key}`, value?.secondary);
};

const AppProvider = ({ children }) => (
  <FeatureFlagContextProvider>
    <ModalProvider>{children}</ModalProvider>
  </FeatureFlagContextProvider>
);

const useThemeRef = (theme, themeName) => {
  const ref = useSpringRef();

  useSpring({ ...blankTheme, ref });

  const onChange = useCallback(({ value }) => setCssVar(themeName, value), [themeName]);

  useEffect(() => {
    ref.start({ ...theme, onChange });
  }, [theme, ref, onChange]);
};

const useThemeRefs = () => {
  const { orgTheme, teamTheme } = useGAS();

  useThemeRef(orgTheme, 'org');
  useThemeRef(teamTheme, 'team');
};

export default function WithAuthLayout({ children }) {
  useNoAuthSignInRedirect();
  useThemeRefs();

  return (
    <AppProvider>
      {children}
      <Suspense>
        <SlideOutPanel />
        <Modal />
      </Suspense>
    </AppProvider>
  );
}
