import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';

const schema = Yup.string().required(`Website is required`).url();

export const WebsiteField = ({ value, update, onSave }) => {
  const [touched, setTouched] = useState(false);
  const [invalid, setInvalid] = useState(true);

  useEffect(() => {
    const setError = msg => invalid !== msg && setInvalid(msg);

    schema
      .validate(value)
      .then(() => setError(false))
      .catch(({ message }) => setError(message));
  }, [value, setInvalid, invalid]);

  return (
    <div className="flex items-end">
      <div>
        <h1 className="py-4 text-xl font-semibold text-gray-600">Brand Website</h1>
        <div className="flex space-x-2">
          <input
            className="bz-input"
            value={value}
            placeholder="Brand Website URL"
            onChange={({ target: { value: v } = {} }) => update(v)}
            onBlur={() => setTouched(true)}
          />
          <button
            type="button"
            disabled={invalid}
            onClick={onSave}
            className={`${invalid ? 'bg-gray-200 cursor-not-allowed' : 'bg-primary'} h-12 px-2 rounded text-white`}
          >
            save
          </button>
        </div>
        <div className="my-1 h-5 flex items-center">
          {touched && invalid && <p className="text-primary h-full">{invalid}</p>}
        </div>
      </div>
    </div>
  );
};
