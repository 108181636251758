import React, { useEffect, useState, useRef, useMemo } from 'react';
import { get, isEqual, mapValues, pick } from 'lodash';
import { API } from 'aws-amplify';
import { setUpBrand } from 'src/graphql/mutations';
import { useGAS } from 'src/GlobalAppState/context';
import { LogoIcon } from 'src/components/shared';
import { BzS3 } from 'src/helpers';
import { updateBrand } from 'src/helpers/brand';
import { CloseIcon, CheckIcon, EditIcon } from 'src/svgs';
import * as Yup from 'yup';
import { IndustryCategoryDropdown, SettingsWebsiteInput } from 'src/components/MainSettings/BrandSettings/formFields';
import { AdditionalSettings } from 'src/components/MainSettings/BrandSettings/AdditionalSettings';
import { getUrlRegex } from 'src/helpers/urlValidator';
import { invalidPoliticalSettings } from 'src/components/MainSettings/BrandSettings/AdditionalSettings/helpers';
import { Headline } from 'src/components/MainSettings/shared/Headers';

const brandComparisonAttributes = ['name', 'websiteUrl', 'logoUrl', 'industryCategoryId', 'politicalSettings'];

const getBrandErrorMessage = brand => {
  if (brand.error?.includes('property is required')) {
    return 'Missing required field';
  }

  if (brand.error?.includes('DomainAddress must be in the form of http(s)')) {
    return 'Invalid website URL';
  }

  return 'Brand setup failed';
};

const convertObjectArrays = obj =>
  mapValues(obj, value => (Array.isArray(value) ? value.map(v => get(v, 'pathKey') || get(v, 'name')) : value));

const pickBrandAttrs = brand => convertObjectArrays(pick(brand, brandComparisonAttributes));

const diffBrand = (draft = {}, original = {}) => !isEqual(pickBrandAttrs(draft), pickBrandAttrs(original));

const isValid = (indCatId, brandDraft) => {
  const baseValid = !!(indCatId && brandDraft.name && brandDraft.websiteUrl);

  if (indCatId === 4385) {
    return !invalidPoliticalSettings(brandDraft.politicalSettings || {}) && baseValid;
  }

  return baseValid;
};

export default function BrandSettingsEditPage() {
  const { brand, routeIds, organization, userAuth } = useGAS();
  const { authLevel } = userAuth;
  const { teamId, organizationId } = routeIds || {};
  const [brandDraft, setBrandDraft] = useState(false);

  const errorMsg = getBrandErrorMessage(brand);
  const showError = brand.error && !brand.isValid;

  const btnRef = useRef();

  let indCatId = brandDraft.industryCategoryId || organization?.accountInfo?.industryCategoryId;

  if (authLevel === 'team') {
    indCatId = brandDraft.industryCategoryId;
  }
  const updateField = (key, value) => {
    let val = value;

    if (key === 'websiteUrl') {
      val = (value || '').replace('https://https://', 'https://').replace('http://http://', 'http://');
    }

    setBrandDraft({ ...brandDraft, [key]: val });
  };

  useEffect(() => {
    if (brand) {
      setBrandDraft(brand);
    }
  }, [brand]);

  const urlRegex = getUrlRegex();
  const schema = {
    name: Yup.string().required(`Name is required`),
    website: Yup.string()
      .required(`URL is required`)
      .url('Must be a valid URL')
      .matches(urlRegex, 'Must be a valid URL'),
  };

  const changed = useMemo(() => diffBrand(brandDraft, brand), [brandDraft, brand]);

  const onUpload = ({ target: { files = [] } = {} }) => {
    const [file] = files;

    if (file) {
      BzS3.getModelBaseKey(brandDraft, 'account-protected').then(baseKey => {
        const key = `${baseKey}/logos/${Date.now()}`;

        return BzS3.put(key, file).then(() => updateField('logoUrl', key));
      });
    }
  };

  const valid = isValid(indCatId, brandDraft);

  const disableSave = !valid || !changed;

  const industryCategory = brandDraft?.industryCategoryId || indCatId;

  const saveDraft = () => {
    if (valid) {
      updateBrand(brandDraft).then(() => {
        const input = {
          id: brandDraft?.id || '',
          teamId,
          organizationId,
          name: brandDraft?.name || '',
          websiteUrl: brandDraft?.websiteUrl,
          industryCategoryId: industryCategory,
        };

        API.graphql({ query: setUpBrand, fetchPolicy: 'no-cache', variables: { input } });
      });
    }
  };

  return (
    <>
      <Headline>{brand.name}</Headline>
      <div className="flex flex-row">
        <div className="h-8 w-40 z-0 absolute right-5 sm:right-12 text-center top-2 sm:top-7 pt-1 rounded hover:bg-gray-100">
          <button
            type="button"
            onClick={saveDraft}
            disabled={disableSave}
            className={`text-primary ${disableSave && 'cursor-not-allowed disabled:opacity-30 '}`}
            data-testid="saveChanges"
          >
            {disableSave ? <span className="ml-1">&#10003; Changes Saved</span> : 'Save Changes'}
          </button>
        </div>
        <div className="flex flex-col items-center space-y-8 p-8">
          <div className="bg-blueGray-150 rounded-full p-12">
            <LogoIcon className="w-32 h-32" model={brandDraft} />
          </div>
          <div className="relative">
            <input
              ref={btnRef}
              type="file"
              accept="image/*"
              id="file"
              className="file w-0 h-0 absolute"
              onChange={onUpload}
            />
            <label htmlFor="file" className="cursor-pointer">
              {!brand ? (
                <button
                  disabled
                  type="button"
                  onClick={() => btnRef.current.click()}
                  className="h-12 flex items-center justify-center space-x-2 rounded border-2 border-primary py-2 px-4 bg-primary text-white opacity-25 cursor-not-allowed"
                >
                  Upload Logo
                </button>
              ) : (
                <button type="button" onClick={() => btnRef.current.click()} className="bz-btn-solid py-3 w-full">
                  Upload Logo
                </button>
              )}
            </label>
          </div>
        </div>
        <div className="flex flex-col pl-2 sm:pt-2 space-y-8 pt-12 sm:mt-0 sm:w-1/2 md:w-5/12">
          {showError && (
            <div className="text-gray-600 bg-red-200 py-1 px-4 m-4">
              <span className="pr-2 font-bold">{errorMsg}:</span>
              Please ensure the below fields are complete. If you need further assistance, contact your account
              representative or reach out to our support team via chat.
            </div>
          )}
          <SettingsNameInput
            label="Brand Name *"
            value={brandDraft.name}
            schema={schema.name}
            onSave={val => updateField('name', val)}
            data-testid="brandNameInput"
          />
          <SettingsWebsiteInput
            label="Brand Website *"
            value={brandDraft.websiteUrl}
            schema={schema.website}
            onSave={val => updateField('websiteUrl', val)}
          />
          <IndustryCategoryDropdown value={industryCategory} onSelect={val => updateField('industryCategoryId', val)} />
        </div>
        <div className="-mt-24 pb-10 sm:pb-0 sm:m-0 sm:w-1/2 md:w-7/12 overflow-y-auto">
          <AdditionalSettings
            brandDraft={brandDraft}
            updateField={updateField}
            setBrandDraft={setBrandDraft}
            indCatId={indCatId}
          />
        </div>
      </div>
    </>
  );
}

const SettingsNameInput = ({ label, value, schema, onSave, 'data-testid': testId }) => {
  const [showEdit, setShowEdit] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [draftName, setDraftName] = useState('');

  const inputRef = useRef();

  useEffect(() => {
    if (showEdit && inputRef?.current) inputRef.current.focus();
  }, [showEdit, inputRef]);

  useEffect(() => {
    setDraftName(value || '');
  }, [value]);

  useEffect(() => {
    const setError = msg => invalid !== msg && setInvalid(msg);

    if (schema) {
      schema
        .validate(draftName)
        .then(() => setError(false))
        .catch(({ message }) => setError(message));
    }
  }, [schema, draftName, setInvalid, invalid]);

  const onSaveClick = () => {
    if (!invalid) {
      onSave(draftName);
      setShowEdit(false);
    }
  };

  const onEditClick = () => {
    if (showEdit) {
      setDraftName(value);
      setShowEdit(false);
    } else {
      setShowEdit(true);
    }
  };

  return (
    <div>
      <div className="flex items-center space-x-4 h-14">
        <button type="button" data-testid="edit" onClick={onEditClick} className="bz-btn-icon w-8 h-8 p-1">
          {!showEdit && <EditIcon className="w-full h-full text-primary stroke-current" />}
          {showEdit && <CloseIcon className="w-5 h-5 text-gray-400 stroke-current" />}
        </button>
        {!showEdit && (
          <div className="flex flex-col">
            <h2 className="font-medium">{label}</h2>
            <h2 className="text-gray-500 text-lg">{draftName}</h2>
          </div>
        )}
        {showEdit && (
          <div className="flex items-center space-x-3">
            <input
              ref={inputRef}
              type="text"
              placeholder={label}
              value={draftName}
              onChange={({ target: { value: val } = {} }) => setDraftName(val)}
              className="bz-input h-12"
              data-testid={testId}
            />
            <button type="button" data-testid="checkmark" onClick={onSaveClick} className="bz-btn-icon p-1">
              <div className="flex items-center justify-center w-8 h-8 border-2 border-green-400 rounded-full">
                <CheckIcon className="w-4 h-4 text-green-400 stroke-current" />
              </div>
            </button>
          </div>
        )}
      </div>
      {invalid && (
        <div className="flex ml-12 space-x-2 text-sm bg-orange-100 p-1 w-1/2 mt-2 ">
          <span className="mx-auto text-gray-500">{invalid}</span>
        </div>
      )}
    </div>
  );
};
