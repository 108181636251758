import {
  SettingsBody,
  SettingsPage,
  SettingsPageHeader,
  SettingsPageHeaderLink,
} from 'src/components/MainSettings/shared/Layout';

export default function TeamSettingsLayout({ children }) {
  return (
    <SettingsPage>
      <SettingsPageHeader>
        <SettingsPageHeaderLink pageKey="edit">Preferences</SettingsPageHeaderLink>
        <SettingsPageHeaderLink pageKey="users">Users</SettingsPageHeaderLink>
        <SettingsPageHeaderLink pageKey="white-label">White Label</SettingsPageHeaderLink>
        <SettingsPageHeaderLink pageKey="integrations">Integrations</SettingsPageHeaderLink>
      </SettingsPageHeader>
      <SettingsBody>{children}</SettingsBody>
    </SettingsPage>
  );
}
