import { createContext, useState, useEffect, useMemo, useContext } from 'react';
import { API } from 'aws-amplify';
import { getAccountTheme } from 'src/graphql/queries';
import { setThemeColor } from 'src/helpers';
import { getDomainInfo } from '../helpers/whitelabel';

const AuthProviderContext = createContext({});

const useTheme = () => {
  const { isWhiteLabel, subdomain } = getDomainInfo();
  const [themeData, setThemeData] = useState({ loaded: false });

  useEffect(() => {
    let mounted = true;

    if (isWhiteLabel && subdomain) {
      API.graphql({
        query: getAccountTheme,
        variables: { subdomain },
        authMode: 'API_KEY',
      })
        .then(({ data: { getAccountTheme: resp } = {} }) => (resp ? JSON.parse(resp) : {}))
        .then(({ primary, secondary, logoUrl, name }) => {
          if (mounted) {
            setThemeData({
              logoUrl,
              name,
              loaded: true,
            });
            if (primary) setThemeColor('primary', primary);
            if (primary) setThemeColor('secondary-dark', secondary);
            setThemeColor('primary-org', primary || '#f06290');
            setThemeColor('secondary-org', secondary || '#03012B');
          }
        });
    } else {
      setThemeData({ loaded: true });
      setThemeColor('primary-org', '#f06290');
      setThemeColor('secondary-org', '#03012B');
    }

    return () => {
      mounted = false;
      setThemeData({ loaded: false });
    };
  }, [isWhiteLabel, subdomain]);

  return themeData;
};

export const AuthProvider = ({ children }) => {
  const { isWhiteLabel } = getDomainInfo();
  const { name: accName, logoUrl: logo } = useTheme();

  const contextValue = useMemo(
    () => ({
      isWhiteLabel,
      logo,
      accName,
    }),
    [isWhiteLabel, logo, accName]
  );

  return <AuthProviderContext.Provider value={contextValue}>{children}</AuthProviderContext.Provider>;
};

export const useAuthProvider = () => useContext(AuthProviderContext);
