import { useGAS } from 'src/GlobalAppState/context';
import { selectCampaignDraftValidForPublish } from 'src/selectors/campaign/summarySelectors';
import { useOrganizationMinBudget } from 'src/helpers/organization/useOrganizationMinBudget';
import { usePublishHandler } from './usePublishHandler';
import { useCreativesContext } from '../../CreativesContext';

export const SaveAndPublishButton = ({ campaignDraft, draftChanged, brandError }) => {
  const { campaign, brandTrackingTags } = useGAS();
  const creatives = useCreativesContext();
  const orgMinimum = useOrganizationMinBudget();
  const draftValidForPublish = selectCampaignDraftValidForPublish(campaignDraft, campaign, {
    brandTrackingTags,
    orgMinimum,
  });

  const onApprove = usePublishHandler(campaignDraft);

  const disabled = !draftValidForPublish || (creatives?.length || 0) < 1 || brandError || !draftChanged;

  return (
    <button
      type="button"
      onClick={onApprove}
      disabled={disabled}
      className="bz-btn-solid w-full h-12 2xl:h-16 disabled:cursor-default hover:opacity-80 disabled:opacity-40 rounded"
    >
      Publish Update
    </button>
  );
};
