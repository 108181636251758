import { MainListHeader } from 'src/components/MainListView/MainListHeader/MainListHeader';

export default function TeamLayout({ children }) {
  return (
    <>
      <MainListHeader />
      {children}
    </>
  );
}
