import { useState } from 'react';
import { useGAS } from 'src/GlobalAppState/context';
import { SavedPaymentMethods } from 'src/components/SavedPaymentMethods';

export default function BrandPaymentMethodsPage() {
  const [paymentMethodType, setPaymentMethodType] = useState('card');
  const { brandPaymentMethods } = useGAS();

  const paymentMethods = brandPaymentMethods[paymentMethodType] ?? [];

  return (
    <div className="flex flex-col space-y-8">
      <h1 className="text-gray-800 text-3xl whitespace-nowrap">Payment Methods</h1>
      <div className="flex flex-row items-center justify-center">
        <SavedPaymentMethods
          paymentMethods={paymentMethods}
          paymentMethodType={paymentMethodType}
          setPaymentMethodType={setPaymentMethodType}
          model="brand"
        />
      </div>
    </div>
  );
}
