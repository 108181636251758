import { API } from 'aws-amplify';
import { brandFields } from '../brand/brandFields';

const getTeamBrandsQuery = /* GraphQL */ `
  query getTeam($id: ID!, $nextToken: String) {
    getTeam(id: $id) {
      brands(nextToken: $nextToken) {
        nextToken
        items {
          ${brandFields}
        }
      }
    }
  }
`;

export const getTeamBrands = ({ teamId }) => {
  if (!teamId) return Promise.resolve([]);

  const getPage = next =>
    API.graphql({
      query: getTeamBrandsQuery,
      variables: { id: teamId, nextToken: next },
    }).then(
      ({
        data: {
          getTeam: {
            brands: { items, nextToken },
          },
        },
      }) => {
        if (nextToken) return getPage(nextToken).then(resp => [...items, ...resp]);

        return items;
      }
    );

  return getPage().then(resp => resp.filter(itm => !itm.archived));
};
