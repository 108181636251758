import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Storage } from 'aws-amplify';
import { BzS3, genWaveformSvg } from 'src/helpers';
import { updateCreative } from 'src/helpers/creative';
import { useModal } from 'src/contexts';
import { useClickAway } from 'react-use';
import { useGAS } from 'src/GlobalAppState/context';

const noop = () => null;

const getCreativeComponent = creativeType => {
  switch (creativeType) {
    case 'audio':
      return AudioCard;
    case 'video':
      return VideoCard;
    case 'display':
      return DisplayCard;
    default:
      return noop;
  }
};

export const CreativeCard = ({
  creative,
  onClick,
  modalData,
  type = 'builder',
  isActive = false,
  setCreativesChanged,
  readOnly = false,
}) => {
  const { creativeType } = creative;
  const { campaign } = useGAS();
  const { openModal } = useModal();

  const onOpenDeleteConfirm = useCallback(
    e => {
      if (readOnly) {
        return;
      }

      e.stopPropagation();
      setCreativesChanged(true);
      const modalOptions = {
        creativeId: creative.id,
        onDeleteConfirm: null,
      };

      openModal('creative-delete-confirmation', modalOptions);
    },
    [creative.id, openModal, readOnly, setCreativesChanged]
  );

  const Card = getCreativeComponent(creativeType);

  return (
    <div className="flex flex-col flex-1">
      <button
        type="button"
        onClick={onClick}
        className="border rounded flex-1 flex flex-col bg-white transform hover:bg-gray-50 p-2"
      >
        <Card creative={creative} modalData={modalData} type={type} isActive={isActive} campaign={campaign} />
      </button>
      {!readOnly && (
        <button
          type="button"
          className="p-1 flex-0 hover:text-primary underline text-xs self-end text-right"
          onClick={onOpenDeleteConfirm}
        >
          Delete
        </button>
      )}
    </div>
  );
};

const VideoCard = ({ creative, modalData, type, isActive }) => {
  const [thumbnail, setThumbnail] = useState(false);
  const [metadata, setMetadata] = useState(false);
  const { s3Key, name } = creative;
  let headerClass = 'font-medium';
  let descClass = 'text-primary';

  if (type === 'analytics') {
    headerClass = 'font-medium text-gray-400';
    descClass = 'text-gray-400 text-left';
    if (isActive) {
      headerClass = 'font-medium text-primary text-center';
      descClass = 'text-primary';
    }
  }

  useEffect(() => {
    let mounted = true;
    const { updated, metadata: meta = {} } = modalData || {};

    if (updated === creative?.id && meta) {
      if (meta.thumbnail) setThumbnail(meta.thumbnail);
      if (meta) setMetadata(meta);
    } else if (s3Key) {
      getMetadata(`${s3Key}-thumbnail`).then(md => mounted && setMetadata(md));
      Storage.get(`${s3Key}-thumbnail`)
        .then(url => getThumbnail(url))
        .then(data => mounted && setThumbnail(data));
    }

    return () => (mounted = false);
  }, [modalData, creative?.id, s3Key]);

  return (
    <div className="w-full h-full flex flex-col items-center">
      <h1 className={`${headerClass} text-center text-sm font-medium pt-2 px-3 pb-1 w-52 truncate`}>{name}</h1>
      <div className="h-44 flex items-center">
        {thumbnail && <img alt="thumbnail" className="max-w-44 max-h-full" src={thumbnail} />}
      </div>
      <h1 className={`${descClass} text-sm pb-3}`}>
        {metadata?.duration && `${Math.floor(metadata?.duration)} seconds`}
      </h1>
    </div>
  );
};

const AudioCard = ({ creative, modalData, isActive, type }) => {
  const [thumbnail, setThumbnail] = useState(false);
  const [metadata, setMetadata] = useState(false);
  const [svgParams, setSvgParams] = useState(false);
  const [companionThumbnail, setCompanionThumbnail] = useState(false);
  const { s3Key, name } = creative;
  let headerClass = 'font-medium';

  if (isActive) headerClass = 'font-bold text-primary';

  useEffect(() => {
    let mounted = true;
    const { updated, metadata: meta = {} } = modalData;

    if (updated === creative?.id && meta) {
      const params = genWaveformSvg(meta.waveform?.split(','));

      if (params) setSvgParams(params);
      if (meta) setMetadata(meta);
    } else if (s3Key) {
      const getAssets = async () => {
        await getMetadata(`${s3Key}-thumbnail`).then(m => {
          if (mounted) setMetadata(m);
          if (!m.waveform) {
            return Storage.get(`${s3Key}-thumbnail`)
              .then(url => getThumbnail(url))
              .then(data => mounted && setThumbnail(data));
          }

          const params = genWaveformSvg(m.waveform.split(','));

          return setSvgParams(params);
        });
        await Storage.get(`${s3Key}-companion`)
          .then(url => getThumbnail(url))
          .then(data => mounted && setCompanionThumbnail(data));
      };

      getAssets();
    }

    return () => (mounted = false);
  }, [modalData, creative?.id, s3Key]);

  return (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <h1 className={`${headerClass} text-center text-sm font-medium pt-2 px-3 pb-1 w-52 truncate`}>{name}</h1>
      <div className="flex-1 flex items-center">
        {svgParams && (
          <div className="text-primary">
            <svg
              className="w-44 text-primary"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              fill="none"
              viewBox={svgParams.viewBox}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path strokeWidth={16} d={svgParams.path} />
            </svg>
          </div>
        )}
        {thumbnail && <img alt="thumbnail" className="w-44" src={thumbnail} />}
      </div>
      <h1 className="text-primary text-sm pb-3">{metadata?.duration && `${Math.floor(metadata?.duration)} seconds`}</h1>
      <div className={`absolute right-1 bottom-1 ${type === 'analytics' ? 'w-20 pl-1' : 'w-24'}`}>
        {companionThumbnail && (
          <img alt="companionThumbnail" className="bg-gray-100 w-full p-0 m-0" src={companionThumbnail} />
        )}
      </div>
    </div>
  );
};

const DisplayCard = ({ creative, modalData, type, isActive }) => {
  const [thumbnail, setThumbnail] = useState(false);
  const { s3Key, name } = creative;
  let headerClass = 'font-medium text-left';

  if (type === 'analytics') {
    headerClass = 'font-medium text-gray-400 text-center';
    if (isActive) headerClass = 'font-medium text-primary text-center';
  }

  useEffect(() => {
    let mounted = true;
    const { updated, metadata: meta = {} } = modalData;

    if (updated === creative?.id && meta?.thumbnail) {
      if (meta.thumbnail) setThumbnail(meta.thumbnail);
    } else if (s3Key) {
      Storage.get(`${s3Key}-thumbnail`)
        .then(url => getThumbnail(url))
        .then(data => mounted && setThumbnail(data));
    }

    return () => (mounted = false);
  }, [modalData, creative?.id, s3Key]);

  return (
    <div className="w-full h-full flex flex-col items-center relative">
      <h1 className={`${headerClass}  text-center text-sm pt-2 px-3 pb-1 w-48 truncate`}>{name}</h1>
      <div className="flex-1 flex items-center">
        {thumbnail && <img alt="thumbnail" className="h-32" src={thumbnail} />}
      </div>
    </div>
  );
};

const getThumbnail = url => {
  let retryCount = 0;

  const tryFetch = () =>
    fetch(url)
      .then(resp => {
        if (resp instanceof Error || !resp.ok) {
          throw resp;
        } else {
          return resp.arrayBuffer();
        }
      })
      .then(buffer => window.URL.createObjectURL(new Blob([buffer])))
      .catch(err => {
        const { response: { status: respStatus } = {}, status: errStatus } = err || {};
        const status = errStatus || respStatus;

        if (status !== 404 || retryCount > 2) {
          throw err;
        } else {
          return new Promise((resolve, reject) => {
            retryCount += 1;
            console.log('retrying', retryCount);

            setTimeout(() => {
              tryFetch().then(resolve).catch(reject);
            }, 1000);
          });
        }
      });

  return tryFetch();
};

const getMetadata = s3Key => {
  let retryCount = 0;

  const tryFetch = () =>
    BzS3.getMetadata(s3Key).catch(err => {
      const { message } = err || {};

      if (message !== 'NotFound' || retryCount > 5) {
        throw err;
      } else {
        return new Promise((resolve, reject) => {
          retryCount += 1;
          console.log('retrying', retryCount);

          setTimeout(() => {
            tryFetch().then(resolve).catch(reject);
          }, 1000);
        });
      }
    });

  return tryFetch();
};

const CreativeDeleteConfirmation = ({ appParams, options }) => {
  const [isDisabled, setDisabled] = useState(false);
  const { campaign } = appParams;
  const { setModalData } = useModal();
  const clickAwayRef = useRef();

  const onClose = (resp = { canceled: true }) => {
    if (resp.updated || resp.deleted) setModalData(resp);
  };

  useEffect(() => {
    if (campaign.status === 'completed') {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [campaign.status]);

  const handleDelete = () => {
    const { creativeId } = options;

    updateCreative({ id: creativeId, archived: true }).then(() => onClose({ deleted: true }));
  };

  const onCancel = (resp = { canceled: true }) => {
    setModalData(resp);
  };

  useClickAway(clickAwayRef, () => {
    onCancel();
  });

  return (
    <div ref={clickAwayRef} className="w-full max-w-2xl rounded py-12 bg-white shadow text-center space-y-8">
      <h2 className="pb-3">Are you sure you want to delete this creative?</h2>
      <button type="button" disabled={isDisabled} className="m-2 shadow p-2 rounded border w-32" onClick={onCancel}>
        Cancel
      </button>
      <button type="button" disabled={isDisabled} className="m-2 shadow p-2 rounded border w-32" onClick={handleDelete}>
        Delete
      </button>
    </div>
  );
};

export default CreativeDeleteConfirmation;
