export const genWaveformSvg = peakInputs => {
  const yScale = 400 / Math.max(...peakInputs);
  const peaks = peakInputs.map(p => Math.round(p * yScale));
  const vw = peaks.length * 80 + 200;
  const vh = Math.max(...peaks) * 2;
  const center = vh / 2 + 50;
  const viewBox = `0 0 ${vw} ${vh + 100}`;
  const path = peaks
    .map((peak, idx) => {
      const startX = idx * 80 + 100;
      const endX = idx * 80 + 140;
      const topY = center - peak;
      const botY = center + peak;
      const arc = `L${startX} ${topY} A20 20 0 0 1 ${endX} ${topY} L${endX} ${botY} A20 20 1 1 0 ${endX + 40} ${botY}`;

      return arc;
    })
    .join(' ');

  const start = `M0 ${center} L100 ${center}`;
  const end = `L${peakInputs.length * 80 + 100} ${center}L${vw} ${center}`;
  const fullPath = `${start} ${path} ${end}`;

  return { viewBox, path: fullPath };
};
