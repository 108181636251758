import { useCallback } from 'react';
import { PiBrowsersLight } from 'react-icons/pi';
import { without } from 'lodash';
import { useGAS } from 'src/GlobalAppState/context';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from 'src/components/shared/TableComponent/TableV2';
import { Check } from 'src/components/shared/Check';
import { BuilderPanel } from '../BuilderPanel';

const addOrRemove = (ary, item) => (ary.includes(item) ? without(ary, item) : [...ary, item]);

export const SiteLists = ({ campaignDraft, setCampaignDraft }) => {
  const { managedSitelists } = useGAS();

  const onClick = useCallback(
    event => {
      const id = event.target.value;

      setCampaignDraft({
        ...campaignDraft,
        bidlists: addOrRemove(campaignDraft.bidlists ?? [], id),
      });
    },
    [campaignDraft, setCampaignDraft]
  );

  if (!managedSitelists.length) {
    return null;
  }

  return (
    <BuilderPanel icon={PiBrowsersLight} title="Site Lists">
      <div className="w-full flex-1 flex flex-col justify-center items-center space-y-4">
        <Table className="w-full text-left">
          <TableHead>
            <TableRow>
              <TableHeadCell>Site List Name</TableHeadCell>
              <TableHeadCell>Description</TableHeadCell>
              <TableHeadCell>Type</TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {managedSitelists?.map(list => (
              <TableRow key={list.id}>
                <TableCell>
                  <label className="flex flex-row space-x-4 items-center cursor-pointer">
                    <Check
                      name={`siteList-${list.id}`}
                      value={list.id}
                      checked={campaignDraft.bidlists?.includes(list.id)}
                      onChange={onClick}
                    />
                    <span>{list.name}</span>
                  </label>
                </TableCell>
                <TableCell>{list.description}</TableCell>
                <TableCell>{list.adjustmentType}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <p className="text-left w-full text-sm">
          These lists have been created for you and are managed by our team. If you have questions, please reach out to
          your representative.
        </p>
      </div>
    </BuilderPanel>
  );
};
