import React, { useState, useEffect, useMemo } from 'react';
import { ChevronLeft } from 'src/svgs';
import { shortNumber } from 'src/helpers';

export const AudienceTypeFirstParty = ({ campaignDraft, setCampaignDraft, setAppRoute, appParams }) => {
  const { brand = {}, brandTrackingTags: trackingTags } = appParams || {};

  const retargetingTags = campaignDraft?.retargetingTags || [];
  const setRetargetingTags = tags => setCampaignDraft({ ...campaignDraft, retargetingTags: tags || [] });

  const firstPartyTags = useMemo(
    () =>
      (trackingTags || [])?.filter(
        ({ trackingTagType: tagtype, pixelAudienceSize, remainingDays }) =>
          tagtype === 'firstParty' && pixelAudienceSize > 0 && remainingDays > 0
      ),
    [trackingTags]
  );

  const setAudienceType = aType => setCampaignDraft({ ...campaignDraft, audienceType: aType });
  const [isDisabled, setDisabled] = useState(false);

  useEffect(() => {
    if (campaignDraft.status === 'completed') {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [campaignDraft.status]);

  const onSettingsClick = () => setAppRoute({ model: 'brand', id: brand?.id, page: 'firstParty' });
  const goBack = () => setAudienceType(false);

  const showFirstParty = firstPartyTags?.length > 0;

  return (
    <div className="flex flex-col justify-center items-center w-full mb-16">
      <div className="w-full pt-2 pb-4 px-3 -ml-1">
        <div className="flex items-center space-x-4">
          <button
            disabled={isDisabled}
            type="button"
            onClick={() => setAudienceType(false)}
            className={`flex space-x-4 ${isDisabled ? 'opacity-25 cursor-not-allowed' : ''}`}
          >
            <div className="w-6 h-6 p-1 rounded bg-primary flex justify-center items-center">
              <ChevronLeft className="stroke-current text-white h-full -ml-px" />
            </div>
            <h3 className="text-primary">Targeting section</h3>
          </button>
          <h3 className="text-primary">/</h3>
          <h3 className="text-primary">Build an audience with first party data</h3>
        </div>
      </div>
      <div className="flex-1 w-full flex items-center justify-center">
        {showFirstParty && (
          <HasFirstParty
            brand={brand}
            trackingTags={firstPartyTags}
            retargetingTags={retargetingTags}
            setRetargetingTags={setRetargetingTags}
          />
        )}
        {!showFirstParty && <NoFirstParty onSettingsClick={onSettingsClick} goBack={goBack} />}
      </div>
    </div>
  );
};

const NoFirstParty = ({ onSettingsClick, goBack }) => (
  <div className="w-full max-w-xl flex flex-col justify-center items-center space-y-4">
    <h3 className="text-gray-900 text-lg font-semibold text-center">You don't have First Party Data</h3>
    <h3 className="text-gray-900 text-center text-sm pb-5">
      You need to upload your first party data and allow time for processing. You can do this through the brand
      settings, or you can go back and pick a different selection.
    </h3>
    <div className="w-full flex space-x-4">
      <button
        type="button"
        onClick={onSettingsClick}
        className="w-full max-w-md bg-primary rounded py-3 text-white font-semibold"
      >
        View brand settings
      </button>
      <button
        type="button"
        onClick={goBack}
        className="w-full max-w-md border-2 border-primary rounded py-3 text-primary font-semibold"
      >
        Go back
      </button>
    </div>
  </div>
);

const HasFirstParty = ({ trackingTags, retargetingTags, setRetargetingTags }) => {
  const tagRows = (trackingTags || [])
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .map(tag => (
      <TrackingTagRow
        key={tag.id}
        tagType="tracking"
        tag={tag}
        retargetingTags={retargetingTags}
        setRetargetingTags={setRetargetingTags}
      />
    ));

  return (
    <div className="w-full flex-1 flex flex-col items-center p-4 pt-8">
      <div className="flex w-full justify-between mt-4 pl-2">
        <div className="text-gray-900 font-semibold flex-1">Target users from your First Party Data</div>
        <div className="w-28 text-center">Audience Size</div>
        <div className="w-28 text-center">Days Remaining</div>
      </div>
      <div className="w-full max-h-60 overflow-scroll pt-4 pb-8">
        <div className="flex-1 w-full flex items-center">
          <div className="w-full flex flex-col rounded-lg shadow-inner border">
            <div>{tagRows}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TrackingTagRow = ({ tag, tagType, retargetingTags, setRetargetingTags }) => {
  const active = (retargetingTags || []).map(t => t.trackingTagId || t.dspId).includes(tag.id);
  const onSelect = () =>
    active
      ? setRetargetingTags((retargetingTags || []).filter(t => (t.trackingTagId || t.dspId) !== tag.id))
      : setRetargetingTags([
          ...retargetingTags,
          tagType === 'universal'
            ? { dspId: tag.id, firstPartyDataId: tag.firstPartyDataId }
            : { trackingTagId: tag.id },
        ]);

  return (
    <button
      type="button"
      key={tag.id}
      onClick={onSelect}
      className={`w-full flex items-center h-16 border-b p-4 first:rounded-t-lg last:rounded-b-lg ${
        active ? 'bg-blue-100 bg-opacity-25' : ' hover:bg-blueGray-50'
      }`}
    >
      <div className="w-12">
        <div
          className={`w-6 h-6 rounded-full border ${
            active ? 'bg-primary border-primary' : 'bg-gray-100 border-gray-300'
          }`}
        />
      </div>
      <div className="flex-1 text-left">
        <h3 className="truncate text-xl font-medium">{tag.name}</h3>
      </div>
      <div className="pl-8 w-28">
        <h3 className="text-xl text-center">{shortNumber(tag.pixelAudienceSize, 0, true)}</h3>
      </div>
      <div className="pl-8 w-28">
        <h3 className="text-xl text-center">{shortNumber(tag.remainingDays, 0, true)}</h3>
      </div>
    </button>
  );
};
