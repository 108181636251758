export const campaignFields = /* GraphQL */ `
  id
  archived
  adminGroups
  viewerGroups
  createdAt
  updatedAt
  stoppedAt
  lastRecurredAt
  organizationId
  teamId
  brandId
  status
  recurrenceWindow
  recurrenceBudget
  name
  goal
  budget
  originalBudget
  startDate
  endDate
  originalEndDate
  audience
  audienceType
  audienceFrom
  bidlists
  retargetingTags {
    dspId
    trackingTagId
    firstPartyDataId
  }
  conversionEvents {
    id
    type
  }
  geoSegments {
    id
    segmentPackageId
    dspId
    name
    pathKey
    segmentType
    segmentGroup
  }
  dmpSegments {
    id
    segmentPackageId
    dspId
    name
    pathKey
    segmentType
    segmentGroup
  }
  inventorySegments {
    id
    segmentPackageId
    dspId
    name
    pathKey
    segmentType
    segmentGroup
  }
  timesOfDay
  daysOfWeek
  ctvSegments
  creatives {
    items {
      id
      archived
      createdAt
      updatedAt
      uploadedAt
      publishedAt
      adminGroups
      viewerGroups
      organizationId
      teamBrandCampaign
      teamId
      brandId
      campaignId
      teamBrandCampaignId
      name
      fileName
      creativeType
      s3Key
      s3ThumbnailKey
      clickthroughUrl
      landingPageUrl
      inApp
      web
      ctv
      devices
      ttdId
      ttdStatus
      isValid
      error
      campaignCreativesId
    }
    nextToken
  }
  forecastRequestedAt
  forecastAdgroups
  publishedAt
  forecast {
    requestedAt
    createdAt
    impressions {
      max
      min
    }
    reach {
      max
      min
    }
    frequency {
      max
      min
    }
    spend {
      max
      min
    }
    results {
      adgroupType
      impressions {
        max
        min
      }
      reach {
        max
        min
      }
      frequency {
        max
        min
      }
      spend {
        max
        min
      }
    }
  }
  conversionEvents {
    id
    type
  }
  ttdId
  conversionsEnabled
  trackingTagId
  pixelTrackingUrl
  ttdStatus
  summaryMetrics {
    items {
      summaryType
      impressions
      clicks
      views
      spend
      conversions
      graphs {
        impressions
        clicks
        views
        spend
        conversions
      }
    }
    total
  }
  ttdUpdatedAt
  activeUpdatedAt
  sfOpportunityId
  isRecurring
  isValid
  error
  brandCampaignsId
  isMultiDataGroups
  campaignPoliticalSettings {
    type
  }
`;
