import { campaignFields } from 'src/helpers/campaign/campaignFields';
import { createAPIQueryHook, createAPIQuerySubscriptionHook } from '../../hooks/useAPIQuery';

const getBrandCampaigns = /* GraphQL */ `
  query brandCampaigns($id: ID!, $nextToken: String) {
    searchCampaigns(filter: { brandId: { eq: $id }}, nextToken: $nextToken) {
      nextToken
      items {
        ${campaignFields}
      }
    }
  }
`;

const getCampaign = /* GraphQL */ `
  query GetCampaign($id: ID!) {
    result: getCampaign(id: $id) {
      ${campaignFields}
    }
  }
`;

const useBrandCampaignQuery = createAPIQueryHook({
  query: getBrandCampaigns,
  paged: true,
  nextTokenPath: 'searchCampaigns.nextToken',
  resultPath: 'searchCampaigns.items',
});

const onCreateCampaign = /* GraphQL */ `
  subscription OnCreateCampaign($organizationId: String, $teamId: String, $brandId: String) {
    create: onCreateCampaign(organizationId: $organizationId, teamId: $teamId, brandId: $brandId) {
      id
      brandId
      teamId
      organizationId
    }
  }
`;

const useBrandCampaignCreateSubscription = createAPIQuerySubscriptionHook({
  subscription: onCreateCampaign,
  query: getCampaign,
});

const onUpdateCampaign = /* GraphQL */ `
  subscription OnUpdateCampaign($organizationId: String, $teamId: String, $brandId: String) {
    update: onUpdateCampaign(organizationId: $organizationId, teamId: $teamId, brandId: $brandId) {
      id
      brandId
      teamId
      organizationId
    }
  }
`;

const useBrandCampaignUpdateSubscription = createAPIQuerySubscriptionHook({
  subscription: onUpdateCampaign,
  query: getCampaign,
});

const useBrandCampaignsSubscription = (...args) => {
  useBrandCampaignCreateSubscription(...args);
  useBrandCampaignUpdateSubscription(...args);
};

export const useBrandCampaigns = ({ organizationId, teamId, brandId }) => {
  const disable = !organizationId || !teamId || !brandId;
  const { data: campaigns, subscriptionHandler, loading } = useBrandCampaignQuery({ id: brandId }, { disable });

  useBrandCampaignsSubscription({ organizationId, teamId, brandId }, { subscriptionHandler, disable });

  return {
    brandCampaigns: campaigns ?? [],
    loading,
  };
};
