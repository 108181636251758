import React, { useState, useEffect } from 'react';
import { animated, useSpring } from 'react-spring';
import { ChevronDown } from 'src/svgs';

const days = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
const tods = ['Overnight', 'Morning', 'Afternoon', 'Night'];

export const AdvancedPanel = ({ campaignDraft, setCampaignDraft, status }) => {
  const [show, setShow] = useState(false);
  const { daysOfWeek = [], timesOfDay = [] } = campaignDraft;
  const springProps = show ? { height: 260, opacity: 1 } : { height: 0, opacity: 0 };
  const containerStyle = useSpring(springProps);
  const chevronStyle = useSpring({ rotate: show ? 0 : 90 });
  const [isDisabled, setDisabled] = useState(false);

  useEffect(() => {
    if (status === 'completed') {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [status]);

  useEffect(() => {
    setShow(false);
  }, [campaignDraft.id]);

  const toggleDay = day => {
    const set = new Set(daysOfWeek || days);

    if (set.has(day)) {
      set.delete(day);
    } else {
      set.add(day);
    }
    if (set.size === 0) days.map(d => set.add(d));
    const arr = Array.from(set);

    setCampaignDraft({ ...campaignDraft, daysOfWeek: arr });
  };

  const toggleTod = tod => {
    const set = new Set(timesOfDay || tods);

    if (set.has(tod)) {
      set.delete(tod);
    } else {
      set.add(tod);
    }
    if (set.size === 0) tods.map(d => set.add(d));
    const arr = Array.from(set);

    setCampaignDraft({ ...campaignDraft, timesOfDay: arr });
  };

  const dayButtons = days.map(day => {
    const arr = daysOfWeek || days;
    const selected = arr.includes(day);

    return (
      <div
        className={`w-full flex flex-col overflow-hidden rounded ${
          isDisabled ? 'relative opacity-25 cursor-not-allowed z-40' : 'bg-primary'
        }`}
        key={day}
      >
        <button
          key={day}
          disabled={isDisabled}
          type="button"
          onClick={() => toggleDay(day)}
          className={`flex-1 py-1 rounded border-2 border-primary font-medium ${
            selected ? 'text-white bg-primary' : 'bg-white text-primary'
          }`}
        >
          {day}
        </button>
      </div>
    );
  });

  const todButtons = tods.map(tod => {
    const arr = timesOfDay || tods;
    const selected = arr.includes(tod);
    let btnText = '12am - 6am';

    switch (tod) {
      case 'Overnight':
        btnText = '12am - 6am';
        break;
      case 'Morning':
        btnText = '6am - 12pm';
        break;
      case 'Afternoon':
        btnText = '12pm - 6pm';
        break;
      case 'Night':
        btnText = '6pm - 12am';
        break;
      default:
        btnText = '12am - 6am';
    }

    return (
      <div
        className={`w-full flex flex-col overflow-hidden rounded ${
          isDisabled ? ' opacity-25 cursor-not-allowed' : 'bg-primary'
        }`}
        key={tod}
      >
        <button
          key={tod}
          disabled={isDisabled}
          type="button"
          onClick={() => toggleTod(tod)}
          className={`flex-1 py-1 rounded border-2 border-primary text-sm sm:text-base font-medium ${
            selected ? 'text-white bg-primary' : 'bg-white text-primary'
          }`}
        >
          {btnText}
        </button>
      </div>
    );
  });

  return (
    <div className="w-full">
      <button type="button" onClick={() => setShow(!show)} className="w-full flex items-center space-x-4 py-4">
        <div className="text-primary text-medium font-semibold">Advanced</div>
        <animated.div style={chevronStyle}>
          <ChevronDown className="w-4 h-4 stroke-current text-primary" />
        </animated.div>
      </button>
      <animated.div style={containerStyle} className="w-full flex flex-col overflow-hidden">
        <p className="w-full flex text-sm sm:text-base">
          Time of Day settings correspond to the time zone where an impression is served to a user, rather than the time
          zone of your campaign.
        </p>
        <h2 className="text-base sm:text-lg font-semibold my-2 sm:my-4">Day of Week Targeting</h2>
        <div className="w-full flex h-10 space-x-2 sm:mb-4">{dayButtons}</div>
        <h2 className="text-base sm:text-lg font-semibold my-2 sm:my-4">Time of Day Targeting</h2>
        <div className="w-full flex sm:h-10 space-x-2">{todButtons}</div>
      </animated.div>
    </div>
  );
};
