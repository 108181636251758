import { Auth } from 'aws-amplify';

const signOut = () => Auth.signOut();

export const AcceptInviteSignOut = () => (
  <>
    <div className="w-full text-center">
      <h1 className="w-full text-3xl text-gray-800">This invitation is for someone else</h1>
    </div>
    <button
      type="button"
      onClick={signOut}
      className="w-full border-0 bz-btn-solid text-white mt-8 h-12 font-medium rounded-lg bg-secondary"
    >
      Sign Out
    </button>
  </>
);
