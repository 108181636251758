import React, { useState } from 'react';

const InfoPopup = ({ content, link, position }) => {
  const [isShown, setIsShown] = useState(false);

  const positions = {
    left: { top: '-0rem', left: '-17rem' },
    top: { top: '-2rem', left: '-7rem' },
    bottom: { top: '2rem', left: '-7rem' },
    right: { top: '-0rem', left: '2rem' },
  };

  const popupPosition = positions[position] || positions.top;

  return (
    <div className="popup">
      <div
        className="relative inline-block"
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
      >
        <button
          type="button"
          className="popup transform hover:scale-110 m-2 font-semibold text-xs inline-block align-middle bg-blueGray-200 border-2 border-blueGray-600 text-blueGray-600 px-1 rounded-full"
          id="btn"
        >
          ?
        </button>
        {isShown && (
          <div className="infoPopup absolute z-10 rounded p-1 pr-3" style={popupPosition}>
            <div className="flex-col pl-3 text-sm bg-gray-700 rounded-md px-2 w-64 text-white">
              {content && <div className="p-5">{content}</div>}
              {link && (
                <a className="text-primary hover:text-blue-800 visited:text-purple-600" href={link}>
                  {link}
                </a>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InfoPopup;
