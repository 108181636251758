export const MetricsDetails = ({ metrics, hasBorder = true, conversionsEnabled }) => {
  const { impressions = 0, spend = 0, clicks = 0, views = 0, conversions = 0 } = metrics || {};
  const cpm = (spend / impressions) * 1000;
  const ctr = (clicks / impressions) * 100;

  let containerClass = 'w-full flex flex-col space-y-12 justify-center p-8 border rounded-lg';

  if (!hasBorder) containerClass = 'w-full flex flex-col space-y-12 justify-center p-8 rounded-lg';

  return (
    <div className={containerClass}>
      <div className="w-full flex items-center justify-between">
        <SiteDetailItem value={impressions} label="Total Impressions" icon={ImpressionsIcon} />
        <SiteDetailItem value={views} label="Total Views" icon={ViewsIcon} />
        <SiteDetailItem value={clicks} label="Total Clicks" icon={ClicksIcon} />
        <SiteDetailItem value={spend} label="Total Spend" icon={SpendIcon} currency />
      </div>
      <div className="w-full flex items-center justify-between">
        <SiteDetailItem value={ctr} label="Average CTR" icon={CtrIcon} percent />
        <SiteDetailItem value={cpm} label="Average CPM" icon={CpmIcon} currency />
        <SiteDetailItem
          value={conversions}
          label="Total Conversions"
          icon={CnvsIcon}
          conversionsEnabled={conversionsEnabled}
        />
      </div>
      {!conversionsEnabled && (
        <div className="flex flex-col space-y-2">
          <p className="text-sm text-center">
            Conversions are disabled for this campaign. Enable conversion tracking in the campaign builder.
          </p>
        </div>
      )}
    </div>
  );
};

const SiteDetailItem = ({ value, label, icon: Icon, currency, percent, conversionsEnabled = true }) => {
  let valStr = (value || '0').toLocaleString();

  if (currency && value) {
    valStr = value.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  if (percent && value) {
    valStr = `${value.toLocaleString('en-US', {
      maximumFractionDigits: 3,
    })}%`;
  }

  if (!conversionsEnabled) valStr = 'N/A';

  return (
    <div className="flex-1 flex flex-col space-y-2 items-center">
      <Icon className="w-10 h-10 stroke-current text-primary" />
      <h1 className="text-xl font-medium">{valStr}</h1>
      <h1 className="text-gray-500">{label}</h1>
    </div>
  );
};

const ImpressionsIcon = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 33.8 31.9">
    <g data-name="Group 5649">
      <g data-name="Group 4911">
        <g data-name="Group 4910">
          <path
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M28.1 28.9 3.4 20.7A3.5 3.5 0 0 1 1 17.4v-4.7a3.5 3.5 0 0 1 2.4-3.3l24.7-8.2a3.5 3.5 0 0 1 4.6 3.3v21a3.5 3.5 0 0 1-4.6 3.4Z"
            data-name="Path 13440"
          />
        </g>
        <path
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M10 22.9a5.6 5.6 0 1 0 10.5 3.4"
          data-name="Path 13441"
        />
      </g>
    </g>
  </svg>
);

const ViewsIcon = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 39.3 30.7">
    <g data-name="Group 4916">
      <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" data-name="Group 4915">
        <path d="M23.8 11a6.1 6.1 0 1 1-8.7 0 6.1 6.1 0 0 1 8.7 0" data-name="Path 13445" />
        <path d="m37.8 23.5-6 6.2-4.2-4.1" data-name="Path 13446" />
        <path
          d="M19.4 29.7C12 29.7 5 25.6 2 19.2h0a8.8 8.8 0 0 1 0-7.8h0C5 5.1 12 1 19.4 1S33.8 5 37 11.4h0a8.9 8.9 0 0 1 1 4"
          data-name="Path 13447"
        />
      </g>
    </g>
  </svg>
);

const ClicksIcon = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 35.4 35.4">
    <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" data-name="Group 4913">
      <path d="m7.3 23.9-2.1 2" data-name="Line 62" />
      <path d="M15.6 1v2.9" data-name="Line 63" />
      <path d="m5.2 5.2 2 2" data-name="Line 64" />
      <path d="M1 15.6h2.9" data-name="Line 65" />
      <path d="m26 5.2-2.1 2" data-name="Line 66" />
      <path
        d="m20 33.7-6-18.4a1 1 0 0 1 1.3-1.4L33.7 20a1 1 0 0 1 0 2l-8.1 3.6-3.5 8.1a1 1 0 0 1-2 0Z"
        data-name="Path 13444"
      />
    </g>
  </svg>
);

const SpendIcon = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 37.5 33.8">
    <g data-name="Group 5654">
      <g
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        data-name="Group 5653"
        transform="translate(1 1)"
      >
        <path
          d="m23.6 23.9 10.7-10.7a4 4 0 0 0 0-5.6l-6.2-6.2a4 4 0 0 0-5.5 0l-12.2 12a4 4 0 0 0 0 5.7l4.9 4.8"
          data-name="Path 14158"
        />
        <rect width="35.2" height="7.7" data-name="Rectangle 1388" rx="1" transform="translate(0 24)" />
        <path d="M0 0h9.4" data-name="Line 89" />
        <path d="M0 6h6" data-name="Line 90" />
        <path d="M0 12h1.7" data-name="Line 91" />
        <path d="M12 20.7 29.9 3" data-name="Line 92" />
      </g>
    </g>
  </svg>
);

const CpmIcon = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 37.5 36.4">
    <g data-name="Group 5658">
      <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" data-name="Group 5657">
        <path d="M1 14.2h35.5" data-name="Line 93" />
        <g data-name="Group 5655">
          <path d="m18.8 29 3 3" data-name="Line 94" />
          <path d="m18.8 35 3-3" data-name="Line 95" />
        </g>
        <g data-name="Group 5656">
          <path d="m18.8 7.4-3-3" data-name="Line 96" />
          <path d="m18.8 1.4-3 3" data-name="Line 97" />
        </g>
        <path d="M21.7 32H5A4 4 0 0 1 1 28V8.3a4 4 0 0 1 4-4h3.9" data-name="Path 14159" />
        <path d="M15.8 4.4h16.8a4 4 0 0 1 3.9 4V28a4 4 0 0 1-4 4h-3.9" data-name="Path 14160" />
      </g>
    </g>
  </svg>
);

const CtrIcon = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 43.6 44.7">
    <g data-name="Group 5660">
      <g
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        data-name="Group 5659"
        transform="translate(1 1)"
      >
        <circle cx=".8" cy=".8" r=".8" data-name="Ellipse 180" transform="translate(7.9 11.1)" />
        <circle cx=".8" cy=".8" r=".8" data-name="Ellipse 181" transform="translate(18.7 11.1)" />
        <circle cx=".8" cy=".8" r=".8" data-name="Ellipse 182" transform="translate(29.5 11.1)" />
        <path
          d="M39 19.5V4.3A4.3 4.3 0 0 0 34.7 0H4.3A4.3 4.3 0 0 0 0 4.3v15.2a4.3 4.3 0 0 0 4.3 4.3H13"
          data-name="Path 14162"
        />
        <path
          strokeWidth="2.0005"
          d="M23.8 39.3 21 20.1a1 1 0 0 1 1.6-1.1l17 9.3a1 1 0 0 1-.2 2l-8.7 2-4.9 7.4a1 1 0 0 1-2-.4Z"
          data-name="Path 14163"
        />
      </g>
    </g>
  </svg>
);

const CnvsIcon = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 51.368 51.825">
    <g id="Group_6477" data-name="Group 6477" transform="translate(-2038.773 -10495.161)">
      <g id="Group_6476" data-name="Group 6476">
        <path id="Path_15797" data-name="Path 15797" d="M2039.773,10508.266h43.579" fill="none" strokeWidth="2" />
        <g id="Group_6475" data-name="Group 6475">
          <g id="Group_6474" data-name="Group 6474">
            <path
              id="Path_15798"
              data-name="Path 15798"
              d="M2087.931,10541.433l-7.263,4.193a2.422,2.422,0,0,1-2.422,0l-7.263-4.193a2.42,2.42,0,0,1-1.21-2.1v-8.56a2.422,2.422,0,0,1,1.21-2.1l7.263-4.192a2.417,2.417,0,0,1,2.422,0l7.263,4.192a2.422,2.422,0,0,1,1.21,2.1v8.56A2.421,2.421,0,0,1,2087.931,10541.433Z"
              fill="none"
              strokeWidth="3"
            />
            <path
              id="Path_15799"
              data-name="Path 15799"
              d="M2088.817,10529.565l-8.149,4.7a2.417,2.417,0,0,1-2.422,0l-8.149-4.7"
              fill="none"
              strokeWidth="3"
            />
            <line
              id="Line_153"
              data-name="Line 153"
              y1="11.358"
              transform="translate(2079.457 10534.592)"
              fill="none"
              strokeWidth="3"
            />
          </g>
        </g>
        <circle
          id="Ellipse_277"
          data-name="Ellipse 277"
          cx="1"
          cy="1"
          r="1"
          transform="translate(2047.773 10502.161)"
          fill="none"
          strokeWidth="3"
        />
        <circle
          id="Ellipse_278"
          data-name="Ellipse 278"
          cx="1"
          cy="1"
          r="1"
          transform="translate(2053.773 10502.161)"
          fill="none"
          strokeWidth="3"
        />
        <circle
          id="Ellipse_279"
          data-name="Ellipse 279"
          cx="1"
          cy="1"
          r="1"
          transform="translate(2059.773 10502.161)"
          fill="none"
          strokeWidth="3"
        />
      </g>
      <g id="Rectangle_1481" data-name="Rectangle 1481">
        <path
          id="Path_15800"
          data-name="Path 15800"
          d="M2062.446,10540.161h-11.673a11,11,0,0,1-11-11v-22a11,11,0,0,1,11-11h22a11,11,0,0,1,11,11v8"
          fill="none"
          strokeLinecap="round"
          strokeWidth="3"
        />
      </g>
    </g>
  </svg>
);
