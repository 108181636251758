import { API } from 'aws-amplify';
import { campaignFields } from './campaignFields';

const getCampaignQuery = /* GraphQL */ `
  query GetCampaign($id: ID!) {
    getCampaign(id: $id) {
      ${campaignFields}
    }
  }
`;

export const getCampaign = async campaignId =>
  API.graphql({
    query: getCampaignQuery,
    variables: {
      id: campaignId,
    },
  })
    .then(({ data: { getCampaign: resp } = {} }) => resp || false)
    .catch(({ data: { getCampaign: resp } = {} }) => resp || false);
