import { useCallback, useMemo, useRef, useState } from 'react';
import { noop } from 'lodash';
import { useClickAway, useKey } from 'react-use';
import { add } from 'date-fns';
import { CampaignDatePickerDropdown } from './CampaignDatePicker/CampaignDatePickerDropdown';
import { CampaignDatePickerButton } from './CampaignDatePicker/CampaignDatePickerButton';
import { SELECT_START, usePickerVariant } from './CampaignDatePicker/campaignDatePickerVariants';
import { HIDDEN, SETTING_END, useCampaignDatePickerOpenState } from './CampaignDatePicker/campaignDatePickerState';
import { CampaignDatePickerInfoMessage } from './CampaignDatePicker/CampaignDatePickerInfoMessage';

const toEOD = date => {
  const adjusted = new Date(date);

  adjusted.setHours(0, 0, 0, 0);
  adjusted.setHours(23, 59, 59, 999);

  return adjusted;
};

const calculateHandlerPayload = (value, campaign, status) => {
  const isRangeSelect = Array.isArray(value);
  const payload = {};

  if (isRangeSelect) {
    const [startDate, endDate] = value;

    if (startDate) {
      payload.startDate = startDate.toISOString();
    }

    if (campaign.isRecurring) {
      payload.endDate = add(startDate, { days: campaign.recurrenceWindow ?? 7 }).toISOString();
    } else if (endDate) {
      payload.endDate = toEOD(endDate).toISOString();
    }
  } else if (status === SELECT_START) {
    payload.startDate = value.toISOString();
  } else {
    payload.endDate = toEOD(value).toISOString();
  }

  return payload;
};

const useCampaignDateHandler = (campaign, onChange, status, setNextState) =>
  useCallback(
    value => {
      const payload = calculateHandlerPayload(value, campaign, status);

      setNextState();

      return onChange(payload);
    },
    [campaign, onChange, setNextState, status]
  );

const useDate = dateString => useMemo(() => new Date(dateString), [dateString]);

const useCloseRef = toggleHide => {
  const clickAwayRef = useRef();

  const handler = useCallback(() => toggleHide(true), [toggleHide]);

  useClickAway(clickAwayRef, handler);
  useKey('Escape', handler);

  return clickAwayRef;
};

const useCampaignDatePickerUX = (campaign, onChange) => {
  const variant = usePickerVariant(campaign);
  const { openState, toggleHide, setNextState } = useCampaignDatePickerOpenState(variant);
  const handler = useCampaignDateHandler(campaign, onChange, variant, setNextState);

  return {
    variant,
    handler,
    openState,
    toggleHide,
  };
};

export const CampaignDatePicker = ({ campaign, onChange = noop, className }) => {
  const [now] = useState(() => new Date());
  const startDate = useDate(campaign.startDate ?? now);
  const endDate = useDate(campaign.endDate ?? now);

  const { variant, toggleHide, handler, openState } = useCampaignDatePickerUX(campaign, onChange);
  const closeRef = useCloseRef(toggleHide);

  const settingEndDate = openState === SETTING_END;
  const hidden = openState === HIDDEN;

  return (
    <div className="w-124 flex flex-row">
      <div className={`${className} relative z-20 w-60`} ref={closeRef}>
        <CampaignDatePickerButton
          startDate={startDate}
          endDate={endDate}
          toggle={toggleHide}
          variant={variant}
          open={!hidden}
          settingEndDate={settingEndDate}
        />
        {!hidden && (
          <div className="absolute top-full">
            <CampaignDatePickerDropdown
              campaign={campaign}
              onChange={handler}
              settingEndDate={settingEndDate}
              startDate={startDate}
              endDate={endDate}
              variant={variant}
              now={now}
            />
          </div>
        )}
      </div>
      <CampaignDatePickerInfoMessage campaign={campaign} variant={variant} />
    </div>
  );
};
