import { useAppParams } from 'src/hooks/useAppParams';
import { CreditHistory } from 'src/components/MainSettings/BrandSettings/BrandSettingsCredits/CreditHistory';
import { CreditHistoryHeader } from 'src/components/MainSettings/shared/Headers';

export default function BrandCreditsPage() {
  const appParams = useAppParams();

  return (
    <div className="flex flex-1 flex-col space-y-8">
      <CreditHistoryHeader modelName="brand" />
      <CreditHistory appParams={appParams} />
    </div>
  );
}
