export const AUDIO = 'audio';
export const CTV = 'ctv';
export const DISPLAY = 'display';
export const DISPLAY_APP = 'displayApp';
export const DISPLAY_WEB = 'displayWeb';
export const WEB_VIDEO = 'webVideo';

export const INTERNAL_ADGROUP_TYPES_MAP = {
  AUDIO,
  CTV,
  DISPLAY,
  DISPLAY_APP,
  DISPLAY_WEB,
  WEB_VIDEO,
};

export const INTERNAL_ADGROUP_TYPES = Object.values(INTERNAL_ADGROUP_TYPES_MAP);

export const dailyAdgroupMinimums = {
  display: 15,
  displayApp: 15,
  displayWeb: 15,
  webVideo: 20,
  audio: 30,
  ctv: 50,
};

export const adgroupDisplayNames = {
  display: 'Display',
  displayApp: 'Display App',
  displayWeb: 'Display Web',
  webVideo: 'Web Video',
  audio: 'Audio',
  ctv: 'CTV',
};
