import React, { useEffect, useState, useRef } from 'react';
import { API } from 'aws-amplify';
import { daSearchSegmentPathChildren } from 'src/graphql/queries';
import { animated, useTransition, useSpring } from 'react-spring';
import { ChevronLeft, ChevronRight, CheckIcon, SearchIcon } from 'src/svgs';
import { DaSegmentType } from 'src/models';
import { diffDmpSegments } from 'src/helpers/diffCampaign';
import { ToggleButton } from 'src/components/shared';
import { BsQuestionCircle } from 'react-icons/bs';
import { useFeatureFlag } from 'src/helpers/featureFlags/useFeatureFlag';

const AGE_PATH_KEY = 'demographic > age';
const GENDER_PATH_KEY = 'demographic > gender';
const AUDIENCE_TARGETING_INTERCOM_LINK =
  'http://help.brandzooka.com/en/articles/8166793-enhanced-audience-targeting-under-construction';

const daSearchSegments = /* GraphQL */ `
  query SearchSegments(
    $filter: SearchableSegmentFilterInput
    $sort: [SearchableSegmentSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    daSearchSegments(filter: $filter, sort: $sort, limit: $limit, nextToken: $nextToken, from: $from) {
      items {
        id
        segmentType
        segmentGroup
        segmentPackageName
        segmentPackageId
        pathKey
        name
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;

export const AudienceTypeThirdParty = ({ campaignDraft, setCampaignDraft, appParams }) => {
  const { audienceType, isMultiDataGroups, dmpSegments } = campaignDraft || {};
  const { segmentPackages = [], campaign } = appParams || {};
  const campaignStatus = campaign.status;
  const [isDisabled, setDisabled] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const isEnhanceableAudience = hasEnhanceableSegments(dmpSegments);
  const hasEnhancedAudienceTargetingFlag = useFeatureFlag('ENHANCED_AUDIENCE', false);

  useEffect(() => {
    if (campaignDraft.status === 'completed') {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [campaignDraft.status]);

  useEffect(() => {
    if (campaignDraft.audienceType === 'thirdParty' && campaignDraft.isMultiDataGroups && !isEnhanceableAudience) {
      setCampaignDraft({ ...campaignDraft, isMultiDataGroups: false });
    }
  }, [campaignDraft, isEnhanceableAudience, setCampaignDraft]);

  const handleResetAudience = () => {
    setShowWarning(false);
    setCampaignDraft({ ...campaignDraft, audienceType: '', dmpSegments: null, isMultiDataGroups: false });
  };

  const handleNavBack = () => {
    if (diffDmpSegments(campaignDraft, campaign)) {
      setShowWarning(true);
    } else {
      setCampaignDraft({ ...campaignDraft, audienceType: '' });
    }
  };

  const handleToggle = () => {
    setCampaignDraft({ ...campaignDraft, isMultiDataGroups: !isMultiDataGroups });
  };

  return (
    <div className="h-full w-full flex flex-col">
      <div className="w-full pb-8 px-2">
        <div className="w-full flex items-center space-x-4 -mb-8 sm:-mb-6 mt-6 sm:mt-2">
          <button
            disabled={isDisabled}
            type="button"
            onClick={() => handleNavBack(true)}
            className={`flex space-x-4 ${isDisabled ? 'opacity-25 cursor-not-allowed' : ''}`}
          >
            <div className="w-6 h-6 p-1 rounded bg-primary flex justify-center items-center">
              <ChevronLeft className="stroke-current text-white h-full -ml-px" />
            </div>
            <h3 className="text-primary">Targeting section</h3>
          </button>
          <h3 className="text-primary">/</h3>
          <h3 className="text-primary">Build your own audience</h3>
        </div>
      </div>
      {hasEnhancedAudienceTargetingFlag && audienceType === 'thirdParty' && (
        <div className="w-full flex flex-row items-center justify-end">
          <span className="pr-2 text-gray-700">Enable Enhanced Targeting</span>
          <a href={AUDIENCE_TARGETING_INTERCOM_LINK} className="w-3 mr-4" target="_blank" rel="noreferrer">
            <BsQuestionCircle className="w-5 h-5 text-primary stroke-current" />
          </a>
          <ToggleButton
            disableToggle={!isEnhanceableAudience}
            onClick={() => handleToggle()}
            enabled={isMultiDataGroups}
          />
        </div>
      )}
      {showWarning && (
        <div className="w-full text-gray-600 bg-orange-100 py-1 px-3 text-center m-1">
          All unsaved audience data will be lost.
          <span className="cursor-pointer underline px-5" onClick={handleResetAudience}>
            Confirm
          </span>
          <span className="cursor-pointer underline" onClick={() => setShowWarning(false)}>
            Cancel
          </span>
        </div>
      )}
      {isMultiDataGroups && isEnhanceableAudience && (
        <div className="w-full text-gray-600 bg-orange-100 py-1 px-3 text-center m-1">
          Enabling enhanced targeting may lead to higher CPM due to increased data cost.
        </div>
      )}
      <div className="w-full sticky z-40">
        <DmpSegmentSearch
          campaignDraft={campaignDraft}
          setCampaignDraft={setCampaignDraft}
          segmentPackages={segmentPackages}
          campaignStatus={campaignStatus}
        />
      </div>
      <div className="w-full flex flex-col justify-start space-y-4 px-12">
        <DmpSegmentSelector
          campaignDraft={campaignDraft}
          setCampaignDraft={setCampaignDraft}
          segmentPackages={segmentPackages}
        />
      </div>
    </div>
  );
};

const hasEnhanceableSegments = segments => {
  const ageSegments = (segments || []).filter(s => s?.pathKey?.startsWith(AGE_PATH_KEY));
  const genderSegments = (segments || []).filter(s => s?.pathKey?.startsWith(GENDER_PATH_KEY));
  const otherSegments = (segments || []).filter(
    s => !s?.pathKey?.startsWith(AGE_PATH_KEY) && !s?.pathKey?.startsWith(GENDER_PATH_KEY)
  );
  const enhanceable =
    (ageSegments.length > 0 && genderSegments.length > 0) ||
    ((ageSegments.length > 0 || genderSegments.length > 0) && otherSegments.length > 0);

  return enhanceable;
};

const DmpSegmentSelector = ({ campaignDraft, setCampaignDraft, segmentPackages }) => {
  const [rootSegments, setRootSegments] = useState([]);

  useEffect(() => {
    let mounted = true;
    const filter = {
      and: [
        { or: segmentPackages.map(packageId => ({ segmentPackageId: { eq: packageId } })) },
        { segmentType: { eq: DaSegmentType.DMP } },
      ],
    };

    API.graphql({
      query: daSearchSegmentPathChildren,
      variables: { filter },
    })
      .then(({ data: { daSearchSegmentPathChildren: { items = [] } } = {} }) =>
        (items || [])
          .filter(({ key }) => key !== 'no match')
          .sort((a, b) => ((!a.key.endsWith(' > ') && b.key.endsWith(' > ')) || a.key < b.key ? -1 : 1))
      )
      .then(resp => mounted && setRootSegments(resp));

    return () => (mounted = false);
  }, [segmentPackages]);

  const rootSegmentItems = rootSegments
    .sort((a, b) => (a.key > b.key ? 1 : -1))
    .map(segment => (
      <DmpSegmentSelectorButton
        parentOpen
        key={segment.key}
        pathKey={segment.key}
        segmentPackages={segmentPackages}
        campaignDraft={campaignDraft}
        setCampaignDraft={setCampaignDraft}
      />
    ));

  return <div className="w-full h-80 overscroll-auto overflow-scroll pb-10">{rootSegmentItems}</div>;
};

const DmpSegmentSelectorButton = ({
  pathKey,
  campaignDraft,
  setCampaignDraft,
  segmentPackages,
  parentOpen = true,
  style = { height: 44 },
}) => {
  const [childSegments, setChildSegments] = useState(false);
  const [open, setOpen] = useState(false);
  const map = new Map();
  const campaignStatus = campaignDraft.status;
  const cleanPathKey = (pathKey || '').toLocaleLowerCase();

  const [isDisabled, setDisabled] = useState(false);

  useEffect(() => {
    if (campaignStatus === 'completed') {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [campaignStatus]);

  for (const seg of campaignDraft?.dmpSegments || []) map.set(seg.pathKey, seg);

  const isSelected = map.has(cleanPathKey);

  const getSegment = () => {
    const eq = pathKey.replace(/ > $/, '');
    const filter = {
      pathKey: { eq },
      and: [
        { or: segmentPackages.map(packageId => ({ segmentPackageId: { eq: packageId } })) },
        { segmentType: { eq: DaSegmentType.DMP } },
      ],
    };

    return API.graphql({
      query: daSearchSegments,
      variables: { filter },
    })
      .then(({ data: { daSearchSegments: { items = [] } } = {} }) => items || [])
      .then(([first]) => first)
      .catch(err => console.log('errr', err));
  };

  const updateSelected = async () => {
    if (map.has(cleanPathKey)) {
      map.delete(cleanPathKey);
    } else {
      map.set(cleanPathKey, { pathKey: cleanPathKey, name: pathKey });
      setCampaignDraft({ ...campaignDraft, dmpSegments: [...map.values()] });

      await getSegment()
        .then(segment => ({
          id: segment?.id,
          name: segment?.name,
          pathKey: segment?.pathKey,
          segmentGroup: segment?.segmentGroup,
          segmentPackageId: segment?.segmentPackageId,
          segmentType: segment?.segmentType,
        }))
        .then(seg => {
          if (seg.pathKey) map.set(pathKey, seg);
        });
    }

    let mapValues = [...map.values()];

    if (!mapValues.length) mapValues = null; // needs to be null not [] for diffDmpSegments

    setCampaignDraft({ ...campaignDraft, dmpSegments: mapValues });
  };

  useEffect(() => {
    let mounted = true;

    if (open && cleanPathKey && segmentPackages) {
      const matchPhrasePrefix = cleanPathKey.replace(/ > $/, '');
      const filter = {
        pathKey: { matchPhrasePrefix },
        and: [
          { or: segmentPackages.map(packageId => ({ segmentPackageId: { eq: packageId } })) },
          { segmentType: { eq: DaSegmentType.DMP } },
        ],
      };

      API.graphql({
        query: daSearchSegmentPathChildren,
        variables: { filter },
      })
        .then(({ data: { daSearchSegmentPathChildren: { items = [] } } = {} }) =>
          items
            .filter(({ key }) => key !== 'no match')
            .sort((a, b) => ((!a.key.endsWith(' > ') && b.key.endsWith(' > ')) || a.key < b.key ? -1 : 1))
        )
        .then(resp => mounted && setChildSegments(resp));
    } else {
      setChildSegments(false);
    }

    return () => (mounted = false);
  }, [open, cleanPathKey, segmentPackages]);

  const children = parentOpen ? childSegments : [];

  const transitions = useTransition(children || [], {
    initial: { opacity: 1, height: 44 },
    from: { opacity: 0, height: 0 },
    enter: { opacity: 1, height: 44 },
    leave: { opacity: 0, height: 0 },
  });

  const chevronOpen = open && parentOpen;

  const chevronStyle = useSpring({
    rotate: chevronOpen ? 90 : 0,
    backgroundColor: chevronOpen ? 'rgba(226, 232, 240, 1)' : 'rgba(226, 232, 240, 0)',
  });

  const rootSegmentItems = transitions((buttonStyle, item) => {
    const childPathKey = `${pathKey}${item.key}`;

    return (
      <DmpSegmentSelectorButton
        style={buttonStyle}
        key={childPathKey}
        pathKey={childPathKey}
        parentOpen={open && parentOpen}
        segmentPackages={segmentPackages}
        campaignDraft={campaignDraft}
        setCampaignDraft={setCampaignDraft}
      />
    );
  });

  const keyLength = pathKey.split(' > ').length;
  const paddingLeft = keyLength * 16;
  const [name] = pathKey.replace(/ > $/, '').split(' > ').reverse();

  const selectedCount = (campaignDraft?.dmpSegments || []).reduce((num, itm) => {
    const pathLength = (cleanPathKey || '').split(' > ').length;
    const itmLength = (itm?.pathKey || '').split(' > ').length;

    if ((itm?.pathKey || '').startsWith(cleanPathKey) && itmLength >= pathLength) return num + 1;

    return num;
  }, 0);

  const isFolder = cleanPathKey.match(/ > $/);

  const folderButton = (
    <animated.button
      style={{ ...style, paddingLeft }}
      type="button"
      onClick={() => setOpen(!open)}
      className={`w-full flex items-center space-x-3 text-left hover:bg-blueGray-50 ${
        isFolder && 'border-b border-blueGray-200'
      }`}
    >
      <animated.div style={chevronStyle} className="p-2 rounded">
        <ChevronRight className="stroke-current text-gray-600 w-4 h-4" />
      </animated.div>
      <h3 className="text-sm sm:text-lg">{name}</h3>
      {selectedCount > 0 && (
        <span className="text-white  bg-primary rounded-full w-7 h-6 text-center">{selectedCount}</span>
      )}
    </animated.button>
  );

  let fileButton = (
    <animated.button
      style={{ ...style, paddingLeft }}
      type="button"
      onClick={updateSelected}
      className={`w-full flex items-center space-x-3 text-left hover:bg-blueGray-50 ${
        isFolder && 'border-b border-blueGray-200'
      }`}
    >
      <div className="w-4" />
      <h3 className="text-lg">{name}</h3>
      {isSelected && <div className="bg-primary w-2 h-2 rounded-full" />}
      <div className="flex-1 flex justify-end pr-5">
        {isSelected && (
          <div className="w-6 h-6 rounded bg-primary flex justify-center items-center p-1">
            <CheckIcon className="stroke-current text-white w-full" />
          </div>
        )}
        {!isSelected && <div className="w-6 h-6 rounded border border-primary flex justify-center items-center p-1" />}
      </div>
    </animated.button>
  );

  if (isDisabled) {
    fileButton = (
      <animated.button
        style={{ ...style, paddingLeft }}
        type="button"
        className="w-full flex items-center space-x-3 text-left hover:bg-blueGray-50 opacity-25 cursor-not-allowed"
      >
        <div className="w-4" />
        <h3 className="text-lg opacity-25">{name}</h3>
        {isSelected && <div className="bg-primary w-2 h-2 rounded-full opacity-25" />}
        <div className="flex-1 flex justify-end pr-5">
          {isSelected && (
            <div className="w-6 h-6 rounded bg-primary flex justify-center items-center p-1 opacity-25">
              <CheckIcon className="stroke-current text-white w-full" />
            </div>
          )}
          {!isSelected && (
            <div className="w-6 h-6 rounded border border-primary flex justify-center items-center p-1 opacity-25" />
          )}
        </div>
      </animated.button>
    );
  }

  return (
    <div className="w-full">
      {isFolder ? folderButton : fileButton}
      {rootSegmentItems}
    </div>
  );
};

const DmpSegmentSearch = ({ campaignDraft, setCampaignDraft, segmentPackages, campaignStatus }) => {
  const [segments, setSegments] = useState([]);
  const [searchQuery, setSearchQuery] = useState([]);
  const [filteredList, setFilteredList] = useState();
  const [open, setOpen] = useState(false);
  const inputRef = useRef();
  const map = new Map();

  const [isDisabled, setDisabled] = useState(false);

  useEffect(() => {
    if (campaignStatus === 'completed') {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [campaignStatus]);

  useEffect(() => {
    let mounted = true;

    const getDmpSegments = async () => {
      const getPage = next => {
        const variables = {
          nextToken: next,
          limit: 100,
          filter: {
            and: [
              { or: segmentPackages.map(packageId => ({ segmentPackageId: { eq: packageId } })) },
              { segmentType: { eq: DaSegmentType.DMP } },
            ],
          },
        };

        return API.graphql({
          query: daSearchSegments,
          fetchPolicy: 'network-only',
          variables,
        }).then(({ data: { daSearchSegments: { items = [], nextToken } = {} } = {} }) => {
          const filteredItems = items.map(({ __typename, ...rest }) => rest);

          if (!nextToken) return filteredItems;

          return getPage(nextToken).then(nextItems => [...filteredItems, ...nextItems]);
        });
      };

      return getPage();
    };

    getDmpSegments().then(resp => mounted && setSegments(resp));

    return () => (mounted = false);
  }, [segmentPackages]);

  const rootSegmentItems = segments.map(segment => (
    <DmpSegmentSelectorButton
      parentOpen
      key={segment.pathKey}
      pathKey={segment.pathKey}
      segmentPackages={segmentPackages}
      campaignDraft={campaignDraft}
      setCampaignDraft={setCampaignDraft}
    />
  ));

  const getUnique = (arr, index) => {
    const unique = rootSegmentItems
      .map(e => e[index])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter(e => arr[e])
      .map(e => arr[e]);

    return unique;
  };

  const handleSearch = e => {
    const query = e.target.value;

    setSearchQuery(query);
    setOpen(true);
    const searchList = getUnique(rootSegmentItems, 'key')
      .filter(item => item.key.toLowerCase().indexOf(query.toLowerCase()) !== -1)
      .map(l => (
        <div className="w-full p-3 border-b hover:bg-blue-100" key={l.key}>
          <button className="capitalize" value={l.key} type="button" onClick={handleClick}>
            {l.key}
          </button>
        </div>
      ));

    setFilteredList(searchList);
    inputRef.current?.focus();
  };

  const searchResults = filteredList?.map(segment => segment.key);

  if (searchResults) {
    searchResults?.map(item => item.key);
  }

  const updateSelectedSearch = async pathKey => {
    const newSegment = await getSegmentSearch(pathKey).then(segment => ({
      id: segment?.id,
      name: segment?.name,
      pathKey: segment?.pathKey,
      segmentGroup: segment?.segmentGroup,
      segmentPackageId: segment?.segmentPackageId,
      segmentType: segment?.segmentType,
    }));

    const draftDmpSegments = campaignDraft?.dmpSegments || [];

    const selectedDraft = draftDmpSegments?.filter(item => item?.pathKey).map(l => l.pathKey);

    if (selectedDraft.includes(newSegment.pathKey)) {
      map.delete(newSegment);
    } else {
      const newDmpSegments = [...draftDmpSegments, newSegment];

      setCampaignDraft({ ...campaignDraft, dmpSegments: newDmpSegments });
    }
  };

  const handleClick = e => {
    updateSelectedSearch(e.target.value);
    setOpen(false);
    setSearchQuery([]);
  };

  const getSegmentSearch = async newPathKey => {
    const filter = {
      pathKey: { eq: newPathKey },
      and: [{ segmentType: { eq: DaSegmentType.DMP } }],
    };

    try {
      const { data: { daSearchSegments: { items = [] } } = {} } = await API.graphql({
        query: daSearchSegments,
        variables: { filter },
      });
      const [first] = items || [];

      return first;
    } catch (err) {
      return console.log('errr', err);
    }
  };

  return (
    <div>
      <div className="py-2 px-6">
        <SearchIcon className="h-4 stroke-current text-primary mt-3 ml-3 absolute" />
        <input
          disabled={isDisabled}
          className={`w-full h-10 border-0 bg-blueGray-150 pl-12 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primary rounded-lg ${
            isDisabled ? 'opacity-25 cursor-not-allowed' : ''
          }`}
          type="search"
          value={searchQuery}
          placeholder="Search..."
          onChange={handleSearch}
        />
      </div>
      {searchQuery?.length > 2 && open ? (
        <div className="pr-5 bg-blueGray-50 rounded-lg shadow-xl overflow-y-scroll h-auto max-h-72 left-14">
          {filteredList}
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};
