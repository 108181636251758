import { useCallback, useMemo, useState } from 'react';
import { useGAS } from 'src/GlobalAppState/context';
import { ToggleButtonV2 } from 'src/components/shared/ToggleButton';
import { createAPIMutationHook } from 'src/hooks/useAPIQuery';
import { isEqual } from 'lodash';
import { SettingsTable, SettingsTableRow } from '../../shared/SettingsTable';
import { SaveButton } from '../../shared/SaveButton';

const updateWhiteLabelConfig = /* GraphQL */ `
  mutation UpdateWhiteLabelConfig($id: ID!, $config: WhitelabelConfigInput!) {
    updateOrganization(input: { id: $id, whitelabelConfig: $config }) {
      id
    }
  }
`;

const useUpdateWhiteLabelConfig = createAPIMutationHook({
  mutation: updateWhiteLabelConfig,
});

const useWhiteLabelConfig = () => {
  const { organization } = useGAS();

  console.log(organization);

  const [localConfig, setLocalConfig] = useState(organization.whitelabelConfig ?? {});

  const updateConfig = useCallback(
    (propName, val) => {
      setLocalConfig({
        ...localConfig,
        [propName]: val,
      });
    },
    [localConfig]
  );

  const { loading, handler } = useUpdateWhiteLabelConfig({ id: organization.id, config: localConfig });

  const configChanged = useMemo(
    () => !isEqual(organization.whitelabelConfig ?? {}, localConfig),
    [localConfig, organization.whitelabelConfig]
  );

  return {
    loading,
    config: localConfig,
    configChanged,
    updateConfig,
    saveConfig: handler,
  };
};

const WhiteLabelConfigForm = () => {
  const { loading, config, updateConfig, saveConfig, configChanged } = useWhiteLabelConfig();

  return (
    <div className="flex flex-col space-y-8">
      <h2 className="font-semibold text-xl text-center">Whitelabel Options</h2>
      <SettingsTable>
        <SettingsTableRow
          descriptionHeadline="Brand User Self-Service Payment Settings"
          description="By default, brand users can buy credits via credit card or ACH in the app. Disable this setting if you want to hide their payment options and fully manage your brand customer billing."
        >
          <ToggleButtonV2
            value={!config.disableBrandCreditSelfService}
            onChange={() => updateConfig('disableBrandCreditSelfService', !config.disableBrandCreditSelfService)}
            label="Brand User Self-Service Payment"
          />
        </SettingsTableRow>
      </SettingsTable>
      <div className="flex flex-row justify-center">
        <SaveButton loading={loading} onSave={saveConfig} disabled={!configChanged} />
      </div>
    </div>
  );
};

const showConfigForm = organization => Boolean(organization.accountDomain && organization.accountSubdomain);

export const WhiteLabelConfig = () => {
  const { organization } = useGAS();

  if (!organization || !showConfigForm(organization)) return null;

  return <WhiteLabelConfigForm />;
};
