import { useCallback } from 'react';
import { CheckBox } from './CheckBox';

export const SelectAllButton = ({
  setSelectAll,
  selectAll,
  campaignDraft,
  setCampaignDraft,
  appParams,
  inventoryCampaignKey,
  allInventory,
}) => {
  const { campaign } = appParams;
  const isDisabled = campaign.status === 'completed';

  const onSelect = useCallback(
    isSelectAll => {
      setCampaignDraft({ ...campaignDraft, [inventoryCampaignKey]: isSelectAll ? [] : allInventory.slice() });
      setSelectAll(!selectAll);
    },
    [setCampaignDraft, campaignDraft, inventoryCampaignKey, allInventory, setSelectAll, selectAll]
  );

  return (
    <button
      type="button"
      disabled={isDisabled}
      onClick={() => onSelect(selectAll)}
      className={`w-full flex items-center space-x-3 text-left text-primary pl-12 pb-12 sm:pb-4 h-12 ${
        isDisabled ? 'opacity-25 cursor-not-allowed' : ''
      }`}
    >
      <CheckBox isSelected={selectAll} />
      <h3 className="sm:text-lg">Select All (Recommended)</h3>
    </button>
  );
};
