export const Table = ({ children, className = '' }) => (
  <table className={`border-2 border-gray-100 ${className}`}>{children}</table>
);

export const TableHead = ({ children }) => <thead className="bg-gray-100">{children}</thead>;

export const TableBody = ({ children }) => <tbody>{children}</tbody>;

export const TableRow = ({ children }) => <tr className="border-b-2 border-gray-100">{children}</tr>;

export const TableHeadCell = ({ children }) => <th className="p-4 font-semibold whitespace-nowrap">{children}</th>;

export const TableCell = ({ children }) => <td className="p-4">{children}</td>;
