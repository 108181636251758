import { useEffect, useState, useRef, useCallback } from 'react';
import { EditIcon } from 'src/svgs';
import { useKey, useClickAway } from 'react-use';

export const CampaignNameField = ({ appParams, campaignDraft, setCampaignDraft, save, isNew, className = '' }) => {
  const [showEdit, setShowEdit] = useState(false);
  const fieldRef = useRef();
  const disabled = !campaignDraft?.name || campaignDraft?.name?.length < 2;
  const campaignStatus = appParams?.campaign?.status;

  useEffect(() => {
    setShowEdit(isNew);
  }, [isNew]);

  useEffect(() => {
    const { current = {} } = fieldRef;

    if (showEdit && current) current.focus();
  }, [showEdit]);

  const onSave = useCallback(() => {
    if (disabled) return;

    save();
    setShowEdit(false);
  }, [disabled, save]);

  const onEnter = useCallback(
    kEvent => {
      if (kEvent?.code !== 'Enter') {
        return;
      }
      onSave();
    },
    [onSave]
  );

  const onCancel = e => {
    if (showEdit) {
      if (isNew) {
        e.preventDefault();
        if (fieldRef.current) fieldRef.current.focus();
      } else {
        if (appParams?.campaign?.name) {
          setCampaignDraft({ ...campaignDraft, name: appParams.campaign.name });
        }
        setShowEdit(false);
      }
    }
  };

  const formRef = useRef();

  useKey('Escape', onCancel);
  useClickAway(formRef, onCancel);

  return (
    <div ref={formRef} className={`w-full flex px-4 ${className}`}>
      {!showEdit && (
        <div className="flex w-full max-w-full items-center py-2 space-x-2 justify-items-start justify-start">
          <p
            className="text-3xl text-primary capitalize truncate flex-shrink min-w-0"
            title={campaignDraft.name}
            data-testid="campaignName"
          >
            {campaignDraft.name}
          </p>
          <button
            type="button"
            className="rounded focus:outline-none focus:ring-2 ring-primary flex-shrink-0"
            onClick={() => setShowEdit(true)}
            disabled={campaignStatus === 'completed'}
            data-testid="campaignNameEdit"
          >
            <EditIcon className="h-7 w-8 mt-1 stroke-current text-primary" />
          </button>
        </div>
      )}
      {showEdit && (
        <div className="flex items-center py-2 space-x-2 flex-grow">
          <input
            ref={fieldRef}
            className="bz-input"
            value={campaignDraft.name || ''}
            placeholder="Campaign name"
            onChange={({ target: { value: v } = {} }) => setCampaignDraft({ ...campaignDraft, name: v.slice(0, 60) })}
            data-testid="campaignNameInput"
            onKeyDown={onEnter}
          />
          <button
            disabled={disabled}
            type="button"
            className={`whitespace-nowrap h-10 px-3 rounded bg-primary text-white border-gray-300 ${
              disabled ? 'opacity-40 cursor-not-allowed' : ''
            } `}
            onClick={onSave}
            data-testid="campaignNameSave"
          >
            Save Changes
          </button>
        </div>
      )}
    </div>
  );
};
