import { useMemo } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import RootLayout from './layout';
import RootPage from './page';
import SignOutPage from './sign-out/page';
import Auth, { authPaths } from './auth/component';
import WithAuth, { withAuthPaths } from './(withauth)/component';

const toModelPath = props => `/${props.match.params.model.replace(/s$/, '')}/${props.match.params.id}`;

const settingsRedirects = {
  whiteLabel: 'white-label',
  margin: 'earnings',
  pixel: 'tracking',
  firstParty: 'first-party',
  settings: 'edit',
};

const redirects = {
  '/verify-email': props => `/auth/verify-email${props.location.search}`,
  '/accept-invite/:inviteCode': props => `/auth/invite/${props.match.params.inviteCode}`,
  '/forgot-password': props => `/auth/reset-password${props.location.search}`,
  '/:appState(build|analyze|report)/:model(teams|campaigns|brands)/:id': toModelPath,
  '/:appState(build|analyze|report)/:model(teams|campaigns|brands)/:id/:page': props => {
    const modelPath = toModelPath(props);
    const pageParam = props.match.params.page;
    const page = settingsRedirects[pageParam] ?? pageParam;

    return `${modelPath}/${page}`;
  },
};

export default function Root() {
  const redirectPaths = useMemo(
    () =>
      Object.entries(redirects).map(([from, toFunc]) => (
        <Route key={from} exact path={from} render={props => <Redirect to={toFunc(props)} />} />
      )),
    []
  );

  return (
    <RootLayout>
      <Switch>
        {redirectPaths}
        <Route path={authPaths}>
          <Auth />
        </Route>
        <Route path={withAuthPaths}>
          <WithAuth />
        </Route>
        <Route exact path="/sign-out">
          <SignOutPage />
        </Route>
        <Route exact path="/">
          <RootPage />
        </Route>
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
    </RootLayout>
  );
}
