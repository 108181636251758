import { useState, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { useGAS } from 'src/GlobalAppState/context';
import { createAPIMutationHook, createAPIQueryHook } from 'src/hooks/useAPIQuery';
import { Spinner } from 'src/components/shared/Spinner';
import { groupBy, mapValues, orderBy, sumBy } from 'lodash';
import { Header, HeaderButton, HeaderLink } from 'src/components/MainSettings/shared/Headers';

const setTeamDefaultMargin = /* GraphQL */ `
  mutation UpdateBrandProtected($id: ID!, $defaultBrandMarkup: Float) {
    updateTeam(input: { id: $id, defaultBrandMarkup: $defaultBrandMarkup }) {
      id
      defaultBrandMarkup
    }
  }
`;

const setBrandMargin = /* GraphQL */ `
  mutation UpdateBrandProtected($id: ID!, $bMar: Float) {
    updateBrandProtected(input: { id: $id, bMar: $bMar }) {
      id
      bMar
    }
  }
`;

const getTeamMarginsQuery = /* GraphQL */ `
  query GetTeamMargins($id: ID!) {
    margins: getMargins(input: { id: $id, recordType: team }) {
      id
      defaultValue
      recordType
      records {
        id
        recordType
        value
      }
    }
  }
`;

const useTeamDefaultMarginHandler = createAPIMutationHook({
  mutation: setTeamDefaultMargin,
});

const useBrandMarginHandler = createAPIMutationHook({
  mutation: setBrandMargin,
});

const useMarginQuery = createAPIQueryHook({
  query: getTeamMarginsQuery,
  resultPath: 'margins',
  paged: false,
});

const useMarginData = team => {
  const { data: marginData, loading, reload } = useMarginQuery({ id: team?.id }, { disable: !team?.id });

  const byBrand = useMemo(
    () =>
      (marginData?.records ?? []).reduce((acc, brandRecord) => {
        acc[brandRecord.id] = brandRecord.value;

        return acc;
      }, {}),
    [marginData]
  );

  return { byBrand, loading, marginData, reload };
};

const SaveButton = ({ loading, onSave, disabled, primaryAction }) => (
  <HeaderButton onClick={onSave} primaryAction={primaryAction} disabled={disabled}>
    <span>Save</span>
    <div className="pointer-events-none absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      {loading && <Spinner variant="inline" />}
    </div>
  </HeaderButton>
);

const MarkupEditor = ({ value, label, loading, onSave, primaryAction, marginData }) => {
  const [margin, setMargin] = useState(value);
  const disabled = margin === value;

  const placeHolderValue = marginData?.defaultValue ?? 0;

  return (
    <div className="flex flex-row space-x-2 items-center">
      {label && <span>{label}</span>}
      <NumberFormat
        className={`border rounded px-3 py-2 w-24 ${Number.isFinite(margin) ? 'border-gray-500' : 'border-gray-200'} hover:border-gray-600 focus:border-blue-400 placeholder:text-gray-400`}
        suffix="%"
        value={Number.isFinite(margin) ? margin * 100 : margin}
        displayType="input"
        placeholder={`${placeHolderValue * 100}%`}
        decimalScale={0}
        allowLeadingZeros={false}
        onValueChange={({ floatValue: v }) => {
          setMargin(v / 100);
        }}
        isAllowed={({ floatValue }) => floatValue === undefined || (floatValue >= 0 && floatValue <= 50)}
      />
      <SaveButton loading={loading} disabled={disabled} onSave={() => onSave(margin)} primaryAction={primaryAction} />
    </div>
  );
};

const TeamSettingsMarkupComponent = ({ team, marginData, reload }) => {
  const { handler: marginHandler, loading } = useTeamDefaultMarginHandler({
    id: team.id,
  });

  const onSave = useCallback(
    margin => {
      marginHandler({
        defaultBrandMarkup: Number(margin) / 100,
      }).then(() => {
        reload();
      });
    },
    [marginHandler, reload]
  );

  return (
    <MarkupEditor
      value={marginData?.defaultValue}
      loading={loading}
      onSave={onSave}
      label="Agency default margin:"
      primaryAction
    />
  );
};

const BrandRowMarkupComponent = ({ record, reload, marginData }) => {
  const { handler: marginHandler, loading } = useBrandMarginHandler({
    id: record.id,
  });

  const onSave = useCallback(
    margin => {
      marginHandler({
        bMar: margin ?? null,
      }).then(() => {
        reload();
      });
    },
    [marginHandler, reload]
  );

  return <MarkupEditor value={record.margin} loading={loading} onSave={onSave} marginData={marginData} />;
};

const useRows = (brands, teamPayoutRecords, byBrandMarginData) =>
  useMemo(() => {
    const payoutsByBrandId = groupBy(teamPayoutRecords, record => {
      try {
        const metadata = JSON.parse(record.metadata);

        return metadata.brandId;
      } catch (e) {
        return '';
      }
    });

    const sumsByBrandId = mapValues(payoutsByBrandId, payouts => sumBy(payouts, 'amount'));

    const rows = brands.map(brand => ({
      id: brand.id,
      name: brand.name,
      earnings: sumsByBrandId[brand.id] ?? 0,
      margin: byBrandMarginData[brand.id],
    }));

    return orderBy(rows, ['earnings'], ['desc']);
  }, [brands, byBrandMarginData, teamPayoutRecords]);

const BrandPayoutRow = ({ record, index, reload, marginData }) => (
  <tr data-mf-replace="" className={`w-full h-20 ${index % 2 ? 'bg-blueGray-100' : 'bg-white'}`}>
    <td className="pl-12 pr-12 text-sm text-right">
      <NumberFormat
        className="w-20"
        value={record.earnings}
        displayType="text"
        prefix="$"
        decimalScale={2}
        fixedDecimalScale
        thousandSeparator
      />
    </td>
    <td className="pr-12">
      <Link to={`/brand/${record.id}`} className="text-gray-600 underline hover:text-gray-800">
        {record.name}
      </Link>
    </td>
    <td className="pr-12">
      <BrandRowMarkupComponent record={record} reload={reload} marginData={marginData} />
    </td>
  </tr>
);

const TeamMarginSettingsBody = ({ byBrand, reload, marginData }) => {
  const {
    teamBrands,
    credits: { teamPayoutRecords },
  } = useGAS();
  const payouts = useRows(teamBrands, teamPayoutRecords, byBrand);

  return (
    <table className="w-full border rounded" data-mf-replace="">
      <thead>
        <tr className="h-20 border-b text-lg font-medium top-0 bg-white">
          <td className="pl-12 w-64">Lifetime Earnings</td>
          <td className="pr-12">Brand</td>
          <td className="pr-12 w-48">Effective Margin</td>
        </tr>
      </thead>
      <tbody data-mf-replace="" className="border-b">
        {payouts.map((record, index) => (
          <BrandPayoutRow record={record} key={record.id} index={index} reload={reload} marginData={marginData} />
        ))}
      </tbody>
    </table>
  );
};

const TeamEarningsEmptyState = () => {
  const { team } = useGAS();

  return (
    <div className="border rounded flex flex-col space-y-8 items-center justify-center flex-1 shadow">
      <p className="max-w-lg text-center">
        You can configure your default margin above, or create a brand to get started.
      </p>
      <div>
        <HeaderLink href={`/team/${team.id}/new-brand`} primaryAction>
          Create a Brand
        </HeaderLink>
      </div>
    </div>
  );
};

const LoadingWrapper = ({ loading, children }) => {
  if (loading) {
    return <Spinner />;
  }

  return children;
};

export default function TeamMarginSettingsPage() {
  const { authLevel, team, teamBrands, teamBrandsLoading } = useGAS();
  const { loading, marginData, reload, byBrand } = useMarginData(team);

  const authorized = ['team', 'organization', 'static'].includes(authLevel);

  if (!authorized) return null;

  return (
    <div className="flex flex-1 flex-col space-y-8">
      <Header title="Margin Settings">
        {!loading && <TeamSettingsMarkupComponent team={team} marginData={marginData} reload={reload} />}
      </Header>
      <LoadingWrapper loading={loading || teamBrandsLoading}>
        {teamBrands.length ? (
          <TeamMarginSettingsBody byBrand={byBrand} reload={reload} marginData={marginData} />
        ) : (
          <TeamEarningsEmptyState />
        )}
      </LoadingWrapper>
    </div>
  );
}
