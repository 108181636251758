import { Switch, Route, Redirect } from 'react-router-dom';
import AuthLayout from './layout';
import AuthTemplate from './template';
import SignInPage from './sign-in/page';
import SignUpPage from './sign-up/page';
import ForgotPasswordPage from './forgot-password/page';
import ResetPasswordPage from './reset-password/page';
import ForgotEmailPage from './forgot-email/page';
import ConfirmSignUpPage from './confirm-signup/page';
import VerifyEmailPage from './verify-email/page';
import AcceptInvitePage from './invite/[inviteCode]/page';

const signInPath = '/auth/sign-in';
const signUpPath = '/auth/sign-up';
const forgotPasswordPath = '/auth/forgot-password';
const resetPasswordPath = '/auth/reset-password';
const forgotEmailPath = '/auth/forgot-email';
const confirmSignupPath = '/auth/confirm-signup';
const verifyEmailPath = '/auth/verify-email';
const inviteCodePath = '/auth/invite/:inviteCode';

export const authPaths = [
  signInPath,
  signUpPath,
  forgotPasswordPath,
  resetPasswordPath,
  forgotEmailPath,
  confirmSignupPath,
  verifyEmailPath,
  inviteCodePath,
];

export default function Auth() {
  return (
    <AuthLayout>
      <AuthTemplate>
        <Switch>
          <Route exact path={signInPath}>
            <SignInPage />
          </Route>
          <Route exact path={signUpPath}>
            <SignUpPage />
          </Route>
          <Route exact path={forgotPasswordPath}>
            <ForgotPasswordPage />
          </Route>
          <Route exact path={resetPasswordPath}>
            <ResetPasswordPage />
          </Route>
          <Route exact path={forgotEmailPath}>
            <ForgotEmailPage />
          </Route>
          <Route exact path={confirmSignupPath}>
            <ConfirmSignUpPage />
          </Route>
          <Route exact path={verifyEmailPath}>
            <VerifyEmailPage />
          </Route>
          <Route exact path={inviteCodePath}>
            <AcceptInvitePage />
          </Route>
          <Route>
            <Redirect to={signInPath} />
          </Route>
        </Switch>
      </AuthTemplate>
    </AuthLayout>
  );
}
