export const SettingsTableRow = ({ descriptionHeadline, description, children, className = '' }) => (
  <tr className={`flex flex-row w-full ${className}`}>
    <td className="p-4 flex flex-col items-center justify-center w-1/2 flex-1">{children}</td>
    <td className="p-4 w-1/2 flex-1">
      <h3 className="text-lg font-semibold mb-4">{descriptionHeadline}</h3>
      <p className="max-w-screen-sm block">{description}</p>
    </td>
  </tr>
);

export const SettingsTable = ({ children }) => (
  <table className="table-fixed w-full flex flex-col">
    <thead className="sr-only flex flex-col">
      <tr>
        <th className="w-1/2">Setting</th>
        <th className="w-1/2">Description</th>
      </tr>
    </thead>
    <tbody className="flex flex-col items-stretch justify-items-stretch space-y-12">{children}</tbody>
  </table>
);
