import { useEffect, useRef } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import Leaflet from 'leaflet';
import { DaSegmentType } from 'src/models';
import { PiMapPinLight } from 'react-icons/pi';
import { CloseIcon } from 'src/svgs';
import { SegmentSearch } from './SegmentSearch';
import { BuilderPanel } from '../BuilderPanel';

const dedup = arr => Object.values(arr.reduce((obj, itm) => ({ ...obj, [itm.id]: itm }), {}));

const getCountries = (segments, options) => {
  const { leafletFeatures, leafletShapes, primary } = options;
  const segKeyMap = new Map();

  for (const seg of segments) {
    const [key] = seg.pathKey.split(' > ');

    if (key !== 'united states') {
      const group = seg.segmentGroup || 'Region';
      const value = segKeyMap.get(key) || {};

      value[group] = (value[group] || 0) + 1;
      segKeyMap.set(key, value);
    }
  }
  const segKeys = Array.from(segKeyMap.keys());
  const segKeyFilters = segKeys.filter(val => !!val).map(eq => ({ pathKey: { eq } }));

  const filter = {
    and: [
      {
        or: [{ segmentPackageId: { eq: 'default' } }, { segmentPackageId: { eq: 'political' } }],
      },
      { segmentType: { eq: 'GEO' } },
      { or: segKeyFilters },
    ],
  };

  const sort = [{ direction: 'asc', field: 'pathKey' }];
  const query = graphqlOperation(
    `query daSearchSegments(
        $filter: SearchableSegmentFilterInput
        $sort: [SearchableSegmentSortInput]
        $limit: Int
      ) {
        daSearchSegments(filter: $filter, sort: $sort, limit: $limit) {
          items {
            id
            name
            pathKey
            segmentType
            segmentGroup
            geoJson {
              type
              coordinates
            }
          }
        }
      }`,
    {
      filter,
      sort,
      limit: 10,
    }
  );

  const onEachFeature = (feature, lyr) => {
    const popup = `<div>${feature?.properties?.text || 'contains selected regions'}</div>`;

    lyr.bindPopup(popup, { closeButton: false });
    lyr.on('mouseover', evt => lyr.openPopup(evt.latlng));
    lyr.on('mousemove', evt => lyr.openPopup(evt.latlng));
    lyr.on('mouseout', () => lyr.closePopup());
  };
  const plural = k => {
    const map = {
      City: 'Cities',
      Region: 'Regions',
      State: 'States',
      Zip: 'Zips',
    };

    return map[k] || 'Regions';
  };

  return API.graphql(query)
    .then(({ data: { daSearchSegments: { items } } = {} }) => items)
    .then(segs => {
      for (const seg of segs) {
        if (seg?.geoJson) {
          const style = {
            color: primary,
            fillColor: '#333333',
            opacity: 0.5,
            fillOpacity: 0.1,
          };

          if (!leafletShapes.current[seg.pathKey]) {
            const summary = Object.entries(segKeyMap.get(seg.pathKey) || {}).reduce((str, [k, v]) => {
              const cleanKey = v > 1 ? plural(k) : k;
              const resp = str ? `${str}, ` : '';

              return `${resp} ${v} ${cleanKey}`.replace(/,\s([^,]+)$/, ' & $1');
            }, '');
            const text = `Selected ${summary} in ${seg.name}`.replace(/ +/g, ' ');

            seg.geoJson.properties = { name: seg.name, text };
            leafletShapes.current[seg.pathKey] = Leaflet.geoJSON(seg?.geoJson, { style, onEachFeature });
            leafletShapes.current[seg.pathKey].addTo(leafletFeatures.current);
          }
        }
      }
    });
};

export const GeoTargeting = ({ appParams, campaignDraft, setCampaignDraft }) => {
  const leafletMap = useRef();
  const leafletFeatures = useRef();
  const leafletShapes = useRef({});
  const geoRef = useRef();
  const geoSegments = campaignDraft?.geoSegments || [];
  const { campaign } = appParams;
  const campaignStatus = campaign.status;

  const isDisabled = campaignStatus === 'completed';

  useEffect(() => {
    if (!leafletMap.current) {
      leafletMap.current = Leaflet.map('map', {
        center: [11.0981, 18.6012],
        zoomSnap: 0.1,
        zoom: 1,
        zoomControl: false,
        scrollWheelZoom: false,
        minZoom: 2,
        maxZoom: 13,
        maxBoundsViscosity: 0.0,
        worldCopyJump: true,
      });
      Leaflet.control.zoom({ position: 'topright' }).addTo(leafletMap.current);
      Leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org">OpenStreetMap</a> contributors',
      }).addTo(leafletMap.current);
      leafletFeatures.current = Leaflet.featureGroup();
      leafletFeatures.current.addTo(leafletMap.current);
    }
  }, []);

  const primary = appParams?.theme?.primary || '#CCCCCC';

  useEffect(() => {
    if (campaignDraft?.geoSegments) {
      const existing = Object.keys(leafletShapes.current);
      const segKeys = campaignDraft?.geoSegments.map(seg => seg.pathKey);
      const removed = existing.filter(k => !segKeys.includes(k));
      const added = segKeys.filter(k => !existing.includes(k));

      for (const ex of removed) {
        const layer = leafletShapes.current[ex];

        if (layer) leafletFeatures.current.removeLayer(layer);
        delete leafletShapes.current[ex];
      }

      const segKeyFilters = added.filter(val => !!val).map(eq => ({ pathKey: { eq } }));

      const filter = {
        and: [
          {
            or: [{ segmentPackageId: { eq: 'default' } }, { segmentPackageId: { eq: 'political' } }],
          },
          { segmentType: { eq: 'GEO' } },
          { or: segKeyFilters },
        ],
      };

      const sort = [{ direction: 'asc', field: 'pathKey' }];
      const query = graphqlOperation(
        `query daSearchSegments(
            $filter: SearchableSegmentFilterInput
            $sort: [SearchableSegmentSortInput]
            $limit: Int
          ) {
            daSearchSegments(filter: $filter, sort: $sort, limit: $limit) {
              items {
                id
                name
                pathKey
                segmentType
                segmentGroup
                geoJson {
                  type
                  coordinates
                }
              }
            }
          }`,
        {
          filter,
          sort,
          limit: 50,
        }
      );

      const notFound = [];

      if (campaignDraft?.geoSegments.length) {
        API.graphql(query)
          .then(({ data: { daSearchSegments: { items } } = {} }) => items)
          .then(segs => {
            for (const seg of segs) {
              if (seg?.geoJson) {
                const style = {
                  color: primary,
                  opacity: 0.5,
                };

                if (!leafletShapes.current[seg.pathKey]) {
                  leafletShapes.current[seg.pathKey] = Leaflet.geoJSON(seg?.geoJson, { style });
                  leafletShapes.current[seg.pathKey].addTo(leafletFeatures.current);
                }
              } else {
                notFound.push(seg);
              }
            }
          })
          .then(() => {
            if (notFound.length) {
              return getCountries(notFound, { leafletFeatures, leafletShapes, leafletMap, primary });
            }
          })
          .then(() => {
            const hasLayers = !!Object.keys(leafletFeatures.current?._layers || {}).length;

            if (hasLayers) {
              leafletMap.current.fitBounds(leafletFeatures.current.getBounds(), {
                padding: [10, 20],
              });
            }
          });
      }
    }
  }, [campaignDraft?.geoSegments, primary]);

  const selectSegment = segment => {
    const newDraft = dedup([...geoSegments, segment]);

    setCampaignDraft({ ...campaignDraft, geoSegments: newDraft });
  };

  const removeSegment = segment => {
    const newDraft = geoSegments.filter(({ id }) => id !== segment.id);

    setCampaignDraft({ ...campaignDraft, geoSegments: newDraft });
  };

  const campaignGeos = geoSegments.map(segment => {
    const shortName = (segment?.name || '').replace('United States >', 'USA');

    return (
      <div key={segment.id} className="pb-2 pr-2">
        <div className="w-auto flex space-x-2 justify-between items-center rounded-r-full rounded-l-full h-10 px-4 bg-white border-2 border-primary">
          <h1 className="text-sm sm:text-base text-gray-700 font-medium">{shortName}</h1>
          <button type="button" onClick={() => removeSegment(segment)} disabled={isDisabled} className="text-primary">
            <CloseIcon
              className={`w-4 h-4 mt-px stroke-current text-primary${
                isDisabled ? 'opacity-25 cursor-not-allowed' : ''
              }`}
            />
          </button>
        </div>
      </div>
    );
  });

  return (
    <BuilderPanel title="Geography" icon={PiMapPinLight}>
      <div className="md:w-192 lg:w-full">
        <SegmentSearch
          appParams={appParams}
          selectSegment={selectSegment}
          segmentType={DaSegmentType.GEO}
          campaignDraft={campaignDraft}
          setCampaignDraft={setCampaignDraft}
        />
        {campaignGeos.length ? (
          <>
            <h2 className="px-8 text-primary py-4 font-medium">Selected Geos</h2>

            <div
              ref={geoRef}
              className="mx-8 flex flex-wrap p-4 h-auto overflow-y-scroll max-h-36 force-scrollbar shadow"
              id="campaignGeos"
            >
              {campaignGeos}
            </div>
          </>
        ) : null}
      </div>
      <div className="flex-1 flex z-0 sm:px-8 py-4">
        <div id="map" className="w-full h-96 z-0" />
      </div>
    </BuilderPanel>
  );
};
