import { Switch, Route } from 'react-router-dom';
import TeamCreditsLayout from './layout';
import TeamCreditsPage from './credits/page';
import TeamMarginPage from './earnings/page';
import TeamPaymentMethodsPage from './payment-methods/page';
import TeamMarginSettingsPage from './margin/page';

const teamCreditsPath = '/team/:id/credits';
const teamEarningsPath = '/team/:id/earnings';
const teamPaymentMethodsPath = '/team/:id/payment-methods';
const teamMarginPath = '/team/:id/margin';

export const teamCreditsPaths = [teamCreditsPath, teamEarningsPath, teamPaymentMethodsPath, teamMarginPath];

export default function TeamCredits() {
  return (
    <TeamCreditsLayout>
      <Switch>
        <Route exact path={teamCreditsPath}>
          <TeamCreditsPage />
        </Route>
        <Route exact path={teamEarningsPath}>
          <TeamMarginPage />
        </Route>
        <Route exact path={teamPaymentMethodsPath}>
          <TeamPaymentMethodsPage />
        </Route>
        <Route exact path={teamMarginPath}>
          <TeamMarginSettingsPage />
        </Route>
      </Switch>
    </TeamCreditsLayout>
  );
}
