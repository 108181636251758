import { selectAudienceValidForPublish, selectAudienceValidForSave } from './audienceValidationSelectors';
import { getCampaignDraftSchemaErrors } from './budgetValidationSelectors';
import { selectDatesValidForPublish, selectDatesValidForSave } from './dateValidationSelectors';
import { selectInventoryValidForPublish, selectInventoryValidForSave } from './inventoryValidationSelectors';
import { selectPixelValidForPublish, selectPixelValidForSave } from './pixelValidationSelectors';
import { selectStatusValidForPublish } from './statusValidationSelectors';

/**
 * Type passed as brandParams throughout this file. Used to add supplemental info to validation
 * @typedef {{
 *   brandTrackingTags: Array<object>
 * }} BrandParams
 */

const createSimpleValidator =
  (...checks) =>
  (campaignOrDraft, brandParams) =>
    checks.every(check => check(campaignOrDraft, brandParams));

const passesSimpleSaveChecks = createSimpleValidator(
  selectAudienceValidForSave,
  selectPixelValidForSave,
  selectInventoryValidForSave,
  selectDatesValidForSave
);

const passesSimplePublishChecks = createSimpleValidator(
  selectAudienceValidForPublish,
  selectPixelValidForPublish,
  selectInventoryValidForPublish,
  selectDatesValidForPublish
);

export const selectCampaignDraftValidForSave = passesSimpleSaveChecks;

export const selectCampaignValidForPublish = (campaignDraft, campaign, brandParams) => {
  if (!passesSimplePublishChecks(campaign, brandParams)) {
    return false;
  }

  const budgetErrors = getCampaignDraftSchemaErrors(campaignDraft, campaign, brandParams?.orgMinimum);

  if (budgetErrors) {
    return false;
  }

  return true;
};

export const selectCampaignDraftValidForPublish = (campaignDraft, campaign, brandParams) => {
  if (!passesSimplePublishChecks(campaignDraft, brandParams)) {
    return false;
  }

  const statusValidForPublish = selectStatusValidForPublish(campaign);

  if (!statusValidForPublish) {
    return false;
  }

  const budgetErrors = getCampaignDraftSchemaErrors(campaignDraft, campaign, brandParams?.orgMinimum);

  if (budgetErrors) {
    return false;
  }

  return true;
};
