import { API } from 'aws-amplify';

const getTrackingTagsByTeamIdQuery = /* GraphQL */ `
  query GetTrackingTagsByTeamId($teamId: ID!, $nextToken: String) {
    listTrackingTags(filter: { teamId: { eq: $teamId } }, nextToken: $nextToken) {
      items {
        id
        dspIds
        name
      }
      nextToken
    }
  }
`;

export const getTrackingTagsByTeamId = ({ teamId }) => {
  if (!teamId) return Promise.resolve([]);

  const getPage = next =>
    API.graphql({
      query: getTrackingTagsByTeamIdQuery,
      variables: { teamId, nextToken: next },
    }).then(
      ({
        data: {
          listTrackingTags: { items, nextToken },
        },
      }) => {
        if (nextToken) return getPage(nextToken).then(resp => [...items, ...resp]);

        return items;
      }
    );

  return getPage();
};
