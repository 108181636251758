import React, { useRef } from 'react';
import { FiClipboard } from 'react-icons/fi';
import { useCopyToClipboard } from 'react-use';
import { animated, useSpring, useSpringRef } from 'react-spring';

export const TrackingCodeBlock = ({ code }) => {
  const [_copyState, copyToClipboard] = useCopyToClipboard();
  const rotate = useRef(0);
  const formattedCode = (code || '').replace(/\s+/g, ' ').trim() || '\n\n';
  const springRef = useSpringRef();
  const style = useSpring({ rotate: rotate.current, ref: springRef });

  const copyCode = () => {
    rotate.current = rotate.current > 0 ? 0 : 360;
    springRef.start({ rotate: rotate.current });

    copyToClipboard(code);
  };

  return (
    <div className="relative flex-1 rounded bg-secondary py-3 px-4">
      <pre className="text-white truncate text-ellipsis pr-8">{formattedCode}</pre>
      <button
        type="button"
        onClick={copyCode}
        className="rounded w-8 h-8 flex justify-center items-center bg-primary absolute top-2 right-2"
      >
        <animated.div style={style}>
          <FiClipboard className="w-5 h-5 stroke-current text-white" />
        </animated.div>
      </button>
    </div>
  );
};
