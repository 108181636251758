import React from 'react';
import { createSelector } from 'reselect';
import { inventorySegments as inventorySegmentTypes } from 'src/constants';
import { ctvSegments } from 'src/constants/ctvSegments';
import { SummaryItem } from './SummaryItem';
import { SummaryHeader } from './SummaryHeader';

const createCreativeTypeSelector = creativeType =>
  createSelector(
    campaign => campaign.creatives,
    creatives => (creatives?.items || []).filter(c => c.creativeType === creativeType && !c.archived)
  );

const selectDisplayCreatives = createCreativeTypeSelector('display');
const selectVideoCreatives = createCreativeTypeSelector('video');
const selectAudioCreatives = createCreativeTypeSelector('audio');

const selectCTVCreatives = createSelector(selectVideoCreatives, creatives => creatives.filter(c => c.ctv));
const selectWebVideoCreatives = createSelector(selectVideoCreatives, creatives => creatives.filter(c => c.web));

const WebSummary = ({ campaignDraft }) => {
  const displayCreatives = selectDisplayCreatives(campaignDraft);
  const webVideoCreatives = selectWebVideoCreatives(campaignDraft);

  if (!displayCreatives.length && !webVideoCreatives.length) {
    return null;
  }

  const segs = campaignDraft.inventorySegments || [];
  const allInventory = Object.entries(inventorySegmentTypes).map(([k, v]) => ({ id: v.id, name: k }));
  const isSelectAll = segs.length === allInventory.length || (segs.length && segs[0].id === 'All');
  const targetingStr = isSelectAll ? `Everything` : `${segs.length} Categories`;

  return <SummaryItem title="Web Targeting">{targetingStr}</SummaryItem>;
};

const CTVSummary = ({ campaignDraft }) => {
  const ctvCreatives = selectCTVCreatives(campaignDraft);

  if (!ctvCreatives.length) {
    return null;
  }

  const allCtvInventory = Object.entries(ctvSegments).map(([k, v]) => ({ id: v.id, name: k }));
  const ctvSelectedAll = campaignDraft.ctvSegments?.length === allCtvInventory.length;
  const ctvSegs = campaignDraft.ctvSegments || [];
  const ctvTargetingStr = ctvSelectedAll ? `Everything` : `${ctvSegs.length} Categories`;

  return <SummaryItem title="CTV targeting">{ctvTargetingStr}</SummaryItem>;
};

const AudioSummary = ({ campaignDraft }) => {
  const audioCreatives = selectAudioCreatives(campaignDraft);

  if (!audioCreatives.length) {
    return null;
  }

  return <SummaryItem title="Audio targeting">Everything</SummaryItem>;
};

export const InventorySummary = ({ campaignDraft }) => {
  const segs = campaignDraft.inventorySegments || [];
  const creativesCount = campaignDraft?.creatives?.items?.length ?? 0;

  if (!creativesCount) {
    return null;
  }

  return (
    <>
      <SummaryHeader panelKey="Categories">Categories</SummaryHeader>
      {segs.length > 0 ? (
        <div className="ml-4">
          <WebSummary campaignDraft={campaignDraft} />
          <CTVSummary campaignDraft={campaignDraft} />
          <AudioSummary campaignDraft={campaignDraft} />
        </div>
      ) : (
        <div className="flex ml-4 space-x-2 text-xs bg-orange-100 p-1 w-1/3">
          <span className="mx-auto text-gray-500">Required</span>
        </div>
      )}
    </>
  );
};
