import { format } from 'date-fns';
import React, { useState } from 'react';
import { DateButton } from './DateButton';

export const StartEndDateSelector = ({
  dates,
  onDateSelect,
  type = 'campaign-builder',
  recurringEnabled,
  disabled = false,
  campaignDraft,
  status,
  forceEdit,
  'data-testid': testid,
  relative = false,
}) => {
  const fmtStart = format(dates.startDate, 'MM/dd/yyyy') || '';
  const fmtEnd = format(dates.endDate, 'MM/dd/yyyy') || '';
  const isDraft = campaignDraft?.status === 'draft' || campaignDraft?.status === 'error' || forceEdit;
  const [show, setShow] = useState(false);
  const onStartSelect = v => {
    const newStart = new Date(v);
    const endDate = new Date(dates.endDate);

    if (endDate < newStart) {
      onDateSelect({ startDate: newStart, endDate: newStart });
    } else {
      onDateSelect({ startDate: newStart, endDate });
    }
  };

  const onEndSelect = v => {
    const newEnd = new Date(v);
    const startDate = new Date(dates.startDate);

    if (newEnd < startDate) {
      onDateSelect({ startDate, endDate: startDate });
    } else {
      onDateSelect({ startDate, endDate: newEnd });
    }
  };

  const styleParams = {
    relative,
  };

  let datesSelector;

  switch (type) {
    case 'campaign-builder':
      datesSelector = getCampaignBuilderSelector(
        dates,
        fmtStart,
        fmtEnd,
        onStartSelect,
        onEndSelect,
        recurringEnabled,
        isDraft,
        disabled,
        setShow,
        show,
        status,
        testid
      );
      break;
    case 'report-header':
      datesSelector = getReportHeaderSelector(dates, fmtStart, fmtEnd, onStartSelect, onEndSelect, styleParams);
      break;
    case 'campaign-analytics':
      datesSelector = getAnalyticsSelector(dates, fmtStart, fmtEnd, onStartSelect, onEndSelect, styleParams);
      break;
    case 'campaign-clone':
      datesSelector = getAnalyticsSelector(dates, fmtStart, fmtEnd, onStartSelect, onEndSelect, styleParams);
      break;
    default:
      datesSelector = null;
      break;
  }

  return <div>{datesSelector}</div>;
};

const getCampaignBuilderSelector = (
  dates,
  fmtStart,
  fmtEnd,
  onStartSelect,
  onEndSelect,
  recurringEnabled,
  isDraft,
  disabled,
  setShow,
  show,
  status,
  testid = 'campaignDate'
) => (
  <div className="w-full flex flex-col">
    <div
      className="w-full pt-0 flex flex-col sm:flex-row items-start justify-start space-y-4 sm:space-y-0 sm:space-x-4"
      data-testid={testid}
    >
      <div onClick={() => setShow(!show)} className="flex-1" data-testid={`${testid}-startDate`}>
        <DateButton
          prefix="Start"
          title="Start Date"
          value={fmtStart}
          onSelect={onStartSelect}
          minDate={dates.minDate || new Date()}
          maxDate={dates.maxDate}
          disabled={!isDraft || status === 'completed'}
          data-testid="startDate"
        />
      </div>
      <div className="flex-1" data-testid={`${testid}-endDate`}>
        {!recurringEnabled ? (
          <DateButton
            prefix="End"
            title="End Date"
            value={fmtEnd}
            onSelect={onEndSelect}
            minDate={dates.minDate || new Date()}
            maxDate={dates.maxDate}
            disabled={disabled || status === 'completed'}
            data-testid="endDate"
          />
        ) : (
          <div />
        )}
      </div>
    </div>
    <div>
      {!isDraft && show && <div className="text-primary w-full pt-1 ">Start date can only be changed on a draft</div>}
    </div>
  </div>
);

const getAnalyticsSelector = (dates, fmtStart, fmtEnd, onStartSelect, onEndSelect, styleParams) => (
  <div
    className={`relative ${styleParams.relative ? '' : 'md:absolute md:right-96 md:pr-28 w-full flex flex-col mt-2'}`}
  >
    <div
      className={`w-full flex flex-col md:flex-row md:items-start md:justify-start space-y-2 md:space-y-0 md:space-x-4 ${
        styleParams.relative ? '' : 'pt-3'
      }`}
    >
      <div className="flex-1">
        <DateButton
          prefix="Start"
          title="Start Date"
          value={fmtStart}
          onSelect={onStartSelect}
          minDate={dates.minDate}
          maxDate={dates.maxDate}
          className="w-full sm:w-44 md:w-60 text-xs md:text-base p-1 md:p-3"
        />
      </div>
      <div className="flex-1">
        <DateButton
          prefix="End"
          title="End Date"
          value={fmtEnd}
          onSelect={onEndSelect}
          minDate={dates.minDate}
          maxDate={dates.maxDate}
          className="w-full sm:w-44 md:w-60 text-xs md:text-base p-1 md:p-3"
        />
      </div>
    </div>
  </div>
);

const getReportHeaderSelector = (dates, fmtStart, fmtEnd, onStartSelect, onEndSelect) => (
  <>
    <div className="flex-1 w-full pb-1">
      <DateButton
        prefix="Start"
        title="Start Date"
        value={fmtStart}
        onSelect={onStartSelect}
        minDate={dates.minDate}
        maxDate={dates.maxDate}
      />
    </div>
    <div className="flex-1 w-full">
      <DateButton
        prefix="End"
        title="End Date"
        value={fmtEnd}
        onSelect={onEndSelect}
        minDate={dates.minDate}
        maxDate={dates.maxDate}
      />
    </div>
  </>
);
