import { API, Auth } from 'aws-amplify';
import { campaignFields } from 'src/helpers/campaign/campaignFields';

const getBrandCampaignsQuery = /* GraphQL */ `
  query brandCampaigns($id: ID!, $nextToken: String) {
    searchCampaigns(filter: { brandId: { eq: $id }}, nextToken: $nextToken) {
      nextToken
      items {
        ${campaignFields}
      }
    }
  }
`;

export const getBrandCampaigns = async ({ brandId }) => {
  if (!brandId) return [];

  const current = await Auth.currentAuthenticatedUser();

  const getPage = next =>
    API.graphql(
      {
        query: getBrandCampaignsQuery,
        variables: { id: brandId, nextToken: next },
      },
      {
        Authorization: current.signInUserSession.idToken.jwtToken,
      }
    ).then(
      ({
        data: {
          searchCampaigns: { items, nextToken },
        },
      }) => {
        if (nextToken) return getPage(nextToken).then(resp => [...items, ...resp]);

        return items;
      }
    );

  const response = await getPage().then(resp =>
    resp.filter(itm => !itm.archived).sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
  );

  return response;
};
