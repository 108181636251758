import React, { useState, useEffect } from 'react';
import { TopSitesIcon } from 'src/svgs';
import { getDataAppMetrics } from 'src/helpers/getDataAppMetrics';
import { Campaign } from 'src/models';
import NumberFormat from 'react-number-format';
import { MetricsDetails } from '../shared/MetricsDetails';
import { DownloadCsvButton } from '../shared/DownloadCsvButton';

export const TopCities = ({ campaign = {} }) => {
  const [metrics, setMetrics] = useState([]);
  const [selected, setSelected] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let mounted = true;

    if (campaign.id) {
      setLoading(true);
      getDataAppMetrics(Campaign, campaign.id, 'CAMPAIGN_TOP_CITIES').then(resp => {
        if (mounted) {
          const arr = resp.items || [];
          const [defaultSelect] = arr.sort((a, b) => b.impressions - a.impressions);

          setSelected(defaultSelect);
          if (arr) setMetrics(arr);
          setLoading(false);
        }
      });
    }

    return () => (mounted = false);
  }, [campaign.id]);

  const empty = !loading && metrics.length < 1;

  if (loading || empty) return <div />;
  const label = selected?.metricKey || 'City Totals';

  const conversionsEnabled = !!campaign?.conversionEvents?.length;

  return (
    <div className="w-full flex flex-col space-y-4 rounded shadow bg-white p-4">
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-6">
          <TopSitesIcon className="w-11 h-11 mt-px stroke-current text-primary" />
          <h1 className="text-gray-900 text-xl font-semibold">Top Cities</h1>
        </div>
        <div>
          <DownloadCsvButton campaign={campaign} metricType="CAMPAIGN_TOP_CITIES" metrics={metrics} loading={loading} />
        </div>
      </div>
      <div className="w-full h-96 overflow-scroll px-8 py-4 border-gray-200 border-2 rounded-lg">
        <CitiesTable metrics={metrics} selected={selected} setSelected={setSelected} />
      </div>
      <div className="w-full">
        <div className="flex justify-center text-gray-900 text-lg font-medium mb-4">{label}</div>

        <MetricsDetails metrics={selected} conversionsEnabled={conversionsEnabled} />
      </div>
    </div>
  );
};

const CitiesTable = ({ metrics, selected, setSelected }) => {
  const [sortKey, setSortKey] = useState('impressions');

  const siteRows = metrics
    .sort((a, b) => (a[sortKey] < b[sortKey] ? 1 : -1))
    .sort((a, b) => {
      if (a[sortKey] === b[sortKey]) {
        return a.metricKey < b.metricKey ? 1 : -1;
      }

      return a[sortKey] < b[sortKey] ? 1 : -1;
    })
    .map(m => {
      const active = selected && selected.id === m.id;

      return (
        <tr
          key={m.id}
          onClick={() => setSelected(m)}
          className={`h-14 rounded ${active ? 'bg-primary text-white' : 'bg-white cursor-pointer'}`}
        >
          <td className="rounded-l text-left pl-4">{m.metricKey}</td>
          <td className="text-center">
            <NumberFormat
              value={m.impressions}
              displayType="text"
              decimalScale={0}
              fixedDecimalScale
              thousandSeparator
            />
          </td>
          <td className="text-center">
            <NumberFormat
              value={m.views || 0}
              displayType="text"
              decimalScale={0}
              fixedDecimalScale
              thousandSeparator
            />
          </td>
          <td className="text-center">
            <NumberFormat
              value={m.clicks || 0}
              displayType="text"
              decimalScale={0}
              fixedDecimalScale
              thousandSeparator
            />
          </td>
          <td className="text-center">
            <NumberFormat
              value={m.conversions || 0}
              displayType="text"
              decimalScale={0}
              fixedDecimalScale
              thousandSeparator
            />
          </td>
          <td className="rounded-r text-center">
            <NumberFormat
              value={m.spend || 0}
              displayType="text"
              prefix="$"
              decimalScale={2}
              fixedDecimalScale
              thousandSeparator
            />
          </td>
        </tr>
      );
    });

  return (
    <table className="relative w-full">
      <thead className="sticky top-0 h-12">
        <tr className="bg-white relative -top-4">
          <th className="text-left pl-4">City</th>
          <HeaderButton title="impressions" sortKey={sortKey} setSortKey={setSortKey} />
          <HeaderButton title="views" sortKey={sortKey} setSortKey={setSortKey} />
          <HeaderButton title="clicks" sortKey={sortKey} setSortKey={setSortKey} />
          <HeaderButton title="conversions" sortKey={sortKey} setSortKey={setSortKey} />
          <HeaderButton title="spend" sortKey={sortKey} setSortKey={setSortKey} />
        </tr>
      </thead>
      <tbody>{siteRows}</tbody>
    </table>
  );
};

const HeaderButton = ({ setSortKey, title, sortKey }) => {
  const active = sortKey === title;

  return (
    <th className="text-center w-24">
      <button
        type="button"
        onClick={() => setSortKey(title)}
        className={`w-full h-full font-semibold capitalize ${active ? ' text-black' : ' text-gray-400'}`}
      >
        {title}
      </button>
    </th>
  );
};
