import { API } from 'aws-amplify';
import { instrumentError } from './graphql/mutations';

class ErrorEmitter {
  constructor() {
    this.eventCount = 0;
    this.MAX_EVENTS = 5;
  }

  canEmit() {
    return this.eventCount < this.MAX_EVENTS;
  }

  async emitError(error, errorInfo) {
    if (!this.canEmit()) {
      console.warn('Reached maximum error reports for this session.');

      return;
    }

    this.eventCount += 1;

    const newErrorInfo = errorInfo ?? {};
    const input = {
      error: error.message,
      errorInfo: JSON.stringify(newErrorInfo),
    };

    try {
      await API.graphql({
        query: instrumentError,
        variables: { input },
      });
    } catch (e) {
      console.error(e);
    }
  }
}

const errorEmitter = new ErrorEmitter();

export default errorEmitter;
