import { useMemo } from 'react';
import { createAPIQueryHook, createAPIQuerySubscriptionHook } from '../../hooks/useAPIQuery';

const fields = /* GraphQL */ `
  {
    id
    brandId
    teamId
    organizationId
    createdAt
    updatedAt
    status
    email
    userId
    authRole
    staticAuthRole
    organization {
      id
      name
      theme {
        primary
        secondary
        pallet
      }
    }
    team {
      id
      name
      logoUrl
      theme {
        primary
        secondary
        pallet
      }
    }
    brand {
      id
      name
      logoUrl
      theme {
        primary
        secondary
        pallet
      }
    }
  }
`;

const listUserAuthRoles = /* GraphQL */ `
  query ListUserAuthRoles($filter: ModelUserAuthRoleFilterInput, $limit: Int, $nextToken: String) {
    authRoles: listUserAuthRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items ${fields}
    }
  }
`;

const getUserAuthRole = /* GraphQL */ `
  query GetUserAuthRoles($id: ID!) {
    result: getUserAuthRole(id: $id) ${fields}
  }
`;

const onCreateUserAuthRole = /* GraphQL */ `
  subscription OnCreateUserAuthRole($userId: String) {
    create: onCreateUserAuthRole(userId: $userId) ${fields}
  }
`;

const onUpdateUserAuthRole = /* GraphQL */ `
  subscription OnUpdateUserAuthRole($userId: String) {
    update: onUpdateUserAuthRole(userId: $userId) ${fields}
  }
`;

const onDeleteUserAuthRole = /* GraphQL */ `
  subscription OnDeleteUserAuthRole($userId: String) {
    delete: onDeleteUserAuthRole(userId: $userId) ${fields}
  }
`;

const useListUserAuthRoles = createAPIQueryHook({
  query: listUserAuthRoles,
  resultPath: 'authRoles.items',
  nextTokenPath: 'authRoles.nextToken',
  paged: true,
});

const useAuthRoleCreateSubscription = createAPIQuerySubscriptionHook({
  subscription: onCreateUserAuthRole,
  query: getUserAuthRole,
});

const useAuthRoleUpdateSubscription = createAPIQuerySubscriptionHook({
  subscription: onUpdateUserAuthRole,
  query: getUserAuthRole,
});

const useAuthRoleDeleteSubscription = createAPIQuerySubscriptionHook({
  subscription: onDeleteUserAuthRole,
  query: getUserAuthRole,
});

const useAuthRoleSubscription = (...args) => {
  useAuthRoleCreateSubscription(...args);
  useAuthRoleUpdateSubscription(...args);
  useAuthRoleDeleteSubscription(...args);
};

export const useUserAuthRoles = user => {
  const userId = user.id;
  const disable = !userId;

  const variables = useMemo(
    () => ({
      filter: {
        userId: {
          eq: userId,
        },
      },
    }),
    [userId]
  );

  const { data: roles, subscriptionHandler, loading, completed } = useListUserAuthRoles(variables, { disable });

  useAuthRoleSubscription({ userId }, { subscriptionHandler, disable });

  return { roles, loading, completed };
};
