import { useState } from 'react';
import { API } from 'aws-amplify';
import { queueCampaignSitesReport } from 'src/graphql/mutations';
import { useAppParams } from 'src/hooks/useAppParams';
import { useModal } from 'src/contexts';
import { useFeatureFlag } from 'src/helpers/featureFlags/useFeatureFlag';
import { useCSVDownloader } from 'src/components/CsvDownloader/CsvDownloader';
import { ColumnCell, ColumnHeader } from './Components';

export const ReportsColumn = {
  key: 'reports',
  header: () => <ColumnHeader className="w-96">Available Reports</ColumnHeader>,
  cell: function CampaignReportsCell({ campaign }) {
    const appParams = useAppParams();
    const hasSitesReportFlag = useFeatureFlag('CAMPAIGN_SITES_REPORT', false);
    const [sitesReportRequested, setSitesReportRequested] = useState(false);
    const { onClick: onCsvClick } = useCSVDownloader(appParams);
    const { openModal } = useModal();

    const onSitesClick = campaignId => {
      const input = { campaignId };

      API.graphql({ query: queueCampaignSitesReport, fetchPolicy: 'no-cache', variables: { input } });
      setSitesReportRequested(campaignId);
    };

    const metrics = (campaign?.summaryMetrics?.items || []).find(obj => obj.summaryType === 'ALL_TIME') || {};
    const { spend = 0 } = metrics;

    const showCsvSummary = spend > 0;

    const onReportClick = (modalKey, options) => {
      openModal(modalKey, options);
    };

    const onCsvDownloadClick = csvKey => {
      onCsvClick(csvKey, campaign);
    };

    return (
      <ColumnCell classNme="whitespace-nowrap">
        <div className="flex space-x-1">
          <button
            type="button"
            disabled={showCsvSummary ? false : 'disabled'}
            onClick={() => onReportClick('report-campaign-summary', { campaign })}
            className="bg-primary disabled:bg-gray-300 disabled:cursor-default rounded w-32 h-10 text-white"
          >
            Summary
          </button>
          <button
            type="button"
            disabled={showCsvSummary ? false : 'disabled'}
            onClick={() => onCsvDownloadClick('campaign-daily')}
            className="bg-primary disabled:bg-gray-300 disabled:cursor-default rounded w-32 h-10 text-white"
          >
            CSV
          </button>
          {hasSitesReportFlag && (
            <button
              type="button"
              disabled={showCsvSummary && sitesReportRequested !== campaign.id ? false : 'disabled'}
              onClick={() => onSitesClick(campaign.id)}
              className="bg-primary disabled:bg-gray-300 disabled:cursor-default rounded w-32 h-10 text-white"
            >
              {sitesReportRequested === campaign.id ? 'Emailed' : 'Sites'}
            </button>
          )}
        </div>
      </ColumnCell>
    );
  },
};
