import { useGAS } from 'src/GlobalAppState/context';

export const MarginLine = ({ marginPct, onClick }) => {
  const { organization } = useGAS();
  const orgIsWhiteLabel = organization?.accountDomain && organization?.accountSubdomain;

  if (orgIsWhiteLabel) return null;

  return (
    <div className="flex items-center space-x-2">
      <div className="flex-1 pl-4">
        <span className="font-medium whitespace-nowrap">Reporting Markup:</span>
      </div>
      <button type="button" onClick={onClick} className="text-lg text-primary underline">
        {`${marginPct || 0}%`}
      </button>
    </div>
  );
};
