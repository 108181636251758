import React from 'react';
import NumberFormat from 'react-number-format';
import { useModal } from 'src/contexts';
import { AddIcon } from 'src/svgs';
import { SavedPaymentMethods } from 'src/components/SavedPaymentMethods';

export const CreditOverview = ({
  appParams,
  paymentMethods,
  paymentMethodType,
  setPaymentMethodType,
  availBudget,
  showPrimary,
}) => {
  const { openModal } = useModal();
  const onAddCredits = () => openModal('add-credits');

  const onTransfer = () => {
    openModal('transfer-brand-credits');
  };

  return (
    <div data-mf-replace="" className="w-full flex space-x-20 overflow-y-auto flex-grow">
      <div className="flex-1 max-w-md flex flex-col space-y-4 mr-5 sm:mr-0">
        <h1 className="text-lg font-medium text-gray-600">Available Credits:</h1>
        <div className="w-full h-96 flex flex-col space-y-5 items-center border-2 rounded p-2">
          <div className="w-full flex-1 flex items-center justify-center">
            <NumberFormat
              className="text-5xl text-primary mt-8"
              value={availBudget}
              displayType="text"
              prefix="$"
              decimalScale={2}
              fixedDecimalScale
              thousandSeparator
              data-testid="available-credits"
            />
          </div>
          <div className="w-full flex flex-col items-center space-y-2">
            <button
              type="button"
              onClick={onAddCredits}
              className="bz-btn-outline w-full h-16 border-gray-300 border-1 flex items-center space-x-4"
              data-testid="add-credits"
            >
              <AddIcon className="stroke-current text-gray-300 h-4 w-4" />
              <h2 className="text-xl flex-1">Add Credits</h2>
            </button>
          </div>
          <div className="w-full flex flex-col items-center space-y-2">
            <button
              type="button"
              onClick={onTransfer}
              className="bz-btn-outline w-full h-16 border-gray-300 border-1 flex items-center space-x-4"
            >
              <AddIcon className="stroke-current text-gray-300 h-4 w-4" />
              <h2 className="text-xl flex-1">Transfer Credits</h2>
            </button>
          </div>
        </div>
      </div>
      <SavedPaymentMethods
        appParams={appParams}
        paymentMethods={paymentMethods}
        paymentMethodType={paymentMethodType}
        setPaymentMethodType={setPaymentMethodType}
        model="brand"
        showPrimary={showPrimary}
      />
    </div>
  );
};
