import lottie from 'lottie-web';

export const hexToRgb = hex =>
  hex
    ? hex
        .replace('#', '')
        .match(/\w\w/g)
        .map(x => parseInt(x, 16))
        .join()
    : '#CCC';

export const setThemeColor = (name, colorHex) => {
  const root = document.documentElement;

  if (colorHex) {
    root.style.setProperty(`--${name}`, colorHex);
  } else {
    root.style.setProperty(`--${name}`, 'initial');
  }
};

export const reloadAnimation = (ref, newAnimationData) => {
  const { wrapper } = ref.current;

  ref.current.destroy();
  ref.current = undefined;
  ref.current = lottie.loadAnimation({
    container: wrapper,
    renderer: 'svg',
    loop: false,
    autoplay: false,
    animationData: newAnimationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  });
};

export const changeStrokeColor = (ref, strokeColor, layerId) => {
  const { animationData: animData } = ref.current;
  const color = hexToRgb(strokeColor)
    .split(',')
    .map(str => Number(str) / 100);

  color.push(1);

  if (animData !== null) {
    animData.layers[0].ef[layerId].ef[0].v.k = color;
    reloadAnimation(ref, animData);
  }

  return null;
};
