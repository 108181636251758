import { API, Auth } from 'aws-amplify';

const getTeamBrandCampaignsQuery = /* GraphQL */ `
  query getTeamBrandCampaigns($id: ID!, $nextToken: String) {
    searchCampaigns(
      filter: { teamId: { eq: $id }, status: { eq: "running" } }
      nextToken: $nextToken
      aggregates: { type: terms, field: brandId, name: "byStatus" }
    ) {
      nextToken
      total
      aggregateItems {
        result {
          buckets {
            doc_count
            key
          }
          value
        }
        name
      }
    }
  }
`;

export const getTeamRunningCampaignCounts = async ({ team }) => {
  const { id: teamId } = team || {};

  let previousToken = '';

  const current = await Auth.currentAuthenticatedUser();

  const getPage = next =>
    API.graphql(
      {
        query: getTeamBrandCampaignsQuery,
        variables: { id: teamId, nextToken: next },
      },
      {
        Authorization: current.signInUserSession.idToken.jwtToken,
      }
    ).then(({ data: { searchCampaigns: { aggregateItems: [items] = [], nextToken } = {} } }) => {
      previousToken = nextToken;
      const results = items?.result?.buckets || [];

      const runningCampaignsByBrand = results.map(c => ({
        brandId: c.key,
        count: c.doc_count,
      }));

      if (nextToken && previousToken !== nextToken)
        return getPage(nextToken).then(resp => [...runningCampaignsByBrand, ...resp]);

      const teamLookup = runningCampaignsByBrand.reduce((running, tm) => {
        running[tm.brandId] = tm.count;

        return running;
      }, {});

      return teamLookup;
    });

  if (teamId) return getPage();

  return [];
};
