import { brandFields } from 'src/helpers/brand/brandFields';
import { createAPIQueryHook, createAPIQuerySubscriptionHook } from '../../hooks/useAPIQuery';

const getTeamBrands = /* GraphQL */ `
  query teamBrands($id: ID!, $nextToken: String) {
    brands: searchBrands(filter: { teamId: { eq: $id }, archived: { ne: true }}, nextToken: $nextToken) {
      nextToken
      items {
        ${brandFields}
      }
    }
  }
`;

const getBrand = /* GraphQL */ `
  query GetBRand($id: ID!) {
    result: getBrand(id: $id) {
      ${brandFields}
    }
  }
`;

const useTeamBrandsQuery = createAPIQueryHook({
  query: getTeamBrands,
  paged: true,
  nextTokenPath: 'brands.nextToken',
  resultPath: 'brands.items',
});

const onCreateBrand = /* GraphQL */ `
  subscription OnCreateBrand($organizationId: String, $teamId: String) {
    onCreateBrand(organizationId: $organizationId, teamId: $teamId) {
      id
      teamId
      organizationId
    }
  }
`;

const useTeamBrandCreateSubscription = createAPIQuerySubscriptionHook({
  subscription: onCreateBrand,
  query: getBrand,
});

const onUpdateBrand = /* GraphQL */ `
  subscription OnUpdateBrand($organizationId: String, $teamId: String) {
    onUpdateBrand(organizationId: $organizationId, teamId: $teamId) {
      id
      teamId
      organizationId
    }
  }
`;

const useTeamBrandUpdateSubscription = createAPIQuerySubscriptionHook({
  subscription: onUpdateBrand,
  query: getBrand,
});

const useTeamBrandsSubscription = (...args) => {
  useTeamBrandCreateSubscription(...args);
  useTeamBrandUpdateSubscription(...args);
};

export const useTeamBrands = ({ organizationId, teamId }) => {
  const disable = !organizationId || !teamId;
  const { data: brands, subscriptionHandler, loading, reload } = useTeamBrandsQuery({ id: teamId }, { disable });

  useTeamBrandsSubscription({ organizationId, teamId }, { subscriptionHandler, disable });

  return {
    teamBrands: brands ?? [],
    loading,
    reload,
  };
};
