import { useModal } from 'src/contexts/modalContext';
import { useGAS } from 'src/GlobalAppState/context';
import { useAppParams } from 'src/hooks/useAppParams';
import { CreditHistory } from 'src/components/MainSettings/TeamSettings/TeamSettingsCredits/CreditHistory';
import { CreditMarginHeader, HeaderButton, HeaderLink } from 'src/components/MainSettings/shared/Headers';
import { BsNodePlusFill } from 'react-icons/bs';

const TeamEarningsPageBody = () => {
  const appParams = useAppParams();

  return <CreditHistory appParams={appParams} marginHistory />;
};

const TeamEarningsEmptyState = () => {
  const { team } = useGAS();

  return (
    <div className="border rounded flex flex-col space-y-8 items-center justify-center flex-1 shadow">
      <p className="max-w-lg text-center">
        Your agency hasn't accrued any margin earnings. Upon completion of a campaign associated with a brand that has a
        margin applied, you'll see those earnings reflected here.
      </p>
      <div>
        <HeaderLink href={`/team/${team.id}/margin`} primaryAction>
          Configure Margin
        </HeaderLink>
      </div>
    </div>
  );
};

export default function TeamEarningsPage() {
  const {
    credits: { teamPayoutRecords, teamPayoutCredits },
  } = useGAS();
  const { openModal } = useModal();

  const showTransfer = teamPayoutCredits > 1;

  return (
    <div className="flex flex-1 flex-col justify-start space-y-8">
      <CreditMarginHeader>
        {showTransfer && (
          <HeaderButton onClick={() => openModal('transfer-agency-margin')} icon={BsNodePlusFill} primaryAction>
            Redeem for Credits at a 10% Bonus
          </HeaderButton>
        )}
      </CreditMarginHeader>
      {teamPayoutRecords?.length ? <TeamEarningsPageBody /> : <TeamEarningsEmptyState />}
    </div>
  );
}
