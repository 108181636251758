export const TileList = ({ active, step, className }) => {
  const alpha = active ? '0.5' : '0.2';
  const fill = active ? '#FFFFFF' : '#1F2937';

  return (
    <svg viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        d="M4.83334 11C4.83334 10.7348 4.9387 10.4804 5.12624 10.2929C5.31377 10.1054 5.56813 10 5.83334 10H27.8333C28.0986 10 28.3529 10.1054 28.5405 10.2929C28.728 10.4804 28.8333 10.7348 28.8333 11C28.8333 11.2652 28.728 11.5196 28.5405 11.7071C28.3529 11.8946 28.0986 12 27.8333 12H5.83334C5.56813 12 5.31377 11.8946 5.12624 11.7071C4.9387 11.5196 4.83334 11.2652 4.83334 11Z"
        fill={fill}
        fillOpacity={step === 0 ? '1' : alpha}
      />
      <path
        d="M8.5 17C8.5 16.7348 8.60536 16.4804 8.79289 16.2929C8.98043 16.1054 9.23478 16 9.5 16H23.5C23.7652 16 24.0196 16.1054 24.2071 16.2929C24.3946 16.4804 24.5 16.7348 24.5 17C24.5 17.2652 24.3946 17.5196 24.2071 17.7071C24.0196 17.8946 23.7652 18 23.5 18H9.5C9.23478 18 8.98043 17.8946 8.79289 17.7071C8.60536 17.5196 8.5 17.2652 8.5 17Z"
        fill={fill}
        fillOpacity={step === 1 ? '1' : alpha}
      />
      <path
        d="M12.1666 23C12.1666 22.7348 12.272 22.4804 12.4595 22.2929C12.6471 22.1054 12.9014 22 13.1666 22H19.1666C19.4319 22 19.6862 22.1054 19.8737 22.2929C20.0613 22.4804 20.1666 22.7348 20.1666 23C20.1666 23.2652 20.0613 23.5196 19.8737 23.7071C19.6862 23.8946 19.4319 24 19.1666 24H13.1666C12.9014 24 12.6471 23.8946 12.4595 23.7071C12.272 23.5196 12.1666 23.2652 12.1666 23Z"
        fill={fill}
        fillOpacity={step === 2 ? '1' : alpha}
      />
    </svg>
  );
};
