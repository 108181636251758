import { useEffect, useRef } from 'react';
import { AddIcon, CloseIcon } from 'src/svgs';

export const NewCustomTagRow = ({
  tagDraft,
  setTagDraft,
  showNewRow,
  setShowNewRow,
  submitting,
  rowCount,
  onCreate,
  error,
  setError,
  setSubmitting,
}) => {
  const inputRef = useRef();

  const show = showNewRow || rowCount === 0;

  useEffect(() => {
    if (show && inputRef.current) inputRef.current.select();
  }, [show]);

  const onClose = () => {
    if (error) {
      setTagDraft({});
      setError('');
      setSubmitting(false);
    }
    setShowNewRow(false);
  };

  const disableSubmit = !tagDraft.ttdId || tagDraft.status === 'Submitting...';
  let submitButton = (
    <button
      type="button"
      onClick={() => !disableSubmit && onCreate()}
      className={`${
        disableSubmit ? 'opacity-25 hover:opacity-25 cursor-default' : 'cursor-pointer'
      } h-10 bz-btn rounded bg-primary text-white w-32 focus:ring-secondary focus:opacity-80 p-1`}
    >
      Import
    </button>
  );

  if (!show) {
    submitButton = (
      <button
        type="button"
        onClick={() => !submitting && setShowNewRow(true)}
        className="bz-btn-icon ring-0 rounded-full w-32 p-1"
      >
        <AddIcon className="stroke-current text-primary w-6 h-3" />
        Add Tag
      </button>
    );
  }

  return (
    <tr className="w-full">
      <td className="w-20">
        {show && (
          <div className="w-full h-full flex items-center justify-center">
            <button
              type="button"
              onClick={() => onClose()}
              className="bz-btn-icon rounded-full w-6 h-6 border-2 border-gray-300 p-1"
            >
              <CloseIcon className="stroke-current text-gray-300 w-4 h-4" />
            </button>
          </div>
        )}
      </td>
      <td className="h-20 py-2">
        {show && !submitting && (
          <input
            ref={inputRef}
            type="text"
            placeholder="Custom Tag ID"
            className="bz-input bg-blueGray-50 shadow-inner text-xl focus:ring px-2 -ml-2"
            value={tagDraft.ttdId || ''}
            onChange={({ target }) => setTagDraft({ ...tagDraft, ttdId: target.value })}
          />
        )}
        {submitting && error && (
          <div className="flex w-full">
            <input
              type="text"
              placeholder="Custom Tag ID"
              className="bz-input bg-blueGray-50 shadow-inner text-xl focus:ring px-2 -ml-2"
              value={tagDraft.ttdId || ''}
              disabled={!error}
              onChange={({ target }) => setTagDraft({ ...tagDraft, ttdId: target.value })}
            />
            {error && <div className="absolute mt-2 ml-60 text-lg text-red-600">{error}</div>}
          </div>
        )}
      </td>
      <td className="py-2 w-full flex justify-between items-center px-2">
        {rowCount === 0 && (
          <div className="h-16 flex items-center">
            {!submitting && (
              <h3 className="text-lg px-2 text-primary">
                {tagDraft.status ? tagDraft.status : 'Create Your First Custom Tag!'}
              </h3>
            )}
          </div>
        )}
      </td>
      <td className="w-40">
        {rowCount === 0 && (
          <button
            type="button"
            onClick={() => !disableSubmit && onCreate()}
            className={`${
              disableSubmit ? 'opacity-25 hover:opacity-25 cursor-default' : 'cursor-pointer'
            } h-10 bz-btn rounded bg-primary text-white w-32 focus:ring-secondary focus:opacity-80 p-1`}
          >
            Import
          </button>
        )}
      </td>
      <td className="w-20">
        {rowCount !== 0 && <div className="w-full h-full flex items-center justify-center">{submitButton}</div>}
      </td>
    </tr>
  );
};
