import { API } from 'aws-amplify';
import { trackingTagFields } from './trackingTagFields';
import { putAppEvent } from '../putAppEvent';

const updateTrackingTagQuery = /* GraphQL */ `
  mutation UpdateTrackingTag($input: UpdateTrackingTagInput!) {
    updateTrackingTag(input: $input) {
      ${trackingTagFields}
    }
  }
`;

const cleanTrackingTagInput = trackingTag => {
  const { dspIds: _dspIds, brand: _brand, ...rest } = trackingTag;

  return rest;
};

export const updateTrackingTag = async trackingTag => {
  const clean = cleanTrackingTagInput(trackingTag);

  if (clean.id) {
    return API.graphql({
      query: updateTrackingTagQuery,
      variables: {
        input: clean,
      },
    })
      .then(({ data: { updateTrackingTag: resp } = {} }) => resp || false)
      .then(resp => {
        putAppEvent('TrackingTagMutation', clean);

        return resp;
      });
  }

  return Promise.reject();
};
