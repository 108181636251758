import { createContext, useReducer, useRef, useCallback } from 'react';
import { isFunction } from 'lodash';
import { featureFlagReducer } from './featureFlagReducer';

const useReducerWithThunk = (reducer, initState) => {
  const lastState = useRef(initState);
  const getState = useCallback(() => lastState.current, [lastState]);

  const [derivedState, dispatch] = useReducer((state, action) => {
    const newState = reducer(state, action);

    lastState.current = newState;

    return newState;
  }, initState);

  const overrideDispatch = useCallback(
    action => {
      if (isFunction(action)) {
        action(overrideDispatch, getState);
      } else {
        dispatch(action);
      }
    },
    [getState, dispatch]
  );

  return [derivedState, overrideDispatch];
};

export const FeatureFlagContext = createContext([{}, () => {}]);

export const FeatureFlagContextProvider = ({ children }) => {
  const reducerInfo = useReducerWithThunk(featureFlagReducer, {});

  return <FeatureFlagContext.Provider value={reducerInfo}>{children}</FeatureFlagContext.Provider>;
};
