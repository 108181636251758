import React, { useState, useEffect, useRef } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { SearchIcon } from 'src/svgs';
import { useModal } from 'src/contexts';

let filterTimeout;

const politicalIds = [4385, 195];

export const SegmentSearch = ({ appParams, selectSegment, segmentType, campaignDraft, setCampaignDraft }) => {
  const { campaign, brand: { industryCategoryId } = {} } = appParams || {};

  const isPolitical = industryCategoryId && politicalIds.includes(industryCategoryId);
  const [selected, setSelected] = useState([]);
  const [search, setSearch] = useState('');
  const [delayedSearch, setDelayedSearch] = useState('');
  const [results, setResults] = useState([]);
  const inputRef = useRef();
  const { openModal } = useModal();
  const campaignStatus = campaign.status;

  const [isDisabled, setDisabled] = useState(false);

  useEffect(() => {
    if (campaignStatus === 'completed') {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [campaignStatus]);

  const toggleSelect = item => {
    if (selected.includes(item)) {
      setSelected(selected.filter(mapItem => mapItem !== item));
    } else {
      setSelected([...selected, item]);
    }
  };

  useEffect(() => {
    clearTimeout(filterTimeout);
    const delayedSetSearch = () => setDelayedSearch(search);

    filterTimeout = setTimeout(() => delayedSetSearch(), 250);

    return () => clearTimeout(filterTimeout);
  }, [search, setDelayedSearch]);

  useEffect(() => {
    let mounted = true;

    if (delayedSearch.length > 1) {
      const searchTerms = delayedSearch
        .split(' ')
        .map(word => ({ pathKey: { wildcard: `${word.toLocaleLowerCase()}*` } }));

      // TEMP: hide international segments until we're ready to open it up. Also push geo-scraper service
      // searchTerms.push({ pathKey: { matchPhrasePrefix: `united states` } });

      const segmentGroupFilters = selected.map(itm => {
        let val = itm;

        if (itm === 'State') val = 'Region';

        return { segmentGroup: { eq: val } };
      });

      const filter = {
        and: [
          ...searchTerms,
          {
            or: segmentGroupFilters,
          },
          { segmentType: { eq: 'GEO' } },
        ],
      };

      if (isPolitical) {
        filter.and.push({
          or: [{ segmentPackageId: { eq: 'default' } }, { segmentPackageId: { eq: 'political' } }],
        });
      } else {
        filter.and.push({ segmentPackageId: { eq: 'default' } });
      }

      const sort = [{ direction: 'desc', field: 'pathKey' }];
      const query = graphqlOperation(
        `query daSearchSegments(
          $filter: SearchableSegmentFilterInput
          $sort: [SearchableSegmentSortInput]
          $limit: Int
        ) {
          daSearchSegments(filter: $filter, sort: $sort, limit: $limit) {
            items {
              id
              name
              pathKey
              segmentType
              segmentGroup
              geoJson {
                type
                coordinates
              }
            }
          }
        }`,
        {
          filter,
          sort,
          limit: 20,
        }
      );

      API.graphql(query)
        .then(({ data: { daSearchSegments: { items } } = {} }) => items || [])
        .then(resp => mounted && setResults(resp));
    } else {
      setResults([]);
    }

    return () => (mounted = false);
  }, [delayedSearch, selected, segmentType, isPolitical]);

  const onSelect = segment => {
    const { id, pathKey, name, segmentId: dspId, segmentGroup, geoJson } = segment;

    selectSegment({ id, pathKey, dspId, segmentGroup, name, geoJson });
    setSearch('');
    setResults([]);
    inputRef.current.focus();
  };

  const searchResults = results.map(segment => {
    const { name } = segment;

    return (
      <button
        type="button"
        onClick={() => onSelect(segment)}
        key={segment.id}
        className="w-full h-10 border-b-2 flex items-center text-left hover:bg-blue-100"
      >
        <span className="text-xs sm:text-lg text-gray-600 w-full truncate overflow-x-scroll">{name}</span>
      </button>
    );
  });

  const showResults = results && results.length > 0;

  return (
    <div className="w-full sm:px-8 pt-8">
      <div className="w-full flex flex-col px-2 pb-4 pt-1 border-primary border-2 rounded-lg">
        <h2 className="text-secondary p-2 px-3">Select the filters below to narrow down your geo search.</h2>
        <SegmentTypeFilter
          isPolitical={isPolitical}
          selected={selected}
          toggleSelect={toggleSelect}
          appParams={appParams}
        />
        <div className="w-full sm:flex-col">
          {!isDisabled ? (
            <div className="w-full pb-1 px-2 relative top-0 text-gray-400 focus-within:text-gray-500">
              <div className="flex w-full justify-between sm:flex-col-reverse">
                <div className="w-full sm:w-auto absolute pr-4 sm:pr-0 top-2 sm:top-7 sm:right-2">
                  <button
                    type="button"
                    disabled={isDisabled}
                    onClick={() => openModal('bulk-zip-code-uploader', { campaignDraft, setCampaignDraft })}
                    className="w-full sm:w-auto py-2 border-primary border rounded flex justify-center items-center text-primary group-hover:bg-primary"
                  >
                    <span className="text-sm sm:text-base px-3 group-hover:text-white">+ Upload Zip Codes</span>
                  </button>
                </div>
                <div className="flex-grow sm:w-2/3 pb-1 px-2 relative text-gray-400 focus-within:text-gray-500 mt-14 sm:mt-6">
                  <input
                    ref={inputRef}
                    type="search"
                    placeholder="Search"
                    value={search}
                    onChange={({ target: { value } }) => setSearch(value)}
                    className="text-lg w-full h-12 border-0 bg-blueGray-150 pl-12 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primary rounded-lg"
                  />
                  <div className="h-16 absolute left-2 -top-2 flex items-center justify-center pl-4">
                    <SearchIcon className="h-5 stroke-current text-primary" />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-full pb-1 px-2 relative text-gray-400 focus-within:text-gray-500">
              <div className="flex w-full justify-between sm:flex-col-reverse">
                <div className="w-full sm:w-auto mt-2 sm:mt-0">
                  <button
                    type="button"
                    disabled={isDisabled}
                    className="w-full sm:w-auto py-2 border-primary border rounded flex justify-center items-center text-primary opacity-25 cursor-not-allowed"
                  >
                    <span className="text-base px-3 opacity-25">+ Upload Zip Codes</span>
                  </button>
                </div>
                <div className="flex-grow sm:w-2/3 pb-1 px-2 relative text-gray-400 focus-within:text-gray-500">
                  <input
                    ref={inputRef}
                    type="search"
                    placeholder="Search"
                    disabled={isDisabled}
                    value={search}
                    className="text-lg w-full h-12 border-0 bg-blueGray-150 pl-12 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primary rounded-lg opacity-25 cursor-not-allowed"
                  />
                  <div className="h-16 absolute left-2 -top-2 flex items-center justify-center pl-4 opacity-25">
                    <SearchIcon className="h-5 stroke-current text-primary" />
                  </div>
                </div>
              </div>
            </div>
          )}
          {showResults && (
            <div className="w-full sm:w-2/3 z-30 h-0 relative">
              <div className="absolute top-2 w-full bg-white rounded shadow p-3 max-h-96 overflow-scroll">
                <div className="flex flex-col w-full">{searchResults}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const SegmentTypeFilter = ({ isPolitical, selected = [], toggleSelect, appParams }) => {
  const options = ['Country', 'State', 'City', 'Metro', 'Zip'];
  const politicalOptions = ['Congress', 'SLDL', 'SLDU'];
  const [isDisabled, setDisabled] = useState(false);
  const { campaign } = appParams;
  const campaignStatus = campaign.status;

  useEffect(() => {
    if (campaignStatus === 'completed') {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [campaignStatus]);

  const politicalChips = politicalOptions.map(key => {
    const active = selected.includes(key);
    const { [key]: title } = {
      Congress: 'House Congressional Districts',
      SLDL: 'State Lower Legislative Districts',
      SLDU: 'State Upper Legislative Districts',
    };

    return (
      <button
        key={key}
        type="button"
        onClick={() => toggleSelect(key)}
        className={`w-full py-2 border-primary border rounded flex justify-center items-center ${
          active ? 'bg-primary text-white' : 'bg-white text-primary'
        }`}
      >
        <span className="text-xs sm:text-base px-3">{title}</span>
      </button>
    );
  });

  const chips = options.map(key => {
    const active = selected.includes(key);

    return (
      <button
        key={key}
        type="button"
        disabled={isDisabled}
        onClick={() => toggleSelect(key)}
        className={`w-full h-10 mx-px border-primary border rounded flex justify-center items-center ${
          active ? 'bg-primary text-white' : 'bg-white text-primary'
        } ${
          isDisabled
            ? 'w-full h-10 mx-px border-primary border rounded flex justify-center items-center  opacity-25 cursor-not-allowed text-white'
            : ''
        }`}
      >
        <span className="text-base whitespace-nowrap px-3">{key}</span>
      </button>
    );
  });

  return (
    <div className="flex flex-col w-full px-2">
      <h2 className="text-secondary py-2">Search Filters</h2>
      <div className="flex-wrap sm:flex-nowrap w-full grid grid-cols-2 sm:grid-cols-5 gap-1">
        {chips.map((chip, index) => (
          <div key={index} className="">
            {chip}
          </div>
        ))}
      </div>
      {isPolitical && (
        <div className="w-full">
          <h2 className="text-secondary py-2">Political Search Filters</h2>
          <div className="sm:flex sm:w-full flex-wrap sm:flex-nowrap grid grid-cols-3 gap-1">{politicalChips}</div>
        </div>
      )}
    </div>
  );
};
