/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useMemo, useEffect, useState } from 'react';
import { TtdCandidateProfile, PoliticalSettings } from 'src/models';
import { ttdStates } from 'src/constants';
import { isEmpty } from 'lodash';
import { AddressSearch } from './AddressSearch';

export const ElectionSettings = ({ brandDraft, updateField, asterisks = true }) => {
  const settingsDraft = useMemo(() => {
    if (!isEmpty(brandDraft?.politicalSettings)) return brandDraft?.politicalSettings;

    const candidateInput = new TtdCandidateProfile(brandDraft?.politicalSettings?.candidate || {});
    const ballotMeasureProfileInput = brandDraft?.politicalSettings?.ballotMeasureProfile || {};
    const initSettings = brandDraft?.politicalSettings || {};

    return new PoliticalSettings({
      ...initSettings,
      candidate: candidateInput,
      ballotMeasureProfile: ballotMeasureProfileInput,
    });
  }, [brandDraft]);

  const candidateDraft = settingsDraft.candidate || new TtdCandidateProfile({});
  const ballotMeasureDraft = settingsDraft.ballotMeasureProfile || {};
  const isFederal = settingsDraft?.politicalType === 'federal';
  const isStateOrLocal = settingsDraft?.politicalType === 'stateOrLocal';
  const federalId = candidateDraft.FederalVerificationId || '';
  const stateId = candidateDraft.StateVerificationId || '';
  const politicalId = isFederal ? federalId : stateId;
  const isBallot = settingsDraft?.electionType === 'ballotMeasure';
  const isCandidateElection = settingsDraft?.electionType === 'candidateElection';
  const isBoth = settingsDraft?.electionType === 'both';
  const shouldShowCandidateSettings = isCandidateElection || isBoth;
  const shouldShowBallotSettings = isBallot || isBoth;

  const updatePoliticalSettings = val => updateField('politicalSettings', val);

  const updateSettingsDraft = (key, value) => {
    updatePoliticalSettings({ ...settingsDraft, [key]: value || '' });
  };

  const handleStateChange = event => {
    const selectedState = event.target.value;

    updateCandidateDraft('ElectionState', selectedState);
  };

  const handleBallotMeasureStateChange = event => {
    const selectedState = event.target.value;

    updateBallotMeasureProfileDraft('ElectionState', selectedState);
  };

  const updateCandidateDraft = (key, evt) => {
    const targetValue = evt.target?.value || '';
    const value = evt.target ? targetValue : evt || '';
    const currentCandidate = settingsDraft.candidate || {};

    updatePoliticalSettings({ ...settingsDraft, candidate: { ...currentCandidate, [key]: value || '' } });
  };

  const updateBallotMeasureProfileDraft = (key, evt) => {
    const targetValue = evt.target?.value || '';
    const value = evt.target ? targetValue : evt || '';
    const currentBallotMeasureProfile = settingsDraft.ballotMeasureProfile || {};

    updatePoliticalSettings({
      ...settingsDraft,
      ballotMeasureProfile: { ...currentBallotMeasureProfile, [key]: value || '' },
    });
  };

  const asterisk = asterisks ? <small className="ml-1 text-gray-400">* required</small> : null;
  const selected = 'bg-secondary';

  return (
    <div className="flex flex-col space-y-2 w-full">
      <div className="flex flex-col space-y-2 w-full">
        <label className={`flex-1 w-full py-2 px-4 rounded-lg ${isBallot ? selected : 'bg-white'}`}>
          <input
            type="radio"
            name="electionType"
            value="ballotMeasure"
            checked={isBallot}
            onChange={() => updateSettingsDraft('electionType', 'ballotMeasure')}
            className="text-secondary focus-white  focus:ring-white ring-white ring-2"
          />
          <span className={`py-2 px-4 ${isBallot ? 'text-white' : 'text-gray-600'}`}>Ballot Measure</span>
        </label>

        <label className={`flex-1 w-full py-2 px-4 rounded-lg ${isCandidateElection ? selected : 'bg-white'}`}>
          <input
            type="radio"
            name="electionType"
            value="candidateElection"
            checked={isCandidateElection}
            onChange={() => updateSettingsDraft('electionType', 'candidateElection')}
            className="text-secondary focus-white  focus:ring-white ring-white ring-2"
          />
          <span className={`py-2 px-4 ${isCandidateElection ? 'text-white' : 'text-gray-600'}`}>
            Candidate Election
          </span>
        </label>

        <label className={`flex-1 w-full py-2 px-4 rounded-lg ${isBoth ? selected : 'bg-white'}`}>
          <input
            type="radio"
            name="electionType"
            value="both"
            checked={isBoth}
            onChange={() => updateSettingsDraft('electionType', 'both')}
            className="text-secondary focus-white  focus:ring-white ring-white ring-2"
          />
          <span className={`py-2 px-4 ${isBoth ? 'text-white' : 'text-gray-600'}`}>Both</span>
        </label>
      </div>
      <div>
        {shouldShowCandidateSettings && (
          <>
            <div className="pb-5">
              <p className="pb-1 font-medium text-gray-600">Candidate Name {asterisk}</p>
              <CandNameField updateCandidateDraft={updateCandidateDraft} candidateDraft={candidateDraft} />
            </div>
            <div className="border-b my-5" />
            <label htmlFor="isFederal">
              <p className="my-4 pb-1 font-medium text-blueGray-500">Verification</p>
              <div className="flex flex-col space-y-2">
                <p className="pb-1 font-medium text-gray-600">Election Type {asterisk}</p>
                <div className="flex flex-col space-y-2 w-full">
                  <label
                    className={`w-full py-2 px-4 rounded-lg ${isFederal ? selected : 'bg-white'}`}
                    onChange={() => updateSettingsDraft('politicalType', 'federal')}
                  >
                    <input
                      type="radio"
                      name="politicalType"
                      value="federal"
                      checked={isFederal}
                      onChange={() => updateSettingsDraft('politicalType', 'federal')}
                      className="text-secondary focus-white  focus:ring-white ring-white ring-2"
                    />
                    <span className={`py-2 px-4 ${isFederal ? 'text-white' : 'text-gray-600'}`}>Federal</span>
                  </label>
                  <label
                    className={`flex-1 py-2 px-4 rounded-lg ${isStateOrLocal ? selected : 'bg-white'}`}
                    onChange={() => updateSettingsDraft('politicalType', 'stateOrLocal')}
                  >
                    <input
                      type="radio"
                      name="politicalType"
                      value="state"
                      checked={isStateOrLocal}
                      onChange={() => updateSettingsDraft('politicalType', 'state')}
                      className="text-secondary focus-white  focus:ring-white ring-white ring-2"
                    />
                    <span className={`py-2 px-4 ${isStateOrLocal ? 'text-white' : 'text-gray-600'}`}>State</span>
                  </label>
                </div>
                <div className="w-full flex space-x-2">
                  {isStateOrLocal && (
                    <div>
                      <label htmlFor="stateSelect" className="flex flex-grow-0">
                        <select
                          id="stateSelect"
                          className="flex-1 bg-white border border-gray-500 focus:border-white focus:bg-white placeholder-gray-500 focus:ring-secondary rounded"
                          onChange={handleStateChange}
                          value={candidateDraft.ElectionState || ttdStates[0].abbreviation}
                        >
                          {ttdStates.map(state => (
                            <option key={state.abbreviation} value={state.name}>
                              {state.abbreviation}
                            </option>
                          ))}
                        </select>
                      </label>

                      <p className="mb-2 max-w-xs pt-1">Note: The state of Washington is not currently supported.</p>
                    </div>
                  )}
                  <input
                    className="bz-input flex-1 bg-white border border-gray-500 focus:border-white focus:bg-white placeholder-gray-500 focus:ring-secondary"
                    placeholder={isFederal ? 'FEC ID Number' : 'EIN Number'}
                    value={politicalId}
                    onChange={evt => {
                      const key = isFederal ? 'FederalVerificationId' : 'StateVerificationId';

                      updateCandidateDraft(key, evt);
                    }}
                  />
                </div>
              </div>
            </label>
            <label htmlFor="isFederal">
              <div className="border-b my-2" />
              <p className="pb-3 font-medium text-blueGray-500">Paying Entity</p>
              <div className="flex flex-col space-y-2">
                <div className="w-full">
                  <p className="pb-1 font-medium text-gray-600">Entity Name {asterisk}</p>
                  <h1 className="text-xs">The entity paying for political advertisement</h1>

                  <PayeeNameField candidateDraft={candidateDraft} updateCandidateDraft={updateCandidateDraft} />
                </div>
                <div className="w-full flex-col">
                  <ExecNameField
                    candidateDraft={candidateDraft}
                    updateCandidateDraft={updateCandidateDraft}
                    asterisk={asterisk}
                  />
                  <p className="pb-1 font-medium text-gray-600">Title of Executive {asterisk}</p>

                  <input
                    className="bz-input flex w-full bg-white border border-gray-500 focus:border-white focus:bg-white placeholder-gray-500 focus:ring-secondary"
                    placeholder="Title"
                    value={candidateDraft.PayingEntityExecutiveTitle || ''}
                    onChange={evt => updateCandidateDraft('PayingEntityExecutiveTitle', evt)}
                  />
                </div>
                <div className="w-full">
                  <p className="pb-1 font-medium text-gray-600">Address{asterisk}</p>

                  <AddressSearch
                    inputValue={candidateDraft.PayingEntityAddress}
                    updateCandidateDraft={updateCandidateDraft}
                    candidateDraft={candidateDraft}
                  />
                </div>
              </div>
            </label>
          </>
        )}
        {shouldShowBallotSettings && (
          <div className="pb-5">
            <p className="pb-1 font-medium text-gray-600">Ballot Measure Profile{asterisk}</p>
            <div className="border-b my-2" />
            <p className="pb-3 font-medium text-blueGray-500">Paying Entity</p>
            <div className="flex flex-col space-y-2">
              <div className="w-full">
                <p className="pb-1 font-medium text-gray-600">Entity Name {asterisk}</p>
                <h1 className="text-xs">The entity paying for political advertisement</h1>
                <div>
                  <label htmlFor="stateSelect" className="flex flex-grow-0">
                    <select
                      id="stateSelect"
                      className="flex-1 bg-white border border-gray-500 focus:border-white focus:bg-white placeholder-gray-500 focus:ring-secondary rounded"
                      onChange={handleBallotMeasureStateChange}
                      value={ballotMeasureDraft.ElectionState || ttdStates[0].abbreviation}
                    >
                      {ttdStates.map(state => (
                        <option key={state.abbreviation} value={state.name}>
                          {state.abbreviation}
                        </option>
                      ))}
                    </select>
                  </label>

                  <p className="mb-2 max-w-xs pt-1">Note: The state of Washington is not currently supported.</p>
                </div>

                <PayeeNameField
                  candidateDraft={ballotMeasureDraft}
                  updateCandidateDraft={updateBallotMeasureProfileDraft}
                />
              </div>
              <div className="w-full flex-col">
                <ExecNameField
                  candidateDraft={ballotMeasureDraft}
                  updateCandidateDraft={updateBallotMeasureProfileDraft}
                  asterisk={asterisk}
                />
                <p className="pb-1 font-medium text-gray-600">Title of Executive {asterisk}</p>

                <input
                  className="bz-input flex w-full bg-white border border-gray-500 focus:border-white focus:bg-white placeholder-gray-500 focus:ring-secondary"
                  placeholder="Title"
                  value={ballotMeasureDraft.PayingEntityExecutiveTitle || ''}
                  onChange={evt => updateBallotMeasureProfileDraft('PayingEntityExecutiveTitle', evt)}
                />
              </div>
              <div className="w-full">
                <p className="pb-1 font-medium text-gray-600">Address{asterisk}</p>

                <AddressSearch
                  inputValue={ballotMeasureDraft.PayingEntityAddress}
                  updateCandidateDraft={updateBallotMeasureProfileDraft}
                  candidateDraft={ballotMeasureDraft}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const PayeeNameField = ({ candidateDraft, updateCandidateDraft }) => {
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (candidateDraft?.PayingEntityName) {
      setTouched(true);
    }
  }, [candidateDraft?.PayingEntityName]);

  const onChangePayee = e => {
    updateCandidateDraft('PayingEntityName', e);
  };

  return (
    <div>
      <input
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={!candidateDraft.PayingEntityName || false}
        className="bz-input flex-1 mr-80 bg-white border border-gray-500 focus:border-white focus:bg-white placeholder-gray-500 w-64 sm:w-full focus:ring-secondary"
        value={candidateDraft.PayingEntityName || ''}
        placeholder="Paying Entity Name"
        onChange={e => onChangePayee(e)}
        onBlur={() => setTouched(true)}
      />
      {touched && !candidateDraft.PayingEntityName && (
        <div className="flex space-x-2 text-sm bg-orange-100 p-1 w-full mt-2 ">
          <span className="mx-auto text-gray-500">Paying Entity Name is required</span>
        </div>
      )}
    </div>
  );
};

const ExecNameField = ({ candidateDraft, updateCandidateDraft, asterisk }) => {
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (candidateDraft.PayingEntityExecutiveName) {
      setTouched(true);
    }
  }, [candidateDraft.PayingEntityExecutiveName]);

  const onChangeName = e => {
    updateCandidateDraft('PayingEntityExecutiveName', e);
  };

  return (
    <div className="w-full">
      <p className="pb-1 font-medium text-gray-600">Executive Name {asterisk}</p>

      <input
        className="bz-input flex flex-1 w-full bg-white border border-gray-500 focus:border-white focus:bg-white placeholder-gray-500 focus:ring-secondary"
        placeholder="Executive Name"
        value={candidateDraft.PayingEntityExecutiveName || ''}
        onChange={e => onChangeName(e)}
        onBlur={() => setTouched(true)}
      />
      {touched && !candidateDraft.PayingEntityExecutiveName && (
        <div className="flex space-x-2 text-sm bg-orange-100 p-1 w-full mt-2 ">
          <span className="mx-auto text-gray-500">Executive Name is required</span>
        </div>
      )}
    </div>
  );
};

const CandNameField = ({ candidateDraft, updateCandidateDraft }) => {
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (candidateDraft.CandidateName) {
      setTouched(true);
    }
  }, [candidateDraft.CandidateName]);

  const onChangeName = e => {
    const { value } = e.target;

    updateCandidateDraft('CandidateName', value);
  };

  return (
    <div>
      <input
        className="bz-input flex-1 bg-white border border-gray-500 focus:border-white focus:bg-white placeholder-gray-500 focus:ring-secondary"
        placeholder="Candidate Name"
        value={candidateDraft.CandidateName || ''}
        onChange={e => onChangeName(e)}
        onBlur={() => setTouched(true)}
      />
      {touched && !candidateDraft.CandidateName && (
        <div className="flex space-x-2 text-sm bg-orange-100 p-1 w-full mt-2 ">
          <span className="mx-auto text-gray-500">Candidate or Ballot Measure Name is required</span>
        </div>
      )}
    </div>
  );
};
