import { invert, mapValues, pick } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useGAS } from 'src/GlobalAppState/context';
import { download } from '../../../CsvDownloader/CsvDownloader';

const metricKeyMap = {
  CAMPAIGN_DAILY: 'date',
  CAMPAIGN_DAY_OF_WEEK: 'day',
  CAMPAIGN_TIME_OF_DAY: 'hour',
  CAMPAIGN_CREATIVES: 'filename',
  CAMPAIGN_TOP_SITES: 'site',
  CAMPAIGN_DEVICES: 'device',
  CAMPAIGN_AD_NETWORK: 'ad network',
  CAMPAIGN_TOP_CITIES: 'city',
};

const filenameKeyMap = {
  CAMPAIGN_DAILY: 'daily',
  CAMPAIGN_DAY_OF_WEEK: 'by_day_of_week',
  CAMPAIGN_TIME_OF_DAY: 'by_hour',
  CAMPAIGN_CREATIVES: 'by_creative',
  CAMPAIGN_TOP_SITES: 'by_site',
  CAMPAIGN_DEVICES: 'by_device',
  CAMPAIGN_AD_NETWORK: 'by_network',
  CAMPAIGN_TOP_CITIES: 'top_cities',
};

const genFileName = (campaign, metricType) =>
  [(campaign?.name ?? 'campaign').replaceAll(/\W/g, ''), filenameKeyMap[metricType], 'export.csv']
    .filter(Boolean)
    .join('_');

const formatMetric = ({ metricType, metricKey, ...rest }, conversionEvents) => {
  const picked = {
    ...pick(rest, 'conversions', 'clicks', 'views', 'impressions', 'spend'),
    ...mapValues(conversionEvents, v => rest.conversionBreakdown?.[v] ?? 0),
    [metricKeyMap[metricType] || 'value']: metricKey,
  };

  const formatted = mapValues(picked, v => {
    if (v == null) return 'N/A';
    if (typeof v === 'string' && v.includes(',')) return `"${v}"`;

    return v;
  });

  return formatted;
};

const useMetricConversionEvents = metrics =>
  useMemo(() => {
    const events = new Set();

    (metrics || []).forEach(metric => {
      if (metric.conversionBreakdown) {
        Object.keys(metric.conversionBreakdown).forEach(key => events.add(key));
      }
    });

    return Array.from(events);
  }, [metrics]);

const tryParseObject = obj => {
  try {
    return JSON.parse(obj);
  } catch (e) {
    console.error(e);

    return {};
  }
};

const useCampaignTotalConversionEvents = (campaign, metrics) => {
  const { brand, brandTrackingTags } = useGAS();

  const metricConversionEvents = useMetricConversionEvents(metrics);

  const trackingTagsWithDeserializedIds = useMemo(
    () => brandTrackingTags?.map?.(tag => ({ ...tag, dspIds: tryParseObject(tag.dspIds) })) ?? [],
    [brandTrackingTags]
  );

  const findTrackingTagbyDSPID = useCallback(
    dspId => trackingTagsWithDeserializedIds.find(tag => tag.dspIds?.crmDataId === dspId),
    [trackingTagsWithDeserializedIds]
  );

  const findTrackingTagbyID = useCallback(
    id => trackingTagsWithDeserializedIds.find(tag => tag.id === id),
    [trackingTagsWithDeserializedIds]
  );

  const pixelMappings = useMemo(
    () => [
      ...(brand.universalPixel?.UniversalPixelMappings?.ExactMatchMappings || []),
      ...(brand.universalPixel?.UniversalPixelMappings?.WildcardMatchMappings || []),
    ],
    [
      brand.universalPixel?.UniversalPixelMappings?.ExactMatchMappings,
      brand.universalPixel?.UniversalPixelMappings?.WildcardMatchMappings,
    ]
  );

  const findUPixel = useCallback(
    dspId => pixelMappings.find(mapping => mapping.TrackingTagId === dspId),
    [pixelMappings]
  );

  return useMemo(() => {
    const eventMap = new Map();

    metricConversionEvents.forEach(dspId => {
      const trackingTag = findTrackingTagbyDSPID(dspId);

      if (trackingTag) {
        eventMap.set(dspId, trackingTag.name);

        return;
      }

      const uPixel = findUPixel(dspId);

      if (uPixel) {
        eventMap.set(dspId, uPixel.UniversalPixelMappingName);

        return;
      }

      eventMap.set(dspId, dspId);
    });

    (campaign.conversionEvents || []).forEach(conversionEvent => {
      if (conversionEvent.type === 'universal') {
        const uPixel = findUPixel(conversionEvent.id);

        if (uPixel) {
          eventMap.set(conversionEvent.id, uPixel.UniversalPixelMappingName);
        }
      }

      if (conversionEvent.type === 'trackingTag') {
        const trackingTag = findTrackingTagbyID(conversionEvent.id);

        if (trackingTag?.dspIds?.trackingTagId) {
          eventMap.set(trackingTag.dspIds.trackingTagId, trackingTag.name);
        }
      }
    });

    return invert(Object.fromEntries(eventMap.entries()));
  }, [campaign.conversionEvents, findTrackingTagbyDSPID, findTrackingTagbyID, findUPixel, metricConversionEvents]);
};

const useMetricsWithParsedEvents = metrics =>
  useMemo(
    () => metrics.map(metric => ({ ...metric, conversionBreakdown: tryParseObject(metric.conversionBreakdown) })),
    [metrics]
  );

export const DownloadCsvButton = ({
  loading,
  metricType,
  metrics: rawMetrics,
  campaign,
  children = 'Download CSV',
}) => {
  const metrics = useMetricsWithParsedEvents(rawMetrics);
  const filename = useMemo(() => genFileName(campaign, metricType), [campaign, metricType]);
  const conversionEvents = useCampaignTotalConversionEvents(campaign, metrics);

  const onClick = useCallback(() => {
    const filtered = metrics
      .filter(metric => metric.metricType === metricType)
      .map(metric => formatMetric(metric, conversionEvents));

    download(filtered, Object.keys(filtered[0]), filename);
  }, [filename, metricType, metrics, conversionEvents]);

  return (
    <button
      type="button"
      disabled={loading}
      onClick={onClick}
      className="bg-primary text-white rounded px-3 py-1 md:py-3 leading-none"
    >
      {loading ? 'Loading…' : children}
    </button>
  );
};
