import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { API } from 'aws-amplify';
import { BsChevronDown } from 'react-icons/bs';
import { useClickAway, useKey } from 'react-use';
import { refreshToken } from 'src/helpers';
import { LogoIcon } from 'src/components/shared';
import { useGAS } from 'src/GlobalAppState/context';
import { updateUserMutation } from './NavbarV1/authSelectorQueries';
import { Spinner } from '../shared/Spinner';

const useLogoModel = selectedAuth => {
  const { authLevel, brand, team, organization } = useGAS();

  switch (authLevel) {
    case 'static':
      return { name: selectedAuth.staticAuthRole };
    case 'organization':
      return organization;
    case 'team':
      return team;
    case brand:
    default:
      return brand;
  }
};

const SwitchAuthButton = ({ userAuthRole, onClick }) => {
  const { brand, team, organization, staticAuthRole } = userAuthRole;
  const model = brand || team || organization || {};
  const name = staticAuthRole || model?.name || '';

  return (
    <button
      type="button"
      onClick={onClick}
      className="w-full border-b last:border-0 flex items-center p-2 space-x-4 hover:bg-blue-100 focus:bg-blue-200"
      role="menuitem"
    >
      <LogoIcon model={model} />
      <h2 className="text-gray-800 text-md whitespace-nowrap text-left overflow-hidden">{name}</h2>
    </button>
  );
};

const AuthHeaderLoading = () => (
  <div className="flex items-center px-2 space-x-4 text-primary">
    <Spinner variant="inline" className="fill-current" />
    <div className="h-2 w-full bg-white opacity-25 rounded" />
  </div>
);

const AuthHeaderButton = ({ onClick, className, showSingle, children }) => {
  if (showSingle) {
    return <div className={className}>{children}</div>;
  }

  return (
    <button type="button" onClick={onClick} className={className}>
      {children}
    </button>
  );
};

export const AuthSelector = () => {
  const {
    user: { id: userId },
    userAuth: { selectedAuth },
    userAuthRoles: { roles: userAuthRoles = [] },
  } = useGAS();
  const logoModel = useLogoModel(selectedAuth);

  const history = useHistory();
  const menuRef = useRef();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(false);
  const [forceLoading, setForceLoading] = useState(false);

  const close = useCallback(() => setOpen(false), []);

  useKey('Escape', close);
  useClickAway(menuRef, close);

  const { userAuthId } = selectedAuth || {};
  const loading = (selected && selected !== userAuthId) || forceLoading;

  useEffect(() => {
    if (userAuthId) {
      setSelected(userAuthId);
    }
  }, [userAuthId, history]);

  const selectAuth = async userAuthRole => {
    setOpen(false);
    setForceLoading(true);

    setSelected(userAuthRole.id);
    const input = { id: userId, selectedAuth: userAuthRole.id };

    API.graphql({ query: updateUserMutation, variables: { input } })
      .then(refreshToken)
      .then(() => {
        setTimeout(() => {
          setForceLoading(false);
          history.push('/');
        }, 1_000);
      });
  };

  const availableRoles = useMemo(
    () =>
      (userAuthRoles || []).filter(
        role => role.userId === userId && role.authRole !== 'member' && role.id !== selectedAuth?.userAuthId
      ),
    [userAuthRoles, userId, selectedAuth?.userAuthId]
  );

  const showSingle = availableRoles.length < 1;

  if (loading) {
    return <AuthHeaderLoading />;
  }

  const openClass = open ? 'bg-white/10 border-white/25' : 'hover:bg-white/10';
  const buttonClass = showSingle ? '' : openClass;

  return (
    <div ref={menuRef} className="relative">
      <AuthHeaderButton
        onClick={() => setOpen(!open)}
        showSingle={showSingle}
        className={`flex items-center p-2 space-x-3 flex-nowrap border border-transparent rounded w-full justify-center lg:justify-start ${buttonClass}`}
      >
        <LogoIcon model={logoModel} />
        <h2 className="text-white text-md text-left min-w-0 hidden lg:block">{logoModel?.name}</h2>
        {!showSingle && <BsChevronDown className="fill-white hidden lg:block" />}
      </AuthHeaderButton>
      <div
        className={`absolute w-64 border rounded ${!open && 'hidden'} z-30 left-full top-2 bg-white focus:outline-none`}
      >
        <p className="block p-2 text-xs border-b">Other Accounts</p>
        {availableRoles.map(userAuthRole => (
          <SwitchAuthButton
            key={`auth-${userAuthRole.id}`}
            userAuthRole={userAuthRole}
            onClick={() => selectAuth(userAuthRole)}
          />
        ))}
      </div>
    </div>
  );
};
