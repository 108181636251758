import { useGAS } from '../GlobalAppState/context';

export function useAppParams() {
  const gas = useGAS();

  const { accountSettings } = gas.organization || gas || {};

  return {
    ...gas,
    ...gas.appRouteParams,
    ...gas.credits,
    ...gas.routeIds,
    ...gas.userAuth,
    accountSettings,
  };
}
