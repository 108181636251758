import { PiUsersLight } from 'react-icons/pi';
import { BuilderPanel } from '../BuilderPanel';
import {
  AudienceTypeEveryone,
  AudienceTypeThirdParty,
  AudienceTypeExisting,
  AudienceTypeRetargeting,
  AudienceTypeFirstParty,
  AudienceTypeIndex,
} from './panels';
import { AudienceTypeManaged } from './panels/AudienceTypeManaged';

const COMPONENTS = {
  index: AudienceTypeIndex,
  everyone: AudienceTypeEveryone,
  thirdParty: AudienceTypeThirdParty,
  existing: AudienceTypeExisting,
  retargeting: AudienceTypeRetargeting,
  firstParty: AudienceTypeFirstParty,
  managed: AudienceTypeManaged,
};

const AudienceComponent = ({ appParams, campaignDraft, setAppRoute, setCampaignDraft }) => {
  const Component = COMPONENTS[campaignDraft?.audienceType || 'index'];

  if (!Component) return null;

  return (
    <Component
      appParams={appParams}
      campaignDraft={campaignDraft}
      setCampaignDraft={setCampaignDraft}
      setAppRoute={setAppRoute}
    />
  );
};

export const AudienceTargeting = ({ appParams, campaignDraft, setCampaignDraft, setAppRoute }) => (
  <BuilderPanel icon={PiUsersLight} title="Audience">
    <div className="w-full">
      <div className="relative md:w-192 lg:w-full">
        <AudienceComponent
          appParams={appParams}
          campaignDraft={campaignDraft}
          setCampaignDraft={setCampaignDraft}
          setAppRoute={setAppRoute}
        />
      </div>
    </div>
  </BuilderPanel>
);
