import { API } from 'aws-amplify';
import { useEffect, useRef, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { shortNumber } from 'src/helpers';
import { CheckIcon, CloseIcon, EditIcon } from 'src/svgs';
import { TrackingCodeBlock } from '../TrackingCodeBlock';

const fields = /* GraphQL */ `
  {
    id
    archived
    trackingTagType
    createdAt
    updatedAt
    organizationId
    teamId
    brandId
    status
    name
    pixelCode
    pixelAudienceSize
    brandTrackingTagsId
  }
`;

const updateTrackingTag = /* GraphQL */ `
  mutation UpdateTrackingTag($input: UpdateTrackingTagInput!) {
    updateTrackingTag(input: $input) ${fields}
  }
`;

export const TrackingTagRow = ({ tag, onDelete, submitting, activeRow, setActiveRow }) => {
  const [showEdit, setShowEdit] = useState(false);
  const [nameDraft, setNameDraft] = useState(tag.name || '');
  const inputRef = useRef();

  useEffect(() => {
    setNameDraft(tag.name || '');
  }, [tag.name]);

  useEffect(() => {
    if (showEdit && tag.id) {
      if (inputRef.current) inputRef.current.select();
      setActiveRow(tag.id);
    }
  }, [showEdit, setActiveRow, tag.id]);

  const onChange = ({ target }) => setNameDraft(target.value.slice(0, 30));
  const onCancel = () => {
    setNameDraft(tag.name || '');
    setShowEdit(false);

    setActiveRow(false);
  };

  const onSubmit = () => {
    API.graphql({
      query: updateTrackingTag,
      variables: { input: { id: tag.id, name: nameDraft } },
    }).then(() => {
      setShowEdit(false);
      setActiveRow(false);
    });
  };

  const buttonGroup = showEdit ? (
    <div className="flex items-center space-x-2">
      <button
        type="button"
        onClick={onCancel}
        className="bz-btn-icon rounded-full w-6 h-6 border-2 border-gray-300 p-1"
      >
        <CloseIcon className="stroke-current text-gray-300 w-4 h-4" />
      </button>
      <button type="button" onClick={onSubmit} className="bz-btn-icon rounded-full w-6 h-6 border-2 border-primary p-1">
        <CheckIcon className="stroke-current text-primary w-4 h-4" />
      </button>
    </div>
  ) : (
    <button type="button" onClick={() => setShowEdit(true)} className="bz-btn-icon">
      <EditIcon className="stroke-current text-primary w-6 h-6" />
    </button>
  );
  const hideActions = activeRow && activeRow !== tag.id;
  const enableEdit = !submitting && tag.pixelCode;

  return (
    <tr key={tag.id} className="w-full">
      <td className="w-20">
        {!hideActions && <div className="w-full h-full flex items-center justify-center">{buttonGroup}</div>}
      </td>
      <td className="w-52 border-b">
        {!showEdit && <h3 className="truncate text-xl font-medium">{tag.name}</h3>}
        {showEdit && enableEdit && (
          <input
            ref={inputRef}
            type="text"
            className="bz-input bg-blueGray-50 shadow-inner text-xl focus:ring px-2 -ml-2"
            value={nameDraft}
            onChange={onChange}
          />
        )}
      </td>
      <td className="py-2 border-b h-16">
        {tag.pixelCode && <TrackingCodeBlock code={tag.pixelCode} />}
        {!tag.pixelCode && <span className="text-lg capitalize">{tag.status || ''}</span>}
      </td>
      <td className="w-40 border-b">
        <h3 className="text-xl text-center">{shortNumber(tag.pixelAudienceSize)}</h3>
      </td>
      <td className="w-20">
        <div className="w-full h-full flex items-center justify-center">
          {!hideActions && showEdit && (
            <button
              type="button"
              title="delete"
              onClick={() => showEdit && onDelete(tag)}
              className={`bz-btn-icon rounded-full w-8 h-8 ${
                showEdit ? 'text-red-600' : 'text-gray-300 cursor-default'
              }`}
            >
              <FaTrash className="stroke-current  h-6" />
            </button>
          )}
        </div>
      </td>
    </tr>
  );
};
