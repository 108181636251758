export const trackingTagFields = /* GraphQL */ `
id
archived
trackingTagType
createdAt
updatedAt
organizationId
teamId
brandId
dspIds
status
ttdId
name
pixelCode
pixelAudienceSize
pixelUrl
remainingDays
brandTrackingTagsId
piiType
retainPii
region
`;
