import { getSelectedAuthS3Ids } from './getSelectedAuthS3Ids';

export const AuthModels = {
  CAMPAIGNS: 'campaign',
  BRANDS: 'brand',
  TEAMS: 'team',
  ORGANIZATIONS: 'organization',
  ADMIN: 'admin',
};

export const ModelIds = {
  [AuthModels.ORGANIZATIONS]: 'organizationId',
  [AuthModels.TEAMS]: 'teamId',
  [AuthModels.BRANDS]: 'brandId',
  [AuthModels.CAMPAIGNS]: 'campaignId',
};

export const getAuthModel = (selectedAuth, selectedAuthS3Prefix) => {
  const ids = getSelectedAuthS3Ids(selectedAuthS3Prefix);

  if (ids.brandId) return AuthModels.BRANDS;
  if (ids.teamId) return AuthModels.TEAMS;
  if (ids.organizationId) return AuthModels.ORGANIZATIONS;
  if (selectedAuth?.staticAuthRole) return AuthModels.ADMIN;

  return AuthModels.CAMPAIGNS;
};
