import { ColumnCell, ColumnHeader } from './Components';

export const InventoryColumn = {
  key: 'inventory',
  header: () => <ColumnHeader className="w-32">Inventory</ColumnHeader>,
  cell: ({ campaign }) => (
    <ColumnCell className="capitalize">
      {(campaign.inventorySegments || []).length ? 'Custom' : 'Everything'}
    </ColumnCell>
  ),
};
