import { Route, Switch } from 'react-router-dom';
import TeamLayout from './layout';
import TeamIndexPage from './page';
import TeamSettings, { teamSettingsPaths } from './(settings)/component';
import TeamCredits, { teamCreditsPaths } from './(credits)/component';
import NewBrandPage from './new-brand/page';

const teamIndexPath = '/team/:id';
const newBrandPath = '/team/:id/new-brand';

export const teamPaths = [teamIndexPath, newBrandPath, ...teamSettingsPaths, ...teamCreditsPaths];

export default function Brand() {
  return (
    <TeamLayout>
      <Switch>
        <Route exact path={teamIndexPath}>
          <TeamIndexPage />
        </Route>
        <Route exact path={teamSettingsPaths}>
          <TeamSettings />
        </Route>
        <Route exact path={teamCreditsPaths}>
          <TeamCredits />
        </Route>
        <Route exact path={newBrandPath}>
          <NewBrandPage />
        </Route>
      </Switch>
    </TeamLayout>
  );
}
