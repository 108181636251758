export const Blues = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" data-name="Group 8464" viewBox="0 0 30.4 30.2" className={className}>
    <g data-name="Group 7114">
      <path
        fill="#5072dd"
        d="M0 15a.8.8 0 1 1 1.7 0 13.5 13.5 0 0 0 4 9.5 13.5 13.5 0 0 0 4.2 3 13.6 13.6 0 0 0 10.6 0 13.5 13.5 0 0 0 4.3-3 13.5 13.5 0 0 0 4-9.5.8.8 0 1 1 1.6 0A15.2 15.2 0 0 1 26 25.7a15.2 15.2 0 0 1-5 3.3 15.3 15.3 0 0 1-11.8 0 15.2 15.2 0 0 1-4.8-3.3A15.2 15.2 0 0 1 0 15Z"
        data-name="Path 16489"
      />
    </g>
    <path
      fill="#7798ed"
      d="M.9 10a.8.8 0 0 1 0-.2 15.2 15.2 0 0 1 2.7-4.6.8.8 0 1 1 1.3 1 13.5 13.5 0 0 0-2.4 4.2A.8.8 0 0 1 1 10Z"
      data-name="Path 16490"
    />
    <path
      fill="#e3eeff"
      d="M25.4 5.8a.8.8 0 0 1 1.5-.6 15.1 15.1 0 0 1 2.6 4.6.8.8 0 1 1-1.6.6 13.4 13.4 0 0 0-2.3-4.1.8.8 0 0 1-.2-.5Z"
      data-name="Path 16491"
    />
    <path
      fill="#bdd3f9"
      d="M16.9.8a.8.8 0 0 1 1-.8 15.1 15.1 0 0 1 5 1.8.8.8 0 0 1-1 1.5 13.4 13.4 0 0 0-4.3-1.6.8.8 0 0 1-.7-.9Z"
      data-name="Path 16492"
    />
    <path
      fill="#9fbaf7"
      d="M7.2 2.5a.8.8 0 0 1 .4-.7 15.1 15.1 0 0 1 5-1.8.8.8 0 0 1 .3 1.7 13.4 13.4 0 0 0-4.4 1.6.8.8 0 0 1-1.3-.8Z"
      data-name="Path 16493"
    />
    <path
      fill="#2948cc"
      d="M11.7 10.5a5.6 5.6 0 0 1-3.4-1.1.8.8 0 1 1 1-1.4 4 4 0 0 0 3.7.6l4-1.3a5.6 5.6 0 0 1 5.1.9.8.8 0 0 1-1 1.3 4 4 0 0 0-3.6-.6l-4 1.3a5.6 5.6 0 0 1-1.8.3Z"
      data-name="Path 16494"
    />
    <path
      fill="#5072dd"
      d="M10.8 17a6.8 6.8 0 0 1-4-1.3.8.8 0 1 1 1-1.4 5.1 5.1 0 0 0 4.7.8l5-1.6a6.8 6.8 0 0 1 6.2 1 .8.8 0 1 1-1 1.4A5.1 5.1 0 0 0 18 15l-5 1.6a6.8 6.8 0 0 1-2.2.4Z"
      data-name="Path 16495"
    />
    <path
      fill="#7798ed"
      d="M11.7 23.2A5.6 5.6 0 0 1 8.3 22a.8.8 0 1 1 1-1.3 4 4 0 0 0 3.7.6l4-1.3a5.6 5.6 0 0 1 5.1.8.8.8 0 1 1-1 1.4 4 4 0 0 0-3.6-.6l-4 1.3a5.6 5.6 0 0 1-1.8.3Z"
      data-name="Path 16496"
    />
  </svg>
);

export const Autumn = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29.991"
    height="30.207"
    viewBox="0 0 29.991 30.207"
    className={className}
  >
    <path
      id="Path_15045"
      data-name="Path 15045"
      d="M1710.973,5812.557a.843.843,0,0,1-.831-.7,13.474,13.474,0,0,0-2.311-5.571.843.843,0,1,1,1.375-.977,15.163,15.163,0,0,1,2.6,6.267.844.844,0,0,1-.691.973A.927.927,0,0,1,1710.973,5812.557Z"
      transform="translate(-1681.827 -5799.11)"
      fill="#a3a901"
    />
    <path
      id="Path_15046"
      data-name="Path 15046"
      d="M1692.328,5812.561a.874.874,0,0,1-.141-.013.844.844,0,0,1-.692-.972,15.306,15.306,0,0,1,.954-3.325,14.919,14.919,0,0,1,1.634-2.948.844.844,0,1,1,1.374.98,13.254,13.254,0,0,0-1.45,2.615,13.621,13.621,0,0,0-.849,2.958A.846.846,0,0,1,1692.328,5812.561Z"
      transform="translate(-1691.48 -5799.112)"
      fill="#feecd4"
    />
    <path
      id="Path_15047"
      data-name="Path 15047"
      d="M1695.983,5805.434a.844.844,0,0,1-.49-1.531,15.308,15.308,0,0,1,2.964-1.646,15.143,15.143,0,0,1,3.3-.955.936.936,0,0,1,.142-.012.845.845,0,0,1,.14,1.678,13.438,13.438,0,0,0-2.935.846,13.654,13.654,0,0,0-2.636,1.463A.835.835,0,0,1,1695.983,5805.434Z"
      transform="translate(-1689.301 -5801.29)"
      fill="#f89f05"
    />
    <path
      id="Path_15048"
      data-name="Path 15048"
      d="M1708.613,5805.426a.843.843,0,0,1-.489-.156,13.315,13.315,0,0,0-2.615-1.45,13.562,13.562,0,0,0-2.958-.848.844.844,0,0,1,.139-1.676.925.925,0,0,1,.141.011,15.384,15.384,0,0,1,3.325.954,15.016,15.016,0,0,1,2.948,1.636.843.843,0,0,1-.49,1.529Z"
      transform="translate(-1685.303 -5801.288)"
      fill="#e36005"
    />
    <path
      id="Ellipse_194"
      data-name="Ellipse 194"
      d="M1.536-2.5A4.01,4.01,0,0,1,4.39-1.318,4.01,4.01,0,0,1,5.572,1.536,4.036,4.036,0,0,1,1.536,5.572,4.01,4.01,0,0,1-1.318,4.39,4.01,4.01,0,0,1-2.5,1.536,4.01,4.01,0,0,1-1.318-1.318,4.01,4.01,0,0,1,1.536-2.5Zm0,6.385A2.349,2.349,0,1,0-.813,1.536,2.351,2.351,0,0,0,1.536,3.885Z"
      transform="translate(13.458 8.675)"
      fill="#f2d670"
    />
    <path
      id="Path_15051"
      data-name="Path 15051"
      d="M1706.475,5825.317a15.2,15.2,0,0,1-14.982-12.675.844.844,0,0,1,1.664-.278,13.506,13.506,0,0,0,26.635,0,.843.843,0,0,1,1.664.278,15.174,15.174,0,0,1-14.981,12.675Z"
      transform="translate(-1691.481 -5795.11)"
      fill="#d04711"
    />
  </svg>
);

export const Pride = ({ className }) => (
  <svg
    id="Group_8462"
    data-name="Group 8462"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 29.99 30.206"
  >
    <path
      id="Path_16511"
      data-name="Path 16511"
      d="M1710.973,5812.557a.843.843,0,0,1-.831-.7,13.474,13.474,0,0,0-2.311-5.571.843.843,0,1,1,1.375-.977,15.163,15.163,0,0,1,2.6,6.267.844.844,0,0,1-.691.973A.927.927,0,0,1,1710.973,5812.557Z"
      transform="translate(-1681.828 -5799.111)"
      fill="#0000f9"
    />
    <path
      id="Path_16512"
      data-name="Path 16512"
      d="M1692.328,5812.561a.874.874,0,0,1-.141-.013.844.844,0,0,1-.692-.972,15.306,15.306,0,0,1,.954-3.325,14.919,14.919,0,0,1,1.634-2.948.844.844,0,1,1,1.374.98,13.254,13.254,0,0,0-1.45,2.615,13.621,13.621,0,0,0-.849,2.958A.846.846,0,0,1,1692.328,5812.561Z"
      transform="translate(-1691.48 -5799.113)"
      fill="#ffa52c"
    />
    <path
      id="Path_16516"
      data-name="Path 16516"
      d="M1695.983,5805.434a.844.844,0,0,1-.49-1.531,15.308,15.308,0,0,1,2.964-1.646,15.143,15.143,0,0,1,3.3-.955.936.936,0,0,1,.142-.012.845.845,0,0,1,.14,1.678,13.438,13.438,0,0,0-2.935.846,13.654,13.654,0,0,0-2.636,1.463A.835.835,0,0,1,1695.983,5805.434Z"
      transform="translate(-1689.302 -5801.29)"
      fill="#ffff41"
    />
    <path
      id="Path_16517"
      data-name="Path 16517"
      d="M1708.613,5805.426a.843.843,0,0,1-.489-.156,13.315,13.315,0,0,0-2.615-1.45,13.562,13.562,0,0,0-2.958-.848.844.844,0,0,1,.139-1.676.925.925,0,0,1,.141.011,15.384,15.384,0,0,1,3.325.954,15.016,15.016,0,0,1,2.948,1.636.843.843,0,0,1-.49,1.529Z"
      transform="translate(-1685.303 -5801.289)"
      fill="#008018"
    />
    <path
      id="Path_16515"
      data-name="Path 16515"
      d="M1706.475,5825.317a15.2,15.2,0,0,1-14.982-12.675.844.844,0,0,1,1.664-.278,13.506,13.506,0,0,0,26.635,0,.843.843,0,0,1,1.664.278,15.174,15.174,0,0,1-14.981,12.675Z"
      transform="translate(-1691.481 -5795.112)"
      fill="#ff0018"
      opacity="0.8"
    />
    <g id="Group_7121" data-name="Group 7121" transform="translate(6.458 5.248)">
      <path
        id="Path_16524"
        data-name="Path 16524"
        d="M1017.553,856.782a.841.841,0,0,1-.536-.193,5.544,5.544,0,0,1,0-8.553.844.844,0,1,1,1.074,1.3,3.857,3.857,0,0,0,0,5.951.844.844,0,0,1-.537,1.495Z"
        transform="translate(-1009.017 -838.547)"
        fill="#86007d"
      />
      <path
        id="Path_16525"
        data-name="Path 16525"
        d="M1020.835,857.675a5.523,5.523,0,0,1-3.529-1.269.844.844,0,0,1,1.075-1.3,3.857,3.857,0,1,0,0-5.951.844.844,0,0,1-1.075-1.3,5.545,5.545,0,1,1,3.529,9.821Z"
        transform="translate(-1009.308 -838.364)"
        fill="#86007d"
      />
      <path
        id="Path_16526"
        data-name="Path 16526"
        d="M1017.844,856.782a.844.844,0,0,1-.537-1.494,3.857,3.857,0,0,0,0-5.951.844.844,0,1,1,1.075-1.3,5.544,5.544,0,0,1,0,8.553A.84.84,0,0,1,1017.844,856.782Z"
        transform="translate(-1009.308 -838.547)"
        fill="#86007d"
      />
      <path
        id="Path_16527"
        data-name="Path 16527"
        d="M1013.544,857.675a5.546,5.546,0,1,1,3.529-9.821.844.844,0,0,1-1.075,1.3,3.857,3.857,0,1,0,0,5.951.844.844,0,0,1,1.075,1.3,5.522,5.522,0,0,1-3.529,1.269Z"
        transform="translate(-1008 -838.364)"
        fill="#86007d"
      />
      <g id="Group_7120" data-name="Group 7120" transform="translate(4.368 0)">
        <g id="Group_7119" data-name="Group 7119" transform="translate(0)">
          <path
            id="Path_16528"
            data-name="Path 16528"
            d="M1016.691,838.8a2.756,2.756,0,0,0,2.731,0,2.732,2.732,0,0,1,2.043.913,2.9,2.9,0,0,1,.758,1.923,3.184,3.184,0,0,1-.671,1.871,6.59,6.59,0,0,1-1.293,1.3,10.705,10.705,0,0,1-1.239.829c-.19.109-.337.185-.427.23a.974.974,0,0,1-1.074,0c-.09-.045-.237-.121-.427-.23a10.685,10.685,0,0,1-1.239-.829,6.6,6.6,0,0,1-1.293-1.3,3.186,3.186,0,0,1-.671-1.871,2.9,2.9,0,0,1,.758-1.923A2.732,2.732,0,0,1,1016.691,838.8Zm1.366,2.279a.845.845,0,0,1-.624-.275.962.962,0,0,0-.742-.317,1.054,1.054,0,0,0-.794.359,1.216,1.216,0,0,0-.321.79,2.682,2.682,0,0,0,1.206,1.753,1.379,1.379,0,0,0,2.549,0,2.682,2.682,0,0,0,1.206-1.753,1.215,1.215,0,0,0-.321-.79,1.054,1.054,0,0,0-.794-.359.963.963,0,0,0-.742.317A.843.843,0,0,1,1018.056,841.078Z"
            transform="translate(-1013.889 -838.799)"
            fill="#ff0018"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const Valentines = ({ className }) => (
  <svg
    id="Group_8461"
    data-name="Group 8461"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 30.146 30.206"
  >
    <path
      id="Path_16533"
      data-name="Path 16533"
      d="M1710.973,5812.557a.843.843,0,0,1-.831-.7,13.474,13.474,0,0,0-2.311-5.571.843.843,0,1,1,1.375-.977,15.163,15.163,0,0,1,2.6,6.267.844.844,0,0,1-.691.973A.927.927,0,0,1,1710.973,5812.557Z"
      transform="translate(-1681.671 -5799.111)"
      fill="#660202"
    />
    <path
      id="Path_16534"
      data-name="Path 16534"
      d="M1692.328,5812.561a.874.874,0,0,1-.141-.013.844.844,0,0,1-.692-.972,15.306,15.306,0,0,1,.954-3.325,14.919,14.919,0,0,1,1.634-2.948.844.844,0,1,1,1.374.98,13.254,13.254,0,0,0-1.45,2.615,13.621,13.621,0,0,0-.849,2.958A.846.846,0,0,1,1692.328,5812.561Z"
      transform="translate(-1691.324 -5799.113)"
      fill="#fc6c85"
    />
    <path
      id="Path_16536"
      data-name="Path 16536"
      d="M1695.983,5805.434a.844.844,0,0,1-.49-1.531,15.308,15.308,0,0,1,2.964-1.646,15.143,15.143,0,0,1,3.3-.955.936.936,0,0,1,.142-.012.845.845,0,0,1,.14,1.678,13.438,13.438,0,0,0-2.935.846,13.654,13.654,0,0,0-2.636,1.463A.835.835,0,0,1,1695.983,5805.434Z"
      transform="translate(-1689.145 -5801.29)"
      fill="#c21d5a"
    />
    <path
      id="Path_16537"
      data-name="Path 16537"
      d="M1708.613,5805.426a.843.843,0,0,1-.489-.156,13.315,13.315,0,0,0-2.615-1.45,13.562,13.562,0,0,0-2.958-.848.844.844,0,0,1,.139-1.676.925.925,0,0,1,.141.011,15.384,15.384,0,0,1,3.325.954,15.016,15.016,0,0,1,2.948,1.636.843.843,0,0,1-.49,1.529Z"
      transform="translate(-1685.146 -5801.289)"
      fill="#922a46"
    />
    <path
      id="Path_16535"
      data-name="Path 16535"
      d="M1706.475,5825.317a15.2,15.2,0,0,1-14.982-12.675.844.844,0,0,1,1.664-.278,13.506,13.506,0,0,0,26.635,0,.843.843,0,0,1,1.664.278,15.174,15.174,0,0,1-14.981,12.675Z"
      transform="translate(-1691.481 -5795.112)"
      fill="#b32638"
      opacity="0.8"
    />
    <g id="Group_7128" data-name="Group 7128" transform="translate(6.58 7.998)">
      <g id="Group_7127" data-name="Group 7127" transform="translate(0 0)">
        <g id="Group_7126" data-name="Group 7126">
          <g id="Group_7125" data-name="Group 7125">
            <path
              id="Path_16544"
              data-name="Path 16544"
              d="M1293.366,896.586a4.892,4.892,0,0,1,2.334.571.883.883,0,0,0,1.635,0,4.891,4.891,0,0,1,2.334-.571,5.1,5.1,0,0,1,3.919,1.863,6.1,6.1,0,0,1,1.448,3.888,7.761,7.761,0,0,1-1.1,3.87,12.3,12.3,0,0,1-2.449,2.977,14.633,14.633,0,0,1-2.728,1.958,3.642,3.642,0,0,1-4.487,0,14.482,14.482,0,0,1-2.728-1.958,12.292,12.292,0,0,1-2.449-2.977,7.759,7.759,0,0,1-1.1-3.87,6.1,6.1,0,0,1,1.447-3.888A5.1,5.1,0,0,1,1293.366,896.586Zm3.151,3.145h0a.844.844,0,0,1-.641-.3,3.224,3.224,0,0,0-2.51-1.163,3.265,3.265,0,0,0-1.472.345,3.7,3.7,0,0,0-1.162.924,4.467,4.467,0,0,0-.772,1.323,4.266,4.266,0,0,0-.273,1.471,6.1,6.1,0,0,0,.875,3.032,10.624,10.624,0,0,0,2.117,2.565,13.03,13.03,0,0,0,2.406,1.729,2.1,2.1,0,0,0,2.867,0,13.037,13.037,0,0,0,2.406-1.729,10.628,10.628,0,0,0,2.117-2.565,6.1,6.1,0,0,0,.875-3.032,4.273,4.273,0,0,0-.273-1.471,4.47,4.47,0,0,0-.772-1.323,3.7,3.7,0,0,0-1.162-.924,3.263,3.263,0,0,0-1.471-.345,3.225,3.225,0,0,0-2.51,1.163A.844.844,0,0,1,1296.517,899.731Z"
              transform="translate(-1288 -896.586)"
              fill="#b32638"
            />
          </g>
          <path
            id="Line_194"
            data-name="Line 194"
            d="M-1.656,1.319A.844.844,0,0,1-2.5.475V-1.656A.844.844,0,0,1-1.656-2.5a.844.844,0,0,1,.844.844V.475A.844.844,0,0,1-1.656,1.319Z"
            transform="translate(10.174 8.895)"
            fill="#b32638"
          />
          <path
            id="Ellipse_304"
            data-name="Ellipse 304"
            d="M-1.017-2.5A1.485,1.485,0,0,1,.466-1.017,1.485,1.485,0,0,1-1.017.466,1.485,1.485,0,0,1-2.5-1.017,1.485,1.485,0,0,1-1.017-2.5Z"
            transform="translate(9.534 7.616)"
            fill="#b32638"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const Snowfall = ({ className }) => (
  <svg
    id="Group_8460"
    data-name="Group 8460"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 30.146 30.206"
  >
    <path
      id="Path_16550"
      data-name="Path 16550"
      d="M1710.973,5812.557a.843.843,0,0,1-.831-.7,13.474,13.474,0,0,0-2.311-5.571.843.843,0,1,1,1.375-.977,15.163,15.163,0,0,1,2.6,6.267.844.844,0,0,1-.691.973A.927.927,0,0,1,1710.973,5812.557Z"
      transform="translate(-1681.671 -5799.111)"
      fill="#d9d8df"
    />
    <path
      id="Path_16551"
      data-name="Path 16551"
      d="M1692.328,5812.561a.874.874,0,0,1-.141-.013.844.844,0,0,1-.692-.972,15.306,15.306,0,0,1,.954-3.325,14.919,14.919,0,0,1,1.634-2.948.844.844,0,1,1,1.374.98,13.254,13.254,0,0,0-1.45,2.615,13.621,13.621,0,0,0-.849,2.958A.846.846,0,0,1,1692.328,5812.561Z"
      transform="translate(-1691.324 -5799.113)"
      fill="#e4c2c7"
    />
    <path
      id="Path_16552"
      data-name="Path 16552"
      d="M1695.983,5805.434a.844.844,0,0,1-.49-1.531,15.308,15.308,0,0,1,2.964-1.646,15.143,15.143,0,0,1,3.3-.955.936.936,0,0,1,.142-.012.845.845,0,0,1,.14,1.678,13.438,13.438,0,0,0-2.935.846,13.654,13.654,0,0,0-2.636,1.463A.835.835,0,0,1,1695.983,5805.434Z"
      transform="translate(-1689.145 -5801.29)"
      fill="#b2a7bb"
    />
    <path
      id="Path_16553"
      data-name="Path 16553"
      d="M1708.613,5805.426a.843.843,0,0,1-.489-.156,13.315,13.315,0,0,0-2.615-1.45,13.562,13.562,0,0,0-2.958-.848.844.844,0,0,1,.139-1.676.925.925,0,0,1,.141.011,15.384,15.384,0,0,1,3.325.954,15.016,15.016,0,0,1,2.948,1.636.843.843,0,0,1-.49,1.529Z"
      transform="translate(-1685.146 -5801.289)"
      fill="#f7d7d4"
    />
    <path
      id="Path_16549"
      data-name="Path 16549"
      d="M1706.475,5825.317a15.2,15.2,0,0,1-14.982-12.675.844.844,0,0,1,1.664-.278,13.506,13.506,0,0,0,26.635,0,.843.843,0,0,1,1.664.278,15.174,15.174,0,0,1-14.981,12.675Z"
      transform="translate(-1691.481 -5795.112)"
      fill="#b2a7bb"
      opacity="0.8"
    />
    <g id="Group_7139" data-name="Group 7139" transform="translate(5.993 5.187)">
      <g id="Group_7138" data-name="Group 7138" transform="translate(0 0)">
        <path
          id="Line_195"
          data-name="Line 195"
          d="M-1.656,17.5A.844.844,0,0,1-2.5,16.66V-1.656A.844.844,0,0,1-1.656-2.5a.844.844,0,0,1,.844.844V16.66A.844.844,0,0,1-1.656,17.5Z"
          transform="translate(10.657 2.5)"
          fill="#b0c2d7"
        />
        <g id="Group_7132" data-name="Group 7132" transform="translate(5.946 1.431)">
          <path
            id="Path_16560"
            data-name="Path 16560"
            d="M1521.886,8909.428a.846.846,0,0,1-.6-.245l-2.21-2.21a.844.844,0,1,1,1.193-1.2l1.614,1.615,1.614-1.615a.844.844,0,1,1,1.193,1.2l-2.21,2.21A.846.846,0,0,1,1521.886,8909.428Z"
            transform="translate(-1518.832 -8905.531)"
            fill="#b0c2d7"
          />
        </g>
        <path
          id="Line_196"
          data-name="Line 196"
          d="M-1.656,8.606a.843.843,0,0,1-.731-.422.844.844,0,0,1,.309-1.152L14.235-2.387a.844.844,0,0,1,1.152.309.844.844,0,0,1-.309,1.152L-1.235,8.493A.84.84,0,0,1-1.656,8.606Z"
          transform="translate(2.5 6.949)"
          fill="#b0c2d7"
        />
        <g id="Group_7133" data-name="Group 7133" transform="translate(12.934 3.38)">
          <path
            id="Path_16561"
            data-name="Path 16561"
            d="M1529.549,8912.957a.849.849,0,0,1-.219-.029l-3.019-.807a.844.844,0,0,1-.6-1.033l.809-3.021a.844.844,0,0,1,1.63.436l-.591,2.2,2.2.591a.844.844,0,0,1-.218,1.659Z"
            transform="translate(-1525.686 -8907.441)"
            fill="#b0c2d7"
          />
        </g>
        <g id="Group_7134" data-name="Group 7134" transform="translate(0.36 11.106)">
          <path
            id="Path_16562"
            data-name="Path 16562"
            d="M1516.407,8920.539a.892.892,0,0,1-.219-.029.843.843,0,0,1-.6-1.032l.591-2.207-2.2-.589a.844.844,0,0,1,.437-1.631l3.02.809a.852.852,0,0,1,.512.394.843.843,0,0,1,.084.641l-.809,3.021A.844.844,0,0,1,1516.407,8920.539Z"
            transform="translate(-1513.352 -8915.021)"
            fill="#b0c2d7"
          />
        </g>
        <path
          id="Line_197"
          data-name="Line 197"
          d="M14.656,8.606a.84.84,0,0,1-.421-.113L-2.078-.926a.844.844,0,0,1-.309-1.152.844.844,0,0,1,1.152-.309L15.079,7.031a.844.844,0,0,1-.423,1.574Z"
          transform="translate(2.5 6.949)"
          fill="#b0c2d7"
        />
        <g id="Group_7135" data-name="Group 7135" transform="translate(0.36 3.38)">
          <path
            id="Path_16563"
            data-name="Path 16563"
            d="M1514.2,8912.957a.844.844,0,0,1-.218-1.659l2.2-.592-.591-2.206a.844.844,0,0,1,.6-1.032.89.89,0,0,1,.219-.028.843.843,0,0,1,.814.625l.809,3.022a.845.845,0,0,1-.6,1.032l-3.02.808A.85.85,0,0,1,1514.2,8912.957Z"
            transform="translate(-1513.352 -8907.439)"
            fill="#b0c2d7"
          />
        </g>
        <g id="Group_7136" data-name="Group 7136" transform="translate(12.934 11.107)">
          <path
            id="Path_16564"
            data-name="Path 16564"
            d="M1527.337,8920.54a.845.845,0,0,1-.814-.626l-.809-3.02a.846.846,0,0,1,.6-1.035l3.02-.808a.844.844,0,1,1,.436,1.63l-2.2.59.591,2.207a.843.843,0,0,1-.6,1.032A.9.9,0,0,1,1527.337,8920.54Z"
            transform="translate(-1525.686 -8915.023)"
            fill="#b0c2d7"
          />
        </g>
        <g id="Group_7137" data-name="Group 7137" transform="translate(5.946 14.674)">
          <path
            id="Path_16565"
            data-name="Path 16565"
            d="M1524.1,8922.417a.841.841,0,0,1-.6-.248l-1.614-1.613-1.614,1.613a.844.844,0,0,1-1.193-1.193l2.21-2.208a.839.839,0,0,1,1.193,0l2.21,2.208a.845.845,0,0,1-.6,1.441Z"
            transform="translate(-1518.832 -8918.519)"
            fill="#b0c2d7"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const Sunrise = ({ className }) => (
  <svg
    id="Group_8459"
    data-name="Group 8459"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 30.427 30.197"
  >
    <g id="Group_7148" data-name="Group 7148" transform="translate(30.427 14.14) rotate(90)">
      <path
        id="Path_16489"
        data-name="Path 16489"
        d="M.844,30.427a.844.844,0,1,1,0-1.687,13.481,13.481,0,0,0,9.564-3.962,13.481,13.481,0,0,0,2.9-4.3,13.576,13.576,0,0,0,0-10.53,13.48,13.48,0,0,0-2.9-4.3A13.481,13.481,0,0,0,.844,1.687.844.844,0,1,1,.844,0,15.168,15.168,0,0,1,11.6,4.456a15.16,15.16,0,0,1,3.26,4.835,15.263,15.263,0,0,1,0,11.845,15.161,15.161,0,0,1-3.26,4.836A15.168,15.168,0,0,1,.844,30.427Z"
        transform="translate(0 0)"
        fill="#b5728e"
      />
    </g>
    <path
      id="Path_16600"
      data-name="Path 16600"
      d="M5.168,4.174a.842.842,0,0,1-.289-.051A15.185,15.185,0,0,1,.3,1.49.844.844,0,1,1,1.386.2a13.5,13.5,0,0,0,4.071,2.34.844.844,0,0,1-.289,1.636Z"
      transform="translate(5.054 4.907) rotate(90)"
      fill="#fff2bd"
    />
    <path
      id="Path_16601"
      data-name="Path 16601"
      d="M.844,4.174A.844.844,0,0,1,.3,2.684,15.118,15.118,0,0,1,4.879.051a.844.844,0,1,1,.577,1.586A13.436,13.436,0,0,0,1.386,3.976.84.84,0,0,1,.844,4.174Z"
      transform="translate(29.547 4.907) rotate(90)"
      fill="#da7f7d"
    />
    <path
      id="Path_16602"
      data-name="Path 16602"
      d="M.845,6.373a.844.844,0,0,1-.832-.99A15.1,15.1,0,0,1,1.817.422a.844.844,0,0,1,1.461.845,13.422,13.422,0,0,0-1.6,4.409A.844.844,0,0,1,.845,6.373Z"
      transform="translate(23.237 0) rotate(90)"
      fill="#ebb58a"
    />
    <path
      id="Path_16603"
      data-name="Path 16603"
      d="M2.548,6.374a.843.843,0,0,1-.731-.422A15.108,15.108,0,0,1,.013.99.844.844,0,0,1,1.675.7a13.426,13.426,0,0,0,1.6,4.41.844.844,0,0,1-.73,1.266Z"
      transform="translate(13.566 0) rotate(90)"
      fill="#f4d797"
    />
    <g id="Group_7152" data-name="Group 7152" transform="translate(5.967 6.335)">
      <g id="Group_7151" data-name="Group 7151" transform="translate(0 0)">
        <path
          id="Line_198"
          data-name="Line 198"
          d="M-1.656.875A.844.844,0,0,1-2.5.031V-1.656A.844.844,0,0,1-1.656-2.5a.844.844,0,0,1,.844.844V.031A.844.844,0,0,1-1.656.875Z"
          transform="translate(10.936 2.5)"
          fill="#f4d797"
        />
        <path
          id="Line_199"
          data-name="Line 199"
          d="M.031-.813H-1.656A.844.844,0,0,1-2.5-1.656.844.844,0,0,1-1.656-2.5H.031a.844.844,0,0,1,.844.844A.844.844,0,0,1,.031-.813Z"
          transform="translate(2.5 10.599)"
          fill="#f4d797"
        />
        <path
          id="Line_200"
          data-name="Line 200"
          d="M-.644.2a.841.841,0,0,1-.6-.247L-2.253-1.06a.844.844,0,0,1,0-1.193.844.844,0,0,1,1.193,0L-.047-1.241a.844.844,0,0,1,0,1.193A.841.841,0,0,1-.644.2Z"
          transform="translate(4.862 4.862)"
          fill="#f4d797"
        />
        <path
          id="Line_201"
          data-name="Line 201"
          d="M-1.656.2a.841.841,0,0,1-.6-.247.844.844,0,0,1,0-1.193l1.012-1.012a.844.844,0,0,1,1.193,0,.844.844,0,0,1,0,1.193L-1.06-.047A.841.841,0,0,1-1.656.2Z"
          transform="translate(15.661 4.862)"
          fill="#f4d797"
        />
        <path
          id="Line_202"
          data-name="Line 202"
          d="M.031-.813H-1.656A.844.844,0,0,1-2.5-1.656.844.844,0,0,1-1.656-2.5H.031a.844.844,0,0,1,.844.844A.844.844,0,0,1,.031-.813Z"
          transform="translate(17.348 10.599)"
          fill="#f4d797"
        />
        <g id="Group_7150" data-name="Group 7150" transform="translate(4.124 4.037)">
          <path
            id="Path_16621"
            data-name="Path 16621"
            d="M1189.2,8858.971a.844.844,0,0,1-.844-.844,3.327,3.327,0,0,0-3.314-3.332H1185a3.327,3.327,0,0,0-3.314,3.332.844.844,0,1,1-1.687,0,5.015,5.015,0,0,1,5-5.021h.037a5.015,5.015,0,0,1,5,5.021A.844.844,0,0,1,1189.2,8858.971Z"
            transform="translate(-1180 -8853.106)"
            fill="#f4d797"
          />
        </g>
        <path
          id="Line_203"
          data-name="Line 203"
          d="M11.5-.813H-1.656A.844.844,0,0,1-2.5-1.656.844.844,0,0,1-1.656-2.5H11.5a.844.844,0,0,1,.844.844A.844.844,0,0,1,11.5-.813Z"
          transform="translate(4.187 13.974)"
          fill="#776e99"
        />
        <path
          id="Line_204"
          data-name="Line 204"
          d="M3.406-.813H-1.656A.844.844,0,0,1-2.5-1.656.844.844,0,0,1-1.656-2.5H3.406a.844.844,0,0,1,.844.844A.844.844,0,0,1,3.406-.813Z"
          transform="translate(8.237 17.348)"
          fill="#776e99"
        />
      </g>
    </g>
  </svg>
);

export const Aqua = ({ className }) => (
  <svg
    id="Group_8458"
    data-name="Group 8458"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 29.99 30.206"
  >
    <path
      id="Path_16582"
      data-name="Path 16582"
      d="M1710.973,5812.557a.843.843,0,0,1-.831-.7,13.474,13.474,0,0,0-2.311-5.571.843.843,0,1,1,1.375-.977,15.163,15.163,0,0,1,2.6,6.267.844.844,0,0,1-.691.973A.927.927,0,0,1,1710.973,5812.557Z"
      transform="translate(-1681.827 -5799.111)"
      fill="#3ce5cb"
    />
    <path
      id="Path_16586"
      data-name="Path 16586"
      d="M1692.328,5812.561a.874.874,0,0,1-.141-.013.844.844,0,0,1-.692-.972,15.306,15.306,0,0,1,.954-3.325,14.919,14.919,0,0,1,1.634-2.948.844.844,0,1,1,1.374.98,13.254,13.254,0,0,0-1.45,2.615,13.621,13.621,0,0,0-.849,2.958A.846.846,0,0,1,1692.328,5812.561Z"
      transform="translate(-1691.48 -5799.113)"
      fill="#0f8ec0"
    />
    <path
      id="Path_16607"
      data-name="Path 16607"
      d="M1695.983,5805.434a.844.844,0,0,1-.49-1.531,15.308,15.308,0,0,1,2.964-1.646,15.143,15.143,0,0,1,3.3-.955.936.936,0,0,1,.142-.012.845.845,0,0,1,.14,1.678,13.438,13.438,0,0,0-2.935.846,13.654,13.654,0,0,0-2.636,1.463A.835.835,0,0,1,1695.983,5805.434Z"
      transform="translate(-1689.301 -5801.29)"
      fill="#1eb3c4"
    />
    <path
      id="Path_16611"
      data-name="Path 16611"
      d="M1708.613,5805.426a.843.843,0,0,1-.489-.156,13.315,13.315,0,0,0-2.615-1.45,13.562,13.562,0,0,0-2.958-.848.844.844,0,0,1,.139-1.676.925.925,0,0,1,.141.011,15.384,15.384,0,0,1,3.325.954,15.016,15.016,0,0,1,2.948,1.636.843.843,0,0,1-.49,1.529Z"
      transform="translate(-1685.303 -5801.289)"
      fill="#2dccde"
    />
    <path
      id="Path_16592"
      data-name="Path 16592"
      d="M1706.475,5825.317a15.2,15.2,0,0,1-14.982-12.675.844.844,0,0,1,1.664-.278,13.506,13.506,0,0,0,26.635,0,.843.843,0,0,1,1.664.278,15.174,15.174,0,0,1-14.981,12.675Z"
      transform="translate(-1691.481 -5795.112)"
      fill="#0771b8"
    />
    <g id="Group_7155" data-name="Group 7155" transform="translate(6.638 6.178)">
      <g id="Group_7154" data-name="Group 7154" transform="translate(0 0)">
        <path
          id="Path_16628"
          data-name="Path 16628"
          d="M1973.843,5786.1a.844.844,0,0,1-.2-1.662,7.235,7.235,0,0,0,2.694-1.2,1.892,1.892,0,0,0,.839-1.341c0-.7-.75-1.459-2.006-2.023a.843.843,0,1,1,.691-1.539,6.345,6.345,0,0,1,2.1,1.437,3.149,3.149,0,0,1,.9,2.125,3.423,3.423,0,0,1-1.481,2.666,8.889,8.889,0,0,1-3.332,1.514A.817.817,0,0,1,1973.843,5786.1Z"
          transform="translate(-1962.149 -5769.789)"
          fill="#13d8ec"
        />
        <path
          id="Path_16629"
          data-name="Path 16629"
          d="M1965.018,5786.1a.845.845,0,0,1-.2-.026,8.887,8.887,0,0,1-3.332-1.515A3.42,3.42,0,0,1,1960,5781.9a3.142,3.142,0,0,1,.9-2.125,6.314,6.314,0,0,1,2.1-1.438.844.844,0,0,1,.69,1.54c-1.255.563-2.005,1.319-2.005,2.024a1.89,1.89,0,0,0,.838,1.34,7.237,7.237,0,0,0,2.7,1.2.844.844,0,0,1-.2,1.663Z"
          transform="translate(-1960 -5769.789)"
          fill="#13d8ec"
        />
        <path
          id="Path_16630"
          data-name="Path 16630"
          d="M1969.182,5768.11a1.666,1.666,0,0,1,1.343.673l2,2.676a4.157,4.157,0,0,1,.829,2.211,4.231,4.231,0,0,1-1.91,3.814,4.214,4.214,0,0,1-4.526,0,4.175,4.175,0,0,1-1.081-6.025l2-2.676A1.666,1.666,0,0,1,1969.182,5768.11Zm0,8.346a2.448,2.448,0,0,0,1.346-.388,2.546,2.546,0,0,0,1.142-2.284,2.439,2.439,0,0,0-.5-1.315l-1.992-2.665-1.993,2.665a2.446,2.446,0,0,0-.5,1.315,2.548,2.548,0,0,0,1.143,2.284A2.45,2.45,0,0,0,1969.182,5776.456Z"
          transform="translate(-1960.826 -5768.11)"
          fill="#0771b8"
        />
        <path
          id="Path_16631"
          data-name="Path 16631"
          d="M1969.24,5784.63a5.968,5.968,0,0,1-3.563-1.008.844.844,0,0,1,1.029-1.338,5.2,5.2,0,0,0,5.067,0,.844.844,0,0,1,1.03,1.338A5.969,5.969,0,0,1,1969.24,5784.63Z"
          transform="translate(-1960.885 -5770.424)"
          fill="#13d8ec"
        />
      </g>
    </g>
  </svg>
);

export const Hallows = ({ className }) => (
  <svg
    id="Group_8457"
    data-name="Group 8457"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 29.99 30.206"
  >
    <path
      id="Path_16583"
      data-name="Path 16583"
      d="M1710.973,5812.557a.843.843,0,0,1-.831-.7,13.474,13.474,0,0,0-2.311-5.571.843.843,0,1,1,1.375-.977,15.163,15.163,0,0,1,2.6,6.267.844.844,0,0,1-.691.973A.927.927,0,0,1,1710.973,5812.557Z"
      transform="translate(-1681.828 -5799.111)"
      fill="#e5552b"
    />
    <path
      id="Path_16587"
      data-name="Path 16587"
      d="M1692.328,5812.561a.874.874,0,0,1-.141-.013.844.844,0,0,1-.692-.972,15.306,15.306,0,0,1,.954-3.325,14.919,14.919,0,0,1,1.634-2.948.844.844,0,1,1,1.374.98,13.254,13.254,0,0,0-1.45,2.615,13.621,13.621,0,0,0-.849,2.958A.846.846,0,0,1,1692.328,5812.561Z"
      transform="translate(-1691.48 -5799.113)"
      fill="#539417"
    />
    <path
      id="Path_16608"
      data-name="Path 16608"
      d="M1695.983,5805.434a.844.844,0,0,1-.49-1.531,15.308,15.308,0,0,1,2.964-1.646,15.143,15.143,0,0,1,3.3-.955.936.936,0,0,1,.142-.012.845.845,0,0,1,.14,1.678,13.438,13.438,0,0,0-2.935.846,13.654,13.654,0,0,0-2.636,1.463A.835.835,0,0,1,1695.983,5805.434Z"
      transform="translate(-1689.302 -5801.29)"
      fill="#83ae3d"
    />
    <path
      id="Path_16612"
      data-name="Path 16612"
      d="M1708.613,5805.426a.843.843,0,0,1-.489-.156,13.315,13.315,0,0,0-2.615-1.45,13.562,13.562,0,0,0-2.958-.848.844.844,0,0,1,.139-1.676.925.925,0,0,1,.141.011,15.384,15.384,0,0,1,3.325.954,15.016,15.016,0,0,1,2.948,1.636.843.843,0,0,1-.49,1.529Z"
      transform="translate(-1685.303 -5801.289)"
      fill="#61278d"
    />
    <path
      id="Path_16593"
      data-name="Path 16593"
      d="M1706.475,5825.317a15.2,15.2,0,0,1-14.982-12.675.844.844,0,0,1,1.664-.278,13.506,13.506,0,0,0,26.635,0,.843.843,0,0,1,1.664.278,15.174,15.174,0,0,1-14.981,12.675Z"
      transform="translate(-1691.481 -5795.112)"
      fill="#3e1852"
      opacity="0.8"
    />
    <g id="Group_7158" data-name="Group 7158" transform="translate(6.668 6.853)">
      <g id="Group_7157" data-name="Group 7157">
        <path
          id="Path_16640"
          data-name="Path 16640"
          d="M1408.218,12436.26a.843.843,0,0,1-.844-.844V12435a2.9,2.9,0,0,1,2.9-2.9h.465a.844.844,0,1,1,0,1.688h-.465a1.21,1.21,0,0,0-1.21,1.209v.412A.843.843,0,0,1,1408.218,12436.26Z"
          transform="translate(-1399.981 -12432.107)"
          fill="#83ae3d"
        />
        <path
          id="Path_16638"
          data-name="Path 16638"
          d="M1403.736,12449.119a4.319,4.319,0,0,1-2.181-.6,5.567,5.567,0,0,1-1.695-1.553,8.674,8.674,0,0,1,0-9.709,5.521,5.521,0,0,1,1.695-1.551,4.319,4.319,0,0,1,2.181-.6,4.117,4.117,0,0,1,1.885.453.843.843,0,1,1-.763,1.5,2.47,2.47,0,0,0-1.122-.27,3.178,3.178,0,0,0-2.515,1.459,5.776,5.776,0,0,0-.842,1.711,7.435,7.435,0,0,0,0,4.295,5.777,5.777,0,0,0,.842,1.709,3.171,3.171,0,0,0,2.515,1.459,2.452,2.452,0,0,0,1.122-.268.843.843,0,1,1,.763,1.5A4.139,4.139,0,0,1,1403.736,12449.119Z"
          transform="translate(-1398.374 -12432.646)"
          fill="#eb7721"
        />
        <path
          id="Path_16639"
          data-name="Path 16639"
          d="M1411.391,12449.119a4.14,4.14,0,0,1-1.885-.453.843.843,0,1,1,.764-1.5,2.452,2.452,0,0,0,1.121.268,3.17,3.17,0,0,0,2.515-1.459,5.765,5.765,0,0,0,.842-1.709,7.432,7.432,0,0,0,0-4.295,5.764,5.764,0,0,0-.842-1.711,3.178,3.178,0,0,0-2.515-1.459,2.468,2.468,0,0,0-1.121.27.843.843,0,1,1-.764-1.5,4.117,4.117,0,0,1,1.885-.453,4.32,4.32,0,0,1,2.181.6,5.526,5.526,0,0,1,1.7,1.551,8.674,8.674,0,0,1,0,9.709,5.571,5.571,0,0,1-1.695,1.553A4.318,4.318,0,0,1,1411.391,12449.119Z"
          transform="translate(-1400.279 -12432.646)"
          fill="#eb7721"
        />
        <path
          id="Ellipse_305"
          data-name="Ellipse 305"
          d="M2.469-2.5a3.806,3.806,0,0,1,2.056.615A5.32,5.32,0,0,1,6.088-.321,8.745,8.745,0,0,1,7.439,4.5,8.745,8.745,0,0,1,6.088,9.331,5.32,5.32,0,0,1,4.526,10.9a3.806,3.806,0,0,1-2.056.615A3.806,3.806,0,0,1,.413,10.9,5.32,5.32,0,0,1-1.149,9.331,8.745,8.745,0,0,1-2.5,4.5,8.745,8.745,0,0,1-1.149-.321,5.32,5.32,0,0,1,.413-1.885,3.806,3.806,0,0,1,2.469-2.5Zm0,12.322a2.779,2.779,0,0,0,2.216-1.43,6.036,6.036,0,0,0,.773-1.719A7.956,7.956,0,0,0,5.751,4.5a7.956,7.956,0,0,0-.292-2.168A6.036,6.036,0,0,0,4.686.618,2.779,2.779,0,0,0,2.469-.813,2.779,2.779,0,0,0,.253.618,6.036,6.036,0,0,0-.52,2.336,7.956,7.956,0,0,0-.813,4.5,7.956,7.956,0,0,0-.52,6.673,6.036,6.036,0,0,0,.253,8.392,2.779,2.779,0,0,0,2.469,9.822Z"
          transform="translate(5.767 4.964)"
          fill="#eb7721"
        />
      </g>
    </g>
  </svg>
);

export const Neon = ({ className }) => (
  <svg
    id="Group_8456"
    data-name="Group 8456"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    viewBox="0 0 30.146 30.206"
  >
    <path
      id="Path_16584"
      data-name="Path 16584"
      d="M1710.973,5812.557a.843.843,0,0,1-.831-.7,13.474,13.474,0,0,0-2.311-5.571.843.843,0,1,1,1.375-.977,15.163,15.163,0,0,1,2.6,6.267.844.844,0,0,1-.691.973A.927.927,0,0,1,1710.973,5812.557Z"
      transform="translate(-1681.671 -5799.111)"
      fill="#52fb5b"
    />
    <path
      id="Path_16588"
      data-name="Path 16588"
      d="M1692.328,5812.561a.874.874,0,0,1-.141-.013.844.844,0,0,1-.692-.972,15.306,15.306,0,0,1,.954-3.325,14.919,14.919,0,0,1,1.634-2.948.844.844,0,1,1,1.374.98,13.254,13.254,0,0,0-1.45,2.615,13.621,13.621,0,0,0-.849,2.958A.846.846,0,0,1,1692.328,5812.561Z"
      transform="translate(-1691.324 -5799.113)"
      fill="#52fb5b"
    />
    <path
      id="Path_16609"
      data-name="Path 16609"
      d="M1695.983,5805.434a.844.844,0,0,1-.49-1.531,15.308,15.308,0,0,1,2.964-1.646,15.143,15.143,0,0,1,3.3-.955.936.936,0,0,1,.142-.012.845.845,0,0,1,.14,1.678,13.438,13.438,0,0,0-2.935.846,13.654,13.654,0,0,0-2.636,1.463A.835.835,0,0,1,1695.983,5805.434Z"
      transform="translate(-1689.145 -5801.29)"
      fill="#faff19"
    />
    <path
      id="Path_16613"
      data-name="Path 16613"
      d="M1708.613,5805.426a.843.843,0,0,1-.489-.156,13.315,13.315,0,0,0-2.615-1.45,13.562,13.562,0,0,0-2.958-.848.844.844,0,0,1,.139-1.676.925.925,0,0,1,.141.011,15.384,15.384,0,0,1,3.325.954,15.016,15.016,0,0,1,2.948,1.636.843.843,0,0,1-.49,1.529Z"
      transform="translate(-1685.146 -5801.289)"
      fill="#48fffe"
    />
    <path
      id="Path_16594"
      data-name="Path 16594"
      d="M1706.475,5825.317a15.2,15.2,0,0,1-14.982-12.675.844.844,0,0,1,1.664-.278,13.506,13.506,0,0,0,26.635,0,.843.843,0,0,1,1.664.278,15.174,15.174,0,0,1-14.981,12.675Z"
      transform="translate(-1691.481 -5795.112)"
      fill="#220efa"
      opacity="0.8"
    />
    <g id="Group_7161" data-name="Group 7161" transform="translate(7.457 7.676)">
      <g id="Group_7160" data-name="Group 7160" transform="translate(0 0)">
        <path
          id="Path_16647"
          data-name="Path 16647"
          d="M2250.1,5282.739a.834.834,0,0,1-.509-.171.843.843,0,0,1-.164-1.182,3.615,3.615,0,0,0,.548-3.323l-1.23-3.688a5.3,5.3,0,0,1,.8-4.875.843.843,0,1,1,1.345,1.018,3.612,3.612,0,0,0-.548,3.324l1.23,3.687a5.3,5.3,0,0,1-.8,4.876A.841.841,0,0,1,2250.1,5282.739Z"
          transform="translate(-2248.473 -5267.68)"
          fill="#e92b99"
        />
        <path
          id="Path_16648"
          data-name="Path 16648"
          d="M2254.111,5284.712a.844.844,0,0,1-.672-1.354,4.727,4.727,0,0,0,.717-4.349l-1.537-4.607a6.413,6.413,0,0,1,.972-5.9.844.844,0,1,1,1.345,1.018,4.728,4.728,0,0,0-.716,4.349l1.536,4.607a6.415,6.415,0,0,1-.972,5.9A.842.842,0,0,1,2254.111,5284.712Z"
          transform="translate(-2246.618 -5268.167)"
          fill="#9929fb"
        />
        <path
          id="Path_16649"
          data-name="Path 16649"
          d="M2258.016,5282.739a.833.833,0,0,1-.508-.171.844.844,0,0,1-.164-1.182,3.614,3.614,0,0,0,.548-3.323l-1.23-3.688a5.3,5.3,0,0,1,.8-4.875.843.843,0,1,1,1.344,1.018,3.616,3.616,0,0,0-.548,3.324l1.23,3.686a5.3,5.3,0,0,1-.8,4.876A.841.841,0,0,1,2258.016,5282.739Z"
          transform="translate(-2244.626 -5267.68)"
          fill="#e92b99"
        />
      </g>
    </g>
  </svg>
);

export const Sunset = ({ className }) => (
  <svg
    id="Group_8455"
    data-name="Group 8455"
    xmlns="http://www.w3.org/2000/svg"
    width="30.146"
    height="30.206"
    viewBox="0 0 30.146 30.206"
    className={className}
  >
    <path
      id="Path_16585"
      data-name="Path 16585"
      d="M1710.973,5812.557a.843.843,0,0,1-.831-.7,13.474,13.474,0,0,0-2.311-5.571.843.843,0,1,1,1.375-.977,15.163,15.163,0,0,1,2.6,6.267.844.844,0,0,1-.691.973A.927.927,0,0,1,1710.973,5812.557Z"
      transform="translate(-1681.671 -5799.111)"
      fill="#ffe58a"
    />
    <path
      id="Path_16589"
      data-name="Path 16589"
      d="M1692.328,5812.561a.874.874,0,0,1-.141-.013.844.844,0,0,1-.692-.972,15.306,15.306,0,0,1,.954-3.325,14.919,14.919,0,0,1,1.634-2.948.844.844,0,1,1,1.374.98,13.254,13.254,0,0,0-1.45,2.615,13.621,13.621,0,0,0-.849,2.958A.846.846,0,0,1,1692.328,5812.561Z"
      transform="translate(-1691.324 -5799.113)"
      fill="#50366f"
    />
    <path
      id="Path_16610"
      data-name="Path 16610"
      d="M1695.983,5805.434a.844.844,0,0,1-.49-1.531,15.308,15.308,0,0,1,2.964-1.646,15.143,15.143,0,0,1,3.3-.955.936.936,0,0,1,.142-.012.845.845,0,0,1,.14,1.678,13.438,13.438,0,0,0-2.935.846,13.654,13.654,0,0,0-2.636,1.463A.835.835,0,0,1,1695.983,5805.434Z"
      transform="translate(-1689.145 -5801.29)"
      fill="#bf3475"
    />
    <path
      id="Path_16614"
      data-name="Path 16614"
      d="M1708.613,5805.426a.843.843,0,0,1-.489-.156,13.315,13.315,0,0,0-2.615-1.45,13.562,13.562,0,0,0-2.958-.848.844.844,0,0,1,.139-1.676.925.925,0,0,1,.141.011,15.384,15.384,0,0,1,3.325.954,15.016,15.016,0,0,1,2.948,1.636.843.843,0,0,1-.49,1.529Z"
      transform="translate(-1685.146 -5801.289)"
      fill="#ee6c45"
    />
    <path
      id="Path_16595"
      data-name="Path 16595"
      d="M1706.475,5825.317a15.2,15.2,0,0,1-14.982-12.675.844.844,0,0,1,1.664-.278,13.506,13.506,0,0,0,26.635,0,.843.843,0,0,1,1.664.278,15.174,15.174,0,0,1-14.981,12.675Z"
      transform="translate(-1691.481 -5795.112)"
      fill="#1f214d"
    />
    <g id="Group_7164" data-name="Group 7164" transform="translate(6.243 5.78)">
      <g id="Group_7163" data-name="Group 7163" transform="translate(0 0)">
        <path
          id="Path_16656"
          data-name="Path 16656"
          d="M1356.515,6617.882a.844.844,0,0,1-.844-.843,2.242,2.242,0,1,0-4.485,0,.844.844,0,0,1-1.687,0,3.93,3.93,0,1,1,7.859,0A.844.844,0,0,1,1356.515,6617.882Z"
          transform="translate(-1344.793 -6607.758)"
          fill="#ffce61"
        />
        <path
          id="Line_205"
          data-name="Line 205"
          d="M.031-.813H-1.656A.844.844,0,0,1-2.5-1.656.844.844,0,0,1-1.656-2.5H.031a.844.844,0,0,1,.844.844A.844.844,0,0,1,.031-.813Z"
          transform="translate(16.336 10.599)"
          fill="#ffce61"
        />
        <path
          id="Line_206"
          data-name="Line 206"
          d="M.031-.813H-1.656A.844.844,0,0,1-2.5-1.656.844.844,0,0,1-1.656-2.5H.031a.844.844,0,0,1,.844.844A.844.844,0,0,1,.031-.813Z"
          transform="translate(2.5 10.599)"
          fill="#ffce61"
        />
        <path
          id="Line_207"
          data-name="Line 207"
          d="M-1.656.875A.844.844,0,0,1-2.5.031V-1.656A.844.844,0,0,1-1.656-2.5a.844.844,0,0,1,.844.844V.031A.844.844,0,0,1-1.656.875Z"
          transform="translate(10.262 2.5)"
          fill="#ffce61"
        />
        <path
          id="Line_208"
          data-name="Line 208"
          d="M-1.656.434a.841.841,0,0,1-.6-.247.844.844,0,0,1,0-1.193l1.247-1.247a.844.844,0,0,1,1.193,0,.844.844,0,0,1,0,1.193L-1.06.187A.841.841,0,0,1-1.656.434Z"
          transform="translate(14.657 4.885)"
          fill="#ffce61"
        />
        <path
          id="Line_209"
          data-name="Line 209"
          d="M-.41.434a.841.841,0,0,1-.6-.247L-2.253-1.06a.844.844,0,0,1,0-1.193.844.844,0,0,1,1.193,0L.187-1.006a.844.844,0,0,1,0,1.193A.841.841,0,0,1-.41.434Z"
          transform="translate(4.682 4.885)"
          fill="#ffce61"
        />
        <path
          id="Line_210"
          data-name="Line 210"
          d="M7.118-.813H-1.656A.844.844,0,0,1-2.5-1.656.844.844,0,0,1-1.656-2.5H7.118a.844.844,0,0,1,.844.844A.844.844,0,0,1,7.118-.813Z"
          transform="translate(2.5 13.974)"
          fill="#bf3475"
        />
        <path
          id="Line_211"
          data-name="Line 211"
          d="M1.718-.813H-1.656A.844.844,0,0,1-2.5-1.656.844.844,0,0,1-1.656-2.5H1.718a.844.844,0,0,1,.844.844A.844.844,0,0,1,1.718-.813Z"
          transform="translate(14.649 13.974)"
          fill="#bf3475"
        />
        <path
          id="Line_212"
          data-name="Line 212"
          d="M6.443-.813h-8.1A.844.844,0,0,1-2.5-1.656.844.844,0,0,1-1.656-2.5h8.1a.844.844,0,0,1,.844.844A.844.844,0,0,1,6.443-.813Z"
          transform="translate(9.249 16.673)"
          fill="#bf3475"
        />
        <path
          id="Line_213"
          data-name="Line 213"
          d="M1.043-.813h-2.7A.844.844,0,0,1-2.5-1.656.844.844,0,0,1-1.656-2.5h2.7a.844.844,0,0,1,.844.844A.844.844,0,0,1,1.043-.813Z"
          transform="translate(3.175 16.673)"
          fill="#bf3475"
        />
      </g>
    </g>
  </svg>
);

export const LifesLemons = ({ className }) => (
  <svg
    id="Group_8454"
    data-name="Group 8454"
    xmlns="http://www.w3.org/2000/svg"
    width="30.427"
    height="30.197"
    viewBox="0 0 30.427 30.197"
    className={className}
  >
    <g id="Group_7173" data-name="Group 7173" transform="translate(30.427 14.14) rotate(90)">
      <path
        id="Path_16489"
        data-name="Path 16489"
        d="M.844,30.427a.844.844,0,1,1,0-1.687,13.481,13.481,0,0,0,9.564-3.962,13.481,13.481,0,0,0,2.9-4.3,13.576,13.576,0,0,0,0-10.53,13.48,13.48,0,0,0-2.9-4.3A13.481,13.481,0,0,0,.844,1.687.844.844,0,1,1,.844,0,15.168,15.168,0,0,1,11.6,4.456a15.16,15.16,0,0,1,3.26,4.835,15.263,15.263,0,0,1,0,11.845,15.161,15.161,0,0,1-3.26,4.836A15.168,15.168,0,0,1,.844,30.427Z"
        transform="translate(0 0)"
        fill="#f2d249"
      />
    </g>
    <path
      id="Path_16700"
      data-name="Path 16700"
      d="M5.168,4.174a.842.842,0,0,1-.289-.051A15.185,15.185,0,0,1,.3,1.49.844.844,0,1,1,1.386.2a13.5,13.5,0,0,0,4.071,2.34.844.844,0,0,1-.289,1.636Z"
      transform="translate(5.054 4.906) rotate(90)"
      fill="#f0e367"
    />
    <path
      id="Path_16697"
      data-name="Path 16697"
      d="M.844,4.174A.844.844,0,0,1,.3,2.684,15.118,15.118,0,0,1,4.879.051a.844.844,0,1,1,.577,1.586A13.436,13.436,0,0,0,1.386,3.976.84.84,0,0,1,.844,4.174Z"
      transform="translate(29.547 4.906) rotate(90)"
      fill="#fff7ad"
    />
    <path
      id="Path_16698"
      data-name="Path 16698"
      d="M.845,6.373a.844.844,0,0,1-.832-.99A15.1,15.1,0,0,1,1.817.422a.844.844,0,0,1,1.461.845,13.422,13.422,0,0,0-1.6,4.409A.844.844,0,0,1,.845,6.373Z"
      transform="translate(23.237 0) rotate(90)"
      fill="#ffdb00"
    />
    <path
      id="Path_16699"
      data-name="Path 16699"
      d="M2.548,6.374a.843.843,0,0,1-.731-.422A15.108,15.108,0,0,1,.013.99.844.844,0,0,1,1.675.7a13.426,13.426,0,0,0,1.6,4.41.844.844,0,0,1-.73,1.266Z"
      transform="translate(13.566 0) rotate(90)"
      fill="#f5ff83"
    />
    <g id="Group_7179" data-name="Group 7179" transform="translate(7.316 7.01)">
      <g id="Group_7178" data-name="Group 7178" transform="translate(0 0)">
        <g id="Group_7177" data-name="Group 7177" transform="translate(0 0)">
          <path
            id="Path_16707"
            data-name="Path 16707"
            d="M1129.354,8233.107a7.688,7.688,0,0,1,2.929.572.082.082,0,0,0,.027.01,3.037,3.037,0,0,1,1.259-.276h0a2.985,2.985,0,0,1,2.707,4.244.171.171,0,0,0,.009.024,7.788,7.788,0,0,1,.4,4.579,8.542,8.542,0,0,1-5.071,6.085,8.1,8.1,0,0,1-3.115.624,7.636,7.636,0,0,1-2.951-.583h0a.439.439,0,0,0-.065-.022,2.986,2.986,0,0,1-1.115.215,2.98,2.98,0,0,1-2.764-4.094.393.393,0,0,0-.022-.068,7.783,7.783,0,0,1-.414-4.591,8.4,8.4,0,0,1,2.307-4.241,8.487,8.487,0,0,1,2.767-1.855A8.141,8.141,0,0,1,1129.354,8233.107Zm2.973,2.274a1.806,1.806,0,0,1-.688-.141,5.958,5.958,0,0,0-2.285-.446,6.449,6.449,0,0,0-2.47.5,6.8,6.8,0,0,0-2.218,1.486,6.731,6.731,0,0,0-1.848,3.394,6.1,6.1,0,0,0,.319,3.6,1.723,1.723,0,0,1,.036,1.329,1.3,1.3,0,0,0,1.695,1.694,1.551,1.551,0,0,1,.6-.118,1.892,1.892,0,0,1,.731.155,5.959,5.959,0,0,0,2.3.451,6.47,6.47,0,0,0,2.472-.492,6.84,6.84,0,0,0,2.218-1.487,6.728,6.728,0,0,0,1.845-3.382,6.114,6.114,0,0,0-.31-3.593,1.646,1.646,0,0,1,.012-1.36,1.3,1.3,0,0,0-1.167-1.866,1.339,1.339,0,0,0-.568.13l0,0A1.574,1.574,0,0,1,1132.327,8235.382Z"
            transform="translate(-1120.996 -8233.107)"
            fill="#ffea01"
          />
        </g>
      </g>
      <path
        id="Line_214"
        data-name="Line 214"
        d="M-1.656-.276a.841.841,0,0,1-.6-.247.844.844,0,0,1,0-1.193l.537-.537a.844.844,0,0,1,1.193,0,.844.844,0,0,1,0,1.193l-.537.537A.841.841,0,0,1-1.656-.276Z"
        transform="translate(10.21 6.754)"
        fill="#ffea01"
      />
      <path
        id="Line_215"
        data-name="Line 215"
        d="M-1.656-.276a.841.841,0,0,1-.6-.247.844.844,0,0,1,0-1.193l.537-.537a.844.844,0,0,1,1.193,0,.844.844,0,0,1,0,1.193l-.537.537A.841.841,0,0,1-1.656-.276Z"
        transform="translate(9.885 12.316)"
        fill="#ffea01"
      />
      <path
        id="Line_216"
        data-name="Line 216"
        d="M-1.656-.276a.841.841,0,0,1-.6-.247.844.844,0,0,1,0-1.193l.537-.537a.844.844,0,0,1,1.193,0,.844.844,0,0,1,0,1.193l-.537.537A.841.841,0,0,1-1.656-.276Z"
        transform="translate(7.076 7.357)"
        fill="#ffea01"
      />
      <path
        id="Line_217"
        data-name="Line 217"
        d="M-1.656-.276a.841.841,0,0,1-.6-.247.844.844,0,0,1,0-1.193l.537-.537a.844.844,0,0,1,1.193,0,.844.844,0,0,1,0,1.193l-.537.537A.841.841,0,0,1-1.656-.276Z"
        transform="translate(10.665 9.385)"
        fill="#ffea01"
      />
      <path
        id="Line_218"
        data-name="Line 218"
        d="M-1.656-.276a.841.841,0,0,1-.6-.247.844.844,0,0,1,0-1.193l.537-.537a.844.844,0,0,1,1.193,0,.844.844,0,0,1,0,1.193l-.537.537A.841.841,0,0,1-1.656-.276Z"
        transform="translate(7.344 10.372)"
        fill="#ffea01"
      />
    </g>
  </svg>
);

export const VintageFlame = ({ className }) => (
  <svg
    id="Group_8453"
    data-name="Group 8453"
    xmlns="http://www.w3.org/2000/svg"
    width="29.99"
    height="30.206"
    viewBox="0 0 29.99 30.206"
    className={className}
  >
    <path
      id="Path_16677"
      data-name="Path 16677"
      d="M1710.973,5812.557a.843.843,0,0,1-.831-.7,13.474,13.474,0,0,0-2.311-5.571.843.843,0,1,1,1.375-.977,15.163,15.163,0,0,1,2.6,6.267.844.844,0,0,1-.691.973A.927.927,0,0,1,1710.973,5812.557Z"
      transform="translate(-1681.827 -5799.111)"
      fill="#edcf12"
    />
    <path
      id="Path_16681"
      data-name="Path 16681"
      d="M1692.328,5812.561a.874.874,0,0,1-.141-.013.844.844,0,0,1-.692-.972,15.306,15.306,0,0,1,.954-3.325,14.919,14.919,0,0,1,1.634-2.948.844.844,0,1,1,1.374.98,13.254,13.254,0,0,0-1.45,2.615,13.621,13.621,0,0,0-.849,2.958A.846.846,0,0,1,1692.328,5812.561Z"
      transform="translate(-1691.48 -5799.113)"
      fill="#dc3b18"
    />
    <path
      id="Path_16685"
      data-name="Path 16685"
      d="M1695.983,5805.434a.844.844,0,0,1-.49-1.531,15.308,15.308,0,0,1,2.964-1.646,15.143,15.143,0,0,1,3.3-.955.936.936,0,0,1,.142-.012.845.845,0,0,1,.14,1.678,13.438,13.438,0,0,0-2.935.846,13.654,13.654,0,0,0-2.636,1.463A.835.835,0,0,1,1695.983,5805.434Z"
      transform="translate(-1689.301 -5801.29)"
      fill="#f28f0a"
    />
    <path
      id="Path_16689"
      data-name="Path 16689"
      d="M1708.613,5805.426a.843.843,0,0,1-.489-.156,13.315,13.315,0,0,0-2.615-1.45,13.562,13.562,0,0,0-2.958-.848.844.844,0,0,1,.139-1.676.925.925,0,0,1,.141.011,15.384,15.384,0,0,1,3.325.954,15.016,15.016,0,0,1,2.948,1.636.843.843,0,0,1-.49,1.529Z"
      transform="translate(-1685.303 -5801.289)"
      fill="#edb605"
    />
    <path
      id="Path_16673"
      data-name="Path 16673"
      d="M1706.475,5825.317a15.2,15.2,0,0,1-14.982-12.675.844.844,0,0,1,1.664-.278,13.506,13.506,0,0,0,26.635,0,.843.843,0,0,1,1.664.278,15.174,15.174,0,0,1-14.981,12.675Z"
      transform="translate(-1691.481 -5795.112)"
      fill="#bf1e33"
    />
    <g id="Group_7182" data-name="Group 7182" transform="translate(7.302 6.509)">
      <g id="Group_7181" data-name="Group 7181" transform="translate(0)">
        <path
          id="Path_16714"
          data-name="Path 16714"
          d="M1915.439,5508.613h0a8.253,8.253,0,0,1-.877-.048,7.774,7.774,0,0,1-4.539-2.14,7.48,7.48,0,0,1-1.619-8.564,7.643,7.643,0,0,1,2.584-3.059,1.756,1.756,0,0,1,1.006-.317,1.948,1.948,0,0,1,1.8,1.3v0a4.508,4.508,0,0,0,.5.977,4.464,4.464,0,0,0,.01-3.179,1.946,1.946,0,0,1,.21-1.795,1.826,1.826,0,0,1,2.36-.6,11.791,11.791,0,0,1,4.541,4.021,10.573,10.573,0,0,1,1.749,5.808,7.438,7.438,0,0,1-.61,2.962,7.584,7.584,0,0,1-1.659,2.417,7.7,7.7,0,0,1-2.456,1.625A7.784,7.784,0,0,1,1915.439,5508.613Zm-3.445-12.44a.072.072,0,0,0-.045.017,5.968,5.968,0,0,0-2.015,2.382,5.795,5.795,0,0,0,1.262,6.641,6.081,6.081,0,0,0,3.547,1.676,6.664,6.664,0,0,0,.7.038h0a6.064,6.064,0,0,0,4.277-1.738,5.809,5.809,0,0,0,1.764-4.174,8.89,8.89,0,0,0-1.473-4.882,10.1,10.1,0,0,0-3.893-3.442.187.187,0,0,0-.083-.022.173.173,0,0,0-.127.079.249.249,0,0,0-.021.238,6.15,6.15,0,0,1-.733,5.729.845.845,0,0,1-.6.353.669.669,0,0,1-.094,0,.851.851,0,0,1-.566-.217,6.266,6.266,0,0,1-1.683-2.491A.275.275,0,0,0,1911.995,5496.173Z"
          transform="translate(-1907.71 -5490.979)"
          fill="#f26417"
        />
      </g>
    </g>
  </svg>
);

export const Earth = ({ className }) => (
  <svg
    id="Group_8452"
    data-name="Group 8452"
    xmlns="http://www.w3.org/2000/svg"
    width="29.99"
    height="30.206"
    viewBox="0 0 29.99 30.206"
    className={className}
  >
    <path
      id="Path_16678"
      data-name="Path 16678"
      d="M1710.973,5812.557a.843.843,0,0,1-.831-.7,13.474,13.474,0,0,0-2.311-5.571.843.843,0,1,1,1.375-.977,15.163,15.163,0,0,1,2.6,6.267.844.844,0,0,1-.691.973A.927.927,0,0,1,1710.973,5812.557Z"
      transform="translate(-1681.828 -5799.111)"
      fill="#dab05e"
    />
    <path
      id="Path_16682"
      data-name="Path 16682"
      d="M1692.328,5812.561a.874.874,0,0,1-.141-.013.844.844,0,0,1-.692-.972,15.306,15.306,0,0,1,.954-3.325,14.919,14.919,0,0,1,1.634-2.948.844.844,0,1,1,1.374.98,13.254,13.254,0,0,0-1.45,2.615,13.621,13.621,0,0,0-.849,2.958A.846.846,0,0,1,1692.328,5812.561Z"
      transform="translate(-1691.48 -5799.113)"
      fill="#899165"
    />
    <path
      id="Path_16686"
      data-name="Path 16686"
      d="M1695.983,5805.434a.844.844,0,0,1-.49-1.531,15.308,15.308,0,0,1,2.964-1.646,15.143,15.143,0,0,1,3.3-.955.936.936,0,0,1,.142-.012.845.845,0,0,1,.14,1.678,13.438,13.438,0,0,0-2.935.846,13.654,13.654,0,0,0-2.636,1.463A.835.835,0,0,1,1695.983,5805.434Z"
      transform="translate(-1689.302 -5801.29)"
      fill="#c1b78e"
    />
    <path
      id="Path_16690"
      data-name="Path 16690"
      d="M1708.613,5805.426a.843.843,0,0,1-.489-.156,13.315,13.315,0,0,0-2.615-1.45,13.562,13.562,0,0,0-2.958-.848.844.844,0,0,1,.139-1.676.925.925,0,0,1,.141.011,15.384,15.384,0,0,1,3.325.954,15.016,15.016,0,0,1,2.948,1.636.843.843,0,0,1-.49,1.529Z"
      transform="translate(-1685.303 -5801.289)"
      fill="#ad8e69"
    />
    <path
      id="Path_16674"
      data-name="Path 16674"
      d="M1706.475,5825.317a15.2,15.2,0,0,1-14.982-12.675.844.844,0,0,1,1.664-.278,13.506,13.506,0,0,0,26.635,0,.843.843,0,0,1,1.664.278,15.174,15.174,0,0,1-14.981,12.675Z"
      transform="translate(-1691.481 -5795.112)"
      fill="#384324"
      opacity="0.8"
    />
    <g id="Group_7187" data-name="Group 7187" transform="translate(5.653 5.836)">
      <path
        id="Path_16721"
        data-name="Path 16721"
        d="M2139.032,460.048h-3.938a.844.844,0,0,1,0-1.687h3.938a1.827,1.827,0,0,0,1.291-3.119l-2.317-2.317a.844.844,0,0,1,1.193-1.193l2.317,2.318a3.514,3.514,0,0,1-2.485,6Z"
        transform="translate(-2134.25 -449.864)"
        fill="#899165"
      />
      <path
        id="Path_16722"
        data-name="Path 16722"
        d="M2147.553,465.294a.843.843,0,0,1-.7-.376l-1.678-2.516a.791.791,0,0,0-.66-.353,2.483,2.483,0,0,1-2.22-1.37l0,0-.124-.249a2.5,2.5,0,0,1,0-2.219l1.069-2.139a2.482,2.482,0,0,1,2.22-1.372h4.162a.844.844,0,0,1,0,1.687h-4.162a.791.791,0,0,0-.711.44l-1.068,2.137a.8.8,0,0,0,0,.711v0l.123.249a.793.793,0,0,0,.711.439,2.475,2.475,0,0,1,2.064,1.1l1.677,2.516a.843.843,0,0,1-.7,1.312Z"
        transform="translate(-2132.272 -449.033)"
        fill="#899165"
      />
      <g id="Group_7186" data-name="Group 7186" transform="translate(0 0)">
        <g id="Group_7184" data-name="Group 7184" transform="translate(8.497 0)">
          <path
            id="Path_16723"
            data-name="Path 16723"
            d="M2141.843,468.878a.844.844,0,0,1,0-1.687,7.653,7.653,0,1,0,0-15.306.844.844,0,0,1,0-1.687,9.34,9.34,0,1,1,0,18.681Z"
            transform="translate(-2141 -450.197)"
            fill="#805b55"
          />
        </g>
        <g id="Group_7185" data-name="Group 7185" transform="translate(0 0)">
          <path
            id="Path_16724"
            data-name="Path 16724"
            d="M2143.591,468.878a9.34,9.34,0,1,1,0-18.681.844.844,0,1,1,0,1.687,7.653,7.653,0,1,0,0,15.306.844.844,0,1,1,0,1.687Z"
            transform="translate(-2134.25 -450.197)"
            fill="#805b55"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const FinalFrontier = ({ className }) => (
  <svg
    id="Group_8451"
    data-name="Group 8451"
    xmlns="http://www.w3.org/2000/svg"
    width="30.146"
    height="30.206"
    viewBox="0 0 30.146 30.206"
    className={className}
  >
    <path
      id="Line_219"
      data-name="Line 219"
      d="M-.027,2.038A.843.843,0,0,1-.76,1.613L-2.389-1.238a.844.844,0,0,1,.314-1.151.844.844,0,0,1,1.151.314L.7.776A.844.844,0,0,1,.391,1.927.84.84,0,0,1-.027,2.038Z"
      transform="translate(19.283 21.29)"
      fill="#4a61a4"
    />
    <path
      id="Line_220"
      data-name="Line 220"
      d="M-1.657,2.038a.84.84,0,0,1-.418-.111A.844.844,0,0,1-2.389.776L-.76-2.075A.844.844,0,0,1,.391-2.389.844.844,0,0,1,.7-1.238L-.924,1.613A.843.843,0,0,1-1.657,2.038Z"
      transform="translate(12.388 21.29)"
      fill="#4a61a4"
    />
    <path
      id="Path_16679"
      data-name="Path 16679"
      d="M1710.973,5812.557a.843.843,0,0,1-.831-.7,13.474,13.474,0,0,0-2.311-5.571.843.843,0,1,1,1.375-.977,15.163,15.163,0,0,1,2.6,6.267.844.844,0,0,1-.691.973A.927.927,0,0,1,1710.973,5812.557Z"
      transform="translate(-1681.671 -5799.111)"
      fill="#73ccf9"
    />
    <path
      id="Path_16683"
      data-name="Path 16683"
      d="M1692.328,5812.561a.874.874,0,0,1-.141-.013.844.844,0,0,1-.692-.972,15.306,15.306,0,0,1,.954-3.325,14.919,14.919,0,0,1,1.634-2.948.844.844,0,1,1,1.374.98,13.254,13.254,0,0,0-1.45,2.615,13.621,13.621,0,0,0-.849,2.958A.846.846,0,0,1,1692.328,5812.561Z"
      transform="translate(-1691.324 -5799.113)"
      fill="#263b61"
    />
    <path
      id="Path_16687"
      data-name="Path 16687"
      d="M1695.983,5805.434a.844.844,0,0,1-.49-1.531,15.308,15.308,0,0,1,2.964-1.646,15.143,15.143,0,0,1,3.3-.955.936.936,0,0,1,.142-.012.845.845,0,0,1,.14,1.678,13.438,13.438,0,0,0-2.935.846,13.654,13.654,0,0,0-2.636,1.463A.835.835,0,0,1,1695.983,5805.434Z"
      transform="translate(-1689.145 -5801.29)"
      fill="#4a61a4"
    />
    <path
      id="Path_16691"
      data-name="Path 16691"
      d="M1708.613,5805.426a.843.843,0,0,1-.489-.156,13.315,13.315,0,0,0-2.615-1.45,13.562,13.562,0,0,0-2.958-.848.844.844,0,0,1,.139-1.676.925.925,0,0,1,.141.011,15.384,15.384,0,0,1,3.325.954,15.016,15.016,0,0,1,2.948,1.636.843.843,0,0,1-.49,1.529Z"
      transform="translate(-1685.146 -5801.289)"
      fill="#489bcf"
    />
    <path
      id="Path_16675"
      data-name="Path 16675"
      d="M1706.475,5825.317a15.2,15.2,0,0,1-14.982-12.675.844.844,0,0,1,1.664-.278,13.506,13.506,0,0,0,26.635,0,.843.843,0,0,1,1.664.278,15.174,15.174,0,0,1-14.981,12.675Z"
      transform="translate(-1691.481 -5795.112)"
      fill="#000f2b"
    />
    <g id="Group_7189" data-name="Group 7189" transform="translate(5.626 11.693)">
      <path
        id="Path_16731"
        data-name="Path 16731"
        d="M2084.367,13560.762a15.779,15.779,0,0,1-6.377-1.217c-1.928-.881-2.991-2.131-2.991-3.525,0-1.824,1.852-3.4,4.953-4.205a.842.842,0,1,1,.426,1.629,8.052,8.052,0,0,0-2.828,1.262,1.878,1.878,0,0,0-.863,1.314,1.413,1.413,0,0,0,.446.945,4.93,4.93,0,0,0,1.558,1.047,11.211,11.211,0,0,0,2.519.771,17.383,17.383,0,0,0,6.314,0,11.21,11.21,0,0,0,2.52-.771,4.939,4.939,0,0,0,1.558-1.047,1.412,1.412,0,0,0,.446-.945,1.887,1.887,0,0,0-.858-1.314,8.056,8.056,0,0,0-2.817-1.252.845.845,0,0,1,.213-1.662.812.812,0,0,1,.214.029,9.677,9.677,0,0,1,3.419,1.551,3.33,3.33,0,0,1,1.516,2.648c0,1.395-1.062,2.645-2.99,3.525A15.782,15.782,0,0,1,2084.367,13560.762Z"
        transform="translate(-2075 -13551.786)"
        fill="#1b264d"
      />
    </g>
    <path
      id="Path_16732"
      data-name="Path 16732"
      d="M2090.873,13558.012a.891.891,0,1,1,.892-.892A.894.894,0,0,1,2090.873,13558.012Z"
      transform="translate(-2071.585 -13540.754)"
      fill="#4a61a4"
    />
    <path
      id="Path_16733"
      data-name="Path 16733"
      d="M2080.805,13558.012a.891.891,0,1,1,.893-.892A.893.893,0,0,1,2080.805,13558.012Z"
      transform="translate(-2070.099 -13540.754)"
      fill="#4a61a4"
    />
    <path
      id="Path_16734"
      data-name="Path 16734"
      d="M2085.835,13559.326a.893.893,0,1,1,.892-.892A.893.893,0,0,1,2085.835,13559.326Z"
      transform="translate(-2070.842 -13540.944)"
      fill="#4a61a4"
    />
    <path
      id="Path_16735"
      data-name="Path 16735"
      d="M2085.105,13556.119a8.322,8.322,0,0,1-3.364-.645,3.744,3.744,0,0,1-1.182-.812,1.96,1.96,0,0,1-.56-1.334.844.844,0,1,1,1.687,0c0,.088.185.352.755.611a7.425,7.425,0,0,0,5.327,0c.571-.262.755-.523.755-.611a.844.844,0,1,1,1.687,0,1.959,1.959,0,0,1-.56,1.334,3.748,3.748,0,0,1-1.182.813A8.325,8.325,0,0,1,2085.105,13556.119Z"
      transform="translate(-2070.111 -13540.198)"
      fill="#73ccf9"
    />
    <path
      id="Path_16736"
      data-name="Path 16736"
      d="M2089.371,13553.432a.845.845,0,0,1-.844-.846v-.029a3.41,3.41,0,1,0-6.82,0v.029a.844.844,0,1,1-1.687,0v-.029a5.1,5.1,0,1,1,10.194,0v.029A.845.845,0,0,1,2089.371,13553.432Z"
      transform="translate(-2070.115 -13539.457)"
      fill="#73ccf9"
    />
  </svg>
);

export const Summertime = ({ className }) => (
  <svg
    id="Group_8450"
    data-name="Group 8450"
    xmlns="http://www.w3.org/2000/svg"
    width="30.146"
    height="30.206"
    viewBox="0 0 30.146 30.206"
    className={className}
  >
    <path
      id="Path_16680"
      data-name="Path 16680"
      d="M1710.973,5812.557a.843.843,0,0,1-.831-.7,13.474,13.474,0,0,0-2.311-5.571.843.843,0,1,1,1.375-.977,15.163,15.163,0,0,1,2.6,6.267.844.844,0,0,1-.691.973A.927.927,0,0,1,1710.973,5812.557Z"
      transform="translate(-1681.671 -5799.111)"
      fill="#3ab49d"
    />
    <path
      id="Path_16684"
      data-name="Path 16684"
      d="M1692.328,5812.561a.874.874,0,0,1-.141-.013.844.844,0,0,1-.692-.972,15.306,15.306,0,0,1,.954-3.325,14.919,14.919,0,0,1,1.634-2.948.844.844,0,1,1,1.374.98,13.254,13.254,0,0,0-1.45,2.615,13.621,13.621,0,0,0-.849,2.958A.846.846,0,0,1,1692.328,5812.561Z"
      transform="translate(-1691.324 -5799.113)"
      fill="#3ab49d"
    />
    <path
      id="Path_16688"
      data-name="Path 16688"
      d="M1695.983,5805.434a.844.844,0,0,1-.49-1.531,15.308,15.308,0,0,1,2.964-1.646,15.143,15.143,0,0,1,3.3-.955.936.936,0,0,1,.142-.012.845.845,0,0,1,.14,1.678,13.438,13.438,0,0,0-2.935.846,13.654,13.654,0,0,0-2.636,1.463A.835.835,0,0,1,1695.983,5805.434Z"
      transform="translate(-1689.145 -5801.29)"
      fill="#fc77b1"
    />
    <path
      id="Path_16692"
      data-name="Path 16692"
      d="M1708.613,5805.426a.843.843,0,0,1-.489-.156,13.315,13.315,0,0,0-2.615-1.45,13.562,13.562,0,0,0-2.958-.848.844.844,0,0,1,.139-1.676.925.925,0,0,1,.141.011,15.384,15.384,0,0,1,3.325.954,15.016,15.016,0,0,1,2.948,1.636.843.843,0,0,1-.49,1.529Z"
      transform="translate(-1685.146 -5801.289)"
      fill="#d92b8e"
    />
    <path
      id="Path_16676"
      data-name="Path 16676"
      d="M1706.475,5825.317a15.2,15.2,0,0,1-14.982-12.675.844.844,0,0,1,1.664-.278,13.506,13.506,0,0,0,26.635,0,.843.843,0,0,1,1.664.278,15.174,15.174,0,0,1-14.981,12.675Z"
      transform="translate(-1691.481 -5795.112)"
      fill="#215a8e"
    />
    <g id="Group_7192" data-name="Group 7192" transform="translate(5.231 5.779)">
      <g id="Group_7191" data-name="Group 7191" transform="translate(0 0)">
        <path
          id="Ellipse_306"
          data-name="Ellipse 306"
          d="M2.731-2.5A5.227,5.227,0,0,1,7.961,2.731,5.227,5.227,0,0,1,2.731,7.961,5.227,5.227,0,0,1-2.5,2.731,5.227,5.227,0,0,1,2.731-2.5Zm0,8.774A3.543,3.543,0,1,0-.813,2.731,3.547,3.547,0,0,0,2.731,6.274Z"
          transform="translate(6.887 6.887)"
          fill="#f1e978"
        />
        <path
          id="Line_221"
          data-name="Line 221"
          d="M-1.656.875A.844.844,0,0,1-2.5.031V-1.656A.844.844,0,0,1-1.656-2.5a.844.844,0,0,1,.844.844V.031A.844.844,0,0,1-1.656.875Z"
          transform="translate(11.274 2.5)"
          fill="#f1e978"
        />
        <path
          id="Line_222"
          data-name="Line 222"
          d="M-1.656.875A.844.844,0,0,1-2.5.031V-1.656A.844.844,0,0,1-1.656-2.5a.844.844,0,0,1,.844.844V.031A.844.844,0,0,1-1.656.875Z"
          transform="translate(11.274 18.361)"
          fill="#f1e978"
        />
        <path
          id="Line_223"
          data-name="Line 223"
          d="M-1.656-.182a.841.841,0,0,1-.6-.247.844.844,0,0,1,0-1.193l.631-.631a.844.844,0,0,1,1.193,0,.844.844,0,0,1,0,1.193l-.631.631A.841.841,0,0,1-1.656-.182Z"
          transform="translate(16.952 4.965)"
          fill="#f1e978"
        />
        <path
          id="Line_224"
          data-name="Line 224"
          d="M-1.656-.182a.841.841,0,0,1-.6-.247.844.844,0,0,1,0-1.193l.631-.631a.844.844,0,0,1,1.193,0,.844.844,0,0,1,0,1.193l-.631.631A.841.841,0,0,1-1.656-.182Z"
          transform="translate(4.965 16.952)"
          fill="#f1e978"
        />
        <path
          id="Line_225"
          data-name="Line 225"
          d="M.031-.813H-1.656A.844.844,0,0,1-2.5-1.656.844.844,0,0,1-1.656-2.5H.031a.844.844,0,0,1,.844.844A.844.844,0,0,1,.031-.813Z"
          transform="translate(18.361 11.274)"
          fill="#f1e978"
        />
        <path
          id="Line_226"
          data-name="Line 226"
          d="M.031-.813H-1.656A.844.844,0,0,1-2.5-1.656.844.844,0,0,1-1.656-2.5H.031a.844.844,0,0,1,.844.844A.844.844,0,0,1,.031-.813Z"
          transform="translate(2.5 11.274)"
          fill="#f1e978"
        />
        <path
          id="Line_227"
          data-name="Line 227"
          d="M-1.025-.182a.841.841,0,0,1-.6-.247l-.631-.631a.844.844,0,0,1,0-1.193.844.844,0,0,1,1.193,0l.631.631a.844.844,0,0,1,0,1.193A.841.841,0,0,1-1.025-.182Z"
          transform="translate(16.952 16.952)"
          fill="#f1e978"
        />
        <path
          id="Line_228"
          data-name="Line 228"
          d="M-1.025-.182a.841.841,0,0,1-.6-.247l-.631-.631a.844.844,0,0,1,0-1.193.844.844,0,0,1,1.193,0l.631.631a.844.844,0,0,1,0,1.193A.841.841,0,0,1-1.025-.182Z"
          transform="translate(4.965 4.965)"
          fill="#f1e978"
        />
      </g>
    </g>
  </svg>
);
