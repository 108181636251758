import React, { useCallback, useState } from 'react';
import { add } from 'date-fns';
import { useGAS } from 'src/GlobalAppState/context';
import { useCampaignDraftSchemaValidator } from 'src/selectors/campaign/budgetValidationSelectors';
import { selectBrandCreditSelfServiceDisabled } from 'src/selectors/organization/organizationSelectors';
import { BrandSettingsCredits } from '../../../MainSettings/BrandSettings/BrandSettingsCredits/BrandSettingsCredits';
import { ToggleButtonV2 } from '../../../shared/ToggleButton';
import { OPTIONS, RecurrenceSelector } from './RecurringPanel/RecurrenceSelector';
import { CurrencyInput } from './formFields/CurrencyInput';
import { ErrorBox } from './formFields/ErrorBox';

const PaymentMethodRequiredMessage = ({ onShow }) => (
  <div>
    <p className="text-gray-600">
      Recurring option requires a saved credit card.
      <button type="button" onClick={onShow} className="text-current underline ml-2 hover:text-blue-500">
        Add a payment method
      </button>
    </p>
  </div>
);

const SelfServiceDisabledMessage = () => (
  <div>
    <p className="text-gray-600">Recurring campaigns are disabled for this account</p>
  </div>
);

const getEndDate = (numOfDays, lastRecurredAt, startDate, endDate) => {
  if (!lastRecurredAt || !endDate) {
    return add(new Date(startDate), { days: numOfDays }).toISOString();
  }

  return endDate;
};

export const RecurringPanel = ({ campaignDraft, setCampaignDraft, appParams, availCredits }) => {
  const { brandPaymentMethods, organization } = useGAS();
  const selfServiceDisabled = selectBrandCreditSelfServiceDisabled(organization);
  const paymentMethods = brandPaymentMethods.card ?? [];

  const { recurrenceBudget, recurrenceWindow, startDate, endDate, isRecurring, lastRecurredAt } = campaignDraft;
  const { campaign } = appParams;
  const { budget: campaignBudget, status: campaignStatus } = campaign;

  const disabled = campaignStatus === 'completed' || selfServiceDisabled;

  let recurBudget = 70;
  let recurWindow = 7;

  if (recurrenceBudget) recurBudget = recurrenceBudget;
  if (recurrenceWindow) recurWindow = recurrenceWindow;

  const [showCredits, setShowCredits] = useState(false);

  const onShow = () => {
    setShowCredits(!showCredits);
  };

  const onRecurToggle = () => {
    const dailyBudget = recurBudget / recurWindow;
    const recurringBudget = dailyBudget * recurWindow;
    const recurringDate = getEndDate(recurWindow, lastRecurredAt, startDate, endDate);

    setCampaignDraft({
      ...campaignDraft,
      isRecurring: !isRecurring,
      recurrenceBudget: recurBudget,
      recurrenceWindow: recurWindow,
      endDate: recurringDate,
      budget: campaignStatus !== 'draft' && campaignBudget && campaignBudget !== 0 ? campaignBudget : recurringBudget,
    });
  };

  const recurringEnabled = Boolean(paymentMethods.length);

  return (
    <div>
      <ToggleRecurring disabled={disabled || !recurringEnabled} isRecurring={isRecurring} onToggle={onRecurToggle} />
      {!recurringEnabled && !selfServiceDisabled && <PaymentMethodRequiredMessage onShow={onShow} />}
      {selfServiceDisabled && <SelfServiceDisabledMessage />}
      {isRecurring && paymentMethods.length > 0 && (
        <>
          <Recurring
            setCampaignDraft={setCampaignDraft}
            campaignDraft={campaignDraft}
            appParams={appParams}
            campaignBudget={campaignBudget}
            campaignStatus={campaignStatus}
            availCredits={availCredits}
          />
          <div className="my-4 pt-2">
            <button type="button" onClick={onShow} className="bz-btn-solid w-96 hover:opacity-95">
              {showCredits ? 'Hide' : 'Show'} Saved Payment Method
            </button>
          </div>
        </>
      )}

      {showCredits && (
        <div className="pt-2">
          <BrandSettingsCredits appParams={appParams} showPrimary={false} />
        </div>
      )}
    </div>
  );
};

const ToggleRecurring = ({ disabled, isRecurring, onToggle }) => (
  <ToggleButtonV2 disabled={disabled} value={isRecurring} onChange={onToggle} label="Recurring" />
);

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const Recurring = ({ campaignDraft, setCampaignDraft, campaignStatus, campaignBudget, availCredits }) => {
  const validationError = useCampaignDraftSchemaValidator(campaignDraft);

  const { lastRecurredAt, startDate, endDate } = campaignDraft;

  const disabled = campaignStatus === 'completed';

  const handleRecurrenceChange = useCallback(
    recurrenceWindow => {
      if (disabled) {
        return;
      }
      const recurringDate = getEndDate(recurrenceWindow, lastRecurredAt, startDate, endDate);

      setCampaignDraft({
        ...campaignDraft,
        endDate: recurringDate,
        recurrenceWindow,
      });
    },
    [campaignDraft, disabled, endDate, lastRecurredAt, setCampaignDraft, startDate]
  );

  const handleRecurringBudgetChange = useCallback(
    e => {
      if (disabled) {
        return;
      }

      const parsed = parseFloat(e.target.value);
      const recurrenceBudget = Number.isFinite(parsed) ? parsed : null;

      setCampaignDraft({
        ...campaignDraft,
        recurrenceBudget,
        budget: campaignStatus !== 'draft' && campaignBudget ? campaignBudget : recurrenceBudget,
      });
    },
    [campaignBudget, campaignDraft, campaignStatus, disabled, setCampaignDraft]
  );

  const d = new Date(startDate);
  const day = daysOfWeek[d.getDay(startDate)];

  return (
    <div className="w-full h-full flex flex-col space-y-6">
      <h2>
        <span className="text-lg font-semibold pr-2">Recurring Budget</span>
        <span className="text-lg text-primary">(Available Credits: ${availCredits})</span>
      </h2>
      <RecurrenceSelector
        value={campaignDraft.recurrenceWindow}
        onChange={handleRecurrenceChange}
        disabled={disabled}
      />
      <div className="my-4 flex flex-col space-y-4">
        <h3 className="text-gray-800 font-medium flex-grow-0">
          {OPTIONS.find(({ value }) => value === campaignDraft.recurrenceWindow)?.label} Budget
        </h3>
        <div className="text-sm text-gray-500 flex flex-row space-x-1">
          <span>Your recurring campaign will repeat every</span>
          {campaignDraft.recurrenceWindow === 7 ? (
            <span className="text-black font-semibold">{day}</span>
          ) : (
            <span className="text-black font-semibold">30 days</span>
          )}
          <span>until you stop it.</span>
        </div>
        <CurrencyInput
          onChange={handleRecurringBudgetChange}
          value={campaignDraft.recurrenceBudget}
          error={validationError}
          disabled={disabled}
        />
        <div className="flex flex-row space-x-4 items-center">
          {validationError && <ErrorBox>{validationError.message}</ErrorBox>}
        </div>
      </div>
    </div>
  );
};
