export const schema = {
    "models": {
        "User": {
            "name": "User",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "cognitoId": {
                    "name": "cognitoId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "userId": {
                    "name": "userId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "authGroup": {
                    "name": "authGroup",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "selectedAuth": {
                    "name": "selectedAuth",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "firstName": {
                    "name": "firstName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "lastName": {
                    "name": "lastName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "profileImageUrl": {
                    "name": "profileImageUrl",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "hasBeenWelcomed": {
                    "name": "hasBeenWelcomed",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "email": {
                    "name": "email",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "authRoles": {
                    "name": "authRoles",
                    "isArray": true,
                    "type": {
                        "model": "UserAuthRole"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "userId"
                    }
                }
            },
            "syncable": true,
            "pluralName": "Users",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": {
                            "level": "off"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byEmail",
                        "queryField": "listUserByEmail",
                        "fields": [
                            "email"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "cognitoId",
                                "allow": "owner",
                                "identityClaim": "cognito:username",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "authGroup",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Admin",
                                    "Media",
                                    "Finance"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "UserAuthRole": {
            "name": "UserAuthRole",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "adminGroups": {
                    "name": "adminGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "viewerGroups": {
                    "name": "viewerGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "UserAuthRoleStatus"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "email": {
                    "name": "email",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "userId": {
                    "name": "userId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "authRole": {
                    "name": "authRole",
                    "isArray": false,
                    "type": {
                        "enum": "AuthRole"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "staticAuthRole": {
                    "name": "staticAuthRole",
                    "isArray": false,
                    "type": {
                        "enum": "StaticAuthRole"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "organizationId": {
                    "name": "organizationId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "organization": {
                    "name": "organization",
                    "isArray": false,
                    "type": {
                        "model": "Organization"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "organizationId"
                    }
                },
                "teamId": {
                    "name": "teamId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "team": {
                    "name": "team",
                    "isArray": false,
                    "type": {
                        "model": "Team"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "teamId"
                    }
                },
                "brandId": {
                    "name": "brandId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "brand": {
                    "name": "brand",
                    "isArray": false,
                    "type": {
                        "model": "Brand"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "HAS_ONE",
                        "associatedWith": "id",
                        "targetName": "brandId"
                    }
                },
                "inviteCode": {
                    "name": "inviteCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "UserAuthRoles",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": {
                            "level": "off"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byUser",
                        "fields": [
                            "userId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byOrganization",
                        "fields": [
                            "organizationId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTeam",
                        "fields": [
                            "teamId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byBrand",
                        "fields": [
                            "brandId"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byInviteCode",
                        "queryField": "userAuthRoleByInviteCode",
                        "fields": [
                            "inviteCode"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Admin",
                                    "Media",
                                    "Sales",
                                    "Finance"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "adminGroups",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "viewerGroups",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "organizationId",
                                "allow": "owner",
                                "identityClaim": "organizationId",
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "teamId",
                                "allow": "owner",
                                "identityClaim": "teamId",
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "brandId",
                                "allow": "owner",
                                "identityClaim": "brandId",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Organization": {
            "name": "Organization",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "adminGroups": {
                    "name": "adminGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "viewerGroups": {
                    "name": "viewerGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "memberGroups": {
                    "name": "memberGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "logoUrl": {
                    "name": "logoUrl",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "accountDomain": {
                    "name": "accountDomain",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "accountSubdomain": {
                    "name": "accountSubdomain",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "accountInfo": {
                    "name": "accountInfo",
                    "isArray": false,
                    "type": {
                        "nonModel": "AccountInfo"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "theme": {
                    "name": "theme",
                    "isArray": false,
                    "type": {
                        "nonModel": "Theme"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "accountConfig": {
                    "name": "accountConfig",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "config": {
                    "name": "config",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "accountSettings": {
                    "name": "accountSettings",
                    "isArray": false,
                    "type": {
                        "nonModel": "AccountSettings"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "availableCredits": {
                    "name": "availableCredits",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "paymentMethods": {
                    "name": "paymentMethods",
                    "isArray": false,
                    "type": {
                        "nonModel": "PaymentMethods"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "oMar": {
                    "name": "oMar",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "markups": {
                    "name": "markups",
                    "isArray": false,
                    "type": {
                        "nonModel": "Markups"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "defaultTeamMarkup": {
                    "name": "defaultTeamMarkup",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "summaryMetrics": {
                    "name": "summaryMetrics",
                    "isArray": false,
                    "type": {
                        "nonModel": "SummaryMetricsConnection"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "segmentPackages": {
                    "name": "segmentPackages",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "teams": {
                    "name": "teams",
                    "isArray": true,
                    "type": {
                        "model": "Team"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "organizationTeamsId"
                    }
                }
            },
            "syncable": true,
            "pluralName": "Organizations",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": {
                            "level": "off"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byAccountDomain",
                        "queryField": "organizationsByAccountDomain",
                        "fields": [
                            "accountDomain",
                            "accountSubdomain"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Admin",
                                    "Media",
                                    "Finance"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "adminGroups",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "viewerGroups",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "memberGroups",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "id",
                                "allow": "owner",
                                "identityClaim": "orgMemberId",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Team": {
            "name": "Team",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "adminGroups": {
                    "name": "adminGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "viewerGroups": {
                    "name": "viewerGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "memberGroups": {
                    "name": "memberGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "organizationId": {
                    "name": "organizationId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "organization": {
                    "name": "organization",
                    "isArray": false,
                    "type": {
                        "model": "Organization"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "organizationTeamsId"
                    }
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "websiteUrl": {
                    "name": "websiteUrl",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "logoUrl": {
                    "name": "logoUrl",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "industryCategoryId": {
                    "name": "industryCategoryId",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "config": {
                    "name": "config",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "availableCredits": {
                    "name": "availableCredits",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "paymentMethods": {
                    "name": "paymentMethods",
                    "isArray": false,
                    "type": {
                        "nonModel": "PaymentMethods"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "tMar": {
                    "name": "tMar",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "markups": {
                    "name": "markups",
                    "isArray": false,
                    "type": {
                        "nonModel": "Markups"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "defaultBrandMarkup": {
                    "name": "defaultBrandMarkup",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "theme": {
                    "name": "theme",
                    "isArray": false,
                    "type": {
                        "nonModel": "Theme"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "summaryMetrics": {
                    "name": "summaryMetrics",
                    "isArray": false,
                    "type": {
                        "nonModel": "SummaryMetricsConnection"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "segmentPackages": {
                    "name": "segmentPackages",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "brands": {
                    "name": "brands",
                    "isArray": true,
                    "type": {
                        "model": "Brand"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "teamBrandsId"
                    }
                }
            },
            "syncable": true,
            "pluralName": "Teams",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": {
                            "level": "off"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byOrganization",
                        "fields": [
                            "organizationId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Admin",
                                    "Media",
                                    "Finance"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "adminGroups",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "viewerGroups",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "memberGroups",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "id",
                                "allow": "owner",
                                "identityClaim": "teamMemberId",
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "organizationId",
                                "allow": "owner",
                                "identityClaim": "organizationId",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Brand": {
            "name": "Brand",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "adminGroups": {
                    "name": "adminGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "viewerGroups": {
                    "name": "viewerGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "organizationId": {
                    "name": "organizationId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "teamId": {
                    "name": "teamId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "team": {
                    "name": "team",
                    "isArray": false,
                    "type": {
                        "model": "Team"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "teamBrandsId"
                    }
                },
                "advertiserId": {
                    "name": "advertiserId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "websiteUrl": {
                    "name": "websiteUrl",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "logoUrl": {
                    "name": "logoUrl",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "industryCategoryId": {
                    "name": "industryCategoryId",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "politicalSettings": {
                    "name": "politicalSettings",
                    "isArray": false,
                    "type": {
                        "nonModel": "PoliticalSettings"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "config": {
                    "name": "config",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "availableCredits": {
                    "name": "availableCredits",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "paymentMethods": {
                    "name": "paymentMethods",
                    "isArray": false,
                    "type": {
                        "nonModel": "PaymentMethods"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "bMar": {
                    "name": "bMar",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "markups": {
                    "name": "markups",
                    "isArray": false,
                    "type": {
                        "nonModel": "Markups"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "universalPixel": {
                    "name": "universalPixel",
                    "isArray": false,
                    "type": {
                        "nonModel": "UniversalPixel"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "pixelCode": {
                    "name": "pixelCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "pixelTracking": {
                    "name": "pixelTracking",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "pixelAudienceSize": {
                    "name": "pixelAudienceSize",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "pixelMappingSizes": {
                    "name": "pixelMappingSizes",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "theme": {
                    "name": "theme",
                    "isArray": false,
                    "type": {
                        "nonModel": "Theme"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "summaryMetrics": {
                    "name": "summaryMetrics",
                    "isArray": false,
                    "type": {
                        "nonModel": "SummaryMetricsConnection"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "segmentPackages": {
                    "name": "segmentPackages",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "campaigns": {
                    "name": "campaigns",
                    "isArray": true,
                    "type": {
                        "model": "Campaign"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "brandCampaignsId"
                    }
                },
                "trackingTags": {
                    "name": "trackingTags",
                    "isArray": true,
                    "type": {
                        "model": "TrackingTag"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "brandTrackingTagsId"
                    }
                },
                "isValid": {
                    "name": "isValid",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "error": {
                    "name": "error",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Brands",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": {
                            "level": "off"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byTeam",
                        "fields": [
                            "teamId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Admin",
                                    "Media",
                                    "Finance"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "adminGroups",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "viewerGroups",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "id",
                                "allow": "owner",
                                "identityClaim": "brandId",
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "organizationId",
                                "allow": "owner",
                                "identityClaim": "organizationId",
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "teamId",
                                "allow": "owner",
                                "identityClaim": "teamId",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Campaign": {
            "name": "Campaign",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "adminGroups": {
                    "name": "adminGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "viewerGroups": {
                    "name": "viewerGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "stoppedAt": {
                    "name": "stoppedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "lastRecurredAt": {
                    "name": "lastRecurredAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "organizationId": {
                    "name": "organizationId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "teamId": {
                    "name": "teamId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "brandId": {
                    "name": "brandId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "brand": {
                    "name": "brand",
                    "isArray": false,
                    "type": {
                        "model": "Brand"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "brandCampaignsId"
                    }
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "CampaignStatus"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "recurrenceWindow": {
                    "name": "recurrenceWindow",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "recurrenceBudget": {
                    "name": "recurrenceBudget",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "goal": {
                    "name": "goal",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "budget": {
                    "name": "budget",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "originalBudget": {
                    "name": "originalBudget",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "startDate": {
                    "name": "startDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "endDate": {
                    "name": "endDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "originalEndDate": {
                    "name": "originalEndDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "audienceType": {
                    "name": "audienceType",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "audienceFrom": {
                    "name": "audienceFrom",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "retargetingTags": {
                    "name": "retargetingTags",
                    "isArray": true,
                    "type": {
                        "nonModel": "RetargetingTag"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "geoSegments": {
                    "name": "geoSegments",
                    "isArray": true,
                    "type": {
                        "nonModel": "CampaignSegment"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "dmpSegments": {
                    "name": "dmpSegments",
                    "isArray": true,
                    "type": {
                        "nonModel": "CampaignSegment"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "inventorySegments": {
                    "name": "inventorySegments",
                    "isArray": true,
                    "type": {
                        "nonModel": "CampaignSegment"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "timesOfDay": {
                    "name": "timesOfDay",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "daysOfWeek": {
                    "name": "daysOfWeek",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "creatives": {
                    "name": "creatives",
                    "isArray": true,
                    "type": {
                        "model": "Creative"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": "campaignCreativesId"
                    }
                },
                "forecastRequestedAt": {
                    "name": "forecastRequestedAt",
                    "isArray": false,
                    "type": "AWSTimestamp",
                    "isRequired": false,
                    "attributes": []
                },
                "publishedAt": {
                    "name": "publishedAt",
                    "isArray": false,
                    "type": "AWSTimestamp",
                    "isRequired": false,
                    "attributes": []
                },
                "forecast": {
                    "name": "forecast",
                    "isArray": false,
                    "type": {
                        "nonModel": "Forecast"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "ttdId": {
                    "name": "ttdId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "conversionsEnabled": {
                    "name": "conversionsEnabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "trackingTagId": {
                    "name": "trackingTagId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "pixelTrackingUrl": {
                    "name": "pixelTrackingUrl",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ttdStatus": {
                    "name": "ttdStatus",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "dspIds": {
                    "name": "dspIds",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "markups": {
                    "name": "markups",
                    "isArray": false,
                    "type": {
                        "nonModel": "Markups"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "summaryMetrics": {
                    "name": "summaryMetrics",
                    "isArray": false,
                    "type": {
                        "nonModel": "SummaryMetricsConnection"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "ttdUpdatedAt": {
                    "name": "ttdUpdatedAt",
                    "isArray": false,
                    "type": "AWSTimestamp",
                    "isRequired": false,
                    "attributes": []
                },
                "activeUpdatedAt": {
                    "name": "activeUpdatedAt",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "sfOpportunityId": {
                    "name": "sfOpportunityId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "isRecurring": {
                    "name": "isRecurring",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "isValid": {
                    "name": "isValid",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "error": {
                    "name": "error",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Campaigns",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": {
                            "level": "off"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byBrand",
                        "fields": [
                            "brandId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Admin",
                                    "Media",
                                    "Finance"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "adminGroups",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "viewerGroups",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "organizationId",
                                "allow": "owner",
                                "identityClaim": "organizationId",
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "teamId",
                                "allow": "owner",
                                "identityClaim": "teamId",
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "brandId",
                                "allow": "owner",
                                "identityClaim": "brandId",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Creative": {
            "name": "Creative",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "uploadedAt": {
                    "name": "uploadedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "publishedAt": {
                    "name": "publishedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "adminGroups": {
                    "name": "adminGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "viewerGroups": {
                    "name": "viewerGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "organizationId": {
                    "name": "organizationId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "teamBrandCampaign": {
                    "name": "teamBrandCampaign",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "teamId": {
                    "name": "teamId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "brandId": {
                    "name": "brandId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "campaignId": {
                    "name": "campaignId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "campaign": {
                    "name": "campaign",
                    "isArray": false,
                    "type": {
                        "model": "Campaign"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "campaignCreativesId"
                    }
                },
                "teamBrandCampaignId": {
                    "name": "teamBrandCampaignId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "fileName": {
                    "name": "fileName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "creativeType": {
                    "name": "creativeType",
                    "isArray": false,
                    "type": {
                        "enum": "CreativeType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "s3Key": {
                    "name": "s3Key",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "s3ThumbnailKey": {
                    "name": "s3ThumbnailKey",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "clickthroughUrl": {
                    "name": "clickthroughUrl",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "landingPageUrl": {
                    "name": "landingPageUrl",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "inApp": {
                    "name": "inApp",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "web": {
                    "name": "web",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "ctv": {
                    "name": "ctv",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "devices": {
                    "name": "devices",
                    "isArray": true,
                    "type": {
                        "enum": "CreativeDevice"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "dspIds": {
                    "name": "dspIds",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "ttdId": {
                    "name": "ttdId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ttdStatus": {
                    "name": "ttdStatus",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "isValid": {
                    "name": "isValid",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "error": {
                    "name": "error",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Creatives",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": {
                            "level": "off"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byCampaign",
                        "fields": [
                            "campaignId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Admin",
                                    "Media",
                                    "Finance"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "adminGroups",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "viewerGroups",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "organizationId",
                                "allow": "owner",
                                "identityClaim": "organizationId",
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "teamId",
                                "allow": "owner",
                                "identityClaim": "teamId",
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "brandId",
                                "allow": "owner",
                                "identityClaim": "brandId",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Credit": {
            "name": "Credit",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "adminGroups": {
                    "name": "adminGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "viewerGroups": {
                    "name": "viewerGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "userId": {
                    "name": "userId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "organizationId": {
                    "name": "organizationId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "teamId": {
                    "name": "teamId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "brandId": {
                    "name": "brandId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "campaignId": {
                    "name": "campaignId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "chargeType": {
                    "name": "chargeType",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "chargeId": {
                    "name": "chargeId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "amountIntended": {
                    "name": "amountIntended",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "amount": {
                    "name": "amount",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "metadata": {
                    "name": "metadata",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                }
            },
            "syncable": true,
            "pluralName": "Credits",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": {
                            "level": "off"
                        }
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byOrganizationTeamBrandCampaign",
                        "queryField": "creditsByOrganizationTeamBrandCampaign",
                        "fields": [
                            "organizationId",
                            "teamId",
                            "brandId",
                            "campaignId",
                            "createdAt"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byChargeId",
                        "queryField": "creditsByChargeId",
                        "fields": [
                            "chargeId"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Admin",
                                    "Media",
                                    "Sales",
                                    "Finance"
                                ],
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "adminGroups",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "organizationId",
                                "allow": "owner",
                                "identityClaim": "organizationId",
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "teamId",
                                "allow": "owner",
                                "identityClaim": "teamId",
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "brandId",
                                "allow": "owner",
                                "identityClaim": "brandId",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "TrackingTag": {
            "name": "TrackingTag",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "archived": {
                    "name": "archived",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "trackingTagType": {
                    "name": "trackingTagType",
                    "isArray": false,
                    "type": {
                        "enum": "TrackingTagType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "adminGroups": {
                    "name": "adminGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "viewerGroups": {
                    "name": "viewerGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "organizationId": {
                    "name": "organizationId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "teamId": {
                    "name": "teamId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "brandId": {
                    "name": "brandId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "dspIds": {
                    "name": "dspIds",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ttdId": {
                    "name": "ttdId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "pixelCode": {
                    "name": "pixelCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "pixelAudienceSize": {
                    "name": "pixelAudienceSize",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "brand": {
                    "name": "brand",
                    "isArray": false,
                    "type": {
                        "model": "Brand"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "association": {
                        "connectionType": "BELONGS_TO",
                        "targetName": "brandTrackingTagsId"
                    }
                }
            },
            "syncable": true,
            "pluralName": "TrackingTags",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": {
                            "level": "off"
                        }
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Admin",
                                    "Media",
                                    "Sales",
                                    "Finance"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "adminGroups",
                                "groupField": "groups",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groupsField": "viewerGroups",
                                "operations": [
                                    "read"
                                ],
                                "groupField": "groups"
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "organizationId",
                                "allow": "owner",
                                "identityClaim": "organizationId",
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "teamId",
                                "allow": "owner",
                                "identityClaim": "teamId",
                                "operations": [
                                    "read"
                                ]
                            },
                            {
                                "provider": "userPools",
                                "ownerField": "brandId",
                                "allow": "owner",
                                "identityClaim": "brandId",
                                "operations": [
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "FeatureFlag": {
            "name": "FeatureFlag",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "value": {
                    "name": "value",
                    "isArray": false,
                    "type": {
                        "enum": "FeatureFlagValue"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "internal": {
                    "name": "internal",
                    "isArray": false,
                    "type": {
                        "nonModel": "FeatureFlagBrandzookaOverrides"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "staticAuthRoles": {
                    "name": "staticAuthRoles",
                    "isArray": true,
                    "type": {
                        "nonModel": "FeatureFlagStaticAuthRoleOverride"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "FeatureFlags",
            "attributes": [
                {
                    "type": "model",
                    "properties": {
                        "subscriptions": null
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "private",
                                "provider": "iam",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            },
                            {
                                "groupClaim": "cognito:groups",
                                "provider": "userPools",
                                "allow": "groups",
                                "groups": [
                                    "Admin"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        }
    },
    "enums": {
        "SummaryType": {
            "name": "SummaryType",
            "values": [
                "ALL_TIME",
                "THIS_MONTH"
            ]
        },
        "DaSegmentType": {
            "name": "DaSegmentType",
            "values": [
                "AUDIENCE",
                "DMP",
                "GEO",
                "INVENTORY"
            ]
        },
        "DaDspSegmentType": {
            "name": "DaDspSegmentType",
            "values": [
                "TTD_DMP",
                "TTD_GEO",
                "TTD_INVENTORY"
            ]
        },
        "SearchableSortDirection": {
            "name": "SearchableSortDirection",
            "values": [
                "asc",
                "desc"
            ]
        },
        "SearchableSegmentSortableFields": {
            "name": "SearchableSegmentSortableFields",
            "values": [
                "id",
                "createdAt",
                "updatedAt",
                "segmentGroup",
                "segmentPackageName",
                "segmentPackageId",
                "pathKey",
                "name",
                "_version",
                "_deleted",
                "_lastChangedAt"
            ]
        },
        "SearchableAggregateType": {
            "name": "SearchableAggregateType",
            "values": [
                "terms",
                "avg",
                "min",
                "max",
                "sum"
            ]
        },
        "SearchableSegmentAggregateField": {
            "name": "SearchableSegmentAggregateField",
            "values": [
                "id",
                "createdAt",
                "updatedAt",
                "segmentType",
                "segmentGroup",
                "segmentPackageName",
                "segmentPackageId",
                "pathKey",
                "name"
            ]
        },
        "DaSearchableMetricsSortableFields": {
            "name": "DaSearchableMetricsSortableFields",
            "values": [
                "id",
                "adminGroups",
                "viewerGroups",
                "createdAt",
                "updatedAt",
                "organizationId",
                "teamId",
                "brandId",
                "campaignId",
                "oMar",
                "tMar",
                "bMar",
                "metricKey",
                "clicks",
                "views",
                "impressions",
                "conversions",
                "spend"
            ]
        },
        "DaSearchableMetricsAggregateField": {
            "name": "DaSearchableMetricsAggregateField",
            "values": [
                "id",
                "adminGroups",
                "viewerGroups",
                "createdAt",
                "updatedAt",
                "organizationId",
                "teamId",
                "brandId",
                "campaignId",
                "oMar",
                "tMar",
                "bMar",
                "metricType",
                "metricKey",
                "clicks",
                "views",
                "impressions",
                "conversions",
                "spend"
            ]
        },
        "GeoLocationType": {
            "name": "GeoLocationType",
            "values": [
                "Country",
                "Region",
                "City",
                "Zip",
                "Metro",
                "Custom"
            ]
        },
        "CustomCategoryMatchType": {
            "name": "CustomCategoryMatchType",
            "values": [
                "ExactMatch",
                "ExactRequired",
                "ExactAvoid"
            ]
        },
        "CustomCategoryMatchApp": {
            "name": "CustomCategoryMatchApp",
            "values": [
                "Exact",
                "Similar"
            ]
        },
        "TtdInsightMetricType": {
            "name": "TtdInsightMetricType",
            "values": [
                "Impressions",
                "Spend",
                "FrequencyUsers",
                "FrequencyPeople",
                "FrequencyHousehold",
                "ReachUsers",
                "ReachPeople",
                "ReachHousehold",
                "Reach",
                "Frequency",
                "OTP",
                "LinearTVReach",
                "DigitalReach",
                "UsDemographicPercentages",
                "SambaUniqueUserCount",
                "UfWithRailsNoExclusion_Avails",
                "UfWithRailsNoExclusion_PotentialBids",
                "UfWithRailsNoExclusion_Impressions",
                "UfWithRailsNoExclusion_PeopleReach",
                "UfWithRailsNoExclusion_DeviceReach",
                "UfWithRailsNoExclusion_HhReach",
                "UfWithRailsNoExclusion_Spend",
                "UfWithRailsNoExclusion_DeviceFreq",
                "UfWithRailsNoExclusion_PeopleFreq",
                "UfWithRailsNoExclusion_HhFreq",
                "UfWithRailsExcludingAcr_Avails",
                "UfWithRailsExcludingAcr_PotentialBids",
                "UfWithRailsExcludingAcr_Impressions",
                "UfWithRailsExcludingAcr_PeopleReach",
                "UfWithRailsExcludingAcr_DeviceReach",
                "UfWithRailsExcludingAcr_HhReach",
                "UfWithRailsExcludingAcr_Spend",
                "UfWithRailsExcludingAcr_DeviceFreq",
                "UfWithRailsExcludingAcr_PeopleFreq",
                "UfWithRailsExcludingAcr_HhFreq",
                "UfWithRailsIntersectsAcr_Avails",
                "UfWithRailsIntersectsAcr_PotentialBids",
                "UfWithRailsIntersectsAcr_Impressions",
                "UfWithRailsIntersectsAcr_PeopleReach",
                "UfWithRailsIntersectsAcr_DeviceReach",
                "UfWithRailsIntersectsAcr_HhReach",
                "UfWithRailsIntersectsAcr_Spend",
                "UfWithRailsIntersectsAcr_DeviceFreq",
                "UfWithRailsIntersectsAcr_PeopleFreq",
                "UfWithRailsIntersectsAcr_HhFreq",
                "UfWithRailsIntersectsSuperAcr_Avails",
                "UfWithRailsIntersectsSuperAcr_PotentialBids",
                "UfWithRailsIntersectsSuperAcr_Impressions",
                "UfWithRailsIntersectsSuperAcr_PeopleReach",
                "UfWithRailsIntersectsSuperAcr_DeviceReach",
                "UfWithRailsIntersectsSuperAcr_HhReach",
                "UfWithRailsIntersectsSuperAcr_Spend",
                "UfWithRailsIntersectsSuperAcr_DeviceFreq",
                "UfWithRailsIntersectsSuperAcr_PeopleFreq",
                "UfWithRailsIntersectsSuperAcr_HhFreq"
            ]
        },
        "TtdMetricGeoLocationType": {
            "name": "TtdMetricGeoLocationType",
            "values": [
                "CustomOnly",
                "StandardOnly",
                "All",
                "Country",
                "Region",
                "City",
                "PostalCode",
                "Metro",
                "AdvertiserSpecific",
                "PartnerSpecific"
            ]
        },
        "TtdVettingStatus": {
            "name": "TtdVettingStatus",
            "values": [
                "Approved",
                "Rejected",
                "Submitted"
            ]
        },
        "AuthRole": {
            "name": "AuthRole",
            "values": [
                "admin",
                "editor",
                "viewer",
                "member"
            ]
        },
        "StaticAuthRole": {
            "name": "StaticAuthRole",
            "values": [
                "Admin",
                "Media",
                "Sales",
                "Finance"
            ]
        },
        "UserAuthRoleStatus": {
            "name": "UserAuthRoleStatus",
            "values": [
                "owner",
                "pending",
                "invited",
                "accepted",
                "declined",
                "revoked"
            ]
        },
        "AccountType": {
            "name": "AccountType",
            "values": [
                "inactive",
                "brand",
                "team",
                "organization"
            ]
        },
        "AccountTier": {
            "name": "AccountTier",
            "values": [
                "brandzooka",
                "brandzookaPlus",
                "brandzookaEnterprise"
            ]
        },
        "OnboardingStatus": {
            "name": "OnboardingStatus",
            "values": [
                "uninitialized",
                "accountSetup",
                "companySetup",
                "tour",
                "complete"
            ]
        },
        "ElectionType": {
            "name": "ElectionType",
            "values": [
                "ballotMeasure",
                "candidateElection"
            ]
        },
        "PoliticalType": {
            "name": "PoliticalType",
            "values": [
                "federal",
                "stateOrLocal"
            ]
        },
        "AudienceType": {
            "name": "AudienceType",
            "values": [
                "everyone",
                "firstParty",
                "thirdParty",
                "retargeting"
            ]
        },
        "CampaignStatus": {
            "name": "CampaignStatus",
            "values": [
                "draft",
                "forecast",
                "approved",
                "launching",
                "updating",
                "running",
                "stopping",
                "stopped",
                "pausing",
                "paused",
                "completed",
                "archived",
                "error"
            ]
        },
        "CreativeDevice": {
            "name": "CreativeDevice",
            "values": [
                "ctv",
                "pc",
                "mobile",
                "tablet"
            ]
        },
        "CreativeType": {
            "name": "CreativeType",
            "values": [
                "display",
                "video",
                "audio"
            ]
        },
        "ChargeType": {
            "name": "ChargeType",
            "values": [
                "stripeCredit",
                "stripeRefund",
                "transferTo",
                "transferFrom"
            ]
        },
        "CreditType": {
            "name": "CreditType",
            "values": [
                "organization",
                "team",
                "brand",
                "campaign"
            ]
        },
        "MetricType": {
            "name": "MetricType",
            "values": [
                "ORGANIZATION_DAILY",
                "TEAM_DAILY",
                "BRAND_DAILY",
                "CAMPAIGN_DAILY",
                "CAMPAIGN_TIME_OF_DAY",
                "CAMPAIGN_DAY_OF_WEEK",
                "CAMPAIGN_TOP_CITIES",
                "CAMPAIGN_TOP_SITES",
                "CAMPAIGN_DEVICES",
                "CAMPAIGN_AD_NETWORK",
                "CAMPAIGN_CREATIVES",
                "CAMPAIGN_HEATMAP"
            ]
        },
        "CampaignAction": {
            "name": "CampaignAction",
            "values": [
                "pause",
                "stop",
                "resume",
                "runForecast",
                "runUpdate"
            ]
        },
        "TrackingTagType": {
            "name": "TrackingTagType",
            "values": [
                "image",
                "firstParty",
                "custom"
            ]
        },
        "FeatureFlagValue": {
            "name": "FeatureFlagValue",
            "values": [
                "TRUE",
                "FALSE"
            ]
        }
    },
    "nonModels": {
        "SummaryMetricsGraph": {
            "name": "SummaryMetricsGraph",
            "fields": {
                "impressions": {
                    "name": "impressions",
                    "isArray": true,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "clicks": {
                    "name": "clicks",
                    "isArray": true,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "views": {
                    "name": "views",
                    "isArray": true,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "spend": {
                    "name": "spend",
                    "isArray": true,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "conversions": {
                    "name": "conversions",
                    "isArray": true,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        },
        "SummaryMetrics": {
            "name": "SummaryMetrics",
            "fields": {
                "summaryType": {
                    "name": "summaryType",
                    "isArray": false,
                    "type": {
                        "enum": "SummaryType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "impressions": {
                    "name": "impressions",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "clicks": {
                    "name": "clicks",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "views": {
                    "name": "views",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "spend": {
                    "name": "spend",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "conversions": {
                    "name": "conversions",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "graphs": {
                    "name": "graphs",
                    "isArray": false,
                    "type": {
                        "nonModel": "SummaryMetricsGraph"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "SummaryMetricsConnection": {
            "name": "SummaryMetricsConnection",
            "fields": {
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "nonModel": "SummaryMetrics"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "total": {
                    "name": "total",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ProtectedFields": {
            "name": "ProtectedFields",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "config": {
                    "name": "config",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "accountConfig": {
                    "name": "accountConfig",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "accountSettings": {
                    "name": "accountSettings",
                    "isArray": false,
                    "type": "AWSJSON",
                    "isRequired": false,
                    "attributes": []
                },
                "paymentMethods": {
                    "name": "paymentMethods",
                    "isArray": false,
                    "type": {
                        "nonModel": "PaymentMethods"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "oMar": {
                    "name": "oMar",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "tMar": {
                    "name": "tMar",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "bMar": {
                    "name": "bMar",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "DaSegment": {
            "name": "DaSegment",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "segmentType": {
                    "name": "segmentType",
                    "isArray": false,
                    "type": {
                        "enum": "DaSegmentType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "segmentGroup": {
                    "name": "segmentGroup",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "segmentPackageName": {
                    "name": "segmentPackageName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "segmentPackageId": {
                    "name": "segmentPackageId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "pathKey": {
                    "name": "pathKey",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "dspSegments": {
                    "name": "dspSegments",
                    "isArray": false,
                    "type": {
                        "nonModel": "DaSegmentDspSegmentConnection"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "geoJson": {
                    "name": "geoJson",
                    "isArray": false,
                    "type": {
                        "nonModel": "GeoJsonMultiPoly"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "DaSegmentDspSegment": {
            "name": "DaSegmentDspSegment",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "dspSegmentType": {
                    "name": "dspSegmentType",
                    "isArray": false,
                    "type": {
                        "enum": "DaDspSegmentType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "dspSegmentGroup": {
                    "name": "dspSegmentGroup",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "dspSegmentId": {
                    "name": "dspSegmentId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "pathKey": {
                    "name": "pathKey",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "DaGeoAggItem": {
            "name": "DaGeoAggItem",
            "fields": {
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "impressions": {
                    "name": "impressions",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "clicks": {
                    "name": "clicks",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "views": {
                    "name": "views",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "spend": {
                    "name": "spend",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "conversions": {
                    "name": "conversions",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "GeoJsonMultiPoly": {
            "name": "GeoJsonMultiPoly",
            "fields": {
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "coordinates": {
                    "name": "coordinates",
                    "isArray": true,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                }
            }
        },
        "SearchableAggregateGenericResult": {
            "name": "SearchableAggregateGenericResult",
            "fields": {
                "buckets": {
                    "name": "buckets",
                    "isArray": true,
                    "type": {
                        "nonModel": "SearchableAggregateBucketResultItem"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "value": {
                    "name": "value",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "SearchableAggregateResult": {
            "name": "SearchableAggregateResult",
            "fields": {
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "result": {
                    "name": "result",
                    "isArray": false,
                    "type": {
                        "nonModel": "SearchableAggregateGenericResult"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "SearchableAggregateBucketResultItem": {
            "name": "SearchableAggregateBucketResultItem",
            "fields": {
                "key": {
                    "name": "key",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "doc_count": {
                    "name": "doc_count",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "SearchableBucketAggItem": {
            "name": "SearchableBucketAggItem",
            "fields": {
                "key": {
                    "name": "key",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "doc_count": {
                    "name": "doc_count",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "DaSegmentDspSegmentConnection": {
            "name": "DaSegmentDspSegmentConnection",
            "fields": {
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "nonModel": "DaSegmentDspSegment"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "nextToken": {
                    "name": "nextToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "SearchableSegmentSegmentPathConnection": {
            "name": "SearchableSegmentSegmentPathConnection",
            "fields": {
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "nonModel": "SearchableBucketAggItem"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "nextToken": {
                    "name": "nextToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "SearchableSegmentConnection": {
            "name": "SearchableSegmentConnection",
            "fields": {
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "nonModel": "DaSegment"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "nextToken": {
                    "name": "nextToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "total": {
                    "name": "total",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "aggregateItems": {
                    "name": "aggregateItems",
                    "isArray": true,
                    "type": {
                        "nonModel": "SearchableAggregateResult"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                }
            }
        },
        "GeoJsonPoint": {
            "name": "GeoJsonPoint",
            "fields": {
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "coordinates": {
                    "name": "coordinates",
                    "isArray": true,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                }
            }
        },
        "DaMetrics": {
            "name": "DaMetrics",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "adminGroups": {
                    "name": "adminGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "viewerGroups": {
                    "name": "viewerGroups",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "organizationId": {
                    "name": "organizationId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "teamId": {
                    "name": "teamId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "brandId": {
                    "name": "brandId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "campaignId": {
                    "name": "campaignId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "oMar": {
                    "name": "oMar",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "tMar": {
                    "name": "tMar",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "bMar": {
                    "name": "bMar",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "metricType": {
                    "name": "metricType",
                    "isArray": false,
                    "type": {
                        "enum": "MetricType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "metricKey": {
                    "name": "metricKey",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "clicks": {
                    "name": "clicks",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "views": {
                    "name": "views",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "impressions": {
                    "name": "impressions",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "conversions": {
                    "name": "conversions",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "spend": {
                    "name": "spend",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "geoJson": {
                    "name": "geoJson",
                    "isArray": false,
                    "type": {
                        "nonModel": "GeoJsonPoint"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "DaSearchableMetricsConnection": {
            "name": "DaSearchableMetricsConnection",
            "fields": {
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "nonModel": "DaMetrics"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "nextToken": {
                    "name": "nextToken",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "total": {
                    "name": "total",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "aggregateItems": {
                    "name": "aggregateItems",
                    "isArray": true,
                    "type": {
                        "nonModel": "SearchableAggregateResult"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": false
                }
            }
        },
        "DaGeoAggItemConnection": {
            "name": "DaGeoAggItemConnection",
            "fields": {
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "nonModel": "DaGeoAggItem"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "total": {
                    "name": "total",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "TtdCpmRate": {
            "name": "TtdCpmRate",
            "fields": {
                "Amount": {
                    "name": "Amount",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "CurrencyCode": {
                    "name": "CurrencyCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "TtdDmpSegment": {
            "name": "TtdDmpSegment",
            "fields": {
                "ThirdPartyDataId": {
                    "name": "ThirdPartyDataId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "BrandId": {
                    "name": "BrandId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "BrandName": {
                    "name": "BrandName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Name": {
                    "name": "Name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "FullPath": {
                    "name": "FullPath",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Description": {
                    "name": "Description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "DevicesBrowsers30DayCount": {
                    "name": "DevicesBrowsers30DayCount",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "UniqueUserCount": {
                    "name": "UniqueUserCount",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "UniqueUserInAppCount": {
                    "name": "UniqueUserInAppCount",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "UniqueUserWebCount": {
                    "name": "UniqueUserWebCount",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "UniqueConnectedTvCount": {
                    "name": "UniqueConnectedTvCount",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "CPMRate": {
                    "name": "CPMRate",
                    "isArray": false,
                    "type": {
                        "nonModel": "TtdCpmRate"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "CPMRateInAdvertiserCurrency": {
                    "name": "CPMRateInAdvertiserCurrency",
                    "isArray": false,
                    "type": {
                        "nonModel": "TtdCpmRate"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "PercentOfMediaCostRate": {
                    "name": "PercentOfMediaCostRate",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "PersonsCount": {
                    "name": "PersonsCount",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "HouseholdCount": {
                    "name": "HouseholdCount",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ReceivedIDsCount": {
                    "name": "ReceivedIDsCount",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ActiveIDsCount": {
                    "name": "ActiveIDsCount",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ActiveIDsCountExpanded": {
                    "name": "ActiveIDsCountExpanded",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ActiveIDsInAppCount": {
                    "name": "ActiveIDsInAppCount",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ActiveIDsWebCount": {
                    "name": "ActiveIDsWebCount",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ActiveIDsConnectedTvCount": {
                    "name": "ActiveIDsConnectedTvCount",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ActivePersonsCount": {
                    "name": "ActivePersonsCount",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ActiveHouseholdCount": {
                    "name": "ActiveHouseholdCount",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "TtdGeoSegment": {
            "name": "TtdGeoSegment",
            "fields": {
                "GeoSegmentId": {
                    "name": "GeoSegmentId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "GeoSegmentName": {
                    "name": "GeoSegmentName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Description": {
                    "name": "Description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "IsStandard": {
                    "name": "IsStandard",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "GeoLocationType": {
                    "name": "GeoLocationType",
                    "isArray": false,
                    "type": {
                        "enum": "GeoLocationType"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "UniversalPixelMappings": {
            "name": "UniversalPixelMappings",
            "fields": {
                "ExactMatchMappings": {
                    "name": "ExactMatchMappings",
                    "isArray": true,
                    "type": {
                        "nonModel": "ExactMatchMappings"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "WildcardMatchMappings": {
                    "name": "WildcardMatchMappings",
                    "isArray": true,
                    "type": {
                        "nonModel": "WildcardMatchMappings"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        },
        "ExactMatchMappings": {
            "name": "ExactMatchMappings",
            "fields": {
                "Url": {
                    "name": "Url",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "HouseholdExtensionEnabled": {
                    "name": "HouseholdExtensionEnabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "Revenue": {
                    "name": "Revenue",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Currency": {
                    "name": "Currency",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "TrackingTagId": {
                    "name": "TrackingTagId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "UniversalPixelMappingName": {
                    "name": "UniversalPixelMappingName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "UniversalPixelMappingType": {
                    "name": "UniversalPixelMappingType",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Description": {
                    "name": "Description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "HitCount30Day": {
                    "name": "HitCount30Day",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "FirstPartyDataId": {
                    "name": "FirstPartyDataId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "WildcardMatchMappings": {
            "name": "WildcardMatchMappings",
            "fields": {
                "UrlPattern": {
                    "name": "UrlPattern",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "HouseholdExtensionEnabled": {
                    "name": "HouseholdExtensionEnabled",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "Revenue": {
                    "name": "Revenue",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Currency": {
                    "name": "Currency",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "TrackingTagId": {
                    "name": "TrackingTagId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "UniversalPixelMappingName": {
                    "name": "UniversalPixelMappingName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "UniversalPixelMappingType": {
                    "name": "UniversalPixelMappingType",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Description": {
                    "name": "Description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "HitCount30Day": {
                    "name": "HitCount30Day",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "FirstPartyDataId": {
                    "name": "FirstPartyDataId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "UniversalPixel": {
            "name": "UniversalPixel",
            "fields": {
                "UniversalPixelMappings": {
                    "name": "UniversalPixelMappings",
                    "isArray": false,
                    "type": {
                        "nonModel": "UniversalPixelMappings"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "UniversalPixelId": {
                    "name": "UniversalPixelId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "UniversalPixelName": {
                    "name": "UniversalPixelName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "AdvertiserId": {
                    "name": "AdvertiserId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "TtdKeyword": {
            "name": "TtdKeyword",
            "fields": {
                "Value": {
                    "name": "Value",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "MatchType": {
                    "name": "MatchType",
                    "isArray": false,
                    "type": {
                        "enum": "CustomCategoryMatchType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "MatchApplication": {
                    "name": "MatchApplication",
                    "isArray": false,
                    "type": {
                        "enum": "CustomCategoryMatchApp"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "TtdCustomCategory": {
            "name": "TtdCustomCategory",
            "fields": {
                "CustomCategoryId": {
                    "name": "CustomCategoryId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "CustomCategoryName": {
                    "name": "CustomCategoryName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "CustomCategoryType": {
                    "name": "CustomCategoryType",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "CustomCategorySourceType": {
                    "name": "CustomCategorySourceType",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "TTDContextualCategoryId": {
                    "name": "TTDContextualCategoryId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "AdvertiserId": {
                    "name": "AdvertiserId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "PartnerId": {
                    "name": "PartnerId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Keywords": {
                    "name": "Keywords",
                    "isArray": true,
                    "type": {
                        "nonModel": "TtdKeyword"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "Status": {
                    "name": "Status",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "TtdMetricResult": {
            "name": "TtdMetricResult",
            "fields": {
                "InsightMetricType": {
                    "name": "InsightMetricType",
                    "isArray": false,
                    "type": {
                        "enum": "TtdInsightMetricType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "RangeStart": {
                    "name": "RangeStart",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "RangeEnd": {
                    "name": "RangeEnd",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "IsCurrency": {
                    "name": "IsCurrency",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "TtdPrivateContractMetricResult": {
            "name": "TtdPrivateContractMetricResult",
            "fields": {
                "PrivateContractId": {
                    "name": "PrivateContractId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "PrivateContractName": {
                    "name": "PrivateContractName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "FloorPrice": {
                    "name": "FloorPrice",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "LogoUrl": {
                    "name": "LogoUrl",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "IsPremium": {
                    "name": "IsPremium",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "MetricResults": {
                    "name": "MetricResults",
                    "isArray": true,
                    "type": {
                        "nonModel": "TtdMetricResult"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        },
        "TtdSubPrivateContractMetricResult": {
            "name": "TtdSubPrivateContractMetricResult",
            "fields": {
                "PrivateContractId": {
                    "name": "PrivateContractId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "PrivateContractName": {
                    "name": "PrivateContractName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "FloorPrice": {
                    "name": "FloorPrice",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "LogoUrl": {
                    "name": "LogoUrl",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "IsPremium": {
                    "name": "IsPremium",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "MetricResults": {
                    "name": "MetricResults",
                    "isArray": true,
                    "type": {
                        "nonModel": "TtdMetricResult"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        },
        "TtdPrivateContractGroupMetricResult": {
            "name": "TtdPrivateContractGroupMetricResult",
            "fields": {
                "PrivateContractGroupId": {
                    "name": "PrivateContractGroupId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "GroupMetricResults": {
                    "name": "GroupMetricResults",
                    "isArray": true,
                    "type": {
                        "nonModel": "TtdMetricResult"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "SubPrivateContractMetricResults": {
                    "name": "SubPrivateContractMetricResults",
                    "isArray": true,
                    "type": {
                        "nonModel": "TtdSubPrivateContractMetricResult"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        },
        "TtdGeoSubMetricResult": {
            "name": "TtdGeoSubMetricResult",
            "fields": {
                "GeoName": {
                    "name": "GeoName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "MetricResults": {
                    "name": "MetricResults",
                    "isArray": true,
                    "type": {
                        "nonModel": "TtdMetricResult"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        },
        "TtdGeoMetricResult": {
            "name": "TtdGeoMetricResult",
            "fields": {
                "GeoLocationType": {
                    "name": "GeoLocationType",
                    "isArray": false,
                    "type": {
                        "enum": "TtdMetricGeoLocationType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "MetricResults": {
                    "name": "MetricResults",
                    "isArray": true,
                    "type": {
                        "nonModel": "TtdGeoSubMetricResult"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        },
        "TtdPrivateContractMetricGeoResult": {
            "name": "TtdPrivateContractMetricGeoResult",
            "fields": {
                "MetricResults": {
                    "name": "MetricResults",
                    "isArray": true,
                    "type": {
                        "nonModel": "TtdGeoMetricResult"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        },
        "TtdForecastAudience": {
            "name": "TtdForecastAudience",
            "fields": {
                "AdvertiserId": {
                    "name": "AdvertiserId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "AudienceId": {
                    "name": "AudienceId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "AudienceName": {
                    "name": "AudienceName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "Description": {
                    "name": "Description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "IncludedDataGroupIds": {
                    "name": "IncludedDataGroupIds",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "ExcludedDataGroupIds": {
                    "name": "ExcludedDataGroupIds",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "LastUpdatedAtUTC": {
                    "name": "LastUpdatedAtUTC",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "IsAutoGenerated": {
                    "name": "IsAutoGenerated",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "GeoForCounts": {
                    "name": "GeoForCounts",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "UniqueUserCount": {
                    "name": "UniqueUserCount",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "WebCount": {
                    "name": "WebCount",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "InAppCount": {
                    "name": "InAppCount",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "CtvCount": {
                    "name": "CtvCount",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "PersonsCount": {
                    "name": "PersonsCount",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "HouseholdCount": {
                    "name": "HouseholdCount",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "ActiveIDsCountExpanded": {
                    "name": "ActiveIDsCountExpanded",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "SuccessfullyRetrievedCounts": {
                    "name": "SuccessfullyRetrievedCounts",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "TtdForecast": {
            "name": "TtdForecast",
            "fields": {
                "AdvertiserId": {
                    "name": "AdvertiserId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "LastCalculatedAt": {
                    "name": "LastCalculatedAt",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "TimeWindow": {
                    "name": "TimeWindow",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "MetricResults": {
                    "name": "MetricResults",
                    "isArray": true,
                    "type": {
                        "nonModel": "TtdMetricResult"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "PrivateContractMetricResults": {
                    "name": "PrivateContractMetricResults",
                    "isArray": true,
                    "type": {
                        "nonModel": "TtdPrivateContractMetricResult"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "PrivateContractGroupMetricResults": {
                    "name": "PrivateContractGroupMetricResults",
                    "isArray": true,
                    "type": {
                        "nonModel": "TtdPrivateContractMetricResult"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "PrivateContractMetricGeoResults": {
                    "name": "PrivateContractMetricGeoResults",
                    "isArray": true,
                    "type": {
                        "nonModel": "TtdPrivateContractMetricGeoResult"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "AverageBidCPMInAdvertiserCurrency": {
                    "name": "AverageBidCPMInAdvertiserCurrency",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "TargetKnownUsers": {
                    "name": "TargetKnownUsers",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "Audience": {
                    "name": "Audience",
                    "isArray": false,
                    "type": {
                        "nonModel": "TtdForecastAudience"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "BudgetMetrics": {
                    "name": "BudgetMetrics",
                    "isArray": true,
                    "type": {
                        "nonModel": "TtdMetricResult"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        },
        "TtdCandidateProfile": {
            "name": "TtdCandidateProfile",
            "fields": {
                "PoliticalDataId": {
                    "name": "PoliticalDataId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "CandidateName": {
                    "name": "CandidateName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ElectionState": {
                    "name": "ElectionState",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "PayingEntityName": {
                    "name": "PayingEntityName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "PayingEntityAddress": {
                    "name": "PayingEntityAddress",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "PayingEntityEmailAddress": {
                    "name": "PayingEntityEmailAddress",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "PayingEntityExecutiveName": {
                    "name": "PayingEntityExecutiveName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "PayingEntityExecutiveTitle": {
                    "name": "PayingEntityExecutiveTitle",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "GeoDescription": {
                    "name": "GeoDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "AudienceDescription": {
                    "name": "AudienceDescription",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "DisclaimerNotice": {
                    "name": "DisclaimerNotice",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "FileLocationInS3": {
                    "name": "FileLocationInS3",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "FederalVerificationId": {
                    "name": "FederalVerificationId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "StateVerificationId": {
                    "name": "StateVerificationId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "AccountSettings": {
            "name": "AccountSettings",
            "fields": {
                "accountType": {
                    "name": "accountType",
                    "isArray": false,
                    "type": {
                        "enum": "AccountType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "accountTier": {
                    "name": "accountTier",
                    "isArray": false,
                    "type": {
                        "enum": "AccountTier"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "onboardingStatus": {
                    "name": "onboardingStatus",
                    "isArray": false,
                    "type": {
                        "enum": "OnboardingStatus"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "teamId": {
                    "name": "teamId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "brandId": {
                    "name": "brandId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "AccountConfig": {
            "name": "AccountConfig",
            "fields": {
                "stripeCustomerId": {
                    "name": "stripeCustomerId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "AccountInfo": {
            "name": "AccountInfo",
            "fields": {
                "owner": {
                    "name": "owner",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "ownerEmail": {
                    "name": "ownerEmail",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "website": {
                    "name": "website",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "companyRole": {
                    "name": "companyRole",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "companyLocation": {
                    "name": "companyLocation",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "companySize": {
                    "name": "companySize",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "referencedBy": {
                    "name": "referencedBy",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "industryCategoryId": {
                    "name": "industryCategoryId",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "sfAccountId": {
                    "name": "sfAccountId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "sfContactId": {
                    "name": "sfContactId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "sfOwnerId": {
                    "name": "sfOwnerId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "PaymentMethod": {
            "name": "PaymentMethod",
            "fields": {
                "stripeId": {
                    "name": "stripeId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "nickname": {
                    "name": "nickname",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "lastFour": {
                    "name": "lastFour",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "cardType": {
                    "name": "cardType",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "default": {
                    "name": "default",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "PaymentMethods": {
            "name": "PaymentMethods",
            "fields": {
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "nonModel": "PaymentMethod"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        },
        "Theme": {
            "name": "Theme",
            "fields": {
                "primary": {
                    "name": "primary",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "secondary": {
                    "name": "secondary",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "pallet": {
                    "name": "pallet",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Markups": {
            "name": "Markups",
            "fields": {
                "oMar": {
                    "name": "oMar",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "tMar": {
                    "name": "tMar",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "bMar": {
                    "name": "bMar",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "PoliticalSettings": {
            "name": "PoliticalSettings",
            "fields": {
                "politicalType": {
                    "name": "politicalType",
                    "isArray": false,
                    "type": {
                        "enum": "PoliticalType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "electionType": {
                    "name": "electionType",
                    "isArray": false,
                    "type": {
                        "enum": "ElectionType"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "candidate": {
                    "name": "candidate",
                    "isArray": false,
                    "type": {
                        "nonModel": "TtdCandidateProfile"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "TtdVettingStatus"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "RetargetingTag": {
            "name": "RetargetingTag",
            "fields": {
                "dspId": {
                    "name": "dspId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "firstPartyDataId": {
                    "name": "firstPartyDataId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "trackingTagId": {
                    "name": "trackingTagId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "CampaignSegment": {
            "name": "CampaignSegment",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "segmentPackageId": {
                    "name": "segmentPackageId",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "dspId": {
                    "name": "dspId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "pathKey": {
                    "name": "pathKey",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "segmentType": {
                    "name": "segmentType",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "segmentGroup": {
                    "name": "segmentGroup",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ForecastRange": {
            "name": "ForecastRange",
            "fields": {
                "max": {
                    "name": "max",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "min": {
                    "name": "min",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ForecastResult": {
            "name": "ForecastResult",
            "fields": {
                "adgroupType": {
                    "name": "adgroupType",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "impressions": {
                    "name": "impressions",
                    "isArray": false,
                    "type": {
                        "nonModel": "ForecastRange"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "reach": {
                    "name": "reach",
                    "isArray": false,
                    "type": {
                        "nonModel": "ForecastRange"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "frequency": {
                    "name": "frequency",
                    "isArray": false,
                    "type": {
                        "nonModel": "ForecastRange"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "spend": {
                    "name": "spend",
                    "isArray": false,
                    "type": {
                        "nonModel": "ForecastRange"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Forecast": {
            "name": "Forecast",
            "fields": {
                "requestedAt": {
                    "name": "requestedAt",
                    "isArray": false,
                    "type": "AWSTimestamp",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSTimestamp",
                    "isRequired": false,
                    "attributes": []
                },
                "impressions": {
                    "name": "impressions",
                    "isArray": false,
                    "type": {
                        "nonModel": "ForecastRange"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "reach": {
                    "name": "reach",
                    "isArray": false,
                    "type": {
                        "nonModel": "ForecastRange"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "frequency": {
                    "name": "frequency",
                    "isArray": false,
                    "type": {
                        "nonModel": "ForecastRange"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "spend": {
                    "name": "spend",
                    "isArray": false,
                    "type": {
                        "nonModel": "ForecastRange"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "results": {
                    "name": "results",
                    "isArray": true,
                    "type": {
                        "nonModel": "ForecastResult"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        },
        "CampaignServiceResponse": {
            "name": "CampaignServiceResponse",
            "fields": {
                "message": {
                    "name": "message",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "campaign": {
                    "name": "campaign",
                    "isArray": false,
                    "type": {
                        "model": "Campaign"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "FeatureFlagStaticAuthRoleOverride": {
            "name": "FeatureFlagStaticAuthRoleOverride",
            "fields": {
                "staticAuthRole": {
                    "name": "staticAuthRole",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "value": {
                    "name": "value",
                    "isArray": false,
                    "type": {
                        "enum": "FeatureFlagValue"
                    },
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "FeatureFlagBrandzookaOverrides": {
            "name": "FeatureFlagBrandzookaOverrides",
            "fields": {
                "brandzookaEmails": {
                    "name": "brandzookaEmails",
                    "isArray": false,
                    "type": {
                        "enum": "FeatureFlagValue"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        }
    },
    "codegenVersion": "3.4.0",
    "version": "6b808786a9f968fcc7f086cb9bed77b8"
};