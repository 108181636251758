import { API } from 'aws-amplify';
import { campaignFields } from './campaignFields';
import { putAppEvent } from '../putAppEvent';

const updateCampaignQuery = /* GraphQL */ `
  mutation UpdateCampaign($input: UpdateCampaignInput!) {
    updateCampaign(input: $input) {
      ${campaignFields}
    }
  }
`;

const cleanCampaignInput = campaign => {
  const {
    brandCampaignsId: _brandCampaignsId,
    creatives: _creatives,
    summaryMetrics: _summaryMetrics,
    draftChanged: _draftChanged,
    ...rest
  } = campaign;

  return rest;
};

export const updateCampaign = async campaign => {
  const clean = cleanCampaignInput(campaign);

  clean.updatedAt = new Date().toISOString();

  if (clean.id) {
    return API.graphql({
      query: updateCampaignQuery,
      variables: {
        input: clean,
      },
    })
      .then(({ data: { updateCampaign: resp } = {} }) => resp || false)
      .then(resp => {
        putAppEvent('CampaignMutation', clean);

        return resp;
      });
  }

  return Promise.reject();
};

export const saveCampaignDraft = campaignDraft => {
  const draft = { ...campaignDraft };

  const isActive = campaignDraft.status && campaignDraft.status !== 'draft';

  if (campaignDraft.trackingTagId && campaignDraft.pixelTrackingUrl) {
    draft.trackingTagId = null;
  }
  if (isActive) draft.activeUpdatedAt = new Date().toISOString();

  draft.audienceType = campaignDraft.audienceType || null;
  draft.geoSegments = (campaignDraft.geoSegments || []).map(({ id, pathKey, segmentGroup, name }) => ({
    id,
    pathKey,
    segmentGroup,
    name,
  }));

  draft.ctvSegments = campaignDraft.ctvSegments || [];

  if (draft.audienceType === 'thirdParty' && !draft.dmpSegments?.length) {
    draft.audienceType = '';
  }
  draft.campaignPoliticalSettings = campaignDraft.campaignPoliticalSettings || null;

  return updateCampaign(draft);
};

export const saveAndApproveDraft = campaignDraft =>
  saveCampaignDraft({
    ...campaignDraft,
    status: 'approved',
    approvedAt: new Date().toISOString(),
  });
