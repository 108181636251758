import { useCallback, useEffect, useState, createContext, useContext, useMemo } from 'react';
import { Auth, Hub } from 'aws-amplify';
import { get } from 'lodash';

const refreshAuthEvents = ['tokenRefresh', 'signIn'];
const ACCOUNT_ACCESS_ROLES = ['Admin', 'Media'];

const useIntercomUpdater = () =>
  useEffect(() => {
    Hub.listen('auth', async ({ payload: { event, data } = {} }) => {
      if (event === 'signIn') {
        window.Intercom?.('update', {
          email: data.attributes.email,
          user_id: data.attributes.sub,
        });
      }
    });
  }, []);

const calculateUserAuth = session => {
  const {
    selectedAuthS3Prefix,
    selectedAccountSettings,
    authClaim,
    userAuthId,
    userId,
    'cognito:groups': authGroups,
  } = get(session, 'signInUserSession.idToken.payload', {});

  const parsedClaim = authClaim ? JSON.parse(authClaim) : [];
  const selectedAuth = parsedClaim.find(c => c.userAuthId === userAuthId);
  const parsedSettings = selectedAccountSettings ? JSON.parse(selectedAccountSettings) : {};

  return {
    selectedAuthS3Prefix,
    selectedAccountSettings: parsedSettings,
    authClaim: parsedClaim,
    userAuthId,
    userId,
    authGroups,
    selectedAuth,
  };
};

const useAuthInfo = () => {
  const [userLoggedIn, setUserLoggedIn] = useState();
  const [lastTokenRefresh, setLastTokenRefresh] = useState(null);
  const [userAuth, setUserAuth] = useState(null);

  useIntercomUpdater();

  const onAuthEvent = useCallback(
    ({ payload }) => {
      if (refreshAuthEvents.includes(payload.event)) {
        setLastTokenRefresh(payload);
      } else {
        console.log({ payload });
      }
    },
    [setLastTokenRefresh]
  );

  useEffect(() => {
    let mounted = true;

    Auth.currentAuthenticatedUser()
      .then(userData => {
        if (!mounted) return;

        setUserAuth(calculateUserAuth(userData));
        setUserLoggedIn(true);
      })
      .catch(() => {
        if (!mounted) {
          return;
        }

        setUserAuth({});
        setUserLoggedIn(false);
      });

    return () => (mounted = false);
  }, [lastTokenRefresh]); // lastTokenRefresh intentional

  useEffect(() => Hub.listen('auth', onAuthEvent), [onAuthEvent]);

  const { isAdmin, isFinance } = useMemo(() => {
    const { staticAuthRole } = userAuth?.selectedAuth ?? {};

    return {
      isAdmin: ACCOUNT_ACCESS_ROLES.includes(staticAuthRole),
      isFinance: staticAuthRole === 'Finance',
    };
  }, [userAuth?.selectedAuth]);

  return {
    userAuth: userAuth ?? {},
    loaded: userLoggedIn != null,
    userLoggedIn,
    isAdmin,
    isFinance,
  };
};

const UserAuthContext = createContext({
  userAuth: {},
  loaded: false,
  userLoggedIn: undefined,
  isAdmin: false,
  isFinance: false,
});

export const UserAuthProvider = ({ children }) => {
  const userAuthInfo = useAuthInfo();

  return <UserAuthContext.Provider value={userAuthInfo}>{children}</UserAuthContext.Provider>;
};

export const useUserAuth = () => useContext(UserAuthContext);
