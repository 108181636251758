/* eslint-disable prettier/prettier */
export const getUrlRegex = () => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i'
  );

  return pattern;
};

export const isValidHttpUrl = urlString => {
  const pattern = getUrlRegex();

  return pattern.test(urlString);
};

const isValidWildcardPixelUrl = pixelUrlString => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' +
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
    '((\\d{1,3}\\.){3}\\d{1,3}))' +
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+*]*)*' + // path that allows for wildcard (*)
    '(\\?[;&a-z\\d%_.~+=-]*)?' +
    '(\\#[-a-z\\d_]*)?$',
    'i'
  );

  if (pattern.test(pixelUrlString)) {
    const wildcardPath = new URL(pixelUrlString).pathname;
    // /^(?:\/(\*|[a-zA-Z0-9+&@=~_|!:,.;-]+\\*))+\/?$/i
    // Allows for path context wildcards
    //  ex: */about-us/*/sales/, /demo/*
    //  NOT: /cont*-us/*obrien, /about-us/john*/sales
    // eslint-disable-next-line prefer-regex-literals
    const pathPattern = new RegExp('^(?:\\/(\\*|[a-z0-9+&@=~_|!:,.;-]+\\\\*))+\\/?$', 'i');

    return pathPattern.test(wildcardPath);
  }
};

export const isValidPixelTrackingUrl = (pixelTrackingUrl) => {
  if (pixelTrackingUrl) {
    const isWildPixelUrl = pixelTrackingUrl.includes('*');

    if (isWildPixelUrl) return isValidWildcardPixelUrl(pixelTrackingUrl);

    return isValidHttpUrl(pixelTrackingUrl);
  }

  return true;
}
