import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import * as SafeAuth from 'src/helpers/SafeAuth';
import { AuthContainer } from 'src/components/Auth/Layout';
import { Spinner } from 'src/components/shared/Spinner';
import { useAuthProvider } from 'src/providers/AuthProvider';
import { useAlreadyAuthedRootRedirect } from 'src/hooks/authHooks';

export default function VerifyEmailPage() {
  const { logo, isWhiteLabel } = useAuthProvider();
  const history = useHistory();
  const location = useLocation();
  const [verifying, setVerifying] = useState(true);
  const [error, setError] = useState('');

  useAlreadyAuthedRootRedirect();

  const [email, code] = useMemo(() => {
    if (location?.search) {
      const params = new URLSearchParams(location.search);

      return [params.get('email'), params.get('code')];
    }

    return [];
  }, [location]);

  const onSuccessPath = `/auth/sign-in?confirmed=${encodeURIComponent(email)}`;

  useEffect(() => {
    SafeAuth.confirmSignUp(email ?? '', code ?? '')
      .then(() => {
        history.push(onSuccessPath);
      })
      .catch(err => {
        if (err.message.includes('Current status is CONFIRMED')) {
          history.push(onSuccessPath);
        }
        setVerifying(false);
      });
  }, [email, code, history, onSuccessPath]);

  const handleResendVerificationToken = useCallback(async () => {
    try {
      await SafeAuth.resendSignUp(email, { domain: window.location.origin });
      history.push('/auth/sign-in');
    } catch (e) {
      setError('Unable to resend verification code. Please reach out to us at support@brandzooka.com');
    }
  }, [email, history]);

  return (
    <AuthContainer logo={logo} isWhiteLabel={isWhiteLabel}>
      {verifying && (
        <>
          <Spinner />
          <p className="mt-12">Verifying Email…</p>
        </>
      )}
      {!verifying && (
        <div>
          <div className="text-center font-bold mb-8">Unable to verify email</div>
          {error && <div className="text-red-500 py-3">{error}</div>}
          <span className="mx-auto mt-6 text-sm cursor-pointer text-gray-600">
            <button
              type="button"
              className="cursor-pointer bg-gray-800 text-white bz-focus w-96 p-4"
              onClick={handleResendVerificationToken}
            >
              Resend Verification Code
            </button>
          </span>
        </div>
      )}
    </AuthContainer>
  );
}
