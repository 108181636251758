import React from 'react';

export const ReportButton = ({ btnTxt, onClick, disabled = false }) => (
  <button
    type="button"
    disabled={disabled}
    onClick={onClick}
    className={`bg-primary text-white rounded py-3 px-6 ${disabled ? 'opacity-50 pointer-events-none' : ''}`}
  >
    {btnTxt}
  </button>
);

export const ReportMargin = ({ onClick, onChange, showMargin, name }) => {
  const toggleBtnClass = 'w-14 h-7 flex items-center border-2 border-gray-400 bg-gray-200 rounded-full p-1';

  return (
    <div className="flex-1 flex flex-col items-start w-full h-full mt-3">
      <div className="flex flex-row flex-wrap">
        <div className="flex cursor-pointer w-16" onClick={onClick}>
          {!showMargin ? (
            <div className={`${toggleBtnClass} opacity-50`}>
              <span className="ml-7 opacity-50">&#x58;</span>
            </div>
          ) : (
            <div className={`${toggleBtnClass}`}>
              <span className="ml-1">&#10003;</span>
            </div>
          )}
          <div
            className={`z-40 ml-1 mt-1 absolute h-5 w-5 px-1 rounded-full bg-primary shadow-md transform ${
              !showMargin ? '' : 'translate-x-7'
            }`}
          />
        </div>
        <div className={`flex text-sm opacity-50 ${showMargin ? 'opacity-100' : 'opacity-0'}`}>
          Enable Report Markup
        </div>
        {showMargin && (
          <div>
            <div className="flex flex-row pt-5">
              <div className="w-6 h-6 rounded-full bg-primary text-white text-center shadow-md">?</div>
              <div className="pl-2 text-sm w-3/4">Margin is applied to {name} only.</div>
            </div>
            <div className="flex flex-row text-2xl mt-10 items-center">
              %{'  '}
              <input
                onChange={onChange}
                type="text"
                name="marginPct"
                label="Margin Percentage"
                className="rounded ml-3 text-xl w-48"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
