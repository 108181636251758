import { Spinner } from '../../shared/Spinner';

export const SaveButton = ({ loading, onSave, disabled }) => (
  <button
    type="button"
    onClick={onSave}
    disabled={disabled}
    className="px-8 py-4 text-white bg-gray-800 rounded disabled:opacity-50 hover:opacity-75 h-16 relative disabled:cursor-default"
  >
    <span className={loading ? 'text-gray-500' : 'text-white'}>Save Changes</span>
    <div className="pointer-events-none absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      {loading && <Spinner variant="inline" />}
    </div>
  </button>
);
