import { createContext, useMemo, useEffect, useState, useContext, useCallback } from 'react';
import { add } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { useAppParams } from 'src/hooks/useAppParams';
import { saveCampaignDraft } from 'src/helpers/campaign/updateCampaign';
import { createCampaign } from 'src/helpers/campaign';
import { inventorySegments as inventorySegmentTypes } from 'src/constants';
import { ctvSegmentIds } from 'src/constants/ctvSegments';

const CampaignDraftContext = createContext({});

export const useCampaignDraftProvider = () => useContext(CampaignDraftContext);

export const CampaignDraftProvider = ({ children }) => {
  const appParams = useAppParams();
  const history = useHistory();
  const { brandId, teamId, organizationId, campaign, page } = appParams || {};
  const [campaignDraft, setCampaignDraft] = useState({});
  const [creativesChanged, setCreativesChanged] = useState(false);

  useEffect(() => {
    if (campaign?.id) {
      const draft = { ...campaign };

      if (!campaign.inventorySegments?.length || campaign.inventorySegments?.[0]?.id === 'All') {
        draft.inventorySegments = Object.entries(inventorySegmentTypes).map(([k, v]) => ({ id: v.id, name: k }));
      }

      if (!campaign.ctvSegments?.length) {
        draft.ctvSegments = ctvSegmentIds.slice();
      }

      draft.bidlists = campaign.bidlists?.slice() ?? [];

      if (!campaign.goal) draft.goal = 'impressions';
      setCampaignDraft(draft);
    }
  }, [campaign]);

  useEffect(() => {
    if (page === 'new-campaign' && brandId) {
      const createdAt = new Date().toISOString();
      const startDate = new Date().toISOString();
      const endDate = add(new Date(), { days: 7 }).toISOString();

      setCampaignDraft({
        brandId,
        teamId,
        organizationId,
        status: 'draft',
        startDate,
        endDate,
        createdAt,
      });
    }
  }, [page, brandId, teamId, organizationId]);

  const onSave = useCallback(() => {
    if (campaignDraft.id) {
      saveCampaignDraft(campaignDraft).then(() => {
        setCreativesChanged(false);
      });
    } else {
      createCampaign(campaignDraft).then(dbCamp => {
        history.replace(`/campaign/${dbCamp.id}/edit`);
        setCreativesChanged(false);
      });
    }
  }, [campaignDraft, history]);

  const contextValue = useMemo(
    () => ({
      onSave,
      campaignDraft,
      setCampaignDraft,
      setCreativesChanged,
      creativesChanged,
    }),
    [campaignDraft, creativesChanged, onSave]
  );

  return <CampaignDraftContext.Provider value={contextValue}>{children}</CampaignDraftContext.Provider>;
};
