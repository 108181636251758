import { Link } from 'react-router-dom';
import { useGAS } from 'src/GlobalAppState/context';

export const SettingsPageHeaderLink = ({ pageKey, children }) => {
  const {
    appRouteParams: { model, id, page },
  } = useGAS();
  const active = page === pageKey;
  const style = active ? 'text-primary' : 'text-gray-700 hover:text-primary';

  return (
    <div>
      <Link
        data-testid={`${pageKey}Settings`}
        className={`text-xl font-medium ${style}`}
        to={`/${model}/${id}/${pageKey}`}
      >
        {children}
      </Link>
      {active && <div className="h-0 border-b-2 border-primary w-full relative top-1 transform scale-110" />}
    </div>
  );
};

export const SettingsPage = ({ children }) => (
  <div className="w-full min-h-0 flex flex-col flex-1 space-y-2 px-3 pb-3">{children}</div>
);

export const SettingsPageHeader = ({ children }) => (
  <div className="w-full flex flex-col space-y-8 py-2 -mx-4 md:mx-0">
    <div className="flex space-x-12 md:px-4 overflow-x-auto md:overflow-visible -mx-2 md:mx-0">{children}</div>
  </div>
);

export const SettingsBody = ({ children }) => (
  <div className="flex-1 flex flex-col space-y-8 bg-white rounded shadow-md p-8 overflow-y-auto relative">
    {children}
  </div>
);
