import { useEffect, useState } from 'react';
import { PiFunnelLight } from 'react-icons/pi';
import { API } from 'aws-amplify';
import { BuilderPanel } from '../BuilderPanel';
import { MultiConversionSelector } from './MultiConversionSelector';

const getTrackingTagQuery = /* GraphQL */ `
  query GetTrackingTag($id: ID!) {
    getTrackingTag(id: $id) {
      id
      name
    }
  }
`;

const getPixelMapping = (universalPixel, pixelUrl) => {
  const wildcardMappings = universalPixel?.UniversalPixelMappings?.WildcardMatchMappings || [];
  const exactMappings = universalPixel?.UniversalPixelMappings?.ExactMatchMappings || [];

  if (pixelUrl.includes('*')) {
    const wildcardMapping = wildcardMappings.find(({ UrlPattern }) => UrlPattern === pixelUrl);

    if (wildcardMapping?.TrackingTagId) return wildcardMapping;
  } else {
    const exactMapping = exactMappings.find(({ Url }) => Url === pixelUrl);

    if (exactMapping?.TrackingTagId) return exactMapping;
  }

  return false;
};

export const CampaignConversions = ({ appParams, campaignDraft, setCampaignDraft }) => {
  const [loading, setLoading] = useState(true);
  const { pixelTrackingUrl, trackingTagId } = campaignDraft;
  const { brand: { universalPixel } = {} } = appParams;

  useEffect(() => {
    let mounted = true;

    if (pixelTrackingUrl) {
      const pixelMap = getPixelMapping(universalPixel, pixelTrackingUrl);

      if (pixelMap) {
        const conversionEvents = [
          {
            id: pixelMap.TrackingTagId,
            type: 'universal',
          },
        ];

        setCampaignDraft({ ...campaignDraft, pixelTrackingUrl: '', conversionEvents });
        setLoading(false);
      }
    } else if (trackingTagId) {
      API.graphql({ query: getTrackingTagQuery, variables: { id: trackingTagId } })
        .then(({ data: { getTrackingTag: resp = {} } = {} }) => {
          resp.dspIds = resp?.dspIds ? JSON.parse(resp.dspIds) : {};

          return resp;
        })
        .then(trackingTag => {
          if (mounted) {
            if (!trackingTag?.archived && trackingTag?.dspIds?.trackingTagId) {
              const conversionEvents = [
                {
                  id: trackingTag.id,
                  type: 'trackingTag',
                },
              ];

              setCampaignDraft({ ...campaignDraft, trackingTagId: '', conversionEvents });
            }
            setLoading(false);
          }
        });
    } else {
      setLoading(false);
    }

    return () => {
      mounted = false;
      setLoading(false);
    };
  }, [campaignDraft, pixelTrackingUrl, setCampaignDraft, trackingTagId, universalPixel]);

  return (
    <BuilderPanel icon={PiFunnelLight} title="Conversions">
      <div className="my-16">
        {!loading && <MultiConversionSelector campaignDraft={campaignDraft} setCampaignDraft={setCampaignDraft} />}
      </div>
    </BuilderPanel>
  );
};
