import React from 'react';
import { CheckBox } from './CheckBox';
import { InventoryIcons } from './InventoryIcons';

export const InventoryButton = ({ onClick, name, isSelected, disabled, icons }) => {
  const rowOpacity = isSelected ? 'opacity-100' : 'opacity-40';

  return (
    <div className={`h-16 ${rowOpacity}`}>
      <button
        type="button"
        disabled={disabled}
        onClick={onClick}
        className={`w-full flex justify-between h-16 items-center space-x-2 text-left border-b border-gray-300 ${
          disabled ? 'opacity-40 cursor-default' : 'hover:bg-blueGray-50'
        } `}
      >
        <div className="flex">
          <CheckBox isSelected={isSelected} />
          <div className="flex w-full">
            <div className="text-md truncate">{name}</div>
          </div>
        </div>
        <InventoryIcons name={name} icons={icons} />
      </button>
    </div>
  );
};
