import { API } from 'aws-amplify';
import { brandFields } from './brandFields';

const getBrandQuery = /* GraphQL */ `
  query GetBrand($id: ID!) {
    getBrand(id: $id) {
      ${brandFields}
    }
  }
`;

export const getBrand = async brandId =>
  API.graphql({
    query: getBrandQuery,
    variables: {
      id: brandId,
    },
  })
    .then(({ data: { getBrand: resp } = {} }) => resp || false)
    .catch(({ data: { getBrand: resp } = {} }) => resp || false);
