import { useCallback } from 'react';
import { useModal } from 'src/contexts';
import { useGAS } from 'src/GlobalAppState/context';

export const CloneButton = () => {
  const { campaign } = useGAS();
  const { openModal } = useModal();

  const onCloneClick = useCallback(() => {
    openModal('clone-campaign', {
      campaign,
    });
  }, [campaign, openModal]);

  return (
    <div className="flex flex-col space-y-1">
      <button
        type="button"
        onClick={onCloneClick}
        className="bz-btn-solid w-full h-12 2xl:h-16 disabled:cursor-default hover:opacity-80 disabled:opacity-40 rounded"
      >
        Clone
      </button>
    </div>
  );
};
