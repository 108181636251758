import { useEffect, useState, useRef, useMemo } from 'react';
import { useClickAway } from 'react-use';
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';
import { useModal } from 'src/contexts';
import { ChevronDown } from 'src/svgs';
import { Team } from 'src/models';
import { getDataAppMetrics } from 'src/helpers/getDataAppMetrics';
import { useGAS } from 'src/GlobalAppState/context';
import { useAppParams } from 'src/hooks/useAppParams';
import { ReportButton } from './ReportButton';
import { MarginLine } from './MarginLine';
import { createAPIQueryHook } from '../../../hooks/useAPIQuery';

const queryTeamCampaigns = /* GraphQL */ `
  query TeamCampaigns($id: ID!) {
    campaigns: searchCampaigns(
      filter: {
        and: [
          { teamId: { eq: $id } }
          { status: { ne: "archived" } }
          { status: { ne: "draft" } }
          { archived: { ne: true } }
        ]
      }
    ) {
      items {
        id
        name
        brandId
        startDate
        endDate
        status
      }
      nextToken
    }
  }
`;

const useTeamReportCampaigns = createAPIQueryHook({
  query: queryTeamCampaigns,
  paged: true,
  resultPath: 'campaigns.items',
  nextTokenPath: 'campaigns.nextToken',
});

export const TeamHeaderReportForm = () => {
  const { teamBrands, teamBrandsLoading, team } = useGAS();
  const appParams = useAppParams();
  const [reportDates, setReportDates] = useState({
    startDate: new Date(),
    endDate: new Date(),
    minDate: new Date(),
    maxDate: new Date(),
  });
  const [metricsLoading, setMetricsLoading] = useState(false);

  const teamBrandsLoaded = !!teamBrands.length && !teamBrandsLoading;

  const [metrics, setMetrics] = useState(null);

  const { data: teamCampaigns, completed: campaignsLoaded } = useTeamReportCampaigns(
    { id: team.id },
    { disable: !team.id }
  );

  useEffect(() => {
    setReportDates({ startDate: new Date(), endDate: new Date(), minDate: new Date(), maxDate: new Date() });
  }, [team.id]);

  const eligibleCampaigns = useMemo(() => {
    if (!teamBrandsLoaded || !campaignsLoaded) {
      return [];
    }

    const brandIds = new Set();

    for (const brand of teamBrands) {
      brandIds.add(brand.id);
    }

    return (teamCampaigns || []).filter(campaign => brandIds.has(campaign.brandId));
  }, [campaignsLoaded, teamBrands, teamBrandsLoaded, teamCampaigns]);

  useEffect(() => {
    if (!eligibleCampaigns.length) {
      return;
    }

    const { minDate, maxDate } = eligibleCampaigns.reduce(
      (acc, campaign) => {
        if (campaign.startDate) {
          const startDate = new Date(campaign.startDate);

          acc.minDate = startDate < acc.minDate ? startDate : acc.minDate;
        }

        if (campaign.endDate) {
          const endDate = new Date(campaign.endDate);

          acc.maxDate = endDate > acc.minDate ? endDate : acc.minDate;
        }

        return acc;
      },
      { minDate: new Date(), maxDate: new Date() }
    );

    setReportDates({ startDate: minDate, endDate: maxDate, minDate, maxDate });
  }, [eligibleCampaigns]);

  useEffect(() => {
    let mounted = true;
    const filterRange = [format(reportDates.startDate, 'yyyy-MM-dd'), format(reportDates.endDate, 'yyyy-MM-dd')];

    if (team.id) {
      setMetricsLoading(true);
      getDataAppMetrics(Team, team.id, 'CAMPAIGN_DAILY', filterRange).then(data => {
        if (mounted) {
          setMetrics(data);
          setMetricsLoading(false);
        }
      });
    }

    return () => (mounted = false);
  }, [reportDates, team.id]);

  return (
    <Form
      appParams={appParams}
      metrics={metrics}
      campaigns={eligibleCampaigns}
      brands={teamBrands}
      reportDates={reportDates}
      setReportDates={setReportDates}
      loading={!campaignsLoaded || metricsLoading}
    />
  );
};

const reportKeys = ['team-daily', 'team-brands'];

const Form = ({ appParams, metrics, campaigns, brands, reportDates, setReportDates, loading }) => {
  const { openModal } = useModal();
  const [overviewData, setOverviewData] = useState({ spend: 0, impressions: 0, cpm: 0 });
  const [marginPct, setMarginPct] = useState(0);
  const [show, setShow] = useState('form');

  useEffect(() => {
    if (metrics?.totals) {
      const totals = metrics?.totals;
      const cpmCalc = ((totals.spend / totals.impressions) * 1000).toFixed(2);

      setOverviewData({ ...totals, cpm: cpmCalc });
    }
  }, [metrics?.totals]);

  const onMarginChange = e => setMarginPct(e.target.value);

  const onReportClick = () => {
    openModal('report-team-summary', {
      startDate: reportDates.startDate,
      endDate: reportDates.endDate,
      metrics,
      campaigns,
      brands,
      marginPct: marginPct || 0,
    });
  };
  const fmtStart = format(reportDates.startDate, 'MM/dd/yyyy') || '';
  const fmtEnd = format(reportDates.endDate, 'MM/dd/yyyy') || '';

  const onStartSelect = v => {
    const newStart = new Date(v);
    const endDate = new Date(reportDates.endDate);

    if (endDate < newStart) {
      onDateSelect({ startDate: newStart, endDate: newStart });
    } else {
      onDateSelect({ startDate: newStart, endDate });
    }
  };
  const onEndSelect = v => {
    const newEnd = new Date(v);
    const startDate = new Date(reportDates.startDate);

    if (newEnd < startDate) {
      onDateSelect({ startDate, endDate: startDate });
    } else {
      onDateSelect({ startDate, endDate: newEnd });
    }
  };

  const onDateSelect = dates => {
    setReportDates({ ...reportDates, startDate: dates.startDate, endDate: dates.endDate });
  };

  const onSelect = v => {
    if (show === 'start') {
      onStartSelect(v);
    } else {
      onEndSelect(v);
    }
    setShow('form');
  };

  return (
    <div className="flex flex-row space-x-2">
      <div className="flex-1 relative flex items-center">
        <MarginLine marginPct={marginPct} onClick={() => setShow('margin')} />
        {show === 'margin' && (
          <ReportMargin onChange={onMarginChange} marginPct={marginPct} setShow={setShow} name="Team Summary Report" />
        )}
      </div>
      <div className="flex-1 relative">
        <DateButton prefix="start" title="Start Date" value={fmtStart} setShow={setShow} />
        {show === 'start' && (
          <DatePanel
            onSelect={onSelect}
            show={show}
            setShow={setShow}
            minDate={reportDates.minDate}
            maxDate={reportDates.maxDate}
          />
        )}
      </div>
      <div className="flex-1 relative">
        <DateButton prefix="end" title="End Date" value={fmtEnd} setShow={setShow} />
        {show === 'end' && (
          <DatePanel
            onSelect={onSelect}
            show={show}
            setShow={setShow}
            minDate={reportDates.minDate}
            maxDate={reportDates.maxDate}
          />
        )}
      </div>
      <ReportButton
        appParams={appParams}
        loading={loading}
        impressions={overviewData?.impressions}
        onPDFClick={onReportClick}
        startDate={reportDates.startDate}
        endDate={reportDates.endDate}
        marginPct={marginPct}
        reportKeys={reportKeys}
      />
    </div>
  );
};

const DateButton = ({ prefix, title, value, setShow }) => {
  const date = value ? new Date(value) : new Date();
  const formattedDate = format(date, 'MM/dd/yyyy') || '';

  const buttonTitle = value ? (
    <div className="flex-1 flex space-x-3 items-center">
      <h1 className="font-semibold text-left capitalize">{prefix}:</h1>
      <h1 className="font-medium text-left text-primary tracking-wide">{formattedDate}</h1>
    </div>
  ) : (
    <div className="flex-1 flex space-x-3 items-center">
      <h1 className="font-semibold flex-1 text-left">{title}</h1>
    </div>
  );

  return (
    <button
      type="button"
      onClick={() => setShow(prefix)}
      className="w-full flex items-center px-4 h-12 bg-white rounded border space-x-2"
    >
      <h1 className="font-semibold flex-1 text-left">{buttonTitle}</h1>
      <ChevronDown className="stroke-current w-4 h-4" />
    </button>
  );
};

const DatePanel = ({ show, setShow, value, onSelect, minDate, maxDate }) => {
  const initDate = (show === 'end' ? maxDate : minDate) || new Date();
  const [selectedDate, setSelectedDate] = useState(initDate);

  useEffect(() => {
    if (value) {
      const d = new Date(value);

      setSelectedDate(d);
    }
  }, [value]);

  const onDateSelect = d => {
    const dateStr = d.toISOString();

    onSelect(dateStr);
  };

  const panelRef = useRef();

  useClickAway(panelRef, () => setShow('form'));

  return (
    <div className="flex-1 absolute top-full z-20">
      <div ref={panelRef} className="w-full h-full flex items-center justify-center">
        <div className="z-10 h-64 w-72 rounded shadow flex justify-center overflow-hidden date-picker-container">
          <DatePicker
            wrapperClassName="w-full"
            calendarClassName="w-full"
            selected={selectedDate}
            onChange={onDateSelect}
            minDate={minDate || null}
            maxDate={maxDate || null}
            inline
          />
        </div>
      </div>
    </div>
  );
};

const ReportMargin = ({ onChange, setShow, marginPct, name }) => {
  const panelRef = useRef();
  const onClose = () => setShow('form');

  useClickAway(panelRef, onClose);

  return (
    <div className="flex-1 absolute top-full z-20 bg-white rounded p-4 border">
      <div ref={panelRef} className="h-full flex flex-col space-y-4">
        <div className="flex flex-row">
          <div className="w-6 h-6 rounded-full bg-primary text-white text-center shadow-md">?</div>
          <div className="pl-2 text-sm w-3/4">An additional margin can be applied to the {name} data.</div>
        </div>
        <div className="flex-1 flex flex-row space-x-2 text-2xl items-center">
          <input
            onChange={onChange}
            value={marginPct}
            type="text"
            name="marginPct"
            label="Margin Percentage"
            className="rounded text-xl flex-1"
          />
          <p>%</p>
        </div>
        <button type="button" onClick={onClose} className="bz-btn-solid">
          Done
        </button>
      </div>
    </div>
  );
};
