import React, { useMemo } from 'react';
import { SummaryItem } from './SummaryItem';
import { SummaryHeader } from './SummaryHeader';

export const GeoSummary = ({ campaignDraft }) => {
  const summary = useMemo(
    () =>
      (campaignDraft?.geoSegments || []).reduce((obj, itm) => {
        if (!itm?.pathKey) return obj;
        const { segmentGroup } = itm || {};

        if (!segmentGroup) return obj;
        const { [segmentGroup]: key = `${segmentGroup}s` } = {
          Country: 'Countries',
          Region: 'States',
          City: 'Cities',
          Zip: 'Zip Codes',
        };
        const keyCount = obj[key] || 0;

        obj[key] = keyCount + 1;

        return obj;
      }, {}),
    [campaignDraft?.geoSegments]
  );

  const summaryItems = Object.entries(summary).map(([k, v]) => (
    <div key={`summary-${k}`} className="pl-4">
      <SummaryItem title={k}>
        <h3 className="text-primary">{v}</h3>
      </SummaryItem>
    </div>
  ));

  const summaryItem = (
    <div className="ml-4">
      <SummaryItem title="targeting">
        <h3 className="text-primary">Everywhere (Global)</h3>
      </SummaryItem>
    </div>
  );

  return (
    <>
      <SummaryHeader panelKey="Geography">Geography</SummaryHeader>
      <div className="grid grid-cols-1 space-y-1">{summaryItems.length ? summaryItems : summaryItem}</div>
    </>
  );
};
