import { SummaryItem } from './SummaryItem';
import { SummaryHeader } from './SummaryHeader';

export const ConversionsSummary = ({ campaignDraft }) => {
  const { conversionEvents } = campaignDraft || {};

  return (
    <>
      <SummaryHeader panelKey="Conversions">Conversions</SummaryHeader>
      <div className="ml-4">
        {conversionEvents?.length ? (
          <div className="w-full flex items-center space-x-2 text-sm">
            <div className="font-medium">
              {conversionEvents.length} {conversionEvents.length === 1 ? 'event' : 'events'}
            </div>
          </div>
        ) : (
          <SummaryItem title="Status">Disabled</SummaryItem>
        )}
      </div>
    </>
  );
};
