import {
  AUDIO,
  CTV,
  DISPLAY_APP,
  DISPLAY_WEB,
  WEB_VIDEO,
  dailyAdgroupMinimums,
  adgroupDisplayNames,
} from 'src/constants/adgroups';
import { differenceInDays } from 'date-fns';

const diffDates = (dateA, dateB) => Math.abs(differenceInDays(new Date(dateA), new Date(dateB)) || 0) + 1;

const hasAdgroupWarning = (adgroupType, adgroupBudget, numOfDays) => {
  const dailyMinimum = dailyAdgroupMinimums[adgroupType];

  const dailySpend = adgroupBudget / numOfDays;

  if (dailySpend < dailyMinimum) return true;

  return false;
};

const getAdgroupTypes = item => {
  if (item.creativeType === 'audio') return [AUDIO];

  if (item.creativeType === 'video') {
    const types = [];

    if (item.ctv) types.push(CTV);
    if (item.web) types.push(WEB_VIDEO);

    if (types.length) return types;
  }

  if (item.creativeType === 'display') {
    if (item.displayTargeting === 'app') return [DISPLAY_APP];

    return [DISPLAY_WEB];
  }

  console.error('INVALID ADGROUP TYPE', item);

  return [];
};

const getWeekDays = (startDate, numOfDays) => {
  const baseDate = new Date(startDate); // just a Monday
  const weekDays = [];

  for (let i = 0; i < numOfDays; i++) {
    weekDays.push(baseDate.toLocaleDateString('en-US', { weekday: 'short' }));
    baseDate.setDate(baseDate.getDate() + 1);
  }

  return weekDays;
};

export const getCampaignAdgroups = (campaign, creatives) => {
  const { budget, startDate, endDate, isRecurring, recurrenceWindow, recurrenceBudget, daysOfWeek } = campaign || {};
  const filteredCreatives = (creatives || []).filter(c => !c.archived);
  let numOfDays = isRecurring ? recurrenceWindow : diffDates(startDate, endDate);
  const campaignBudget = isRecurring ? recurrenceBudget : budget;

  if (daysOfWeek?.length) {
    const weekDays = getWeekDays(startDate, numOfDays);

    numOfDays = weekDays.filter(d => daysOfWeek.includes(d)).length || 0;
  }

  const adgroupsObj = (filteredCreatives || []).reduce((acc, item) => {
    getAdgroupTypes(item).forEach(type => {
      acc[type] = acc[type] || [];
      acc[type].push(item.id);
    });

    return acc;
  }, {});

  const adgroupBudget = campaignBudget / (Object.keys(adgroupsObj).length || 1);

  return Object.entries(adgroupsObj).map(([adgroupType, creativeIds]) => ({
    creatives: creativeIds,
    adgroupType,
    displayName: adgroupDisplayNames[adgroupType],
    budget: adgroupBudget,
    warning: hasAdgroupWarning(adgroupType, adgroupBudget, numOfDays),
  }));
};
