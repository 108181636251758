import { Chart } from 'chart.js';

export const getDomChartDataUrl = (selectorId, config) => {
  const ctx = document.getElementById(selectorId).getContext('2d');
  const chart = new Chart(ctx, config);

  const dataUrl = chart.toBase64Image();

  chart.destroy();

  return dataUrl;
};
