import {
  SettingsBody,
  SettingsPage,
  SettingsPageHeader,
  SettingsPageHeaderLink,
} from 'src/components/MainSettings/shared/Layout';

export default function BrandCreditsLayout({ children }) {
  return (
    <SettingsPage>
      <SettingsPageHeader>
        <SettingsPageHeaderLink pageKey="credits">Credits</SettingsPageHeaderLink>
        <SettingsPageHeaderLink pageKey="payment-methods">Saved Payment Methods</SettingsPageHeaderLink>
      </SettingsPageHeader>
      <SettingsBody>{children}</SettingsBody>
    </SettingsPage>
  );
}
