import { useCallback } from 'react';
import { creativeFields } from '../creative/creativeFields';
import { createAPIQuerySubscriptionHook } from '../../hooks/useAPIQuery';
import { emit, useEventCallback } from '../eventEmitter';

const creativeQuery = /* GraphQL */ `
  query GetCreative($id: ID!) {
    result: getCreative(id: $id) {
      ${creativeFields}
    }
  }
`;

const subscriptionFields = /* GraphQL */ `
  id
  campaignId
  brandId
  teamId
  organizationId
`;

const creationSubscription = /* GraphQL */ `
  subscription OnCreateCreative($organizationId: String, $teamId: String, $brandId: String) {
    update: onCreateCreative(organizationId: $organizationId, teamId: $teamId, brandId: $brandId) {
      ${subscriptionFields}
    }
  }
`;

const updateSubscription = /* GraphQL */ `
subscription OnUpdateCreative($organizationId: String, $teamId: String, $brandId: String) {
  update: onUpdateCreative(organizationId: $organizationId, teamId: $teamId, brandId: $brandId) {
    ${subscriptionFields}
  }
}
`;

const useCreationSubscription = createAPIQuerySubscriptionHook({
  query: creativeQuery,
  subscription: creationSubscription,
});

const useUpdateSubscription = createAPIQuerySubscriptionHook({
  query: creativeQuery,
  subscription: updateSubscription,
});

const CREATIVE_SUBSCRIPTION = 'brandCreativesSubscription';

export const useBrandCreativesSubscriptionAPIHook = ({ organizationId, teamId, brandId }) => {
  const disable = !organizationId || !teamId || !brandId;

  const subscriptionHandler = useCallback(data => {
    emit(CREATIVE_SUBSCRIPTION, data);
  }, []);

  useCreationSubscription({ organizationId, teamId, brandId }, { subscriptionHandler, disable });
  useUpdateSubscription({ organizationId, teamId, brandId }, { subscriptionHandler, disable });
};

export const useBrandCreativesSubscription = (campaignId, subscriptionHandler) => {
  const callback = useCallback(
    event => {
      if (!campaignId || !subscriptionHandler) return;

      const eventData = event?.detail;

      if (eventData?.value?.data?.campaignId === campaignId) {
        subscriptionHandler(eventData);
      }
    },
    [campaignId, subscriptionHandler]
  );

  useEventCallback(CREATIVE_SUBSCRIPTION, callback);
};
