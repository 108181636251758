import NumberFormat from 'react-number-format';
import { useGAS } from 'src/GlobalAppState/context';
import { LogoIcon } from 'src/components/shared';
import { TeamHeaderReportForm } from './TeamHeaderReportForm';
import { BrandHeaderReportForm } from './BrandHeaderReportForm';

export const MainListHeader = () => {
  const {
    team,
    brand,
    credits: { teamCredits, brandCredits },
    appRouteParams: { model, page },
  } = useGAS();

  const isTeam = model === 'team';

  const activeModel = isTeam ? team : brand;
  const activeCredits = isTeam ? teamCredits : brandCredits;

  const showReporting = !page;

  const ReportForm = isTeam ? TeamHeaderReportForm : BrandHeaderReportForm;

  return (
    <div className="w-full py-4 px-3">
      <div className="relative flex rounded shadow-md bg-white p-4 space-x-2">
        <div className="flex-0 flex items-center space-x-3">
          <LogoIcon className="w-12 h-12" model={activeModel} />
          <div>
            <p className="text-2xl font-medium capitalize truncate" title={activeModel.name}>
              {activeModel.name}
            </p>
            <div className="flex items-center space-x-2">
              <h2 className="text-sm font-medium whitespace-nowrap">Available Credits</h2>
              <h2 className="text-sm text-gray-400">
                <NumberFormat
                  value={activeCredits}
                  displayType="text"
                  prefix="$"
                  decimalScale={2}
                  fixedDecimalScale
                  thousandSeparator
                />
              </h2>
            </div>
          </div>
        </div>
        <span className="flex-1" />
        {showReporting && (
          <div className="flex items-center justify-center">
            <ReportForm />
          </div>
        )}
      </div>
    </div>
  );
};
