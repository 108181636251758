export const shortNumber = (numStr, decimals, shortenThousands) => {
  const num = Number(numStr);

  if (num > 1000000000) return `${(num / 1000000000).toFixed(decimals)}B`;
  if (num > 1000000) return `${(num / 1000000).toFixed(decimals)}M`;
  if (num > 1000 && shortenThousands) return `${(num / 1000).toFixed(decimals)}k`;
  if (num > 999 && num < 1000000 && !shortenThousands) return num.toLocaleString();
  if (num < 1000) return num;
};

export const nFormatter = (numStr, showSymbol = true) => {
  const num = Number(numStr);
  const digits = num > 1e6 ? 2 : 0;

  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e6, symbol: ' million' },
    { value: 1e9, symbol: ' billion' },
    { value: 1e12, symbol: ' trillion' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(itm => num >= itm.value);

  if (item && digits) {
    const symbol = showSymbol ? item.symbol : '';

    return (num / item.value).toFixed(digits).replace(rx, '$1') + symbol;
  }
  if (item && !digits) {
    return (num / item.value).toFixed(digits).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  return '0';
};
