import { Auth } from 'aws-amplify';
import {
  S3,
  S3Client,
  DeleteObjectCommand,
  GetObjectCommand,
  PutObjectCommand,
  ListObjectsV2Command,
  HeadObjectCommand,
} from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { once } from 'lodash';

import awsconfig from 'src/aws-exports';

const getModelBaseKey = (model, level) => {
  const { organizationId: orgId, teamId, brandId, id } = model || {};

  let prefix = id;

  if (orgId) prefix = `${orgId}/${id}`;
  if (teamId) prefix = `${orgId}/${teamId}/${id}`;
  if (brandId) prefix = `${orgId}/${teamId}/${brandId}/${id}`;

  switch (level) {
    case 'private':
      return Auth.currentAuthenticatedUser().then(
        ({ signInUserSession: { idToken: { payload: { sub: userId } = {} } = {} } = {} }) =>
          `private/${prefix || userId}`
      );
    case 'protected':
      return Auth.currentAuthenticatedUser().then(
        ({ signInUserSession: { idToken: { payload: { sub: userId } = {} } = {} } = {} }) =>
          `protected/${prefix || userId}`
      );
    case 'account-protected':
      return Auth.currentAuthenticatedUser().then(
        ({ signInUserSession: { idToken: { payload: { selectedAuthS3Prefix } = {} } = {} } = {} }) =>
          `account-protected/${prefix || selectedAuthS3Prefix}`
      );
    case 'account-private-pii':
      return Auth.currentAuthenticatedUser().then(
        ({ signInUserSession: { idToken: { payload: { selectedAuthS3Prefix } = {} } = {} } = {} }) =>
          `account-private/_pii/${prefix || selectedAuthS3Prefix}`
      );
    case 'account-private':
    default:
      return Auth.currentAuthenticatedUser().then(
        ({ signInUserSession: { idToken: { payload: { selectedAuthS3Prefix } = {} } = {} } = {} }) =>
          `account-private/${prefix || selectedAuthS3Prefix}`
      );
  }
};

const getS3 = once(
  () =>
    new S3({
      region: awsconfig.aws_cognito_region,
      credentials: () => Auth.currentUserCredentials(),
    })
);

const getS3Client = once(
  () =>
    new S3Client({
      region: awsconfig.aws_cognito_region,
      credentials: () => Auth.currentUserCredentials(),
    })
);

const get = key => {
  const s3Client = getS3Client();

  const getObject = new GetObjectCommand({
    Key: key,
    Bucket: process.env.REACT_APP_S3_BUCKET,
  });

  return getSignedUrl(s3Client, getObject, {
    expiresIn: 3600,
  });
};

const getIfExists = async key => {
  try {
    await getMetadata(key);
  } catch (e) {
    // does not exist
    return null;
  }

  return get(key);
};

const getMetadata = key => {
  const s3 = getS3();

  const getHeadObject = new HeadObjectCommand({
    Key: key,
    Bucket: process.env.REACT_APP_S3_BUCKET,
  });

  return s3.send(getHeadObject).then(({ Metadata }) => Metadata);
};

export const cleanMetadata = metadata =>
  Object.entries(metadata).reduce((obj, [k, v]) => {
    switch (typeof v) {
      case 'number':
        return { ...obj, [k]: String(v) };
      case 'object':
        return { ...obj, [k]: Array.isArray(v) ? v.join(',') : JSON.stringify(v) };
      default:
        return { ...obj, [k]: v };
    }
  }, {});

const put = (key, file, metadata = {}) => {
  const s3 = getS3();
  const ContentType = metadata.type || file.type;
  const Metadata = cleanMetadata(metadata);

  const putObject = new PutObjectCommand({
    Key: key,
    Bucket: process.env.REACT_APP_S3_BUCKET,
    ContentType,
    Metadata,
    Body: file,
  });

  return s3.send(putObject).then(() => get(key));
};

const deleteObject = key => {
  const s3 = getS3();

  const deleteObjectCommand = new DeleteObjectCommand({
    Key: key,
    Bucket: process.env.REACT_APP_S3_BUCKET,
  });

  return s3.send(deleteObjectCommand);
};

const list = prefix => {
  const s3Client = getS3Client();

  const listObjects = new ListObjectsV2Command({
    Prefix: `${prefix}/`,
    Bucket: process.env.REACT_APP_S3_BUCKET,
  });

  return s3Client.send(listObjects);
};

// SAVE: object lambda example
// const s3ObjectGet = async (key, options = {}) => {
//   const { level } = options;

//   const { client, baseKey } = await initS3Client(options);
//   const transformOptions = JSON.stringify(options);

//   const getObject = new GetObjectCommand({
//     Key: `${baseKey}/${key}`,
//     Bucket: process.env.REACT_APP_S3_OBJECT_LAMBDA,
//   });

//   client.middlewareStack.add(
//     next => args => {
//       args.request.headers['x-amz-transform-options'] = transformOptions;

//       return next(args);
//     },
//     { step: 'build' }
//   );

//   return getSignedUrl(client, getObject, {
//     expiresIn: 3600,
//   });
// };

const BzS3 = { get, put, list, delete: deleteObject, getMetadata, getModelBaseKey };

export { BzS3, getIfExists };
