import {
  defaultsDeep,
  get,
  merge,
  orderBy,
  partialRight,
  pick,
  set,
  zip,
  map,
  compact,
  flatMap,
  mergeWith,
} from 'lodash';

export const round = (num, precision = 2) => {
  const factor = 10 ** precision;

  return Math.round(num * factor) / factor;
};

const parseDate = date => Date.parse(date.split?.('T')[0] ?? date);

export const dateBetween = (start, end) => {
  if (!(start || end)) return () => true;

  const pStart = start ? parseDate(start) : 0;
  const pEnd = end ? parseDate(end) : Date.now() * 2;

  return date => {
    const pDate = date && parseDate(date);

    return pDate && pStart <= pDate && pDate <= pEnd;
  };
};

export const addMargin = (spend, marginPct) => round(spend * (1 + marginPct / 100), 2);

const createAdder = (a, b) => fieldName => (a[fieldName] || 0) + (b[fieldName] || 0);

const createObjectAdder = (aggregate, metric) => fieldName => {
  const parsedObject = JSON.parse(metric[fieldName] ?? '{}');

  return mergeWith(aggregate[fieldName], parsedObject, (a, b) => (a || 0) + (b || 0));
};

const flattenObject = (obj, depth = 0) => {
  if (depth < 1) return obj;

  const values = Object.values(obj);

  if (depth === 1) return values;

  return values.map(value => flattenObject(value, depth - 1)).flat();
};

export const aggregateMetricsBy =
  (...fields) =>
  (items, options = {}) => {
    const { startDate, endDate, marginPct = 0 } = options;
    const comparator = dateBetween(startDate, endDate);

    const byFields = items.reduce((acc, metric) => {
      const fieldValues = fields.map(field => metric[field]);

      if (!comparator(metric.metricKey)) return acc;
      defaultsDeep(acc, set({}, fieldValues, {}));
      const aggregate = get(acc, fieldValues);
      const add = createAdder(aggregate, metric);
      const objectAdder = createObjectAdder(aggregate, metric);

      merge(aggregate, pick(metric, ['metricKey', ...fields]), {
        impressions: add('impressions'),
        clicks: add('clicks'),
        views: add('views'),
        spend: add('spend'),
        conversions: add('conversions'),
        conversionBreakdown: objectAdder('conversionBreakdown'),
      });

      return acc;
    }, {});

    return flattenObject(byFields, fields.length).map(agMetric => {
      const spend = addMargin(agMetric.spend, marginPct);

      return Object.assign(agMetric, {
        spend,
        ctr: round((agMetric.clicks / agMetric.impressions) * 100 || 0, 3),
        cpm: round((spend / agMetric.impressions) * 1000 || 0, 3),
        date: agMetric.metricKey,
      });
    });
  };

export const makeSorter = (...args) => partialRight(orderBy, ...zip(args));

export const collectUniqueCampaignConversionEventsDspIds = (campaigns, trackingTagIdtoTtdIdMap) => {
  const campaignConversionEventDspIds = flatMap(
    map(campaigns, campaign =>
      compact(
        map(campaign.conversionEvents, conversionEvent =>
          conversionEvent.type === 'trackingTag' ? trackingTagIdtoTtdIdMap[conversionEvent.id] : conversionEvent.id
        )
      )
    )
  );

  return [...new Set([...campaignConversionEventDspIds])];
};

export const createBrandTrackingTagIdTtdIdMap = brandTrackingTags =>
  brandTrackingTags.reduce((acc, trackingTag) => {
    const { dspIds, id } = trackingTag;

    if (!dspIds) return acc;

    const parsedDspIds = JSON.parse(dspIds);
    const { trackingTagId } = parsedDspIds;

    if (!trackingTagId) return acc;

    acc[id] = trackingTagId;

    return acc;
  }, {});

export const createBrandDspIdsToTrackingTagNameMap = brandTrackingTags =>
  brandTrackingTags.reduce((acc, trackingTag) => {
    const { dspIds, name } = trackingTag;

    if (!dspIds) return acc;

    const parsedDspIds = JSON.parse(dspIds);
    const { trackingTagId } = parsedDspIds;

    if (!trackingTagId) return acc;

    acc[trackingTagId] = name;

    return acc;
  }, {});

export const createUniversalPixelDspIdsToTrackingTagNameMap = brand => {
  const { universalPixel } = brand;

  if (!universalPixel) return {};

  const { UniversalPixelMappings } = universalPixel;
  const { ExactMatchMappings: exactMatchMappings, WildcardMatchMappings: wildcardMatchMappings } =
    UniversalPixelMappings;

  const mergedMappings = [...exactMatchMappings, ...wildcardMatchMappings];

  return mergedMappings.reduce((acc, mapping) => {
    const { TrackingTagId, UniversalPixelMappingName } = mapping;

    acc[TrackingTagId] = UniversalPixelMappingName;

    return acc;
  }, {});
};
