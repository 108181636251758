import AnimalPlanet from 'src/svgs/InventoryIcons/AnimalPlanet.svg';
import Amazon from 'src/svgs/InventoryIcons/Amazon.svg';
import AutoGuide from 'src/svgs/InventoryIcons/AutoGuide.svg';
import Bloomberg from 'src/svgs/InventoryIcons/Bloomberg.svg';
import BuzzFeed from 'src/svgs/InventoryIcons/BuzzFeed.svg';
import CarAndDriver from 'src/svgs/InventoryIcons/CarAndDriver.svg';
import CareerBuilder from 'src/svgs/InventoryIcons/CareerBuilder.svg';
import CBS from 'src/svgs/InventoryIcons/Cbs.svg';
import CNN from 'src/svgs/InventoryIcons/CNN.svg';
import Delish from 'src/svgs/InventoryIcons/Delish.svg';
import Dwell from 'src/svgs/InventoryIcons/Dwell.svg';
import Eater from 'src/svgs/InventoryIcons/Eater.svg';
import Ebay from 'src/svgs/InventoryIcons/Ebay.svg';
import EdWeek from 'src/svgs/InventoryIcons/EducationWeek.svg';
import Ehow from 'src/svgs/InventoryIcons/Ehow.svg';
import ESPN from 'src/svgs/InventoryIcons/ESPN.svg';
import FoodNetwork from 'src/svgs/InventoryIcons/FoodNetwork.svg';
import FoxNews from 'src/svgs/InventoryIcons/FoxNews.svg';
import GameSpot from 'src/svgs/InventoryIcons/GameSpot.svg';
import Glassdoor from 'src/svgs/InventoryIcons/Glassdoor.svg';
import GoodHousekeeping from 'src/svgs/InventoryIcons/GoodHousekeeping.svg';
import HGTV from 'src/svgs/InventoryIcons/HGTV.svg';
import HomesAndGarden from 'src/svgs/InventoryIcons/HomesAndGarden.svg';
import Hotwire from 'src/svgs/InventoryIcons/Hotwire.svg';
import Houzz from 'src/svgs/InventoryIcons/Houzz.svg';
import IGN from 'src/svgs/InventoryIcons/IGN.svg';
import MarketWatch from 'src/svgs/InventoryIcons/MarketWatch.svg';
import MensHealth from 'src/svgs/InventoryIcons/MensHealth.svg';
import MotorTrend from 'src/svgs/InventoryIcons/MotorTrend.svg';
import MSNBC from 'src/svgs/InventoryIcons/MSNBC.svg';
import MTV from 'src/svgs/InventoryIcons/MTV.svg';
import Nasdaq from 'src/svgs/InventoryIcons/Nasdaq.svg';
import NatGeo from 'src/svgs/InventoryIcons/NationalGeographic.svg';
import NFL from 'src/svgs/InventoryIcons/NFL.svg';
import Petfinder from 'src/svgs/InventoryIcons/Petfinder.svg';
import PlayStation from 'src/svgs/InventoryIcons/PlayStation.svg';
import PopSugar from 'src/svgs/InventoryIcons/PopSugar.svg';
import PopularScience from 'src/svgs/InventoryIcons/PopularScience.svg';
import Priceline from 'src/svgs/InventoryIcons/Priceline.svg';
import Quora from 'src/svgs/InventoryIcons/Quora.svg';
import ScienceNews from 'src/svgs/InventoryIcons/ScienceNews.svg';
import TechCrunch from 'src/svgs/InventoryIcons/TechCrunch.svg';
import TheDodo from 'src/svgs/InventoryIcons/TheDodo.svg';
import TheVerge from 'src/svgs/InventoryIcons/TheVerge.svg';
import Today from 'src/svgs/InventoryIcons/Today.svg';
import TMZ from 'src/svgs/InventoryIcons/TMZ.svg';
import TripAdvisor from 'src/svgs/InventoryIcons/TripAdvisor.svg';
import Upworthy from 'src/svgs/InventoryIcons/Upworthy.svg';
import Walmart from 'src/svgs/InventoryIcons/Walmart.svg';
import WebMD from 'src/svgs/InventoryIcons/WebMD.svg';
import Wikipedia from 'src/svgs/InventoryIcons/Wikipedia.svg';
import Wired from 'src/svgs/InventoryIcons/Wired.svg';
import WomensHealth from 'src/svgs/InventoryIcons/WomensHealth.svg';
import Zillow from 'src/svgs/InventoryIcons/Zillow.svg';

export const inventorySegments = {
  'Arts & Entertainment': {
    id: '49900',
    icons: [Today, TMZ, MTV],
  },
  'Autos & Vehicles': {
    id: '49902',
    icons: [AutoGuide, CarAndDriver, MotorTrend],
  },
  'Business & Finance': {
    id: '49903',
    icons: [MarketWatch, Nasdaq, Bloomberg],
  },
  'Computers & Electronics': {
    id: '49904',
    icons: [TechCrunch, Wired, TheVerge],
  },
  'Food & Drink': {
    id: '49905',
    icons: [FoodNetwork, Delish, Eater],
  },
  Games: {
    id: '49906',
    icons: [IGN, GameSpot, PlayStation],
  },
  'Health & Fitness': {
    id: '49907',
    icons: [MensHealth, WomensHealth, WebMD],
  },
  'Home & Garden': {
    id: '49908',
    icons: [HGTV, HomesAndGarden, GoodHousekeeping],
  },
  'Jobs & Education': {
    id: '49909',
    icons: [EdWeek, CareerBuilder, Glassdoor],
  },
  News: {
    id: '49910',
    icons: [FoxNews, CNN, MSNBC],
  },
  'Pets & Animals': {
    id: '49911',
    icons: [TheDodo, AnimalPlanet, Petfinder],
  },
  'Real Estate': {
    id: '49912',
    icons: [Zillow, Houzz, Dwell],
  },
  Reference: {
    id: '49913',
    icons: [Quora, Ehow, Wikipedia],
  },
  Science: {
    id: '49914',
    icons: [NatGeo, ScienceNews, PopularScience],
  },
  Shopping: {
    id: '49915',
    icons: [Amazon, Ebay, Walmart],
  },
  Sports: {
    id: '49916',
    icons: [ESPN, NFL, CBS],
  },
  Travel: {
    id: '49917',
    icons: [Priceline, TripAdvisor, Hotwire],
  },
  Trending: {
    id: '49918',
    icons: [BuzzFeed, PopSugar, Upworthy],
  },
};
