import { useEffect } from 'react';
import { userFields } from './userFields';
import { createAPIQueryHook, createAPIQuerySubscriptionHook } from '../../hooks/useAPIQuery';

const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    result: getUser(id: $id) {
      ${userFields}
    }
  }
`;

const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($cognitoId: String) {
    update: onUpdateUser(cognitoId: $cognitoId) {
      id
      cognitoId
    }
  }
`;

const queryHookArgs = {
  query: getUser,
};

const useUserQuery = createAPIQueryHook(queryHookArgs);

const useUserSubscription = createAPIQuerySubscriptionHook({
  ...queryHookArgs,
  subscription: onUpdateUser,
});

const identifyUserForAnalytics = userId => {
  if (typeof window?.dataLayer?.push !== 'function') return;

  window.dataLayer.push({
    user_id: userId,
  });
};

export function useUser(userAuth) {
  const userId = userAuth?.userId;
  const disable = !userId;
  const { data: user, subscriptionHandler } = useUserQuery({ id: userId }, { disable });

  useEffect(() => {
    if (disable) return;

    identifyUserForAnalytics(userId);
  }, [disable, userId]);

  useUserSubscription({ cognitoId: userId }, { disable, subscriptionHandler });

  return user ?? {};
}
