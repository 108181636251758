import { format } from 'date-fns';
import { ColumnCell, ColumnHeader } from './Components';

export const FlightDatesColumn = {
  key: 'flight',
  header: () => <ColumnHeader className="w-64">Flight Dates</ColumnHeader>,
  cell: ({ campaign }) => {
    const dateStr = `${format(campaign.startDate ? new Date(campaign.startDate) : new Date(), 'MM/dd/yyyy')} - ${format(
      campaign.endDate ? new Date(campaign.endDate) : new Date(),
      'MM/dd/yyyy'
    )}`;

    return <ColumnCell>{dateStr}</ColumnCell>;
  },
};
