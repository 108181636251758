import React, { useEffect, useState, useRef } from 'react';
import { useKey, useClickAway } from 'react-use';
import { BsCheckCircle } from 'react-icons/bs';

export const TeamNameField = ({ value = '', save }) => {
  const [updatedValue, setUpdatedValue] = useState(value);
  const fieldRef = useRef();

  useEffect(() => {
    fieldRef.current?.focus();
  }, []);

  useEffect(() => {
    setUpdatedValue(value);
  }, [value]);

  const onCancel = () => {
    setUpdatedValue(value);
  };

  const onSave = () => {
    save(updatedValue);
  };

  const formRef = useRef();

  useKey('Escape', onCancel);
  useClickAway(formRef, onCancel);

  return (
    <div ref={formRef}>
      <div className="flex items-center">
        <input
          ref={fieldRef}
          className="bz-input"
          value={updatedValue}
          placeholder="Team name"
          onChange={({ target: { value: v } = {} }) => setUpdatedValue(v)}
        />
        <button
          type="button"
          className="px-2 h-10 w-12 flex justify-center items-center rounded focus:outline-none focus:ring-2 ring-primary"
          onClick={onSave}
        >
          <BsCheckCircle className="h-7 w-7 fill-green-600" />
        </button>
      </div>
    </div>
  );
};
