import { useMemo } from 'react';
import { Dropdown } from 'src/components/shared';
import { ChevronDown } from 'src/svgs';
import { useCSVDownloader } from 'src/components/CsvDownloader/CsvDownloader';
import { startCase } from 'lodash';

const getReportButtonText = (loading, impressions) => {
  if (loading) return 'Loading...';
  if (impressions) return 'Generate Report';

  return 'No Data Available';
};

const reportKeyMap = {
  'brand-daily': 'Daily Totals CSV Report',
  'brand-campaigns': 'Summary CSV Report',
  'team-daily': 'Daily Totals CSV Report',
  'team-brands': 'Summary CSV Report',
};

export const ReportButton = ({
  impressions,
  onPDFClick,
  loading,
  appParams,
  startDate,
  endDate,
  marginPct,
  reportKeys,
}) => {
  const disabled = loading || impressions === 0;
  const { loading: csvLoading, onClick: csvOnClick } = useCSVDownloader(appParams, {
    startDate,
    endDate,
    marginPct,
  });

  // Disabling - gist is we want a stable array between renders. If the contents
  // of reportKeys don't change, just keep the array identity as-is
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoReportKeys = useMemo(() => reportKeys, reportKeys);
  const anyLoading = loading || csvLoading;

  const dropdownItems = useMemo(() => {
    if (!memoReportKeys) return [];

    return [
      {
        key: 'pdf',
        children: 'Overview PDF Report',
        onClick: onPDFClick,
      },
      ...memoReportKeys.map(key => ({
        key,
        children: reportKeyMap[key] ?? `Generate ${startCase(key)} Report`,
        onClick: () => csvOnClick(key),
      })),
    ];
  }, [onPDFClick, memoReportKeys, csvOnClick]);

  if (dropdownItems.length && !disabled && !anyLoading) {
    return (
      <Dropdown items={dropdownItems} buttonClassName="bg-primary text-white rounded p-3 w-full px-4">
        <span className="flex items-center space-between space-x-4">
          <span className="flex-1 whitespace-nowrap">Generate Report</span>
          <ChevronDown className="svg-stroke-secondary w-4 h-4" />
        </span>
      </Dropdown>
    );
  }

  return (
    <button
      type="button"
      className={`h-12 ${
        disabled
          ? 'bg-primary text-white rounded p-3 opacity-50 cursor-not-allowed'
          : 'bg-primary text-white rounded p-3'
      }`}
      disabled={disabled}
      onClick={onPDFClick}
    >
      {getReportButtonText(anyLoading, impressions)}
    </button>
  );
};
