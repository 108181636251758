import { useEffect, useMemo, useState } from 'react';
import { BzS3 } from 'src/helpers';
import { defaultTheme } from 'src/constants';
import { useGAS } from 'src/GlobalAppState/context';

export const LogoIcon = ({ model = {}, className }) => {
  const { brand, team, organization, accountSettings } = useGAS();

  const { accountType } = accountSettings || {};
  const { logoUrl, name = '' } = model || {};

  const { primary } = useMemo(
    () => ({
      ...defaultTheme,
      ...(accountSettings.theme || {}),
      ...(organization.theme || {}),
      ...(team.theme || {}),
      ...(brand.theme || {}),
      ...(model?.theme || model?.team?.theme || model?.team?.organization?.theme || model?.organization?.theme),
    }),
    [
      accountSettings.theme,
      brand.theme,
      model?.organization?.theme,
      model?.team?.organization?.theme,
      model?.team?.theme,
      model?.theme,
      organization.theme,
      team.theme,
    ]
  );

  const [firstLetter = ''] = name ? name.split('') : accountType?.split('') || [];

  const iconColor = primary || '#f06290';

  const [signedUrl, setSignedUrl] = useState('');

  useEffect(() => {
    let mounted = true;

    if (logoUrl) {
      BzS3.get(logoUrl)
        .then(url => mounted && setSignedUrl(url))
        .catch(() => mounted && setSignedUrl(''));
    } else {
      setSignedUrl('');
    }

    return () => (mounted = false);
  }, [logoUrl]);

  return (
    <div className="flex text-sm rounded-full flex-0">
      <div className={`relative w-10 h-10 ${className}`}>
        {signedUrl ? (
          <div className="absolute w-full h-full">
            <div className="flex justify-center items-center w-full h-full">
              <img
                onError={e => {
                  e.target.onerror = null;
                  e.target.src =
                    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MzIgNjMyIj4KICA8ZyBmaWxsPSIjZWNmMWY1IiBzdHJva2U9IiM3MDcwNzAiIGRhdGEtbmFtZT0iRWxsaXBzZSAyMTMiPgogICAgPGNpcmNsZSBjeD0iMzE2IiBjeT0iMzE2IiByPSIzMTYiIHN0cm9rZT0ibm9uZSIvPgogICAgPGNpcmNsZSBjeD0iMzE2IiBjeT0iMzE2IiByPSIzMTUuNSIgZmlsbD0ibm9uZSIvPgogIDwvZz4KPC9zdmc+';
                }}
                src={signedUrl}
                alt="logo"
                className="max-w-full max-h-full"
              />
            </div>
          </div>
        ) : (
          <div className="absolute w-full h-full">
            <div
              style={{ backgroundColor: iconColor }}
              className="flex justify-center items-center rounded-full w-full h-full"
            >
              <span className="text-xl text-white uppercase">{firstLetter}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
