import { useEffect, useRef } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { AddIcon, CloseIcon } from 'src/svgs';

export const NewTrackingTagRow = ({
  tagDraft,
  setTagDraft,
  showNewRow,
  setShowNewRow,
  submitting,
  rowCount,
  onCreate,
}) => {
  const inputRef = useRef();

  const show = showNewRow || rowCount === 0;

  useEffect(() => {
    if (show && inputRef.current) inputRef.current.select();
  }, [show]);

  const disableSubmit = !tagDraft.name;
  let submitButton = (
    <button
      type="button"
      onClick={() => !disableSubmit && onCreate()}
      className={`${
        disableSubmit ? 'opacity-25 hover:opacity-25 cursor-default' : 'cursor-pointer'
      } h-10 bz-btn rounded bg-primary text-white w-32 focus:ring-secondary focus:opacity-80 uppercase p-1`}
    >
      generate
    </button>
  );

  if (!show) {
    submitButton = (
      <button
        type="button"
        onClick={() => !submitting && setShowNewRow(true)}
        className="bz-btn-icon ring-0 ring-primary focus:ring-primary rounded-full w-6 h-6 p-1"
      >
        <AddIcon className="stroke-current text-primary h-5" />
      </button>
    );
  }

  return (
    <tr className="w-full">
      <td className="w-20">
        {show && (
          <div className="w-full h-full flex items-center justify-center">
            <button
              type="button"
              onClick={() => setShowNewRow(false)}
              className="bz-btn-icon rounded-full w-6 h-6 border-2 border-gray-300 p-1"
            >
              <CloseIcon className="stroke-current text-gray-300 w-4 h-4" />
            </button>
          </div>
        )}
      </td>
      <td className="w-52 h-20 py-2">
        {show && !submitting && (
          <input
            ref={inputRef}
            type="text"
            placeholder="Tag Name"
            className="bz-input bg-blueGray-50 shadow-inner text-xl focus:ring px-2 -ml-2"
            value={tagDraft.name || ''}
            onChange={({ target }) => setTagDraft({ ...tagDraft, name: target.value.slice(0, 30) })}
          />
        )}
        {submitting && <span className="text-lg transform animate-pulse">submitting</span>}
      </td>
      <td className="py-2 w-full flex justify-between items-center px-2">
        {rowCount === 0 && (
          <div className="h-16 flex items-center">
            <h3 className="text-lg px-2 text-primary">Create Your First Tracking Tag!</h3>
          </div>
        )}
        {rowCount === 0 && !disableSubmit && (
          <div className="h-16 flex space-x-2 items-center">
            <h3 className="text-lg px-2 text-primary">Now Click Here</h3>
            <FaArrowRight className="w-8 h-8 stroke-current text-primary" />
          </div>
        )}
      </td>
      <td className="w-40">
        {rowCount === 0 && (
          <button
            type="button"
            onClick={() => !disableSubmit && onCreate()}
            className={`${
              disableSubmit ? 'opacity-25 hover:opacity-25 cursor-default' : 'cursor-pointer'
            } h-10 bz-btn rounded bg-primary text-white w-32 focus:ring-secondary focus:opacity-80 uppercase p-1`}
          >
            generate
          </button>
        )}
      </td>
      <td className="w-20">
        {rowCount !== 0 && <div className="w-full h-full flex items-center justify-center">{submitButton}</div>}
      </td>
    </tr>
  );
};
