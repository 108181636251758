import React, { useState, useEffect, useMemo } from 'react';
import Leaflet from 'leaflet';
import 'leaflet/dist/images/marker-icon-2x.png';

import { HeatmapPanel } from './HeatmapPanel';
import { TopRegions } from './TopRegions';
import { DEFAULT_PALETTE, IMPRESSIONS, US_BBOX, GEO_MARKER_OPTIONS, SEGMENT_GROUP_DEFAULTS } from './Heatmap/constants';
import { bboxToBounds, calcMax, fitLayer, getMapS3Key } from './Heatmap/utils';
import { useHeatmapSpring, useMap, useMapParams, useMountedLeafletRef } from './Heatmap/hooks';
import { MetricsSelector, RegionMapSelector } from './Heatmap/Selectors';

const buildEachFeature = (feature, lyr, params, geoJson, setSelectedMap, setFeature) => {
  const { leafletRefs, regionSegmentGroup, metricSelection } = params;
  const { map, layer } = leafletRefs.current;
  const mapSegmentGroup = geoJson.properties.segmentGroup;

  const max = calcMax(geoJson, geoJson.properties.segmentGroup, metricSelection);

  lyr
    .on('mouseover', () => {
      const { segmentGroup } = feature?.properties || {};
      let showHover = false;

      if (segmentGroup !== 'Mask' && segmentGroup === mapSegmentGroup) showHover = true;
      if (segmentGroup === 'Zip' || segmentGroup === 'HeatmapRegion') showHover = true;

      if (showHover) {
        lyr.setStyle({ weight: 1, fillOpacity: 0 });
      }
      setFeature(feature);
    })
    .on('mouseout', () => {
      const { segmentGroup, metrics = {} } = feature.properties || {};

      const style = { weight: 1 };

      if (segmentGroup === mapSegmentGroup || segmentGroup === 'Zip') {
        style.fillOpacity = metrics[metricSelection] ? metrics[metricSelection] / max + 0.1 : 0;
      }

      if (segmentGroup === 'HeatmapRegion') {
        style.weight = 0;
        style.fillOpacity = metrics[metricSelection] ? metrics[metricSelection] / max : 0;
      }

      lyr.setStyle(style);

      setFeature(null);
    })
    .on('click', () => {
      const { pathKey, segmentGroup } = feature?.properties || {};

      if (segmentGroup === 'Country' && pathKey !== 'united states') return;

      const featureBounds = feature.bbox ? bboxToBounds(feature.bbox) : false;

      if (segmentGroup === 'Mask') {
        const parentKeyArr = pathKey.split(' > ');

        parentKeyArr.pop();
        const parentPathKey = parentKeyArr.join(' > ');
        let parentSegmentGroup = 'Region';

        if (parentKeyArr.length > 2) parentSegmentGroup = regionSegmentGroup;
        if (parentKeyArr.length < 2) parentSegmentGroup = 'Country';
        const mapKey = `${parentSegmentGroup}|${parentPathKey}`;

        setSelectedMap({ key: mapKey });
      } else if (segmentGroup === 'Region') {
        feature.properties.fileName = `${segmentGroup}|${pathKey}`;
        setSelectedMap({ key: `${segmentGroup}|${pathKey}` });
      } else if (segmentGroup === 'Zip' || segmentGroup === 'County') {
        console.log('zip');
      } else if (segmentGroup === 'HeatmapRegion') {
        console.log('heatmap');
      } else if (segmentGroup === 'City') {
        const [lng, lat] = feature?.geometry?.coordinates || [];
        const latLng = Leaflet.latLng(lat, lng);

        map.flyTo(latLng, 9, { duration: 0.4 });
      } else {
        const layerMask = JSON.parse(JSON.stringify(feature));

        layerMask.geometry.coordinates[0].push([
          [180, 90],
          [-180, 90],
          [-180, -90],
          [180, -90],
          [180, 90],
        ]);
        layerMask.properties = { ...feature?.properties, segmentGroup: 'Mask' };

        const zipKey = `${regionSegmentGroup || 'County'}|${feature.properties.pathKey}`;

        layerMask.properties.fileName = zipKey;
        layerMask.bbox = feature.bbox;

        map.fitBounds(featureBounds, { paddingTopLeft: [300, 100] });
        setSelectedMap({ key: zipKey, mask: layerMask });
        layer.clearLayers();
      }
    });
};

const addGeoLayer = (params, setSelectedMap, setFeature) => {
  const { leafletRefs, regionSegmentGroup, metricSelection, mask, geoJson } = params;
  const { mapType } = geoJson.properties || {};
  const { map, layer } = leafletRefs.current;

  layer.clearLayers();

  let leafletBounds = false;

  if (geoJson.bbox) {
    leafletBounds = bboxToBounds(geoJson.bbox);
  } else if (mask.bbox) {
    leafletBounds = bboxToBounds(mask.bbox);
  } else {
    leafletBounds = layer.getBounds();
  }

  if (!leafletBounds.getNorthEast()) {
    leafletBounds = bboxToBounds(US_BBOX);
  }

  const max = geoJson ? calcMax(geoJson, geoJson.properties.segmentGroup, metricSelection) : 0;

  const onEachFeature = (feature, lyr) => buildEachFeature(feature, lyr, params, geoJson, setSelectedMap, setFeature);

  const style = feature => {
    const { segmentGroup, metrics = {} } = feature?.properties || {};
    const resp = {
      color: 'var(--heatmap-color)',
      opacity: 0.8,
      fillOpacity: 0,
      weight: segmentGroup === 'HeatmapRegion' ? 0 : 1.2,
    };

    if (segmentGroup === 'Mask') {
      resp.fillOpacity = 0.5;
      resp.color = '#333';
    } else if (segmentGroup === mapType) {
      resp.opacity = 0.4;
    } else if (segmentGroup === 'HeatmapRegion') {
      resp.fillOpacity = metrics[metricSelection] / max;
    } else if (metrics[metricSelection]) {
      resp.fillOpacity = metrics[metricSelection] / max + 0.1;
    }

    return resp;
  };

  const pointToLayer = (feature, latlng) => Leaflet.circleMarker(latlng, GEO_MARKER_OPTIONS);
  const geoJsonOptions = { style, onEachFeature };

  if (regionSegmentGroup === 'City') {
    geoJsonOptions.pointToLayer = pointToLayer;
  } else {
    geoJsonOptions.onEachFeature = onEachFeature;
  }

  Leaflet.geoJSON(geoJson, geoJsonOptions).addTo(layer);
  layer.addTo(map);
  const sidebarAdjust = regionSegmentGroup !== 'Heatmap';

  fitLayer(map, leafletBounds, sidebarAdjust);
};

export const Heatmap = ({ campaign, appParams }) => {
  const pallet = appParams?.theme?.pallet || DEFAULT_PALETTE;
  const [selectedSegmentGroup, setSelectedSegmentGroup] = useState({});
  const [metricSelection, setMetricSelection] = useState(IMPRESSIONS);
  const [feature, setFeature] = useState(null);

  const mapS3Key = useMemo(() => getMapS3Key(campaign), [campaign]);
  const [selectedMap, setSelectedMap] = useMap(mapS3Key);
  const leafletRefs = useMountedLeafletRef(mapS3Key, setSelectedMap);
  const mapParams = useMapParams({
    leafletRefs,
    mapS3Key,
    metricSelection,
    selectedMap,
    selectedSegmentGroup,
  });

  useEffect(() => {
    if (mapParams.loaded) {
      addGeoLayer(mapParams, setSelectedMap, setFeature);
    }
  }, [mapParams, setSelectedMap]);

  useHeatmapSpring(pallet, metricSelection);

  const mapSegmentGroup = useMemo(() => {
    const [segmentGroup = 'Country', fname] = selectedMap?.key?.split('|') || [];

    if (!fname && segmentGroup !== 'Country') return 'Region';

    return segmentGroup;
  }, [selectedMap]);

  const selectedGroup = selectedSegmentGroup[mapSegmentGroup] || SEGMENT_GROUP_DEFAULTS[mapSegmentGroup];

  return (
    <div className="w-full h-full flex">
      <TopRegions
        leafletRefs={leafletRefs}
        mapParams={mapParams}
        metricSelection={metricSelection}
        regionSegmentGroup={selectedGroup}
      />
      <div className="flex-1 h-full">
        <div className="relative w-full">
          <div id="heatmap" style={{ height: 800 }} className="w-full z-0 rounded" />
          <div className="absolute top-0 w-full p-3">
            <div className="flex flex-col w-full">
              <div className="w-full h-20 rounded bg-white shadow flex items-center justify-between px-4">
                {feature && <HeatmapPanel geoJson={feature} name={feature.properties.pathKey || 'Heatmap Region'} />}
                {!feature && mapParams.geoJson?.properties && (
                  <HeatmapPanel geoJson={mapParams.geoJson} name={mapParams.geoJson.properties.pathKey} />
                )}
              </div>
              <div className="flex justify-between w-full pt-2">
                <div className="flex bg-white rounded-md border w-80">
                  <MetricsSelector metricSelection={metricSelection} setMetricSelection={setMetricSelection} />
                </div>
                <div className="flex bg-white rounded-md border">
                  <RegionMapSelector
                    mapParams={mapParams}
                    selectedGroup={selectedGroup}
                    selectedSegmentGroup={selectedSegmentGroup}
                    mapSegmentGroup={mapSegmentGroup}
                    setSelectedSegmentGroup={setSelectedSegmentGroup}
                  />
                </div>
              </div>
              <div className="w-full flex justify-end pr-10 h-0">
                <button type="button" onClick={() => setSelectedMap(false)}>
                  <div className="rounded text-primary font-medium bg-white px-2 h-6 flex items-center justify-center mt-2 bg-opacity-60">
                    reset map
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
