import { FaQuestionCircle } from 'react-icons/fa';
import { InfoPopupV2 } from '../../../shared/Tooltips/InfoPopupV2';

const isProcessing = tag => !!tag.status?.includes('Processing');
const isFailed = tag => !!tag.status?.includes('Failed');
const isSucceeded = tag => !!tag.status?.includes('Succeeded');
const isSucceededRecent = tag => isSucceeded(tag) && tag.remainingDays > 85;

export const isSucceededEmpty = tag => isSucceeded(tag) && !tag.pixelAudienceSize;

const intercomHref = 'http://help.brandzooka.com/en/articles/8038514-uploading-first-party-data';

const FirstPartyStatusHelpText = ({ tag }) => {
  if (isProcessing(tag)) {
    return <InfoPopupV2 content="Please check back in 24/72 hours" position="top" variant="question" />;
  }

  if (isSucceededEmpty(tag)) {
    return <InfoPopupV2 content="Upload succeeded. Finding audience matches." position="top" variant="info" />;
  }

  if (isSucceededRecent(tag)) {
    return <InfoPopupV2 content="This audience is still matching new IDs" position="top" variant="info" />;
  }

  if (isFailed(tag)) {
    return (
      <a
        className="px-2 text-sm leading-6 text-blueGray-600 rounded-full flex items-center relative flex bg-blueGray-200 rounded-full space-x-1 ml-4 hover:underline"
        target="_blank"
        rel="noreferrer"
        href={intercomHref}
      >
        <FaQuestionCircle />
        <p>troubleshoot</p>
      </a>
    );
  }

  return null;
};

const getStatusClassName = tag => {
  if (tag.status && tag.status.includes('Failed')) {
    return 'text-red-500';
  }
  if (tag.status === 'Succeeded') {
    return 'text-green-500';
  }

  return '';
};

const getStatusText = tag => {
  if (isSucceededEmpty(tag)) {
    return 'Matching';
  }

  if (tag.status && tag.status.includes('Failed')) {
    return 'Failed';
  }

  return tag.status || '';
};

export const FirstPartyStatusText = ({ tag }) => (
  <span className="text-lg flex items-center">
    <span className={getStatusClassName(tag)}>{getStatusText(tag)}</span>
    <FirstPartyStatusHelpText tag={tag} />
  </span>
);
