import React from 'react';

export const CreditToggle = ({ selection, setSelection }) => (
  <div className="flex items-center w-64 h-12 rounded">
    <button
      type="button"
      onClick={() => setSelection('overview')}
      className={`rounded-l h-full flex-1 border-2 border-primary ${
        selection === 'overview'
          ? 'bg-primary text-white hover:opacity-80'
          : 'bg-white text-primary hover:bg-blueGray-100'
      }`}
    >
      Overview
    </button>
    <button
      type="button"
      onClick={() => setSelection('history')}
      className={`rounded-r h-full flex-1 border-2 border-primary ${
        selection === 'history'
          ? 'bg-primary text-white hover:opacity-80'
          : 'bg-white text-primary hover:bg-blueGray-100'
      }`}
    >
      History
    </button>
  </div>
);
