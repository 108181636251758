import { API } from 'aws-amplify';
import { useEffect, useRef, useState } from 'react';
import { shortNumber } from 'src/helpers';
import { CheckIcon, CloseIcon } from 'src/svgs';
import { TrackingCodeBlock } from '../TrackingCodeBlock';
import { FirstPartyStatusText, isSucceededEmpty } from './FirstPartyStatusText';

const fields = /* GraphQL */ `
  {
    id
    archived
    trackingTagType
    createdAt
    updatedAt
    organizationId
    teamId
    brandId
    status
    name
    pixelCode
    pixelAudienceSize
    brandTrackingTagsId
  }
`;

const updateTrackingTag = /* GraphQL */ `
  mutation UpdateTrackingTag($input: UpdateTrackingTagInput!) {
    updateTrackingTag(input: $input) ${fields}
  }
`;

export const FirstPartyRow = ({ tag, onDelete, setActiveRow, showNewRow }) => {
  const [showEdit, setShowEdit] = useState(false);
  const [nameDraft, setNameDraft] = useState(tag.name || '');
  const inputRef = useRef();

  useEffect(() => {
    setNameDraft(tag.name || '');
  }, [tag.name]);

  useEffect(() => {
    if (showEdit && tag.id) {
      if (inputRef.current) inputRef.current.select();
      setActiveRow(tag.id);
    }
  }, [showEdit, setActiveRow, tag.id]);

  const onChange = ({ target }) => setNameDraft(target.value.slice(0, 30));
  const onCancel = () => {
    setNameDraft(tag.name || '');
    setShowEdit(false);

    setActiveRow(false);
  };

  const onSubmit = () => {
    API.graphql({
      query: updateTrackingTag,
      variables: { input: { id: tag.id, name: nameDraft } },
    }).then(() => {
      setShowEdit(false);
      setActiveRow(false);
    });
  };

  const buttonGroup = showEdit && (
    <div className="flex items-center space-x-2 mr-3">
      <button
        type="button"
        onClick={onCancel}
        className="bz-btn-icon rounded-full w-6 h-6 border-2 border-gray-300 p-1"
      >
        <CloseIcon className="stroke-current text-gray-300 w-4 h-4" />
      </button>
      <button type="button" onClick={onSubmit} className="bz-btn-icon rounded-full w-6 h-6 border-2 border-primary p-1">
        <CheckIcon className="stroke-current text-primary w-4 h-4" />
      </button>
    </div>
  );

  const hideActions = false;

  return (
    <tr key={tag.id} className="w-full">
      <td className="w-20">
        {!hideActions && <div className="w-full h-full flex items-center justify-center">{buttonGroup}</div>}
      </td>
      <td className="w-52 border-b">
        {!showEdit && <h3 className="truncate text-xl font-medium">{tag.name}</h3>}
        {showEdit && (
          <input
            ref={inputRef}
            type="text"
            className="bz-input bg-blueGray-50 shadow-inner text-xl focus:ring px-2 -ml-2"
            value={nameDraft}
            onChange={onChange}
          />
        )}
      </td>
      <td className="border-b h-16">
        {tag.pixelCode && <TrackingCodeBlock code={tag.pixelCode} />}
        {!tag.pixelCode && <FirstPartyStatusText tag={tag} />}
      </td>
      <td className="w-20 border-b">
        <h3 className={`text-center -ml-32 ${isSucceededEmpty(tag) ? 'text-sm' : 'text-xl'}`}>
          {isSucceededEmpty(tag) ? 'Pending' : shortNumber(tag.pixelAudienceSize)}
        </h3>
      </td>
      <td className="w-40 border-b">
        <h3 className="text-xl text-center -ml-16">{shortNumber(tag.remainingDays)}</h3>
      </td>
      <td className="w-20 pl-10 border-b">
        <div className="w-full h-full flex space-x-8 ml-5 ">
          <button
            type="button"
            title="edit"
            disabled={showNewRow}
            onClick={() => setShowEdit(true)}
            className={`text-blueGray-500 underline ${showNewRow ? 'opacity-25 disabled cursor-not-allowed' : ''} `}
          >
            Edit
          </button>
          <button
            type="button"
            title="delete"
            disabled={showNewRow}
            onClick={() => onDelete(tag)}
            className={`text-red-500 underline ${showNewRow ? 'opacity-25 disabled cursor-not-allowed' : ''} `}
          >
            Delete
          </button>
        </div>
      </td>
    </tr>
  );
};
