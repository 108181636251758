import { useCallback, useState, useEffect } from 'react';
import { padStart } from 'lodash';
import { API } from 'aws-amplify';
import { useCopyToClipboard } from 'react-use';
import { useGAS } from 'src/GlobalAppState/context';
import { generateApiKey } from 'src/graphql/mutations';
import { apiKeysByOrganizationTeamBrand } from 'src/graphql/queries';
import { Headline } from 'src/components/MainSettings/shared/Headers';

const KEY_LENGTH = 40;

const padApiKey = apiKey => padStart(apiKey, KEY_LENGTH, '•');

const ContentBlock = ({ children, className }) => (
  <div className={`flex flex-col space-y-4 max-w-3xl mb-4 py-6 ${className}`}>{children}</div>
);

const Link = ({ children, href }) => (
  <a href={href} target="_blank" rel="noreferrer" className="text-primary underline">
    {children}
  </a>
);

const KeyCopy = ({ apiKeyRecord }) => {
  const [state, copy] = useCopyToClipboard();
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    let timeout;

    if (state.value === apiKeyRecord.apiKey) {
      setUpdated(true);

      const callback = () => setUpdated(false);

      timeout = setTimeout(callback, 3_000);

      return () => {
        if (timeout) timeout = null;
        callback();
      };
    }
  }, [state, apiKeyRecord]);

  const onCopy = useCallback(() => {
    copy(apiKeyRecord.apiKey);
  }, [copy, apiKeyRecord]);

  if (apiKeyRecord?.obfuscated || !navigator.clipboard.writeText) return null;

  return (
    <button
      type="button"
      onClick={onCopy}
      className="rounded text-primary ml-4 hover:bg-primary hover:text-white p-2 w-24"
    >
      {updated ? 'Copied!' : 'Copy'}
    </button>
  );
};

const KeyLoading = () => <p className="italic">Loading…</p>;

const KeyEmptyState = () => <p className="italic">No existing API keys</p>;

const KeyList = ({ apiKey: apiKeyRecord }) => (
  <dl className="flex flex-col">
    <div className="flex flex-row">
      <dt className="flex-grow-0 flex flex-row justify-items-start items-center w-64 p-4 border-r-2">
        Reporting API Key
      </dt>
      <dd className="p-4 pl-8 h-20 flex flex-row justify-items-start items-center">
        {padApiKey(apiKeyRecord.apiKey)}
        <KeyCopy apiKeyRecord={apiKeyRecord} />
      </dd>
    </div>
  </dl>
);

const Keys = ({ apiKey: apiKeyRecord, loading }) => {
  if (loading) return <KeyLoading />;
  if (!apiKeyRecord) return <KeyEmptyState />;

  return <KeyList apiKey={apiKeyRecord} />;
};

const getGenerateButtonText = (loading, apiKey, showingNewKey) => {
  if (showingNewKey) return 'Generated a Reporting API key';
  if (loading) return 'Generating a Reporting API key';
  if (apiKey) return 'Regenerate your Reporting API Key';

  return 'Generate a Reporting API Key';
};

export default function TeamSettingsIntegrationsPage() {
  const { team } = useGAS();
  const [apiKeyRecord, setApiKey] = useState(null);
  const [existingKeyLoading, setExistingKeyLoading] = useState(false);
  const [genLoading, setGenLoading] = useState(false);
  const [error, setError] = useState(null);
  const { id: teamId, organizationId, name: teamName } = team;
  const anyLoading = genLoading || existingKeyLoading;
  const showingNewKey = apiKeyRecord && !apiKeyRecord.obfuscated;

  useEffect(() => {
    let mounted = true;

    if (!organizationId || !teamId) return;

    setExistingKeyLoading(true);

    const input = { organizationId, teamIdBrandIdCreatedAt: { beginsWith: { teamId } } };

    API.graphql({
      query: apiKeysByOrganizationTeamBrand,
      variables: input,
    })
      .then(({ data: { apiKeysByOrganizationTeamBrand: resp } = {} }) => resp)
      .then(resp => {
        if (!mounted) return;
        const { items = [] } = resp || {};
        const keyItem = items.find(item => item.api === 'reporting');

        if (keyItem) {
          setApiKey({ ...keyItem, obfuscated: true });
        }
      })
      .catch(e => {
        console.error(e);
        const message = e?.errors?.[0]?.message;

        if (message) console.info(message);
      })
      .finally(() => {
        setExistingKeyLoading(false);
      });

    return () => (mounted = false);
  }, [organizationId, teamId]);

  const onClick = useCallback(() => {
    if (apiKeyRecord && !window.confirm('Are you sure? This will interrupt your existing integrations.')) return;
    setGenLoading(true);
    setError(null);
    const input = {
      organizationId,
      teamId,
    };

    API.graphql({
      query: generateApiKey,
      fetchPolicy: 'no-cache',
      variables: { input },
    })
      .then(({ data: { generateApiKey: response } = {} }) => {
        setApiKey(JSON.parse(response) ?? null);
      })
      .catch(e => {
        console.error(e);
        setError(e);
      })
      .finally(() => {
        setGenLoading(false);
      });
  }, [organizationId, teamId, apiKeyRecord]);

  return (
    <div className="flex flex-col flex-grow overflow-scroll">
      <ContentBlock className="pt-0">
        <Headline>Integrations</Headline>
        <h2 className="font-medium text-md">
          <Link href="https://cloud.google.com/looker/">Looker Studio</Link>
          <span className="text-sm text-gray-500"> (formerly Google Data Studio)</span>
        </h2>
        <p>
          Manage your Brandzooka campaign performance data with Looker Studio’s powerful data visualization tools. To
          get started:
        </p>
        <ol className="list-decimal pl-4">
          <li className="my-2 pl-2">Generate an API Key below</li>
          <li className="my-2 pl-2">
            Read{' '}
            <Link href="http://help.brandzooka.com/en/articles/8032905-how-to-connect-your-brandzooka-data-to-looker-studio">
              ‘How to connect Brandzooka to Looker Studio’
            </Link>
          </li>
        </ol>
      </ContentBlock>
      <ContentBlock className="pt-0">
        <h2 className="font-medium text-md text-gray-500">Want to see your data somewhere else?</h2>
        <p>
          <Link href={`mailto:integrations@brandzooka.com?subject=Integration Feedback ${teamName}`}>Contact us</Link>{' '}
          to suggest new integrations!
        </p>
      </ContentBlock>
      <hr />
      <ContentBlock>
        <h2 className="font-medium text-lg text-gray-500">Generate a Reporting API key</h2>
        <p className="italic">
          <em className="uppercase font-medium text-sm not-italic">Note:</em> Generating a new key will invalidate all
          existing keys and require updates of existing integrations.
        </p>
      </ContentBlock>
      <ContentBlock className="max-w-full">
        <h2 className="font-medium text-md text-gray-500">Existing Keys:</h2>
        <div className="h-20">
          <Keys apiKey={apiKeyRecord} loading={anyLoading} />
        </div>
      </ContentBlock>
      <ContentBlock>
        {error && <p>There was a problem generating your API key. Please reach out to your account manager</p>}
        <button
          type="button"
          onClick={onClick}
          className={`whitespace-nowrap h-10 w-64 -ml-4 sm:ml-0 sm:w-96 text-sm sm:text-base px-3 rounded text-white bg-primary ${
            showingNewKey ? 'opacity-50 cursor-not-allowed' : 'disabled:opacity-25 '
          }`}
          data-testid="saveDraft"
          disabled={anyLoading || showingNewKey}
        >
          {getGenerateButtonText(genLoading, apiKeyRecord, showingNewKey)}
        </button>
      </ContentBlock>
    </div>
  );
}
