import { API } from 'aws-amplify';

const getTeamCampaignsQuery = /* GraphQL */ `
  query getTeamCampaigns($teamId: ID!, $nextToken: String) {
    listCampaigns(filter: { teamId: { eq: $teamId } }, nextToken: $nextToken) {
      items {
        id
        conversionEvents {
          id
          type
        }
      }
      nextToken
    }
  }
`;

export const getTeamCampaigns = ({ teamId }) => {
  if (!teamId) return Promise.resolve([]);

  const getPage = next =>
    API.graphql({
      query: getTeamCampaignsQuery,
      variables: { teamId, nextToken: next },
    }).then(
      ({
        data: {
          listCampaigns: { items, nextToken },
        },
      }) => {
        if (nextToken) return getPage(nextToken).then(resp => [...items, ...resp]);

        return items;
      }
    );

  return getPage();
};
