import NumberFormat from 'react-number-format';
import { ColumnCell, ColumnHeader } from './Components';

export const BudgetColumn = {
  key: 'budget',
  header: () => <ColumnHeader className="w-44">Budget</ColumnHeader>,
  cell: ({ campaign }) => (
    <ColumnCell>
      {Number.isFinite(campaign.budget) ? (
        <NumberFormat
          className="text-lg text-gray-600"
          displayType="text"
          value={campaign.budget}
          thousandSeparator
          fixedDecimalScale
          prefix="$"
          decimalScale={2}
        />
      ) : (
        <span className="text-lg text-gray-600">--</span>
      )}
    </ColumnCell>
  ),
};
