export const industryCategories = [
  { text: 'Arts & Entertainment', key: 'arts', value: 2 },
  { text: 'Automotive', key: 'automotive', value: 10 },
  { text: 'Business', key: 'business', value: 34 },
  { text: 'Careers', key: 'careers', value: 47 },
  { text: 'Education', key: 'education', value: 59 },
  { text: 'Family & Parenting', key: 'family', value: 78 },
  { text: 'Health & Fitness', key: 'health', value: 85 },
  { text: 'Herbs for Health (i.e. CBD)', key: 'herbs', value: 109 },
  { text: 'Food & Drink', key: 'food', value: 131 },
  { text: 'Hobbies & Interests', key: 'hobbies', value: 151 },
  { text: 'Home & Garden', key: 'home', value: 182 },
  { text: 'Elections (Candidates and Issues)', key: 'elections', value: 4385 },
  { text: 'Politics (Non Election)', key: 'politics', value: 195 },
  { text: 'News', key: 'news', value: 198 },
  { text: 'Personal Finance', key: 'finance', value: 202 },
  { text: 'Society', key: 'society', value: 215 },
  { text: 'Science', key: 'science', value: 224 },
  { text: 'Pets', key: 'pets', value: 237 },
  { text: 'Sports', key: 'sports', value: 248 },
  { text: 'Style & Fashion', key: 'fashion', value: 288 },
  { text: 'Technology & Computing', key: 'technology', value: 313 },
  { text: 'Travel', key: 'travel', value: 332 },
  { text: 'Real Estate', key: 'realEstate', value: 362 },
  { text: 'Shopping', key: 'shopping', value: 364 },
  { text: 'Religion & Spirituality', key: 'religion', value: 369 },
];
