import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { SearchIcon } from 'src/svgs';
import { AddIcon } from 'src/svgs/icons';
import { useGAS } from 'src/GlobalAppState/context';
import { useAppParams } from 'src/hooks/useAppParams';
import { SortDropdown } from 'src/components/BrandIndex/SortDropdown';
import { StatusFilter } from 'src/components/BrandIndex/StatusFilter';
import { campaignSorter } from 'src/components/BrandIndex/campaignSorter';
import { ColumnSelector, useColumnSets } from 'src/components/BrandIndex/ColumnSelector';
import { CampaignTable } from 'src/components/BrandIndex/CampaignTable';
import { Spinner } from 'src/components/shared/Spinner';

const getDefaultFilter = () => ({
  all: true,
  draft: true,
  running: true,
  paused: true,
  updating: true,
  completed: true,
  stopped: true,
  error: true,
});

const Campaigns = ({ campaigns, sortedCampaigns, columns, loading, brand }) => {
  if (loading) {
    return (
      <div className="flex flex-1 flex-col items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (!campaigns.length) {
    return (
      <div className="flex flex-row items-center justify-center h-96">
        <Link
          to={`/build/brands/${brand.id}/new-campaign`}
          className="bg-primary text-white px-6 py-4 rounded hover:opacity-75"
        >
          Create your first campaign
        </Link>
      </div>
    );
  }

  if (!sortedCampaigns.length) {
    return <div className="flex flex-row items-center justify-center h-96">No results match your filters</div>;
  }

  return <CampaignTable campaigns={sortedCampaigns} columns={columns} />;
};

export default function BrandIndexPage() {
  const appParams = useAppParams();
  const [columns, setActiveColumns] = useColumnSets();
  const { brand, brandCampaigns: campaigns, brandCampaignsLoading } = useGAS();
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('Date Desc');
  const [filter, setFilter] = useState(getDefaultFilter());

  const options = useMemo(
    () => ({
      sort,
      filter,
      search,
      loaded: true,
      isListView: true,
    }),
    [sort, filter, search]
  );

  const sortedCampaigns = useMemo(() => campaignSorter(campaigns, appParams, options), [appParams, campaigns, options]);

  return (
    <div className="px-3 pb-3 flex-1 max-h-full overflow-hidden flex flex-col">
      <div className="w-full flex flex-col pt-1 pb-5 space-y-4">
        <h1 className="text-lg font-semibold">Campaigns</h1>
        <div className="w-full flex justify-between space-x-2">
          <div className="flex space-x-2">
            <div className="w-64 h-10 relative text-gray-400 focus-within:text-gray-500 flex flex-row justify-stretch">
              <input
                type="search"
                placeholder="Filter campaign name"
                value={search}
                onChange={({ target }) => setSearch(target.value)}
                className="block w-full border border-gray-200 bg-white pl-8 placeholder-gray-400 focus:border-gray-200 focus:outline-none focus:ring-primary rounded"
              />
              <div className="h-16 absolute left-2 -top-3 flex items-center justify-center">
                <SearchIcon className="h-4 stroke-current text-primary" />
              </div>
            </div>
            <div className="hidden lg:flex">
              <StatusFilter filter={filter} setFilter={setFilter} />
            </div>
            <Link
              to={`/brand/${brand.id}/new-campaign`}
              className="text-sm bg-primary hover:opacity-75 rounded flex items-center space-x-2 px-3"
            >
              <AddIcon className="text-white w-3 stroke-current" />
              <span className="text-white whitespace-nowrap">Create New Campaign</span>
            </Link>
          </div>
          <div className="flex space-x-4 items-center">
            <ColumnSelector columns={columns} setActiveColumns={setActiveColumns} />
            <span className="text-gray-300">|</span>
            <SortDropdown sort={sort} setSort={setSort} />
          </div>
        </div>
      </div>
      <Campaigns
        campaigns={campaigns}
        columns={columns}
        sortedCampaigns={sortedCampaigns}
        brand={brand}
        loading={brandCampaignsLoading}
      />
    </div>
  );
}
