import { useMemo } from 'react';
import NumberFormat from 'react-number-format';
import { useGAS } from 'src/GlobalAppState/context';

const Num = ({ value }) => (
  <NumberFormat value={value} prefix="$" fixedDecimalScale decimalScale={2} displayType="text" />
);

const isProcessingACH = credit =>
  ['processing', 'pending'].includes(credit.status) && credit.amount === 0 && credit.amountIntended > 0;

const Em = ({ children }) => <span className="font-medium text-black">{children}</span>;

const Processing = ({ chargeType }) => {
  const innerText = chargeType === 'stripe_ach' ? 'Bank Transfer' : 'Transaction';

  return (
    <>
      Pending <Em>{innerText}</Em>
    </>
  );
};

const Refund = () => 'Refund Processed';

const CashOut = () => 'Payout processed';

const MarginTransferFromAgency = ({ pairedRecord }) => (
  <>
    Redeemed <Num value={pairedRecord.amount} /> in credits
  </>
);

const MarginTransferCredit = ({ pairedRecord }) => (
  <>
    Redeemed <Num value={-pairedRecord.amount} /> from margin earnings
  </>
);

const Remainder = ({ name }) => (
  <>
    Received remaining credits from the <Em>{name}</Em> campaign
  </>
);

const Payout = ({ brandName, campaignName }) => (
  <>
    <Em>{brandName || 'A'}</Em> payout for <Em>{campaignName}</Em> campaign
  </>
);

const Transfer = ({ teamName, brandName, amount, type }) => {
  const recordName = type === 'brand' ? teamName : brandName;

  if (amount > 0) {
    return (
      <>
        Received credits from <Em>{recordName}</Em>
      </>
    );
  }

  return (
    <>
      Transferred credits to <Em>{recordName}</Em>
    </>
  );
};

const Campaign = () => 'Launched a Campaign';

const Fallback = ({ amount }) => {
  if (amount < 0) {
    return 'Removed Credits';
  }

  return 'Added Credits';
};

const usePairedCreditRecord = (credit, type) => {
  const {
    credits: { teamCreditRecords, teamPayoutRecords },
  } = useGAS();

  const recordsToSearch = useMemo(() => {
    if (type !== 'team') {
      return [];
    }

    switch (credit.chargeType) {
      case 'cashout':
        return teamCreditRecords;
      case 'credit':
        return teamPayoutRecords;
      default:
        return [];
    }
  }, [credit.chargeType, teamCreditRecords, teamPayoutRecords, type]);

  return useMemo(
    () => recordsToSearch.find(record => record.chargeId === credit.chargeId) ?? null,
    [credit.chargeId, recordsToSearch]
  );
};

export const TransactionDescriptor = ({ credit, type, team, brand, campaign }) => {
  const { chargeType, campaignId, amount } = credit;
  const metadata = credit?.metadata || {};
  const pairedRecord = usePairedCreditRecord(credit, type);

  console.log({ pairedRecord });

  const teamName = metadata.team || team?.name || '';
  const brandName = metadata.brand || brand?.name || '';
  const campaignName = metadata.campaign || campaign?.name || '';

  if (isProcessingACH(credit)) {
    return <Processing chargeType={chargeType} />;
  }

  if (chargeType === 'refund') {
    return <Refund />;
  }

  if (pairedRecord && chargeType === 'cashout') {
    return <MarginTransferFromAgency pairedRecord={pairedRecord} />;
  }

  if (pairedRecord && chargeType === 'credit') {
    return <MarginTransferCredit pairedRecord={pairedRecord} />;
  }

  if (chargeType === 'cashout') {
    return <CashOut pairedRecord={pairedRecord} />;
  }

  if (chargeType === 'remainder') {
    return <Remainder name={campaignName} />;
  }

  if (chargeType === 'payout') {
    return <Payout brandName={brandName} campaignName={campaignName} />;
  }

  if (chargeType === 'transfer') {
    return <Transfer teamName={teamName} brandName={brandName} amount={amount} type={type} />;
  }

  if (campaignId) {
    return <Campaign />;
  }

  return <Fallback amount={amount} />;
};
