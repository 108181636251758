import NumberFormat from 'react-number-format';
import { ColumnCell, ColumnHeader } from './Components';

export const CPMColumn = {
  key: 'cpm',
  header: () => <ColumnHeader className="w-32">CPM</ColumnHeader>,
  cell: ({ metrics }) => {
    const { spend = 0, impressions = 0 } = metrics;
    const cpm = (spend / (impressions || 1)) * 1000 || 0;

    return (
      <ColumnCell>
        {cpm ? (
          <NumberFormat
            className="text-lg text-gray-600"
            displayType="text"
            value={cpm}
            thousandSeparator
            fixedDecimalScale
            prefix="$"
            decimalScale={2}
          />
        ) : (
          <span className="text-lg text-gray-600">--</span>
        )}
      </ColumnCell>
    );
  },
};
