import { Switch, Route } from 'react-router-dom';
import AppLayout from './layout';

import Brand, { brandPaths } from './brand/[id]/component';
import Team, { teamPaths } from './team/[id]/component';
import Campaign, { campaignPaths } from './campaign/[id]/component';

export const appPaths = [...campaignPaths, ...brandPaths, ...teamPaths];

export default function AppComponent() {
  return (
    <AppLayout>
      <Switch>
        <Route exact path={campaignPaths}>
          <Campaign />
        </Route>
        <Route exact path={brandPaths}>
          <Brand />
        </Route>
        <Route exact path={teamPaths}>
          <Team />
        </Route>
      </Switch>
    </AppLayout>
  );
}
