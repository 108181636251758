import { bzColorPallets } from 'src/helpers';
import * as Color from 'color';

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const buildChartData = (metrics, selectedType, loading, theme, selected) => {
  const graphMetrics = loading
    ? Array(metrics.length)
        .fill()
        .map((_val, i) => ({
          metricKey: selectedType === 'dates' ? Date.now() : i,
          clicks: 0,
          views: 0,
          impressions: 0,
          spend: 0,
          conversions: 0,
        }))
    : metrics;

  const labels = graphMetrics
    ? graphMetrics.map(({ metricKey }) => {
        switch (selectedType) {
          case 'daysOfWeek':
            return days[metricKey] || '';
          case 'hoursOfDay': {
            if (metricKey < 1) return `12am`;
            if (metricKey > 12) return `${metricKey - 12}pm`;
            if (metricKey < 12) return `${metricKey}am`;

            return `12pm`;
          }
          case 'dates': {
            return metricKey || '';
          }
          default:
            return metricKey;
        }
      })
    : [];
  const defaultColors = ['#1F2937', '#D1D5DB', '#374151', '#9CA3AF', '#4B5563', '#6B7280'];

  const palletKey = theme?.pallet || 'The Blues';
  const { [palletKey]: selectedColor } = bzColorPallets || {};
  const { colors = defaultColors } = selectedColor || {};
  const selects = ['impressions', 'clicks', 'views', 'spend', 'conversions'];
  const idx = selects.indexOf(selected);
  const color = colors[idx] || '#295AEF';
  const colorObj = new Color(color);
  const colorDark = colorObj.alpha(1).string();
  const colorLight = colorObj.alpha(0.4).string();

  return {
    labels,
    datasets: [
      {
        label: '',
        data: (graphMetrics || []).map(itm => itm[selected] || 0),
        fill: 'origin',
        borderColor: colorDark,
        backgroundColor: colorLight,
        pointBackgroundColor: colorDark,
        pointBorderColor: '#FFF',
        lineTension: 0.5,
        tension: 0.1,
      },
    ],
  };
};
