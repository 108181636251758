import { useGAS } from 'src/GlobalAppState/context';
import { useCampaignCreatives } from 'src/helpers/campaign/useCampaignCreatives';
import { SummaryColumn } from 'src/components/CampaignDetails/SummaryColumn';
import { CreativesContext } from 'src/components/CampaignDetails/CreativesContext';
import { CampaignDraftProvider } from 'src/providers/CampaignDraftProvider';

export default function CampaignLayout({ children }) {
  const { campaign } = useGAS();
  const campaignCreatives = useCampaignCreatives(campaign?.id);

  return (
    <CampaignDraftProvider>
      <CreativesContext.Provider value={campaignCreatives}>
        <div className="w-full h-full flex-grow flex flex-row min-h-0 space-x-3 px-3">
          {children}
          <SummaryColumn />
        </div>
      </CreativesContext.Provider>
    </CampaignDraftProvider>
  );
}
