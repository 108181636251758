import React, { useState, useRef } from 'react';
import { API } from 'aws-amplify';
import {
  FaCcAmex,
  FaCcDinersClub,
  FaCcDiscover,
  FaCcJcb,
  FaCcMastercard,
  FaCcVisa,
  FaCreditCard,
  FaTrash,
} from 'react-icons/fa';
import { useGAS } from 'src/GlobalAppState/context';
import { useModal } from 'src/contexts';
import { AddIcon } from 'src/svgs';
import { createSetupIntent, updateBrand, updateTeam } from 'src/graphql/mutations';

export const SavedPaymentMethods = ({
  paymentMethods,
  paymentMethodType,
  setPaymentMethodType,
  model,
  showPrimary = true,
}) => {
  const { routeIds, organization, team, brand, campaign, user } = useGAS();
  const { organizationId, teamId, brandId, campaignId } = routeIds;
  const { openModal } = useModal();
  const [primaryCard, setPrimaryCard] = useState();
  const primaryCardRef = useRef(null);

  const name = brand?.name || team?.name || organization?.name;
  const description = campaign?.name ? `${name} - ${campaign?.name}` : name;
  const metadataObj = {
    organizationId,
    organization: organization?.name,
    teamId,
    team: team?.name,
    brandId,
    brand: brand?.name,
    campaignId,
    campaign: campaign?.name,
    userId: user?.id,
    user: user?.email,
  };
  const input = { organizationId, teamId, brandId, description, metadata: JSON.stringify(metadataObj) };

  const handleSetPrimaryCard = async paymentMethodId => {
    let update = {
      id: brand.id,
      defaultStripeId: paymentMethodId,
    };

    API.graphql({
      query: updateBrand,
      variables: { input: update },
    });
    setPrimaryCard(paymentMethodId);
    if (model === 'team') {
      update = {
        id: team.id,
        defaultStripeId: paymentMethodId,
      };
      API.graphql({
        query: updateTeam,
        variables: { input: update },
      });
      setPrimaryCard(paymentMethodId);
    }
  };

  const hasDefaultStripeId = brand.defaultStripeId || team.defaultStripeId;

  const onSaveNewCard = () => {
    API.graphql({
      query: createSetupIntent,
      variables: { input },
    })
      .then(({ data: { createSetupIntent: resp = {} } = {} }) => JSON.parse(resp))
      .then(intent => openModal('save-new-payment-method', { clientSecret: intent.client_secret, model }))
      .catch(error => console.error('Error creating setup intent with these inputs:', input, error));
  };

  const onRemove = async paymentMethodId => {
    const removePaymentInput = { paymentMethodId, brandId: brand.id };

    openModal('remove-payment-method', { input: removePaymentInput, model });
  };

  let paymentMethodRows = <div />;

  if (!paymentMethods.error) {
    paymentMethodRows = paymentMethods.map(paymentMethod => {
      let PaymentMethodRow = CardRow;

      if (paymentMethod.type === 'us_bank_account') PaymentMethodRow = AchRow;

      return (
        <div data-mf-replace="" className="flex w-full" key={paymentMethod.id}>
          <PaymentMethodRow
            paymentMethod={paymentMethod}
            onRemove={onRemove}
            onSetPrimaryCard={() => handleSetPrimaryCard(paymentMethod.id)}
            primaryCardRef={paymentMethod === primaryCard ? primaryCardRef : null}
            primaryCard={primaryCard}
            hasDefaultStripeId={hasDefaultStripeId}
            showPrimary={showPrimary}
          />
        </div>
      );
    });
  }

  return (
    <div className="flex-1 max-w-screen-lg flex flex-col space-y-4">
      <div data-mf-replace="" className="w-full flex space-x-2 items-center justify-between">
        <div className="flex items-center space-x-3">
          <span className="text-lg font-medium text-gray-600">Saved Payment Methods:</span>
          <button
            type="button"
            onClick={onSaveNewCard}
            className="text-xl font-medium text-gray-700 hover:text-primary rounded-full border border-primary p-1"
          >
            <AddIcon className="w-3 h-3 stroke-current text-primary" />
          </button>
        </div>
        <div className="flex items-center space-x-3">
          <button
            type="button"
            onClick={() => setPaymentMethodType('us_bank_account')}
            className={`text-base ${paymentMethodType === 'us_bank_account' ? 'text-primary' : 'text-gray-600'}`}
          >
            Bank Accounts
          </button>
          <button
            type="button"
            onClick={() => setPaymentMethodType('card')}
            className={`text-base ${paymentMethodType === 'card' ? 'text-primary' : 'text-gray-600'}`}
          >
            Credit Cards
          </button>
        </div>
      </div>
      <div className="w-full h-96 overflow-scroll border rounded bg-gray-100 shadow-inner">
        <div data-mf-replace="" className="w-full flex-col items-center px-8">
          {paymentMethodRows}
        </div>
      </div>
    </div>
  );
};

const CardRow = ({
  paymentMethod,
  onRemove,
  onSetPrimaryCard,
  primaryCardRef,
  hasDefaultStripeId,
  primaryCard,
  showPrimary,
}) => {
  const { card = {} } = paymentMethod || {};
  const { [card.brand]: Icon = FaCreditCard } = {
    visa: FaCcVisa,
    mastercard: FaCcMastercard,
    amex: FaCcAmex,
    diners_club: FaCcDinersClub,
    discover: FaCcDiscover,
    jcb: FaCcJcb,
  };

  const isPrimaryCard = primaryCard === hasDefaultStripeId;

  const expStr = card.exp_month ? `${card.exp_month}/${card.exp_year}` : '';

  const handleClick = async () => {
    const newPrimaryCard = isPrimaryCard ? null : paymentMethod.id;

    await onSetPrimaryCard(newPrimaryCard);
  };

  let label = 'Set as Primary';

  if (hasDefaultStripeId === paymentMethod.id) {
    label = 'Primary Card';
  }

  return (
    <div
      className={`w-full h-28 flex items-center justify-between border-b border-gray-300 ${
        isPrimaryCard ? 'primary-card' : ''
      }`}
      ref={isPrimaryCard ? primaryCardRef : null}
    >
      <div data-mf-replace="" className="flex flex-col items-end">
        <div className="flex items-center space-x-2">
          <h1 className="hidden sm:block text-2xl tracking-wider mt-2">**** **** ****</h1>
          <h1 className="sm:hidden text-2xl tracking-wider mt-2">****</h1>
          <h1 className="font-medium text-lg">{card.last4 || '****'}</h1>
        </div>
        <h1 className="text-gray-700">{expStr}</h1>
      </div>
      <div data-mf-replace="" className="flex items-center space-x-4">
        <Icon className="fill-gray-600 w-12 h-12 ml-4" />
        <button type="button" onClick={() => onRemove(paymentMethod.id)} className="bz-btn-icon focus:ring-red-600">
          <div className="w-5 h-5 rounded-full flex items-center justify-center">
            <FaTrash className="stroke-current text-red-600 h-4" />
          </div>
        </button>
        {showPrimary && (
          <div className="flex items-center space-x-2 self-end">
            <button
              type="button"
              onClick={handleClick}
              disabled={hasDefaultStripeId === paymentMethod.id}
              className="bg-white border border-gray-300 rounded-lg px-3 py-2 shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary disabled:opacity-50"
            >
              <span className="text-gray-700">{label}</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const AchRow = ({ paymentMethod, onRemove }) => {
  const { us_bank_account: bank = {} } = paymentMethod || {};

  return (
    <div data-mf-replace="" className="w-full h-28 flex items-center justify-between border-b border-gray-300">
      <div className="flex flex-col items-end">
        <h1 className="font-medium text-lg">{bank.bank_name}</h1>
        <div className="flex items-center space-x-2">
          <h1 className="text-2xl tracking-wider mt-2">******</h1>
          <h1 className="font-medium text-lg">{bank.last4 || '****'}</h1>
        </div>
      </div>
      <div className="flex items-center space-x-4">
        <div className="flex flex-col items-end">
          <h1 className="font-medium text-lg">{bank.account_type}</h1>
          <h1 className="text-gray-700 pt-1">{bank.routing_number}</h1>
        </div>
        <button type="button" onClick={() => onRemove(paymentMethod.id)} className="bz-btn-icon focus:ring-red-600">
          <div className="w-5 h-5 rounded-full flex items-center justify-center">
            <FaTrash className="stroke-current text-red-600 h-4" />
          </div>
        </button>
      </div>
    </div>
  );
};
