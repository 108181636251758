import React, { useState } from 'react';
import { updateBrand } from 'src/helpers';
import { ttdStates } from 'src/constants';
import { Link } from 'react-router-dom';

const getPoliticalOptions = politicalSettings => {
  if (!politicalSettings) return [];

  const empty = { label: 'Select One…' };
  const ballotMeasure = { label: 'Ballot Measure', value: 'ballotMeasure' };
  const candidateElection = { label: 'Candidate', value: 'candidateElection' };
  const disabledPolitical = { label: 'Non Political Campaign', value: 'none' };

  if (!politicalSettings.electionType) return [];

  const isBallot = ['both', 'ballotMeasure'].includes(politicalSettings.electionType);
  const isCandidate = ['both', 'candidateElection'].includes(politicalSettings.electionType);

  return [empty, isBallot && ballotMeasure, isCandidate && candidateElection, disabledPolitical].filter(Boolean);
};

const ballotMeasureFieldsPresent = formState => {
  const {
    PayingEntityName,
    PayingEntityAddress,
    PayingEntityExecutiveName,
    PayingEntityExecutiveTitle,
    ElectionState,
  } = formState;

  return (
    PayingEntityName && PayingEntityAddress && PayingEntityExecutiveName && PayingEntityExecutiveTitle && ElectionState
  );
};

const PayingEntityInformation = ({ record }) => (
  <div className="flex flex-col">
    <p>Paying Entity</p>
    <p className="font-semibold">{record.PayingEntityName}</p>
    <p className="font-semibold">
      {record.PayingEntityExecutiveName}, {record.PayingEntityExecutiveTitle}
    </p>
    <p className="font-semibold"> {record.PayingEntityAddress} </p>
  </div>
);

const PoliticalConfigurationElectionInformation = ({ record, campaignPoliticalType, brandId }) => {
  const stateElection =
    campaignPoliticalType === 'candidateElection' && record.ElectionState && record.StateVerificationId;

  const electionTypeCopy = stateElection ? 'State' : 'Federal';
  const verificationCopy = stateElection ? 'EIN' : 'Federal Verification Commission ID';
  const verificationValue = stateElection ? record.StateVerificationId : record.FederalVerificationId;

  return (
    <div className="bg-brandzooka-blueDarker text-white p-4 rounded">
      <div className="flex justify-between items-center border-b border-white pb-3">
        <p className="font-semibold">
          {campaignPoliticalType === 'ballotMeasure' ? 'Ballot Measure Profile' : record.CandidateName}
        </p>

        <Link to={`/build/brands/${brandId}/settings`} className="text-brandzooka-pink text-sm">
          Edit Political Settings
        </Link>
      </div>

      {campaignPoliticalType === 'ballotMeasure' && (
        <div className="py-4">
          {' '}
          <div>
            <p>State</p>
            <p className="font-semibold"> {record.ElectionState} </p>
          </div>
          <PayingEntityInformation record={record} />
        </div>
      )}

      {campaignPoliticalType === 'candidateElection' && (
        <div className="flex flex-row py-4 space-x-4">
          <div className="flex flex-col space-y-4 flex-shrink-0 flex-grow-0 w-1/3">
            <div>
              <p>Election Type</p>
              <p className="font-semibold">{electionTypeCopy}</p>
            </div>

            {stateElection && (
              <div>
                <p>State</p>
                <p className="font-semibold"> {record.ElectionState} </p>
              </div>
            )}
            <div>
              <p>{verificationCopy}</p>
              <p className="font-semibold"> {verificationValue} </p>
            </div>
          </div>

          <div>
            <PayingEntityInformation record={record} />
          </div>
        </div>
      )}
    </div>
  );
};

const PoliticalSettingsFormField = ({ label, htmlFor, children }) => (
  <div className="flex flex-col inline space-y-2 mt-4" htmlFor={htmlFor}>
    <label>{label}: </label>
    {children}
  </div>
);

const PoliticalSettingsBallotForm = ({ handleStateChange, handleInputChange, handleSubmit, formState }) => (
  <div className="flex flex-col flex-grow-1">
    <h2 className="">Ballot measure information</h2>
    <div className="grid gap-4 grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3">
      <div>
        <PoliticalSettingsFormField label="Paying Entity Name">
          <input type="text" name="PayingEntityName" value={formState.PayingEntityName} onChange={handleInputChange} />
        </PoliticalSettingsFormField>

        <PoliticalSettingsFormField label="Address" htmlFor="PayingEntityAddress">
          <input
            type="text"
            name="PayingEntityAddress"
            value={formState.PayingEntityAddress}
            onChange={handleInputChange}
          />
        </PoliticalSettingsFormField>
      </div>
      <div>
        <PoliticalSettingsFormField label="Executive Name" htmlFor="PayingEntityExecutiveName">
          <input
            type="text"
            name="PayingEntityExecutiveName"
            value={formState.PayingEntityExecutiveName}
            onChange={handleInputChange}
          />
        </PoliticalSettingsFormField>

        <PoliticalSettingsFormField label="Executive Title" htmlFor="PayingEntityExecutiveTitle">
          <input
            type="text"
            name="PayingEntityExecutiveTitle"
            value={formState.PayingEntityExecutiveTitle}
            onChange={handleInputChange}
          />
        </PoliticalSettingsFormField>
      </div>
      <div>
        <PoliticalSettingsFormField label="State" htmlFor="state">
          <select
            id="stateSelect"
            className="flex-1 bg-white border border-gray-500 focus:border-white focus:bg-white placeholder-gray-500 focus:ring-secondary rounded"
            onChange={handleStateChange}
            value={formState.ElectionState || ttdStates[0].abbreviation}
            name="state"
          >
            {ttdStates.map(state => (
              <option key={state.abbreviation} value={state.name}>
                {state.abbreviation}
              </option>
            ))}
          </select>
        </PoliticalSettingsFormField>
      </div>
    </div>
    <div className="mt-6">
      <button
        type="submit"
        className="bg-primary text-white rounded p-3 mt-4"
        disabled={!ballotMeasureFieldsPresent(formState)}
        onClick={handleSubmit}
      >
        Create Ballot Measure Profile
      </button>
    </div>
  </div>
);

export const PoliticalConfiguration = ({ campaignDraft, setCampaignDraft, brand }) => {
  const [formState, setFormState] = useState({
    PayingEntityName: '',
    PayingEntityAddress: '',
    PayingEntityExecutiveName: '',
    PayingEntityExecutiveTitle: '',
    ElectionState: '',
  });

  const campaignPoliticalType = campaignDraft.campaignPoliticalSettings?.type;

  const handleStateChange = event => {
    const selectedState = event.target.value;

    setFormState(prevState => ({
      ...prevState,
      ElectionState: selectedState,
    }));
  };

  const handleInputChange = e => {
    const { name, value } = e.target;

    setFormState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const options = getPoliticalOptions(brand?.politicalSettings);

  const { ballotMeasureProfile, candidate } = brand.politicalSettings || {};

  const ballotMeasureCandidatePresent = ballotMeasureProfile && ballotMeasureProfile.PayingEntityName;
  const candidateProfilePresent = candidate && candidate.PayingEntityName;

  const handleSubmit = () => {
    const input = {
      id: brand.id,
      politicalSettings: { ...brand.politicalSettings, ballotMeasureProfile: formState },
    };

    updateBrand(input).then(res => {
      console.log('success', res);
    });
  };

  return (
    <div className="flex flex-col space-y-16">
      <div className="flex-grow-0">
        <select
          value={campaignPoliticalType}
          onChange={e => {
            setCampaignDraft({ ...campaignDraft, campaignPoliticalSettings: { type: e.target.value } });
          }}
        >
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      {campaignPoliticalType === 'ballotMeasure' && !ballotMeasureCandidatePresent && (
        <PoliticalSettingsBallotForm
          handleInputChange={handleInputChange}
          handleStateChange={handleStateChange}
          handleSubmit={handleSubmit}
          formState={formState}
        />
      )}

      <div className="flex flex-col flex-grow-1">
        {ballotMeasureCandidatePresent && campaignPoliticalType === 'ballotMeasure' && (
          <PoliticalConfigurationElectionInformation
            campaignPoliticalType={campaignPoliticalType}
            record={ballotMeasureProfile}
            brandId={brand.id}
          />
        )}

        {candidateProfilePresent && campaignPoliticalType === 'candidateElection' && (
          <PoliticalConfigurationElectionInformation
            campaignPoliticalType={campaignPoliticalType}
            record={candidate}
            brandId={brand.id}
          />
        )}
      </div>
    </div>
  );
};

export default PoliticalConfiguration;
