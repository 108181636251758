export const TILE_URL = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
export const MAP_ATTRIBUTION =
  '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';

export const DEFAULT_PALETTE = 'The Blues';

export const STATE_KEY_TYPES = ['County', 'Congress', 'SLDL', 'SLDU'];

export const US_BBOX = [-124.848974, 24.396308, -66.885444, 49.384358];

export const IMPRESSIONS = 'impressions';
export const VIEWS = 'views';
export const CLICKS = 'clicks';
export const SPEND = 'spend';

export const METRICS = [IMPRESSIONS, VIEWS, CLICKS, SPEND];

export const SEGMENT_GROUP_DEFAULTS = {
  World: 'Country',
  Country: 'Region',
  Region: 'County',
  Zip: 'Zip',
  County: 'Zip',
  Congress: 'Zip',
  SLDL: 'Zip',
  SLDU: 'Zip',
};

export const READABLE_KEYS = {
  SLDU: 'State Senate',
  SLDL: 'State House',
  Zip: 'Zip Code',
  Region: 'States',
  City: 'Top Cities',
};

export const GEO_MARKER_OPTIONS = {
  radius: 8,
  fillColor: 'var(--heatmap-color)',
  color: 'var(--heatmap-color)',
  weight: 1,
};
