import { BsFillPersonLinesFill } from 'react-icons/bs';
import { useGAS } from 'src/GlobalAppState/context';

export const AudienceTypeIndex = ({ campaignDraft, setCampaignDraft, appParams }) => {
  const { dmpSegments } = campaignDraft || {};
  const { campaign, brandTrackingTags } = appParams || {};
  const { managedAudiences } = useGAS();
  const hasFirstParty = brandTrackingTags && brandTrackingTags.some(tag => tag.trackingTagType === 'firstParty');
  const setAudienceType = aType => {
    if (aType !== 'thirdParty' && dmpSegments?.length) {
      setCampaignDraft({ ...campaignDraft, audienceType: aType, dmpSegments: null, isMultiDataGroups: false });
    } else {
      setCampaignDraft({ ...campaignDraft, audienceType: aType });
    }
  };

  return (
    <div className="w-full flex flex-col justify-evenly items-center space-y-16 mb-16">
      <h3 className="text-primary text-center">
        Build your audience here by targeting specific demographics, interests , political affiliations, and more!
      </h3>
      <div className="flex w-full items-center justify-evenly">
        <AudienceTypeButton audienceTypeKey="everyone" setAudienceType={setAudienceType} campaign={campaign}>
          <TargetEveryone className="stroke-current w-12" />
          <span className="text-sm sm:text-base">Target everyone</span>
        </AudienceTypeButton>
        <AudienceTypeButton audienceTypeKey="thirdParty" setAudienceType={setAudienceType} campaign={campaign}>
          <BuildAudience className="stroke-current w-12" />
          <span className="text-sm sm:text-base">Build your audience</span>
        </AudienceTypeButton>
        <AudienceTypeButton audienceTypeKey="existing" setAudienceType={setAudienceType} campaign={campaign}>
          <ExistingAudience className="stroke-current w-12 " />
          <span className="text-sm sm:text-base">Use existing audience</span>
        </AudienceTypeButton>
      </div>
      <div className="flex w-full items-center justify-evenly">
        <AudienceTypeButton audienceTypeKey="retargeting" setAudienceType={setAudienceType} campaign={campaign}>
          <RetargetingAudience className="stroke-current w-12" />
          <span className="text-sm sm:text-base">Use retargeting audience</span>
        </AudienceTypeButton>
        <AudienceTypeButton audienceTypeKey="firstParty" setAudienceType={setAudienceType} campaign={campaign}>
          <UploadAudience className="stroke-current w-12" />
          <span className="text-sm sm:text-base tracking-tight">
            {hasFirstParty ? 'Use First Party Audience' : 'Upload an Audience'}
          </span>
        </AudienceTypeButton>
        {!!managedAudiences.length && (
          <AudienceTypeButton audienceTypeKey="managed" setAudienceType={setAudienceType} campaign={campaign}>
            <BsFillPersonLinesFill className="h-12 w-12 stroke-current ml-px" />
            <span className="text-sm sm:text-base tracking-tight">Use a managed audience</span>
          </AudienceTypeButton>
        )}
      </div>
    </div>
  );
};

const AudienceTypeButton = ({ setAudienceType, audienceTypeKey, children, campaign }) => {
  const isActive = campaign.audienceType === audienceTypeKey;
  const buttonClass = isActive
    ? 'w-1/4 flex flex-col justify-evenly items-center border bg-primary text-white h-36 rounded shadow'
    : 'w-1/4 flex flex-col justify-evenly items-center border text-gray-500 bg-gray-100 h-36 rounded shadow hover:bg-primary hover:text-white';

  return (
    <button onClick={() => setAudienceType(audienceTypeKey)} className={buttonClass} type="button">
      {children}
    </button>
  );
};

const TargetEveryone = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 55.957 36.59">
    <g id="Group_12768" data-name="Group 12768" transform="translate(8574.34 -8401.081)">
      <circle
        id="Ellipse_450"
        data-name="Ellipse 450"
        cx="7.725"
        cy="7.725"
        r="7.725"
        transform="translate(-8554.088 8402.08)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <circle
        id="Ellipse_451"
        data-name="Ellipse 451"
        cx="4.788"
        cy="4.788"
        r="4.788"
        transform="translate(-8533.912 8411.076)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="Path_24381"
        data-name="Path 24381"
        d="M8.835,23.769V21.117c0-5.737,3.91-10.387,8.734-10.387h8.846c4.824,0,8.734,4.65,8.734,10.387v2.652"
        transform="translate(-8568.354 8412.9)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="Path_24382"
        data-name="Path 24382"
        d="M23.835,10.73h2.111a7.628,7.628,0,0,1,7.629,7.629v1.948"
        transform="translate(-8552.958 8415.669)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <circle
        id="Ellipse_452"
        data-name="Ellipse 452"
        cx="4.788"
        cy="4.788"
        r="4.788"
        transform="translate(-8568.39 8411.076)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="Path_24383"
        data-name="Path 24383"
        d="M10.49,10.73H8.379A7.628,7.628,0,0,0,.75,18.359v1.948"
        transform="translate(-8574.09 8415.669)"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);

const BuildAudience = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 43.206 43.527">
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_4355"
          data-name="Rectangle 4355"
          width="43.206"
          height="43.527"
          fill="none"
          strokeWidth="2"
        />
      </clipPath>
    </defs>
    <g id="Group_12769" data-name="Group 12769" transform="translate(8382.391 -8397.612)">
      <g id="Group_12753" data-name="Group 12753" transform="translate(-8382.392 8397.611)" clipPath="url(#clip-path)">
        <path
          id="Path_24384"
          data-name="Path 24384"
          d="M9.606.75a20.09,20.09,0,1,1,0,40.179"
          transform="translate(11.836 0.924)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_24385"
          data-name="Path 24385"
          d="M9.868,20.117a20.092,20.092,0,0,1-6.045-3.475"
          transform="translate(4.711 20.506)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_24386"
          data-name="Path 24386"
          d="M3.823,4.776A20,20,0,0,1,9.868,1.3"
          transform="translate(4.711 1.603)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_24387"
          data-name="Path 24387"
          d="M.75,11.8A19.992,19.992,0,0,1,3.132,5.253"
          transform="translate(0.924 6.473)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_24388"
          data-name="Path 24388"
          d="M.75,11.311a19.992,19.992,0,0,0,2.382,6.551"
          transform="translate(0.924 13.937)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_24389"
          data-name="Path 24389"
          d="M23.535,15.392a4.727,4.727,0,0,0-4.38-2.975h-9.1a4.727,4.727,0,0,0-4.382,2.975"
          transform="translate(6.996 15.3)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <circle
          id="Ellipse_453"
          data-name="Ellipse 453"
          cx="5.022"
          cy="5.022"
          r="5.022"
          transform="translate(16.581 12.835)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
);

const ExistingAudience = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 44.984 44.984">
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_4356"
          data-name="Rectangle 4356"
          width="44.984"
          height="44.984"
          fill="none"
          strokeWidth="2"
        />
      </clipPath>
    </defs>
    <g id="Group_12770" data-name="Group 12770" transform="translate(8197.281 -8396.883)">
      <g id="Group_12755" data-name="Group 12755" transform="translate(-8197.281 8396.883)" clipPath="url(#clip-path)">
        <path
          id="Path_24390"
          data-name="Path 24390"
          d="M1.59,2.515v6.3H7.9"
          transform="translate(2.078 3.287)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_24391"
          data-name="Path 24391"
          d="M37.905,11.143a20.739,20.739,0,0,0-35.939-.014"
          transform="translate(2.569 0.98)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_24392"
          data-name="Path 24392"
          d="M42.1,8.624a20.709,20.709,0,0,1-20.584,23.36l-.014-.012A20.706,20.706,0,0,1,.92,8.624"
          transform="translate(0.98 11.271)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_24393"
          data-name="Path 24393"
          d="M24.205,15.492a4.886,4.886,0,0,0-4.526-3.075h-9.4A4.886,4.886,0,0,0,5.75,15.492"
          transform="translate(7.515 16.228)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <circle
          id="Ellipse_454"
          data-name="Ellipse 454"
          cx="5.191"
          cy="5.191"
          r="5.191"
          transform="translate(17.302 13.265)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
);

const RetargetingAudience = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 44.984 44.984">
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_4358"
          data-name="Rectangle 4358"
          width="44.984"
          height="44.984"
          fill="none"
          strokeWidth="2"
        />
      </clipPath>
    </defs>
    <g id="Group_12772" data-name="Group 12772" transform="translate(8476.281 -8547.171)">
      <g id="Group_12757" data-name="Group 12757" transform="translate(-8476.281 8547.171)" clipPath="url(#clip-path)">
        <path
          id="Rectangle_4357"
          data-name="Rectangle 4357"
          d="M1.667,0h31.27A1.666,1.666,0,0,1,34.6,1.666v31.27A1.667,1.667,0,0,1,32.936,34.6H1.667A1.667,1.667,0,0,1,0,32.936V1.667A1.667,1.667,0,0,1,1.667,0Z"
          transform="translate(1.731 8.651)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_24394"
          data-name="Path 24394"
          d="M15.6,8.874a5.768,5.768,0,1,1-8.157,0,5.77,5.77,0,0,1,8.157,0"
          transform="translate(7.515 9.39)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_24395"
          data-name="Path 24395"
          d="M24.512,18.144a5.491,5.491,0,0,0-5.094-3.46H8.844a5.491,5.491,0,0,0-5.094,3.46"
          transform="translate(4.901 19.19)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_24396"
          data-name="Path 24396"
          d="M3.75,7.671V4.6A3.845,3.845,0,0,1,7.6.75H34.508A3.845,3.845,0,0,1,38.353,4.6V31.508a3.845,3.845,0,0,1-3.846,3.846H31.433"
          transform="translate(4.901 0.98)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
);

const UploadAudience = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 40.596 42.678">
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_4359"
          data-name="Rectangle 4359"
          width="40.596"
          height="42.677"
          fill="none"
          strokeWidth="2"
        />
      </clipPath>
    </defs>
    <g id="Group_12771" data-name="Group 12771" transform="translate(8288.087 -8547.17)">
      <g id="Group_12759" data-name="Group 12759" transform="translate(-8288.087 8547.171)" clipPath="url(#clip-path)">
        <line
          id="Line_443"
          data-name="Line 443"
          x1="3.839"
          y1="3.839"
          transform="translate(35.195 37.277)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_24397"
          data-name="Path 24397"
          d="M18.473,10.75A8.723,8.723,0,0,1,27.2,19.473,8.856,8.856,0,0,1,18.473,28.2a8.723,8.723,0,1,1,0-17.446"
          transform="translate(10.548 11.63)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_24398"
          data-name="Path 24398"
          d="M.75,14.75v4.164a4.163,4.163,0,0,0,4.164,4.164h8.327"
          transform="translate(0.811 15.957)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_24399"
          data-name="Path 24399"
          d="M27.4,16.323V5.914A4.163,4.163,0,0,0,23.241,1.75H10.75"
          transform="translate(11.63 1.893)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_24400"
          data-name="Path 24400"
          d="M18.449,14.207l-3.708,3.708-2.228-2.223"
          transform="translate(13.538 15.369)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_24401"
          data-name="Path 24401"
          d="M15.323,13.5H1.791A1.044,1.044,0,0,1,.75,12.455v-.845A4.371,4.371,0,0,1,5.109,7.25H12a4.371,4.371,0,0,1,4.359,4.359v.845A1.044,1.044,0,0,1,15.323,13.5Z"
          transform="translate(0.811 7.843)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_24402"
          data-name="Path 24402"
          d="M6.664,9.077A4.164,4.164,0,1,1,10.827,4.9,4.166,4.166,0,0,1,6.664,9.077Z"
          transform="translate(2.705 0.811)"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
);
