import React, { useEffect, useState } from 'react';
import * as yup from 'yup';

export const PasswordField = ({
  value = '',
  label,
  update,
  invalidText = 'Please enter your password',
  inputRef,
  'data-testid': testid,
}) => {
  const [invalid, setInvalid] = useState(false);
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (value) setTouched(true);
  }, [value]);

  useEffect(() => {
    let mounted = true;

    yup
      .string()
      .required(invalidText)
      .validate(value)
      .then(() => mounted && setInvalid(false))
      .catch(({ message }) => mounted && setInvalid(message));

    return () => (mounted = false);
  }, [value, invalidText]);

  return (
    <div>
      <input
        ref={inputRef}
        className="bz-input focus:border-gray-100 bg-gray-100 border border-gray-200 py-0"
        type="password"
        autoComplete="password"
        value={value}
        placeholder={label}
        data-testid={testid ?? 'password'}
        onChange={({ target: { value: v } = {} }) => update(v)}
        onBlur={() => setTouched(true)}
      />
      <div className="my-1 h-5 flex items-center">
        {touched && invalid && <p className="text-primary h-full">{invalid}</p>}
      </div>
    </div>
  );
};
