import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  PiBank,
  PiChartLine,
  PiGearSix,
  PiMegaphoneSimple,
  PiNotePencil,
  PiSliders,
  PiSpinner,
  PiTabsLight,
  PiWallet,
} from 'react-icons/pi';
import { useModal } from 'src/contexts';
import { useGAS } from 'src/GlobalAppState/context';
import { AuthLevels } from 'src/helpers/auth/getAuthLevel';
import { AuthSelector } from './AuthSelector';

const useMappedHref = href => {
  const { routeIds, routeIdsLoaded } = useGAS();

  return useMemo(() => {
    if (!routeIdsLoaded) {
      return null;
    }

    return Object.entries(routeIds).reduce((acc, [key, value]) => acc.replace(`{${key}}`, value), href);
  }, [href, routeIds, routeIdsLoaded]);
};

const SideBarSection = ({ children, title, name }) => (
  <div className="border-t-white/10 border-t pt-2 flex flex-col space-y-2">
    <h6 className="text-white text-sm py-2 px-3 flex-col space-y-1 hidden lg:flex">
      <span className="text-xs opacity-50 font-extralight hidden xl:block">{title}</span>
      <span className="min-h-5">{name ?? <PiSpinner className="animate-spin" />}</span>
    </h6>
    {children}
  </div>
);

const SidebarLinkInner = ({ to, className, children }) => {
  if (to) {
    return (
      <Link to={to} className={className}>
        {children}
      </Link>
    );
  }

  return <div className={`${className} pointer-events-none`}>{children}</div>;
};

const SidebarLink = ({ href, children, icon: Icon }) => {
  const mappedHref = useMappedHref(href);
  const { pathname } = useLocation();

  const active = mappedHref === pathname;

  return (
    <SidebarLinkInner
      to={mappedHref}
      className="py-1 px-3 group text-gray-400 hover:text-white flex-shrink-0 w-full rounded-lg focus:outline-none ring-primary focus:ring-2 flex justify-center lg:justify-start items-center space-x-4 text-sm select-none"
    >
      <Icon className={`w-6 h-6 stroke-current ${active ? 'text-primary' : 'text-gray-400 group-hover:text-white'}`} />
      <span className={`hidden lg:block text-left group-hover:text-white ${active ? 'text-white' : 'text-gray-400'}`}>
        {children}
      </span>
    </SidebarLinkInner>
  );
};

const BrandsLink = () => (
  <SidebarLink icon={PiTabsLight} href="/team/{teamId}">
    Brands
  </SidebarLink>
);

const CampaignsLink = () => (
  <SidebarLink icon={PiMegaphoneSimple} href="/brand/{brandId}">
    Campaigns
  </SidebarLink>
);

const AnalyticsLink = () => (
  <SidebarLink icon={PiChartLine} href="/campaign/{campaignId}">
    Performance
  </SidebarLink>
);

const CampaignEditLink = () => (
  <SidebarLink icon={PiNotePencil} href="/campaign/{campaignId}/edit">
    Edit
  </SidebarLink>
);

const BrandCreditsLink = () => (
  <SidebarLink icon={PiWallet} href="/brand/{brandId}/credits">
    Brand Credits
  </SidebarLink>
);

const TeamCreditsLink = () => (
  <SidebarLink icon={PiBank} href="/team/{teamId}/credits">
    Agency Financials
  </SidebarLink>
);

const BrandSettingsLink = () => (
  <SidebarLink icon={PiSliders} href="/brand/{brandId}/edit">
    Brand Config
  </SidebarLink>
);

const TeamSettingsLink = () => (
  <SidebarLink icon={PiGearSix} href="/team/{teamId}/edit">
    Agency Settings
  </SidebarLink>
);

const CampaignNavigation = () => {
  const { campaign } = useGAS();

  return (
    <SideBarSection title="Campaign" name={campaign?.name}>
      <AnalyticsLink />
      <CampaignEditLink />
    </SideBarSection>
  );
};

const BrandNavigation = () => {
  const { brand } = useGAS();

  return (
    <SideBarSection title="Brand" name={brand?.name}>
      <CampaignsLink />
      <BrandCreditsLink />
      <BrandSettingsLink />
    </SideBarSection>
  );
};

const TeamNavigation = () => {
  const { team } = useGAS();

  return (
    <SideBarSection title="Agency" name={team?.name}>
      <BrandsLink />
      <TeamCreditsLink />
      <TeamSettingsLink />
    </SideBarSection>
  );
};

const navModelHierarchy = { organization: 0, team: 1, brand: 2, campaign: 3 };
const authHierarchy = {
  [AuthLevels.STATIC]: 3,
  [AuthLevels.ORGANIZATION]: 2,
  [AuthLevels.TEAM]: 1,
  [AuthLevels.BRAND]: 0,
};

const isAtOrBelowNavHierarchy = (model, target) => navModelHierarchy[model] >= navModelHierarchy[target];
const isAtOrAboveAuthHierarchy = (authLevel, target) => authHierarchy[authLevel] >= authHierarchy[target];

const Sidebar = () => {
  const {
    appRouteParams: { model },
    authLevel,
  } = useGAS();

  return (
    <div className="relative w-full flex flex-col space-y-4 px-2 py-2 flex-0">
      <AuthSelector />
      {isAtOrBelowNavHierarchy(model, 'team') && isAtOrAboveAuthHierarchy(authLevel, 'team') && <TeamNavigation />}
      {isAtOrBelowNavHierarchy(model, 'brand') && <BrandNavigation />}
      {isAtOrBelowNavHierarchy(model, 'campaign') && <CampaignNavigation />}
    </div>
  );
};

export const SidebarV1 = () => {
  const { openModal } = useModal();
  const { isWhiteLabel } = useGAS();

  const onPrivacyLinkClick = () => {
    openModal('privacy-pdf');
  };

  const onWhitelabelSupportLinkClick = () => {
    openModal('wl-help');
  };

  return (
    <nav
      aria-label="Sidebar"
      className="hidden w-20 lg:w-56 xl:w-64 md:flex md:flex-shrink-0 bg-secondary flex-col justify-between max-h-screen"
    >
      <Sidebar />
      <div className="flex-0 p-3">
        {isWhiteLabel ? (
          <button
            onClick={onWhitelabelSupportLinkClick}
            type="button"
            className="button bg-white p-3 text-sm font-bold rounded text-gray-500 hover:text-gray-700 shadow-sm transition-all ease-out hover:shadow-dropLeftSm"
          >
            Help
          </button>
        ) : (
          <button
            onClick={onPrivacyLinkClick}
            type="button"
            className="text-pink-500 underline text-xs hidden max-w-full md:block hover:text-white text-left"
          >
            Terms, Conditions & Privacy
          </button>
        )}
      </div>
    </nav>
  );
};
