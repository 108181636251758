import { useState } from 'react';
import { useGAS } from 'src/GlobalAppState/context';
import { useAvailableBrandBudget } from 'src/helpers/brand/useAvailableBrandBudget';
import { selectBrandCreditSelfServiceDisabled } from 'src/selectors/organization/organizationSelectors';
import { CreditHistory } from './CreditHistory';
import { CreditOverview } from './CreditOverview';
import { CreditToggle } from './CreditToggle';

export const BrandSettingsCredits = ({ appParams, showPrimary }) => {
  const { brandCampaigns: campaigns, brandPaymentMethods, organization } = useGAS();
  const showSelfService = !selectBrandCreditSelfServiceDisabled(organization);
  const [selection, setSelection] = useState(showSelfService ? 'overview' : 'history');
  const [paymentMethodType, setPaymentMethodType] = useState('card');
  const availBudget = useAvailableBrandBudget(appParams, campaigns);
  const paymentMethods = brandPaymentMethods[paymentMethodType] ?? [];

  return (
    <div className="w-full h-full flex flex-col space-y-4 overflow-y-auto flex-grow">
      {showSelfService && (
        <div className="h-16">
          <CreditToggle selection={selection} setSelection={setSelection} />
        </div>
      )}
      {selection === 'overview' && (
        <CreditOverview
          appParams={appParams}
          paymentMethods={paymentMethods}
          paymentMethodType={paymentMethodType}
          setPaymentMethodType={setPaymentMethodType}
          availBudget={availBudget}
          showPrimary={showPrimary}
        />
      )}
      {selection === 'history' && <CreditHistory appParams={appParams} />}
    </div>
  );
};
