import React from 'react';

export const BrandPerson = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 23.5 26.6">
    <g data-name="Group 4519">
      <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" data-name="Group 4518" transform="translate(1 1)">
        <path d="M0 24.6h0a6.8 6.8 0 016.8-6.8h7.9a6.8 6.8 0 016.8 6.8h0" data-name="Path 12942" />
        <circle cx="6.5" cy="6.5" r="6.5" data-name="Ellipse 53" transform="translate(4.2)" />
      </g>
    </g>
  </svg>
);
