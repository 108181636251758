export const PixelToggle = ({ selection, setSelection, authRole }) => (
  <div className="flex items-center rounded">
    <button
      type="button"
      onClick={() => setSelection('universal')}
      className={`w-40 p-2 rounded-l h-full border-2 border-primary mr-2 ${
        selection === 'universal'
          ? 'bg-primary text-white hover:opacity-80'
          : 'bg-white text-primary hover:bg-blueGray-100'
      }`}
    >
      Universal Pixel
    </button>
    <button
      type="button"
      onClick={() => setSelection('tracking')}
      className={`w-40 p-2 rounded-r h-full border-2 border-primary mr-2 ${
        selection === 'tracking'
          ? 'bg-primary text-white hover:opacity-80'
          : 'bg-white text-primary hover:bg-blueGray-100'
      }`}
    >
      Tracking Tags
    </button>
    {authRole && (
      <button
        type="button"
        onClick={() => setSelection('custom')}
        className={`w-40 p-2 rounded-r h-full border-2 border-primary ${
          selection === 'custom'
            ? 'bg-primary text-white hover:opacity-80'
            : 'bg-white text-primary hover:bg-blueGray-100'
        }`}
      >
        Custom
      </button>
    )}
  </div>
);
