import { API } from 'aws-amplify';
import { Campaign, Brand, Team } from 'src/models';
import { getCampaign } from 'src/helpers/campaign';
import { getTeam } from 'src/helpers/team';
import { getBrand } from 'src/helpers/brand';
import { daSearchMetrics } from '@brandzooka/web/src/graphql/queries';

const getModelFilter = async (model, id) => {
  if (model === Campaign) {
    const campaign = await getCampaign(id);
    const { organizationId, teamId, brandId, id: campaignId } = campaign;

    return {
      and: [
        { campaignId: { eq: campaignId } },
        { brandId: { eq: brandId } },
        { teamId: { eq: teamId } },
        { organizationId: { eq: organizationId } },
      ],
    };
  }

  if (model === Brand) {
    const brand = await getBrand(id);
    const { organizationId, teamId, id: brandId } = brand;

    return {
      and: [{ brandId: { eq: brandId } }, { teamId: { eq: teamId } }, { organizationId: { eq: organizationId } }],
    };
  }

  if (model === Team) {
    const team = await getTeam(id);
    const { organizationId, id: teamId } = team;

    return {
      and: [{ teamId: { eq: teamId } }, { organizationId: { eq: organizationId } }],
    };
  }
};

export const getDataAppMetricsWithConversionBreakdown = async (model, id, metricType, metricKeyRange) => {
  const filter = await getModelFilter(model, id);

  if (metricType) {
    filter.and.push({ metricType: { eq: metricType } });
  }

  const variables = { filter };

  if (metricType === 'CAMPAIGN_DAILY') {
    variables.sort = { direction: 'asc', field: 'metricKey' };
  }

  if (metricKeyRange) variables.metricKeyRange = metricKeyRange;

  let nextToken;
  const results = [];

  do {
    const response = await API.graphql({
      query: daSearchMetrics,
      variables: {
        ...variables,
        nextToken,
      },
    });

    results.splice(results.length, 0, ...(response.data?.daSearchMetrics?.items || []));

    nextToken = response.data?.daSearchMetrics?.nextToken;
  } while (nextToken);

  return { items: results };
};

// eslint-disable-next-line default-param-last
export const getDataAppMetrics = async (model, id, metricType, metricKeyRange, results = [], nextToken) => {
  const filter = await getModelFilter(model, id);
  const aggregates = [
    { field: 'impressions', name: 'impressions', type: 'sum' },
    { field: 'clicks', name: 'clicks', type: 'sum' },
    { field: 'views', name: 'views', type: 'sum' },
    { field: 'spend', name: 'spend', type: 'sum' },
    { field: 'conversions', name: 'conversions', type: 'sum' },
  ];

  if (metricType) {
    filter.and.push({ metricType: { eq: metricType } });
  }

  const variables = { filter, aggregates, nextToken };

  if (metricType === 'CAMPAIGN_DAILY') {
    variables.sort = { direction: 'asc', field: 'metricKey' };
  }

  if (metricKeyRange) variables.metricKeyRange = metricKeyRange;

  return API.graphql({
    query: daSearchMetrics,
    variables,
  })
    .then(({ data: { daSearchMetrics: { items, aggregateItems, nextToken: pageNextToken } } = {} }) => ({
      items: items || [],
      totals: (aggregateItems || []).reduce(
        (obj, itm) => ({ ...obj, [itm.name]: Math.round((itm?.result?.value || 0) * 100) / 100 }),
        {}
      ),
      pageNextToken,
    }))
    .then(resp => {
      const { views = 0, impressions = 0 } = resp.totals || {};

      if (views && impressions && views > impressions) resp.totals.views = impressions;

      if (['CAMPAIGN_DAY_OF_WEEK', 'CAMPAIGN_TIME_OF_DAY'].includes(metricType)) {
        resp.items = (resp.items || [])
          .map(itm => ({ ...itm, metricKey: Number(itm.metricKey) }))
          .sort((a, b) => (a.metricKey > b.metricKey ? 1 : -1));
      }

      return {
        pageResults: [...results, ...resp.items],
        pageNextToken: resp.pageNextToken,
        totals: resp.totals,
      };
    })
    .then(({ pageResults, pageNextToken, totals }) => {
      if (pageNextToken) {
        return getDataAppMetrics(model, id, metricType, metricKeyRange, pageResults, pageNextToken);
      }

      return { items: pageResults, totals };
    })
    .catch(e => console.error(e));
};
