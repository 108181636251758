import { API } from 'aws-amplify';
import { daSearchMetrics } from '@brandzooka/web/src/graphql/queries';

const getModelFilter = ({ organizationId, teamId, brandId, campaignId }) => {
  if (campaignId) {
    return {
      and: [
        { campaignId: { eq: campaignId } },
        { brandId: { eq: brandId } },
        { teamId: { eq: teamId } },
        { organizationId: { eq: organizationId } },
      ],
    };
  }

  if (brandId) {
    return {
      and: [{ brandId: { eq: brandId } }, { teamId: { eq: teamId } }, { organizationId: { eq: organizationId } }],
    };
  }

  if (teamId) {
    return {
      and: [{ teamId: { eq: teamId } }, { organizationId: { eq: organizationId } }],
    };
  }
};

// eslint-disable-next-line default-param-last
export const getCsvMetrics = (ids, metricType, metricKeyRange, results = [], nextToken) => {
  const filter = getModelFilter(ids);
  const aggregates = [
    { field: 'impressions', name: 'impressions', type: 'sum' },
    { field: 'clicks', name: 'clicks', type: 'sum' },
    { field: 'views', name: 'views', type: 'sum' },
    { field: 'spend', name: 'spend', type: 'sum' },
    { field: 'conversions', name: 'conversions', type: 'sum' },
  ];

  if (metricType) {
    filter.and.push({ metricType: { eq: metricType } });
  }

  const variables = { filter, aggregates, nextToken };

  if (metricType === 'CAMPAIGN_DAILY') {
    variables.sort = { direction: 'asc', field: 'metricKey' };
  }

  if (metricKeyRange) variables.metricKeyRange = metricKeyRange;

  return API.graphql({
    query: daSearchMetrics,
    variables,
  })
    .then(({ data: { daSearchMetrics: { items, aggregateItems, nextToken: pageNextToken } } = {} }) => ({
      items: items || [],
      totals: (aggregateItems || []).reduce(
        (obj, itm) => ({ ...obj, [itm.name]: Math.round((itm?.result?.value || 0) * 100) / 100 }),
        {}
      ),
      pageNextToken,
    }))
    .then(resp => {
      const { views = 0, impressions = 0 } = resp.totals || {};

      if (views && impressions && views > impressions) resp.totals.views = impressions;

      if (['CAMPAIGN_DAY_OF_WEEK', 'CAMPAIGN_TIME_OF_DAY'].includes(metricType)) {
        resp.items = (resp.items || [])
          .map(itm => ({ ...itm, metricKey: Number(itm.metricKey) }))
          .sort((a, b) => (a.metricKey > b.metricKey ? 1 : -1));
      }

      return {
        pageResults: [...results, ...resp.items],
        pageNextToken: resp.pageNextToken,
        totals: resp.totals,
      };
    })
    .then(({ pageResults, pageNextToken, totals }) => {
      if (pageNextToken) {
        return getCsvMetrics(ids, metricType, metricKeyRange, pageResults, pageNextToken);
      }

      return { items: pageResults, totals };
    })
    .catch(e => console.error(e));
};
