import { useCallback, useMemo, useState } from 'react';
import { SELECT_RANGE_ALL, SELECT_RANGE_END, SELECT_START } from './campaignDatePickerVariants';

export const HIDDEN = 'hidden';
export const SETTING_SINGLE = 'setting_single';
export const SETTING_START = 'setting_start';
export const SETTING_END = 'setting_end';

/**
 * The goal of this function is to ensure that when users change
 * a datepicker variant, such as changing whether their campaign is recurring
 * or not, they get the correct local state for the new variant. This should
 * not happen under normal circumstances, as if they click the recurring toggle
 * that will reset state here, but we want handling for subscription updates
 */
const overrideStateIfChangedVariant = (variant, state) => {
  if (state === HIDDEN) return state;
  const isSingleState = state === SETTING_SINGLE;
  const isMultiState = !isSingleState;

  switch (variant) {
    case SELECT_RANGE_ALL:
      return isSingleState ? SETTING_START : state;
    case SELECT_RANGE_END:
      return isSingleState ? SETTING_END : state;
    case SELECT_START:
      return isMultiState ? SETTING_SINGLE : state;
    default:
      return HIDDEN;
  }
};

/**
 * Each of the states lies on a path. What is the next step in that path
 *  ┌──────┐  ┌───────┐
 *  │      ▼  ▼       │
 *  │      Hidden     │
 *  │     1│ 2│3│     │
 *  │      │  │ ▼     │
 *  │      ▼  │ Start │
 *  └─Single  │ │     │
 *            ▼ ▼     │
 *            End─────┘
 * Paths:
 * 1. SELECT_START
 * 2. SELECT_RANGE_END
 * 3. SELECT_RANGE_ALL
 */
const getNextState = (variant, state) => {
  if ([SETTING_SINGLE, SETTING_END].includes(state)) {
    return HIDDEN;
  }

  if (state === SETTING_START) {
    return SETTING_END;
  }

  // must be hidden. determine first visible state

  switch (variant) {
    case SELECT_RANGE_ALL:
      return SETTING_START;
    case SELECT_RANGE_END:
      return SETTING_END;
    case SELECT_START:
      return SETTING_SINGLE;
    default:
      return HIDDEN;
  }
};

const useStateForVariant = variant => {
  const [state, setState] = useState(HIDDEN);

  return [overrideStateIfChangedVariant(variant, state), setState];
};

export const useCampaignDatePickerOpenState = variant => {
  const [openState, _setState] = useStateForVariant(variant);

  const setState = useCallback(v => _setState(v), [_setState]);

  const { toggleHide, setNextState } = useMemo(
    () => ({
      toggleHide: force => setState(openState === HIDDEN && !force ? getNextState(variant, openState) : HIDDEN),
      setNextState: () => setState(getNextState(variant, openState)),
    }),
    [openState, setState, variant]
  );

  return {
    openState,
    toggleHide,
    setNextState,
  };
};
