import { API } from 'aws-amplify';
import { creativeFields } from './creativeFields';
import { putAppEvent } from '../putAppEvent';

const updateCreativeQuery = /* GraphQL */ `
  mutation UpdateCreative($input: UpdateCreativeInput!) {
    updateCreative(input: $input) {
      ${creativeFields}
    }
  }
`;

const cleanCreativeInput = creative => {
  const { dspIds: _dspIds, campaign: _campaign, ...rest } = creative;

  return rest;
};

export const updateCreative = async creative => {
  const clean = cleanCreativeInput(creative);

  if (clean.id) {
    return API.graphql({
      query: updateCreativeQuery,
      variables: {
        input: clean,
      },
    })
      .then(({ data: { updateCreative: resp } = {} }) => resp || false)
      .then(resp => {
        putAppEvent('CreativeMutation', clean);

        return resp;
      });
  }

  return Promise.reject();
};
