import React, { useState, useEffect } from 'react';
import { getDataAppMetrics } from 'src/helpers/getDataAppMetrics';
import { TotalMetricsIcon } from 'src/svgs';
import { StartEndDateSelector } from 'src/components/shared';
import { format } from 'date-fns';
import { Campaign } from 'src/models';
import { MetricsDetails } from '../shared/MetricsDetails';

export const TotalMetrics = ({ campaign = {} }) => {
  const [totals, setTotals] = useState(false);
  const [filterStart, setFilterStart] = useState(new Date());
  const [filterEnd, setFilterEnd] = useState(new Date());

  const [loading, setLoading] = useState(true);

  const { id, startDate, endDate } = campaign;

  const conversionsEnabled = !!campaign?.conversionEvents?.length;

  useEffect(() => {
    if (campaign.startDate) setFilterStart(new Date(campaign.startDate));
  }, [campaign.startDate]);

  useEffect(() => {
    if (campaign.endDate) setFilterEnd(new Date(campaign.endDate));
  }, [campaign.endDate]);

  useEffect(() => {
    let mounted = true;

    if (id) {
      const filterRange = [format(filterStart, 'yyyy-MM-dd'), format(filterEnd, 'yyyy-MM-dd')];

      getDataAppMetrics(Campaign, id, 'CAMPAIGN_DAILY', filterRange).then(resp => {
        if (mounted) {
          if (resp.totals) setTotals(resp.totals);
          setLoading(false);
        }
      });
    }

    return () => (mounted = false);
  }, [id, filterStart, filterEnd]);

  const metricDates = {
    startDate: filterStart,
    endDate: filterEnd,
    minDate: startDate ? new Date(campaign.startDate) : new Date(),
    maxDate: endDate ? new Date(campaign.endDate) : new Date(),
  };

  const onDateSelect = dates => {
    setFilterStart(dates.startDate);
    setFilterEnd(dates.endDate);
  };

  return (
    <div className="w-full flex flex-col space-y-4 rounded shadow bg-white p-4">
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-6">
          <TotalMetricsIcon className="w-11 h-11 mt-px stroke-current text-primary" />
          <h1 className="text-gray-900 text-xl font-semibold">Total Campaign Metrics</h1>
        </div>
        <div className="relative flex justify-between z-50">
          <StartEndDateSelector dates={metricDates} onDateSelect={onDateSelect} type="campaign-analytics" />
        </div>
      </div>
      <div className="w-full p-8">
        {loading && <div className="animate-pulse h-72 m-5">Loading...</div>}
        {!totals && !loading && <span>No analytics data yet.</span>}
        {!loading && totals && (
          <MetricsDetails metrics={totals} hasBorder={false} conversionsEnabled={conversionsEnabled} />
        )}
      </div>
    </div>
  );
};
