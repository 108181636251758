import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { API } from 'aws-amplify';
import { getInviteLinkByCode } from 'src/graphql/queries';
import { useUserAuth } from 'src/GlobalAppState/UserAuth';
import { AcceptInviteSignIn } from 'src/components/Auth/AcceptInviteSignIn';
import { AcceptInviteSignUp } from 'src/components/Auth/AcceptInviteSignUp';
import { AcceptInviteSignOut } from 'src/components/Auth/AcceptInviteSignOut';
import { AcceptInviteCurrentUser } from 'src/components/Auth/AcceptInviteCurrentUser';
import { AuthContainer } from 'src/components/Auth/Layout';
import { useAuthProvider } from 'src/providers/AuthProvider';
import { Spinner } from '../../../../components/shared/Spinner';

const AcceptInviteBody = ({ invite, userLoggedIn }) => {
  if (!invite) {
    return <Spinner variant="inline" />;
  }

  if (invite.currentUser) {
    return <AcceptInviteCurrentUser invite={invite} />;
  }

  if (userLoggedIn === false) {
    if (invite.existing) {
      return <AcceptInviteSignIn invite={invite} />;
    }

    return <AcceptInviteSignUp invite={invite} />;
  }

  return <AcceptInviteSignOut />;
};

export default function AcceptInvitePage() {
  const { userAuth, userLoggedIn } = useUserAuth();
  const { logo, isWhiteLabel } = useAuthProvider();
  const [invite, setInvite] = useState(null);
  const { inviteCode } = useParams();

  useEffect(() => {
    let mounted = true;

    if (userLoggedIn == null) {
      return;
    }

    const getInvite = async token => {
      const authMode = userAuth.userId ? 'AMAZON_COGNITO_USER_POOLS' : 'API_KEY';

      await API.graphql({
        query: getInviteLinkByCode,
        variables: { inviteCode: token, username: userAuth.userId },
        authMode,
      })
        .then(({ data: { getInviteLinkByCode: resp = {} } = {} }) => JSON.parse(resp))
        .then(inv => mounted && setInvite(inv));
    };

    if (inviteCode) getInvite(inviteCode);

    return () => {
      mounted = false;
    };
  }, [inviteCode, userLoggedIn, userAuth.userId]);

  return (
    <AuthContainer logo={invite?.logo ?? logo} isWhiteLabel={isWhiteLabel}>
      <AcceptInviteBody userLoggedIn={userLoggedIn} invite={invite} />
    </AuthContainer>
  );
}
