export const teamFields = /* GraphQL */ `
  id
  archived
  createdAt
  updatedAt
  organizationId
  name
  websiteUrl
  logoUrl
  industryCategoryId
  defaultBrandMarkup
  theme {
    primary
    secondary
    pallet
  }
  availableCredits
  summaryMetrics {
    items {
      summaryType
      impressions
      clicks
      views
      spend
      conversions
      graphs {
        impressions
        clicks
        views
        spend
        conversions
      }
    }
    total
  }
  segmentPackages
  organizationTeamsId
  defaultStripeId
`;
