import React, { useEffect, useState, useRef, useMemo } from 'react';
import { API } from 'aws-amplify';
import { useAppParams } from 'src/hooks/useAppParams';
import { LogoIcon } from 'src/components/shared';
import { BzS3 } from 'src/helpers';
import { CloseIcon, CheckIcon, EditIcon } from 'src/svgs';
import * as Yup from 'yup';
import { IndustryCategoryDropdown, SettingsWebsiteInput } from 'src/components/MainSettings/TeamSettings/formFields';
import { Headline } from '../../../../../../../components/MainSettings/shared/Headers';

const updateTeam = /* GraphQL */ `
  mutation UpdateTeam($input: UpdateTeamInput!, $condition: ModelTeamConditionInput) {
    updateTeam(input: $input, condition: $condition) {
      id
      organizationId
      name
      websiteUrl
      logoUrl
      industryCategoryId
    }
  }
`;

export default function TeamSettingsEditPage() {
  const appParams = useAppParams();
  const { team } = appParams || {};
  const [teamDraft, setTeamDraft] = useState(false);

  const btnRef = useRef();
  const indCatId = appParams.organization?.accountInfo?.industryCategoryId;

  const updateField = (key, value) => {
    let val = value;

    if (key === 'websiteUrl') {
      val = (value || '').replace('https://https://', 'https://').replace('http://http://', 'http://');
    }
    setTeamDraft({ ...teamDraft, [key]: val });
  };

  useEffect(() => {
    if (team) {
      setTeamDraft({
        id: team.id,
        name: team.name,
        logoUrl: team.logoUrl,
        websiteUrl: team.websiteUrl,
        industryCategoryId: team.industryCategoryId || indCatId,
        politicalSettings: team.politicalSettings,
      });
    }
  }, [team, indCatId]);
  const urlRegex =
    /^(https?):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2,15}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;

  const schema = {
    name: Yup.string().required(`Agency name is required`),
    website: Yup.string()
      .required(`URL is required`)
      .url('Must be a valid URL')
      .matches(urlRegex, 'Must be a valid URL'),
  };

  const onUpload = ({ target: { files = [] } = {} }) => {
    const [file] = files;

    if (file) {
      BzS3.getModelBaseKey(team, 'account-protected').then(baseKey => {
        const key = `${baseKey}/logos/${Date.now()}`;

        return BzS3.put(key, file).then(() => updateField('logoUrl', key));
      });
    }
  };

  const valid = teamDraft.name && teamDraft.websiteUrl && teamDraft.industryCategoryId;
  const changed = useMemo(() => {
    let hasChanged = false;

    if (teamDraft) {
      if (teamDraft.name && teamDraft.name !== team.name) hasChanged = true;
      if (teamDraft.websiteUrl && teamDraft.websiteUrl !== team.websiteUrl) hasChanged = true;
      if (teamDraft.logoUrl && teamDraft.logoUrl !== team.logoUrl) hasChanged = true;
      if (teamDraft.industryCategoryId && teamDraft.industryCategoryId !== team.industryCategoryId) {
        hasChanged = true;
      }
      const initSettings = team?.politicalSettings || { none: '' };
      const draftSettings = teamDraft?.politicalSettings || { none: '' };

      if (JSON.stringify(initSettings) !== JSON.stringify(draftSettings)) {
        hasChanged = true;
      }
    }

    return hasChanged;
  }, [teamDraft, team.name, team.websiteUrl, team.industryCategoryId, team.politicalSettings, team.logoUrl]);

  const disableSave = !valid || !changed;

  const saveDraft = () => {
    if (valid) {
      const cleanWebsite = (teamDraft?.websiteUrl || '')
        .replace('http://https://', 'https://')
        .replace('https://http://', 'http://')
        .replace('https://https://', 'https://')
        .replace('http://http://', 'http://');

      const input = {
        ...teamDraft,
        websiteUrl: cleanWebsite || '',
      };

      API.graphql({ query: updateTeam, variables: { input } });
    }
  };

  return (
    <div>
      <Headline>{team.name}</Headline>
      <div className="w-full flex space-x-16 flex-grow relative">
        <div className="h-8 w-40 z-0 absolute right-4 sm:right-12 text-center top-4 sm:top-7 pt-1 rounded hover:bg-gray-100">
          <button
            type="button"
            onClick={saveDraft}
            disabled={disableSave}
            className={`text-primary ${disableSave && 'cursor-not-allowed disabled:opacity-30 '}`}
          >
            {disableSave ? <span className="ml-1">&#10003; Changes Saved</span> : 'Save Changes'}
          </button>
        </div>
        <div className="flex flex-col items-center space-y-8 p-8">
          <div className="bg-blueGray-150 rounded-full p-12">
            <LogoIcon className="w-32 h-32" model={teamDraft} appParams={appParams} />
          </div>

          <div className="relative">
            <input
              ref={btnRef}
              type="file"
              accept="image/*"
              id="file"
              className="file w-0 h-0 absolute"
              onChange={onUpload}
            />
            <label htmlFor="file" className="cursor-pointer">
              <button type="button" onClick={() => btnRef.current.click()} className="bz-btn-solid py-3 w-full">
                Upload Logo
              </button>
            </label>
          </div>
          <p className="text-gray-400">Formats: all image formats</p>
        </div>
        <div data-mf-replace="" className="flex flex-col space-y-8 pt-12 -mt-12 sm:mt-0">
          <h1 className="text-lg pl-2 font-semibold">Basic Info</h1>
          <SettingsAttributeInput
            label="Agency Name"
            value={teamDraft.name}
            schema={schema.name}
            onSave={val => updateField('name', val)}
          />
          <SettingsWebsiteInput
            label="Agency Website"
            value={team.websiteUrl}
            schema={schema.website}
            onSave={val => updateField('websiteUrl', val)}
          />
          <IndustryCategoryDropdown
            value={teamDraft.industryCategoryId}
            onSelect={val => updateField('industryCategoryId', val)}
          />
        </div>
      </div>
    </div>
  );
}

const SettingsAttributeInput = ({ label, value, schema, onSave }) => {
  const [showEdit, setShowEdit] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [draft, setDraft] = useState('');

  const inputRef = useRef();

  useEffect(() => {
    if (showEdit && inputRef.current) inputRef.current.focus();
  }, [showEdit, inputRef]);

  useEffect(() => {
    setDraft(value || '');
  }, [value]);

  useEffect(() => {
    const setError = msg => invalid !== msg && setInvalid(msg);

    if (schema) {
      schema
        .validate(draft)
        .then(() => setError(false))
        .catch(({ message }) => setError(message));
    }
  }, [schema, draft, setInvalid, invalid]);

  const onSaveClick = () => {
    if (!invalid) {
      onSave(draft);
      setShowEdit(false);
    }
  };

  const onEditClick = () => {
    if (showEdit) {
      setDraft(value);
      setShowEdit(false);
    } else {
      setShowEdit(true);
    }
  };

  return (
    <div>
      <div className="flex items-center space-x-4 h-14">
        <button type="button" onClick={onEditClick} className="bz-btn-icon w-8 h-8 p-1">
          {!showEdit && <EditIcon className="w-full h-full text-primary stroke-current" />}
          {showEdit && <CloseIcon className="w-5 h-5 text-gray-400 stroke-current" />}
        </button>
        {!showEdit && (
          <div className="flex flex-col">
            <h2 className="font-medium">{label}</h2>
            <h2 className="text-gray-500 text-lg">{draft}</h2>
          </div>
        )}
        {showEdit && (
          <div className="flex items-center space-x-3">
            <input
              ref={inputRef}
              type="text"
              placeholder={label}
              value={draft}
              onChange={({ target: { value: val } = {} }) => setDraft(val)}
              className="bz-input h-12"
            />
            <button type="button" onClick={onSaveClick} className="bz-btn-icon p-1">
              <div className="flex items-center justify-center w-8 h-8 border-2 border-green-400 rounded-full">
                <CheckIcon className="w-4 h-4 text-green-400 stroke-current" />
              </div>
            </button>
          </div>
        )}
      </div>
      {invalid && (
        <div className="my-1 h-5 flex justify-self-center">
          <p className="text-primary h-full">{invalid}</p>
        </div>
      )}
    </div>
  );
};
