import { API } from 'aws-amplify';
import { useCallback, useEffect, useState } from 'react';
import { AuthModels } from '../auth/getAuthModel';

const getCampaignQuery = /* GraphQL */ `
  query GetCampaign($id: ID!) {
    getCampaign(id: $id) {
      id
      brandId
      teamId
      organizationId
    }
  }
`;

const getCampaignIds = id =>
  API.graphql({
    query: getCampaignQuery,
    variables: { id },
  })
    .then(({ data: { getCampaign: resp } = {} }) => resp || {})
    .then(resp => {
      if (!resp) return {};

      return {
        campaignId: resp.id,
        brandId: resp.brandId,
        teamId: resp.teamId,
        organizationId: resp.organizationId,
      };
    });

const getBrandQuery = /* GraphQL */ `
  query GetBrand($id: ID!) {
    getBrand(id: $id) {
      id
      teamId
      organizationId
    }
  }
`;

const getBrandIds = id =>
  API.graphql({
    query: getBrandQuery,
    variables: { id },
  })
    .then(({ data: { getBrand: resp } = {} }) => resp || {})
    .then(resp => {
      if (!resp) return {};

      return {
        brandId: resp.id,
        teamId: resp.teamId,
        organizationId: resp.organizationId,
      };
    });

const getTeamQuery = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      organizationId
    }
  }
`;

const getTeamIds = id =>
  API.graphql({
    query: getTeamQuery,
    variables: { id },
  })
    .then(({ data: { getTeam: resp } = {} }) => resp || {})
    .then(resp => {
      if (!resp) return {};

      return {
        teamId: resp.id,
        organizationId: resp.organizationId,
      };
    });

export const useRouteIds = (userAuth, routeParams) => {
  const [routeIds, setRouteIds] = useState(null);

  const { model, id } = routeParams;

  const callback = useCallback(
    params => {
      setRouteIds({
        campaignId: null,
        brandId: null,
        teamId: null,
        organizationId: null,
        ...(params ?? {}),
      });
    },
    [setRouteIds]
  );

  useEffect(() => {
    if (!userAuth?.userId) {
      return;
    }

    switch (model) {
      case AuthModels.CAMPAIGNS:
        getCampaignIds(id).then(callback);
        break;
      case AuthModels.BRANDS:
        getBrandIds(id).then(callback);
        break;
      case AuthModels.TEAMS:
        getTeamIds(id).then(callback);
        break;
      case AuthModels.ORGANIZATIONS:
        callback({
          organizationId: id,
        });
        break;
      default:
        callback();
        break;
    }
  }, [callback, id, model, userAuth?.userId]);

  return {
    routeIds: routeIds ?? {},
    loaded: !!routeIds,
  };
};
