import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { API } from 'aws-amplify';
import * as SafeAuth from 'src/helpers/SafeAuth';
import { checkInviteLinkEmail } from 'src/graphql/queries';
import { acceptInviteLink } from 'src/graphql/mutations';
import { refreshToken } from 'src/helpers';
import { EmailField } from './EmailField';
import { PasswordField } from './PasswordField';
import { Spinner } from '../shared/Spinner';

export const AcceptInviteSignIn = ({ invite }) => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { inviteCode, name } = invite;

  const signIn = async () => {
    setErrorMessage('');

    await API.graphql({
      query: checkInviteLinkEmail,
      variables: { inviteCode, email },
      authMode: 'API_KEY',
    })
      .then(({ data: { checkInviteLinkEmail: resp } = {} }) => resp)
      .then(emailMatch => {
        if (!emailMatch) {
          throw Error('email does not match invite');
        }
      })
      .then(() => SafeAuth.signIn({ username: email, password }))
      .then(() => {
        setLoading(true);

        return API.graphql({
          query: acceptInviteLink,
          variables: { inviteCode, status: 'accepted' },
        });
      })
      .then(refreshToken)
      .then(() => history.push('/'))
      .catch(err => {
        setLoading(false);
        if (err.code === 'UserNotConfirmedException') {
          history.push(`/auth/confirm-signup?email=${encodeURIComponent(email)}`);

          return;
        }
        setErrorMessage(err.message);
      });
  };

  const handleForgotPassword = async () => {
    try {
      await SafeAuth.forgotPassword(email, { domain: window.location.host });

      history.push('/auth/forgot-password');
    } catch (e) {
      console.error(e);
    }
  };

  const passwordFieldRef = useRef();
  const valid = email && password;

  return (
    <>
      <div className="text-center font-medium">
        <p className="text-lg">You've been invited to join</p>
        <h1 className="text-2xl">{name}</h1>
      </div>
      <div data-mf-replace="" className="flex flex-col w-full relative">
        <EmailField className="p-0 w-full" label="Email" value={email} update={setEmail} />
        <PasswordField
          inputRef={passwordFieldRef}
          className="p-0"
          label="Password"
          value={password}
          update={setPassword}
        />
        <div className="relative h-0 flex items-center text-left space-x-2 w-full">
          {errorMessage && <h3 className="text-lg px-4 rounded-l-full rounded-r bg-red-700 text-white">error</h3>}
          {errorMessage && <h3 className="text-white text-lg">{errorMessage}</h3>}
        </div>
        <button
          type="button"
          onClick={signIn}
          disabled={!valid}
          className={`w-full border-0 bz-btn-solid text-white mt-8 h-12 font-medium rounded-lg ${
            valid ? 'bg-secondary' : 'bg-secondary opacity-50 cursor-not-allowed hover:opacity-50'
          }`}
        >
          Accept Invitation
        </button>
        <div className="w-full pt-6">
          <button type="button" className="text-base cursor-pointer text-white" onClick={handleForgotPassword}>
            Forgot password?
          </button>
        </div>
        {loading && (
          <div className="absolute inset-0 flex items-center justify-center bg-white">
            <Spinner />
          </div>
        )}
      </div>
    </>
  );
};
