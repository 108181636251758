import React, { useState, useEffect, useMemo } from 'react';
import { TopSitesIcon } from 'src/svgs';
import { getDataAppMetrics } from 'src/helpers/getDataAppMetrics';
import { getCampaignCreatives } from 'src/helpers/campaign';
import { Campaign } from 'src/models';
import { MetricsDetails } from '../shared/MetricsDetails';
import { CreativeCard } from '../../CampaignBuilder/CampaignCreatives/CreativeCard';
import { DownloadCsvButton } from '../shared/DownloadCsvButton';

export const Creatives = ({ campaign = {} }) => {
  const [creatives, setCreatives] = useState([]);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let mounted = true;

    if (campaign.id) {
      setLoading(true);
      getDataAppMetrics(Campaign, campaign.id, 'CAMPAIGN_CREATIVES').then(metrcs => {
        getCampaignCreatives(campaign.id, false)
          .then(crtvs => {
            if (mounted) {
              const filteredCreatives = crtvs.map(({ __typename, ...rest }) => rest);
              const creativeMetrics = getCreativeMetrics(filteredCreatives, metrcs.items) || [];
              const filteredCreativeMetrics = creativeMetrics.filter(c => c.metrics.impressions > 0);
              const [defaultSelect] = filteredCreativeMetrics.sort(
                (a, b) => b.metrics.impressions - a.metrics.impressions
              );

              setSelected(defaultSelect);
              setCreatives(filteredCreativeMetrics);
              setLoading(false);
            }
          })
          .catch(() => []);
      });
    }

    return () => (mounted = false);
  }, [campaign.id, campaign.creatives]);

  const downloadableMetrics = useMemo(
    () => creatives.map(creative => ({ ...creative.metrics, metricKey: creative.name })),
    [creatives]
  );

  const conversionsEnabled = !!campaign?.conversionEvents?.length;

  return (
    <div className="w-full flex flex-col space-y-4 rounded shadow bg-white p-4">
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-6">
          <TopSitesIcon className="w-11 h-11 mt-px stroke-current text-primary" />
          <h1 className="text-gray-900 text-xl font-semibold">Creatives</h1>
        </div>
        <div>
          <DownloadCsvButton
            metricType="CAMPAIGN_CREATIVES"
            metrics={downloadableMetrics}
            loading={loading}
            campaign={campaign}
          />
        </div>
      </div>
      {loading ? (
        <div className="animate-pulse h-72 px-8">Loading...</div>
      ) : (
        <>
          <div className="w-full overflow-x-scroll">
            <CreativesButtons creatives={creatives} selected={selected} setSelected={setSelected} />
          </div>
          <MetricsDetails metrics={selected?.metrics} conversionsEnabled={conversionsEnabled} />
        </>
      )}
    </div>
  );
};

const CreativesButtons = ({ creatives, selected, setSelected }) => {
  const carousel = creatives.map(c => {
    const active = selected.id === c.id;

    return (
      <div className="flex w-60 mr-8" key={c.id}>
        <CreativeCard
          creative={c}
          onClick={() => setSelected(c)}
          modalData={{}}
          type="analytics"
          isActive={active}
          readOnly
        />
      </div>
    );
  });

  return (
    <div className="flex">
      <div className="flex">{carousel}</div>
    </div>
  );
};

const getCreativeMetrics = (creatives, metrics) => {
  const creativeMetrics = [];

  for (const creative of creatives) {
    const [cmet] = metrics.filter(m => m.metricKey === creative.id);

    if (cmet) {
      creativeMetrics.push({ ...creative, metrics: cmet });
    } else {
      creativeMetrics.push({
        ...creative,
        metrics: {
          impressions: 0,
          spend: 0,
          views: 0,
          clicks: 0,
          conversions: 0,
        },
      });
    }
  }

  return creativeMetrics;
};
