import { API } from 'aws-amplify';
import { trackingTagFields } from './trackingTagFields';
import { putAppEvent } from '../putAppEvent';

const updateTrackingTagMutation = /* GraphQL */ `
  mutation UpdateTrackingTag($input: UpdateTrackingTagInput!) {
    updateTrackingTag(input: $input) {
      ${trackingTagFields}
    }
  }
`;

export const deleteTrackingTag = id => {
  const input = { id, archived: true };

  return API.graphql({
    query: updateTrackingTagMutation,
    variables: { input },
  })
    .then(({ data: { updateTrackingTag: resp } }) => resp || false)
    .then(resp => {
      putAppEvent('TrackingTagMutation', input);

      return resp;
    });
};
