import { Link } from 'react-router-dom';
import { AuthContainer } from 'src/components/Auth/Layout';
import { useAuthProvider } from 'src/providers/AuthProvider';
import { useAlreadyAuthedRootRedirect } from 'src/hooks/authHooks';

export default function ForgotEmailPage() {
  const { logo, isWhiteLabel } = useAuthProvider();

  useAlreadyAuthedRootRedirect();

  return (
    <AuthContainer logo={logo} isWhiteLabel={isWhiteLabel}>
      <div>
        <h1 className="text-3xl mb-4">Forgot your email?</h1>
        <h2 className="text-sm">It's all good! You can chat with us and we'll help you login.</h2>
        <div className="flex space-x-4 mt-8">
          <button
            type="button"
            onClick={() => window.Intercom?.('showNewMessage')}
            className="w-full bz-btn-solid mt-4 h-12 font-medium text-sm"
          >
            Contact Us
          </button>
          <Link to="/auth/sign-in" className="w-full bz-btn-solid mt-4 h-12 font-medium text-sm">
            Return to Login
          </Link>
        </div>
      </div>
    </AuthContainer>
  );
}
