import NumberFormat from 'react-number-format';
import { ColumnCell, ColumnHeader } from './Components';

export const CTRColumn = {
  key: 'ctr',
  header: () => <ColumnHeader className="w-32">CTR</ColumnHeader>,
  cell: ({ metrics }) => {
    const { impressions = 0, clicks = 0 } = metrics;
    const ctr = (clicks / (impressions || 1)) * 100 || 0;

    return (
      <ColumnCell>
        {ctr ? (
          <NumberFormat
            className="text-lg text-gray-600"
            displayType="text"
            value={ctr}
            thousandSeparator
            fixedDecimalScale
            decimalScale={3}
          />
        ) : (
          <span className="text-lg text-gray-600">--</span>
        )}
      </ColumnCell>
    );
  },
};
