import { useMemo } from 'react';
import { createAPIQueryHook, createAPIQuerySubscriptionHook } from '../../hooks/useAPIQuery';

const fields = /* GraphQL */ `
  {
    id
    brandId
    teamId
    organizationId
    createdAt
    updatedAt
    status
    email
    userId
    authRole
  }
`;

const listUserAuthRoles = /* GraphQL */ `
  query ListUserAuthRoles($filter: ModelUserAuthRoleFilterInput, $limit: Int, $nextToken: String) {
    authRoles: listUserAuthRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items ${fields}
    }
  }
`;

const getUserAuthRole = /* GraphQL */ `
  query GetUserAuthRoles($id: ID!) {
    result: getUserAuthRole(id: $id) ${fields}
  }
`;

const onCreateUserAuthRole = /* GraphQL */ `
  subscription OnCreateUserAuthRole($organizationId: String) {
    create: onCreateUserAuthRole(organizationId: $organizationId) ${fields}
  }
`;

const onUpdateUserAuthRole = /* GraphQL */ `
  subscription OnUpdateUserAuthRole($organizationId: String) {
    update: onUpdateUserAuthRole(organizationId: $organizationId) ${fields}
  }
`;

const onDeleteUserAuthRole = /* GraphQL */ `
  subscription OnDeleteUserAuthRole($organizationId: String) {
    delete: onDeleteUserAuthRole(organizationId: $organizationId) ${fields}
  }
`;

const useListUserAuthRoles = createAPIQueryHook({
  query: listUserAuthRoles,
  resultPath: 'authRoles.items',
  nextTokenPath: 'authRoles.nextToken',
  paged: true,
});

const useAuthRoleCreateSubscription = createAPIQuerySubscriptionHook({
  subscription: onCreateUserAuthRole,
  query: getUserAuthRole,
});

const useAuthRoleUpdateSubscription = createAPIQuerySubscriptionHook({
  subscription: onUpdateUserAuthRole,
  query: getUserAuthRole,
});

const useAuthRoleDeleteSubscription = createAPIQuerySubscriptionHook({
  subscription: onDeleteUserAuthRole,
  query: getUserAuthRole,
});

const useAuthRoleSubscription = (...args) => {
  useAuthRoleCreateSubscription(...args);
  useAuthRoleUpdateSubscription(...args);
  useAuthRoleDeleteSubscription(...args);
};

const useRollup = (roles, { loading, completed }) =>
  useMemo(
    () =>
      roles.reduce(
        (acc, role) => {
          acc.organization.push(role);

          if (role.teamId) {
            acc.teams[role.teamId] = acc.teams[role.teamId] || [];
            acc.teams[role.teamId].push(role);
          }

          if (role.brandId) {
            acc.brands[role.brandId] = acc.brands[role.brandId] || [];
            acc.brands[role.brandId].push(role);
          }

          return acc;
        },
        { organization: [], teams: {}, brands: {}, loading, completed }
      ),
    [completed, loading, roles]
  );

export const useOrganizationAuthRoles = ({ organizationId }) => {
  const disable = !organizationId;

  const variables = useMemo(
    () => ({
      filter: {
        organizationId: {
          eq: organizationId,
        },
      },
    }),
    [organizationId]
  );

  const { data, subscriptionHandler, loading, completed } = useListUserAuthRoles(variables, { disable });

  useAuthRoleSubscription({ organizationId }, { subscriptionHandler, disable });

  return useRollup(data ?? [], { loading, completed });
};
