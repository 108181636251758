import { useCallback, useEffect, useState } from 'react';
import { add, endOfDay } from 'date-fns';
import { useGAS } from 'src/GlobalAppState/context';
import { useAvailableBrandBudget } from 'src/helpers/brand/useAvailableBrandBudget';
import { CampaignDatePicker } from 'src/components/shared/CampaignDatePicker';
import { useCampaignDraftSchemaValidator } from 'src/selectors/campaign/budgetValidationSelectors';
import { getCampaignAdgroups } from 'src/helpers';
import { AdgroupsWarningPanel, AdgroupsBudgetList } from 'src/components/shared/AdgroupsBudgetSplit';
import { GoalSelector } from './GoalSelector';
import { RecurringPanel } from './RecurringPanel';
import { AdvancedPanel } from './AdvancedPanel';
import { PoliticalConfiguration } from './PoliticalConfiguration';
import { CurrencyInput } from './formFields/CurrencyInput';
import { ErrorBox } from './formFields/ErrorBox';

const GOALS_INTERCOM_LINK =
  'http://help.brandzooka.com/en/articles/8590051-mastering-campaign-goals-a-comprehensive-guide-to-optimize-and-achieve-success';
const BUDGET_ALLOCATION_INTERCOM_LINK =
  'http://help.brandzooka.com/en/articles/8813887-understanding-budget-allocation-in-multi-channel-campaigns';

export const BasicsEdit = ({ campaignDraft, creatives, setCampaignDraft, appParams }) => {
  const { goal, isRecurring, recurrenceWindow } = campaignDraft;
  const { campaign, brand, isWhiteLabel } = appParams;
  const { status } = campaign;
  const disabled = status === 'completed';
  const { brandCampaigns: campaigns } = useGAS();
  const [adgroups, setAdgroups] = useState([]);

  const availCredits = useAvailableBrandBudget(appParams, campaigns);
  const validationError = useCampaignDraftSchemaValidator(campaignDraft);

  const isPoliticalBrand = !!brand?.politicalSettings?.electionType;

  useEffect(() => {
    const campaignAdgroups = getCampaignAdgroups(campaignDraft, creatives);

    setAdgroups(campaignAdgroups);
  }, [campaignDraft, creatives]);

  const onGoalSelect = value => setCampaignDraft({ ...campaignDraft, goal: value });

  const onDateSelect = useCallback(
    dates => {
      const draft = {
        ...campaignDraft,
        ...dates,
      };

      if (campaignDraft.isRecurring) {
        const endOfDayDate = endOfDay(add(new Date(draft.startDate), { days: draft.recurrenceWindow })).toISOString();

        Object.assign(draft, {
          draftChanged: true,
          endDate: endOfDayDate,
          lastRecurredAt: dates.startDate, // This impacts recurrence budget calculations in processCredits
        });
      }

      setCampaignDraft(draft);
    },
    [campaignDraft, setCampaignDraft]
  );

  const handleBudgetChange = useCallback(
    e => {
      if (disabled) {
        return;
      }

      const parsed = parseFloat(e.target.value);

      setCampaignDraft({
        ...campaignDraft,
        budget: Number.isFinite(parsed) ? parsed : null,
      });
    },
    [campaignDraft, disabled, setCampaignDraft]
  );

  return (
    <div className="md:w-192 lg:w-full">
      <div className="flex flex-col w-full space-y-8 p-4 sm:p-8">
        <h3 className="text-primary">
          Fill out the basics of your campaign here. Included is the campaign's goal, budget, and run dates.
        </h3>

        {isPoliticalBrand && (
          <div className="flex w-full flex-col space-y-4">
            <h2 className="text-lg font-semibold">Political Advertising Disclosure</h2>
            <h2 className="text-m">Confirm the candidate or ballot measure including paying entity information</h2>
            <PoliticalConfiguration campaignDraft={campaignDraft} setCampaignDraft={setCampaignDraft} brand={brand} />
          </div>
        )}
        <div className="flex w-full flex-col space-y-4">
          <div className="flex flex-row items-center">
            <h2 className="text-lg font-semibold">Campaign Goal</h2>
            <div className="flex-1" />
            {!isWhiteLabel && (
              <a
                href={GOALS_INTERCOM_LINK}
                target="_blank"
                rel="noreferrer"
                className="underline text-gray-500 hover:text-black"
              >
                How do campaign goals work?
              </a>
            )}
          </div>
          <GoalSelector goal={goal || 'impressions'} onSelect={onGoalSelect} status={status} disabled={disabled} />
        </div>
        <div className="flex w-full flex-col space-y-4">
          <h2 className="text-lg font-semibold">{campaignDraft.isRecurring ? 'Start Date' : 'Run Dates'}</h2>
          <CampaignDatePicker campaign={campaignDraft} onChange={onDateSelect} />
        </div>
        <RecurringPanel
          campaignDraft={campaignDraft}
          setCampaignDraft={setCampaignDraft}
          appParams={appParams}
          status={status}
          availCredits={availCredits}
        />
        {campaignDraft.isRecurring ? (
          <div />
        ) : (
          <div className="flex w-full flex-col space-y-4">
            <h2>
              <span className="text-lg font-semibold pr-2">Budget</span>
              <span className="text-lg text-primary">(Available Credits: ${availCredits})</span>
            </h2>
            <h3 className="text-gray-800 font-medium flex-grow-0">Total Dollar Amount</h3>
            <CurrencyInput
              onChange={handleBudgetChange}
              value={campaignDraft.budget}
              error={validationError}
              disabled={disabled}
            />
            <div className="flex flex-row space-x-4 items-center">
              {validationError && <ErrorBox>{validationError.message}</ErrorBox>}
            </div>
          </div>
        )}
        {!!adgroups.length && (
          <div className="flex w-full text-sm">
            <div className="w-1/2 border-b">
              <div>Budget Breakdown</div>
              {!!(adgroups || []).find(a => a.warning) && <AdgroupsWarningPanel adgroups={adgroups} />}
              <div className="w-full text-xs pt-2 ">
                <AdgroupsBudgetList adgroups={adgroups} isRecurring={isRecurring} recurrenceWindow={recurrenceWindow} />
              </div>
            </div>
            <div className="mx-auto">
              {!isWhiteLabel && (
                <a
                  href={BUDGET_ALLOCATION_INTERCOM_LINK}
                  target="_blank"
                  rel="noreferrer"
                  className="underline text-gray-500 hover:text-black"
                >
                  How does Multi-Channel Budget Allocation Work?
                </a>
              )}
            </div>
          </div>
        )}
      </div>
      <AdvancedPanel status={status} campaignDraft={campaignDraft} setCampaignDraft={setCampaignDraft} />
    </div>
  );
};
