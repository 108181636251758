import { useCallback, useMemo, useState } from 'react';

const PREFIX = 'bz';

/**
 * Abstracts lifecycle for accessing localStorage
 * @class {LocalStorageHelper}
 */
class LocalStorageHelper {
  cache = new Map();

  constructor() {
    ['getItem', 'setItem', 'removeItem'].forEach(fnName => {
      this._scopeItemKey(fnName);
    });
  }

  _scopeItemKey(fnKey) {
    const fn = this[fnKey];
    const wrapped = function (itemKey, ...args) {
      return fn.call(this, `${PREFIX}.${itemKey}`, ...args);
    };

    this[fnKey] = wrapped.bind(this);
  }

  /**
   * Accessor and parser for local storage values
   * @param {string} itemKey Index value used to access storage
   * @returns {undefined | any} The local storage value if parseable
   */
  getItem(itemKey) {
    if (!this.cache.has(itemKey)) {
      const storedValue = localStorage.getItem(itemKey);

      if (typeof storedValue === 'string') {
        try {
          const parsedValue = JSON.parse(storedValue);

          this.cache.set(itemKey, parsedValue);
        } catch (e) {
          console.error('Invalid storage data', itemKey, storedValue, e);
          this.cache.set(itemKey, undefined);
        }
      } else {
        this.cache.set(itemKey, undefined);
      }
    }

    return this.cache.get(itemKey);
  }

  /**
   * Serializes and stores values in local storage
   * @param {string} itemKey Index value used to access storage
   * @param {any} value Value to store in local storage as json
   */
  setItem(itemKey, value) {
    try {
      const strValue = JSON.stringify(value);

      this.cache.delete(itemKey);
      localStorage.setItem(itemKey, strValue);
      this.getItem(itemKey); // store in cache
    } catch (e) {
      console.error(e);
    }
  }

  removeItem(itemKey) {
    this.cache.delete(itemKey);
    localStorage.removeItem(itemKey);
  }
}

const localStorageHelper = new LocalStorageHelper();

/**
 * Hook to expose local storage values.
 * This does NOT share state for multiple instances.
 * @param {string} itemKey - use to access local storage data
 * @return {[any, any => void, { persist: () => void, clear: () => void }]}
 */
export const useLocalStorageState = (itemKey, defaultValue, forceDefault = false) => {
  const [value, setValue] = useState(() =>
    forceDefault ? defaultValue : localStorageHelper.getItem(itemKey) ?? defaultValue
  );

  const persist = useCallback(() => {
    localStorageHelper.setItem(itemKey, value);
  }, [value, itemKey]);

  const clear = useCallback(() => {
    localStorageHelper.removeItem(itemKey);
  }, [itemKey]);

  const handlers = useMemo(
    () => ({
      clear,
      persist,
    }),
    [clear, persist]
  );

  return [value, setValue, handlers];
};

export default localStorageHelper;
