import { createAPIQueryHook, createAPIQuerySubscriptionHook } from '../../hooks/useAPIQuery';
import { teamFields } from './teamFields';

const getTeamQuery = /* GraphQL */ `
  query GetTeam($id: ID!) {
    result: getTeam(id: $id) {
      ${teamFields}
    }
  }
`;

const useTeamQuery = createAPIQueryHook({
  query: getTeamQuery,
});

const onUpdateTeam = /* GraphQL */ `
  subscription OnUpdateTeam($id: String, $organizationId: String) {
    update: onUpdateTeam(id: $id, organizationId: $organizationId) {
      id
      organizationId
    }
  }
`;

const useTeamSubscription = createAPIQuerySubscriptionHook({
  subscription: onUpdateTeam,
  query: getTeamQuery,
});

export const useTeam = ({ organizationId, teamId }) => {
  const disable = !organizationId || !teamId;
  const { data: team, subscriptionHandler } = useTeamQuery({ id: teamId }, { disable });

  useTeamSubscription({ id: teamId, organizationId }, { disable, subscriptionHandler });

  return team ?? {};
};
