import { API } from 'aws-amplify';
import { bzColorPallets } from 'src/helpers';
import { daSearchMetricsGeoAgg } from 'src/graphql/queries';
import Color from 'color';

const daSearchSegmentsQuery = /* GraphQL */ `
  query DaSearchSegments($filter: SearchableSegmentFilterInput, $limit: Int, $nextToken: String) {
    daSearchSegments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        segmentType
        segmentGroup
        segmentPackageName
        segmentPackageId
        pathKey
        name
        geoJson {
          type
          coordinates
        }
      }
      nextToken
    }
  }
`;

const daSearchSegmentGeoIntersect = /* GraphQL */ `
  query DaSearchSegmentGeoIntersect($id: ID!, $segmentGroup: String, $limit: Int, $nextToken: String) {
    daSearchSegmentGeoIntersect(id: $id, segmentGroup: $segmentGroup, limit: $limit, nextToken: $nextToken) {
      items {
        id
        segmentType
        segmentGroup
        segmentPackageName
        segmentPackageId
        pathKey
        name
        geoJson {
          type
          coordinates
        }
      }
      total
      nextToken
    }
  }
`;

export const getCountries = inputSegments => {
  const countryPrefixSet = new Set();

  for (const { pathKey } of inputSegments || []) {
    const [prefix] = (pathKey || '').split(' > ');

    if (prefix) countryPrefixSet.add(prefix);
  }

  const prefixes = Array.from(countryPrefixSet);
  const filter = { and: [{ segmentType: { eq: 'GEO' } }, { segmentGroup: { eq: 'Country' } }] };
  const orArr = [];

  for (const prefix of prefixes || []) {
    orArr.push({ pathKey: { matchPhrasePrefix: prefix } });
  }

  if (orArr.length) filter.and.push({ or: orArr });

  return API.graphql({
    query: daSearchSegmentsQuery,
    variables: { filter },
  }).then(({ data: { daSearchSegments: { items } } = {} }) => items || []);
};

export const getRegions = (inputSegments, next) => {
  const countryPrefixSet = new Set();

  for (const { pathKey } of inputSegments || []) {
    const [prefix] = (pathKey || '').split(' > ');

    if (prefix) countryPrefixSet.add(prefix);
  }

  const prefixes = Array.from(countryPrefixSet);
  const filter = { and: [{ segmentType: { eq: 'GEO' } }, { segmentGroup: { eq: 'Region' } }] };
  const orArr = [];

  for (const prefix of prefixes || []) {
    orArr.push({ pathKey: { matchPhrasePrefix: `${prefix} > ` } });
  }

  if (orArr.length) filter.and.push({ or: orArr });

  const variables = { filter, limit: 27 };

  if (next) variables.nextToken = next;

  return API.graphql({
    query: daSearchSegmentsQuery,
    variables,
  }).then(({ data: { daSearchSegments: { items, nextToken } } = {} }) => {
    if (nextToken) return getRegions(inputSegments, nextToken).then(resp => [...items, ...resp]);

    return items || [];
  });
};

export const getZips = segmentId => {
  const variables = { id: segmentId, segmentGroup: 'Zip', limit: 1000 };

  return API.graphql({
    query: daSearchSegmentGeoIntersect,
    variables,
  }).then(({ data: { daSearchSegmentGeoIntersect: { items } } = {} }) => items);
};

export const searchSegmentsFn = inputSegments => {
  const filter = { and: [{ segmentType: { eq: 'GEO' } }] };
  const orArr = [];

  for (const { pathKey } of inputSegments || []) {
    orArr.push({ pathKey: { eq: pathKey } });
  }

  if (orArr.length) filter.and.push({ or: orArr });

  return API.graphql({
    query: daSearchSegmentsQuery,
    variables: { filter },
  }).then(({ data: { daSearchSegments: { items } } = {} }) => items || []);
};

export const geoAggFn = (campaignId, segments, totalImp, budget) => {
  const segmentsInput = (segments || []).map(({ id, name }) => ({ id, name }));

  return API.graphql({
    query: daSearchMetricsGeoAgg,
    fetchPolicy: 'network-only',
    variables: {
      campaignId,
      segments: segmentsInput,
    },
  }).then(({ data: { daSearchMetricsGeoAgg: { items } } = {} }) => {
    const itemsMap = items.reduce((obj, { name, ...itm }) => ({ ...obj, [name]: itm }), {});
    const total = items.reduce((acc, itm) => acc + itm.impressions, 0);
    const offset = total && totalImp && total < totalImp ? totalImp / total : 1;

    return segments
      .map(segment => {
        const metrics = itemsMap[segment.name] || {
          impressions: 0,
          views: 0,
          spend: 0,
          clicks: 0,
          conversions: 0,
        };

        metrics.impressions = Math.floor(metrics.impressions * offset);
        metrics.views = Math.floor(metrics.views * offset);
        metrics.spend = Math.floor(metrics.spend * offset * 100) / 100;

        if (metrics.spend > budget) metrics.spend = budget;

        return { ...segment, metrics };
      })
      .sort((a, b) => (a.metrics.impressions > b.metrics.impressions ? -1 : 1));
  });
};

export const shortNumber = numStr => {
  const num = Number(numStr);

  if (num > 999 && num < 1000000) return num.toLocaleString();
  if (num > 1000000) return `${(num / 1000000).toFixed(1)}M`;
  if (num > 1000000000) return `${(num / 1000000).toFixed(1)}B`;
  if (num < 1000) return num;
};

export const buildColorArray = (pallet = 'The Blues', length = 6) => {
  const { [pallet]: { colors = [] } = {} } = bzColorPallets || {};
  const darkToLightPallet = colors.sort((a, b) => (new Color(a).luminosity() > new Color(b).luminosity() ? 1 : -1));
  // const gradientTo = new Color(darkToLightPallet[1]).lighten(0.5);
  // const gradientTo = new Color(darkToLightPallet[0]).mix(new Color(darkToLightPallet[1]).lighten(0.5));
  const gradientFrom = new Color(darkToLightPallet[0]);
  const gradientTo = gradientFrom.mix(new Color(darkToLightPallet[1]), 1.2).lighten(0.2);
  const darkToLight = [gradientFrom, gradientTo];

  if (length < darkToLight.length) return darkToLight.slice(0, length);

  const colorLength = darkToLight.length;
  const num = Math.floor(length / colorLength);

  const mod = length % darkToLight.length;

  const newArr = [];

  for (const [idx, col] of darkToLight.entries()) {
    const color1 = new Color(col);
    const color2 = new Color(darkToLight[idx + 1] || '#FFF');

    const numBetween = idx < mod ? num + 1 : num;
    const percent = Math.floor((1 / numBetween) * 1000) / 1000;

    for (const [idxBetween] of Array(numBetween).fill().entries()) {
      if (!idxBetween) {
        newArr.push(color1.hex());
      } else {
        newArr.push(color1.mix(color2, percent * idxBetween).hex());
      }
    }
  }

  return newArr;
};
