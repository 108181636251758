export const organizationFields = /* GraphQL */ `
id
archived
createdAt
updatedAt
name
logoUrl
accountDomain
accountSubdomain
accountInfo {
  owner
  ownerEmail
  website
  companyRole
  companyLocation
  companySize
  referencedBy
  industryCategoryId
  sfAccountId
  sfContactId
  sfOwnerId
}
theme {
  primary
  secondary
  pallet
}
accountSettings {
  accountType
  accountTier
  onboardingStatus
  teamId
  brandId
  role
  companySize
  address {
    street
    unitNumber
    city
    state
    postalCode
    country
  }
}
availableCredits
summaryMetrics {
  items {
    summaryType
    impressions
    clicks
    views
    spend
    conversions
    graphs {
      impressions
      clicks
      views
      spend
      conversions
    }
  }
  total
}
segmentPackages
whitelabelConfig {
  disableBrandCreditSelfService
}
`;
