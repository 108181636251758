import React, { useState, useEffect, useRef } from 'react';
import { useClickAway } from 'react-use';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

const placesServiceOptions = {
  apiKey: 'AIzaSyCOE_r1TKguhZR6u8PUPDO4G6IbAEg3H8E',
  options: { types: ['address'], componentRestrictions: { country: 'us' } },
};

export const AddressSearch = ({ inputValue, updateCandidateDraft, candidateDraft }) => {
  const [showResults, setShowResults] = useState(false);
  const [lastSearched, setLastSearched] = useState(null);
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (candidateDraft.PayingEntityAddress || inputValue) {
      setTouched(true);
    }
  }, [candidateDraft.PayingEntityAddress, inputValue]);

  const { placePredictions, getPlacePredictions } = usePlacesService(placesServiceOptions);

  const inputRef = useRef();
  const resultsRef = useRef();

  useEffect(() => {
    if (!getPlacePredictions || !inputValue || inputValue === lastSearched) {
      return;
    }

    setLastSearched(inputValue);

    getPlacePredictions({ input: inputValue });
  }, [inputValue, getPlacePredictions, lastSearched]);

  useClickAway(resultsRef, () => {
    setShowResults(false);
  });

  const onChangeAddress = e => {
    updateCandidateDraft('PayingEntityAddress', e);
  };

  const onSelect = place => {
    onChangeAddress(place.description);
    setShowResults(false);
    inputRef.current.blur();
  };

  const rows = (placePredictions || []).map(place => (
    <AddressRow key={place.place_id} place={place} onClick={() => onSelect(place)} />
  ));

  const focusIdx = useRef(-1);

  const prevItem = () => {
    if (focusIdx.current > 0) {
      focusIdx.current -= 1;
      const itmRefs = resultsRef?.current?.lastChild?.firstChild?.children || [];
      const itm = itmRefs[focusIdx.current];

      itm?.focus();
    } else {
      if (focusIdx.current > -1) focusIdx.current -= 1;
      inputRef.current.focus();
    }
  };

  const nextItem = () => {
    if (focusIdx.current < rows.length) focusIdx.current += 1;
    const itmRefs = resultsRef?.current?.lastChild?.firstChild?.children || [];
    const itm = itmRefs[focusIdx.current];

    itm?.focus();
  };

  return (
    <div
      onKeyDown={evt => {
        if (evt?.key === 'ArrowDown') nextItem();
        if (evt?.key === 'ArrowUp') prevItem();
        if (evt?.key === 'Tab' && !evt.shiftKey) nextItem();
        if (evt?.key === 'Tab' && evt.shiftKey) prevItem();
        if (evt?.key === 'Tab') evt.preventDefault();
      }}
      ref={resultsRef}
      className="w-full relative z-20"
    >
      <input
        ref={inputRef}
        className="bz-input bg-white border border-gray-500 focus:border-white focus:bg-white z-20 placeholder-gray-500 focus:ring-secondary"
        type="text"
        value={candidateDraft.PayingEntityAddress || inputValue || ''}
        placeholder="Street Address"
        onFocus={() => setShowResults(true)}
        onChange={e => onChangeAddress(e)}
        onBlur={() => setTouched(true)}
      />
      {touched && !candidateDraft.PayingEntityAddress && !inputValue && (
        <div className="flex space-x-2 text-sm bg-orange-100 p-1 w-full mt-2 ">
          <span className="mx-auto text-gray-500">Address is required</span>
        </div>
      )}

      {showResults && rows.length > 0 && (
        <div className="pt-1 h-0 w-full overflow-visible">
          <div className="w-full bg-white border">{rows}</div>
        </div>
      )}
    </div>
  );
};

const AddressRow = ({ className, onClick, place }) => (
  <button
    type="button"
    onClick={onClick}
    className={`bz-focus flex items-center w-full h-12 border-b px-4 hover:bg-gray-50 ${className}`}
  >
    {place.description}
  </button>
);
