import { useEffect, useState, useMemo, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { BsXCircle } from 'react-icons/bs';
import { API } from 'aws-amplify';
import { setUpBrand } from 'src/graphql/mutations';
import { Brand } from 'src/models';
import { createBrand } from 'src/helpers/brand';
import { useAppParams } from 'src/hooks/useAppParams';
import { useGAS } from 'src/GlobalAppState/context';
import { IndustryCategoryDropdownNew, WebsiteField } from 'src/components/MainSettings/BrandSettings/formFields';
import { AdditionalSettings } from 'src/components/MainSettings/BrandSettings/AdditionalSettings';
import { getUrlRegex } from 'src/helpers/urlValidator';
import { SettingsBody, SettingsPage, SettingsPageHeader } from 'src/components/MainSettings/shared/Layout';

const SettingsAttributeInput = ({ label, placeholder, value, onSave }) => {
  const updateDraft = useCallback(
    e => {
      onSave(e.target.value);
    },
    [onSave]
  );

  return (
    <label htmlFor={label} className="w-full">
      <p className="pb-1 font-medium text-gray-600">{label}</p>

      <input
        type="text"
        placeholder={placeholder}
        defaultValue={value}
        onChange={updateDraft}
        className="bz-input h-12 placeholder-gray-400"
      />
    </label>
  );
};

export default function NewBrandPage() {
  const appParams = useAppParams();
  const history = useHistory();
  const { reloadTeamBrands } = useGAS();
  const { brand, page, teamId, organizationId } = appParams || {};
  const [brandDraft, rawSetBrandDraft] = useState(() => new Brand({}));
  const isNewBrand = page === 'new-brand';

  const setBrandDraft = useCallback(draftParams => {
    rawSetBrandDraft(new Brand({ ...draftParams }));
  }, []);

  useEffect(() => {
    if (teamId) {
      setBrandDraft({ ...brandDraft, teamId });
    }
  }, [brandDraft, setBrandDraft, teamId]);

  useEffect(() => {
    if (organizationId) {
      setBrandDraft({ ...brandDraft, organizationId });
    }
  }, [brandDraft, organizationId, setBrandDraft]);

  const updateField = useCallback(
    (key, value) => {
      setBrandDraft(new Brand({ ...brandDraft, [key]: value }));
    },
    [setBrandDraft, brandDraft]
  );

  const urlRegex = getUrlRegex();

  const validUrl = urlRegex.test(brandDraft.websiteUrl);
  const valid = brandDraft.name && brandDraft.websiteUrl && brandDraft.industryCategoryId && validUrl;
  const changed = useMemo(() => {
    let hasChanged = false;

    if (brandDraft) {
      if (brandDraft.name && brandDraft.name !== brand.name) hasChanged = true;
      if (brandDraft.websiteUrl && brandDraft.websiteUrl !== brand.websiteUrl) hasChanged = true;
      if (brandDraft.industryCategoryId && brandDraft.industryCategoryId !== brand.industryCategoryId) {
        hasChanged = true;
      }
      const initSettings = brand?.politicalSettings || { none: '' };
      const draftSettings = brandDraft?.politicalSettings || { none: '' };

      if (JSON.stringify(initSettings) !== JSON.stringify(draftSettings)) {
        hasChanged = true;
      }
    }

    return hasChanged;
  }, [brandDraft, brand]);

  const disableSave = !valid || !changed;

  const saveDraft = () => {
    if (valid) {
      const underReview = brandDraft.industryCategoryId === 109;

      createBrand({ ...brandDraft, teamId, organizationId, underReview }).then(savedBrand => {
        const input = {
          id: savedBrand.id,
          teamId,
          organizationId,
          name: savedBrand?.name || '',
          websiteUrl: savedBrand?.websiteUrl || '',
          industryCategoryId: savedBrand?.industryCategoryId || 1,
        };

        API.graphql({ query: setUpBrand, fetchPolicy: 'no-cache', variables: { input } }).then(() => {
          reloadTeamBrands(); // sub isn't working. TODO FIX
        });

        if (isNewBrand) {
          history.replace(`/brand/${savedBrand.id}`);
        }
      });
    }
  };

  return (
    <SettingsPage>
      <SettingsPageHeader>
        <h1 className="text-2xl text-gray-700 font-semibold my-4">Create a brand</h1>
        <Link
          to={`/build/teams/${teamId}`}
          className="rounded-full p-2 hover:bg-blueGray-200 absolute right-5"
          data-testid="closeBrandSettings"
        >
          <BsXCircle className="h-8 w-8 stroke-current text-primary" />
        </Link>
      </SettingsPageHeader>
      <SettingsBody>
        <div className="w-full flex space-x-8">
          <div className="flex-1 max-w-xl flex flex-col space-y-8">
            <div className="flex items-center space-x-4">
              <h1 className="text-lg font-semibold">Basic Information</h1>
            </div>
            <SettingsAttributeInput
              label="Brand Name"
              value={brandDraft.name || ''}
              placeholder="Brand Name"
              onSave={val => updateField('name', val)}
            />
            <WebsiteField value={brandDraft.websiteUrl || ''} update={val => updateField('websiteUrl', val)} />
            <IndustryCategoryDropdownNew
              value={brandDraft.industryCategoryId}
              onSelect={val => updateField('industryCategoryId', val)}
            />
            <button
              type="button"
              onClick={saveDraft}
              disabled={disableSave}
              className={`bz-btn-solid h-12 ${disableSave && 'bg-gray-200 border-gray-200 cursor-not-allowed'}`}
            >
              Save
            </button>
            {!validUrl && brandDraft.websiteUrl ? <p className="text-red-500">Please Enter a valid url</p> : null}
          </div>
          <AdditionalSettings brandDraft={brandDraft} updateField={updateField} />
        </div>
      </SettingsBody>
    </SettingsPage>
  );
}
