import { SummaryItem } from './SummaryItem';
import { useCreativesContext } from '../CreativesContext';
import { SummaryHeader } from './SummaryHeader';

export const CreativeSummary = ({ creativesChanged }) => {
  const creatives = useCreativesContext();

  const counts = (creatives || [])
    .map(c => c.creativeType)
    .reduce((obj, itm) => {
      const val = (obj[itm] || 0) + 1;

      return { ...obj, [itm]: val };
    }, {});

  const rows = Object.entries(counts).map(([k, v]) => (
    <div key={k} className="ml-4">
      <SummaryItem title={k}>{v}</SummaryItem>
    </div>
  ));

  return (
    <>
      <SummaryHeader panelKey="Creatives" highlight={creativesChanged}>
        Creatives
      </SummaryHeader>
      <div className="flex flex-col space-y-1">
        {rows?.length > 0 ? (
          rows
        ) : (
          <div className="flex ml-4 space-x-2 text-xs bg-orange-100 p-1 w-1/3">
            <span className="mx-auto text-gray-500">Required</span>
          </div>
        )}
      </div>
    </>
  );
};
