import React, { useEffect, useState, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { useSpring, useSpringRef } from 'react-spring';
import { format } from 'date-fns';
import { PerformanceIcon } from 'src/svgs';
import { StartEndDateSelector } from 'src/components/shared';

import { shortNumber } from 'src/helpers';
import { getDataAppMetrics } from 'src/helpers/getDataAppMetrics';
import { Campaign } from 'src/models';
import { buildChartOptions } from './helpers/buildChartOptions';
import { buildChartData } from './helpers/buildChartData';
import { GraphDropdown } from './GraphDropdown';
import { GraphTypeSelector } from './GraphTypeSelector';
import { Tooltip } from './Tooltip';
import { DownloadCsvButton } from '../shared/DownloadCsvButton';

export const PerformanceSpend = ({ appParams, campaign = {} }) => {
  const [selected, setSelected] = useState('impressions');
  const [selectedType, setSelectedType] = useState('dates');
  const [loading, setLoading] = useState(true);
  const [metrics, setMetrics] = useState([]);
  const tooltipRef = useRef();
  const tooltipSpringRef = useSpringRef();
  const tooltipStyle = useSpring({ x: 0, y: 0, opacity: 0, ref: tooltipSpringRef });
  const { [selectedType]: metricType } = {
    dates: 'CAMPAIGN_DAILY',
    daysOfWeek: 'CAMPAIGN_DAY_OF_WEEK',
    hoursOfDay: 'CAMPAIGN_TIME_OF_DAY',
  };
  const [filterStart, setFilterStart] = useState(new Date());
  const [filterEnd, setFilterEnd] = useState(new Date());

  useEffect(() => {
    if (campaign.startDate) setFilterStart(new Date(campaign.startDate));
  }, [campaign.startDate]);

  useEffect(() => {
    if (campaign.endDate) setFilterEnd(new Date(campaign.endDate));
  }, [campaign.endDate]);

  useEffect(() => {
    let mounted = true;

    if (campaign.id) {
      const filterRange =
        metricType === 'CAMPAIGN_DAILY' ? [format(filterStart, 'yyyy-MM-dd'), format(filterEnd, 'yyyy-MM-dd')] : false;

      setLoading(true);
      getDataAppMetrics(Campaign, campaign.id, metricType, filterRange).then(resp => {
        if (mounted) {
          if (resp.items) setMetrics(resp.items || []);
          setLoading(false);
        }
      });
    }

    return () => (mounted = false);
  }, [campaign.id, metricType, filterStart, filterEnd]);

  const tooltipCallback = context => {
    const { tooltip: { dataPoints: [{ raw: value, label, element: { x, y } = {} }] = [{}], opacity } = {} } = context;

    tooltipRef.current.innerHTML = `\
<div class="flex flex-col space-y-1 items-center justify-center w-full h-full pb-2">
<h1 class="text-gray-400 text-sm">${label}</h1>
<h1 class="text-xl text-semibold">
  ${
    selected === 'spend'
      ? (value || 0).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
      : shortNumber(value)
  }
</h1>
</div>`;
    tooltipSpringRef.start({ x: x - 48, y: y - 112, opacity });
  };

  const empty = !loading && metrics.length < 1;
  const options = buildChartOptions(selected, tooltipCallback, metrics);

  const data = buildChartData(metrics, selectedType, loading, appParams.theme, selected);

  const metricDates = {
    startDate: filterStart,
    endDate: filterEnd,
    minDate: campaign.startDate ? new Date(campaign.startDate) : new Date(),
    maxDate: campaign.endDate ? new Date(campaign.endDate) : new Date(),
  };

  const onDateSelect = dates => {
    setFilterStart(dates.startDate);
    setFilterEnd(dates.endDate);
  };

  return (
    <div className="w-full flex flex-col space-y-4 rounded shadow bg-white p-4">
      <div className="flex flex-wrap justify-between items-start">
        <div className="flex items-center space-x-6">
          <PerformanceIcon className="w-11 h-11 mt-px stroke-current text-primary" />
          <h1 className="text-gray-900 text-xl font-semibold">Performance & Spend</h1>
          {loading && <span className="animate-pulse">Loading...</span>}
          {empty && <span>No analytics data yet.</span>}
        </div>
        <div className="relative flex flex-col justify-between space-y-2 items-end space-x-4">
          <DownloadCsvButton loading={loading} metrics={metrics} metricType={metricType} campaign={campaign} />
        </div>
      </div>
      <div className="flex justify-between items-start">
        <GraphDropdown selected={selected} setSelected={setSelected} />
        <div className="relative">
          <StartEndDateSelector dates={metricDates} onDateSelect={onDateSelect} type="campaign-analytics" relative />
        </div>
      </div>
      <div className="w-full px-8 h-96 relative">
        <Tooltip tooltipRef={tooltipRef} tooltipStyle={tooltipStyle} />
        <Line options={options} data={data} />
      </div>
      <div className="w-full flex justify-center py-4">
        <GraphTypeSelector selectedType={selectedType} setSelectedType={setSelectedType} />
      </div>
    </div>
  );
};
