import { Route, Switch } from 'react-router-dom';
import BrandLayout from './layout';
import BrandIndexPage from './page';
import BrandSettings, { brandSettingsPaths } from './(settings)/component';
import BrandCredits, { brandCreditsPaths } from './(credits)/component';
import NewCampaignPage from './new-campaign/page';

const brandIndexPath = '/brand/:id';
const newCampaignPage = '/brand/:id/new-campaign';

const brandHeaderPaths = [brandIndexPath, ...brandCreditsPaths, ...brandSettingsPaths];

export const brandPaths = [...brandHeaderPaths, newCampaignPage];

export default function Brand() {
  return (
    <Switch>
      <Route exact path={newCampaignPage}>
        <NewCampaignPage />
      </Route>
      <Route exact path={brandHeaderPaths}>
        <BrandLayout>
          <Switch>
            <Route exact path="/brand/:id">
              <BrandIndexPage />
            </Route>
            <Route exact path={brandSettingsPaths}>
              <BrandSettings />
            </Route>
            <Route exact path={brandCreditsPaths}>
              <BrandCredits />
            </Route>
          </Switch>
        </BrandLayout>
      </Route>
    </Switch>
  );
}
