import { API } from 'aws-amplify';
import { brandFields } from './brandFields';
import { putAppEvent } from '../putAppEvent';

const createBrandQuery = /* GraphQL */ `
  mutation CreateBrand($input: CreateBrandInput!) {
    createBrand(input: $input) {
      ${brandFields}
    }
  }
`;

const cleanBrandInput = brand => {
  const {
    teamBrandsId: _teamBrandsId,
    markup: _markup,
    summaryMetrics: _summaryMetrics,
    availableCredits: _availableCredits,
    ...rest
  } = brand;

  return rest;
};

export const createBrand = async input =>
  API.graphql({
    query: createBrandQuery,
    variables: { input: cleanBrandInput(input) },
  })
    .then(({ data: { createBrand: resp } = {} }) => resp || false)
    .then(resp => {
      putAppEvent('BrandMutation', input);

      return resp;
    })
    .catch(({ data: { createBrand: resp } = {} }) => resp || false);
