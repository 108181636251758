import { useCallback } from 'react';
import { useModal } from 'src/contexts';
import { useGAS } from 'src/GlobalAppState/context';
import { useAppParams } from 'src/hooks/useAppParams';
import { CreditHistory } from 'src/components/MainSettings/TeamSettings/TeamSettingsCredits/CreditHistory';
import { CreditHistoryHeader, HeaderButton } from 'src/components/MainSettings/shared/Headers';
import { AddIcon } from 'src/svgs';

const TeamCreditsPageBody = () => {
  const appParams = useAppParams();

  return <CreditHistory appParams={appParams} />;
};

const TeamCreditsEmptyState = () => {
  const { team } = useGAS();

  const { openModal } = useModal();

  const onAddCredits = useCallback(() => {
    openModal('add-credits', { team });
  }, [openModal, team]);

  return (
    <div className="border rounded flex flex-col space-y-8 items-center justify-center flex-1 shadow">
      <p className="max-w-md text-center">
        Your agency doesn't have any credit transactions. When you add agency credits or transfer credits to brands,
        you'll see those records here.
      </p>
      <div>
        <HeaderButton onClick={onAddCredits} icon={AddIcon} primaryAction>
          Add Credits
        </HeaderButton>
      </div>
    </div>
  );
};

export default function TeamCreditsPage() {
  const {
    credits: { teamCreditRecords },
  } = useGAS();

  return (
    <div className="flex flex-1 flex-col space-y-8">
      <CreditHistoryHeader modelName="team" />
      {teamCreditRecords?.length ? <TeamCreditsPageBody /> : <TeamCreditsEmptyState />}
    </div>
  );
}
