import { diffCampaign } from 'src/helpers/diffCampaign';
import { useModal } from 'src/contexts';
import { useGAS } from 'src/GlobalAppState/context';
import { useOrganizationMinBudget } from 'src/helpers/organization/useOrganizationMinBudget';
import { CampaignNameField } from '../CampaignName';
import { AudienceTargeting } from './AudienceTargeting';
import { CampaignBasics } from './CampaignBasics';
import { CampaignConversions } from './CampaignConversions';
import { CampaignCreatives } from './CampaignCreatives';
import { GeoTargeting } from './GeoTargeting';
import { InventoryTargeting } from './InventoryTargeting';
import { useCreativesContext } from '../CreativesContext';
import { InteractionLock } from './InteractionLock';
import { SiteLists } from './SiteLists/SiteLists';

export const CampaignBuilder = ({
  campaignDraft,
  setCampaignDraft,
  onSave,
  appParams,
  setAppRoute,
  setCreativesChanged,
}) => {
  const creatives = useCreativesContext();
  const campaignStatus = appParams?.campaign?.status;
  const { budget, startDate, endDate } = campaignDraft || {};
  const { openModal } = useModal();
  const draftChanged = diffCampaign(campaignDraft, appParams?.campaign);
  const requestedAt = campaignDraft?.forecast?.requestedAt || campaignDraft?.forecastRequestedAt || 0;
  const forecastTime = campaignDraft?.forecast?.createdAt || 0;
  const requested = !forecastTime && requestedAt && requestedAt > forecastTime;
  const { campaign, brand } = appParams;

  const { brandPaymentMethods } = useGAS();
  const minimumBudget = useOrganizationMinBudget();
  const budgetValid = budget && budget >= minimumBudget;

  const paymentMethods = brandPaymentMethods.card ?? [];

  const { isRecurring } = campaignDraft;

  let valid;

  if (!isRecurring) {
    valid = (!requested && budgetValid && startDate && endDate) || 0;
  }

  if (isRecurring) {
    valid = (!requested && budgetValid && startDate && paymentMethods.length) || 0;
  }

  const { page } = appParams || {};
  const isNew = page === 'new-campaign';

  const onCloneClick = () => {
    openModal('clone-campaign', {
      campaign,
    });
  };

  let saveStatus = <span />;

  const exceedsLimit = campaignDraft?.pixelTrackingUrl?.length > 256;
  const activeStatus = ['running', 'paused'];

  if (valid && !draftChanged && !exceedsLimit) {
    saveStatus = <span className="whitespace-nowrap h-10 rounded text-gray-500 ">Saved</span>;
  }

  if (draftChanged && campaignStatus && campaignStatus !== 'draft') {
    if (campaignStatus === 'completed' || campaignStatus === 'stopped') {
      saveStatus = (
        <div className="flex flex-row">
          <span className="text-orange-400 pr-3 pt-1">You cannot edit a completed campaign</span>
          <button type="button" onClick={onCloneClick} className="bz-btn-solid w-20 h-8 hover:opacity-80 rounded">
            Clone
          </button>
        </div>
      );
    } else if (activeStatus.includes(campaignStatus)) {
      saveStatus = <span className="text-orange-400">You are editing an active campaign</span>;
    }
  }

  const errorMsg = getCampaignErrorMsg(campaignStatus, campaignDraft, appParams);
  const brandErrorMsg = getBrandErrorMsg(brand, setAppRoute);

  return (
    <div className="flex-1 max-h-screen flex flex-col overflow-hidden relative">
      <div
        className="flex-1 flex-grow flex-shrink max-h-screen overflow-scroll sm:overflow-y-scroll py-3 space-y-3 "
        data-testid="campaignBuilder"
      >
        {brandErrorMsg && <div className="mt-5">{brandErrorMsg}</div>}
        {errorMsg && (
          <div className="text-center space-x-2 shadow p-3 mt-5 bg-red-600 bg-opacity-30 text-xs sm:text-sm">
            {errorMsg}
          </div>
        )}
        <div className="bg-white rounded w-full shadow h-20 flex items-center px-4 justify-between flex-wrap max-w-full">
          <div className="w-auto flex items-center flex-grow flex-shrink min-w-0">
            <CampaignNameField
              appParams={appParams}
              campaignDraft={campaignDraft}
              setCampaignDraft={setCampaignDraft}
              save={onSave}
              isNew={isNew}
              className="flex-grow flex-shrink"
            />
          </div>
          <div className="flex items-center justify-end  ml-72 sm:ml-8 h-2 flex-grow-0 flex-shrink-0">
            {!isNew && <div className="text-xs sm:text-sm -mt-3 sm:mt-0">{saveStatus}</div>}
          </div>
        </div>
        <InteractionLock lock={isNew}>
          <CampaignBasics
            campaignDraft={campaignDraft}
            creatives={creatives}
            appParams={appParams}
            setCampaignDraft={setCampaignDraft}
          />
          <CampaignCreatives
            appParams={appParams}
            creatives={creatives}
            setCreativesChanged={setCreativesChanged}
            campaignStatus={campaignStatus}
          />
          <InventoryTargeting appParams={appParams} campaignDraft={campaignDraft} setCampaignDraft={setCampaignDraft} />
          <SiteLists campaignDraft={campaignDraft} setCampaignDraft={setCampaignDraft} />
          <AudienceTargeting
            appParams={appParams}
            campaignDraft={campaignDraft}
            setCampaignDraft={setCampaignDraft}
            setAppRoute={setAppRoute}
          />
          <GeoTargeting appParams={appParams} campaignDraft={campaignDraft} setCampaignDraft={setCampaignDraft} />
          <CampaignConversions
            appParams={appParams}
            campaignDraft={campaignDraft}
            setCampaignDraft={setCampaignDraft}
          />
        </InteractionLock>
      </div>
      <div className="relative h-0 w-full">
        <div
          style={{ '--tw-gradient-to': 'rgba(236, 241, 245, 0)' }}
          className="h-12 -mt-12 w-full top-0 bg-gradient-to-t from-blueGray-150"
        />
      </div>
    </div>
  );
};

const getCampaignErrorMsg = (status, campaign, appParams) => {
  let errorMsg = '';

  if (status && status === 'error') {
    errorMsg =
      'This campaign is in error. Please contact your account representative or reach out to our support team via the chat icon in the lower left-hand corner of the screen.';

    const {
      user: { email: userEmail },
    } = appParams;
    const isBzUser = userEmail?.includes('brandzooka');

    if (isBzUser) errorMsg = campaign.error || 'Undefined Error';
  }

  return errorMsg;
};

const getBrandErrorMsg = (brand, setAppRoute) => {
  let errorMsg = '';

  if (brand.error && !brand.isValid) {
    errorMsg = (
      <div className="text-gray-600 bg-red-200 py-1 px-8">
        <span className="pr-2 font-bold">Unable to launch campaign.</span>
        Go to{' '}
        <span
          className="cursor-pointer underline"
          onClick={() => setAppRoute({ model: 'brand', id: brand.id, page: 'settings' })}
        >
          brand settings
        </span>{' '}
        to complete the brand setup. If you need furthur assistance, please contact your account representative or reach
        out to our support team via chat.
      </div>
    );
  }

  if (brand.underReview) {
    errorMsg = (
      <div className="text-gray-600 bg-red-200 py-1 px-8">
        <span className="pr-2 font-bold">
          To ensure your brand meets advertising guidelines, your account is under review and your account rep will be
          in contact. Please reach out to your account rep if you have questions.{' '}
        </span>
      </div>
    );
  }

  return errorMsg;
};
