import React from 'react';

export const AgencyPeople = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 45.3 29.6">
    <g data-name="Group 4522">
      <g strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" data-name="Group 4521" transform="translate(1 1)">
        <circle cx="4" cy="4" r="4" data-name="Ellipse 54" transform="translate(32.2 7.7)" />
        <circle cx="6.1" cy="6.1" r="6.1" data-name="Ellipse 55" transform="translate(15.5)" />
        <circle cx="4" cy="4" r="4" data-name="Ellipse 56" transform="translate(3.2 7.7)" />
        <path d="M43.3 27.6v-2.2a5 5 0 00-5-5h-1.5" data-name="Path 12943" />
        <path d="M0 27.6v-2.2a5 5 0 015-5h1.5" data-name="Path 12944" />
        <path d="M32.2 27.5v-3.1a6.9 6.9 0 00-7-6.9H18a6.9 6.9 0 00-6.9 6.9v3.1" data-name="Path 12945" />
      </g>
    </g>
  </svg>
);
