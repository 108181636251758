import React from 'react';
import { animated } from 'react-spring';

export const Tooltip = ({ tooltipRef, tooltipStyle }) => (
  <animated.div style={{ ...tooltipStyle, pointerEvents: 'none' }} className="absolute">
    <div
      ref={tooltipRef}
      style={{ height: 80, pointerEvents: 'none' }}
      className="bg-white w-24 border-2 border-primary rounded-lg"
    />
    <div className="relative -top-2 left-10 w-4 h-4 z-0 transform rotate-45 bg-white border-primary border-r-2 border-b-2" />
  </animated.div>
);
