import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useUserAuth } from 'src/GlobalAppState/UserAuth';
import { useGAS } from 'src/GlobalAppState/context';

const useAuthRedirect = (loggedInState, redirectTo) => {
  const { userLoggedIn } = useUserAuth();
  const history = useHistory();

  useEffect(() => {
    if (userLoggedIn === loggedInState) {
      history.push(redirectTo);
    }
  }, [history, userLoggedIn, loggedInState, redirectTo]);
};

export const useNoAuthSignInRedirect = () => useAuthRedirect(false, '/auth/sign-in');

export const useAlreadyAuthedRootRedirect = () => useAuthRedirect(true, '/');

const useOnboardingRedirect = (onboardingState, redirectTo) => {
  const { isAdmin } = useUserAuth();
  const history = useHistory();
  const { organization } = useGAS();

  const onboardingStatus = organization?.accountSettings?.onboardingStatus;

  useEffect(() => {
    const onboardingComplete = onboardingStatus === 'complete';
    const onboardingIncomplete = onboardingStatus && !onboardingComplete; // TODO add admin etc

    if (onboardingComplete && onboardingState) {
      history.replace(redirectTo);
    } else if (onboardingIncomplete && !onboardingState && !isAdmin) {
      history.replace(redirectTo);
    }
  }, [history, onboardingStatus, isAdmin, onboardingState, redirectTo]);
};

export const useOnboardingIncompleteRedirect = () => useOnboardingRedirect(false, '/onboarding');

export const useOnboardingCompleteRedirect = () => useOnboardingRedirect(true, '/');
