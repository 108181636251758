import { useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { ColumnCell, ColumnHeader } from './Components';

const REM_SIZE = 16;

const MIN_REM_WIDTH = 16;
const MAX_REM_WIDTH = 32;

const getRemSizeCeil = sizePx => Math.ceil(sizePx / REM_SIZE);

const postLoadRemPadding = 0.5;

const enforceMinMax = sizeRem => Math.min(MAX_REM_WIDTH, Math.max(MIN_REM_WIDTH, sizeRem));

const getRemSize = sizePx => {
  const currentSize = getRemSizeCeil(sizePx);
  const withPad = currentSize + postLoadRemPadding;

  return enforceMinMax(withPad);
};

const NameColumnHeader = ({ metadata }) => {
  const ref = useRef();
  const [width, setWidth] = useState(null);

  useEffect(() => {
    if (ref.current) {
      const bbox = ref.current.getBoundingClientRect();

      setWidth(getRemSize(bbox.width));
    }
  }, [metadata]);

  const style = useMemo(() => (width ? { width: `${width}rem` } : {}), [width]);

  return (
    <ColumnHeader
      className={`sticky bg-white h-fit left-0 pl-4 transition-all ease-in-out duration-75 ${width ? '' : 'w-96'}`}
      style={style}
    >
      <span>Name</span>
      <span className="invisible absolute whitespace-nowrap" ref={ref}>
        {metadata.maxCampaignName?.name}
      </span>
    </ColumnHeader>
  );
};

export const NameColumn = {
  key: 'name',
  header: NameColumnHeader,
  cell: ({ campaign }) => (
    <ColumnCell className="sticky h-fit bg-white group-hover:bg-blueGray-50 truncate left-0 pl-4">
      <Link
        to={`/campaign/${campaign.id}${campaign.status === 'draft' ? '/edit' : ''}`}
        className="hover:underline hover:cursor-pointer font-semibold text-gray-800 capitalize tracking-wide whitespace-nowrap"
      >
        {campaign.name}
      </Link>
    </ColumnCell>
  ),
};
