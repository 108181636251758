import { useMemo } from 'react';

export const SELECT_RANGE_ALL = 'select_range_all'; // regular draft
export const SELECT_RANGE_END = 'select_range_end'; // regular running
export const SELECT_RANGE_DISABLED = 'select_range_disabled'; // regular complete

export const SELECT_START = 'select_start'; // recurring draft
export const SELECT_START_DISABLED = 'select_start_disabled'; // recurring running or complete

export const usePickerVariant = ({ isRecurring, status, startDate }) =>
  useMemo(() => {
    if (isRecurring) {
      return status === 'draft' ? SELECT_START : SELECT_START_DISABLED;
    }

    if (status === 'running') {
      const today = new Date();
      const start = new Date(startDate);

      if (start.setHours(0, 0, 0, 0) > today.setHours(0, 0, 0, 0)) {
        return SELECT_RANGE_ALL;
      }
    }

    switch (status) {
      case 'draft':
        return SELECT_RANGE_ALL;
      case 'completed':
      case 'stopped':
      case 'stopping':
        return SELECT_RANGE_DISABLED;
      default:
        return SELECT_RANGE_END;
    }
  }, [isRecurring, status, startDate]);
