import { useState, useRef } from 'react';
import { ChevronDown } from 'src/svgs';
import { DropdownButton } from 'src/components/shared';
import { CheckBox } from 'src/components/CampaignDetails/CampaignBuilder/InventoryTargeting/CheckBox';

import { useClickAway } from 'react-use';

export const StatusFilter = ({ filter, setFilter }) => {
  const [statusOpen, setStatusOpen] = useState(false);
  const statusRef = useRef();

  useClickAway(statusRef, () => setStatusOpen(false));

  const onStatusClick = status => {
    if (status !== 'all') {
      setFilter({ ...filter, [status]: !filter[status] });
    }
  };

  const filteredStatus = Object.keys(filter)
    .filter(status => status !== 'updating')
    .reduce((obj, key) => {
      obj[key] = filter[key];

      return obj;
    }, {});

  const onAllStatusClick = () => {
    const updatedFilter = {};

    if (Object.values(filteredStatus).every(val => val === true)) {
      Object.keys(filteredStatus).forEach(key => {
        updatedFilter[key] = false;
      });
    } else {
      Object.keys(filteredStatus).forEach(key => {
        updatedFilter[key] = true;
      });
    }

    setFilter(updatedFilter);
  };

  const { updating: _u, all: _a, ...rest } = filter;
  const selectedCount = Object.values(rest).filter(val => val).length;
  const allSelected = Object.values(filter).every(val => val === true);
  const statusLabel = `${allSelected ? 'All' : selectedCount} Statuses`;

  return (
    <div ref={statusRef} className="flex flex-col items-end">
      <button
        type="button"
        className="h-10 border hover:bg-white rounded flex items-center justify-end space-x-2 px-3"
        onClick={() => setStatusOpen(!statusOpen)}
      >
        <span className=" w-24 text-gray-500 whitespace-nowrap">{statusLabel}</span>
        <ChevronDown className="text-gray-500 w-4 stroke-current" />
      </button>
      <div className="z-20 w-0 h-0 overflow-visible">
        <div
          className={`origin-bottom-right ${
            !statusOpen && 'hidden'
          } w-44 -ml-44 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
        >
          <div className="py-1 rounded w-44">
            <DropdownButton className="flex" onClick={() => onAllStatusClick()}>
              <CheckBox isSelected={allSelected} />
              <span>All</span>
            </DropdownButton>
            {Object.keys(filter)
              .filter(status => status !== 'updating' && status !== 'all')
              .map(status => (
                <DropdownButton className="flex" onClick={() => onStatusClick(status)} key={status}>
                  <CheckBox isSelected={filter[status]} />
                  <span>{status.charAt(0).toUpperCase() + status.slice(1)}</span>
                </DropdownButton>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
