import { get, isEqual, mapValues, pick } from 'lodash';

const campaignComparisonAttributes = [
  'budget',
  'startDate',
  'endDate',
  'goal',
  'audienceType',
  'geoSegments',
  'daysOfWeek',
  'timesOfDay',
  'recurrenceWindow',
  'recurrenceBudget',
  'retargetingTags',
  'name',
  'dmpSegments',
  'inventorySegments',
  'creatives',
  'conversionsEnabled',
  'trackingTagId',
  'pixelTrackingUrl',
  'isRecurring',
  'markups',
  'activeUpdatedAt',
  'isValid',
  'error',
  'isMultiDataGroups',
  'ctvSegments',
  'audience',
  'bidlists',
  'campaignPoliticalSettings',
  'conversionEvents',
];

const forecastComparisonAttributes = [
  'startDate',
  'endDate',
  'dmpSegments',
  'geoSegments',
  'inventorySegments',
  'creatives',
  'budget',
];

const dmpSegmentAttribute = ['dmpSegments'];

const convertObjectArrays = obj =>
  mapValues(obj, value => (Array.isArray(value) ? value.map(v => get(v, 'pathKey') || get(v, 'name') || v) : value));

const pickCampaignAttrs = campaign => convertObjectArrays(pick(campaign, campaignComparisonAttributes));
const pickForecastAttrs = forecast => convertObjectArrays(pick(forecast, forecastComparisonAttributes));
const pickDmpSegmentAttr = campaign => convertObjectArrays(pick(campaign, dmpSegmentAttribute));

export const diffCampaign = (draft = {}, original = {}) =>
  !isEqual(pickCampaignAttrs(draft), pickCampaignAttrs(original));

export const diffDmpSegments = (draft = {}, original = {}) =>
  !isEqual(pickDmpSegmentAttr(draft), pickDmpSegmentAttr(original));

export const diffForecast = (forecast = {}, campaign = {}) => {
  const geoSegments = (campaign.geoSegments || []).map(({ __typename, ...rest }) => rest);
  const dmpSegments = (campaign.dmpSegments || []).map(({ __typename, ...rest }) => rest);
  const inventorySegments = (campaign.inventorySegments || []).map(({ __typename, ...rest }) => rest);
  const creatives = (campaign.creatives?.items || []).map(itm => ({
    id: itm.id,
    updatedAt: itm.updatedAt,
    creativeType: itm.creativeType,
    web: itm.web,
    ctv: itm.ctv,
    displayTargeting: itm.displayTargeting,
    archived: itm.archived,
  }));

  const cmpgn = {
    startDate: campaign.startDate,
    endDate: campaign.endDate,
    geoSegments,
    dmpSegments,
    inventorySegments,
    creatives,
    budget: campaign.budget,
  };

  const frcst = {
    startDate: forecast.startDate,
    endDate: forecast.endDate,
    geoSegments: forecast.geoSegments,
    dmpSegments: forecast.dmpSegments,
    inventorySegments: forecast.inventorySegments,
    creatives: forecast.creatives,
    budget: forecast.campaignBudget,
  };

  return !isEqual(pickForecastAttrs(frcst), pickForecastAttrs(cmpgn));
};
