import { isValidPixelTrackingUrl } from 'src/helpers/urlValidator';

export const selectPixelValidForPublish = campaignOrDraft => {
  const pixelUrl = campaignOrDraft.pixelTrackingUrl;

  if (!pixelUrl) {
    return true;
  }

  return isValidPixelTrackingUrl(pixelUrl);
};

export const selectPixelValidForSave = selectPixelValidForPublish;
