import { useCallback } from 'react';
import { useModal } from 'src/contexts';
import { useGAS } from 'src/GlobalAppState/context';
import { useSufficientBrandBudgetCheck } from 'src/selectors/campaign/budgetValidationSelectors';
import { selectBrandCreditSelfServiceDisabled } from 'src/selectors/organization/organizationSelectors';

export const usePublishHandler = campaignDraft => {
  const { openModal } = useModal();
  const {
    organization,
    credits: { brandCredits, campaignCredits },
  } = useGAS();
  const selfServiceDisabled = selectBrandCreditSelfServiceDisabled(organization);
  const { hasRequiredCredits, requiredCredits } = useSufficientBrandBudgetCheck(campaignDraft);

  return useCallback(() => {
    if (hasRequiredCredits) {
      openModal('launch-campaign-verify', {
        campaign: campaignDraft,
      });
    } else if (selfServiceDisabled) {
      openModal('insufficient-credits-self-service-disabled', {
        campaignDraft,
        amount: requiredCredits,
      });
    } else {
      openModal('add-credits', {
        campaign: campaignDraft,
        amount: requiredCredits,
        availBrandCredits: brandCredits,
        campaignCredits,
      });
    }
  }, [
    brandCredits,
    campaignCredits,
    campaignDraft,
    hasRequiredCredits,
    openModal,
    requiredCredits,
    selfServiceDisabled,
  ]);
};
