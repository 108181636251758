import { API } from 'aws-amplify';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGAS } from 'src/GlobalAppState/context';
import { useAppParams } from 'src/hooks/useAppParams';
import { useModal } from 'src/contexts';
import { addFirstPartyTrackingTag } from 'src/graphql/mutations';
import { BzS3 } from 'src/helpers';
import { createTrackingTag } from 'src/helpers/trackingTag';
import { AddIcon } from 'src/svgs';
import { v4 as uuid } from 'uuid';
import { FirstPartyRow } from 'src/components/MainSettings/BrandSettings/BrandSettingsFirstParty/FirstPartyRow';
import { Headline } from 'src/components/MainSettings/shared/Headers';

export default function BrandSettingsFirstPartyPage() {
  const { brand } = useGAS();
  const appParams = useAppParams();
  const { openModal } = useModal();
  const [showNewRow, setShowNewRow] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [activeRow, setActiveRow] = useState(false);
  const [trackingTags, setTrackingTags] = useState([]);
  const { organizationId, teamId, brandId, brandTrackingTags } = appParams;

  useEffect(() => {
    if (brandTrackingTags) {
      const tags = brandTrackingTags.filter(t => t.trackingTagType === 'firstParty');

      for (let i = 0; i < tags.length; i++) {
        const tag = tags[i];

        if (tag.status && tag.status.includes('Error: Verification Failed.')) {
          tag.showIconWithTooltip = false;
        } else if (tag.status && tag.status.includes('Processing')) {
          tag.showIconWithTooltip = true;
        }
      }

      setTrackingTags(tags);
    }
  }, [brandTrackingTags]);

  const onCreate = async ({ file, tagName, piiType, region, retainPii }) => {
    if (file) {
      const id = uuid();

      setSubmitting(true);
      BzS3.getModelBaseKey(brand, 'account-private-pii').then(baseKey => {
        const key = `${baseKey}/data/${id}.csv`;

        return BzS3.put(key, file).then(() => {
          const processFile = async () => {
            const input = {
              name: tagName,
              piiType,
              id,
              brandId,
              teamId,
              region,
              retainPii,
              organizationId,
              status: 'Uploading...',
              trackingTagType: 'firstParty',
            };

            const dbTag = await createTrackingTag(input);

            setShowNewRow(false);

            delete input.piiType;
            delete input.region;
            delete input.retainPii;

            await API.graphql({
              query: addFirstPartyTrackingTag,
              variables: { input: { ...input, id: dbTag.id } },
            });
            setSubmitting(false);
          };

          return processFile();
        });
      });
    }
  };
  const onInfoClick = () => openModal('first-party-info-modal');
  const showCreateModal = () => openModal('create-first-party-modal', { onCreate });

  const onDelete = useCallback(
    trackingTag => {
      openModal('confirm-delete-first-party', { trackingTag, brand, setActiveRow });
    },
    [brand, openModal]
  );

  const rows = useMemo(
    () =>
      (trackingTags || []).map(tag => (
        <FirstPartyRow
          key={tag.id}
          tag={tag}
          submitting={submitting}
          onDelete={onDelete}
          activeRow={activeRow}
          setActiveRow={setActiveRow}
          showNewRow={showNewRow}
        />
      )),
    [activeRow, onDelete, showNewRow, submitting, trackingTags]
  );

  return (
    <div className="w-full flex flex-col space-y-4 overflow-y-auto flex-grow">
      <Headline>First Party Data</Headline>
      <div className="flex flex-row space-between w-full">
        <div className="flex flex-grow">
          <p className="text-gray-500 tracking-tighter pr-5">
            Upload email addresses to use for audience targeting. Minimum of 5,000 records.
          </p>
          <button
            type="button"
            onClick={onInfoClick}
            className="flex w-45 text-primary underline p-2 hover:bg-blueGray-50 rounded -mt-2"
          >
            View Requirements
          </button>
        </div>
        <button
          type="button"
          disabled={showNewRow}
          onClick={showCreateModal}
          className={`bz-btn-solid flex items-center space-x-1 py-1 rounded ${
            !showNewRow
              ? 'bg-primary text-white'
              : 'bg-blueGray-200 focus:ring-0 border-0 disabled:opacity-50 cursor-not-allowed'
          } `}
        >
          <AddIcon className="stroke-current text-white w-4" />
          &nbsp; Create New
        </button>
      </div>
      <div>
        <table className="text-sm sm:text-base block w-full">
          <thead>
            <tr className="h-16 sm:h-20 text-lg font-medium">
              <td className="w-16 sm:w-28 px-2 sm:px-4" />
              <td className="w-96 sm:w-1/3 border-b">Name</td>
              <td className="border-b w-40 sm:w-1/4">Status</td>
              <td className="w-40 sm:w-1/5 border-b">
                <span className="text-lg font-semibold">Audience Size</span>
              </td>
              <td className="w-40 sm:w-1/5 border-b">
                <span className="text-lg font-semibold whitespace-nowrap">Days Remaining</span>
              </td>
              <td className="w-52 sm:w-1/5 border-b pl-12">
                <div className="flex flex-col items-center">
                  <span className="text-lg font-semibold whitespace-nowrap">Actions</span>
                </div>
              </td>
            </tr>
          </thead>

          <tbody>{rows}</tbody>
        </table>
      </div>
    </div>
  );
}
