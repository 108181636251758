import { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { CheckIcon } from 'src/svgs';
import * as SafeAuth from 'src/helpers/SafeAuth';
import { EmailField } from 'src/components/Auth/EmailField';
import { AuthContainer } from 'src/components/Auth/Layout';
import { useAuthProvider } from 'src/providers/AuthProvider';
import { useAlreadyAuthedRootRedirect } from 'src/hooks/authHooks';

export default function ForgotPasswordPage() {
  const { logo, isWhiteLabel } = useAuthProvider();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [sentResetRequest, setSentResetRequest] = useState(false);
  const [sentCount, setSentCount] = useState(0);

  useAlreadyAuthedRootRedirect();

  const handleForgotPassword = useCallback(async () => {
    setErrorMessage('');
    setLoading(true);
    await SafeAuth.forgotPassword(email, { domain: window.location.origin })
      .then(() => setSentResetRequest(true))
      .then(() => setSentCount(sentCount + 1))
      .catch(e => {
        if (e.name === 'UserNotFoundException') {
          setErrorMessage('Email does not exist');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [email, sentCount, setSentCount, setSentResetRequest, setErrorMessage]);

  return (
    <AuthContainer logo={logo} isWhiteLabel={isWhiteLabel} loading={loading}>
      {!sentResetRequest && (
        <>
          <h1 className="text-3xl font-medium">Forgot password</h1>
          <div data-mf-replace="" className="flex flex-col w-full items-center max-w-md">
            <div className="w-full">
              <EmailField className="p-0 w-full" label="Email" value={email} update={setEmail} />
            </div>
            <button type="button" onClick={handleForgotPassword} className="w-full bz-btn-solid mt-4 h-12 font-medium">
              Reset Password
            </button>
            {errorMessage && (
              <div className="w-full h-auto max-h-20">
                {errorMessage && <h1 className="text-red-500 text-lg">{errorMessage}</h1>}
              </div>
            )}
            <div className="w-full">
              <Link to="/auth/sign-in" className="text-xs relative left-0 top-2 cursor-pointer text-primary">
                Sign In
              </Link>
            </div>
            {!isWhiteLabel && (
              <Link to="/auth/forgot-email" className="text-sm relative left-0 top-2 cursor-pointer text-primary">
                Forgot Email?
              </Link>
            )}
          </div>
        </>
      )}
      {sentResetRequest && (
        <>
          <div className="rounded-full p-4 border-4 border-green-700">
            <CheckIcon className="w-12 h-12 text-green-700 stroke-current" />
          </div>
          <div className="w-full text-center">
            <h1 className="text-3xl font-medium">{sentCount <= 1 ? 'Link sent' : 'Link resent'}</h1>
            {!isWhiteLabel && (
              <h1 className="text-sm text-center pt-2">
                If you have an account with that email you should receive a link to reset your password soon. Didn't
                receive a link?
                <button className="text-primary" type="button" onClick={() => window.Intercom('showNewMessage')}>
                  &nbsp;Contact us.
                </button>
              </h1>
            )}
          </div>

          <div className="w-full flex items-center justify-between">
            <button type="button" onClick={handleForgotPassword} className="w-full bz-btn-icon mt-4 h-12 font-medium">
              Resend Verification Code
            </button>
          </div>
        </>
      )}
    </AuthContainer>
  );
}
