import { useMemo } from 'react';

export const useAccountSettings = (userAuth, organization) => {
  const { accountSettings = {} } = organization || {};

  const { selectedAuth } = userAuth || {};
  const hasStaticAuth = !!selectedAuth?.staticAuthRole;

  return useMemo(
    () => ({
      ...accountSettings,
      onboardingStatus: hasStaticAuth ? '' : accountSettings.onboardingStatus,
      accountType: hasStaticAuth ? 'admin' : accountSettings.accountType,
    }),
    [accountSettings, hasStaticAuth]
  );
};
