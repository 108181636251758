import { createAPIQueryHook } from 'src/hooks/useAPIQuery';
import { useMemo } from 'react';
import { useBrandCreativesSubscription } from '../brand/useBrandCreativesSubscription';
import { creativeFields } from '../creative/creativeFields';

const getCampaignCreativesQuery = /* GraphQL */ `
  query ListCampaignCreatives($id: ID!, $nextToken: String) {
    getCampaign(id: $id) {
      creatives(filter: { archived: { ne: true } }, nextToken: $nextToken) {
        nextToken
        items {
          ${creativeFields}
        }
      }
    }
  }
`;

const useCampaignCreativesQuery = createAPIQueryHook({
  query: getCampaignCreativesQuery,
  paged: true,
  resultPath: 'getCampaign.creatives.items',
  nextTokenPath: 'getCampaign.creatives.nextToken',
});

export const useCampaignCreatives = campaignId => {
  const disable = !campaignId;

  const { data: creatives, subscriptionHandler } = useCampaignCreativesQuery({ id: campaignId }, { disable });

  useBrandCreativesSubscription(campaignId, subscriptionHandler);

  return useMemo(() => (creatives || []).filter(({ archived }) => !archived), [creatives]);
};
