import { forecastFields } from 'src/helpers/forecast/forecastFields';
import { createAPIQueryHook, createAPIQuerySubscriptionHook } from '../../hooks/useAPIQuery';

const listForecasts = /* GraphQL */ `
  query ListForecasts($campaignId: ID!, $nextToken: String) {
    listCampaignForecastByCampaign(campaignId: $campaignId, nextToken: $nextToken) {
      nextToken
      items {
        ${forecastFields}
      }
    }
  }
`;

const getForecast = /* GraphQL */ `
  query GetForecast($id: ID!) {
    result: getCampaignForecast(id: $id) {
      ${forecastFields}
    }
  }
`;

const useCampignForecastsQuery = createAPIQueryHook({
  query: listForecasts,
  paged: true,
  nextTokenPath: 'listCampaignForecastByCampaign.nextToken',
  resultPath: 'listCampaignForecastByCampaign.items',
});

const onCreateForecast = /* GraphQL */ `
  subscription OnCreateForecast($organizationId: String, $teamId: String, $brandId: String, $campaignId: String) {
    create: onCreateCampaignForecast(
      organizationId: $organizationId
      teamId: $teamId
      brandId: $brandId
      campaignId: $campaignId
    ) {
      id
      campaignId
      brandId
      teamId
      organizationId
    }
  }
`;

const useCampaignForecastCreateSubscription = createAPIQuerySubscriptionHook({
  subscription: onCreateForecast,
  query: getForecast,
});

const onUpdateForecast = /* GraphQL */ `
  subscription OnUpdateForecast($organizationId: String, $teamId: String, $brandId: String, $campaignId: String) {
    update: onUpdateCampaignForecast(
      organizationId: $organizationId
      teamId: $teamId
      brandId: $brandId
      campaignId: $campaignId
    ) {
      id
      campaignId
      brandId
      teamId
      organizationId
    }
  }
`;

const useCampaignForecastUpdateSubscription = createAPIQuerySubscriptionHook({
  subscription: onUpdateForecast,
  query: getForecast,
});

const onDeleteForecast = /* GraphQL */ `
  subscription OnDeleteForecast($organizationId: String, $teamId: String, $brandId: String, $campaignId: String) {
    delete: onDeleteCampaignForecast(
      organizationId: $organizationId
      teamId: $teamId
      brandId: $brandId
      campaignId: $campaignId
    ) {
      id
      campaignId
      brandId
      teamId
      organizationId
    }
  }
`;

const useCampaignForecastDeleteSubscription = createAPIQuerySubscriptionHook({
  subscription: onDeleteForecast,
  query: getForecast,
});

const useCampaignForecastsSubscription = (...args) => {
  useCampaignForecastCreateSubscription(...args);
  useCampaignForecastUpdateSubscription(...args);
  useCampaignForecastDeleteSubscription(...args);
};

export const useCampaignForecasts = ({ organizationId, teamId, brandId, campaignId }) => {
  const disable = !organizationId || !teamId || !brandId || !campaignId;

  const { data: forecasts, subscriptionHandler } = useCampignForecastsQuery({ campaignId }, { disable });

  useCampaignForecastsSubscription({ organizationId, teamId, brandId, campaignId }, { subscriptionHandler, disable });

  return forecasts ?? [];
};
