import { useState, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as SafeAuth from 'src/helpers/SafeAuth';
import { EmailField } from 'src/components/Auth/EmailField';
import { PasswordField } from 'src/components/Auth/PasswordField';
import { AuthContainer } from 'src/components/Auth/Layout';
import { useAuthProvider } from 'src/providers/AuthProvider';
import { useAlreadyAuthedRootRedirect } from 'src/hooks/authHooks';

export default function SignUpPage() {
  const { isWhiteLabel, logo } = useAuthProvider();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);

  useAlreadyAuthedRootRedirect();

  const onSignUp = useCallback(
    async e => {
      e.preventDefault();
      setErrorMessage('');
      setLoading(true);

      try {
        const { userConfirmed } = await SafeAuth.signUp({
          usernameAlias: 'email',
          username: email,
          password,
          clientMetadata: {
            domain: window.location.origin,
          },
        });

        if (!userConfirmed) {
          setLoading(false);
          history.push(`/auth/confirm-signup?email=${encodeURIComponent(email)}`);

          return;
        }

        await SafeAuth.signIn({ username: email, password });
      } catch (err) {
        setErrorMessage(err.message);
      }

      setLoading(false);
    },
    [email, password, history]
  );

  return (
    <AuthContainer logo={logo} isWhiteLabel={isWhiteLabel} loading={loading}>
      <h1 className="text-3xl font-medium">Welcome!</h1>

      <form
        data-mf-replace=""
        className="flex flex-col w-full items-center max-w-md"
        onSubmit={onSignUp}
        data-testid="signUpForm"
      >
        <div className="w-full">
          <EmailField className="p-0" label="Email" value={email} update={setEmail} />
        </div>
        <div className="w-full">
          <PasswordField className="p-0" label="Password" value={password} update={setPassword} />
        </div>
        <div className="w-full">
          <PasswordField
            className="p-0"
            label="Password Confirmation"
            invalidText="please confirm your password"
            value={confirmPassword}
            update={setConfirmPassword}
            data-testid="confirmPassword"
          />
        </div>
        <div className="relative text-center w-full">
          {errorMessage && <h1 className="text-red-500 text-lg">{errorMessage}</h1>}
        </div>
        <div>
          {password !== confirmPassword ? (
            <h1 className="text-red-500 text-lg pt-3 -mt-5">Passwords do not match.</h1>
          ) : null}
        </div>
        {password === confirmPassword ? (
          <button type="submit" className="bg-primary w-full rounded text-white font-semibold py-3 mt-4">
            Sign Up
          </button>
        ) : (
          <button disabled type="submit" className="bg-primary opacity-25 w-full rounded text-white text-lg py-3 mt-4">
            Passwords do not match.
          </button>
        )}
        <h2 className="text-gray-600 w-full text-center py-6">Already a User?</h2>
        <Link to="/auth/sign-in" className="cursor-pointer text-primary bz-focus w-20">
          Sign In
        </Link>
      </form>
    </AuthContainer>
  );
}
