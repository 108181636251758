import { API } from 'aws-amplify';
import { cloneDeep } from 'lodash';
import { checkFeatureFlag } from '../../graphql/queries';

const REFRESH_PERIOD = 60_000 * 5;

export const ActionTypes = {
  REQUEST_FEATURE_FLAG: 'REQUEST_FEATURE_FLAG',
  RECEIVE_FEATURE_FLAG: 'RECEIVE_FEATURE_FLAG',
};

const cloneAndScaffold = (state, key) => {
  const newState = { ...state };

  if (newState[key]) {
    newState[key] = cloneDeep(newState[key]);
  } else {
    newState[key] = {
      value: null,
      listeners: new Set(),
      lastRequested: 0,
    };
  }

  return newState;
};

export const requestFeatureFlag = featureFlag => async (dispatch, getState) => {
  const currentState = getState();

  if ((currentState[featureFlag]?.lastRequested ?? 0) < Date.now() - REFRESH_PERIOD) {
    try {
      dispatch({
        type: ActionTypes.REQUEST_FEATURE_FLAG,
        payload: {
          featureFlag,
        },
      });

      const { data } = await API.graphql({
        query: checkFeatureFlag,
        variables: {
          id: featureFlag,
        },
      });

      const value = JSON.parse(data.checkFeatureFlag);

      if (!getState()) {
        return;
      }

      dispatch({
        type: ActionTypes.RECEIVE_FEATURE_FLAG,
        payload: {
          featureFlag,
          value,
        },
      });
    } catch (e) {
      console.error(`Error fetching feature flag: ${featureFlag}`, e);
    }
  }
};

export const featureFlagReducer = (state, { payload, type }) => {
  const newState = cloneAndScaffold(state, payload.featureFlag);
  const featureFlagData = newState[payload.featureFlag];

  switch (type) {
    case ActionTypes.REQUEST_FEATURE_FLAG:
      featureFlagData.lastRequested = Date.now();

      return newState;
    case ActionTypes.RECEIVE_FEATURE_FLAG:
      featureFlagData.value = payload.value;

      return newState;
    default:
      return state;
  }
};
