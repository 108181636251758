import React, { useState, useEffect } from 'react';
import { TopSitesIcon } from 'src/svgs';
import { getDataAppMetrics } from 'src/helpers/getDataAppMetrics';
import { Campaign } from 'src/models';
import { Heatmap } from './Heatmap';

export const Geography = ({ campaign = {}, appParams }) => {
  const [topCitiesMetrics, setTopCities] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let mounted = true;

    if (campaign.id) {
      getDataAppMetrics(Campaign, campaign.id, 'CAMPAIGN_TOP_CITIES').then(resp => {
        if (mounted) {
          if (resp.items) {
            const sorted = resp.items.sort((a, b) => (a.impressions > b.impressions ? -1 : 1)).slice(0, 15);

            setTopCities(sorted);
          }
          setLoading(false);
        }
      });
    }

    return () => (mounted = false);
  }, [campaign.id]);

  const empty = !loading && topCitiesMetrics.length < 1;

  if (loading || empty) return <div />;

  return (
    <div className="w-full flex flex-col space-y-4 rounded shadow bg-white p-4">
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-6">
          <TopSitesIcon className="w-11 h-11 mt-px stroke-current text-primary" />
          <h1 className="text-gray-900 text-xl font-semibold">Geography</h1>
        </div>
        <h1 className="text-primary pl-6 pr-4 max-w-5xl text-sm">
          {/* eslint-disable-next-line no-irregular-whitespace */}
          <em className="not-italic font-bold">NOTE: </em>
          The map below is provided for general visualization purposes only. While 100% of ads were served to the
          targeted audience locations, it's important to note that perceived discrepancies may appear due to factors
          such as travel activity, VPN status, and other factors at the moment the ad was viewed. This map may take up
          to 24 hours to update.
        </h1>
      </div>
      <div className="flex flex-row">
        {/* <div className="flex-col px-8">
          <h2 className="text-gray-900 text-l font-semibold mb-4">Top Cities</h2>
          <div className="flex flex-col h-full w-full space-y-4">
            {topCitiesMetrics.map((data, idx) => (
              <div key={data.id} className="flex w-full justify-start space-x-4">
                <span>{`${idx + 1}.`}</span>
                <span>{data.metricKey}</span>
              </div>
            ))}
          </div>
        </div> */}
        <div className="flex-1 flex-col">
          <Heatmap campaign={campaign} appParams={appParams} />
        </div>
      </div>
    </div>
  );
};
