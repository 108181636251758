import { createAPIQueryHook, createAPIQuerySubscriptionHook } from '../../hooks/useAPIQuery';
import { campaignFields } from './campaignFields';

const getCampaign = /* GraphQL */ `
  query GetCampaign($id: ID!) {
    result: getCampaign(id: $id) {
      ${campaignFields}
    }
  }
`;

const useCampaignQuery = createAPIQueryHook({
  query: getCampaign,
});

const onUpdateCampaign = /* GraphQL */ `
  subscription OnUpdateCampaign($id: String, $organizationId: String, $teamId: String, $brandId: String) {
    update: onUpdateCampaign(id: $id, organizationId: $organizationId, teamId: $teamId, brandId: $brandId) {
      id
      brandId
      teamId
      organizationId
    }
  }
`;

const useCampaignSubscription = createAPIQuerySubscriptionHook({
  subscription: onUpdateCampaign,
  query: getCampaign,
});

export const useCampaign = ({ organizationId, teamId, brandId, campaignId }) => {
  const disable = !organizationId || !teamId || !brandId || !campaignId;

  const { data: campaign, subscriptionHandler } = useCampaignQuery({ id: campaignId }, { disable });

  useCampaignSubscription({ id: campaignId, brandId, teamId, organizationId }, { subscriptionHandler, disable });

  return campaign ?? {};
};
