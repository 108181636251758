import { SELECT_RANGE_DISABLED, SELECT_RANGE_END, SELECT_START_DISABLED } from './campaignDatePickerVariants';

const END_DATE_ONLY_MESSAGE = 'Only end dates can be modified on running campaigns';
const NO_DATE_RECURRING = 'Dates cannot be edited on running recurring campaigns';
const CAMPAIGN_COMPLETE = 'Dates cannot be changed on completed campaigns';
const DATES_INVALID = 'Start date must be before end date';

const isComplete = campaign => ['completed', 'stopping', 'starting'].includes(campaign.status);

const datesInvalid = campaign => new Date(campaign.startDate) > new Date(campaign.endDate);

const getInfoMessage = (campaign, variant) => {
  if (datesInvalid(campaign)) return DATES_INVALID;

  switch (variant) {
    case SELECT_RANGE_END:
      return END_DATE_ONLY_MESSAGE;
    case SELECT_RANGE_DISABLED:
      return CAMPAIGN_COMPLETE;
    case SELECT_START_DISABLED:
      return isComplete(campaign) ? CAMPAIGN_COMPLETE : NO_DATE_RECURRING;
    default:
      return null;
  }
};

const colorMap = {
  red: {
    bg: 'bg-red-50',
    border: 'border-red-200',
    text: 'text-red-800',
  },
  gray: {
    bg: 'bg-gray-50',
    border: 'border-gray-200',
    text: 'text-gray-800',
  },
  yellow: {
    bg: 'bg-yellow-50',
    border: 'border-yellow-200',
    text: 'text-yellow-800',
  },
};

const getColor = (campaign, variant) => {
  if (datesInvalid) return colorMap.red;

  if ([SELECT_START_DISABLED, SELECT_RANGE_DISABLED].includes(variant)) return colorMap.gray;

  return colorMap.yellow;
};

const InfoMessageBox = ({ campaign, children, variant }) => {
  const color = getColor(campaign, variant);

  const className = `${color.bg} border-2 ${color.border} ${color.text} text-xs px-2 py-1 ml-2 flex items-center w-60`;

  return <div className={className}>{children}</div>;
};

export const CampaignDatePickerInfoMessage = ({ campaign, variant }) => {
  const message = getInfoMessage(campaign, variant);

  if (!message) {
    return null;
  }

  return (
    <InfoMessageBox campaign={campaign} variant={variant}>
      {message}
    </InfoMessageBox>
  );
};
