import { useEffect } from 'react';
import { useHistory, generatePath } from 'react-router-dom';
import { useUserAuth } from 'src/GlobalAppState/UserAuth';
import { AppLoader } from 'src/components/App/AppLoader';
import { useGAS } from 'src/GlobalAppState/context';
import { useNoAuthSignInRedirect } from 'src/hooks/authHooks';

export default function RootRoute() {
  const history = useHistory();
  const { isAdmin, isFinance } = useUserAuth();
  const { userAuthLoaded, routeIdsLoaded, appRouteParams, accountSettings } = useGAS();
  const { onboardingStatus } = accountSettings || {};

  useNoAuthSignInRedirect();

  useEffect(() => {
    if (!userAuthLoaded) return;

    const eligibleForRootRedirect = routeIdsLoaded && !!onboardingStatus;
    const shouldRedirectToOnboarding = eligibleForRootRedirect && onboardingStatus === 'accountSetup';
    const shouldRootRedirect = eligibleForRootRedirect && onboardingStatus !== 'accountSetup';

    if (isAdmin) {
      history.replace('/admin');
    } else if (isFinance) {
      history.replace('/finance');
    } else if (shouldRedirectToOnboarding) {
      history.replace('/onboarding');
    } else if (shouldRootRedirect) {
      history.replace(generatePath('/:model/:id', appRouteParams));
    }
  }, [history, isAdmin, isFinance, appRouteParams, userAuthLoaded, routeIdsLoaded, onboardingStatus]);

  return <AppLoader />;
}
