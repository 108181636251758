import React, { useEffect, useState } from 'react';
import { defaultTheme } from 'src/constants';
import { setThemeColor, bzColorPallets } from 'src/helpers';
import { useGAS } from 'src/GlobalAppState/context';
import { GraphExamples } from 'src/svgs';
import { HexColorPicker } from 'react-colorful';
import { createAPIMutationHook } from 'src/hooks/useAPIQuery';
import { GraphThemeDropdown } from 'src/components/MainSettings/TeamSettings/formFields';
import { WhiteLabelConfig } from 'src/components/MainSettings/TeamSettings/TeamSettingsWhiteLabel/WhiteLabelConfig';
import { SettingsTable, SettingsTableRow } from 'src/components/MainSettings/shared/SettingsTable';
import { SaveButton } from 'src/components/MainSettings/shared/SaveButton';
import { Headline } from 'src/components/MainSettings/shared/Headers';

const saveTheme = /* GraphQL */ `
  mutation UpdateTeam($input: UpdateTeamInput!) {
    updateTeam(input: $input) {
      id
      organizationId
      theme {
        primary
        secondary
        pallet
      }
    }
  }
`;

const useSaveThemeHandler = createAPIMutationHook({
  mutation: saveTheme,
});

export default function TeamSettingsWhiteLabelPage() {
  const { organization, team } = useGAS();
  const {
    primary: teamPrimary,
    secondary: teamSecondary,
    pallet: teamPallet,
  } = team?.theme || organization?.theme || {};
  const { primary: defaultPrimary, secondary: defaultSecondary, pallet: defaultPallet } = defaultTheme;
  const primary = teamPrimary || defaultPrimary;
  const secondary = teamSecondary || defaultSecondary;
  const teamTheme = team?.theme || {};
  const pallet = (teamTheme && teamPallet) || defaultPallet;
  const [primaryColor, setPrimaryColor] = useState(primary);
  const [secondaryColor, setSecondaryColor] = useState(secondary);
  const [palletColor, setPalletColor] = useState(pallet);

  useEffect(() => {
    setPrimaryColor(primary);

    return () => setThemeColor('primary-picker', 'initial');
  }, [primary]);

  useEffect(() => {
    setSecondaryColor(secondary);

    return () => setThemeColor('secondary-picker', 'initial');
  }, [secondary]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    pallet && setPalletColor(pallet);
  }, [pallet]);

  useEffect(() => {
    if (secondaryColor) setThemeColor('secondary-picker', secondaryColor);
  }, [secondaryColor]);

  useEffect(() => {
    if (primaryColor) setThemeColor('primary-picker', primaryColor);
  }, [primaryColor]);

  const { loading, handler: onSaveTheme } = useSaveThemeHandler({
    input: {
      id: team.id,
      theme: {
        primary: primaryColor,
        secondary: secondaryColor,
        pallet: palletColor,
      },
    },
  });

  return (
    <div className="flex flex-col space-y-8 overflow-y-scroll">
      <Headline>Whitelabel Settings</Headline>
      <WhiteLabelConfig />
      <div className="flex flex-col space-y-8 justify-center items-center">
        <SettingsTable>
          <ColorSelectors
            team={team}
            setSecondaryColor={setSecondaryColor}
            secondaryColor={secondaryColor}
            setPrimaryColor={setPrimaryColor}
            primaryColor={primaryColor}
            primary={primary}
            secondary={secondary}
            onSave={onSaveTheme}
          />
          <SettingsTableRow
            descriptionHeadline="Chart Palette"
            description="These colors will be used to render charts and graphs throughout your account."
          >
            <PalletSelector
              team={team}
              palletColor={palletColor}
              setPalletColor={setPalletColor}
              onSave={onSaveTheme}
            />
          </SettingsTableRow>
        </SettingsTable>
        <div className="flex flex-row items-center">
          <SaveButton
            loading={loading}
            onSave={onSaveTheme}
            disabled={pallet === palletColor && primaryColor === primary && secondaryColor === secondary}
          />
        </div>
      </div>
    </div>
  );
}

const ColorSelectors = ({ primary, secondary, primaryColor, secondaryColor, setPrimaryColor, setSecondaryColor }) => {
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    primary && setPrimaryColor(primary);
  }, [primary, setPrimaryColor]);
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    secondary && setSecondaryColor(secondary);
  }, [secondary, setSecondaryColor]);

  useEffect(() => {
    if (secondaryColor) setThemeColor('secondary-picker', secondaryColor.padEnd(7, 'f'));
  }, [secondaryColor]);

  useEffect(() => {
    if (primaryColor) setThemeColor('primary-picker', primaryColor.padEnd(7, 'f'));
  }, [primaryColor]);

  return (
    <>
      <SettingsTableRow
        descriptionHeadline="Primary Palette Color"
        description="These colors will control the overall theme and style of pages in your account"
      >
        <ColorSelector color={primaryColor} onReset={() => setPrimaryColor(primary)} onChange={setPrimaryColor} />
      </SettingsTableRow>
      <SettingsTableRow
        descriptionHeadline="Secondary Palette Color"
        description="These colors will control the overall theme and style of pages in your account"
      >
        <ColorSelector
          color={secondaryColor}
          onReset={() => setSecondaryColor(secondary)}
          onChange={setSecondaryColor}
        />
      </SettingsTableRow>
    </>
  );
};

const ColorSelector = ({ color, onChange, onReset }) => {
  const onFieldChange = ({ target: { value: val } = {} }) => {
    const cleanVal = `#${val.replace(/^#/, '')}`;

    onChange(cleanVal);
  };

  const pickerColor = (color || '#').padEnd(7, 'f');

  return (
    <div className="flex flex-col space-y-8 sm:flex-row sm:space-x-8 sm:space-y-0 w-auto px-2 sm:px-0 items-center">
      <div className="flex-1 mb-4 sm:mb-0">
        <HexColorPicker color={pickerColor} onChange={onChange} />
      </div>
      <div className="flex flex-col space-y-2 w-full">
        <input type="text" onChange={onFieldChange} value={color} className="bz-input" />
        <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2 w-full">
          <div style={{ backgroundColor: color }} className="h-12 sm:w-1/2 sm:h-auto rounded bg-green-200" />
          <div className="w-full sm:w-1/2 h-full flex flex-col space-y-2">
            <div className="flex items-center space-x-2">
              <div style={{ backgroundColor: color }} className="h-12 w-1/2 rounded bg-green-200 opacity-50" />
              <h1 className="font-medium">50%</h1>
            </div>
            <div className="flex items-center space-x-2">
              <div style={{ backgroundColor: color }} className="h-12 w-1/4 rounded bg-green-200 opacity-25" />
              <h1 className="font-medium">25%</h1>
            </div>
          </div>
        </div>
        <div className="flex space-x-2 items-center justify-end">
          <button type="button" onClick={onReset} className="text-sm hover:text-primary underline">
            Reset
          </button>
        </div>
      </div>
    </div>
  );
};

const defaultColors = ['#1F2937', '#D1D5DB', '#374151', '#9CA3AF', '#4B5563', '#6B7280'];

const PalletSelector = ({ palletColor, setPalletColor }) => {
  const { [palletColor]: selectedColor } = bzColorPallets || {};
  const { colors: themeColors = defaultColors } = selectedColor || {};

  return (
    <div className="w-full flex flex-col space-y-8 items-center">
      <GraphThemeDropdown value={palletColor} onSelect={setPalletColor} />
      <div className="flex-1 px-4 w-96">
        <GraphExamples className="h-full" colors={themeColors} />
      </div>
    </div>
  );
};
