import React from 'react';
import { shortNumber } from './helpers';

export const HeatmapPanel = ({ name, geoJson }) => {
  const { impressions, clicks, views, spend } = geoJson?.properties?.metrics || {};

  return (
    <div className="flex space-x-4 items-center w-full">
      <div className="flex w-full items-center space-x-8">
        <div className="flex flex-col flex-1">
          {name.split(' > ').map(str => (
            <h1 key={`name-${str}`} className="text-gray-800 text-base">
              {str}
            </h1>
          ))}
        </div>
        <div className="w-20 flex flex-col items-center">
          <h1 className="text-lg text-primary">{shortNumber(impressions || 0)}</h1>
          <h1 className="text-secondary">impressions</h1>
        </div>
        <div className="w-20 flex flex-col items-center">
          <h1 className="text-lg text-primary">{shortNumber(views || 0)}</h1>
          <h1 className="text-secondary">views</h1>
        </div>
        <div className="w-20 flex flex-col items-center">
          <h1 className="text-lg text-primary">{shortNumber(clicks || 0)}</h1>
          <h1 className="text-secondary">clicks</h1>
        </div>
        <div className="w-20 flex flex-col items-center">
          <h1 className="text-lg text-primary">${shortNumber(spend || 0)}</h1>
          <h1 className="text-secondary">spend</h1>
        </div>
      </div>
    </div>
  );
};
