// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const SummaryType = {
  "ALL_TIME": "ALL_TIME",
  "THIS_MONTH": "THIS_MONTH"
};

const DaSegmentType = {
  "AUDIENCE": "AUDIENCE",
  "DMP": "DMP",
  "GEO": "GEO",
  "INVENTORY": "INVENTORY"
};

const DaDspSegmentType = {
  "TTD_DMP": "TTD_DMP",
  "TTD_GEO": "TTD_GEO",
  "TTD_INVENTORY": "TTD_INVENTORY"
};

const SearchableSortDirection = {
  "ASC": "asc",
  "DESC": "desc"
};

const SearchableSegmentSortableFields = {
  "ID": "id",
  "CREATED_AT": "createdAt",
  "UPDATED_AT": "updatedAt",
  "SEGMENT_GROUP": "segmentGroup",
  "SEGMENT_PACKAGE_NAME": "segmentPackageName",
  "SEGMENT_PACKAGE_ID": "segmentPackageId",
  "PATH_KEY": "pathKey",
  "NAME": "name",
  "VERSION": "_version",
  "DELETED": "_deleted",
  "LAST_CHANGED_AT": "_lastChangedAt"
};

const SearchableAggregateType = {
  "TERMS": "terms",
  "AVG": "avg",
  "MIN": "min",
  "MAX": "max",
  "SUM": "sum"
};

const SearchableSegmentAggregateField = {
  "ID": "id",
  "CREATED_AT": "createdAt",
  "UPDATED_AT": "updatedAt",
  "SEGMENT_TYPE": "segmentType",
  "SEGMENT_GROUP": "segmentGroup",
  "SEGMENT_PACKAGE_NAME": "segmentPackageName",
  "SEGMENT_PACKAGE_ID": "segmentPackageId",
  "PATH_KEY": "pathKey",
  "NAME": "name"
};

const DaSearchableMetricsSortableFields = {
  "ID": "id",
  "ADMIN_GROUPS": "adminGroups",
  "VIEWER_GROUPS": "viewerGroups",
  "CREATED_AT": "createdAt",
  "UPDATED_AT": "updatedAt",
  "ORGANIZATION_ID": "organizationId",
  "TEAM_ID": "teamId",
  "BRAND_ID": "brandId",
  "CAMPAIGN_ID": "campaignId",
  "O_MAR": "oMar",
  "T_MAR": "tMar",
  "B_MAR": "bMar",
  "METRIC_KEY": "metricKey",
  "CLICKS": "clicks",
  "VIEWS": "views",
  "IMPRESSIONS": "impressions",
  "CONVERSIONS": "conversions",
  "SPEND": "spend"
};

const DaSearchableMetricsAggregateField = {
  "ID": "id",
  "ADMIN_GROUPS": "adminGroups",
  "VIEWER_GROUPS": "viewerGroups",
  "CREATED_AT": "createdAt",
  "UPDATED_AT": "updatedAt",
  "ORGANIZATION_ID": "organizationId",
  "TEAM_ID": "teamId",
  "BRAND_ID": "brandId",
  "CAMPAIGN_ID": "campaignId",
  "O_MAR": "oMar",
  "T_MAR": "tMar",
  "B_MAR": "bMar",
  "METRIC_TYPE": "metricType",
  "METRIC_KEY": "metricKey",
  "CLICKS": "clicks",
  "VIEWS": "views",
  "IMPRESSIONS": "impressions",
  "CONVERSIONS": "conversions",
  "SPEND": "spend"
};

const GeoLocationType = {
  "COUNTRY": "Country",
  "REGION": "Region",
  "CITY": "City",
  "ZIP": "Zip",
  "METRO": "Metro",
  "CUSTOM": "Custom"
};

const CustomCategoryMatchType = {
  "EXACT_MATCH": "ExactMatch",
  "EXACT_REQUIRED": "ExactRequired",
  "EXACT_AVOID": "ExactAvoid"
};

const CustomCategoryMatchApp = {
  "EXACT": "Exact",
  "SIMILAR": "Similar"
};

const TtdInsightMetricType = {
  "IMPRESSIONS": "Impressions",
  "SPEND": "Spend",
  "FREQUENCY_USERS": "FrequencyUsers",
  "FREQUENCY_PEOPLE": "FrequencyPeople",
  "FREQUENCY_HOUSEHOLD": "FrequencyHousehold",
  "REACH_USERS": "ReachUsers",
  "REACH_PEOPLE": "ReachPeople",
  "REACH_HOUSEHOLD": "ReachHousehold",
  "REACH": "Reach",
  "FREQUENCY": "Frequency",
  "OTP": "OTP",
  "LINEAR_TV_REACH": "LinearTVReach",
  "DIGITAL_REACH": "DigitalReach",
  "US_DEMOGRAPHIC_PERCENTAGES": "UsDemographicPercentages",
  "SAMBA_UNIQUE_USER_COUNT": "SambaUniqueUserCount",
  "UF_WITH_RAILS_NO_EXCLUSION_AVAILS": "UfWithRailsNoExclusion_Avails",
  "UF_WITH_RAILS_NO_EXCLUSION_POTENTIAL_BIDS": "UfWithRailsNoExclusion_PotentialBids",
  "UF_WITH_RAILS_NO_EXCLUSION_IMPRESSIONS": "UfWithRailsNoExclusion_Impressions",
  "UF_WITH_RAILS_NO_EXCLUSION_PEOPLE_REACH": "UfWithRailsNoExclusion_PeopleReach",
  "UF_WITH_RAILS_NO_EXCLUSION_DEVICE_REACH": "UfWithRailsNoExclusion_DeviceReach",
  "UF_WITH_RAILS_NO_EXCLUSION_HH_REACH": "UfWithRailsNoExclusion_HhReach",
  "UF_WITH_RAILS_NO_EXCLUSION_SPEND": "UfWithRailsNoExclusion_Spend",
  "UF_WITH_RAILS_NO_EXCLUSION_DEVICE_FREQ": "UfWithRailsNoExclusion_DeviceFreq",
  "UF_WITH_RAILS_NO_EXCLUSION_PEOPLE_FREQ": "UfWithRailsNoExclusion_PeopleFreq",
  "UF_WITH_RAILS_NO_EXCLUSION_HH_FREQ": "UfWithRailsNoExclusion_HhFreq",
  "UF_WITH_RAILS_EXCLUDING_ACR_AVAILS": "UfWithRailsExcludingAcr_Avails",
  "UF_WITH_RAILS_EXCLUDING_ACR_POTENTIAL_BIDS": "UfWithRailsExcludingAcr_PotentialBids",
  "UF_WITH_RAILS_EXCLUDING_ACR_IMPRESSIONS": "UfWithRailsExcludingAcr_Impressions",
  "UF_WITH_RAILS_EXCLUDING_ACR_PEOPLE_REACH": "UfWithRailsExcludingAcr_PeopleReach",
  "UF_WITH_RAILS_EXCLUDING_ACR_DEVICE_REACH": "UfWithRailsExcludingAcr_DeviceReach",
  "UF_WITH_RAILS_EXCLUDING_ACR_HH_REACH": "UfWithRailsExcludingAcr_HhReach",
  "UF_WITH_RAILS_EXCLUDING_ACR_SPEND": "UfWithRailsExcludingAcr_Spend",
  "UF_WITH_RAILS_EXCLUDING_ACR_DEVICE_FREQ": "UfWithRailsExcludingAcr_DeviceFreq",
  "UF_WITH_RAILS_EXCLUDING_ACR_PEOPLE_FREQ": "UfWithRailsExcludingAcr_PeopleFreq",
  "UF_WITH_RAILS_EXCLUDING_ACR_HH_FREQ": "UfWithRailsExcludingAcr_HhFreq",
  "UF_WITH_RAILS_INTERSECTS_ACR_AVAILS": "UfWithRailsIntersectsAcr_Avails",
  "UF_WITH_RAILS_INTERSECTS_ACR_POTENTIAL_BIDS": "UfWithRailsIntersectsAcr_PotentialBids",
  "UF_WITH_RAILS_INTERSECTS_ACR_IMPRESSIONS": "UfWithRailsIntersectsAcr_Impressions",
  "UF_WITH_RAILS_INTERSECTS_ACR_PEOPLE_REACH": "UfWithRailsIntersectsAcr_PeopleReach",
  "UF_WITH_RAILS_INTERSECTS_ACR_DEVICE_REACH": "UfWithRailsIntersectsAcr_DeviceReach",
  "UF_WITH_RAILS_INTERSECTS_ACR_HH_REACH": "UfWithRailsIntersectsAcr_HhReach",
  "UF_WITH_RAILS_INTERSECTS_ACR_SPEND": "UfWithRailsIntersectsAcr_Spend",
  "UF_WITH_RAILS_INTERSECTS_ACR_DEVICE_FREQ": "UfWithRailsIntersectsAcr_DeviceFreq",
  "UF_WITH_RAILS_INTERSECTS_ACR_PEOPLE_FREQ": "UfWithRailsIntersectsAcr_PeopleFreq",
  "UF_WITH_RAILS_INTERSECTS_ACR_HH_FREQ": "UfWithRailsIntersectsAcr_HhFreq",
  "UF_WITH_RAILS_INTERSECTS_SUPER_ACR_AVAILS": "UfWithRailsIntersectsSuperAcr_Avails",
  "UF_WITH_RAILS_INTERSECTS_SUPER_ACR_POTENTIAL_BIDS": "UfWithRailsIntersectsSuperAcr_PotentialBids",
  "UF_WITH_RAILS_INTERSECTS_SUPER_ACR_IMPRESSIONS": "UfWithRailsIntersectsSuperAcr_Impressions",
  "UF_WITH_RAILS_INTERSECTS_SUPER_ACR_PEOPLE_REACH": "UfWithRailsIntersectsSuperAcr_PeopleReach",
  "UF_WITH_RAILS_INTERSECTS_SUPER_ACR_DEVICE_REACH": "UfWithRailsIntersectsSuperAcr_DeviceReach",
  "UF_WITH_RAILS_INTERSECTS_SUPER_ACR_HH_REACH": "UfWithRailsIntersectsSuperAcr_HhReach",
  "UF_WITH_RAILS_INTERSECTS_SUPER_ACR_SPEND": "UfWithRailsIntersectsSuperAcr_Spend",
  "UF_WITH_RAILS_INTERSECTS_SUPER_ACR_DEVICE_FREQ": "UfWithRailsIntersectsSuperAcr_DeviceFreq",
  "UF_WITH_RAILS_INTERSECTS_SUPER_ACR_PEOPLE_FREQ": "UfWithRailsIntersectsSuperAcr_PeopleFreq",
  "UF_WITH_RAILS_INTERSECTS_SUPER_ACR_HH_FREQ": "UfWithRailsIntersectsSuperAcr_HhFreq"
};

const TtdMetricGeoLocationType = {
  "CUSTOM_ONLY": "CustomOnly",
  "STANDARD_ONLY": "StandardOnly",
  "ALL": "All",
  "COUNTRY": "Country",
  "REGION": "Region",
  "CITY": "City",
  "POSTAL_CODE": "PostalCode",
  "METRO": "Metro",
  "ADVERTISER_SPECIFIC": "AdvertiserSpecific",
  "PARTNER_SPECIFIC": "PartnerSpecific"
};

const TtdVettingStatus = {
  "APPROVED": "Approved",
  "REJECTED": "Rejected",
  "SUBMITTED": "Submitted"
};

const AuthRole = {
  "ADMIN": "admin",
  "EDITOR": "editor",
  "VIEWER": "viewer",
  "MEMBER": "member"
};

const StaticAuthRole = {
  "ADMIN": "Admin",
  "MEDIA": "Media",
  "SALES": "Sales",
  "FINANCE": "Finance"
};

const UserAuthRoleStatus = {
  "OWNER": "owner",
  "PENDING": "pending",
  "INVITED": "invited",
  "ACCEPTED": "accepted",
  "DECLINED": "declined",
  "REVOKED": "revoked"
};

const AccountType = {
  "INACTIVE": "inactive",
  "BRAND": "brand",
  "TEAM": "team",
  "ORGANIZATION": "organization"
};

const AccountTier = {
  "BRANDZOOKA": "brandzooka",
  "BRANDZOOKA_PLUS": "brandzookaPlus",
  "BRANDZOOKA_ENTERPRISE": "brandzookaEnterprise"
};

const OnboardingStatus = {
  "UNINITIALIZED": "uninitialized",
  "ACCOUNT_SETUP": "accountSetup",
  "COMPANY_SETUP": "companySetup",
  "TOUR": "tour",
  "COMPLETE": "complete"
};

const ElectionType = {
  "BALLOT_MEASURE": "ballotMeasure",
  "CANDIDATE_ELECTION": "candidateElection"
};

const PoliticalType = {
  "FEDERAL": "federal",
  "STATE_OR_LOCAL": "stateOrLocal"
};

const AudienceType = {
  "EVERYONE": "everyone",
  "FIRST_PARTY": "firstParty",
  "THIRD_PARTY": "thirdParty",
  "RETARGETING": "retargeting"
};

const CampaignStatus = {
  "DRAFT": "draft",
  "FORECAST": "forecast",
  "APPROVED": "approved",
  "LAUNCHING": "launching",
  "UPDATING": "updating",
  "RUNNING": "running",
  "STOPPING": "stopping",
  "STOPPED": "stopped",
  "PAUSING": "pausing",
  "PAUSED": "paused",
  "COMPLETED": "completed",
  "ARCHIVED": "archived",
  "ERROR": "error"
};

const CreativeDevice = {
  "CTV": "ctv",
  "PC": "pc",
  "MOBILE": "mobile",
  "TABLET": "tablet"
};

const CreativeType = {
  "DISPLAY": "display",
  "VIDEO": "video",
  "AUDIO": "audio"
};

const ChargeType = {
  "STRIPE_CREDIT": "stripeCredit",
  "STRIPE_REFUND": "stripeRefund",
  "TRANSFER_TO": "transferTo",
  "TRANSFER_FROM": "transferFrom"
};

const CreditType = {
  "ORGANIZATION": "organization",
  "TEAM": "team",
  "BRAND": "brand",
  "CAMPAIGN": "campaign"
};

const MetricType = {
  "ORGANIZATION_DAILY": "ORGANIZATION_DAILY",
  "TEAM_DAILY": "TEAM_DAILY",
  "BRAND_DAILY": "BRAND_DAILY",
  "CAMPAIGN_DAILY": "CAMPAIGN_DAILY",
  "CAMPAIGN_TIME_OF_DAY": "CAMPAIGN_TIME_OF_DAY",
  "CAMPAIGN_DAY_OF_WEEK": "CAMPAIGN_DAY_OF_WEEK",
  "CAMPAIGN_TOP_CITIES": "CAMPAIGN_TOP_CITIES",
  "CAMPAIGN_TOP_SITES": "CAMPAIGN_TOP_SITES",
  "CAMPAIGN_DEVICES": "CAMPAIGN_DEVICES",
  "CAMPAIGN_AD_NETWORK": "CAMPAIGN_AD_NETWORK",
  "CAMPAIGN_CREATIVES": "CAMPAIGN_CREATIVES",
  "CAMPAIGN_HEATMAP": "CAMPAIGN_HEATMAP"
};

const CampaignAction = {
  "PAUSE": "pause",
  "STOP": "stop",
  "ARCHIVE": "archive",
  "RESUME": "resume",
  "RUN_FORECAST": "runForecast",
  "RUN_UPDATE": "runUpdate"
};

const TrackingTagType = {
  "IMAGE": "image",
  "FIRST_PARTY": "firstParty",
  "CUSTOM": "custom"
};

const FeatureFlagValue = {
  "TRUE": "TRUE",
  "FALSE": "FALSE"
};

const { User, UserAuthRole, Organization, Team, Brand, Campaign, Creative, Credit, TrackingTag, FeatureFlag, SummaryMetricsGraph, SummaryMetrics, SummaryMetricsConnection, ProtectedFields, DaSegment, DaSegmentDspSegment, DaGeoAggItem, GeoJsonMultiPoly, SearchableAggregateGenericResult, SearchableAggregateResult, SearchableAggregateBucketResultItem, SearchableBucketAggItem, DaSegmentDspSegmentConnection, SearchableSegmentSegmentPathConnection, SearchableSegmentConnection, GeoJsonPoint, DaMetrics, DaSearchableMetricsConnection, DaGeoAggItemConnection, TtdCpmRate, TtdDmpSegment, TtdGeoSegment, UniversalPixelMappings, ExactMatchMappings, WildcardMatchMappings, UniversalPixel, TtdKeyword, TtdCustomCategory, TtdMetricResult, TtdPrivateContractMetricResult, TtdSubPrivateContractMetricResult, TtdPrivateContractGroupMetricResult, TtdGeoSubMetricResult, TtdGeoMetricResult, TtdPrivateContractMetricGeoResult, TtdForecastAudience, TtdForecast, TtdCandidateProfile, AccountSettings, AccountConfig, AccountInfo, PaymentMethod, PaymentMethods, Theme, Markups, PoliticalSettings, RetargetingTag, CampaignSegment, ForecastRange, ForecastResult, Forecast, CampaignServiceResponse, FeatureFlagStaticAuthRoleOverride, FeatureFlagBrandzookaOverrides } = initSchema(schema);

export {
  User,
  UserAuthRole,
  Organization,
  Team,
  Brand,
  Campaign,
  Creative,
  Credit,
  TrackingTag,
  FeatureFlag,
  SummaryType,
  DaSegmentType,
  DaDspSegmentType,
  SearchableSortDirection,
  SearchableSegmentSortableFields,
  SearchableAggregateType,
  SearchableSegmentAggregateField,
  DaSearchableMetricsSortableFields,
  DaSearchableMetricsAggregateField,
  GeoLocationType,
  CustomCategoryMatchType,
  CustomCategoryMatchApp,
  TtdInsightMetricType,
  TtdMetricGeoLocationType,
  TtdVettingStatus,
  AuthRole,
  StaticAuthRole,
  UserAuthRoleStatus,
  AccountType,
  AccountTier,
  OnboardingStatus,
  ElectionType,
  PoliticalType,
  AudienceType,
  CampaignStatus,
  CreativeDevice,
  CreativeType,
  ChargeType,
  CreditType,
  MetricType,
  CampaignAction,
  TrackingTagType,
  FeatureFlagValue,
  SummaryMetricsGraph,
  SummaryMetrics,
  SummaryMetricsConnection,
  ProtectedFields,
  DaSegment,
  DaSegmentDspSegment,
  DaGeoAggItem,
  GeoJsonMultiPoly,
  SearchableAggregateGenericResult,
  SearchableAggregateResult,
  SearchableAggregateBucketResultItem,
  SearchableBucketAggItem,
  DaSegmentDspSegmentConnection,
  SearchableSegmentSegmentPathConnection,
  SearchableSegmentConnection,
  GeoJsonPoint,
  DaMetrics,
  DaSearchableMetricsConnection,
  DaGeoAggItemConnection,
  TtdCpmRate,
  TtdDmpSegment,
  TtdGeoSegment,
  UniversalPixelMappings,
  ExactMatchMappings,
  WildcardMatchMappings,
  UniversalPixel,
  TtdKeyword,
  TtdCustomCategory,
  TtdMetricResult,
  TtdPrivateContractMetricResult,
  TtdSubPrivateContractMetricResult,
  TtdPrivateContractGroupMetricResult,
  TtdGeoSubMetricResult,
  TtdGeoMetricResult,
  TtdPrivateContractMetricGeoResult,
  TtdForecastAudience,
  TtdForecast,
  TtdCandidateProfile,
  AccountSettings,
  AccountConfig,
  AccountInfo,
  PaymentMethod,
  PaymentMethods,
  Theme,
  Markups,
  PoliticalSettings,
  RetargetingTag,
  CampaignSegment,
  ForecastRange,
  ForecastResult,
  Forecast,
  CampaignServiceResponse,
  FeatureFlagStaticAuthRoleOverride,
  FeatureFlagBrandzookaOverrides
};