import { format } from 'date-fns';
import { mapValues } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import { useGAS } from 'src/GlobalAppState/context';
import { getTeamRunningCampaignCounts } from 'src/helpers/team';
import { SearchIcon } from 'src/svgs';
import { AddIcon } from 'src/svgs/icons';
import { Spinner } from 'src/components/shared/Spinner';
import { MainListBrandSort } from 'src/components/MainListView/MainListBrandSort';

const getSpend = brand => {
  const summaryMetrics = brand?.summaryMetrics?.items || [];
  const summaryAllTime = summaryMetrics.find(m => m.summaryType === 'ALL_TIME') || {};

  return summaryAllTime.spend || 0;
};

const useBrandCreditsMap = () => {
  const { credits } = useGAS();

  const records = credits.creditsRecords?.byBrand;

  return useMemo(() => mapValues(records || {}, 'sum'), [records]);
};

const BrandIndexBody = ({ brands, brandRows, teamBrandsLoading, team }) => {
  if (teamBrandsLoading) {
    return <Spinner />;
  }

  if (!brands?.length) {
    return (
      <div className="flex flex-col items-center justify-center space-y-8 mt-12 text-center max-w-lg bg-white shadow rounded p-8 self-center">
        <p>Use brands to organize your campaigns and clients into individual reporting groups.</p>
        <Link to={`/team/${team.id}/new-brand`} className="p-4 w-64 bg-primary rounded text-white text-center">
          Create your first brand
        </Link>
      </div>
    );
  }

  if (!brandRows?.length) {
    return <p className="flex flex-row items-center justify-center h-96">No brands match your filter</p>;
  }

  return (
    <div className="w-full rounded overflow-scroll force-scrollbar max-h-full flex-shrink">
      <table className="table-fixed w-full rounded-md">
        <thead>
          <tr className="h-12 z-10 border-b text-lg font-medium sticky top-0 bg-white">
            <th
              key="name"
              className="sticky left-0 h-fit bg-white w-72 text-left font-medium text-gray-600 capitalize tracking-wider pl-4 rounded-tl-md"
            >
              name
            </th>
            <th key="created" className=" w-40 text-left font-medium text-gray-600 capitalize tracking-wider">
              Created
            </th>
            <th key="website" className="w-72 text-left font-medium text-gray-600 capitalize tracking-wider">
              Website
            </th>
            <th key="credits" className="w-48 text-left font-medium text-gray-600 capitalize tracking-wider">
              Available Credits
            </th>
            <th key="spend" className="w-48 text-left font-medium text-gray-600 capitalize tracking-wider">
              Total Spend
            </th>
            <th key="running" className="w-48 text-left font-medium text-gray-600 capitalize tracking-wider">
              Running Campaigns
            </th>
          </tr>
        </thead>
        <tbody>{brandRows}</tbody>
      </table>
    </div>
  );
};

export default function BrandIndexPage() {
  const { team, teamBrands: brands, teamBrandsLoading } = useGAS();
  const creditsByBrand = useBrandCreditsMap();
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('Sort A-Z');
  const [runningCampaigns, setRunningCampaigns] = useState([]);

  useEffect(() => {
    let mounted = true;

    if (team.id) {
      getTeamRunningCampaignCounts({ team }).then(resp => mounted && setRunningCampaigns(resp));
    }

    return () => (mounted = false);
  }, [team]);

  const filteredBrands = useMemo(() => {
    if (!search || !brands) return brands || [];

    return brands.filter(brand => {
      const str = `${brand.name}${brand.websiteUrl}`.toLowerCase();

      return str.includes(search.toLowerCase());
    });
  }, [brands, search]);

  const sortedBrands = useMemo(() => {
    if (!sort || !filteredBrands) return filteredBrands || [];

    if (sort === 'Sort A-Z') return filteredBrands.sort((a, b) => a.name.localeCompare(b.name));
    if (sort === 'Sort Z-A') return filteredBrands.sort((a, b) => b.name.localeCompare(a.name));
    if (sort === 'Created ASC') return filteredBrands.sort((a, b) => a.createdAt.localeCompare(b.createdAt));
    if (sort === 'Created DESC') return filteredBrands.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
    if (sort === 'Credits') return filteredBrands.sort((a, b) => b.availableCredits - a.availableCredits);
    if (sort === 'Spend') return filteredBrands.sort((a, b) => getSpend(b) - getSpend(a));

    return filteredBrands;
  }, [filteredBrands, sort]);

  const brandRows = (sortedBrands || []).map(brand => {
    const summaryMetrics = brand?.summaryMetrics?.items || [];
    const summaryAllTime = summaryMetrics.find(m => m.summaryType === 'ALL_TIME') || {};
    const createdDateStr = `${brand.createdAt ? format(new Date(brand.createdAt), 'MM/dd/yyyy') : '--'}`;

    const totalRunning = runningCampaigns[brand.id] || 0;

    return (
      <tr key={brand.id} className="w-full h-16 bg-white border-b last-of-type:border-b-0">
        <td className="sticky left-0 h-fit bg-white pl-4 truncate">
          <Link
            to={`/brand/${brand.id}`}
            className="hover:underline hover:cursor-pointer font-semibold text-gray-800 capitalize tracking-wide"
          >
            {brand.name}
          </Link>
        </td>
        <td>{createdDateStr}</td>
        <td className="pr-2">
          <div className="truncate" title={brand.websiteUrl}>
            {brand.websiteUrl || '--'}
          </div>
        </td>
        <td>
          <NumberFormat
            className="text-lg text-gray-600"
            displayType="text"
            value={creditsByBrand[brand.id] || 0}
            thousandSeparator
            fixedDecimalScale
            prefix="$"
            decimalScale={2}
          />
        </td>
        <td>
          <NumberFormat
            className="text-lg text-gray-600"
            displayType="text"
            value={summaryAllTime.spend || 0}
            thousandSeparator
            fixedDecimalScale
            prefix="$"
            decimalScale={2}
          />
        </td>
        <td>{totalRunning}</td>
      </tr>
    );
  });

  return (
    <div className="w-full px-3 mb-4 flex-shrink max-h-full overflow-hidden flex flex-col">
      <div className="w-full flex flex-col pt-1 pb-5 space-y-4">
        <h1 className="text-lg font-semibold">Brands</h1>
        <div className="w-full flex justify-between">
          <div className="flex space-x-2">
            <div className="w-64 h-10 relative text-gray-400 focus-within:text-gray-500 flex flex-row justify-stretch">
              <input
                type="search"
                placeholder="Filter brand name"
                value={search}
                onChange={({ target }) => setSearch(target.value)}
                className="block w-full border border-gray-200 bg-white pl-8 placeholder-gray-400 focus:border-gray-200 focus:outline-none focus:ring-primary rounded"
              />
              <div className="h-16 absolute left-2 -top-3 flex items-center justify-center">
                <SearchIcon className="h-4 stroke-current text-primary" />
              </div>
            </div>
            <Link
              to={`/team/${team.id}/new-brand`}
              className="text-sm bg-primary hover:opacity-75 rounded flex items-center space-x-2 px-3"
            >
              <AddIcon className="text-white w-3 stroke-current" />
              <span className="text-white whitespace-nowrap">Create New Brand</span>
            </Link>
          </div>
          <div className="flex space-x-4">
            <MainListBrandSort sort={sort} setSort={setSort} />
          </div>
        </div>
      </div>
      <BrandIndexBody brands={brands} brandRows={brandRows} teamBrandsLoading={teamBrandsLoading} team={team} />
    </div>
  );
}
