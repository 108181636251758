import { useCallback } from 'react';
import { API } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { useGAS } from 'src/GlobalAppState/context';
import { diffForecast } from 'src/helpers';
import { selectCampaignValidForPublish } from 'src/selectors/campaign/summarySelectors';
import { campaignService } from 'src/graphql/mutations';
import { CampaignAction } from 'src/models';
import { updateCampaign } from 'src/helpers/campaign/updateCampaign';
import { useOrganizationMinBudget } from 'src/helpers/organization/useOrganizationMinBudget';
import { useCreativesContext } from '../../CreativesContext';

const getDeleteCampaignForecastQuery = forecasts => /* GraphQL */ `
  mutation DeleteCampaignForecast {
    ${forecasts
      .map(
        ({ id }, index) => /* GraphQL */ `
      forecast${index}: deleteCampaignForecast(input: { id: "${id}"}) {
        id
      }`
      )
      .join('')}
  }
`;

const HOUR = 1_000 * 60 * 60;
const DAY = 24 * HOUR;

const deleteForecasts = forecasts =>
  API.graphql({
    query: getDeleteCampaignForecastQuery(forecasts),
  });

const useClickHandler = needsForecast => {
  const { campaign, campaignForecasts } = useGAS();
  const history = useHistory();

  return useCallback(async () => {
    if (needsForecast) {
      await updateCampaign({
        id: campaign.id,
        forecastRequestedAt: new Date().getTime(),
      });

      if (campaignForecasts?.length) {
        await deleteForecasts(campaignForecasts);
      }

      await API.graphql({
        query: campaignService,
        variables: {
          campaignId: campaign.id,
          action: CampaignAction.RUN_FORECAST,
        },
      });
    }

    history.push(`/campaign/${campaign.id}/forecast`);
  }, [campaign.id, campaignForecasts, history, needsForecast]);
};

export const ForecastButton = ({ campaignDraft, draftChanged }) => {
  const creatives = useCreativesContext();
  const { campaign, campaignForecasts: forecasts, appRouteParams } = useGAS();
  const orgMinimum = useOrganizationMinBudget();
  const campaignValidForPublish = selectCampaignValidForPublish(campaignDraft, campaign, { orgMinimum });
  const isNew = appRouteParams.page === 'new-campaign';
  const forecastRequestedAt = campaignDraft?.forecastRequestedAt || campaignDraft?.forecast?.requestedAt || 0;

  const forecastExpired = new Date() - new Date(forecastRequestedAt) > DAY;
  const forecastChanged = forecasts?.length && diffForecast(forecasts[0], campaign);
  const forecastEligibleForRun = forecastExpired || forecastChanged;
  const processingForecast = !forecasts?.length && !forecastExpired;
  const needsForecast = !processingForecast && forecastEligibleForRun;

  const onForecastClick = useClickHandler(needsForecast);

  return (
    <button
      type="button"
      disabled={!campaignValidForPublish || !creatives.length || draftChanged || processingForecast || isNew}
      onClick={onForecastClick}
      className="bz-btn-solid w-full h-12 2xl:h-16 disabled:cursor-default hover:opacity-80 disabled:opacity-40 rounded"
    >
      {needsForecast ? 'Generate' : 'View'} Forecast
    </button>
  );
};
