import { useGAS } from 'src/GlobalAppState/context';
import { useFeatureFlag } from 'src/helpers/featureFlags/useFeatureFlag';
import { diffCampaign } from 'src/helpers';
import { CloneButton } from './SummaryButtons/CloneButton';
import { SaveAndPublishButton } from './SummaryButtons/SaveAndPublishButton';
import { ForecastButton } from './SummaryButtons/ForecastButton';
import { RequestForecastButton } from './SummaryButtons/RequestForecastButton';
import { SaveDraftButton } from './SummaryButtons/SaveDraftButton';
import { PublishButton } from './SummaryButtons/PublishButton';

const Wrapper = ({ children }) => <div className="flex flex-col space-y-1">{children}</div>;

const FlaggedForecast = ({ campaignDraft, draftChanged }) => {
  const { appRouteParams } = useGAS();
  const hasRequestForecastFlag = useFeatureFlag('REQUEST_FORECAST', false);
  const isOnForecast = appRouteParams.appState === 'forecast';

  if (isOnForecast) return null;

  const ForecastComponent = hasRequestForecastFlag ? RequestForecastButton : ForecastButton;

  return <ForecastComponent campaignDraft={campaignDraft} draftChanged={draftChanged} />;
};

export const SummaryButtons = ({ campaignDraft, onSave, creativesChanged }) => {
  const { campaign, brand } = useGAS();

  const draftChanged = diffCampaign(campaignDraft, campaign) || creativesChanged;

  const brandError = !brand.isValid && brand.error;

  switch (campaign.status) {
    case 'stopped':
    case 'stopping':
    case 'completed':
      return (
        <Wrapper>
          <CloneButton />
        </Wrapper>
      );
    case 'draft':
    case undefined:
    case null:
      return (
        <Wrapper>
          <SaveDraftButton campaignDraft={campaignDraft} onSave={onSave} draftChanged={draftChanged} />
          <FlaggedForecast campaignDraft={campaignDraft} draftChanged={draftChanged} />
          <PublishButton campaignDraft={campaignDraft} draftChanged={draftChanged} brandError={brandError} />
        </Wrapper>
      );
    default:
      // running
      return (
        <Wrapper>
          <FlaggedForecast campaignDraft={campaignDraft} draftChanged={draftChanged} />
          <SaveAndPublishButton campaignDraft={campaignDraft} draftChanged={draftChanged} brandError={brandError} />
        </Wrapper>
      );
  }
};
