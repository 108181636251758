import React, { useState, useRef, useEffect, useCallback } from 'react';
import { animated, useTransition } from 'react-spring';
import { Auth, Hub } from 'aws-amplify';
import { DropdownButton } from 'src/components/shared';
import { useClickAway } from 'react-use';
import { BzS3 } from 'src/helpers';
import { useGAS } from 'src/GlobalAppState/context';

export const UserMenu = ({ openUp = false }) => {
  const { user } = useGAS();

  const [open, setOpen] = useState(false);
  const menuRef = useRef();

  useClickAway(menuRef, () => setOpen(false));

  const openPage = useCallback(
    page => {
      Hub.dispatch('sidebar', {
        event: 'showSidebar',
        data: { page },
      });
      setOpen(false);
    },
    [setOpen]
  );

  const signOut = () => Auth.signOut().then(() => window?.location.reload());

  return (
    <div className="relative inline-block w-full text-left">
      <ProfileIconField user={user} onClick={() => setOpen(!open)} />
      <div
        ref={menuRef}
        className={`${!open && 'hidden'} absolute z-50 ${
          openUp ? 'left-0 bottom-2' : 'right-0 top-2'
        } w-44 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
      >
        <div className="py-1">
          <DropdownButton onClick={() => openPage('accountSettings')} dataTestId="accountSettings">
            Account Settings
          </DropdownButton>
          <DropdownButton onClick={() => openPage('userSettings')} dataTestId="userSettings">
            User Settings
          </DropdownButton>
          <DropdownButton onClick={signOut}>Sign Out</DropdownButton>
        </div>
      </div>
    </div>
  );
};

const ProfileIconField = ({ user, onClick }) => {
  const { profileImageUrl, firstName = '', lastName = '', id } = user || {};
  const [firstLetter] = firstName ? firstName.split('') : [''];
  const [lastLetter] = lastName ? lastName.split('') : [''];
  const initials = firstLetter ? `${firstLetter}${lastLetter}` : 'me';

  const [signedUrl, setSignedUrl] = useState(false);

  useEffect(() => {
    let mounted = true;

    if (profileImageUrl) {
      BzS3.get(profileImageUrl, { level: 'protected' }).then(url => mounted && setSignedUrl(url));
    }

    return () => (mounted = false);
  }, [profileImageUrl]);

  let logoKey = 'loading';

  if (id && !profileImageUrl) logoKey = 'textLogo';
  if (signedUrl) logoKey = 'imageLogo';

  const transitions = useTransition(logoKey, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const logo = transitions((style, key) => {
    switch (key) {
      case 'imageLogo':
        return (
          <animated.div style={style} className="absolute w-full h-full">
            <div className="flex justify-center items-center w-full h-full rounded-full overflow-hidden">
              <img src={signedUrl} alt="logo" className="w-full h-full" />
            </div>
          </animated.div>
        );
      case 'textLogo':
        return (
          <animated.div style={style} className="absolute w-full h-full">
            <div className="flex justify-center items-center rounded-full w-full h-full bg-primary pt-px">
              <span className="text-xl -mt-1 -ml-px text-white lowercase">{initials}</span>
            </div>
          </animated.div>
        );
      default:
        return (
          <animated.div style={style} className="absolute w-full h-full">
            <div className="flex justify-center items-center rounded-full w-full h-full bg-blueGray-200" />
          </animated.div>
        );
    }
  });

  return (
    <button
      type="button"
      className="flex text-sm bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
      onClick={onClick}
      data-testid="userAvatar"
    >
      <div className="relative w-10 h-10">{logo}</div>
    </button>
  );
};
